import React from 'react';
import { createRoot } from 'react-dom/client';
import './bootstrap';
import { App } from './App';
import { reportWebVitals } from './reportWebVitals';
import './index.css';
import {
  isGoogleAnalyticsConsentGranted,
  initializeGoogleAnalytics,
} from './services/googleAnalytics';
import { isHotjarConsentGranted, initializeHotjar } from './services/hotjar';
import { initializeSentry } from './services/sentry';

initializeSentry();

if (isGoogleAnalyticsConsentGranted()) {
  initializeGoogleAnalytics();
}

if (isHotjarConsentGranted()) {
  initializeHotjar();
}

// Create a root.
const container = document.getElementById('root');
if (container !== null) {
  const root = createRoot(container); // createRoot(container!) if you're sure it's non-null

  // Initial render: Render an element to the root.
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
