import { Box } from '@mui/material';

interface IconProps {
  width?: number;
  height?: number;
  primaryColor?: string;
}

export function QuestionTypeSelectIcon({ width, height, primaryColor }: IconProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <svg
        width={`${width ?? 30}`}
        height={`${height ?? 31}`}
        viewBox="0 0 30 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d)">
          <circle cx="15" cy="14.5" r="10" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.0913 12.127C14.0912 11.9483 14.0911 11.7698 14.0911 11.5912C14.0911 11.3829 14.0912 11.1746 14.0913 10.9663C14.0916 10.5496 14.0919 10.133 14.0911 9.71635C14.0904 9.25479 13.8386 9.00048 13.381 9.00048C12.1572 8.99984 10.9328 8.99984 9.70902 9.00048C9.25775 9.00048 9.00149 9.26178 9.00149 9.7208C9.00086 10.9656 9.00086 12.2111 9.00149 13.456C9.00149 13.9054 9.25837 14.1693 9.69777 14.1693C10.9322 14.1706 12.1666 14.1706 13.401 14.1693C13.8367 14.1687 14.0898 13.9093 14.0904 13.4668C14.0918 13.0203 14.0915 12.5736 14.0913 12.127ZM12.8073 12.8685H10.2797C10.2797 12.0134 10.2797 11.1678 10.2803 10.3063C10.293 10.3049 10.3057 10.3032 10.3184 10.3015C10.3504 10.2972 10.3822 10.293 10.414 10.293C10.7158 10.2927 11.0176 10.2927 11.3194 10.2926C11.772 10.2926 12.2246 10.2925 12.6773 10.2917C12.7641 10.2911 12.8248 10.2962 12.8241 10.4144C12.8209 11.01 12.8207 11.6052 12.8206 12.2005C12.8206 12.399 12.8206 12.5975 12.8204 12.796C12.8204 12.8098 12.8172 12.8237 12.8132 12.841C12.8113 12.8493 12.8093 12.8583 12.8073 12.8685ZM11.0707 20C11.2718 20 11.473 19.923 11.627 19.7705L14.7702 16.6274C15.0767 16.3193 15.0751 15.8227 14.7686 15.5162C14.4622 15.2098 13.964 15.2098 13.6575 15.5162L11.0707 18.1031L10.0539 17.0878C9.74739 16.7814 9.2492 16.7814 8.94274 17.0878C8.63628 17.3943 8.63628 17.8925 8.94274 18.199L10.5143 19.7705C10.6683 19.923 10.8695 20 11.0707 20ZM17.3571 18.4284H20.5003C20.934 18.4284 21.2861 18.0764 21.2861 17.6426C21.2861 17.2088 20.934 16.8568 20.5003 16.8568H17.3571C16.9233 16.8568 16.5713 17.2088 16.5713 17.6426C16.5713 18.0764 16.9233 18.4284 17.3571 18.4284ZM17.3571 12.142H20.5003C20.934 12.142 21.2861 11.79 21.2861 11.3562C21.2861 10.9225 20.934 10.5704 20.5003 10.5704H17.3571C16.9233 10.5704 16.5713 10.9225 16.5713 11.3562C16.5713 11.79 16.9233 12.142 17.3571 12.142Z"
            fill={`${primaryColor ?? '#6B6B6B'}`}
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0.5"
            width="30"
            height="30"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
        </defs>
      </svg>
    </Box>
  );
}
