import { createContext, useContext } from 'react';
import { AlarmActiveShowHideFormContextType } from './types';

export const AlarmActiveShowHideFormContext = createContext<AlarmActiveShowHideFormContextType>({
  isAddNewVisible: false,
  showAddNew: () => {},
  hideAddNew: () => {},
  isAddFromTemplateVisible: false,
  showAddFromTemplate: () => {},
  hideAddFromTemplate: () => {},
  isAnyFormVisible: false,
  hideAllForms: () => {},
});
export const useAlarmActiveShowHideForm = () => useContext(AlarmActiveShowHideFormContext);
