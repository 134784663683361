import { MeasuringDeviceType } from '../models';

export const SCASE_SUPPORT_EXTERNAL_LINK = 'https://www.scase.io/support/';

export const PULSE_OXIMETER_PATH = 'pulse-oximeter';
export const PULSE_OXIMETER_OXYGEN_SATURATION_SUBPATH = 'oxygen-saturation';
export const PULSE_OXIMETER_PULSE_SUBPATH = 'pulse';
export const WEIGHT_PATH = 'weight';
export const WEIGHT_WEIGHT_SUBPATH = 'weight';
export const HEIGHT_PATH = 'height';
export const HEIGHT_HEIGHT_SUBPATH = 'height';
export const MOVEMENT_PAIN_PATH = 'movement-pain';
export const MOVEMENT_PAIN_PATH_SUBPATH = 'movement-pain';
export const PROBE_FLUID_INTAKE_PATH = 'probe-fluid-intake';
export const PROBE_FLUID_INTAKE_PATH_SUBPATH = 'probe-fluid-intake';
export const INTRAVENOUS_FLUID_INTAKE_PATH = 'intravenous-fluid-intake';
export const INTRAVENOUS_FLUID_INTAKE_PATH_SUBPATH = 'intravenous-fluid-intake';
export const URINE_OUTPUT_PATH = 'urine-output';
export const URINE_OUTPUT_PATH_SUBPATH = 'urine-output';
export const THERMOMETER_PATH = 'thermometer';
export const THERMOMETER_TYPE_SUBPATH = 'type';
export const THERMOMETER_TEMPERATURE_SUBPATH = 'temperature';
export const GLUCOMETER_PATH = 'glucometer';
export const GLUCOMETER_GLUCOSE_SUBPATH = 'glucose';
export const GLUCOMETER_FOOD_TYPE_SUBPATH = 'food-type';
export const GLUCOMETER_FOOD_TYPE_BEFORE_FOOD_SUBPATH = 'before-food';
export const GLUCOMETER_FOOD_TYPE_AFTER_FOOD_SUBPATH = 'after-food';
export const BLOOD_PRESSURE_MONITOR_PATH = 'blood-pressure-monitor';
export const BLOOD_PRESSURE_MONITOR_SYSTOLIC_SUBPATH = 'systolic';
export const BLOOD_PRESSURE_MONITOR_DIASTOLIC_SUBPATH = 'diastolic';
export const BLOOD_PRESSURE_MONITOR_PULSE_SUBPATH = 'pulse';

export const PROFILE_PATH = '/profile';

export const ADMIN_DASHBOARD_PATH = '/admin-dashboard';
export const SYSTEM_THRESHOLDS_PATH = `${ADMIN_DASHBOARD_PATH}/thresholds`;

export const MEASURING_DEVICES_PATHS = [
  PULSE_OXIMETER_PATH,
  WEIGHT_PATH,
  HEIGHT_PATH,
  THERMOMETER_PATH,
  GLUCOMETER_PATH,
  BLOOD_PRESSURE_MONITOR_PATH,
];

export const DEFAULT_MEASUREMENT_SORT_PATH_PARAM = 'sort=measuredOnDatetime:desc';
export const DEFAULT_NOTE_SORT_PATH_PARAM = 'sort=createdAt:desc';
export const DEFAULT_PAIRING_SORT_PATH_PARAM = 'sort=endDate:asc';

export const MEASUREMENT_TYPES_PATHS: {
  [key in MeasuringDeviceType]: string;
} = {
  Glucometer: GLUCOMETER_PATH,
  Thermometer: THERMOMETER_PATH,
  BloodPressureMonitor: BLOOD_PRESSURE_MONITOR_PATH,
  PulseOximeter: PULSE_OXIMETER_PATH,
  Weight: WEIGHT_PATH,
  Height: HEIGHT_PATH,
  MovementPain: MOVEMENT_PAIN_PATH,
  ProbeFluidIntake: PROBE_FLUID_INTAKE_PATH,
  IntravenousFluidIntake: INTRAVENOUS_FLUID_INTAKE_PATH,
  UrineOutput: URINE_OUTPUT_PATH,
};
