import { Legend } from 'recharts';
import { useCustomLegendStyles } from './styles';
import { Props } from 'recharts/types/component/DefaultLegendContent';
import { ThresholdsSubLegend } from './ThresholdsSubLegend';

export function CustomLegend({ payload }: Props) {
  const classes = useCustomLegendStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.defaultLegendWrapper}>
        <Legend payload={payload} />
      </div>
      <ThresholdsSubLegend />
    </div>
  );
}
