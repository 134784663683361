import React from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

export const IntravenousFluidIntakeIcon = React.memo(function IntravenousFluidIntakeIconComponent({
  width,
  height,
  color,
}: Props) {
  return (
    <svg
      width={width || 25}
      height={height || 25}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.4142 9.66773C34.9216 8.89306 35.2158 7.97212 35.2158 6.98403C35.2158 4.23143 32.9333 2 30.1177 2C27.3021 2 25.0196 4.23143 25.0196 6.98403C25.0196 7.97212 25.3138 8.89306 25.8212 9.66773H19.5294C17.5801 9.66773 16 11.2126 16 13.1182V36.8257C16 37.7261 16.36 38.5908 17.003 39.2352L22.8046 45.0485C23.4686 45.7141 24.3797 46.0895 25.3306 46.0895H35.2969C36.2478 46.0895 37.1589 45.7141 37.823 45.0485L43.6246 39.2352C44.2676 38.5908 44.6276 37.7261 44.6276 36.8257V13.1182C44.6276 11.2126 43.0474 9.66773 41.0981 9.66773H34.4142ZM32.8628 6.98403C32.8628 8.4662 31.6339 9.66773 30.1177 9.66773C28.6015 9.66773 27.3726 8.4662 27.3726 6.98403C27.3726 5.50185 28.6015 4.30032 30.1177 4.30032C31.6339 4.30032 32.8628 5.50185 32.8628 6.98403ZM30.902 24.6788C27.7728 22.7194 24.5823 22.6941 22.1482 23.2702C20.6163 23.6328 18.7451 22.5886 18.7451 21.0477V13.885C18.7451 12.8263 19.6229 11.9681 20.7059 11.9681H39.9217C41.0047 11.9681 41.8824 12.8263 41.8824 13.885V22.7848C41.8824 23.2601 41.7086 23.7256 41.344 24.0398C39.4498 25.672 35.4314 27.5148 30.902 24.6788Z"
        fill={`${color ?? '#27335D'}`}
      />
      <path
        d="M48.283 42.6466C47.3841 42.2753 46.3049 42.0639 45.0197 42.0639C41.2544 42.0639 39.4491 44.8185 38.826 46.6164C38.5774 47.3332 38.5491 48.0476 38.5491 48.6085V54.9073C38.5491 55.7184 38.3603 56.8783 37.7763 57.7886C37.2482 58.6117 36.3547 59.3163 34.6275 59.3163C33.7425 59.3163 33.1554 59.1156 32.7471 58.8587C32.3324 58.598 32.0111 58.2222 31.7549 57.7394C31.2827 56.8507 31.1134 55.7969 30.9602 54.7995C33.1684 54.3337 34.8236 52.4133 34.8236 50.115V48.0064H25.0196V50.115C25.0196 52.2614 26.463 54.0783 28.4526 54.6885L28.1734 54.7311C28.1906 54.8388 28.2086 54.959 28.2278 55.0897C28.3729 56.0671 28.6054 57.6337 29.3184 58.9763C29.7409 59.7714 30.3555 60.5458 31.262 61.1158C32.1742 61.6895 33.2933 62 34.6275 62C37.3385 62 39.0925 60.7877 40.1016 59.2146C41.0552 57.7287 41.2942 56.0132 41.2942 54.9073V48.6085C41.2942 48.1125 41.3275 47.7619 41.4259 47.4777C41.8687 46.2 42.9463 44.7476 45.0197 44.7476C45.6776 44.7476 46.2195 44.819 46.6691 44.9368C47.244 44.0812 47.8004 43.2978 48.283 42.6466Z"
        fill={`${color ?? '#27335D'}`}
      />
      <path
        d="M43.8455 54.4604C43.9148 51.6095 47.9421 45.6472 49.947 43.0224C51.9039 45.5322 55.8523 51.3336 55.9906 54.4604C56.1637 58.3688 53.9474 61.0415 49.947 61.0415C45.9469 61.0415 43.7594 58.024 43.8455 54.4604Z"
        fill={`${color ?? '#27335D'}`}
      />
    </svg>
  );
});
