import React, { ReactNode, useRef } from 'react';
import { Box, MenuItem } from '@mui/material';
import { useStyles } from './styles';
import { Medic } from '../../../models';
import { useRequireFunctionality } from '../../../hooks';
import {
  ResetOnetimePasswordButton,
  ResetOnetimePasswordCompletedHandler,
  ResetOnetimePasswordHandler,
} from '../../ResetOnetimePasswordButton';
import { ResetPasswordCompletedHandler, ResetPasswordHandler } from '../../ResetPasswordButton';
import { ResetPasscodeButton } from '../../ResetPasscodeButton';
import { useTranslation } from 'react-i18next';
import {
  CanCloseMenu,
  PageTopBarDetailedMoreOptionsButton,
} from '../../PageTopBarDetailedMoreOptionsButton';

interface Props {
  medic: Medic;
  onResetOnetimePassword?: ResetOnetimePasswordHandler;
  onResetOnetimePasswordCompleted?: ResetOnetimePasswordCompletedHandler;
  onResetPasscode?: ResetPasswordHandler;
  onResetPasscodeCompleted?: ResetPasswordCompletedHandler;
}

export function MoreOptionsButton({
  medic,
  onResetOnetimePassword,
  onResetOnetimePasswordCompleted,
  onResetPasscode,
  onResetPasscodeCompleted,
}: Props) {
  const classes = useStyles();
  const commonMoreOptionsButtonRef = useRef<CanCloseMenu>(null);
  const { t } = useTranslation('medic');
  const { checkHasFunctionality } = useRequireFunctionality();

  function handleMenuClose() {
    commonMoreOptionsButtonRef.current?.closeMenu();
  }

  async function handleResetOnetimePasswordCompleted() {
    handleMenuClose();
    if (onResetOnetimePasswordCompleted) {
      return onResetOnetimePasswordCompleted();
    }
  }

  async function handleResetPasscodeCompleted() {
    handleMenuClose();
    if (onResetPasscodeCompleted) {
      return onResetPasscodeCompleted();
    }
  }

  const hasUpdateMedicFunctionality = checkHasFunctionality(['MEDICS_UPDATE']);

  const menuItems: ReactNode[] = [];

  if (hasUpdateMedicFunctionality && onResetOnetimePassword) {
    menuItems.push(
      <MenuItem classes={{ root: classes.menuItemRoot }} disableGutters={true} key={0}>
        <ResetOnetimePasswordButton
          medicId={medic.id}
          buttonText={t('onetimePassword.resetButtonLong')}
          onResetOnetimePassword={onResetOnetimePassword}
          onResetOnetimePasswordCompleted={handleResetOnetimePasswordCompleted}
          showStartIcon={false}
          fullWidth={true}
          variant="text"
          classes={{ text: classes.menuItemText }}
        />
      </MenuItem>,
    );
  }

  if (hasUpdateMedicFunctionality && onResetPasscode) {
    menuItems.push(
      <MenuItem classes={{ root: classes.menuItemRoot }} disableGutters={true} key={1}>
        <Box display={'flex'} width={'100%'}>
          <ResetPasscodeButton
            buttonText={t('passcode.resetButtonLong')}
            dialogTitle={t('passcode.label')}
            medicId={medic.id}
            color="secondary"
            onResetPasscode={onResetPasscode}
            onResetPasscodeCompleted={handleResetPasscodeCompleted}
            showStartIcon={false}
            fullWidth={true}
            variant="text"
            classes={{ text: classes.menuItemText }}
          />
        </Box>
      </MenuItem>,
    );
  }

  return (
    <PageTopBarDetailedMoreOptionsButton menuItems={menuItems} ref={commonMoreOptionsButtonRef} />
  );
}
