import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MedicationIcon } from '../../../../components/Icons';
import { MeasurandType, MeasurandWithThresholds } from '../../../../models';
import { isValidWebUrl } from '../../../../utils';
import { useThresholdsGuidelineInfoStyles } from './styles';

interface Props {
  measurandWithThresholds: MeasurandWithThresholds;
}

export function ThresholdsGuidelineInfo({ measurandWithThresholds }: Props) {
  const { t } = useTranslation('thresholds');
  const { t: tMeasurement } = useTranslation('measurement');
  const classes = useThresholdsGuidelineInfoStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.text}>
        <span className={classes.iconWrapper}>
          <MedicationIcon width={16} height={16} />
        </span>
        {t('guidelineInfoText', {
          measurementType: tMeasurement(measurandWithThresholds.type as MeasurandType),
        }) + ' '}
        <a
          target="_blank"
          rel="noreferrer"
          href={measurandWithThresholds.sourceLink}
          className={classes.link}
        >
          {extractSourceLinkText(measurandWithThresholds.sourceLink) + ' ⟶ '}
        </a>
      </Typography>
    </Box>
  );
}

function extractSourceLinkText(sourceLink: string) {
  if (!isValidWebUrl(sourceLink)) {
    return sourceLink;
  }

  const url = new URL(sourceLink);

  if (url.hostname.startsWith('www.')) {
    return url.hostname.substring(4);
  }

  return url.hostname;
}
