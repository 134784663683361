import { useStyles } from './styles';
import { Box } from '@mui/material';
import { DashboardNavigation } from '../Navigation';
import { DashboardHeader } from '../Header';
import { MouseEventHandler } from 'react';
import { DashboardFooter } from '../Footer';
import { useDashboardMenuContext } from '../../../context/dashboardMenu';
import { DashboardFooterActions } from '../FooterActions';

interface Props {
  onNavigationListItemClick?: MouseEventHandler;
}

export function DashboardDrawerContent({ onNavigationListItemClick }: Props) {
  const { isDrawerOpen } = useDashboardMenuContext();
  const classes = useStyles({ isDrawerOpen: isDrawerOpen });

  return (
    <Box className={classes.navigationBox}>
      <Box className={classes.headerAndNavigationContainer}>
        <DashboardHeader onNavigationListItemClick={onNavigationListItemClick} />
        <Box className={classes.navigationContainer}>
          <DashboardNavigation onNavigationListItemClick={onNavigationListItemClick} />
          {!isDrawerOpen && <DashboardFooterActions />}
        </Box>
      </Box>
      <Box className={classes.navigationFooterWrapper}>
        <DashboardFooter />
      </Box>
    </Box>
  );
}
