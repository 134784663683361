import React from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

export const GlucomerIcon = React.memo(function GlucomerIconComponent({
  width,
  height,
  color,
}: Props) {
  return (
    <svg
      width={width || 18}
      height={height || 19}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.02893 0.285195C5.57819 0.969532 6.6424 2.35689 7.59921 3.95988C8.83229 6.02565 9.45744 7.73405 9.4574 9.03762C9.4574 11.6842 7.33612 13.8373 4.7287 13.8373C2.12128 13.8373 0 11.6842 0 9.03762C0 7.7349 0.625215 6.02676 1.85826 3.96074C2.81507 2.35761 3.87929 0.969782 4.4285 0.28523C4.50204 0.193583 4.61229 0.140381 4.72874 0.140381C4.84515 0.140345 4.95543 0.193547 5.02893 0.285195ZM4.52555 12.1775C4.81487 12.1775 5.10149 12.1412 5.37761 12.0695C5.46973 12.0456 5.53091 11.9633 5.52331 11.8734C5.51574 11.7836 5.44164 11.7115 5.34666 11.7015C3.43832 11.5014 1.99924 9.98339 1.99924 8.17063C1.99924 8.06655 2.00536 7.95668 2.0174 7.8442C2.02747 7.75016 1.96185 7.66369 1.86414 7.64233C1.76635 7.62089 1.66715 7.67145 1.63233 7.76006C1.42623 8.2845 1.32168 8.75435 1.32168 9.15649C1.32168 10.8223 2.75892 12.1775 4.52555 12.1775ZM0 16.9893C0 15.8789 0.900195 14.9787 2.01064 14.9787H15.6812C16.7917 14.9787 17.6919 15.8789 17.6919 16.9893C17.6919 18.0998 16.7917 19 15.6812 19H2.01064C0.900198 19 0 18.0998 0 16.9893ZM1.22876 16.9323C1.22876 16.6214 1.48079 16.3694 1.7917 16.3694H3.47441C3.78532 16.3694 4.03736 16.6214 4.03736 16.9323C4.03736 17.2432 3.78532 17.4953 3.47441 17.4953H1.7917C1.4808 17.4953 1.22876 17.2432 1.22876 16.9323Z"
        fill={color ?? '#6B6B6B'}
      />
    </svg>
  );
});
