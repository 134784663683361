import { Patient } from '../../../../../models';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { FONT_SIZES, FONT_WEIGHTS } from '../../../../Constants';
import { ExportPatientFormValues } from '../../../../../pages/patients/export';
import { useTranslation } from 'react-i18next';

export interface PageHeaderData {
  patient: Patient;
  exportMeasurementsFormValues: ExportPatientFormValues | null;
  measurementMinDate: Date | null;
  measurementMaxDate: Date | null;
}

interface Props {
  pageHeaderData: PageHeaderData;
}

const styles = StyleSheet.create({
  section: {
    width: '100%',
    margin: 2,
    marginBottom: 5,
    fontFamily: 'SegoeUI',
  },
  datetimesWrapper: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  datetimeWrapper: {
    minWidth: 90,
  },
  datetimeLabel: {
    fontSize: FONT_SIZES.h6,
    fontWeight: FONT_WEIGHTS.light,
    color: '#676767',
  },
  datetimeValue: {
    fontSize: FONT_SIZES.h5,
  },
  patientName: {
    fontSize: FONT_SIZES.h2,
    fontWeight: FONT_WEIGHTS.semibold,
    maxWidth: '75%',
    paddingVertical: 10,
  },
  patientInfoWrapper: {
    fontSize: FONT_SIZES.h6,
    flexDirection: 'row',
    width: '100%',
    paddingVertical: 10,
  },
  patientInfoElement: {
    marginRight: 35,
  },
  patientInfoElementLabel: {
    fontSize: 8,
    color: '#676767',
    marginBottom: 2,
    fontWeight: FONT_WEIGHTS.light,
  },
  patientInfoPurpose: {
    width: '100%',
  },
});

export function PatientHeader({ pageHeaderData }: Props) {
  const { t } = useTranslation(['common', 'patient', 'measurement', 'report']);
  return (
    <View style={styles.section} fixed={true}>
      <View style={styles.datetimesWrapper}>
        <View>
          <View style={styles.datetimeWrapper}>
            <Text style={styles.datetimeLabel}> </Text>
            <Text style={[styles.datetimeValue, { fontWeight: FONT_WEIGHTS.light }]}>
              {new Date().toLocaleString([], {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Text>
          </View>
          <Text style={styles.patientName}>
            {pageHeaderData.patient.firstName} {pageHeaderData.patient.lastName}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={[styles.datetimeWrapper, { paddingHorizontal: 4 }]}>
            <Text style={styles.datetimeLabel}>{t('report:from.label')}</Text>
            <Text style={styles.datetimeValue}>
              {pageHeaderData.exportMeasurementsFormValues?.from?.toLocaleDateString() ??
                pageHeaderData.measurementMinDate?.toLocaleDateString() ??
                '-'}
            </Text>
          </View>
          <View style={[styles.datetimeWrapper, { paddingHorizontal: 4 }]}>
            <Text style={styles.datetimeLabel}>{t('report:to.label')}</Text>
            <Text style={styles.datetimeValue}>
              {pageHeaderData.exportMeasurementsFormValues?.to?.toLocaleDateString() ??
                pageHeaderData.measurementMaxDate?.toLocaleDateString() ??
                '-'}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}
