import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { DeleteItemCompletedHandler, DeleteItemHandler } from './types';
import { DeleteGridIcon } from '../Icons';

interface Props {
  onDelete: DeleteItemHandler;
  onDeleteCompleted?: DeleteItemCompletedHandler;
  icon?: ReactNode;
}

export function DeleteItemButton({ onDelete, onDeleteCompleted, icon }: Props) {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();
    setIsOpen(true);
  }

  function handleClose(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();
    setIsOpen(false);
  }

  async function handleDelete(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();
    setIsDeleting(true);
    await onDelete();
    setIsDeleting(false);
    setIsOpen(false);

    if (onDeleteCompleted) {
      await onDeleteCompleted();
    }
  }

  return (
    <React.Fragment>
      <IconButton
        aria-label="Delete"
        onClick={handleClick}
        className={`${icon ? classes.iconOptWrapper : classes.iconWrapper}`}
        size="large"
      >
        {icon ?? <DeleteGridIcon />}
      </IconButton>
      {isOpen && (
        <Dialog open={isOpen} onClose={handleClose}>
          <DialogTitle>{t('deleteConfirmation.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('deleteConfirmation.text')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} disabled={isDeleting}>
              {t('cancel')}
            </Button>
            <Button color="secondary" onClick={handleDelete} disabled={isDeleting}>
              {isDeleting ? <CircularProgress size={24} color="secondary" /> : t('delete')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
}
