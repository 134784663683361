import * as Yup from 'yup';
import { AlarmFormValues } from '../../AlarmStepperForm';
import { useTranslation } from 'react-i18next';

export function AlarmFormPartialTimesSchema() {
  const { t: tAlarm } = useTranslation('alarm');
  return Yup.object({
    triggerTimes: Yup.object({
      morning: Yup.boolean().required(),
      morningTimes: Yup.array()
        .of(Yup.date().required().typeError(tAlarm('triggerTimes.times.validation.validTime')))
        .notRequired()
        .when('morning', {
          is: (value: boolean) => value,
          then: Yup.array()
            .of(
              Yup.date()
                .required(tAlarm('triggerTimes.times.validation.required'))
                .typeError(tAlarm('triggerTimes.times.validation.validTime')),
            )
            .required(tAlarm('triggerTimes.times.validation.required'))
            .min(1, tAlarm('triggerTimes.times.validation.minArrayValue', { min: 1 })),
        }),
      afternoon: Yup.boolean().required(),
      afternoonTimes: Yup.array()
        .of(Yup.date().required().typeError(tAlarm('triggerTimes.times.validation.validTime')))
        .notRequired()
        .when('afternoon', {
          is: (value: boolean) => value,
          then: Yup.array()
            .of(
              Yup.date()
                .required(tAlarm('triggerTimes.times.validation.required'))
                .typeError(tAlarm('triggerTimes.times.validation.validTime')),
            )
            .required(tAlarm('triggerTimes.times.validation.required'))
            .min(1, tAlarm('triggerTimes.times.validation.minArrayValue', { min: 1 })),
        }),
      evening: Yup.boolean().required(),
      eveningTimes: Yup.array()
        .of(Yup.date().required().typeError(tAlarm('triggerTimes.times.validation.validTime')))
        .notRequired()
        .when('evening', {
          is: (value: boolean) => value,
          then: Yup.array()
            .of(
              Yup.date()
                .required(tAlarm('triggerTimes.times.validation.required'))
                .typeError(tAlarm('triggerTimes.times.validation.validTime')),
            )
            .required(tAlarm('triggerTimes.times.validation.required'))
            .min(1, tAlarm('triggerTimes.times.validation.minArrayValue', { min: 1 })),
        }),
    }),
  })
    .test({
      name: 'atLeastOneOfPartOfDaySelected',
      test: function (values) {
        const alarm = values as AlarmFormValues;

        const isValid =
          alarm.triggerTimes.morning || alarm.triggerTimes.afternoon || alarm.triggerTimes.evening;
        if (isValid) {
          return true;
        }

        return this.createError({
          path: 'triggerTimes',
          message: tAlarm('triggerTimes.validation.atLeastOneSelected'),
        });
      },
    })
    .test({
      name: 'triggerTimesMorningTest',
      test: function (values) {
        const alarm = values as AlarmFormValues;
        const isValid =
          !alarm.triggerTimes.morning ||
          (alarm.triggerTimes.morning && alarm.triggerTimes.morningTimes.length > 0);

        if (isValid) {
          return true;
        }

        return this.createError({
          path: 'triggerTimes.morning',
          message: tAlarm('triggerTimes.times.validation.minArrayValue', { min: 1 }),
        });
      },
    })
    .test({
      name: 'afternoonTimesAfternoonTest',
      test: function (values) {
        const alarm = values as AlarmFormValues;
        const isValid =
          !alarm.triggerTimes.afternoon ||
          (alarm.triggerTimes.afternoon && alarm.triggerTimes.afternoonTimes.length > 0);

        if (isValid) {
          return true;
        }

        return this.createError({
          path: 'triggerTimes.afternoon',
          message: tAlarm('triggerTimes.times.validation.minArrayValue', { min: 1 }),
        });
      },
    })
    .test({
      name: 'triggerTimesEveningTest',
      test: function (values) {
        const alarm = values as AlarmFormValues;
        const isValid =
          !alarm.triggerTimes.evening ||
          (alarm.triggerTimes.evening && alarm.triggerTimes.eveningTimes.length > 0);

        if (isValid) {
          return true;
        }

        return this.createError({
          path: 'triggerTimes.evening',
          message: tAlarm('triggerTimes.times.validation.minArrayValue', { min: 1 }),
        });
      },
    });
}
