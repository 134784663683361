import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  amPmWrapper: {
    display: 'flex',
    lineHeight: 1.6,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'normal',
    fontFamily: 'Inter',
    color: '#40434a',
  },
  amPmLabel: {
    color: '#838994',
    marginRight: theme.spacing(0.5),
    fontWeight: 300,
  },
  tooltip: {
    display: 'flex',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  timesWrapper: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  time: {
    display: 'flex',
    overflow: 'hidden',
    '&:after': {
      content: "'/'",
      display: 'block',
      margin: theme.spacing(0, 0.4),
    },
    '&:last-child:after': {
      display: 'none',
    },
  },
  am: {
    color: '#40434a',
  },
  pm: {
    color: '#1b1e22',
  },
}));
