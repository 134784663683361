import { ThresholdsContextProvider } from '../../../context/thresholds';
import {
  MeasurementThresholdsFormValues,
  ThresholdsForm,
} from '../../patients/measurements/components/ThresholdsForm';
import React, { useEffect, useState } from 'react';
import { SubmitFormCompletedHandler } from '../../../components/Form';
import {
  MeasurandWithThresholds,
  MeasurementThresholds,
  MeasuringDeviceType,
  Optional,
} from '../../../models';
import { FunctionalityProvider } from '../../../context/functionality';
import { useParams } from 'react-router-dom';
import {
  getKeyByTypeSubtypeAndSubtypeCoding,
  getThresholdsByDeviceName,
  kebabize,
  sortMeasurandsWithThresholdsByMeasuringDevice,
  transformMeasurandTypeSubTypeAndSubTypeCoding,
} from '../../../utils';
import { ApiResponse } from '../../../services/api';
import { ProgressIndicator } from '../../../components/ProgressIndicator';
import { Box } from '@mui/material';

interface Props<TResponseData = never> {
  onSubmit: (
    values: MeasurementThresholdsFormValues,
    deviceId: number,
  ) => Promise<ApiResponse<TResponseData>>;
  onSubmitCompleted: SubmitFormCompletedHandler<TResponseData>;
  measurementThresholds: MeasurementThresholds[];
  handleModalClose?: () => void;
}

export function SystemThresholdsFormWrapper<TResponseData = never>({
  onSubmit,
  onSubmitCompleted,
  measurementThresholds,
}: Props<TResponseData>) {
  const adminThresholdsPath = '/admin-dashboard/thresholds';
  const { measuringDevice } = useParams<{ measuringDevice: string }>();
  const [deviceThresholds] = useState(
    getThresholdsByDeviceName(measuringDevice as string, measurementThresholds),
  );
  const [sortedDeviceThresholds, setSortedDeviceThresholds] =
    useState<Optional<MeasurementThresholds>>(null);

  useEffect(() => {
    const sortedMeasurementValues = sortMeasurandsWithThresholdsByMeasuringDevice(
      deviceThresholds?.measuringDevice as MeasuringDeviceType,
      deviceThresholds?.measurands,
    );

    const modifiedDeviceThresholds = deviceThresholds;
    if (modifiedDeviceThresholds && sortedMeasurementValues) {
      modifiedDeviceThresholds['measurands'] = sortedMeasurementValues;
    }

    setSortedDeviceThresholds(modifiedDeviceThresholds);
  }, [measurementThresholds, deviceThresholds]);

  function getRoutePath(measuringDevice: string, measurand: MeasurandWithThresholds) {
    const { type, subtype, subtypeCoding } = transformMeasurandTypeSubTypeAndSubTypeCoding(
      measurand,
      kebabize,
    );

    let routePath = `${adminThresholdsPath}/${measuringDevice}/${type}`;

    if (subtype) {
      routePath += `/${subtype}`;
    }

    if (subtypeCoding) {
      routePath += `/${subtypeCoding}`;
    }

    return routePath;
  }

  function getRoutePathRegex(measuringDevice: string, measurand: MeasurandWithThresholds) {
    const { type, subtype, subtypeCoding } = transformMeasurandTypeSubTypeAndSubTypeCoding(
      measurand,
      kebabize,
    );

    let routePathRegex = `${adminThresholdsPath}/:measuringDevice(${measuringDevice})/:measurementType(${type})`;

    if (subtype) {
      routePathRegex += `/:measurementSubtype(${subtype})`;
    }

    if (subtypeCoding) {
      routePathRegex += `/:measurementSubtypeCoding(${subtypeCoding})`;
    }

    return routePathRegex;
  }

  let initialValues: MeasurementThresholdsFormValues = {};
  if (deviceThresholds && deviceThresholds.measurands) {
    initialValues = Object.fromEntries(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(deviceThresholds?.measurands).map(([_, v]) => [
        getKeyByTypeSubtypeAndSubtypeCoding(v),
        {
          typeId: v.typeId,
          subtypeId: v.subtypeId,
          subtypeCodingId: v.subtypeCodingId,
          maxWarning: v.thresholds.maxWarning,
          maxError: v.thresholds.maxError,
          minWarning: v.thresholds.minWarning,
          minError: v.thresholds.minError,
          isMaxWarningCurrentlyActiveOnForm: !!v.thresholds.maxWarning,
          isMaxErrorCurrentlyActiveOnForm: !!v.thresholds.maxError,
          isMinWarningCurrentlyActiveOnForm: !!v.thresholds.minWarning,
          isMinErrorCurrentlyActiveOnForm: !!v.thresholds.minError,
          isMaxWarningDisabled: v.thresholds.isMaxWarningDisabled,
          isMaxErrorDisabled: v.thresholds.isMaxErrorDisabled,
          isMinWarningDisabled: v.thresholds.isMinWarningDisabled,
          isMinErrorDisabled: v.thresholds.isMinErrorDisabled,
        },
      ]),
    );
  }

  if (measurementThresholds && deviceThresholds && !sortedDeviceThresholds) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <ProgressIndicator />
      </Box>
    );
  }

  return (
    <>
      <FunctionalityProvider
        readFunctionality="SYSTEM_THRESHOLDS_READ"
        updateFunctionality="SYSTEM_THRESHOLDS_CREATE_OR_UPDATE"
      >
        <ThresholdsContextProvider>
          <ThresholdsForm
            onSubmit={(values) => onSubmit(values, deviceThresholds?.id ?? -1)}
            onSubmitCompleted={onSubmitCompleted}
            measurementThresholds={sortedDeviceThresholds}
            initialValues={initialValues}
            getRoutePath={getRoutePath}
            getRoutePathRegex={getRoutePathRegex}
            isSystemThresholds={true}
          />
        </ThresholdsContextProvider>
      </FunctionalityProvider>
    </>
  );
}
