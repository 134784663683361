import { Box } from '@mui/material';
import { theme } from '../../assets/style';

interface IconProps {
  width?: number;
  height?: number;
  primaryColor?: string;
  secondaryColor?: string;
}

export function PatientQuestionnaireIcon({
  width,
  height,
  primaryColor = theme.palette.primary.main,
  secondaryColor = theme.palette.primary.dark,
}: IconProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <svg
        width={`${width ?? 16}`}
        height={`${height ?? 15}`}
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip097)">
          <g clipPath="url(#clip197)">
            <path
              d="M2.43359 4.4375C2.43359 2.90975 3.67668 1.66667 5.20443 1.66667H10.532C10.3401 0.99525 9.72818 0.5 8.99609 0.5H2.28776C1.40284 0.5 0.683594 1.21925 0.683594 2.10417V11.1458C0.683594 12.0307 1.40284 12.75 2.28776 12.75H2.43359V4.4375Z"
              fill="url(#paint0_linear97)"
            />
            <path
              d="M11.9131 2.8335H5.20475C4.31984 2.8335 3.60059 3.55275 3.60059 4.43766V12.896C3.60059 13.7809 4.31984 14.5002 5.20475 14.5002H11.9131C12.798 14.5002 13.5173 13.7809 13.5173 12.896V4.43766C13.5173 3.55275 12.798 2.8335 11.9131 2.8335ZM10.7464 12.7502H6.37142C6.12992 12.7502 5.93392 12.5542 5.93392 12.3127C5.93392 12.0712 6.12992 11.8752 6.37142 11.8752H10.7464C10.9879 11.8752 11.1839 12.0712 11.1839 12.3127C11.1839 12.5542 10.9879 12.7502 10.7464 12.7502ZM10.7464 10.4168H6.37142C6.12992 10.4168 5.93392 10.2208 5.93392 9.97933C5.93392 9.73783 6.12992 9.54183 6.37142 9.54183H10.7464C10.9879 9.54183 11.1839 9.73783 11.1839 9.97933C11.1839 10.2208 10.9879 10.4168 10.7464 10.4168ZM10.7464 8.37516H6.37142C6.12992 8.37516 5.93392 8.17916 5.93392 7.93766C5.93392 7.69616 6.12992 7.50016 6.37142 7.50016H10.7464C10.9879 7.50016 11.1839 7.69616 11.1839 7.93766C11.1839 8.17916 10.9879 8.37516 10.7464 8.37516ZM10.7464 6.04183H6.37142C6.12992 6.04183 5.93392 5.84583 5.93392 5.60433C5.93392 5.36283 6.12992 5.16683 6.37142 5.16683H10.7464C10.9879 5.16683 11.1839 5.36283 11.1839 5.60433C11.1839 5.84583 10.9879 6.04183 10.7464 6.04183Z"
              fill="url(#paint1_linear97)"
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear97"
            x1="9.58398"
            y1="1.73221"
            x2="1.15195"
            y2="7.40772"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={primaryColor} />
            <stop offset="0.9996" stopColor={secondaryColor} />
          </linearGradient>
          <linearGradient
            id="paint1_linear97"
            x1="12.5627"
            y1="4.00703"
            x2="4.37307"
            y2="9.83508"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={primaryColor} />
            <stop offset="0.9996" stopColor={secondaryColor} />
          </linearGradient>
          <clipPath id="clip097">
            <rect width="15" height="14" fill="white" transform="translate(0.100586 0.5)" />
          </clipPath>
          <clipPath id="clip197">
            <rect width="14" height="14" fill="white" transform="translate(0.100586 0.5)" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
}
