import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AlarmComponent } from '../../../components/AlarmComponent';
import { ProgressIndicator } from '../../../components/ProgressIndicator';
import { Alarm, UpdateAlarmInput } from '../../../models';
import { useGetApi, ApiRoutes, makeApiRequest } from '../../../services/api';

export function AlarmSubPage() {
  const { t } = useTranslation('alarm');
  const { alarmId, id } = useParams();

  const [{ data: alarm, isLoading: isAlarmLoading }] = useGetApi<Alarm>(
    ApiRoutes.PatientAlarm(id as string, alarmId as string),
  );

  function handleSubmit(input: UpdateAlarmInput) {
    return makeApiRequest<never, UpdateAlarmInput>(
      'PUT',
      ApiRoutes.PatientAlarm(id as string, alarmId as string),
      input,
    );
  }

  if (isAlarmLoading) {
    return <ProgressIndicator />;
  }

  if (!alarm) {
    return <div>{t('error.alarmNotFound')}</div>;
  }

  return <AlarmComponent onSubmit={handleSubmit} alarm={alarm} />;
}
