import React from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

export const OximeterIcon = React.memo(function OximeterIconComponent({
  width,
  height,
  color,
}: Props) {
  return (
    <svg
      width={width || 17}
      height={height || 18}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.852 4.98131C9.29534 3.63567 7.95317 2.47108 7.95317 0.709519C7.94519 0.320996 7.62587 0.00782917 7.22971 0C6.17693 0 5.17707e-07 6.48843 5.17707e-07 10.8874C-0.00149632 14.8141 3.24301 17.9985 7.24692 18C9.70373 18.0009 11.8756 16.8032 13.1875 14.9704C13.1169 14.8941 13.0534 14.8104 12.9983 14.7201H12.998L12.2703 13.5306L11.3436 15.3486C11.0858 15.8526 10.5742 16.1861 9.99847 16.221H9.98828C9.95062 16.2229 9.91517 16.2232 9.90132 16.2232H9.89858C9.36101 16.2232 8.85524 15.9598 8.55431 15.5171L7.53899 14.0239H6.53475C5.64269 14.0239 4.91915 13.3136 4.91915 12.4387C4.91915 11.5639 5.64269 10.8547 6.53475 10.8547H8.40355C8.91487 10.8547 9.39325 11.092 9.69708 11.4898L10.6961 9.52997L10.6966 9.52886C10.9601 9.0152 11.4875 8.6787 12.0787 8.65645L12.0853 8.65535H12.0919C12.6904 8.64173 13.2225 8.92955 13.5253 9.42244L13.5262 9.42355L14.4983 11.013C14.499 10.973 14.4993 10.933 14.4993 10.8928V10.8874C14.4993 8.14723 12.5634 6.46396 10.852 4.98131Z"
        fill={`${color ?? '#6B6B6B'}`}
      />
      <path
        d="M9.27665 15.0455C9.41569 15.2501 9.64966 15.3718 9.89858 15.3718C9.91428 15.3718 9.92998 15.3718 9.94493 15.371C10.211 15.3549 10.448 15.2009 10.5676 14.9671L12.2009 11.7627L13.7431 14.2831C13.8776 14.5038 14.1213 14.6386 14.3837 14.6386H16.2525C16.6651 14.6386 17 14.3102 17 13.9055C17 13.5009 16.6651 13.1724 16.2525 13.1724H14.8068L12.7818 9.86175C12.6412 9.63302 12.402 9.50033 12.112 9.50693C11.8391 9.51719 11.5947 9.67261 11.4721 9.9116L9.80589 13.1805L9.02548 12.0325C8.88644 11.8287 8.65322 11.7062 8.40355 11.7062H6.53475C6.12212 11.7062 5.78723 12.0347 5.78723 12.4393C5.78723 12.844 6.12212 13.1724 6.53475 13.1724H8.00362L9.27665 15.0455Z"
        fill={`${color ?? '#6B6B6B'}`}
      />
    </svg>
  );
});
