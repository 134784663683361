import React from 'react';
import { Box } from '@mui/material';
import { FormAddButton } from '../../AddButton';
import { FieldArray, FieldArrayRenderProps, useField } from 'formik';
import { FormDeleteIconButton } from '../../DeleteIconButton';
import { TextInput } from '../../TextInput';
import { AnswerOptionCreateInput } from '../../../models';
import { useTranslation } from 'react-i18next';
import { NumberInput } from '../../NumberInput';
import { CheckboxInput } from '../../CheckboxInput';

interface Props {
  id: string;
}

export function QuestionAnswersInput({ id }: Props) {
  const { t } = useTranslation('questionnaire');
  const [{ value }, { error, touched }] = useField<AnswerOptionCreateInput>(id);

  function renderInputs(arrayHelpers: FieldArrayRenderProps) {
    if (!value || !Array.isArray(value) || value.length === 0) {
      return null;
    }

    return value.map((_, index) => (
      <Box key={index} display="flex" alignItems="center">
        <Box marginRight={1} flex={3} alignItems="center" justifyContent="center">
          <TextInput
            id={`${id}[${index}].value`}
            label={t('question.answerOption.label')}
            required={true}
            debounceInput={true}
          />
        </Box>
        <Box marginLeft={1} flex={1}>
          <NumberInput
            id={`${id}[${index}].score`}
            label={t('question.score.label')}
            integerOnly={true}
            debounceInput={true}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <FormDeleteIconButton onDeleteClick={() => arrayHelpers.remove(index)} />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" flexShrink="0">
          <CheckboxInput
            id={`${id}[${index}].initialValue`}
            label={t('question.answerOption.initialValue')}
          />
        </Box>
      </Box>
    ));
  }

  function renderArray(arrayHelpers: FieldArrayRenderProps) {
    return (
      <React.Fragment>
        {renderInputs(arrayHelpers)}
        <Box display="flex" justifyContent="space-between">
          <FormAddButton
            onAddClick={() => arrayHelpers.push({ value: '', initialValue: false })}
            id="addQuestionAnswerOptionBtn"
          />
        </Box>
        {/* https://formik.org/docs/api/fieldarray */}
        {touched && error && typeof error === 'string' ? <Box color="#f44336">{error}</Box> : null}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <FieldArray name={id} render={renderArray} />
    </React.Fragment>
  );
}
