import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Measurands, WebMeasurement } from '../../../../../models';
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { lineColors } from './colors';
import { ExtendedDot } from './ExtendedDot';
import { ChartKey, ChartMeasurement, ChartPayload, DotExtendedProps } from './types';
import { Props as LegendProps } from 'recharts/types/component/DefaultLegendContent';
import { getColorByThresholds, getDoubleDisplayValue } from '../../../../../utils';
import { SELECTED_DOT_STROKE_WIDTH, THRESHOLD_DOT_STROKE_WIDTH } from '../../../../../constants';
import { thresholdColors } from '../../../../../constants/colors';
import { useGraphStyles } from './styles';
import { CustomLegend } from './CustomLegend';
import { useSelectedMeasurementContext } from '../../../../../context/selectedMeasurement';
import { MeasurementTooltip } from './MeasurementTooltip';

const MAX_DISPLAYED_VALUES = 15;

export interface MeasurementChartExtensibleProps {
  dot?: DotExtendedProps;
  activeDot?: DotExtendedProps;
  getLegend?: (legendProps: LegendProps) => React.ReactNode;
  hideInLegend?: (key: ChartKey) => boolean;
}

interface Props extends MeasurementChartExtensibleProps {
  data: WebMeasurement[];
}

interface BrushStartEndIndex {
  startIndex?: number;
  endIndex?: number;
}

export type LineColors = {
  [key in ChartKey]?: string;
};

export function MeasurementChart({ data, activeDot, dot, getLegend, hideInLegend }: Props) {
  const { t } = useTranslation('measurement');
  const classes = useGraphStyles();
  const theme = useTheme();
  const mobileMatches = useMediaQuery(theme.breakpoints.down('sm'));
  const [chartData, setChartData] = useState<ChartMeasurement[]>([]);
  const [measurementUnits, setMeasurementUnits] = useState<Measurands>({});
  const { selectedMeasurement, setSelectedMeasurement } = useSelectedMeasurementContext();
  const [minY, setMinY] = useState<number | undefined>(undefined);
  const [maxY, setMaxY] = useState<number | undefined>(undefined);
  const [brushStartEndIndex, setBrushStartEndIndex] = useState<BrushStartEndIndex | undefined>(
    undefined,
  );
  const [loaded, setLoaded] = useState(false);

  // https://github.com/recharts/recharts/issues/2480
  // eslint-disable-next-line
  function handleBrushChange(newIndex: any) {
    setBrushStartEndIndex({ startIndex: newIndex.startIndex, endIndex: newIndex.endIndex });
  }

  // https://github.com/recharts/recharts/issues/511
  // Legend overlapping with chart in some cases (eg. access directly through URL)
  // This is temporary solution, because it is not fixed in library yet.
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 0);
  }, []);

  useEffect(() => {
    if (data.length === 0) {
      setChartData([]);
      return;
    }

    const _measurementUnits: Measurands = {};

    let min: number | undefined;
    let max: number | undefined;
    const keys = getChartKeys(data[0]);
    const transformedData = data.map((d) => {
      const chartMeasurement: ChartMeasurement = {
        id: {
          value: d.id,
          displayValue: d.id.toString(),
        },
        measuredOnDatetime: {
          value: d.measuredOnDatetime,
          displayValue: new Date(d.measuredOnDatetime).toLocaleDateString([], {
            month: 'numeric',
            day: 'numeric',
          }),
        },
        measuredByName: {
          value: d.measuredByName,
          displayValue: d.measuredByName,
        },
      };

      for (const key of keys) {
        const k = key as ChartKey;
        const value = d[k]?.value;

        _measurementUnits[key] = d[k];

        if (value != null && typeof value !== 'string') {
          if (min == null || min > value) {
            min = value;
          }
          if (max == null || max < value) {
            max = value;
          }
        }

        chartMeasurement[k] = {
          value: value?.toString(),
          displayValue: getDisplayValue(value, t),
          isMaxError: d[k]?.isMaxError,
          isMaxWarning: d[k]?.isMaxWarning,
          isMinWarning: d[k]?.isMinWarning,
          isMinError: d[k]?.isMinError,
        };
      }

      return chartMeasurement;
    });

    const minYAxis = Math.round(min || 0);
    const maxYAxis = Math.round(max || 0);
    const yAxisOffset = Math.max(Math.round((maxYAxis - minYAxis) / 5), 5);

    setMeasurementUnits(_measurementUnits);
    setChartData(transformedData);
    // if minumum on Y axis is greater than 0 but substructing offset from it would
    // make it less than 0, use 0 instead (do not go to negative values if not required)
    setMinY(minYAxis > 0 && minYAxis - yAxisOffset < 0 ? 0 : minYAxis - yAxisOffset);
    setMaxY(maxYAxis + yAxisOffset);
  }, [data]);

  useEffect(() => {
    if (chartData.length > MAX_DISPLAYED_VALUES && typeof brushStartEndIndex === 'undefined') {
      setBrushStartEndIndex({
        startIndex: chartData.length - MAX_DISPLAYED_VALUES,
        endIndex: chartData.length - 1,
      });
    }
  }, [chartData]);

  useEffect(() => {
    if (chartData && chartData.length > 0 && typeof selectedMeasurement !== 'undefined') {
      let diff = 0;
      const selectedMeasurementIndex = chartData.findIndex(
        (x) => x.id.value == selectedMeasurement,
      );
      if (selectedMeasurementIndex >= 0) {
        // if range was specified before, check if point is in range, if yes, dont change it
        if (
          typeof brushStartEndIndex !== 'undefined' &&
          typeof brushStartEndIndex.startIndex !== 'undefined' &&
          typeof brushStartEndIndex.endIndex !== 'undefined' &&
          selectedMeasurementIndex >= brushStartEndIndex.startIndex &&
          selectedMeasurementIndex <= brushStartEndIndex.endIndex
        ) {
          return;
        }

        diff = chartData.length - selectedMeasurementIndex;
        let endIndex = chartData.length - diff + Math.floor(MAX_DISPLAYED_VALUES / 2);
        let startIndex = endIndex - MAX_DISPLAYED_VALUES;

        if (startIndex < 0) {
          startIndex = 0;
          endIndex = MAX_DISPLAYED_VALUES;
        } else if (endIndex > chartData.length) {
          endIndex = chartData.length - 1;
          startIndex = chartData.length - MAX_DISPLAYED_VALUES;
        }
        setBrushStartEndIndex({ startIndex: startIndex, endIndex: endIndex });
      }
    }
  }, [selectedMeasurement, chartData]);

  if (chartData.length === 0) {
    return null;
  }

  function hideLineInLegend(key: ChartKey) {
    if (!hideInLegend) {
      return undefined;
    }

    if (hideInLegend(key)) {
      return 'none';
    }

    return undefined;
  }

  const keys = getChartKeys(chartData[0]);

  // eslint-disable-next-line
  function handleLineChartClick(data: any) {
    const measurementId =
      data && data.activePayload && data.activePayload.length > 0
        ? data.activePayload[0].payload?.id?.value
        : null;

    if (measurementId != null && typeof measurementId !== 'undefined') {
      setSelectedMeasurement(measurementId);
    }
  }

  const calcLineColors: LineColors = {};
  keys.forEach((key, index) => {
    calcLineColors[key] = lineColors[index % lineColors.length];
  });

  const getActiveDotFill = (payload: ChartPayload, key: ChartKey, index: number) => {
    return (
      getColorByThresholds(payload[key])?.dark ??
      calcLineColors[key] ??
      lineColors[index % lineColors.length]
    );
  };

  const getDotStroke = (payload: ChartPayload, key: ChartKey, index: number) =>
    getColorByThresholds(payload[key])?.dark ??
    calcLineColors[key] ??
    lineColors[index % lineColors.length];

  const getDotStrokeWidth = (payload: ChartPayload, key: ChartKey, index: number) => {
    const isDotSelected = selectedMeasurement == payload.id.value;
    if (isDotSelected) {
      return SELECTED_DOT_STROKE_WIDTH;
    }

    return Object.values(thresholdColors).includes(
      getColorByThresholds(payload[key])?.dark ??
        calcLineColors[key] ??
        lineColors[index % lineColors.length],
    )
      ? THRESHOLD_DOT_STROKE_WIDTH
      : undefined;
  };

  const getDotFill = (payload: ChartPayload, key: ChartKey, index: number) => {
    const isDotSelected = selectedMeasurement == payload.id.value;
    if (isDotSelected) {
      return getActiveDotFill(payload, key, index);
    } else {
      return 'white';
    }
  };

  return (
    <Box display="flex" flex={1} height={300} className={classes.graphWrapper}>
      {loaded && (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={chartData}
            width={500}
            height={300}
            margin={{
              top: 5,
              right: !mobileMatches ? 45 : 20,
              left: !mobileMatches ? 20 : -30,
              bottom: 5,
            }}
            onClick={handleLineChartClick}
            style={{ cursor: 'pointer' }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="measuredOnDatetime.displayValue" />
            <YAxis type="number" domain={[minY || 0, maxY || 0]} />

            <Tooltip
              content={
                <MeasurementTooltip
                  lineColors={calcLineColors}
                  measurementUnits={measurementUnits}
                />
              }
            />

            <Legend content={getLegend ? (props) => getLegend(props) : <CustomLegend />} />

            {keys.map((key, index) => (
              <Line
                isAnimationActive={false}
                type="linear"
                dataKey={`${key}.displayValue`}
                name={t(`${key}` as const)}
                unit={` ${measurementUnits[key]?.unit ?? ''}`}
                stroke={calcLineColors[key]}
                key={index}
                activeDot={
                  <ExtendedDot
                    getStroke={(payload) => getDotStroke(payload, key, index)}
                    getStrokeWidth={(payload) => getDotStrokeWidth(payload, key, index)}
                    getFill={
                      activeDot?.getFill ?? ((payload) => getActiveDotFill(payload, key, index))
                    }
                  />
                }
                dot={
                  <ExtendedDot
                    getStroke={(payload) => getDotStroke(payload, key, index)}
                    getStrokeWidth={(payload) => getDotStrokeWidth(payload, key, index)}
                    getFill={dot?.getFill ?? ((payload) => getDotFill(payload, key, index))}
                  />
                }
                legendType={hideLineInLegend(key)}
              />
            ))}
            {chartData.length > MAX_DISPLAYED_VALUES && (
              <Brush
                dataKey="measuredOnDatetime.displayValue"
                endIndex={brushStartEndIndex?.endIndex ?? chartData.length - 1}
                startIndex={
                  brushStartEndIndex?.startIndex ?? chartData.length - MAX_DISPLAYED_VALUES
                }
                height={20}
                onChange={handleBrushChange}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
}

function getObjectKeys<T extends object>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>;
}

function getChartKeys(data: WebMeasurement | ChartMeasurement): ChartKey[] {
  return getObjectKeys(data).filter(
    (x) => x !== 'id' && x !== 'measuredOnDatetime' && x !== 'measuredByName',
  ) as ChartKey[];
}

function getDisplayValue(value: string | number | undefined, t: TFunction): string {
  let displayValue = '';
  if (typeof value === 'string') {
    // eslint-disable-next-line
    displayValue = t(`${value}` as any);
  } else if (typeof value === 'number') {
    if (!Number.isInteger(value)) {
      displayValue = getDoubleDisplayValue(value);
    } else {
      displayValue = value.toString();
    }
  }
  return displayValue;
}
