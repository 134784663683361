import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTopBarDetailed } from '../PageTopBarDetailed';
import { Device } from '../../models/Device';

interface Props {
  device: Device;
  lastUpdated: Date | null;
}

export function DeviceHeader({ device, lastUpdated }: Props) {
  const { t } = useTranslation('device');
  return (
    <PageTopBarDetailed
      entityName={t('title.device', { name: device.chassisId })}
      lastUpdated={lastUpdated}
    />
  );
}
