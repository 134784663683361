import React, { useEffect, useState } from 'react';
import {
  GridCellParams,
  GridRenderCellParams,
  GridRowId,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  GridColDef,
} from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import { getDeleteColumn, getEditColumn } from './columns';
import { useTranslation } from 'react-i18next';
import { useGridContext } from '../../context';
import { BackIconWithCircleBackground, SaveIconWithCircleBackground } from '../Icons';
import { useFunctionalityMode } from '../../context/functionality';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  GRID_DEFAULT_PAGE_SIZE,
  GRID_DEFAULT_PAGE_SIZE_PAGINATION_DISABLED,
  GRID_FIELDS_STOP_PROPAGATION,
} from '../../constants';
import { HEADER_HEIGHT, ROW_HEIGHT } from './constants';
import { CustomDataGrid, goBackAndSaveSelectionButtonStyles, rowClickableStyles } from './styles';

type GridRowData = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type GetEditPathHandler = (id: number) => string;
export type RowEditDisabledHandler<T> = (row: T) => boolean;
type GoBackHandler = () => void;
export type GetRowClassNameHandler<T> = (row: T) => string;
export type RowClickHandler<T> = (row: T) => void;
export type DeleteHandler = (id: number) => Promise<void>;
export type DeleteCompletedHandler<T> = (row: T) => Promise<void>;
export type OnMultiSelectionRow = (rows: GridRowId[]) => void;

export interface DataTableProps<T> {
  columns: GridColumnDefinition<T>[];
  rows: GridRowData[];
  onDelete?: DeleteHandler;
  onDeleteCompleted?: DeleteCompletedHandler<T>;
  getEditPath?: GetEditPathHandler;
  getRowEditDisabled?: RowEditDisabledHandler<T>;
  getRowClassName?: GetRowClassNameHandler<T>;
  selectedIds?: GridRowId[];
  onMultiSelectionRow?: OnMultiSelectionRow;
  onGoBack?: GoBackHandler;
  onSaveSelection?: (ids: number[]) => Promise<void>;
  onRowClick?: RowClickHandler<T>;
  sortModel?: GridSortModel;
  onSortModelChange?: (sortModel: GridSortModel) => void;
  columnBuffer?: number;
  renderDeleteButtonCondition?: (params: GridCellParams) => boolean;
  rowHeight?: number;
  disablePagination?: boolean;
  minHeightRowCount?: number;
  disableStriped?: boolean;
  className?: string;
}

export type GridColumnDefinition<T> = GridColDef & {
  field: Extract<keyof T, string> | 'edit' | 'delete';
  doNotWrapWithEllipsisOverflow?: boolean;
};

export function Grid<T>({
  columns,
  onDelete,
  onDeleteCompleted,
  getEditPath,
  getRowEditDisabled,
  getRowClassName,
  selectedIds,
  onMultiSelectionRow,
  onGoBack,
  onSaveSelection,
  onRowClick,
  rows,
  renderDeleteButtonCondition,
  rowHeight,
  disablePagination = false,
  disableStriped = false,
  minHeightRowCount,
  className,
  //columnBuffer,
  ...props
}: DataTableProps<T>) {
  const { t } = useTranslation('grid');
  const { t: tCommon } = useTranslation('common');
  const {
    currentPage,
    onPageChange,
    pageSize,
    onCurrentRowsCountChange,
    sortModel,
    onSortModelChange,
  } = useGridContext();

  const [selectedRows, setSelectedRows] = useState<GridRowId[]>(selectedIds || []);
  const [allowSelectionModelChange, setAllowSelectionModelChange] = useState(true);

  const allColumns = columns.map((col) => {
    if (col.doNotWrapWithEllipsisOverflow) {
      return col;
    }

    return {
      ...col,
      renderCell: (params: GridRenderCellParams) => (
        <Box overflow="hidden" textOverflow="ellipsis">
          {col.renderCell ? col.renderCell(params) : params.value}
        </Box>
      ),
    };
  });

  useEffect(() => {
    setAllowSelectionModelChange(false);
    onCurrentRowsCountChange(rows.length);
    setSelectedRows([...selectedRows]);
  }, [rows]);

  useEffect(() => {
    setAllowSelectionModelChange(true);
  }, [selectedRows]);

  function onSelectionModelChange(selectionModel: GridRowSelectionModel) {
    if (allowSelectionModelChange) {
      setSelectedRows(selectionModel);
      if (onMultiSelectionRow) {
        onMultiSelectionRow(selectionModel);
      }
    }
  }

  function handleGetRowClassName(params: GridRowParams) {
    if (!getRowClassName) {
      return '';
    }

    return getRowClassName(params.row as unknown as T);
  }

  function handleRowClick(params: GridRowParams) {
    if (onRowClick) {
      onRowClick(params.row as unknown as T);
    }
  }

  const { hasUpdateFunctionality, hasDeleteFunctionality } = useFunctionalityMode();

  if (getEditPath && hasUpdateFunctionality) {
    allColumns.push(
      getEditColumn(getEditPath, onRowClick, getRowEditDisabled, tCommon('cannotBeEdited')),
    );
  }
  if (onDelete && hasDeleteFunctionality) {
    allColumns.push(
      getDeleteColumn(onDelete, onDeleteCompleted, onRowClick, renderDeleteButtonCondition),
    );
  }

  return (
    <React.Fragment>
      <div
        className={`${className ?? ''} ${onRowClick || !!selectedIds ? rowClickableStyles : ''}`}
      >
        <CustomDataGrid
          {...props}
          disableStriped={disableStriped}
          headerHeight={HEADER_HEIGHT}
          rowHeight={rowHeight ?? ROW_HEIGHT}
          pageSize={minHeightRowCount ? minHeightRowCount : pageSize}
          autoHeight
          columns={allColumns}
          rows={rows}
          getRowClassName={handleGetRowClassName}
          disableRowSelectionOnClick={!selectedIds}
          checkboxSelection={!!selectedIds}
          rowSelectionModel={selectedRows}
          onRowSelectionModelChange={onSelectionModelChange}
          onRowClick={handleRowClick}
          slots={{
            columnSortedDescendingIcon: ExpandLessIcon,
            columnSortedAscendingIcon: ExpandMoreIcon,
          }}
          pageSizeOptions={[
            disablePagination ? GRID_DEFAULT_PAGE_SIZE_PAGINATION_DISABLED : GRID_DEFAULT_PAGE_SIZE,
          ]}
          hideFooter={disablePagination}
          paginationModel={{
            page: currentPage ?? 0,
            pageSize: disablePagination ? GRID_DEFAULT_PAGE_SIZE_PAGINATION_DISABLED : pageSize,
          }}
          onPaginationModelChange={(paginationModel) => onPageChange(paginationModel.page)}
          sortModel={allColumns.length > 0 ? sortModel : undefined}
          onSortModelChange={onSortModelChange}
          disableColumnMenu
          onCellClick={({ field }, event) => {
            if (GRID_FIELDS_STOP_PROPAGATION.includes(field)) {
              event.stopPropagation();
            }
          }}
        />
      </div>
      <Box
        display="flex"
        width="100%"
        justifyContent={!onGoBack || !onSaveSelection ? 'flex-end' : 'space-between'}
      >
        {!!onGoBack && (
          <Box my={1}>
            <Button
              onClick={onGoBack}
              variant="outlined"
              startIcon={<BackIconWithCircleBackground invertColors />}
              sx={(theme) => goBackAndSaveSelectionButtonStyles(theme)}
            >
              {t('button.goBack')}
            </Button>
          </Box>
        )}
        {!!onSaveSelection && (
          <Box my={1}>
            <Button
              onClick={() =>
                onSaveSelection(selectedRows.map((selectedRowId) => Number(selectedRowId)))
              }
              variant="outlined"
              startIcon={<SaveIconWithCircleBackground />}
              sx={(theme) => goBackAndSaveSelectionButtonStyles(theme)}
            >
              {t('button.saveSelection')}
            </Button>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
}
