import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useAlarmTypeNavTabsStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: '#eff1f3',
    padding: theme.typography.pxToRem(6),
    borderRadius: theme.typography.pxToRem(4),
    width: 'fit-content',
    minHeight: 'unset',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  flexContainer: {
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(1),
  },
}));
