import { Box } from '@mui/material';

interface IconProps {
  width?: number;
  height?: number;
}

export function EditGridIcon({ width = 16, height = 16 }: IconProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <svg
        width={`${width}`}
        height={`${height}`}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.51304 12.8002H1.59954L5.7725 11.8921C5.85378 11.8786 5.9291 11.8409 5.98869 11.784L13.0589 4.7138C13.5754 4.19843 13.8635 3.49745 13.8589 2.76788C13.8599 2.03264 13.5726 1.32632 13.0589 0.800318C12.5436 0.283835 11.8426 -0.00432806 11.113 0.000348432C10.3787 -0.006149 9.67443 0.291201 9.16705 0.821963L2.11842 7.89216C2.05626 7.94219 2.01094 8.01011 1.98868 8.08675L1.08061 12.2597C1.05582 12.4095 1.10399 12.5622 1.21035 12.6705C1.29018 12.752 1.39898 12.7986 1.51304 12.8002ZM11.113 0.86521C12.1519 0.865127 12.9941 1.70727 12.9942 2.74615C12.9942 2.7534 12.9941 2.76064 12.994 2.76788C13.0011 3.2628 12.8059 3.73918 12.4535 4.08678L9.79408 1.4057C10.1444 1.05753 10.619 0.863016 11.113 0.86521ZM9.18866 2.03276L11.8481 4.6922L5.68596 10.8327L3.02653 8.19485L9.18866 2.03276ZM2.68059 9.05975L4.8211 11.2003L2.07517 11.8057L2.68059 9.05975Z"
          fill="currentColor"
        />
        <path
          d="M15.2431 15.1355H0.75665C0.517817 15.1355 0.324219 15.3291 0.324219 15.5679C0.324219 15.8068 0.517817 16.0004 0.75665 16.0004H15.2431C15.4819 16.0004 15.6755 15.8068 15.6755 15.5679C15.6755 15.3291 15.4819 15.1355 15.2431 15.1355Z"
          fill="currentColor"
        />
      </svg>
    </Box>
  );
}
