import { Button, Dialog, DialogContent } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PatientPairing } from './PatientPairing';
import { usePairButtonStyles } from './styles';
import { Device, Patient } from '../../../models';

interface Props {
  patient: Patient;
  devices: Device[];
  onPairDone: () => Promise<void>;
}

export function PairPatientButton({ patient, devices, onPairDone }: Props) {
  const { t } = useTranslation('patient');
  const classes = usePairButtonStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDoneClick = () => {
    handleClose();
    onPairDone();
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        disableElevation={true}
        onClick={handleClick}
        className={classes.pairButton}
      >
        {t('pair')}
      </Button>
      {open && (
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <PatientPairing
              patient={patient}
              devices={devices}
              onDone={handleDoneClick}
              onClose={handleClose}
            />
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
}
