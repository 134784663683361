import { PageHeaderData, PatientHeader } from '../../detail/PatientHeader';
import { Footer } from '../Footer';
import { Page, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

interface Props {
  pageHeaderData: PageHeaderData;
  children: React.ReactNode;
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    fontFamily: 'SegoeUI',
    paddingHorizontal: 25,
    paddingVertical: 15,
  },
});

export function PageWithHeaderAndFooter({ pageHeaderData, children }: Props) {
  return (
    <Page size="A4" style={styles.page}>
      <PatientHeader pageHeaderData={pageHeaderData} />
      {children}
      <Footer />
    </Page>
  );
}
