import { ProgressIndicator } from '../../../components/ProgressIndicator';
import {
  Alarm,
  AlarmGridModel,
  AlarmType,
  AlarmTypeEnum,
  CreateAlarmInput,
  ListAlarmGroup,
  TriggerTime,
} from '../../../models';
import { ApiRoutes, makeApiRequest, useGetApi } from '../../../services/api';
import { AlarmGroupWithTemplatesFormValues } from './AlarmsFromTemplateForm';
import { FunctionalityProvider } from '../../../context/functionality';
import { RequireFunctionality } from '../../../components/RequireFunctionality';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getTriggerTimesFromAlarmFormValues } from '../../../components/AlarmComponent';
import { CreateAlarmOrAddFromTemplateShowHideForm } from './CreateAlarmOrAddFromTemplateShowHideForm';
import { AlarmFormValues } from '../../../components/AlarmForm';
import { useEntityActionSnackbar } from '../../../hooks';
import { AlarmsGrid } from '../../../components/Grids/AlarmsGrid';
import { useNavigate } from 'react-router';
import { useAlarmActiveShowHideForm } from '../../../context/alarmActiveShowHideForm';
import { NoAlarms } from '../../../components/NoAlarms';
import { EDIT_SUBPATH } from '../../../constants';
import {
  PATIENT_ALARMS_CREATE_POLICY,
  PATIENT_ALARMS_DELETE_POLICY,
  PATIENT_ALARMS_READ_POLICY,
  PATIENT_ALARMS_UPDATE_POLICY,
} from '../../../constants/Policies';

interface Props {
  patientId: number | string;
}

export function ListAlarmsSubPage({ patientId }: Props) {
  const { t } = useTranslation('alarm');
  const navigate = useNavigate();
  const { isAnyFormVisible, hideAllForms } = useAlarmActiveShowHideForm();
  const { enqueueEntityCreatedSnackbar, enqueueEntityDeletedSnackbar } = useEntityActionSnackbar();
  const [{ data: alarms, isLoading: areAlarmsLoading }, { refetch: refetchAlarms }] = useGetApi<
    Alarm[]
  >(ApiRoutes.PatientAlarms(patientId));

  const [{ data: alarmTypes, isLoading: areAlarmTypesLoading }] = useGetApi<AlarmType[]>(
    ApiRoutes.AlarmTypes,
  );

  const [
    { data: autocompleteDrugNames, isLoading: areAutocompleteDrugNamesLoading },
    { refetch: refetchAutocompleteDrugNames },
  ] = useGetApi<string[]>(ApiRoutes.AutocompleteDrugNames);

  const [{ data: dailyPlanTemplates, isLoading: areDailyPlanTemplatesLoading }] = useGetApi<
    ListAlarmGroup[]
  >(ApiRoutes.DailyPlanTemplates);

  async function handleSubmitTemplate(values: AlarmGroupWithTemplatesFormValues) {
    if (values.templateId) {
      return await makeApiRequest(
        'POST',
        ApiRoutes.CreateAlarmsFromDailyPlanTemplates(patientId, values.templateId),
      );
    }

    return {};
  }

  const reminderId = alarmTypes?.find((alarmType) => alarmType.type === AlarmTypeEnum.REMINDER)?.id;

  function handleSubmitAlarm(values: AlarmFormValues) {
    const triggerTimes: TriggerTime[] = getTriggerTimesFromAlarmFormValues(values);

    return makeApiRequest<never, CreateAlarmInput>('POST', ApiRoutes.PatientAlarms(patientId), {
      ...values,
      typeIds: values.typeIds,
      startOnDatetime: values.startOnDatetime || new Date(),
      endOnDatetime: values.endOnDatetime,
      repetitionInterval: values.repetitionIntervalIsActive ? values.repetitionInterval : null,
      repetitionDays: !values.repetitionIntervalIsActive ? values.repetitionDays : null,
      triggerTimes: triggerTimes,
      drugName: reminderId != null && values.typeIds.includes(reminderId) ? values.drugName : null,
    });
  }

  async function handleSubmitAlarmCompleted() {
    await refetchAlarms();
    await refetchAutocompleteDrugNames();
    hideAllForms();

    enqueueEntityCreatedSnackbar(t('alarm'));
  }

  async function handleAlarmDelete(alarmId: number) {
    await makeApiRequest('DELETE', ApiRoutes.PatientAlarm(patientId, alarmId));

    enqueueEntityDeletedSnackbar(t('alarm'));
  }

  function getEditPath(id: number) {
    return `/patients/${patientId}/alarms/${id}/${EDIT_SUBPATH}`;
  }

  function handleRowClick(row: AlarmGridModel) {
    navigate(`/patients/${patientId}/alarms/${row.id}`);
  }

  if (
    areAlarmsLoading ||
    areAlarmTypesLoading ||
    areAutocompleteDrugNamesLoading ||
    areDailyPlanTemplatesLoading
  ) {
    return <ProgressIndicator />;
  }

  if (!alarmTypes) {
    return <div>{t('error.alarmTypesNotFound')}</div>;
  }

  return (
    <RequireFunctionality functionality={PATIENT_ALARMS_READ_POLICY}>
      <React.Fragment>
        <FunctionalityProvider
          createFunctionality={PATIENT_ALARMS_CREATE_POLICY}
          readFunctionality={PATIENT_ALARMS_READ_POLICY}
          updateFunctionality={PATIENT_ALARMS_UPDATE_POLICY}
          deleteFunctionality={PATIENT_ALARMS_DELETE_POLICY}
        >
          <CreateAlarmOrAddFromTemplateShowHideForm
            onSubmitNew={handleSubmitAlarm}
            onSubmitTemplate={handleSubmitTemplate}
            onSubmitCompleted={handleSubmitAlarmCompleted}
            alarmTypes={alarmTypes}
            autocompleteDrugNames={autocompleteDrugNames || []}
            dailyPlanTemplates={dailyPlanTemplates || []}
          />
          {!alarms || alarms.length === 0 ? (
            !isAnyFormVisible && <NoAlarms />
          ) : (
            <AlarmsGrid
              alarms={alarms ?? []}
              alarmTypes={alarmTypes}
              onDelete={handleAlarmDelete}
              onDeleteCompleted={refetchAlarms}
              getEditPath={getEditPath}
              onRowClick={handleRowClick}
            />
          )}
        </FunctionalityProvider>
      </React.Fragment>
    </RequireFunctionality>
  );
}
