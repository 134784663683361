import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { EDIT_SUBPATH } from '../../constants';
import { EditIconWithCircleBackground } from '../Icons';
import { useStyles } from './styles';
import { CustomTooltip } from '../CustomTooltip';

interface Props {
  disabled?: boolean;
}

export function EditFormButton({ disabled = false }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('common');
  const classes = useStyles();

  function redirectToEdit() {
    let editPath = location.pathname;
    if (!editPath.endsWith('/')) {
      editPath += '/';
    }
    editPath += EDIT_SUBPATH;

    navigate(editPath);
  }

  let content = (
    <Button
      variant="outlined"
      startIcon={<EditIconWithCircleBackground />}
      onClick={redirectToEdit}
      className={classes.editButton}
      disabled={disabled}
    >
      {t('edit')}
    </Button>
  );

  if (disabled) {
    content = (
      <CustomTooltip title={t('cannotBeEdited')} placement={'right'}>
        <div>{content}</div>
      </CustomTooltip>
    );
  }

  return content;
}
