import React from 'react';
import { Grid, GridColumnDefinition } from '../../Grid';
import {
  Alarm,
  AlarmGridModel,
  AlarmGridModelDuration,
  AlarmGridModelRepetition,
  AlarmGridModelType,
  AlarmType,
  AlarmTypeName,
} from '../../../models';
import { GridCellParams } from '@mui/x-data-grid';
import { DurationCell } from './columnCells/DurationCell';
import { DeleteItemCompletedHandler } from '../../DeleteButton';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { RepeatCell } from './columnCells/RepeatCell';
import { LoopCell } from './columnCells/LoopCell';
import { TypeCell } from './columnCells/TypeCell';
import { DeviceSyncEntityState } from '../../../models/enums/DeviceSyncEntityState';
import { OnlineStatusCircle } from '../../StatusCircle';
import { Box } from '@mui/material';
import { CustomTooltip } from '../../CustomTooltip';

interface Props {
  alarms: Alarm[];
  alarmTypes: AlarmType[];
  onDelete?: (id: number) => Promise<void>;
  onDeleteCompleted?: DeleteItemCompletedHandler;
  getEditPath?: (id: number) => string;
  onRowClick?: (row: AlarmGridModel) => void;
  columnBuffer?: number;
}

export function AlarmsGrid({
  alarms,
  alarmTypes,
  onDelete,
  onDeleteCompleted,
  getEditPath,
  onRowClick,
  columnBuffer,
}: Props) {
  const { t } = useTranslation('alarm');
  const classes = useStyles();
  const columns: GridColumnDefinition<AlarmGridModel>[] = [
    {
      field: 'type',
      headerName: t('gridColumnType'),
      flex: 0.25,
      minWidth: 120,
      renderCell: (params: GridCellParams) => (
        <TypeCell rowId={params.id} type={params.value as AlarmGridModelType} />
      ),
      sortable: false,
    },
    {
      field: 'times',
      headerName: t('gridColumnLoop'),
      flex: 0.25,
      minWidth: 120,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <LoopCell rowId={params.id} times={params.value as Date[]} />
      ),
    },
    {
      field: 'duration',
      headerName: t('gridColumnDuration'),
      headerAlign: 'center',
      flex: 0.25,
      minWidth: 120,
      sortable: false,
      align: 'center',
      renderCell: (params: GridCellParams) => (
        <DurationCell rowId={params.id} duration={params.value as AlarmGridModelDuration} />
      ),
    },
    {
      field: 'repetition',
      headerName: t('gridColumnRepeat'),
      headerAlign: 'right',
      align: 'right',
      flex: 0.25,
      minWidth: 120,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <RepeatCell rowId={params.id} repetition={params.value as AlarmGridModelRepetition} />
      ),
    },
    {
      field: 'deviceSyncEntityState',
      headerName: t('gridColumnSync'),
      headerAlign: 'right',
      align: 'right',
      flex: 0.25,
      width: 20,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <CustomTooltip
          title={
            (params.row as AlarmGridModel).syncDate !== null
              ? `${(params.row as AlarmGridModel).syncDate}`
              : '-'
          }
          placement="bottom-end"
        >
          <Box display="flex" alignItems="center">
            <OnlineStatusCircle
              isOnline={
                (params.row as AlarmGridModel).deviceSyncEntityState === DeviceSyncEntityState.Sync
              }
            />
          </Box>
        </CustomTooltip>
      ),
    },
  ];

  return (
    <Grid
      columns={columns}
      rows={mapAlarmToAlarmGridModel(alarms, alarmTypes)}
      getEditPath={getEditPath}
      onDelete={onDelete}
      onDeleteCompleted={onDeleteCompleted}
      onRowClick={onRowClick}
      disablePagination={true}
      disableStriped={true}
      className={classes.alarmsGridRoot}
      columnBuffer={columnBuffer}
      rowHeight={80}
      minHeightRowCount={5}
    />
  );
}

function mapAlarmToAlarmGridModel(alarms: Alarm[], alarmTypes: AlarmType[]): AlarmGridModel[] {
  const alarmGridModels: AlarmGridModel[] = [];
  const notUndefined = (anyValue: unknown) => typeof anyValue !== 'undefined';

  for (const alarm of alarms) {
    let alarmTimes: Date[] = [];
    for (const triggerTime of alarm.triggerTimes) {
      alarmTimes = [...alarmTimes, ...triggerTime.times];
    }

    alarmGridModels.push({
      id: alarm.id,
      syncDate: alarm.syncDate,
      deviceSyncEntityState: alarm.deviceSyncEntityState,
      duration: {
        startOnDatetime: alarm.startOnDatetime,
        endOnDatetime: alarm.endOnDatetime,
      },
      times: alarmTimes,
      repetition: {
        interval: alarm.repetitionInterval,
        days: alarm.repetitionDays,
        startDate: alarm.startOnDatetime,
      },
      type: {
        name: alarm.drugName,
        sensors: alarm.typeIds
          .map((x) => alarmTypes.find((alarmType) => alarmType.id === x)?.type)
          .filter(notUndefined) as AlarmTypeName[],
      },
    });
  }

  return alarmGridModels;
}
