import { useTranslation } from 'react-i18next';
import { MeasurandTypes, MeasurementValueWithUnit, WebMeasurement } from '../../../../../models';
import React from 'react';
import { TableHeaderRow } from '../../common/TableHeaderRow';

interface Props {
  measurements: WebMeasurement[];
}

export function MeasurementTableHeader({ measurements }: Props) {
  const { t: tMeasurement } = useTranslation('measurement');

  if (measurements.length <= 0) {
    return <></>;
  }

  return (
    <>
      <TableHeaderRow
        data={[
          { value: tMeasurement('measuredOnDatetime.label') },
          ...Object.entries(measurements[0])
            .filter(([key]) => key != 'id' && key != 'measuredOnDatetime')
            .map(([key, val]) => ({
              value: `${tMeasurement(`${key as keyof MeasurandTypes}` as const)}${
                (val as MeasurementValueWithUnit).unit
                  ? ` (${(val as MeasurementValueWithUnit).unit})`
                  : ''
              }`,
            })),
        ]}
      />
    </>
  );
}
