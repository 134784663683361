import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useNewAlarmDialogStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiPaper-rounded': {
      borderRadius: `${theme.spacing(1)} !important`,
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 424,
    },
    '& .MuiPaper-root': {
      [theme.breakpoints.up('lg')]: {
        minWidth: 424,
      },
    },
  },
}));
