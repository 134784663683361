import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Page } from '../../components/Page';
import { FunctionalityProvider } from '../../context/functionality';
import { CreateDailyPlanTemplateInput, CreateDailyPlanTemplateResult } from '../../models';
import { ApiRoutes, makeApiRequest } from '../../services/api';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import { PageTopBar } from '../../components/PageTopBar';
import { Box } from '@mui/material';
import { DailyPlanTemplateForm, DailyPlanTemplateFormValues } from './DailyPlanTemplateForm';
import { useEntityActionSnackbar } from '../../hooks';

export function CreateDailyPlanTemplatePage() {
  const { t } = useTranslation('dailyPlanTemplate');
  const { enqueueEntityCreatedSnackbar } = useEntityActionSnackbar();
  const navigate = useNavigate();

  function handleSubmit(values: DailyPlanTemplateFormValues) {
    return makeApiRequest<CreateDailyPlanTemplateResult, CreateDailyPlanTemplateInput>(
      'POST',
      ApiRoutes.DailyPlanTemplates,
      values,
    );
  }

  function handleSubmitCompleted(result?: CreateDailyPlanTemplateResult) {
    if (result) {
      navigate(`/daily-plan-templates/${result.id}`);
    }

    enqueueEntityCreatedSnackbar(t('title.template'));
  }

  return (
    <FunctionalityProvider
      createFunctionality="DAILY_PLAN_TEMPLATES_CREATE"
      readFunctionality="DAILY_PLAN_TEMPLATES_READ"
      updateFunctionality="DAILY_PLAN_TEMPLATES_UPDATE"
      deleteFunctionality="DAILY_PLAN_TEMPLATES_DELETE"
    >
      <>
        <StickyTopAppBar>
          <PageTopBar title={t('title.new')} hideLastUpdated={true} />
        </StickyTopAppBar>
        <Box mt={2}>
          <Page requireFunctionality="DAILY_PLAN_TEMPLATES_CREATE">
            <DailyPlanTemplateForm
              onSubmit={handleSubmit}
              onSubmitCompleted={handleSubmitCompleted}
            />
          </Page>
        </Box>
      </>
    </FunctionalityProvider>
  );
}
