import * as Yup from 'yup';
import { TextInput } from '../../components/TextInput';
import { SubmitFormCompletedHandler, SubmitFormHandler } from '../../components/Form';
import { DatePicker } from '../../components/DatePicker';
import { Box, Divider, Typography } from '@mui/material';
import { SelectInput, SelectOption, SexSelectInput } from '../../components/SelectInput';
import {
  CivilStatusEnum,
  ContactPerson,
  Department,
  IdentifierType,
  Optional,
  SexEnum,
} from '../../models';
import { ContactPersonsInput } from '../../components/ContactPersonsInput';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { FormButton } from '../../components/FormButton';
import { EntityForm } from '../../components/EntityForm';
import { usePatientFormStyles } from './styles';
import { NumberInput } from '../../components/NumberInput';
import { isNullOrUndefined } from '../../utils';
import {
  PATIENT_MAX_HEIGHT_LIMIT,
  PATIENT_MAX_WEIGHT_LIMIT,
  PATIENT_MIN_HEIGHT_LIMIT,
  PATIENT_MIN_WEIGHT_LIMIT,
} from '../../constants';
import { DepartmentInput } from '../../components/DepartmentsInput';
import { DepartmentRoomInput } from '../../components/DepartmentRoomInput/DepartmentRoomInput';

export interface PatientFormValues {
  firstName: string;
  lastName: string;
  birthday?: Date;
  identifierTypeId: Optional<number>;
  identificationNumber: Optional<string>;
  username: Optional<string>;
  email: Optional<string>;
  phone: Optional<string>;
  sex: Optional<SexEnum>;
  civilStatus: Optional<CivilStatusEnum>;
  height: Optional<number>;
  weight: Optional<number>;
  addressStreet: Optional<string>;
  addressState: Optional<string>;
  contactPersons: ContactPerson[];
  departmentIds: string[];
  roomId: Optional<string>;
}

interface Props<TResponseData = never> {
  initialValues?: PatientFormValues;
  onSubmit: SubmitFormHandler<PatientFormValues, TResponseData>;
  onSubmitCompleted: SubmitFormCompletedHandler<TResponseData>;
  identifierTypes: IdentifierType[];
  departments: Department[];
  weightUnit?: Optional<string>;
  heightUnit?: Optional<string>;
}

export function PatientForm<TResponseData = never>({
  initialValues,
  onSubmit,
  onSubmitCompleted,
  identifierTypes,
  departments,
  weightUnit,
  heightUnit,
}: Props<TResponseData>) {
  const { t } = useTranslation('patient');
  const { t: tCommon } = useTranslation('common');
  const classes = usePatientFormStyles();

  const defaultFormValues: PatientFormValues = {
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    phone: '',
    identifierTypeId: undefined,
    identificationNumber: '',
    sex: undefined,
    civilStatus: undefined,
    height: null,
    weight: null,
    addressStreet: '',
    addressState: '',
    contactPersons: [],
    departmentIds: [],
    birthday: new Date(),
    roomId: null,
  };
  const today = new Date();
  const schema: Yup.SchemaOf<PatientFormValues> = Yup.object({
    firstName: Yup.string().required(t('firstName.validation.required')),
    lastName: Yup.string().required(t('lastName.validation.required')),
    birthday: Yup.date()
      .required(t('birthday.validation.required'))
      .max(today, t('birthday.validation.maxDate', { max: today.toLocaleDateString() })),
    identifierTypeId: Yup.number()
      .oneOf(
        identifierTypes.map((option) => option.id),
        t('identifierType.validation.oneOf'),
      )
      .nullable(),
    identificationNumber: Yup.string().nullable(),
    departmentIds: Yup.array()
      .min(1, t('department.validation.min'))
      .required(t('department.validation.required'))
      .of(Yup.string().required(t('department.validation.requiredItem'))),
    sex: Yup.mixed<SexEnum>()
      .required(t('sex.validation.required'))
      .oneOf(Object.values(SexEnum), t('sex.validation.oneOf')),
    roomId: Yup.string().nullable(),
    civilStatus: Yup.mixed<CivilStatusEnum>()
      .oneOf(Object.values(CivilStatusEnum), t('civilStatus.validation.oneOf'))
      .nullable(),
    weight: !isNullOrUndefined(initialValues?.weight)
      ? Yup.number()
          .required(t('weight.validation.required'))
          .min(
            PATIENT_MIN_WEIGHT_LIMIT,
            t('weight.validation.minValue', { min: PATIENT_MIN_WEIGHT_LIMIT }),
          )
          .max(
            PATIENT_MAX_WEIGHT_LIMIT,
            t('weight.validation.maxValue', { max: PATIENT_MAX_WEIGHT_LIMIT }),
          )
      : Yup.number()
          .nullable()
          .min(
            PATIENT_MIN_WEIGHT_LIMIT,
            t('weight.validation.minValue', { min: PATIENT_MIN_WEIGHT_LIMIT }),
          )
          .max(
            PATIENT_MAX_WEIGHT_LIMIT,
            t('weight.validation.maxValue', { max: PATIENT_MAX_WEIGHT_LIMIT }),
          ),
    height: !isNullOrUndefined(initialValues?.height)
      ? Yup.number()
          .required(t('height.validation.required'))
          .min(
            PATIENT_MIN_HEIGHT_LIMIT,
            t('height.validation.minValue', { min: PATIENT_MIN_HEIGHT_LIMIT }),
          )
          .max(
            PATIENT_MAX_HEIGHT_LIMIT,
            t('height.validation.maxValue', { max: PATIENT_MAX_HEIGHT_LIMIT }),
          )
      : Yup.number()
          .nullable()
          .min(
            PATIENT_MIN_HEIGHT_LIMIT,
            t('height.validation.minValue', { min: PATIENT_MIN_HEIGHT_LIMIT }),
          )
          .max(
            PATIENT_MAX_HEIGHT_LIMIT,
            t('height.validation.maxValue', { max: PATIENT_MAX_HEIGHT_LIMIT }),
          ),
    addressStreet: Yup.string().nullable(),
    addressState: Yup.string().nullable(),
    username: Yup.string().nullable(),
    email: Yup.string().email(t('email.validation.email')).nullable(),
    phone: Yup.string().nullable(),
    contactPersons: Yup.array().of(
      Yup.object({
        fullname: Yup.string().required(t('contactPerson.fullname.validation.required')),
        role: Yup.string().required(t('contactPerson.role.validation.required')),
        email: Yup.string()
          .email(t('contactPerson.email.validation.email'))
          .required(t('contactPerson.email.validation.required')),
        phone: Yup.string().required(t('contactPerson.phone.validation.required')),
      }),
    ),
  });

  const civilStatusSelectOptions: SelectOption[] = Object.entries(CivilStatusEnum).map((x) => ({
    value: x[1],
    label: tCommon(`civilStatus.${x[1]}` as const),
  }));

  return (
    <EntityForm<PatientFormValues, TResponseData>
      initialValues={initialValues || defaultFormValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      onSubmitCompleted={onSubmitCompleted}
    >
      <Box className={classes.formRow}>
        <TextInput id="firstName" label={t('firstName.label')} required={true} />
        <TextInput id="lastName" label={t('lastName.label')} required={true} />
      </Box>
      <Box className={classes.formRow}>
        <SexSelectInput id="sex" label={t('sex.label')} required={true} />
        <DatePicker id="birthday" label={t('birthday.label')} required={true} />
      </Box>
      <Box className={classes.formRow}>
        <TextInput id="email" label={t('email.label')} type="text" />
        <TextInput id="phone" label={t('phone.label')} type="text" />
      </Box>
      <Box>
        <Typography variant="h6" component={'div'} className={classes.bold}>
          {t('departments.title')}
        </Typography>
        <DepartmentInput id="departmentIds" departments={departments} required={true} />
        <DepartmentRoomInput departments={departments} label={'Room'} id="roomId" />
        <Divider classes={{ root: classes.dividerMargin }} />
      </Box>
      <Box className={classes.formRow}>
        <Box display="flex" width="100%">
          <Box display="flex" width="40%" mr={1}>
            <SelectInput
              id="identifierTypeId"
              label={t('identifierType.label')}
              options={identifierTypes.map((x) => {
                return { value: x.id, label: x.type };
              })}
              withNoneOption={true}
            />
          </Box>
          <Box display="flex" width="60%" ml={1}>
            <TextInput id="identificationNumber" label={t('identificationNumber.label')} />
          </Box>
        </Box>
        <SelectInput
          id="civilStatus"
          label={t('civilStatus.label')}
          options={civilStatusSelectOptions}
          withNoneOption={true}
        />
      </Box>
      <Box className={classes.formRow}>
        <TextInput id="addressStreet" label={t('addressStreet.label')} />
        <TextInput id="addressState" label={t('addressState.label')} />
      </Box>
      <Box className={classes.formRow}>
        <NumberInput
          id="weight"
          label={t('weight.label')}
          endAdornment={weightUnit}
          maxDecimalNumbersCount={2}
        />
        <NumberInput
          id="height"
          label={t('height.label')}
          endAdornment={heightUnit}
          maxDecimalNumbersCount={2}
        />
      </Box>
      <Box mb={2} mt={1}>
        <Divider />
      </Box>
      <Typography variant="h6" component={'div'}>
        {t('contactPerson.title.list')}
      </Typography>
      <ContactPersonsInput id="contactPersons" />
      <Box marginTop={2} display="flex" justifyContent="flex-end">
        <FormButton />
      </Box>
    </EntityForm>
  );
}
