import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import { ProgressIndicator } from '../../components/ProgressIndicator';
import { Organization } from '../../models';
import { Page } from '../../components/Page';
import { useTranslation } from 'react-i18next';
import { FunctionalityProvider } from '../../context/functionality';
import { OrganizationsList } from './OrganizationsList';
import { PageTopBar } from '../../components/PageTopBar';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import React, { useEffect, useState } from 'react';
import { isSubstring } from '../../utils';
import { useEntityActionSnackbar } from '../../hooks';

export function ListOrganizationsPage() {
  const { enqueueEntityDeletedSnackbar } = useEntityActionSnackbar();
  const { t } = useTranslation('organization');
  const [
    { isLoading: isOrganizationsLoading, isError, data: organizations },
    { refetch: refetchOrganizations },
    { lastFetchDatetime },
  ] = useGetApi<Organization[]>(ApiRoutes.Organizations);

  const [filteredOrganizations, setFilteredOrganizations] = useState(organizations);

  useEffect(() => {
    setFilteredOrganizations(organizations);
  }, [organizations]);

  async function handleDelete(id: number) {
    await makeApiRequest('DELETE', ApiRoutes.Organization(id));
  }

  async function handleDeleteCompleted(organizationMedic: Organization) {
    await refetchOrganizations();

    enqueueEntityDeletedSnackbar(t('organization', { context: organizationMedic.name }), {
      context: organizationMedic.name,
    });
  }

  function handleSearch(searchedText: string) {
    if (!organizations) {
      setFilteredOrganizations([]);
    } else {
      if (searchedText === '') {
        setFilteredOrganizations(organizations);
      } else {
        const searchedTextToLower = searchedText.toLowerCase();
        const searchedOrganizations = organizations.filter((organization) => {
          const { name } = organization;
          return isSubstring(name, searchedTextToLower);
        });
        setFilteredOrganizations(searchedOrganizations);
      }
    }
  }

  if (isOrganizationsLoading) {
    return <ProgressIndicator />;
  }

  return (
    <FunctionalityProvider
      createFunctionality="ORGANIZATIONS_CREATE"
      readFunctionality="ORGANIZATIONS_READ"
      updateFunctionality="ORGANIZATIONS_UPDATE"
      deleteFunctionality="ORGANIZATIONS_DELETE"
    >
      <>
        <StickyTopAppBar>
          <PageTopBar
            title={t('title.list')}
            addNewRedirectPath={'/organizations/new'}
            newRecordDescriptionText={t('title.new')}
            onSearch={handleSearch}
            lastUpdated={lastFetchDatetime}
          />
        </StickyTopAppBar>
        <Page fullWidth={true} noPaper={true} requireFunctionality="ORGANIZATIONS_READ">
          {!isOrganizationsLoading && !isError && (
            <OrganizationsList
              organizations={filteredOrganizations}
              onDelete={handleDelete}
              onDeleteCompleted={handleDeleteCompleted}
            />
          )}
        </Page>
      </>
    </FunctionalityProvider>
  );
}
