import { ReactNode, useState } from 'react';
import { SelectedQuestionIndexContext } from '.';
import { usePrevious } from '../../hooks';

interface Props {
  children: ReactNode;
}

export function SelectedQuestionIndexProvider({ children }: Props) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isQuestionMoving, setIsQuestionMoving] = useState(false);
  const previousSelectedIndex = usePrevious(selectedIndex);

  return (
    <SelectedQuestionIndexContext.Provider
      value={{
        selectedIndex,
        previousSelectedIndex,
        setSelectedIndex,
        isQuestionMoving,
        setIsQuestionMoving,
      }}
    >
      {children}
    </SelectedQuestionIndexContext.Provider>
  );
}
