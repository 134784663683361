import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  purposeButton: {
    fontWeight: 900,
    color: '#888',
    '& .MuiButton-label': {
      border: `${theme.typography.pxToRem(1)} solid #E5E5E5`,
      borderRadius: theme.typography.pxToRem(70),
    },
  },
  purposeButtonNotPaired: {
    fontWeight: 'bold',
    color: '#DDDDDD',
    '& .MuiButton-label': {
      border: `${theme.typography.pxToRem(1)} solid #E5E5E5`,
      borderRadius: theme.typography.pxToRem(70),
    },
  },
}));
