import { Button } from '@mui/material';
import { useUnpairButtonStyles } from './styles';

interface Props {
  onClick: () => void;
  disabled?: boolean;
  children: React.ReactNode;
}

export function UnpairButton({ onClick, disabled, children }: Props) {
  const classes = useUnpairButtonStyles();
  return (
    <Button
      variant="outlined"
      color="primary"
      disableElevation={true}
      onClick={onClick}
      disabled={disabled}
      className={classes.unpairButton}
    >
      {children}
    </Button>
  );
}
