import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  searchWrapper: {
    minWidth: 300,
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      margin: theme.spacing(2, 0, 3),
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      maxWidth: '100%',
      width: '100%',
    },
  },
  searchTextFieldRoot: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1.5, 1, 1.5, 3),
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      caretColor: theme.palette.primary.main,
      color: theme.palette.secondary['100'],
      fontFamily: 'Inter',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.29,
      letterSpacing: 0.4,
      boxShadow:
        '0 0 1px 0 rgba(0, 0, 0, 0.04), 0 2px 6px 0 rgba(0, 0, 0, 0.04), 0 16px 24px 0 rgba(0, 0, 0, 0.06)',
      '& .MuiInputBase-input': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
  },
  searchTextFieldRootDesktop: {
    '& .MuiInputBase-input': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: '#0f2e8a',
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.38,
      letterSpacing: 0.3,
      padding: theme.spacing(1.5),
    },
  },
  removeSearchTextButton: {
    padding: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
}));
