import { AlarmTypeName } from '../../models';
import React from 'react';
import { mapAlarmTypeNameToIcon } from '../../utils';

interface Props {
  alarmType: AlarmTypeName;
  isActive?: boolean;
}

export const AlarmCheckBoxIcon = React.memo(function AlarmCheckBoxIconComponent({
  alarmType,
  isActive = false,
}: Props) {
  return mapAlarmTypeNameToIcon(alarmType, isActive);
});
