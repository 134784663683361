import { createContext, useContext } from 'react';
import { ThresholdsContextType } from './types';

export const ThresholdsContext = createContext<ThresholdsContextType>({
  selectedTextField: 'default',
  setSelectedTextField: () => {},
  hoveringOverTextField: null,
  setHoveringOverTextField: () => {},
  defaultSelectedTextField: 'maxError',
  setDefaultSelectedTextField: () => {},
  hoveringOverButton: { type: null, action: null },
  setHoveringOverButton: () => {},
  removeHoveringOverButton: () => {},
});
export const useThresholdsContext = () => useContext(ThresholdsContext);
