import { ExportRowData } from '../TableRow';
import { StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import { TableHeaderCell } from '../TableHeaderCell';

interface Props {
  data: ExportRowData[];
}

export function TableHeaderRow({ data }: Props) {
  const oneColDynamicWidth = 100 / data.length;
  const styles = StyleSheet.create({
    row: {
      flexDirection: 'row',
      alignItems: 'stretch',
    },
    valueCol: {
      width: `${oneColDynamicWidth}%`,
    },
  });

  return (
    <View style={styles.row} wrap={false} fixed={true}>
      {data.map((data, idx) => (
        <View style={styles.valueCol} key={idx}>
          <TableHeaderCell style={data.style}>{data.value}</TableHeaderCell>
        </View>
      ))}
    </View>
  );
}
