import { useParams } from 'react-router-dom';
import { ProgressIndicator } from '../../../components/ProgressIndicator';
import { ApiRoutes, useGetApi } from '../../../services/api';
import { Device } from '../../../models';
import { useTranslation } from 'react-i18next';

import React from 'react';
import { Box } from '@mui/material';
import { OrganizationDeviceGrid } from '../../../components/Grids/OrganizationDeviceGrid';

export function OrganizationDevicePage() {
  const { t } = useTranslation('device');
  const { id } = useParams<{ id: string }>();
  const [{ data: devices, isLoading: isDeviceLoading }] = useGetApi<Device[]>(
    ApiRoutes.OrganizationDevice(id as string),
  );

  if (isDeviceLoading) {
    return <ProgressIndicator />;
  }

  if (devices == null) {
    return <div>{t('error.deviceNotFound')}</div>;
  }
  //<DataGrid columns={columns} rows={devices} classes={{ root: classes.grid }}
  return (
    <div>
      <Box mt={4}>{devices && <OrganizationDeviceGrid data={devices} />}</Box>
    </div>
  );
}
