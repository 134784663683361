import { ReactNode, useEffect, useState } from 'react';
import { SelectedMeasurementContext } from '.';
import { useNavigate, useLocation } from 'react-router-dom';
import { URL_SELECTED_MEASUREMENT } from '../../constants';
import qs from 'query-string';
import { areUrlSearchParamsEqual, isNumber } from '../../utils';

interface Props {
  children: ReactNode;
}

export function SelectedMeasurementContextProvider({ children }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMeasurement, setSelectedMeasurement] = useState<number | undefined>(undefined);

  useEffect(() => {
    const searchQuery = qs.parse(qs.pick(location.search, [URL_SELECTED_MEASUREMENT]), {
      arrayFormat: 'comma',
      parseNumbers: true,
    });
    // get SELECTED_MEASUREMENT from URL
    if (URL_SELECTED_MEASUREMENT in searchQuery) {
      const selectedQuery = searchQuery[URL_SELECTED_MEASUREMENT];
      if (isNumber(selectedQuery) && selectedMeasurement != Number(selectedQuery)) {
        setSelectedMeasurement(Number(selectedQuery));
      }
    } else {
      setSelectedMeasurement(undefined);
    }
  }, [location.search]);

  function handleSelectedChange(selectedId: number | undefined) {
    if (selectedMeasurement != selectedId) {
      const searchQuery = qs.parse(location.search, { arrayFormat: 'comma' });
      searchQuery[URL_SELECTED_MEASUREMENT] = null;

      if (typeof selectedId !== 'undefined') {
        searchQuery[URL_SELECTED_MEASUREMENT] = String(selectedId);
      }

      const currentSortQuery = qs.parse(qs.pick(location.search, [URL_SELECTED_MEASUREMENT]), {
        arrayFormat: 'comma',
      });

      // change url only if it changes
      if (
        !areUrlSearchParamsEqual(
          currentSortQuery[URL_SELECTED_MEASUREMENT],
          searchQuery[URL_SELECTED_MEASUREMENT],
        )
      ) {
        navigate(
          {
            pathname: location.pathname,
            search: `?${qs.stringify(searchQuery, { arrayFormat: 'comma', skipNull: true })}`,
          },
          { replace: true },
        );
      }
    }
  }

  return (
    <SelectedMeasurementContext.Provider
      value={{
        selectedMeasurement: selectedMeasurement,
        setSelectedMeasurement: handleSelectedChange,
      }}
    >
      {children}
    </SelectedMeasurementContext.Provider>
  );
}
