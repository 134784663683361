import navigation from './navigation.json';
import common from './common.json';
import login from './login.json';
import medic from './medic.json';
import note from './note.json';
import questionnaire from './questionnaire.json';
import patient from './patient.json';
import alarmGroup from './alarmGroup.json';
import dailyPlanTemplate from './dailyPlanTemplate.json';
import alarm from './alarm.json';
import measurement from './measurement.json';
import error from './error.json';
import cookie from './cookie.json';
import grid from './grid.json';
import resetPassword from './resetPassword.json';
import systemEvent from './systemEvent.json';
import dashboard from './dashboard.json';
import thresholds from './thresholds.json';
import adminDashboard from './adminDashboard.json';
import hub from './hub.json';
import profile from './profile.json';
import snackbar from './snackbar.json';
import report from './report.json';
import patientDevicePairing from './patientDevicePairing.json';
import forgottenPassword from './forgottenPassword.json';
import notFound from './notFound.json';

export const sq = {
  navigation,
  login,
  common,
  medic,
  note,
  questionnaire,
  patient,
  alarmGroup,
  dailyPlanTemplate,
  alarm,
  measurement,
  error,
  grid,
  resetPassword,
  cookie,
  systemEvent,
  dashboard,
  thresholds,
  adminDashboard,
  hub,
  profile,
  snackbar,
  report,
  patientDevicePairing,
  forgottenPassword,
  notFound,
};
