import { DatePicker as MUIDatePicker, TimePicker as MUITimePicker } from '@mui/x-date-pickers';
import { useField, useFormikContext } from 'formik';
import { getInputErrorText } from '../InputErrorText';
import { useFormikDisableContext } from '../../context/formikDisable';
import { isValid } from 'date-fns';
import React, { ReactNode, useState } from 'react';
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

type DateTimePickerMode = 'date' | 'time';

export interface DatePickerProps {
  id: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  mode?: DateTimePickerMode;
  showErrorMessage?: boolean;
  setHoursAndMinutesToZeroInUtc?: boolean;
  keyboardIcon?: ReactNode;
}

export function DatePicker({
  id,
  label,
  required,
  disabled,
  mode = 'date',
  setHoursAndMinutesToZeroInUtc = false,
  showErrorMessage = true,
  keyboardIcon,
}: DatePickerProps) {
  const [{ onChange: _, value, ...field }, meta] = useField<Date>(id);
  const { isSubmitting, setFieldValue, setFieldTouched } = useFormikContext();
  const { isDisabled } = useFormikDisableContext();
  const [open, setOpen] = useState(false);

  const errorMessage = meta.touched ? meta.error : null;
  const inputValue = value || null;

  function handleChange(value: Date | null) {
    if (value && isValid(value) && setHoursAndMinutesToZeroInUtc) {
      value = new Date(value.setUTCHours(0, 0, 0, 0));
    }

    setFieldValue(id, value);
    setFieldTouched(id, true);
  }

  const isComponentDisabled = disabled || isDisabled || isSubmitting;

  const PickerComponent = mode === 'date' ? MUIDatePicker : MUITimePicker;
  const format = mode === 'date' ? 'dd/MM/yyyy' : 'HH:mm';

  return (
    <PickerComponent
      {...field}
      value={new Date(inputValue)}
      onChange={(value: Date | null) => handleChange(value)}
      onClose={() => setOpen(false)}
      open={open}
      slots={{ textField: TextField }}
      slotProps={{
        textField: {
          id: id,
          label: label,
          margin: 'normal',
          error: !!errorMessage,
          helperText: showErrorMessage && getInputErrorText(errorMessage),
          required: required,
          fullWidth: true,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton disabled={isComponentDisabled} onClick={() => setOpen(true)}>
                  {keyboardIcon || <CalendarTodayIcon />}
                </IconButton>
              </InputAdornment>
            ),
            style: {
              pointerEvents: isComponentDisabled ? 'none' : 'auto',
            },
          },
          disabled: isComponentDisabled,
        },
      }}
      format={format}
    />
  );
}
