import { GridCellParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CalculatedThresholds,
  Measurands,
  MeasurandTypes,
  MeasurementValueWithUnit,
  WebMeasurement,
} from '../../../models';
import { Grid, GridColumnDefinition } from '../../Grid';
import {
  areNumbers,
  areStrings,
  ClassNameAndPriority,
  getClassNameAndPriorityByThresholds,
  getMeasurementGridColumnsWidth,
  getStyleByThresholds,
} from '../../../utils';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useSelectedMeasurementContext } from '../../../context/selectedMeasurement';
import { CustomTooltip } from '../../../components/CustomTooltip';
import { DATE_TIME_LOCALE_WITHOUT_SECONDS } from '../../../constants';

interface Props {
  data: WebMeasurement[];
}

export function MeasurementsGrid({ data }: Props) {
  const { t } = useTranslation('measurement');
  const { selectedMeasurement, setSelectedMeasurement } = useSelectedMeasurementContext();
  const [columns, setColumns] = useState<GridColumnDefinition<WebMeasurement>[]>([]);
  const [uniqKeys, setUniqKeys] = useState<Array<keyof WebMeasurement>>([]);
  const theme = useTheme();
  const downSmMatches = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const cols: GridColumnDefinition<WebMeasurement>[] = [];

    // get all unique keys from all array items
    const uniqueKeys = Object.keys(
      data.reduce(function (result, obj) {
        return Object.assign(result, obj);
      }, {}),
    ).filter((key) => key !== 'id') as Array<keyof WebMeasurement>;
    setUniqKeys(uniqueKeys);

    const oneColLength = 1 / uniqueKeys.length;
    const [measurementValueWidth, dateTimeWidth] = getMeasurementGridColumnsWidth(
      downSmMatches,
      oneColLength,
    );

    const measurandsWithUnit: Measurands = {};

    if (data.length > 0) {
      Object.entries(data[0]).forEach(([key, value]) => {
        if (key != 'id' && key != 'measuredOnDatetime') {
          const measurandKey = key as keyof MeasurandTypes;
          const measurandValue = value as MeasurementValueWithUnit;
          measurandsWithUnit[measurandKey] = {
            value: measurandValue.value,
            unit: measurandValue.unit,
          };
        }
      });
    }
    uniqueKeys.forEach((key) => {
      switch (key) {
        case 'id':
          cols.push({
            field: 'id',
            headerAlign: 'center',
            align: 'center',
            headerName: t('id.label'),
            width: 0,
          });
          break;
        case 'measuredOnDatetime':
          cols.push({
            field: 'measuredOnDatetime',
            headerName: t('measuredOnDatetime.label'),
            headerAlign: 'center',
            align: 'center',
            ...dateTimeWidth,
            renderCell: (params: GridCellParams) => (
              <span>
                {new Date(params.value as string).toLocaleString(
                  [],
                  DATE_TIME_LOCALE_WITHOUT_SECONDS,
                )}
              </span>
            ),
          });
          break;
        case 'measuredByName':
          cols.push({
            field: 'measuredByName',
            headerName: t('measuredByName'),
            headerAlign: 'center',
            align: 'center',
            ...dateTimeWidth,
            renderCell: (params: GridCellParams) => (
              <CustomTooltip title={params.value as string}>
                <span>{params.value as string}</span>
              </CustomTooltip>
            ),
          });
          break;
        default: {
          cols.push({
            field: key,
            ...measurementValueWidth,
            headerAlign: 'center',
            align: 'center',
            headerName: `${t(`${key}` as const)}${
              measurandsWithUnit[key]?.unit ? ` (${measurandsWithUnit[key]?.unit})` : ''
            }`,
            sortComparator: (v1, v2) => {
              const v1ValueWithUnit = v1 as MeasurementValueWithUnit;
              const v2ValueWithUnit = v2 as MeasurementValueWithUnit;
              if (areNumbers(v1ValueWithUnit.value, v2ValueWithUnit.value)) {
                return Number(v2ValueWithUnit.value) - Number(v1ValueWithUnit.value);
              } else if (areStrings(v1ValueWithUnit.value, v2ValueWithUnit.value)) {
                return String(v2ValueWithUnit.value).localeCompare(String(v1ValueWithUnit.value));
              }
              return 0;
            },
            renderCell: (params) => {
              const measurandType = params.field as keyof MeasurandTypes;
              const measurement = params.value as MeasurementValueWithUnit;
              const measurementThresholds = params.row[measurandType] as CalculatedThresholds;
              return (
                <Box
                  width="100%"
                  maxWidth="130px"
                  {...getStyleByThresholds(
                    measurementThresholds,
                    Number(params.id) === selectedMeasurement,
                  )}
                >
                  {measurement?.value}
                </Box>
              );
            },
          });
        }
      }
    });

    setColumns(cols);
  }, [data, selectedMeasurement]);

  function compareByPriorities(a: ClassNameAndPriority, b: ClassNameAndPriority) {
    return a.priority - b.priority;
  }

  return (
    <Grid
      columns={columns}
      rows={data}
      onRowClick={({ id }) => {
        setSelectedMeasurement(id);
      }}
      getRowClassName={(params: WebMeasurement) => {
        const measurandKeys: Array<keyof WebMeasurement> = uniqKeys.filter(
          (x) => x != 'id' && x != 'measuredOnDatetime',
        );

        const thresholds: ClassNameAndPriority[] = [];

        for (const key of measurandKeys) {
          const currentClass = getClassNameAndPriorityByThresholds(
            params[key as keyof WebMeasurement] as CalculatedThresholds,
          );

          if (currentClass) {
            thresholds.push(currentClass);
          }
        }

        thresholds.sort(compareByPriorities);

        if (thresholds.length > 0) {
          return thresholds[0].class;
        }

        if (selectedMeasurement == params.id) {
          return 'customSelected';
        }

        return '';
      }}
    />
  );
}
