import { Organization } from '../../models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTopBarDetailed } from '../PageTopBarDetailed';

interface Props {
  organization: Organization;
  lastUpdated: Date | null;
}

export function OrganizationHeader({ organization, lastUpdated }: Props) {
  const { t } = useTranslation('organization');
  return (
    <PageTopBarDetailed
      entityName={t('title.organization', { name: organization.name, context: organization.ico })}
      lastUpdated={lastUpdated}
    />
  );
}
