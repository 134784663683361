import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  questionTextLine: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(23),
    borderRadius: theme.typography.pxToRem(5),
    boxShadow: `0 ${theme.typography.pxToRem(1)} ${theme.typography.pxToRem(
      5,
    )} rgba(0, 0, 0, 0.15)`,
  },
  questionText: {
    fontWeight: 900,
  },
  questionSampleAnswer: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(23),
    color: '#666',
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  questionAnswerOption: {
    boxShadow: `0 ${theme.typography.pxToRem(1)} ${theme.typography.pxToRem(3)} rgba(0, 0, 0, 0.1)`,
    borderRadius: theme.typography.pxToRem(15),
    padding: theme.spacing(0.5, 1),
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(23),
    color: '#666',
  },
  questionAnswerOptionNotInitial: {
    color: '#DADADA',
  },
}));
