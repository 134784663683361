import { Functionality } from '../../models';

export const PATIENT_ALARMS_READ_POLICY: Functionality[] = [
  'PATIENT_ALARMS_READ',
  'PATIENT_ASSIGNED_TO_MEDIC_ALARMS_READ',
];

export const PATIENT_ALARMS_CREATE_POLICY: Functionality[] = [
  'PATIENT_ALARMS_CREATE',
  'PATIENT_ASSIGNED_TO_MEDIC_ALARMS_CREATE',
];

export const PATIENT_ALARMS_UPDATE_POLICY: Functionality[] = [
  'PATIENT_ALARMS_UPDATE',
  'PATIENT_ASSIGNED_TO_MEDIC_ALARMS_UPDATE',
];

export const PATIENT_ALARMS_DELETE_POLICY: Functionality[] = [
  'PATIENT_ALARMS_DELETE',
  'PATIENT_ASSIGNED_TO_MEDIC_ALARMS_DELETE',
];
