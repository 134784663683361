import { PatientNavigation } from './PatientNavigation';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgressIndicator } from '../../components/ProgressIndicator';
import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import { PatientFormValues } from './PatientForm';
import {
  Department,
  Device,
  IdentifierType,
  MeasuringDeviceWithType,
  Patient,
  PatientEditInput,
  PatientUpdateResult,
  UnpairPatientInput,
} from '../../models';
import { Page } from '../../components/Page';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import { PatientHeader } from '../../components/PatientHeader';
import { isNullOrUndefined } from '../../utils';
import { useEntityActionSnackbar } from '../../hooks';
import { PATIENTS_READ_POLICY } from '../../constants/Policies';

export function PatientPage() {
  const { t } = useTranslation(['patient', 'common', 'snackbar']);
  const { enqueueSnackbar, enqueueEntityUpdatedSnackbar } = useEntityActionSnackbar();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [
    { data: patient, isLoading: isPatientLoading },
    { refetch: refetchPatient },
    { lastFetchDatetime: patientFetchDatetime },
  ] = useGetApi<Patient>(ApiRoutes.Patient(id as string));

  const [{ data: identifierTypes, isLoading: areIdentifierTypesLoading }] = useGetApi<
    IdentifierType[]
  >(ApiRoutes.IdentifierTypes);

  const [{ data: departments, isLoading: aredepartmentsLoading }] = useGetApi<Department[]>(
    ApiRoutes.Departments,
  );

  const [{ data: measuringDevices, isLoading: areMeasuringDevicesLoading }] = useGetApi<
    MeasuringDeviceWithType[]
  >(ApiRoutes.MeasuringDevices);

  const [{ data: devices }] = useGetApi<Device[]>(ApiRoutes.Devices);

  function handleSubmit(values: PatientFormValues) {
    const input: PatientEditInput = {
      ...values,
      email: values.email || null,
      weight: !isNullOrUndefined(values.weight) ? values.weight : null,
      height: !isNullOrUndefined(values.height) ? values.height : null,
    };

    return makeApiRequest<PatientUpdateResult, PatientEditInput>(
      'PUT',
      ApiRoutes.Patient(id as string),
      input,
    );
  }

  async function handleSubmitCompleted(data?: PatientUpdateResult) {
    await refetchPatient();
    navigate(`/patients/${id}`);

    enqueueEntityUpdatedSnackbar(t('patient:patient', { context: data?.sex }), {
      context: data?.sex,
    });
  }

  async function handleUnpair(conclusion: string) {
    await makeApiRequest<never, UnpairPatientInput>('POST', ApiRoutes.UnpairPatient, {
      patientId: parseInt(id as string),
      conclusion: conclusion,
    });
  }

  async function handleUnpairCompleted() {
    await refetchPatient();
    enqueueSnackbar(t('patient:unpairCompleted', { context: patient?.sex }));
  }

  async function handlePairCompleted() {
    await refetchPatient();
    enqueueSnackbar(t('patient:pairCompleted', { context: patient?.sex }));
  }

  async function handleArchiveCompleted() {
    navigate('/patients');

    enqueueSnackbar(
      t('snackbar:archived', {
        context: patient?.sex,
        entity: t('patient:patient', { context: patient?.sex }),
      }),
    );
  }

  if (
    isPatientLoading ||
    areIdentifierTypesLoading ||
    areMeasuringDevicesLoading ||
    aredepartmentsLoading
  ) {
    return <ProgressIndicator />;
  }

  if (patient == null) {
    return <div>{t('patient:error.patientNotFound')}</div>;
  }

  if (identifierTypes == null) {
    return <div>{t('patient:error.identifierTypesNotFound')}</div>;
  }

  if (measuringDevices == null) {
    return <div>{t('common:error.measuringDevicesNotFound')}</div>;
  }

  if (departments == null) {
    return <div>{t('patient:error.departmentsNotFound')}</div>;
  }

  if (devices == null) {
    return <div>{t('patient:device_not_found')}</div>;
  }

  return (
    <>
      <StickyTopAppBar>
        <PatientHeader
          patient={patient}
          devices={devices}
          onPairDone={handlePairCompleted}
          onUnpair={handleUnpair}
          onUnpairCompleted={handleUnpairCompleted}
          onPatientArchiveCompleted={handleArchiveCompleted}
          lastUpdated={patientFetchDatetime}
        />
      </StickyTopAppBar>
      <Page noPaper={true} fullWidth={false} requireFunctionality={PATIENTS_READ_POLICY}>
        <PatientNavigation
          id={id as string}
          patient={patient}
          patientFetchDatetime={patientFetchDatetime}
          onSubmit={handleSubmit}
          onSubmitCompleted={handleSubmitCompleted}
          identifierTypes={identifierTypes}
          departments={departments}
          measuringDevices={measuringDevices}
        />
      </Page>
    </>
  );
}
