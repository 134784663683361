import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  unansweredQuestionnaireRow: {
    color: '#B5B5B5',
  },
  statusButton: {
    '& .MuiButton-label': {
      border: '1px solid #E5E5E5',
      borderRadius: theme.typography.pxToRem(70),
      paddingRight: theme.typography.pxToRem(20),
    },
  },
}));
