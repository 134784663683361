import { useSnackbar as useSnackbarExternal } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Optional } from '../models';

interface Result {
  enqueueSnackbar: (message: string) => void;
  enqueueWarningSnackbar: (message: string) => void;
  enqueueErrorSnackbar: (message: string) => void;
  enqueueEntityCreatedSnackbar: (entityName: string, options?: SnackbarOptions) => void;
  enqueueEntityUpdatedSnackbar: (entityName: string, options?: SnackbarOptions) => void;
  enqueueEntityDeletedSnackbar: (entityName: string, options?: SnackbarOptions) => void;
  enqueueEntityAssignedSnackbar: (entityName: string, options?: SnackbarOptions) => void;
  enqueueEntityArchivedSnackbar: (entityName: string, options?: SnackbarOptions) => void;
}

interface SnackbarOptions {
  context?: Optional<string>;
}

export function useEntityActionSnackbar(): Result {
  const { t } = useTranslation('snackbar');
  const { enqueueSnackbar } = useSnackbarExternal();

  function enqueueWarningSnackbar(message: string) {
    enqueueSnackbar(message, {
      variant: 'warning',
    });
  }

  function enqueueErrorSnackbar(message: string) {
    enqueueSnackbar(message, {
      variant: 'error',
    });
  }

  function enqueueEntityCreatedSnackbar(entityName: string, options?: SnackbarOptions) {
    enqueueSnackbar(t('created', { entity: entityName, context: options?.context }));
  }

  function enqueueEntityUpdatedSnackbar(entityName: string, options?: SnackbarOptions) {
    enqueueSnackbar(t('updated', { entity: entityName, context: options?.context }));
  }

  function enqueueEntityDeletedSnackbar(entityName: string, options?: SnackbarOptions) {
    enqueueSnackbar(t('deleted', { entity: entityName, context: options?.context }));
  }

  function enqueueEntityAssignedSnackbar(entityName: string, options?: SnackbarOptions) {
    enqueueSnackbar(t('assigned', { entity: entityName, context: options?.context }));
  }

  function enqueueEntityArchivedSnackbar(entityName: string, options?: SnackbarOptions) {
    enqueueSnackbar(t('assigned', { entity: entityName, context: options?.context }));
  }

  return {
    enqueueSnackbar,
    enqueueWarningSnackbar,
    enqueueErrorSnackbar,
    enqueueEntityCreatedSnackbar,
    enqueueEntityUpdatedSnackbar,
    enqueueEntityDeletedSnackbar,
    enqueueEntityAssignedSnackbar,
    enqueueEntityArchivedSnackbar,
  };
}
