import { useNavigate } from 'react-router-dom';
import { ProgressIndicator } from '../../components/ProgressIndicator';
import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import { Department, DeviceCreateOrEditInput, DeviceCreateResult } from '../../models';
import { DeviceForm, DeviceFormValues } from './DeviceForm';
import { Page } from '../../components/Page';
import { useTranslation } from 'react-i18next';
import { FunctionalityProvider } from '../../context/functionality';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import { PageTopBar } from '../../components/PageTopBar';
import React from 'react';
import { Box } from '@mui/material';
import { useEntityActionSnackbar } from '../../hooks';

export function CreateDevicePage() {
  const { t } = useTranslation('device');
  const navigate = useNavigate();
  const { enqueueEntityCreatedSnackbar } = useEntityActionSnackbar();
  const [{ data: departments, isLoading: areDepartmentsLoading }] = useGetApi<Department[]>(
    ApiRoutes.Departments,
  );

  async function handleSubmit(values: DeviceFormValues) {
    const input: DeviceCreateOrEditInput = {
      ...values,
    };
    return makeApiRequest<DeviceCreateResult, DeviceCreateOrEditInput>(
      'POST',
      ApiRoutes.Devices,
      input,
    );
  }

  async function handleSubmitCompleted(data?: DeviceCreateResult) {
    if (!data) {
      navigate(-1);

      return;
    }

    navigate(`/devices/${data.id}`, { replace: true });

    enqueueEntityCreatedSnackbar(t('device', { context: data?.chassisId }), {
      context: data?.chassisId,
    });
  }

  if (areDepartmentsLoading) {
    return <ProgressIndicator />;
  }

  if (departments == null) {
    return <div>{t('error.departmentNotFound')}</div>;
  }

  return (
    <FunctionalityProvider
      createFunctionality="DEVICES_CREATE"
      readFunctionality="DEVICES_READ"
      updateFunctionality="DEVICES_UPDATE"
      deleteFunctionality="DEVICES_DELETE"
    >
      <>
        <StickyTopAppBar>
          <PageTopBar title={t('title.new')} hideLastUpdated={true} />
        </StickyTopAppBar>
        <Box mt={2}>
          <Page requireFunctionality="DEVICES_READ">
            <DeviceForm<DeviceCreateResult>
              onSubmit={handleSubmit}
              onSubmitCompleted={handleSubmitCompleted}
              departments={departments}
            />
          </Page>
        </Box>
      </>
    </FunctionalityProvider>
  );
}
