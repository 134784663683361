import React from 'react';
import { useRequireFunctionality } from '../../hooks';
import { Functionality } from '../../models';

export interface RequireFunctionalityProps {
  functionality?: Functionality | Functionality[];
}

interface Props extends RequireFunctionalityProps {
  children: React.ReactNode;
}

export function RequireFunctionality({ children, functionality }: Props): JSX.Element | null {
  const { hasFunctionality } = useRequireFunctionality(functionality);

  if (!hasFunctionality) {
    return null;
  }

  if (!children) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export function userHasFunctionality(functionality: Functionality | Functionality[]) {
  const { hasFunctionality } = useRequireFunctionality(functionality);
  return hasFunctionality;
}
