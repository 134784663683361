import { Typography } from '@mui/material';
import { CustomTooltip } from '../../../components/CustomTooltip';
import {
  SystemEventListSubItemStyleProps,
  useSystemEventListItemEntityPropertyValueStyles,
} from './styles';
import { SystemEventListSubItem } from './SystemEventListSubItem';

interface Props extends SystemEventListSubItemStyleProps {
  propertyName: string;
  propertyValue: string | React.ReactNode;
  showTooltip?: boolean;
}

export function SystemEventListItemEntityPropertyValue({
  propertyValue,
  propertyName,
  showTooltip,
  ...listSubItemStyleProps
}: Props) {
  const classes = useSystemEventListItemEntityPropertyValueStyles();

  function conditionalyWrapWithTooltip(elem: React.ReactElement, value: string) {
    if (!showTooltip) {
      return elem;
    }
    return <CustomTooltip title={value}>{elem}</CustomTooltip>;
  }

  return (
    <SystemEventListSubItem {...listSubItemStyleProps}>
      <Typography color="primary" variant="body2" className={classes.propertyNameTypography}>
        {propertyName + ':'}
      </Typography>
      {typeof propertyValue === 'string'
        ? conditionalyWrapWithTooltip(
            <Typography display="block" color="textPrimary" variant="body2" noWrap={true}>
              {propertyValue}
            </Typography>,
            propertyValue,
          )
        : propertyValue}
    </SystemEventListSubItem>
  );
}
