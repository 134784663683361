import { Box } from '@mui/material';
import React from 'react';
import { useThresholdsContext } from '../../../../context/thresholds';
import { useTranslation } from 'react-i18next';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import WarningIcon from '@mui/icons-material/Warning';
import { useThresholdsInfoMessageStyles } from './styles';

export function ThresholdsInfoMessage() {
  const { hoveringOverButton } = useThresholdsContext();
  const classes = useThresholdsInfoMessageStyles();
  const { t } = useTranslation('thresholds');

  if (!hoveringOverButton.type || !hoveringOverButton.action) {
    return <Box className={`${classes.common} ${classes.placeholder}`}>placeholder</Box>;
  }

  if (hoveringOverButton.action === 'add') {
    return (
      <Box className={`${classes.common} ${classes.addStatus}`}>
        <AssignmentTurnedInIcon />
        <Box>{t(`${hoveringOverButton.type}.statusMessageAdd` as const)}</Box>
      </Box>
    );
  } else if (hoveringOverButton.action === 'delete') {
    return (
      <Box className={`${classes.common} ${classes.deleteStatus}`}>
        <WarningIcon />
        <Box>{t(`${hoveringOverButton.type}.statusMessageDelete` as const)}</Box>
      </Box>
    );
  }

  return <Box className={classes.common}>&nbsp;</Box>;
}
