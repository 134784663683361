import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  formWrapper: {
    maxWidth: theme.typography.pxToRem(350),
    margin: 'auto',
    width: '100%',
    marginBottom: theme.spacing(1),
    boxShadow: `0px ${theme.typography.pxToRem(1)} ${theme.typography.pxToRem(
      4,
    )} rgba(0, 0, 0, 0.25)`,
    borderRadius: theme.typography.pxToRem(10),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      width: '100%',
      maxWidth: 400,
      border: 'unset',
      boxShadow: 'unset',
    },
  },
  iconWrapper: {
    display: 'flex',
    fontSize: theme.typography.pxToRem(75),
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    borderRadius: '50%',
    border: `${theme.typography.pxToRem(2)} solid ${theme.palette.primary.light}`,
    color: theme.palette.primary.main,
    boxShadow: `0px ${theme.typography.pxToRem(1)} ${theme.typography.pxToRem(
      4,
    )} rgba(0, 0, 0, 0.25)`,
  },
  title: {
    color: theme.palette.primary.main,
  },
  subtitle: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(0.5, 0),
  },
  textsWrapper: {
    padding: theme.spacing(1, 1, 0),
  },
}));
