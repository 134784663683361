import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  iconOptWrapper: {
    padding: '0 !important',
  },
  iconWrapper: {
    color: '#828282',
    border: `${theme.typography.pxToRem(1)} solid transparent`,
    backgroundColor: 'inherit',
    padding: theme.spacing(1),
    borderRadius: theme.typography.pxToRem(5),
    '&:hover': {
      backgroundColor: '#FF4961',
      color: 'white',
      border: `${theme.typography.pxToRem(1)} solid #FF4961`,
      boxShadow: `0 ${theme.typography.pxToRem(1)} ${theme.typography.pxToRem(
        3,
      )} 0 #0000001A inset`,
    },
    '&:hover::before': {
      boxShadow: `0 0 0 ${theme.typography.pxToRem(3)} ${theme.palette.grey['200']}`,
      borderRadius: theme.typography.pxToRem(5),
      content: '""',
      position: 'absolute',
      top: -0.5,
      left: -0.5,
      right: -0.5,
      bottom: -0.5,
    },
  },
  iconDefault: {},
  iconSelected: {},
  iconInnerWrapper: {
    color: 'inherit',
    backgroundColor: 'inherit',
    padding: theme.spacing(1),
    borderRadius: theme.typography.pxToRem(5),
  },
}));
