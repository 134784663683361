import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  dividerMargin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  bold: {
    fontWeight: 'bold',
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    columnGap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  notificationCheckboxesWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: theme.typography.pxToRem(20),
    columnGap: theme.typography.pxToRem(40),
    rowGap: theme.typography.pxToRem(10),
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  notificationGroupWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  notificationGroupHeader: {
    fontWeight: 'bold',
  },
}));
