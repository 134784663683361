import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  navigationBox: {
    background: theme.palette.common.white,
    color: theme.palette.primary.A700,
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 'normal',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  headerAndNavigationContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
  },
  navigationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.palette.primary.main}B3 ${theme.palette.grey.A100}`,
    scrollbarGutter: 'always',
    '&::-webkit-scrollbar': {
      width: 3,
      border: `1px solid ${theme.palette.grey.A100}`,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.grey.A100,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `${theme.palette.primary.main}B3`,
      borderRadius: 20,
    },
  },
  divider: {
    backgroundColor: '#eaebed',
  },
}));
