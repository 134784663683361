import React from 'react';
import { Chip } from '../Chip';
import { useStyles } from './styles';
import { GridRowId } from '@mui/x-data-grid';

interface Props {
  rowId?: GridRowId;
  text: string;
  tooltipText?: string;
  wrapperClassName?: string;
  textClassName?: string;
}

export function RepetitionChip({
  rowId,
  text,
  tooltipText,
  wrapperClassName,
  textClassName,
}: Props) {
  const classes = useStyles();
  return (
    <Chip
      id={`row[${rowId}].repetitionInterval`}
      text={text}
      tooltipText={tooltipText}
      wrapperClassName={`${wrapperClassName} ${classes.repetitionChipWrapper}`}
      textClassName={`${textClassName} ${classes.repetitionChipText}`}
      entityType={'repetitionChip'}
    />
  );
}
