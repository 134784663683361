import { Box, Button, Tooltip } from '@mui/material';
import { NumberInput } from '../../../../components/NumberInput';
import React, { useEffect, useState } from 'react';
import { useThresholdInputStyles } from './styles';
import { useField } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { useThresholdsContext } from '../../../../context/thresholds';
import { ThresholdsType } from '../../../../models';
import { useTranslation } from 'react-i18next';
import { useFormikDisableContext } from '../../../../context/formikDisable';

interface Props {
  numberInputId: string;
  isDisabledId: string;
  isThresholdActiveId: string;
  isChangedId: string;
  label: string;
  systemThresholdValue: number | null;
  type: ThresholdsType;
}

export function ThresholdInput({
  numberInputId,
  isThresholdActiveId,
  isDisabledId,
  isChangedId,
  systemThresholdValue,
  label,
  type,
}: Props) {
  const classes = useThresholdInputStyles();
  const {
    selectedTextField,
    setSelectedTextField,
    setHoveringOverTextField,
    setHoveringOverButton,
    setDefaultSelectedTextField,
    removeHoveringOverButton,
  } = useThresholdsContext();
  const { isDisabled: isDisabledByFunctionality } = useFormikDisableContext();
  const { t } = useTranslation('thresholds');
  const [{ value: isThresholdActive }, , { setValue: setIsThresholdActiveValue }] =
    useField(isThresholdActiveId);
  const [{ value: isThresholdDisabled }] = useField(isDisabledId);
  const [, , { setValue: setIsThresholdsChanged }] = useField(isChangedId);
  const [, , { setValue: setNumberInputValue, setTouched: setNumberInputValueTouched }] =
    useField(numberInputId);

  const [isDisabledByThreshold, setIsDisabledByThreshold] = useState(!isThresholdActive);

  const isDisabled = isDisabledByFunctionality || isDisabledByThreshold;

  useEffect(() => {
    setIsDisabledByThreshold(!isThresholdActive);
    if (!isThresholdActive) {
      setNumberInputValue(null);
      setNumberInputValueTouched(true);
    }
  }, [isThresholdActive]);

  const getInputAdornmentClassAndTextByType = () => {
    switch (type) {
      case 'maxError':
      case 'minError':
        return [classes.inputStartAdornmentError, t('error').slice(0, 4).toUpperCase()];
      case 'maxWarning':
      case 'minWarning':
        return [classes.inputStartAdornmentWarning, t('warning').slice(0, 4).toUpperCase()];
    }
  };

  const [inputAdornmentClass, inputAdornmentText] = getInputAdornmentClassAndTextByType();

  const handleDeleteLimit = () => {
    setIsThresholdActiveValue(false);
    setIsThresholdsChanged(true);
    handleNumberInputChange(null);
    setHoveringOverButton({ type: type, action: 'add' });
  };

  // https://github.com/formium/formik/issues/2059
  const handleNumberInputChange = async (value: number | null) => {
    await setNumberInputValue(value);
    setNumberInputValueTouched(true);
  };

  const handleAddLimit = () => {
    setIsThresholdActiveValue(true);
    setIsThresholdsChanged(true);
    handleNumberInputChange(systemThresholdValue);
    setHoveringOverButton({ type: type, action: 'delete' });
  };

  if (isThresholdDisabled) {
    return <></>;
  }

  return (
    <Box className={classes.wrapper}>
      <Box className={`${classes.inputLabel} ${isDisabled ? classes.disabled : ''}`}>{label}:</Box>
      <NumberInput
        id={numberInputId}
        showErrorMessage={false}
        label={''}
        onMouseEnter={() => {
          setHoveringOverTextField(type);
          if (selectedTextField == 'default') {
            setDefaultSelectedTextField(type);
          }
        }}
        onMouseLeave={() => setHoveringOverTextField(null)}
        onSelect={() => setSelectedTextField(type)}
        onBlur={() => {
          setSelectedTextField('default');
        }}
        onChange={() => setIsThresholdsChanged(true)}
        placeholder={'--.--'}
        maxDecimalNumbersCount={2}
        startAdornment={
          <Box
            className={`${classes.inputStartAdornment} ${inputAdornmentClass} ${
              isDisabled ? classes.disabled : ''
            }`}
          >
            {inputAdornmentText}
          </Box>
        }
        className={classes.numberInput}
        disabled={isDisabled}
      />
      {!isDisabledByFunctionality &&
        (isThresholdActive ? (
          <Tooltip
            title={t('deleteLimit.long')}
            aria-label="add"
            placement="right-end"
            enterDelay={400}
          >
            <span className={classes.buttonTooltip}>
              <Button
                className={`${classes.inputButtonCommon} ${classes.deleteLimitButton}`}
                startIcon={<ClearIcon />}
                onClick={() => handleDeleteLimit()}
                onMouseEnter={() => {
                  setHoveringOverButton({ type: type, action: 'delete' });
                  setHoveringOverTextField(type);
                  if (selectedTextField == 'default') {
                    setDefaultSelectedTextField(type);
                  }
                }}
                onMouseLeave={() => {
                  removeHoveringOverButton();
                  setHoveringOverTextField(null);
                }}
              >
                <span className={classes.inputButtonLabelCommon}>{t('deleteLimit.short')}</span>
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Tooltip
            title={t('addLimit.long')}
            aria-label="add"
            placement="right-end"
            enterDelay={400}
          >
            <span className={classes.buttonTooltip}>
              <Button
                className={`${classes.inputButtonCommon} ${classes.addLimitButton}`}
                startIcon={<AddIcon />}
                onClick={() => handleAddLimit()}
                onMouseEnter={() => {
                  setHoveringOverButton({ type: type, action: 'add' });
                  setHoveringOverTextField(type);
                  if (selectedTextField == 'default') {
                    setDefaultSelectedTextField(type);
                  }
                }}
                onMouseLeave={() => {
                  removeHoveringOverButton();
                  setHoveringOverTextField(null);
                }}
              >
                <span className={classes.inputButtonLabelCommon}>{t('addLimit.short')}</span>
              </Button>
            </span>
          </Tooltip>
        ))}
    </Box>
  );
}
