import { StyledMeasurementTypesTabs } from '../StyledTabs';

interface Props {
  value: string;
  children: React.ReactNode;
}

export function MeasurementTypeNavTabs({ value, children }: Props) {
  return (
    <StyledMeasurementTypesTabs value={value} onChange={() => {}}>
      {children}
    </StyledMeasurementTypesTabs>
  );
}
