import React, { ReactNode, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from '@mui/material';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { ApiRoutes, makeApiRequest } from '../../../services/api';
import { useParams } from 'react-router-dom';
import { Optional, SexEnum } from '../../../models';
import { useEntityActionSnackbar, useRequireFunctionality } from '../../../hooks';
import {
  CanCloseMenu,
  PageTopBarDetailedMoreOptionsButton,
} from '../../PageTopBarDetailedMoreOptionsButton';
import { PATIENTS_DELETE_POLICY, PATIENTS_UPDATE_POLICY } from '../../../constants/Policies';

interface Props {
  onPatientArchiveCompleted: () => Promise<void>;
  patientSex: Optional<SexEnum>;
}

export function MoreOptionsButton({ onPatientArchiveCompleted, patientSex }: Props) {
  const classes = useStyles();
  const commonMoreOptionsButtonRef = useRef<CanCloseMenu>(null);
  const { t } = useTranslation(['common', 'patient', 'snackbar', 'error']);
  const { id } = useParams<{ id: string }>();
  const { checkHasFunctionality } = useRequireFunctionality();
  const [isOpen, setIsOpen] = useState(false);
  const { enqueueSnackbar, enqueueErrorSnackbar } = useEntityActionSnackbar();

  function handleMenuClose() {
    commonMoreOptionsButtonRef.current?.closeMenu();
  }

  function handleArchiveClick() {
    setIsOpen(true);
  }

  function handleModalClose() {
    setIsOpen(false);
  }

  const [isArchiving, setIsArchiving] = useState(false);

  const hasDeletePatientFunctionality = checkHasFunctionality(PATIENTS_DELETE_POLICY);
  const hasUpdatePatientFunctionality = checkHasFunctionality(PATIENTS_UPDATE_POLICY);

  async function handleArchive() {
    setIsArchiving(true);
    await makeApiRequest('DELETE', ApiRoutes.Patient(id as string));
    setIsArchiving(false);
    setIsOpen(false);
    if (onPatientArchiveCompleted) {
      await onPatientArchiveCompleted();
    }
  }

  async function handleSendLoginInstructions() {
    const { error } = await makeApiRequest(
      'POST',
      ApiRoutes.PatientSendLoginInstructions(id as string),
    );

    handleMenuClose();

    if (error) {
      if (error.errors && 'email' in error.errors) {
        enqueueErrorSnackbar(t('patient:sendLoginInstructions.errors.emailNotDefined'));
      } else {
        enqueueErrorSnackbar(t('error:generic'));
      }
      return;
    }

    enqueueSnackbar(
      t('snackbar:loginInstructionsSent', {
        context: patientSex,
      }),
    );
  }

  const menuItems: ReactNode[] = [];

  if (hasDeletePatientFunctionality) {
    menuItems.push(
      <MenuItem classes={{ root: classes.menuItemRoot }} onClick={handleArchiveClick} key={0}>
        {t('common:archive')}
      </MenuItem>,
    );
  }

  if (hasUpdatePatientFunctionality) {
    menuItems.push(
      <MenuItem
        classes={{ root: classes.menuItemRoot }}
        onClick={handleSendLoginInstructions}
        key={1}
      >
        {t('patient:sendLoginInstructions.buttonText')}
      </MenuItem>,
    );
  }

  return (
    <>
      <PageTopBarDetailedMoreOptionsButton menuItems={menuItems} ref={commonMoreOptionsButtonRef} />
      {hasDeletePatientFunctionality && isOpen && (
        <Dialog open={isOpen} onClose={handleModalClose}>
          <DialogTitle>
            {t('patient:archiveConfirmation.title', { context: patientSex })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('patient:archiveConfirmation.text', { context: patientSex })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleModalClose}
              disabled={isArchiving}
              variant="outlined"
              className={classes.dialogButton}
            >
              {t('common:cancel')}
            </Button>
            <Button
              onClick={handleArchive}
              variant="contained"
              disableElevation={true}
              className={`${classes.dialogButton} ${classes.archiveButton}`}
              disabled={isArchiving}
            >
              {t('common:archive')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
