import { useTranslation } from 'react-i18next';
import { Page } from '../../components/Page';
import { AdminDashboardNavigation } from './AdminDashboardNavigation';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import { PageTopBar } from '../../components/PageTopBar';
import React from 'react';
import { RequireFunctionality } from '../../components/RequireFunctionality';

export function AdminDashboardPage() {
  const { t } = useTranslation('adminDashboard');

  return (
    <RequireFunctionality functionality="ADMIN_PANEL">
      <>
        <StickyTopAppBar>
          <PageTopBar title={t('title')} hideLastUpdated={true} />
        </StickyTopAppBar>
        <Page noPaper={true}>
          <AdminDashboardNavigation />
        </Page>
      </>
    </RequireFunctionality>
  );
}
