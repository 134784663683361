import React from 'react';
import { Box } from '@mui/material';
import { useStyles } from './styles';
import { CustomTooltip } from '../CustomTooltip';
import { ChipEntityType } from '../../models';

interface Props {
  id?: string;
  text: string;
  tooltipText?: string;
  wrapperClassName?: string;
  textClassName?: string;
  entityType?: ChipEntityType;
}

export function Chip({
  id,
  text,
  tooltipText,
  wrapperClassName,
  textClassName,
  entityType,
}: Props) {
  const classes = useStyles();

  let content = (
    <Box
      className={`${classes.wrapper} ${wrapperClassName ?? ''}`}
      data-entity-type={entityType ?? 'chip'}
      id={id}
    >
      <Box className={`${classes.textWrapper} ${textClassName ?? ''}`}>{text}</Box>
    </Box>
  );

  if (tooltipText) {
    content = <CustomTooltip title={tooltipText}>{content}</CustomTooltip>;
  }

  return content;
}
