import { GridCellParams, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Group, OrganizationOrGroupTypeEnum } from '../../../models';
import {
  DeleteCompletedHandler,
  DeleteHandler,
  Grid,
  GridColumnDefinition,
  RowClickHandler,
} from '../../Grid';
import { EDIT_SUBPATH } from '../../../constants';

interface Props {
  data: Group[];
  onDelete?: DeleteHandler;
  onDeleteCompleted?: DeleteCompletedHandler<Group>;
  onRowClick?: RowClickHandler<Group>;
}

export function GroupsGrid({ data, onDelete, onDeleteCompleted, onRowClick }: Props) {
  const { t } = useTranslation('group');
  const { t: tCommon } = useTranslation('common');
  const columns: GridColumnDefinition<Group>[] = [
    {
      field: 'name',
      headerName: t('name.label'),
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridCellParams) => <strong>{params.row.name}</strong>,
    },
    {
      field: 'description',
      headerName: t('description.label'),
      minWidth: 250,
      flex: 2,
    },
    {
      field: 'organizationOrGroupType',
      headerName: t('organizationOrGroupType.label'),
      minWidth: 250,
      flex: 2,
      renderCell: (params: GridRenderCellParams) =>
        tCommon(
          `organizationOrGroupType.${OrganizationOrGroupTypeEnum[params.value as keyof typeof OrganizationOrGroupTypeEnum]}`,
        ),
    },
  ];

  function getEditPath(id: number) {
    return `/groups/${id}/${EDIT_SUBPATH}`;
  }

  return (
    <Grid
      columns={columns}
      rows={data}
      getEditPath={getEditPath}
      onDelete={onDelete}
      onDeleteCompleted={onDeleteCompleted}
      onRowClick={onRowClick}
    />
  );
}
