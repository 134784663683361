import { Box, useMediaQuery, useTheme } from '@mui/material';
import { AddButton } from '../../../components/AddButton';
import { SubmitFormCompletedHandler, SubmitFormHandler } from '../../../components/Form';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AlarmType, ListDailyPlanTemplate } from '../../../models';
import { useFunctionalityMode } from '../../../context/functionality';
import { ReactComponent as DailyPlanNavIcon } from '../../../assets/icons/DailyPlanNavIcon.svg';
import { useCreateAlarmOrAddFromTemplateShowHideFormStyles } from './styles';
import {
  AlarmGroupWithTemplatesFormValues,
  AlarmsFromTemplateForm,
} from './AlarmsFromTemplateForm';
import { AlarmFormValues } from '../../../components/AlarmForm';
import { useAlarmActiveShowHideForm } from '../../../context/alarmActiveShowHideForm';
import { NewAlarmDialog } from '../../../components/NewAlarmDialog';

interface ShowHideFormProps {
  alarmTypes: AlarmType[];
  autocompleteDrugNames: string[];
  dailyPlanTemplates: ListDailyPlanTemplate[];
  onSubmitNew: SubmitFormHandler<AlarmFormValues>;
  onSubmitTemplate: SubmitFormHandler<AlarmGroupWithTemplatesFormValues>;
  onSubmitCompleted: SubmitFormCompletedHandler;
}

export function CreateAlarmOrAddFromTemplateShowHideForm({
  onSubmitNew,
  onSubmitTemplate,
  onSubmitCompleted,
  alarmTypes,
  autocompleteDrugNames,
  dailyPlanTemplates,
}: ShowHideFormProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('alarm');
  const classes = useCreateAlarmOrAddFromTemplateShowHideFormStyles();
  const {
    isAddNewVisible,
    hideAddNew,
    isAddFromTemplateVisible,
    showAddFromTemplate,
    hideAddFromTemplate,
  } = useAlarmActiveShowHideForm();

  const { hasCreateFunctionality } = useFunctionalityMode();

  if (!hasCreateFunctionality) {
    return null;
  }

  useEffect(() => {
    if (isAddNewVisible) {
      hideAddFromTemplate();
    }
  }, [isAddNewVisible]);

  useEffect(() => {
    if (isAddFromTemplateVisible) {
      hideAddNew();
    }
  }, [isAddFromTemplateVisible]);

  return (
    <>
      {hasCreateFunctionality && (
        <Box marginTop={2} marginBottom={2}>
          {!isAddNewVisible && !isAddFromTemplateVisible && (
            <Box className={classes.buttonsWrapper}>
              {!isAddNewVisible && (
                <NewAlarmDialog
                  onSubmit={onSubmitNew}
                  onSubmitCompleted={onSubmitCompleted}
                  alarmTypes={alarmTypes}
                  autocompleteDrugNames={autocompleteDrugNames}
                />
              )}
              {!isAddFromTemplateVisible && dailyPlanTemplates.length > 0 && (
                <AddButton
                  id="dailyPlanTemplateAddFromTemplateBtn"
                  onAddClick={showAddFromTemplate}
                  text={t('chooseFromTemplate')}
                  icon={
                    <Box className={classes.iconWrapper}>
                      <DailyPlanNavIcon />
                    </Box>
                  }
                  fullWidth={isMobile}
                />
              )}
            </Box>
          )}
          <Box>
            {isAddFromTemplateVisible && (
              <React.Fragment>
                <AlarmsFromTemplateForm
                  onSubmit={onSubmitTemplate}
                  onSubmitCompleted={onSubmitCompleted}
                  onCancel={hideAddFromTemplate}
                  dailyPlanTemplates={dailyPlanTemplates}
                />
              </React.Fragment>
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
