import React, { ReactNode } from 'react';
import { Button as MuiButton } from '@mui/material';
import { useStyles } from './styles';
import { ClassNameMap } from '@mui/styles/withStyles/withStyles';

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  fullWidth?: boolean;
  text: string | ReactNode;
  color: 'primary' | 'secondary';
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  disabled?: boolean;
  classes?: Partial<ClassNameMap>;
  type?: 'button' | 'submit' | 'reset' | undefined;
  variant?: Variant;
}

export type Variant = 'text' | 'outlined' | 'contained' | undefined;

export function Button({
  onClick,
  text,
  color,
  startIcon,
  endIcon,
  fullWidth = false,
  disabled,
  classes,
  type,
  variant,
}: Props) {
  const invertColors = color !== 'primary';
  const hasTextVariant = variant === 'text';
  const tClasses = useStyles({
    invertColors: invertColors,
    height: 'auto',
    width: 'auto',
  });

  const componentClasses = {
    ...classes,
    ...{ root: !hasTextVariant ? tClasses.root : tClasses.rootTextVariant },
  };

  return (
    <MuiButton
      variant={variant ?? 'contained'}
      onClick={onClick}
      classes={componentClasses}
      fullWidth={fullWidth}
      startIcon={startIcon}
      endIcon={endIcon}
      disableElevation={true}
      disabled={disabled}
      type={type}
    >
      {text}
    </MuiButton>
  );
}
