import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TextInput } from '../../../../components/TextInput';
import { DatePicker } from '../../../../components/DatePicker';

export function UserForm() {
  //const classes = useStyles();
  const { t } = useTranslation('installation');

  return (
    <>
      <Box>
        <TextInput id="name" label={t('name.label')} required={true} type="text" />
        <TextInput id="surname" label={t('surname.label')} required={true} type="text" />
        <DatePicker id="birthday" label={t('birthday.label')} required={true} />
        <TextInput id="email" label={t('email.label')} required={true} type="text" />
        <TextInput id="chassisId" label={t('chassisId.label')} required={true} type="text" />
      </Box>
    </>
  );
}
