import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

interface Props {
  isDrawerOpen: boolean;
}

export const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoAndActionButton: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ({ isDrawerOpen }) => (isDrawerOpen ? 'flex-start' : 'flex-end'),
    alignItems: 'center',
    gap: theme.typography.pxToRem(70),
    padding: theme.spacing(0, 3.5),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    ['@media (max-height:500px)']: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
    },
  },
  menuButton: {
    cursor: 'pointer',
    marginRight: ({ isDrawerOpen }) => (isDrawerOpen ? 0 : theme.spacing(-1.5)),
    color: theme.palette.primary.A200,
    '&:hover, &:focus': {
      color: theme.palette.primary.main,
    },
  },
}));
