import React from 'react';
import { StyledMeasurementTypesTabs } from '../StyledTabs';
import { useAlarmTypeNavTabsStyles } from './styles';

interface Props {
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number | string) => void;
  children: React.ReactNode;
}

export function AlarmTypeNavTabs({ value, onChange, children }: Props) {
  const classes = useAlarmTypeNavTabsStyles();
  return (
    <StyledMeasurementTypesTabs value={value} onChange={onChange} classes={classes}>
      {children}
    </StyledMeasurementTypesTabs>
  );
}
