import { AlarmGridModelRepetition } from '../../../../../models';
import { isNullOrUndefined } from '../../../../../utils';
import { DAYS } from '../../../../../constants';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { ShortMonthDate } from '../../../../ShortMonthDate';
import { DayChips } from '../../../../DayChips';
import { RepetitionChip } from '../../../../RepetitionChip';
import { GridRowId } from '@mui/x-data-grid';

interface Props {
  rowId?: GridRowId;
  repetition: AlarmGridModelRepetition;
}

export function RepeatCell({ rowId, repetition }: Props) {
  const { t } = useTranslation('common');
  const { days, interval } = repetition;

  if (days && days.length > 0) {
    const isEveryDay = DAYS.length === days.length && DAYS.every((v) => days.includes(v.id));
    if (isEveryDay) {
      return <RepetitionChip text={t('eachDay', { count: 1 })} />;
    } else {
      return <DayChips rowId={rowId} days={days} />;
    }
  } else if (!isNullOrUndefined(interval)) {
    if (interval === 0) {
      return (
        <Box display="flex" flexDirection="column">
          <RepetitionChip text={t('onlyOnce')} />
          <ShortMonthDate date={repetition.startDate} />
        </Box>
      );
    }

    return <RepetitionChip text={t('eachDay', { count: interval ?? 0 })} />;
  }

  return <></>;
}
