import React, { useEffect } from 'react';
import { useField } from 'formik';
import { useMeasuringDeviceTypesInputStyles } from './styles';
import { CheckboxIconInput } from '../CheckboxIconInput';
import { MeasuringDeviceWithLabel } from '../../models';
import { Box } from '@mui/material';
import { MeasuringDeviceCheckBoxIcon } from '../Icons/MeasuringDeviceCheckBoxIcon';

interface Props {
  id: string;
  measuringDevicesOptions: MeasuringDeviceWithLabel[];
  setAsInitiallyTouched?: boolean;
}

export function MeasuringDevicesTypesInput({
  id,
  measuringDevicesOptions,
  setAsInitiallyTouched = false,
}: Props) {
  const [{ value }, , { setValue, setTouched }] = useField<number[]>(id);

  useEffect(() => {
    if (setAsInitiallyTouched) {
      setTouched(true);
    }
  }, []);

  const classes = useMeasuringDeviceTypesInputStyles();

  return (
    <React.Fragment>
      {measuringDevicesOptions.map((measuringDeviceType, index) => (
        <Box key={index} className={classes.measuringDeviceTypesWrapper}>
          <CheckboxIconInput
            id={id}
            label={measuringDeviceType.label}
            icon={
              <MeasuringDeviceCheckBoxIcon
                measuringDeviceType={measuringDeviceType.name}
                isActive={value.includes(measuringDeviceType.id)}
              />
            }
            checked={value.includes(measuringDeviceType.id)}
            showError={false}
            onChange={(e) => {
              if (e.target.checked) {
                // if setting device measurement alarm, add measurement alarm
                setValue([...value, measuringDeviceType.id]);
              } else {
                // remove selected alarm from array
                setValue([...value.filter((x) => x != measuringDeviceType.id)]);
              }
            }}
          />
        </Box>
      ))}
    </React.Fragment>
  );
}
