import { AlarmGridModelDuration } from '../../../../../models';
import { Box } from '@mui/material';
import { useStyles } from './styles';
import { ShortMonthDate } from '../../../../ShortMonthDate';
import { GridRowId } from '@mui/x-data-grid';

interface Props {
  rowId?: GridRowId;
  duration: AlarmGridModelDuration;
}

export function DurationCell({ rowId, duration }: Props) {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column">
      <ShortMonthDate
        date={duration.startOnDatetime}
        testId="alarmStartOnDatetime"
        id={`row[${rowId}].startOnDatetime`}
      />
      {duration.endOnDatetime && (
        <ShortMonthDate
          date={duration.endOnDatetime}
          className={classes.endDate}
          testId="alarmEndOnDatetime"
          id={`row[${rowId}].endOnDatetime`}
        />
      )}
    </Box>
  );
}
