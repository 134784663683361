import { Box, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BackIconWithCircleBackground } from '../../../components/Icons';
import { ProgressIndicator } from '../../../components/ProgressIndicator';
import { QuestionnairePreviewHeader } from '../../../components/Questionnaire';
import { QuestionnaireResponseQuestionsList } from '../../../components/Questionnaire/Response/QuestionsList';
import { Patient, PatientQuestionnaire, QuestionnaireResponse } from '../../../models';
import { ApiRoutes, useGetApi } from '../../../services/api';
import { useStyles } from '../../questionnaires/styles';
import { UnansweredPatientQuestionnairePage } from './UnansweredPatientQuestionnairePage';

export function PatientQuestionnaireResponsePage() {
  const { t } = useTranslation(['questionnaire', 'patient', 'common']);
  const classes = useStyles();
  const navigate = useNavigate();
  const { id, questionnaireId } = useParams<{
    id: string;
    questionnaireId: string;
  }>();

  const [{ data: patient, isLoading: isPatientLoading }] = useGetApi<Patient>(
    ApiRoutes.Patient(id as string),
  );

  const [{ data: questionnaire, isLoading: isQuestionnaireLoading }] =
    useGetApi<PatientQuestionnaire>(
      ApiRoutes.PatientQuestionnaire(id as string, questionnaireId as string),
    );

  const [{ data: patientQuestionnaireResponse, isLoading: isPatientQuestionnaireResponse }] =
    useGetApi<QuestionnaireResponse>(
      ApiRoutes.PatientQuestionnaireResponse(id as string, questionnaireId as string),
    );

  function onGoBack() {
    navigate(-1);
  }

  if (isPatientLoading || isQuestionnaireLoading || isPatientQuestionnaireResponse) {
    return <ProgressIndicator />;
  }

  if (patient == null) {
    return <div>{t('patient:error.patientNotFound')}</div>;
  }

  if (questionnaire == null) {
    return <div>{t('questionnaire:error.questionnaireNotFound')}</div>;
  }

  // when questionnaire does not have response, show questionnaire preview partial only
  if (patientQuestionnaireResponse == null) {
    return <UnansweredPatientQuestionnairePage questionnaire={questionnaire} />;
  }

  return (
    <React.Fragment>
      <QuestionnairePreviewHeader
        questionnaire={questionnaire}
        datetime={patientQuestionnaireResponse.answeredOnDatetime}
        score={questionnaire.score}
      />
      <QuestionnaireResponseQuestionsList
        questions={questionnaire.items}
        questionsAnswers={patientQuestionnaireResponse.items}
      />
      <Box display="flex" width="100%" justifyContent="flex-end">
        <Button
          onClick={onGoBack}
          startIcon={<BackIconWithCircleBackground invertColors={true} />}
          size="small"
          variant="text"
          className={classes.backButton}
        >
          {t('common:goBack')}
        </Button>
      </Box>
    </React.Fragment>
  );
}
