import { PatientNoteIcon } from '../../../components/Icons';
import { SystemEventPatientNote } from '../../../models';
import { SystemEventListItemContainer } from './SystemEventListItemContainer';
import { SystemEventListItemAction } from './SystemEventListItemAction';
import { SystemEventListItemValues } from './SystemEventListItemValues';
import { SystemEventListItemEntityPropertyValue } from './SystemEventListItemEntityPropertyValue';
import { SystemEventListItemActionPatient } from './SystemEventListItemActionPatient';
import { useTranslation } from 'react-i18next';
import { SYSTEM_EVENT_ICON_SIZE } from './styles';

interface Props {
  systemEvent: SystemEventPatientNote;
}

export function SystemEventPatientNoteListItem({ systemEvent }: Props) {
  const { t } = useTranslation(['note', 'systemEvent']);

  return (
    <SystemEventListItemContainer
      linkTo={`/patients/${systemEvent.entity.patient.id}/notes/${systemEvent.entity.id}`}
    >
      <SystemEventListItemAction
        icon={<PatientNoteIcon width={SYSTEM_EVENT_ICON_SIZE} height={SYSTEM_EVENT_ICON_SIZE} />}
        actionName={
          systemEvent.action === 'created'
            ? t('systemEvent:action.patientNoteCreated')
            : t('systemEvent:action.patientNoteUpdated')
        }
        systemEvent={systemEvent}
      />
      <SystemEventListItemValues>
        <SystemEventListItemActionPatient patient={systemEvent.entity.patient} />
        <SystemEventListItemEntityPropertyValue
          propertyName={t('note:author.label')}
          propertyValue={systemEvent.entity.author || '-'}
        />
        <SystemEventListItemEntityPropertyValue
          propertyName={t('note:text.label')}
          propertyValue={systemEvent.entity.text}
        />
      </SystemEventListItemValues>
    </SystemEventListItemContainer>
  );
}
