import { useDashboardMenuContext } from '../../../context/dashboardMenu';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { Box, Link, Tooltip } from '@mui/material';
import { ReactComponent as SupportIcon } from '../../../assets/icons/SupportIcon.svg';
import { ReactComponent as LogOutIcon } from '../../../assets/icons/LogOutIcon.svg';
import { SCASE_SUPPORT_EXTERNAL_LINK } from '../../../constants';
import { Link as RouterLink } from 'react-router-dom';

export function DashboardFooterActions() {
  const { isDrawerOpen } = useDashboardMenuContext();
  const classes = useStyles({ isDrawerOpen: isDrawerOpen });
  const { t } = useTranslation('navigation');
  return (
    <Box className={classes.actionsContainer}>
      <Tooltip
        title={t('sidebar.footer.contactSupport')}
        placement={isDrawerOpen ? 'top' : 'right'}
        classes={{ tooltip: classes.tooltip }}
      >
        <Link
          href={SCASE_SUPPORT_EXTERNAL_LINK}
          target="_blank"
          rel="noopener"
          className={classes.link}
        >
          <SupportIcon />
        </Link>
      </Tooltip>
      <Tooltip
        title={t('sidebar.footer.logout')}
        placement={isDrawerOpen ? 'top' : 'right'}
        classes={{ tooltip: classes.tooltip }}
      >
        <RouterLink to="/logout" className={classes.link}>
          <LogOutIcon />
        </RouterLink>
      </Tooltip>
    </Box>
  );
}
