import {
  QuestionTypeBooleanIcon,
  QuestionTypeDefaultIcon,
  QuestionTypeMultiselectIcon,
  QuestionTypeNumberIcon,
  QuestionTypeSelectIcon,
  QuestionTypeStringIcon,
} from '../components/Icons';
import { theme } from '../assets/style';

const QUESTION_TYPE_ICON_WIDTH = 38;
const QUESTION_TYPE_ICON_HEIGHT = 39;

const questionTypeIconMapping = [
  {
    type: 'default',
    icon: (
      <QuestionTypeDefaultIcon
        height={QUESTION_TYPE_ICON_HEIGHT}
        width={QUESTION_TYPE_ICON_WIDTH}
      />
    ),
    errorIcon: (
      <QuestionTypeDefaultIcon
        height={QUESTION_TYPE_ICON_HEIGHT}
        width={QUESTION_TYPE_ICON_WIDTH}
        primaryColor={theme.palette.error.dark}
      />
    ),
    selectedIcon: (
      <QuestionTypeDefaultIcon
        primaryColor={theme.palette.primary.dark}
        height={QUESTION_TYPE_ICON_HEIGHT}
        width={QUESTION_TYPE_ICON_WIDTH}
      />
    ),
  },
  {
    type: 'string',
    icon: (
      <QuestionTypeStringIcon height={QUESTION_TYPE_ICON_HEIGHT} width={QUESTION_TYPE_ICON_WIDTH} />
    ),
    errorIcon: (
      <QuestionTypeStringIcon
        primaryColor={theme.palette.error.dark}
        height={QUESTION_TYPE_ICON_HEIGHT}
        width={QUESTION_TYPE_ICON_WIDTH}
      />
    ),
    selectedIcon: (
      <QuestionTypeStringIcon
        primaryColor={theme.palette.primary.dark}
        height={QUESTION_TYPE_ICON_HEIGHT}
        width={QUESTION_TYPE_ICON_WIDTH}
      />
    ),
  },
  {
    type: 'number',
    icon: (
      <QuestionTypeNumberIcon height={QUESTION_TYPE_ICON_HEIGHT} width={QUESTION_TYPE_ICON_WIDTH} />
    ),
    errorIcon: (
      <QuestionTypeNumberIcon
        primaryColor={theme.palette.error.dark}
        height={QUESTION_TYPE_ICON_HEIGHT}
        width={QUESTION_TYPE_ICON_WIDTH}
      />
    ),
    selectedIcon: (
      <QuestionTypeNumberIcon
        primaryColor={theme.palette.primary.dark}
        height={QUESTION_TYPE_ICON_HEIGHT}
        width={QUESTION_TYPE_ICON_WIDTH}
      />
    ),
  },
  {
    type: 'boolean',
    icon: (
      <QuestionTypeBooleanIcon
        height={QUESTION_TYPE_ICON_HEIGHT}
        width={QUESTION_TYPE_ICON_WIDTH}
      />
    ),
    errorIcon: (
      <QuestionTypeBooleanIcon
        primaryColor={theme.palette.error.dark}
        height={QUESTION_TYPE_ICON_HEIGHT}
        width={QUESTION_TYPE_ICON_WIDTH}
      />
    ),
    selectedIcon: (
      <QuestionTypeBooleanIcon
        primaryColor={theme.palette.primary.dark}
        height={QUESTION_TYPE_ICON_HEIGHT}
        width={QUESTION_TYPE_ICON_WIDTH}
      />
    ),
  },
  {
    type: 'select',
    icon: (
      <QuestionTypeSelectIcon height={QUESTION_TYPE_ICON_HEIGHT} width={QUESTION_TYPE_ICON_WIDTH} />
    ),
    errorIcon: (
      <QuestionTypeSelectIcon
        primaryColor={theme.palette.error.dark}
        height={QUESTION_TYPE_ICON_HEIGHT}
        width={QUESTION_TYPE_ICON_WIDTH}
      />
    ),
    selectedIcon: (
      <QuestionTypeSelectIcon
        primaryColor={theme.palette.primary.dark}
        height={QUESTION_TYPE_ICON_HEIGHT}
        width={QUESTION_TYPE_ICON_WIDTH}
      />
    ),
  },
  {
    type: 'multiselect',
    icon: (
      <QuestionTypeMultiselectIcon
        height={QUESTION_TYPE_ICON_HEIGHT}
        width={QUESTION_TYPE_ICON_WIDTH}
      />
    ),
    errorIcon: (
      <QuestionTypeMultiselectIcon
        primaryColor={theme.palette.error.dark}
        height={QUESTION_TYPE_ICON_HEIGHT}
        width={QUESTION_TYPE_ICON_WIDTH}
      />
    ),
    selectedIcon: (
      <QuestionTypeMultiselectIcon
        primaryColor={theme.palette.primary.dark}
        height={QUESTION_TYPE_ICON_HEIGHT}
        width={QUESTION_TYPE_ICON_WIDTH}
      />
    ),
  },
];

export function getIconByQuestionType(questionType?: string, isSelected = false, isError = false) {
  const iconMappingValue =
    questionTypeIconMapping.find((x) => x.type == questionType) ??
    questionTypeIconMapping.find((x) => x.type == 'default');

  if (isError) {
    return iconMappingValue?.errorIcon;
  }
  if (isSelected) {
    return iconMappingValue?.selectedIcon;
  }

  return iconMappingValue?.icon;
}
