import { Card, CardContent } from '@mui/material';
import { ProgressIndicator } from '../../../components/ProgressIndicator';
import {
  AllMeasurements,
  MeasurementThresholds,
  MeasurementTypesNavTabFunctionalities,
  MeasuringDeviceTypeEnum,
  WebMeasurement,
} from '../../../models';
import { ApiRoutes, useGetApi } from '../../../services/api';
import { ChartPayload, foodTypeColors, GlucometerLegend } from './components';
import { DeviceMeasurement } from './DeviceMeasurement';
import { MeasurementNavTabsWrapper, NavTabPanel } from '../../../components/NavTabs';
import { ProtectedRoute } from '../../../components/ProtectedRoute';
import { Navigation } from '../../../types';
import {
  getDeviceIdFromMeasurements,
  getSortedThresholdsByDeviceId,
  mapMeasurementsToWebMeasurements,
} from '../../../utils';
import { GridContextProvider } from '../../../context';
import { useStyles } from './styles';
import * as NavigationConstants from '../../../constants/NavigationConstants';
import { NoMeasurements } from './components/NoMeasurements';
import { Navigate, Route, Routes } from 'react-router-dom';

interface Props {
  patientId: string;
}

export function PatientMeasurements({ patientId }: Props) {
  const classes = useStyles();

  const patientMeasurementsPath = `/patients/${patientId}/measurements`;
  const nav: Navigation = {
    pulseOximeter: {
      tab: `${patientMeasurementsPath}/${NavigationConstants.PULSE_OXIMETER_PATH}?${NavigationConstants.DEFAULT_MEASUREMENT_SORT_PATH_PARAM}`,
      route: {
        path: `${patientMeasurementsPath}/pulse-oximeter/*`,
      },
      subRoutes: {},
    },
    thermometer: {
      tab: `${patientMeasurementsPath}/${NavigationConstants.THERMOMETER_PATH}?${NavigationConstants.DEFAULT_MEASUREMENT_SORT_PATH_PARAM}`,
      route: {
        path: `${patientMeasurementsPath}/thermometer/*`,
      },
      subRoutes: {},
    },
    bloodPressure: {
      tab: `${patientMeasurementsPath}/${NavigationConstants.BLOOD_PRESSURE_MONITOR_PATH}?${NavigationConstants.DEFAULT_MEASUREMENT_SORT_PATH_PARAM}`,
      route: {
        path: `${patientMeasurementsPath}/blood-pressure-monitor/*`,
      },
      subRoutes: {},
    },
    glucometer: {
      tab: `${patientMeasurementsPath}/${NavigationConstants.GLUCOMETER_PATH}?${NavigationConstants.DEFAULT_MEASUREMENT_SORT_PATH_PARAM}`,
      route: {
        path: `${patientMeasurementsPath}/glucometer/*`,
      },
      subRoutes: {},
    },
    weight: {
      tab: `${patientMeasurementsPath}/${NavigationConstants.WEIGHT_PATH}?${NavigationConstants.DEFAULT_MEASUREMENT_SORT_PATH_PARAM}`,
      route: {
        path: `${patientMeasurementsPath}/weight/*`,
      },
      subRoutes: {},
    },
    height: {
      tab: `${patientMeasurementsPath}/${NavigationConstants.HEIGHT_PATH}?${NavigationConstants.DEFAULT_MEASUREMENT_SORT_PATH_PARAM}`,
      route: {
        path: `${patientMeasurementsPath}/height/*`,
      },
      subRoutes: {},
    },
    movementPain: {
      tab: `${patientMeasurementsPath}/${NavigationConstants.MOVEMENT_PAIN_PATH}?${NavigationConstants.DEFAULT_MEASUREMENT_SORT_PATH_PARAM}`,
      route: {
        path: `${patientMeasurementsPath}/movement-pain/*`,
      },
      subRoutes: {},
    },
    probeFluidIntake: {
      tab: `${patientMeasurementsPath}/${NavigationConstants.PROBE_FLUID_INTAKE_PATH}?${NavigationConstants.DEFAULT_MEASUREMENT_SORT_PATH_PARAM}`,
      route: {
        path: `${patientMeasurementsPath}/probe-fluid-intake/*`,
      },
      subRoutes: {},
    },
    intravenousFluidIntake: {
      tab: `${patientMeasurementsPath}/${NavigationConstants.INTRAVENOUS_FLUID_INTAKE_PATH}?${NavigationConstants.DEFAULT_MEASUREMENT_SORT_PATH_PARAM}`,
      route: {
        path: `${patientMeasurementsPath}/intravenous-fluid-intake/*`,
      },
      subRoutes: {},
    },
    urineOutput: {
      tab: `${patientMeasurementsPath}/${NavigationConstants.URINE_OUTPUT_PATH}?${NavigationConstants.DEFAULT_MEASUREMENT_SORT_PATH_PARAM}`,
      route: {
        path: `${patientMeasurementsPath}/urine-output/*`,
      },
      subRoutes: {},
    },
  };

  const [
    { data: measurements, isLoading: isMeasurementsLoading },
    { refetch: refetchMeasurements },
  ] = useGetApi<AllMeasurements>(ApiRoutes.PatientMeasurements(patientId));

  const [
    { data: measurementThresholds, isLoading: isMeasurementThresholdsLoading },
    { refetch: refetchMeasurementThresholds },
  ] = useGetApi<MeasurementThresholds[]>(ApiRoutes.PatientMeasurementThresholds(patientId));

  if (isMeasurementsLoading || isMeasurementThresholdsLoading) {
    return <ProgressIndicator />;
  }

  if (!measurements) {
    return <NoMeasurements />;
  }

  function getFoodTypeColor(payload: ChartPayload) {
    if (payload.foodType?.value === 'AFTER_FOOD') {
      return foodTypeColors.afterFood;
    } else if (payload.foodType?.value === 'BEFORE_FOOD') {
      return foodTypeColors.beforeFood;
    }

    return '';
  }

  function transformThermometerChartMeasurements(measurements: WebMeasurement[]): WebMeasurement[] {
    return measurements.map((measurement) => {
      const { type: _, ...chartMeasurement } = measurement;
      return chartMeasurement;
    });
  }

  async function handleThresholdsSubmitCompleted() {
    await refetchMeasurements();
    await refetchMeasurementThresholds();
  }

  const navTabsFunctionalities: MeasurementTypesNavTabFunctionalities = {
    PulseOximeter: 'MEASUREMENTS_PULSEOXIMETER',
    Thermometer: 'MEASUREMENTS_THERMOMETER',
    Glucometer: 'MEASUREMENTS_GLUCOMETER',
    BloodPressureMonitor: 'MEASUREMENTS_BLOODPRESSURE',
    Weight: 'MEASUREMENTS_WEIGHT',
    Height: 'MEASUREMENTS_HEIGHT',
    MovementPain: 'MEASUREMENTS_MOVEMENT_PAIN',
    ProbeFluidIntake: 'MEASUREMENTS_PROBE_FLUID_INTAKE',
    IntravenousFluidIntake: 'MEASUREMENTS_INTRAVENOUS_FLUID_INTAKE',
    UrineOutput: 'MEASUREMENTS_URINE_OUTPUT',
  };

  return (
    <div>
      <MeasurementNavTabsWrapper
        nav={nav}
        measurementTypesFunctionalities={navTabsFunctionalities}
        ariaLabel={'patient measurements tabs'}
      />
      <Card className={classes.boxShadowAndBorderRadius}>
        <CardContent className={classes.cardContent}>
          <NavTabPanel>
            <Routes>
              <Route
                path="/*"
                element={
                  <ProtectedRoute requireFunctionality="MEASUREMENTS_BLOODPRESSURE">
                    <Navigate
                      to={`${patientMeasurementsPath}/${NavigationConstants.BLOOD_PRESSURE_MONITOR_PATH}?${NavigationConstants.DEFAULT_MEASUREMENT_SORT_PATH_PARAM}`}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pulse-oximeter/*"
                element={
                  <ProtectedRoute requireFunctionality="MEASUREMENTS_PULSEOXIMETER">
                    <GridContextProvider>
                      <DeviceMeasurement
                        deviceMeasurements={mapMeasurementsToWebMeasurements(
                          measurements.PulseOximeter,
                        )}
                        measuringDeviceId={getDeviceIdFromMeasurements(
                          MeasuringDeviceTypeEnum.PULSE_OXIMETER,
                          measurements,
                        )}
                        measuringDevice={MeasuringDeviceTypeEnum.PULSE_OXIMETER}
                        patientId={patientId}
                        measurementThresholds={getSortedThresholdsByDeviceId(
                          getDeviceIdFromMeasurements(
                            MeasuringDeviceTypeEnum.PULSE_OXIMETER,
                            measurements,
                          ),
                          measurementThresholds,
                        )}
                        onThresholdsSubmitCompleted={handleThresholdsSubmitCompleted}
                      />
                    </GridContextProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/thermometer/*"
                element={
                  <ProtectedRoute requireFunctionality="MEASUREMENTS_THERMOMETER">
                    <GridContextProvider>
                      <DeviceMeasurement
                        deviceMeasurements={mapMeasurementsToWebMeasurements(
                          measurements.Thermometer,
                        )}
                        measuringDeviceId={getDeviceIdFromMeasurements(
                          MeasuringDeviceTypeEnum.THERMOMETER,
                          measurements,
                        )}
                        measuringDevice={MeasuringDeviceTypeEnum.THERMOMETER}
                        patientId={patientId}
                        measurementThresholds={getSortedThresholdsByDeviceId(
                          getDeviceIdFromMeasurements(
                            MeasuringDeviceTypeEnum.THERMOMETER,
                            measurements,
                          ),
                          measurementThresholds,
                        )}
                        transformChartMeasurements={transformThermometerChartMeasurements}
                        onThresholdsSubmitCompleted={handleThresholdsSubmitCompleted}
                      />
                    </GridContextProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/blood-pressure-monitor/*"
                element={
                  <ProtectedRoute requireFunctionality="MEASUREMENTS_BLOODPRESSURE">
                    <GridContextProvider>
                      <DeviceMeasurement
                        deviceMeasurements={mapMeasurementsToWebMeasurements(
                          measurements.BloodPressureMonitor,
                        )}
                        measuringDeviceId={getDeviceIdFromMeasurements(
                          MeasuringDeviceTypeEnum.BLOOD_PRESSURE_MONITOR,
                          measurements,
                        )}
                        measuringDevice={MeasuringDeviceTypeEnum.BLOOD_PRESSURE_MONITOR}
                        patientId={patientId}
                        measurementThresholds={getSortedThresholdsByDeviceId(
                          getDeviceIdFromMeasurements(
                            MeasuringDeviceTypeEnum.BLOOD_PRESSURE_MONITOR,
                            measurements,
                          ),
                          measurementThresholds,
                        )}
                        onThresholdsSubmitCompleted={handleThresholdsSubmitCompleted}
                      />
                    </GridContextProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/glucometer/*"
                element={
                  <ProtectedRoute requireFunctionality="MEASUREMENTS_GLUCOMETER">
                    <GridContextProvider>
                      <DeviceMeasurement
                        deviceMeasurements={mapMeasurementsToWebMeasurements(
                          measurements.Glucometer,
                        )}
                        measuringDeviceId={getDeviceIdFromMeasurements(
                          MeasuringDeviceTypeEnum.GLUCOMETER,
                          measurements,
                        )}
                        measuringDevice={MeasuringDeviceTypeEnum.GLUCOMETER}
                        patientId={patientId}
                        measurementThresholds={getSortedThresholdsByDeviceId(
                          getDeviceIdFromMeasurements(
                            MeasuringDeviceTypeEnum.GLUCOMETER,
                            measurements,
                          ),
                          measurementThresholds,
                        )}
                        onThresholdsSubmitCompleted={handleThresholdsSubmitCompleted}
                        chart={{
                          getLegend: (props) => <GlucometerLegend {...props} />,
                          activeDot: {
                            getFill: getFoodTypeColor,
                          },
                          dot: {
                            getFill: getFoodTypeColor,
                          },
                          hideInLegend: (key) => key === 'foodType',
                        }}
                      />
                    </GridContextProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/weight/*"
                element={
                  <ProtectedRoute requireFunctionality="MEASUREMENTS_WEIGHT">
                    <GridContextProvider>
                      <DeviceMeasurement
                        deviceMeasurements={mapMeasurementsToWebMeasurements(measurements.Weight)}
                        measuringDeviceId={getDeviceIdFromMeasurements(
                          MeasuringDeviceTypeEnum.WEIGHT,
                          measurements,
                        )}
                        measuringDevice={MeasuringDeviceTypeEnum.WEIGHT}
                        patientId={patientId}
                        measurementThresholds={getSortedThresholdsByDeviceId(
                          getDeviceIdFromMeasurements(MeasuringDeviceTypeEnum.WEIGHT, measurements),
                          measurementThresholds,
                        )}
                        onThresholdsSubmitCompleted={handleThresholdsSubmitCompleted}
                      />
                    </GridContextProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/height/*"
                element={
                  <ProtectedRoute requireFunctionality="MEASUREMENTS_HEIGHT">
                    <GridContextProvider>
                      <DeviceMeasurement
                        deviceMeasurements={mapMeasurementsToWebMeasurements(measurements.Height)}
                        measuringDeviceId={getDeviceIdFromMeasurements(
                          MeasuringDeviceTypeEnum.HEIGHT,
                          measurements,
                        )}
                        measuringDevice={MeasuringDeviceTypeEnum.HEIGHT}
                        patientId={patientId}
                        measurementThresholds={getSortedThresholdsByDeviceId(
                          getDeviceIdFromMeasurements(MeasuringDeviceTypeEnum.HEIGHT, measurements),
                          measurementThresholds,
                        )}
                        onThresholdsSubmitCompleted={handleThresholdsSubmitCompleted}
                      />
                    </GridContextProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/movement-pain/*"
                element={
                  <ProtectedRoute requireFunctionality="MEASUREMENTS_MOVEMENT_PAIN">
                    <GridContextProvider>
                      <DeviceMeasurement
                        deviceMeasurements={mapMeasurementsToWebMeasurements(
                          measurements.MovementPain,
                        )}
                        measuringDeviceId={getDeviceIdFromMeasurements(
                          MeasuringDeviceTypeEnum.MOVEMENT_PAIN,
                          measurements,
                        )}
                        measuringDevice={MeasuringDeviceTypeEnum.MOVEMENT_PAIN}
                        patientId={patientId}
                        measurementThresholds={getSortedThresholdsByDeviceId(
                          getDeviceIdFromMeasurements(
                            MeasuringDeviceTypeEnum.MOVEMENT_PAIN,
                            measurements,
                          ),
                          measurementThresholds,
                        )}
                        onThresholdsSubmitCompleted={handleThresholdsSubmitCompleted}
                      />
                    </GridContextProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/probe-fluid-intake/*"
                element={
                  <ProtectedRoute requireFunctionality="MEASUREMENTS_PROBE_FLUID_INTAKE">
                    <GridContextProvider>
                      <DeviceMeasurement
                        deviceMeasurements={mapMeasurementsToWebMeasurements(
                          measurements.ProbeFluidIntake,
                        )}
                        measuringDeviceId={getDeviceIdFromMeasurements(
                          MeasuringDeviceTypeEnum.PROBE_FLUID_INTAKE,
                          measurements,
                        )}
                        measuringDevice={MeasuringDeviceTypeEnum.PROBE_FLUID_INTAKE}
                        patientId={patientId}
                        measurementThresholds={getSortedThresholdsByDeviceId(
                          getDeviceIdFromMeasurements(
                            MeasuringDeviceTypeEnum.PROBE_FLUID_INTAKE,
                            measurements,
                          ),
                          measurementThresholds,
                        )}
                        onThresholdsSubmitCompleted={handleThresholdsSubmitCompleted}
                      />
                    </GridContextProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/intravenous-fluid-intake/*"
                element={
                  <ProtectedRoute requireFunctionality="MEASUREMENTS_INTRAVENOUS_FLUID_INTAKE">
                    <GridContextProvider>
                      <DeviceMeasurement
                        deviceMeasurements={mapMeasurementsToWebMeasurements(
                          measurements.IntravenousFluidIntake,
                        )}
                        measuringDeviceId={getDeviceIdFromMeasurements(
                          MeasuringDeviceTypeEnum.INTRAVENOUS_FLUID_INTAKE,
                          measurements,
                        )}
                        measuringDevice={MeasuringDeviceTypeEnum.INTRAVENOUS_FLUID_INTAKE}
                        patientId={patientId}
                        measurementThresholds={getSortedThresholdsByDeviceId(
                          getDeviceIdFromMeasurements(
                            MeasuringDeviceTypeEnum.INTRAVENOUS_FLUID_INTAKE,
                            measurements,
                          ),
                          measurementThresholds,
                        )}
                        onThresholdsSubmitCompleted={handleThresholdsSubmitCompleted}
                      />
                    </GridContextProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/urine-output/*"
                element={
                  <ProtectedRoute requireFunctionality="MEASUREMENTS_URINE_OUTPUT">
                    <GridContextProvider>
                      <DeviceMeasurement
                        deviceMeasurements={mapMeasurementsToWebMeasurements(
                          measurements.UrineOutput,
                        )}
                        measuringDeviceId={getDeviceIdFromMeasurements(
                          MeasuringDeviceTypeEnum.URINE_OUTPUT,
                          measurements,
                        )}
                        measuringDevice={MeasuringDeviceTypeEnum.URINE_OUTPUT}
                        patientId={patientId}
                        measurementThresholds={getSortedThresholdsByDeviceId(
                          getDeviceIdFromMeasurements(
                            MeasuringDeviceTypeEnum.URINE_OUTPUT,
                            measurements,
                          ),
                          measurementThresholds,
                        )}
                        onThresholdsSubmitCompleted={handleThresholdsSubmitCompleted}
                      />
                    </GridContextProvider>
                  </ProtectedRoute>
                }
              />
            </Routes>
          </NavTabPanel>
        </CardContent>
      </Card>
    </div>
  );
}
