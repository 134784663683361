import { Box, Typography } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { QUESTION_TEXT_MAX_LENGTH } from '../../../constants';
import { AnswerOptionCreateInput, QuestionType } from '../../../models';
import { QuestionAnswersInput } from '../QuestionAnswersInput';
import { SelectInput, SelectOption } from '../../SelectInput';
import { TextInput } from '../../TextInput';
import { Trans, useTranslation } from 'react-i18next';
import { QuestionnaireFormValues } from '../../../pages/questionnaires/QuestionnaireForm';
import { useSelectedQuestionIndex } from '../../../context/selectedQuestionIndex';

interface Props {
  id: string;
  questionTypes: QuestionType[];
}

export function SelectedQuestionInput({ id, questionTypes }: Props) {
  const { t: tQuestionnaire } = useTranslation('questionnaire');
  const { t: tCommon } = useTranslation('common');
  const { selectedIndex, isQuestionMoving } = useSelectedQuestionIndex();
  const [{ value }] = useField(id);
  const [{ name: answerOptionsFieldName }] = useField(
    `${id}[${selectedIndex}].questionnaireAnswerOptions`,
  );

  const [{ name: initialValueField }] = useField(`${id}[${selectedIndex}].initialValue`);
  const { values, setFieldValue } = useFormikContext<QuestionnaireFormValues>();
  // Set initial value of currentSelectedIndex to -1
  // We do not want useEffect below to run on initial load
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState(-1);
  const emptyAnswerOption: AnswerOptionCreateInput = { value: '', initialValue: false };

  useEffect(() => {
    // Do not reset question answer options if the question is moving (being reordered)
    // in form state
    // or if currently selected index is not the selected index of the state.
    // Therefore: we want to reset question answer options only is the questionTypeId
    // changed by changing the value in Select not when it changed by selecting different
    // question on by reordering questions.
    if (isQuestionMoving || currentSelectedIndex !== selectedIndex) {
      return;
    }

    setFieldValue(answerOptionsFieldName, []);
    const questionType = questionTypes.find(
      (type) => type.id == values.items[selectedIndex].questionTypeId,
    );

    // reset initial value
    setFieldValue(initialValueField, '', false);

    if (questionType?.isSelection || questionType?.isMultiselection) {
      // when selection or multiselection, add two required empty answer options
      setFieldValue(answerOptionsFieldName, [emptyAnswerOption, emptyAnswerOption]);
    }
  }, [values.items[selectedIndex]?.questionTypeId]);

  // Set the current selected index AFTER previous useEffect so that we have
  // previous value available in previous useEffect
  useEffect(() => setCurrentSelectedIndex(selectedIndex), [selectedIndex]);

  const selectedQuestionValue = value[selectedIndex];
  const selectedQuestionType = questionTypes.find(
    (type) => type.id == selectedQuestionValue?.questionTypeId,
  );

  const questionTypesSelectOptions: SelectOption[] = questionTypes
    .sort((a, b) => a.id - b.id)
    .map((q) => ({
      value: q.id,
      label: tQuestionnaire(`question.types.${q?.type}` as const),
    }));

  function shouldShowInitialValue(questionType?: QuestionType) {
    if (!questionType) {
      return false;
    }
    return (
      !questionType?.isSelection && !questionType?.isMultiselection && !questionType?.isBoolean
    );
  }

  function shouldShowAnswerOptions(questionType?: QuestionType) {
    if (!questionType) {
      return false;
    }
    return questionType?.isSelection || questionType?.isMultiselection;
  }

  return (
    <React.Fragment>
      <Box display="flex" width="100%">
        <Box width="100%">
          <SelectInput
            id={`${id}[${selectedIndex}].questionTypeId`}
            label={tQuestionnaire('question.type.label')}
            required={true}
            options={questionTypesSelectOptions}
          />
        </Box>
      </Box>

      <TextInput
        id={`${id}[${selectedIndex}].text`}
        label={tQuestionnaire('question.text.label')}
        required={true}
        multiline={true}
        debounceInput={true}
        rows={3}
        helperText={`${selectedQuestionValue?.text.length}/${QUESTION_TEXT_MAX_LENGTH}`}
      />
      {shouldShowInitialValue(selectedQuestionType) && (
        <React.Fragment>
          <Typography variant="subtitle2">
            {tQuestionnaire('question.initialValue.helperText', {
              questionType: selectedQuestionType
                ? tQuestionnaire(`question.types.${selectedQuestionType?.type}` as const)
                : '',
            })}
          </Typography>
          <TextInput
            id={`${id}[${selectedIndex}].initialValue`}
            label={`${tQuestionnaire('question.initialValue.label')} (${tCommon('optional')})`}
            debounceInput={true}
          />
        </React.Fragment>
      )}
      {shouldShowAnswerOptions(selectedQuestionType) && (
        <React.Fragment>
          <Typography variant="subtitle2">
            {/*
              Using Trans component because of html tag in translation.
              The text inside the <Trans> component is only used as a fallback if no translation is found.
            */}
            <Trans t={tQuestionnaire} i18nKey="question.answerOptionArray.helperText">
              Answer Options have an <strong>optional</strong> &quot;Initial&quot; check. When it is
              checked, it will be selected by default for patient when they are filling out this
              Question.
            </Trans>
          </Typography>
          <QuestionAnswersInput id={`${id}[${selectedIndex}].questionnaireAnswerOptions`} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
