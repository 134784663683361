import { StyledTab, StyledTabProps } from '../StyledTab';

interface Props extends StyledTabProps {
  value: string;
  to: string;
}

export function NavTab({ value, to, ...props }: Props) {
  return <StyledTab {...props} value={value} to={to} />; //toto tu robilo problem component={Link}  celkom nechapem preco sa tam posielal link
}
