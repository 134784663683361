import { StyleSheet, Text } from '@react-pdf/renderer';
import React from 'react';

interface Props {
  children: React.ReactElement | string | number;
  style?: Record<string, unknown>;
}

const styles = StyleSheet.create({
  headerCell: {
    flexGrow: 1,
    backgroundColor: '#DDD',
    border: '1px solid black',
    padding: '5px',
    marginHorizontal: '-0.5px',
  },
});

export function TableHeaderCell({ children, style }: Props) {
  return <Text style={[styles.headerCell, style ? style : {}]}>{children}</Text>;
}
