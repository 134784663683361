import React from 'react';
import { Box } from '@mui/material';
import { FormAddButton } from '../AddButton';
import { FieldArray, FieldArrayRenderProps, useField } from 'formik';
import { FormDeleteIconButton } from '../DeleteIconButton';
import { DatePicker } from '../DatePicker';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TimeIcon } from '../../assets/icons/TimeIcon.svg';
import { useFormikDisableContext } from '../../context/formikDisable';

interface Props {
  id: string;
  disabled?: boolean;
  groupCheckboxId: string;
}

export function AlarmTimesInput({ id, disabled = false, groupCheckboxId }: Props) {
  const emptyValue: Date = new Date();
  const [{ value }] = useField<Date[]>(id);
  const [{ value: groupCheckboxValue }, , { setValue: setGroupCheckboxValue }] =
    useField<boolean>(groupCheckboxId);
  const { isEditable } = useFormikDisableContext();

  const { t: tCommon } = useTranslation('common');

  function handleEnableGroup() {
    if (isEditable && !groupCheckboxValue) {
      setGroupCheckboxValue(true);
    }
  }

  function renderInputs(arrayHelpers: FieldArrayRenderProps) {
    return value.map((_, index) => (
      <Box key={index} display="flex" flexDirection="column" marginY={1}>
        <Box width="100%">
          <Box display="flex" my={-1.5}>
            <Box display="flex" justifyContent="center" alignItems="center" p={-1}>
              <FormDeleteIconButton
                disabled={disabled || value.length <= 1}
                onDeleteClick={() => {
                  arrayHelpers.remove(index);
                }}
              />
            </Box>
            <Box display="inline" maxWidth={120} onClick={() => handleEnableGroup()}>
              <DatePicker
                id={`${id}[${index}]`}
                label={tCommon('time')}
                mode="time"
                disabled={disabled}
                showErrorMessage={false}
                keyboardIcon={
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={20}
                    height={20}
                  >
                    <TimeIcon />
                  </Box>
                }
              />
            </Box>
          </Box>
        </Box>
      </Box>
    ));
  }

  function renderArray(arrayHelpers: FieldArrayRenderProps) {
    return (
      <React.Fragment>
        {renderInputs(arrayHelpers)}
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          onClick={() => handleEnableGroup()}
        >
          <FormAddButton
            id={`${id}-addBtn`}
            isIconOnlyButton={true}
            disabled={disabled}
            onAddClick={() => arrayHelpers.push(emptyValue)}
          />
        </Box>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <FieldArray name={id} render={renderArray} />
    </React.Fragment>
  );
}
