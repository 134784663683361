import { StyledMeasurementsTabs } from '../StyledTabs';

interface Props {
  value: string;
  children: React.ReactNode;
}

export function MeasurementNavTabs({ value, children }: Props) {
  return (
    <StyledMeasurementsTabs value={value} onChange={() => {}}>
      {children}
    </StyledMeasurementsTabs>
  );
}
