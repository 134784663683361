import React from 'react';
import { Box } from '@mui/material';
import { useStyles } from './styles';

interface Props {
  upperText?: string;
  lowerText?: string | React.ReactNode;
}

export function IconTitle({ upperText, lowerText }: Props) {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" px={1}>
      <Box className={classes.upperText}>{upperText}</Box>
      <Box className={classes.lowerText}>{lowerText}</Box>
    </Box>
  );
}
