import { styled } from '@mui/system';
import { IconButton as MuiIconButton } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { StyleProps } from './IconButton';

interface CustomIconButtonProps extends StyleProps {
  disabled?: boolean;
  className?: string;
}

export const CustomIconButton = styled(MuiIconButton, {
  shouldForwardProp: (prop) =>
    prop !== 'invertColors' && prop !== 'backgroundColor' && prop !== 'height' && prop !== 'width',
})<CustomIconButtonProps>(({ theme, invertColors, backgroundColor, height, width }) => {
  const typedTheme = theme as Theme; // Type assertion

  return {
    transition: typedTheme.transitions.create(['background-color', 'color', 'border'], {
      easing: typedTheme.transitions.easing.sharp,
      duration: typedTheme.transitions.duration.short,
    }),
    border: backgroundColor
      ? backgroundColor
      : invertColors
        ? '#0065f0'
        : `${typedTheme.typography.pxToRem(1)} solid ${typedTheme.palette.grey[100]}`,
    borderRadius: typedTheme.typography.pxToRem(4),
    padding: typedTheme.spacing(0.4, width ? 0.4 : 6),
    height: height,
    width: width,
    color: invertColors ? typedTheme.palette.primary.main : '#e5eefd',
    backgroundColor: backgroundColor
      ? backgroundColor
      : invertColors
        ? '#e5eefd'
        : typedTheme.palette.primary.main,
    boxShadow: 'none',
    '&:hover, &:focus': {
      border: invertColors ? '#0065f0' : `${typedTheme.typography.pxToRem(1)} solid #041e74`,
      boxShadow: 'none',
      color: invertColors ? typedTheme.palette.common.white : 'auto',
      backgroundColor: invertColors
        ? typedTheme.palette.primary.main
        : typedTheme.palette.primary.dark,
    },
    '&.Mui-disabled': {
      backgroundColor: '#f3f4f7 !important',
      color: '#a3a9bd !important',
    },
    '.MuiButton-label': {
      fontSize: typedTheme.typography.pxToRem(14),
      fontFamily: 'Inter',
      fontWeight: 'normal',
    },
  };
});
