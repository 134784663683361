import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useStyles } from './styles';

export function ScoringCategoriesTitle() {
  const { t } = useTranslation('questionnaire');
  const classes = useStyles();

  return (
    <Box display="inline" className={classes.scoringCategoriesTitle}>
      {t('scoringCategories')}
    </Box>
  );
}
