import { Functionality } from '../../models';

export const PATIENT_THRESHOLDS_READ_POLICY: Functionality[] = [
  'PATIENT_THRESHOLDS_READ',
  'PATIENT_ASSIGNED_TO_MEDIC_THRESHOLDS_READ',
];

export const PATIENT_THRESHOLDS_CREATE_OR_UPDATE_POLICY: Functionality[] = [
  'PATIENT_THRESHOLDS_CREATE_OR_UPDATE',
  'PATIENT_ASSIGNED_TO_MEDIC_THRESHOLDS_CREATE_OR_UPDATE',
];
