import { Functionality } from '../../models';

export const PATIENT_NOTES_READ_POLICY: Functionality[] = [
  'PATIENT_NOTES_READ',
  'PATIENT_ASSIGNED_TO_MEDIC_NOTES_READ',
];

export const PATIENT_NOTES_CREATE_POLICY: Functionality[] = [
  'PATIENT_NOTES_CREATE',
  'PATIENT_ASSIGNED_TO_MEDIC_NOTES_CREATE',
];

export const PATIENT_NOTES_UPDATE_POLICY: Functionality[] = [
  'PATIENT_NOTES_UPDATE',
  'PATIENT_ASSIGNED_TO_MEDIC_NOTES_UPDATE',
];

export const PATIENT_NOTES_DELETE_POLICY: Functionality[] = [
  'PATIENT_NOTES_DELETE',
  'PATIENT_ASSIGNED_TO_MEDIC_NOTES_DELETE',
];
