import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { DeleteCompletedHandler, DeleteHandler } from '../../components/Grid';
import { Device, Organization } from '../../models';
import { DevicesGrid } from '../../components/Grids/DevicesGrid';

interface Props {
  devices: Device[] | null;
  organizations: Organization[] | null;
  onDelete?: DeleteHandler;
  onDeleteCompleted?: DeleteCompletedHandler<Device>;
  onAddOrganizationHandle: (deviceIds: string[], organizationId: string) => Promise<void>;
}

export function DevicesList({
  devices,
  organizations,
  onDelete,
  onDeleteCompleted,
  onAddOrganizationHandle,
}: Props) {
  const navigate = useNavigate();

  function onRowClick(row: Device) {
    navigate(`/devices/${row.id}`);
  }

  return (
    <Box width="100%">
      {devices && (
        <DevicesGrid
          data={devices}
          organizations={organizations}
          onDelete={onDelete}
          onDeleteCompleted={onDeleteCompleted}
          onRowClick={onRowClick}
          onAddOrganizationHandle={onAddOrganizationHandle}
        />
      )}
    </Box>
  );
}
