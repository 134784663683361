import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import { ProgressIndicator } from '../../components/ProgressIndicator';
import { DailyPlanTemplate } from '../../models';
import { DailyPlanTemplatesGrid } from '../../components/Grids';
import { Page } from '../../components/Page';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FunctionalityProvider } from '../../context/functionality';
import { PageTopBar } from '../../components/PageTopBar';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import { useEntityActionSnackbar } from '../../hooks';
import { Box } from '@mui/material';

export function ListDailyPlanTemplatesPage() {
  const { t } = useTranslation('dailyPlanTemplate');
  const { enqueueEntityDeletedSnackbar } = useEntityActionSnackbar();
  const [
    { data: dailyPlanTemplates, isError, isLoading: areDailyPlanTemplatesLoading },
    { refetch: refetchDailyPlanTemplates },
    { lastFetchDatetime },
  ] = useGetApi<DailyPlanTemplate[]>(ApiRoutes.DailyPlanTemplates);
  const navigate = useNavigate();

  async function handleDelete(id: number) {
    await makeApiRequest('DELETE', ApiRoutes.DailyPlanTemplate(id));
  }

  async function handleDeleteCompleted() {
    await refetchDailyPlanTemplates();
    enqueueEntityDeletedSnackbar(t('title.template'));
  }

  function handleRowClick(row: DailyPlanTemplate) {
    navigate(`/daily-plan-templates/${row.id}`);
  }

  if (areDailyPlanTemplatesLoading) {
    return <ProgressIndicator />;
  }

  return (
    <FunctionalityProvider
      createFunctionality="DAILY_PLAN_TEMPLATES_CREATE"
      readFunctionality="DAILY_PLAN_TEMPLATES_READ"
      updateFunctionality="DAILY_PLAN_TEMPLATES_UPDATE"
      deleteFunctionality="DAILY_PLAN_TEMPLATES_DELETE"
    >
      <>
        <StickyTopAppBar>
          <PageTopBar
            title={t('title.list')}
            addNewRedirectPath={'/daily-plan-templates/new'}
            newRecordDescriptionText={t('title.new')}
            lastUpdated={lastFetchDatetime}
          />
        </StickyTopAppBar>
        <Page noPaper={true} requireFunctionality="DAILY_PLAN_TEMPLATES_READ">
          <Box width="100%">
            {!areDailyPlanTemplatesLoading && !isError && dailyPlanTemplates && (
              <DailyPlanTemplatesGrid
                onDelete={handleDelete}
                onDeleteCompleted={handleDeleteCompleted}
                data={dailyPlanTemplates}
                onRowClick={handleRowClick}
              />
            )}
          </Box>
        </Page>
      </>
    </FunctionalityProvider>
  );
}
