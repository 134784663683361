import { Card, CardContent } from '@mui/material';
import { useStyles } from './styles';
import React from 'react';
import { NavTab, NavTabPanel, NavTabs } from '../../components/NavTabs';
import { useTranslation } from 'react-i18next';
import { ProtectedRoute } from '../../components/ProtectedRoute';
import { useLocation } from 'react-router';
import { PatientCardIcon } from '../../components/Icons';
import { Navigation } from '../../types';
import { getTabsValue, isMatch } from '../../utils';
import { theme } from '../../assets/style';
import { MedicForm, MedicFormValues } from './MedicForm';
import { SubmitFormCompletedHandler, SubmitFormHandler } from '../../components/Form';
import { Department, Qualification, UserRole } from '../../models';
import { GridContextProvider } from '../../context';
import { MedicPatientsCardIcon } from '../../components/Icons/MedicPatientsCardIcon';
import { MedicPatients } from './patients';
import { FunctionalityProvider } from '../../context/functionality';

import {
  PATIENTS_DELETE_POLICY,
  PATIENTS_READ_POLICY,
  PATIENTS_UPDATE_POLICY,
} from '../../constants/Policies';
import { Route, Routes } from 'react-router-dom';

interface Props {
  id: string;
  initialValues?: MedicFormValues;
  onSubmit: SubmitFormHandler<MedicFormValues>;
  onSubmitCompleted: SubmitFormCompletedHandler;
  qualifications: Qualification[];
  departments: Department[];
  userRoles: UserRole[];
}

export function MedicNavigation({
  id,
  initialValues,
  onSubmit,
  onSubmitCompleted,
  qualifications,
  departments,
  userRoles,
}: Props) {
  const { t } = useTranslation(['medic', 'patient']);
  const location = useLocation();
  const classes = useStyles();
  const medicPath = `/medics/${id}`;

  const nav: Navigation = {
    medic: {
      tab: medicPath,
      route: {
        path: medicPath,
      },
      subRoutes: {},
    },
    patients: {
      tab: `${medicPath}/patients`,
      route: {
        path: `${medicPath}/patients`,
      },
      subRoutes: {},
    },
  };

  return (
    <React.Fragment>
      <NavTabs
        value={getTabsValue(nav, location.pathname)}
        aria-label="medic tabs"
        id="medic-nav-tabs"
      >
        <NavTab
          label={t('medic:card')}
          to={nav.medic.route.path as string}
          icon={
            <PatientCardIcon
              {...(isMatch(nav.medic.route, location.pathname)
                ? {}
                : {
                    primaryColor: theme.palette.grey['500'],
                    secondaryColor: theme.palette.grey['500'],
                  })}
            />
          }
          value={nav.medic.tab}
          requireFunctionality="MEDICS_READ"
        />
        <NavTab
          label={t('patient:title.list')}
          to={nav.patients.route.path as string}
          icon={
            <MedicPatientsCardIcon
              {...(isMatch(nav.patients.route, location.pathname, false)
                ? {}
                : {
                    primaryColor: theme.palette.grey['500'],
                    secondaryColor: theme.palette.grey['500'],
                  })}
            />
          }
          value={nav.patients.tab}
          requireFunctionality={PATIENTS_READ_POLICY}
        />
      </NavTabs>
      <Card className={`${classes.fullWidth} ${classes.boxShadowAndBorderRadius}`}>
        <CardContent>
          <NavTabPanel>
            <Routes>
              <Route
                path={'/'}
                element={
                  <ProtectedRoute requireFunctionality="MEDICS_READ">
                    <FunctionalityProvider
                      createFunctionality="MEDICS_CREATE"
                      readFunctionality="MEDICS_READ"
                      updateFunctionality="MEDICS_UPDATE"
                      deleteFunctionality="MEDICS_DELETE"
                    >
                      <MedicForm
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onSubmitCompleted={onSubmitCompleted}
                        qualifications={qualifications}
                        departments={departments}
                        userRoles={userRoles}
                      />
                    </FunctionalityProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path={'/patients'}
                element={
                  <ProtectedRoute requireFunctionality={PATIENTS_READ_POLICY}>
                    <FunctionalityProvider
                      createFunctionality="PATIENTS_CREATE"
                      readFunctionality={PATIENTS_READ_POLICY}
                      updateFunctionality={PATIENTS_UPDATE_POLICY}
                      deleteFunctionality={PATIENTS_DELETE_POLICY}
                    >
                      <GridContextProvider>
                        <MedicPatients medicId={id} />
                      </GridContextProvider>
                    </FunctionalityProvider>
                  </ProtectedRoute>
                }
              />
            </Routes>
          </NavTabPanel>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
