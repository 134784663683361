import { Functionality } from '../models';
import { useCurrentUser } from '../context';

interface RequireFunctionalityResult {
  hasFunctionality: boolean;
  checkHasFunctionality: (functionality: Functionality | Functionality[]) => boolean;
}

export function useRequireFunctionality(
  functionality?: Functionality | Functionality[],
): RequireFunctionalityResult {
  const { user } = useCurrentUser();

  function checkHasFunctionality(functionality: Functionality | Functionality[]) {
    if (Array.isArray(functionality)) {
      return !!user && Array.from(user.functionalities).some((f) => functionality.includes(f));
    }

    return !!user && user.functionalities.has(functionality);
  }

  let hasFunctionality = false;
  if (!functionality) {
    // no functionality is required
    hasFunctionality = true;
  } else {
    hasFunctionality = checkHasFunctionality(functionality);
  }

  return { hasFunctionality, checkHasFunctionality };
}
