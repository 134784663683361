import { Page } from '../../../components/Page';
import { useTranslation } from 'react-i18next';
import { PageTopBar } from '../../../components/PageTopBar';
import { StickyTopAppBar } from '../../../components/StickyTopAppBar';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { IntegrationTokenComponent } from '../../../components/TokenComponent';
import { AdminProfile } from '../../../models/adminProfile';
import { ApiRoutes, useGetApi } from '../../../services/api';
import { ProgressIndicator } from '../../../components/ProgressIndicator';
import { ErrorPage } from '../../error';
import { useStyles } from './styles';
import { IntegrationTypeEnum } from '../../../models';

export function AdminProfilePage() {
  const [{ data, isLoading, isError }] = useGetApi<AdminProfile>(ApiRoutes.AdminProfile);
  const { t } = useTranslation('profile');
  const classes = useStyles();
  if (isLoading) {
    return <ProgressIndicator />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  function getIntegrationTypeString(type: IntegrationTypeEnum, t: (key: string) => string): string {
    switch (type) {
      case IntegrationTypeEnum.None:
        return t('integrationTypeName.none');
      case IntegrationTypeEnum.IRSoft:
        return t('integrationTypeName.irsoft');
      default:
        return t('integrationTypeName.none');
    }
  }

  return (
    <>
      <StickyTopAppBar>
        <PageTopBar title={t('title')} />
      </StickyTopAppBar>
      <Box>
        <Page>
          <Box className={classes.formRow}>
            <Typography variant="h5" component={'div'} className={classes.bold}>
              {t('admin.info')}
            </Typography>
          </Box>
          <Divider className={classes.formRow} />
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography display="inline">{t('admin.name')}</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography display="inline">{data?.fullName}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography display="inline">{t('admin.userName')}</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography display="inline">{data?.userName}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography display="inline">{t('admin.email')}</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography display="inline">{data?.email}</Typography>
            </Grid>
          </Grid>
          <Typography variant="h6" component={'div'} className={classes.bold}>
            {t('admin.organization')}
          </Typography>
          <Divider className={classes.formRow} />
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography display="inline">{t('admin.organizationName')}</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography display="inline">{data?.organizationName}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography display="inline">{t('admin.ico')}</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography display="inline">{data?.ico}</Typography>
            </Grid>
          </Grid>
          <Typography variant="h6" component={'div'} className={classes.bold}>
            {t('admin.integration')}
          </Typography>
          <Divider className={classes.formRow} />
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography display="inline">{t('admin.integrationType')}</Typography>
            </Grid>
            <Grid item xs={10}>
              {data && (
                <Typography display="inline">
                  {getIntegrationTypeString(data.integrationType, t)}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Box>{data && <IntegrationTokenComponent adminProfile={data} />}</Box>
        </Page>
      </Box>
    </>
  );
}
