import { CssBaseline, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { theme } from './assets/style';
import { CurrentUserProvider, SnackbarProvider } from './context';
import { AppRoutes } from './components/Routes';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getCurrentDatetimeLocale } from './i18n';
import { CookieBanner } from './components/Cookie';
import { initializeGoogleAnalytics } from './services/googleAnalytics';
import { DashboardMenuProvider } from './context/dashboardMenu';
import { initAmplitude } from './utils/amplitude';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export function App() {
  initAmplitude();
  const datetimeLocale = getCurrentDatetimeLocale();

  return (
    <CurrentUserProvider>
      <DashboardMenuProvider>
        <SnackbarProvider>
          <DndProvider backend={HTML5Backend}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={datetimeLocale}>
                  <CssBaseline />
                  <CookieBanner onAccept={initializeGoogleAnalytics} />
                  <Router>
                    <AppRoutes />
                  </Router>
                </LocalizationProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </DndProvider>
        </SnackbarProvider>
      </DashboardMenuProvider>
    </CurrentUserProvider>
  );
}
