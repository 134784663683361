import { Group } from '../../models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTopBarDetailed } from '../PageTopBarDetailed';

interface Props {
  group: Group;
  lastUpdated: Date | null;
}

export function GroupHeader({ group, lastUpdated }: Props) {
  const { t } = useTranslation('group');
  return (
    <PageTopBarDetailed
      entityName={t('title.department', { name: group.name, context: group.description })}
      lastUpdated={lastUpdated}
    />
  );
}
