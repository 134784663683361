import { Box } from '@mui/material';
import { theme } from '../../assets/style';

interface IconProps {
  width?: number;
  height?: number;
  primaryColor?: string;
  secondaryColor?: string;
}

export function MedicPatientsCardIcon({
  width,
  height,
  primaryColor = theme.palette.primary.main,
  secondaryColor = theme.palette.primary.dark,
}: IconProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <svg
        width={`${width ?? 16}`}
        height={`${height ?? 15}`}
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M8.45833 14.5C8.26408 14.5 8.0815 14.4031 7.973 14.2404L6.97958 12.75H5.83333C5.51133 12.75 5.25 12.4886 5.25 12.1666C5.25 11.8446 5.51133 11.5833 5.83333 11.5833H7.29167C7.4865 11.5833 7.6685 11.6807 7.777 11.8429L8.386 12.7564L9.68625 10.1553C9.78192 9.96515 9.97267 9.84148 10.1856 9.83332C10.4119 9.82807 10.5986 9.93365 10.7083 10.1156L12.2885 12.75H13.4167C13.7387 12.75 14 13.0113 14 13.3333C14 13.6553 13.7387 13.9166 13.4167 13.9166H11.9583C11.7536 13.9166 11.5634 13.8093 11.4584 13.6337L10.255 11.6282L8.98042 14.178C8.88708 14.3641 8.70217 14.4866 8.4945 14.4994C8.48283 14.5 8.47058 14.5 8.45833 14.5Z"
            fill={primaryColor}
          />
          <path
            d="M5.24992 6.33333C6.86075 6.33333 8.16658 5.0275 8.16658 3.41667C8.16658 1.80584 6.86075 0.5 5.24992 0.5C3.63909 0.5 2.33325 1.80584 2.33325 3.41667C2.33325 5.0275 3.63909 6.33333 5.24992 6.33333Z"
            fill={primaryColor}
          />
          <path
            d="M4.08333 12.1667C4.08333 11.2018 4.8685 10.4167 5.83333 10.4167H7.29167C7.59442 10.4167 7.88608 10.4943 8.14217 10.6366L8.64325 9.63383C8.89817 9.12575 9.3835 8.79267 9.93475 8.704C9.40392 7.97775 8.55225 7.5 7.58333 7.5H2.91667C1.30608 7.5 0 8.80608 0 10.4167V12.3125C0 12.554 0.196 12.75 0.4375 12.75H4.19067C4.12533 12.5668 4.08333 12.372 4.08333 12.1667Z"
            fill={primaryColor}
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear94"
            x1="12.1665"
            y1="7.47434"
            x2="8.37877"
            y2="9.97791"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={primaryColor} />
            <stop offset="0.9996" stopColor={secondaryColor} />
          </linearGradient>
          <linearGradient
            id="paint1_linear94"
            x1="13.2266"
            y1="12.008"
            x2="11.6356"
            y2="15.3731"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={primaryColor} />
            <stop offset="0.9996" stopColor={secondaryColor} />
          </linearGradient>
          <linearGradient
            id="paint2_linear94"
            x1="6.979"
            y1="0.852061"
            x2="4.44463"
            y2="4.03481"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={primaryColor} />
            <stop offset="0.9996" stopColor={secondaryColor} />
          </linearGradient>
          <linearGradient
            id="paint3_linear94"
            x1="8.86246"
            y1="3.30618"
            x2="1.27148"
            y2="9.30847"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={primaryColor} />
            <stop offset="0.9996" stopColor={secondaryColor} />
          </linearGradient>
          <clipPath id="clip094">
            <rect width="15" height="14" fill="white" transform="translate(0.400391 0.5)" />
          </clipPath>
          <clipPath id="clip194">
            <rect width="14" height="14" fill="white" transform="translate(-0.0996094 0.5)" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
}
