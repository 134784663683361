import { getCookieConsentValue } from 'react-cookie-consent';
import { CONSENT_COOKIE_NAME } from '../../constants';
import { hotjar } from 'react-hotjar';

export function isHotjarConsentGranted() {
  const consent = getCookieConsentValue(CONSENT_COOKIE_NAME);

  return !!consent;
}

export async function initializeHotjar() {
  try {
    const hotjarIdEnv = process.env.REACT_APP_HOTJAR_ID;
    const isTestPlatform = process.env.REACT_APP_IS_TEST_PLATFORM?.toLowerCase() === 'true';
    if (!hotjarIdEnv || isTestPlatform) {
      return;
    }

    const hotjarId = parseFloat(hotjarIdEnv);
    if (isNaN(hotjarId)) {
      return;
    }

    hotjar.initialize({ id: hotjarId, sv: 6 });
    // we raise pageview event here because initialization is run asynchronously and otherwise
    // we might skip this pageview
    hotjar.stateChange(window.location.pathname + window.location.search);
  } catch {
    /*
     * adblock might block loading of hotjar
     * and web can function properly without hotjar loaded
     * therefor we ignore this error
     */
  }
}
