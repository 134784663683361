import { Paper } from '@mui/material';
import { Functionality } from '../../models';
import { useStyles } from './styles';
import { RequirePageFunctionality } from '../RequireFunctionality';
import { ReactNode } from 'react';

export interface PageStyleProps {
  fullWidth?: boolean;
}

interface Props extends PageStyleProps {
  requireFunctionality?: Functionality | Functionality[];
  children: ReactNode;
  noPaper?: boolean;
}

export function Page({ children, requireFunctionality, noPaper, ...styleProps }: Props) {
  const classes = useStyles(styleProps);

  let innerComponent = <div className={classes.pageContent}>{children}</div>;
  if (!noPaper) {
    innerComponent = <Paper>{innerComponent}</Paper>;
  }

  return (
    <RequirePageFunctionality functionality={requireFunctionality}>
      <div className={!styleProps.fullWidth ? classes.pageLayoutNotFull : classes.pageLayout}>
        {innerComponent}
      </div>
    </RequirePageFunctionality>
  );
}
