import React from 'react';
import { PatientQuestionnaire } from '../../../models';
import { QuestionnairePreviewPage } from '../../questionnaires/QuestionnairePreviewPage';

interface Props {
  questionnaire: PatientQuestionnaire | null;
}

export function UnansweredPatientQuestionnairePage({ questionnaire }: Props) {
  return (
    <QuestionnairePreviewPage
      questionnaire={questionnaire}
      showOnlyMainContent={true}
      datetime={questionnaire?.assignmentDatetime}
    />
  );
}
