import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useCreateAlarmOrAddFromTemplateShowHideFormStyles = makeStyles((theme: Theme) => ({
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    '&>*': {
      margin: theme.spacing(0, 0.6),
    },
    '&>*:first-child': {
      marginRight: theme.spacing(0.6),
      marginLeft: 0,
    },
    '&>*:last-child': {
      marginLeft: theme.spacing(0.6),
      marginRight: 0,
    },
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
}));

export const useAlarmsFromTemplateFormStyles = makeStyles((theme: Theme) => ({
  buttonWrapper: {
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  },
}));
