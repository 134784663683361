import { Box, Divider, Hidden } from '@mui/material';
import { CheckboxInput } from '../../CheckboxInput';
import { AlarmTimesInput } from '../../AlarmTimesInput';
import { ErrorMessage, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AlarmFormValues } from '../../AlarmStepperForm';
import { useAlarmFormPartialTimesStyles } from './styles';
import { useAlarmFormPartialCommonStyles } from '../styles';

export function AlarmFormPartialTimes() {
  const { t: tCommon } = useTranslation('common');
  const { values } = useFormikContext<AlarmFormValues>();
  const classes = useAlarmFormPartialTimesStyles();
  const commonClasses = useAlarmFormPartialCommonStyles();
  return (
    <>
      <Box py={0} px={0} className={classes.alarmTimesWrapper}>
        <Box className={classes.alarmTimesPartOfDayWrapper}>
          <CheckboxInput
            id="triggerTimes.morning"
            label={tCommon('partsOfDay.morning')}
            setTouchedWhenCheckedOnLoad={true}
          />
          <AlarmTimesInput
            id="triggerTimes.morningTimes"
            disabled={!values.triggerTimes.morning}
            groupCheckboxId={'triggerTimes.morning'}
          />
          <ErrorMessage
            name="triggerTimes.morningTimes"
            className={commonClasses.customErrorMessage}
            component="div"
          />
        </Box>
        <Hidden smUp={true}>
          <Box mx={10} my={1}>
            <Divider orientation="horizontal" />
          </Box>
        </Hidden>
        <Box className={classes.alarmTimesPartOfDayWrapper}>
          <CheckboxInput
            id="triggerTimes.afternoon"
            label={tCommon('partsOfDay.afternoon')}
            setTouchedWhenCheckedOnLoad={true}
          />
          <AlarmTimesInput
            id="triggerTimes.afternoonTimes"
            disabled={!values.triggerTimes.afternoon}
            groupCheckboxId={'triggerTimes.afternoon'}
          />
          <ErrorMessage
            name="triggerTimes.afternoonTimes"
            className={commonClasses.customErrorMessage}
            component="div"
          />
        </Box>
        <Hidden smUp={true}>
          <Box mx={10} my={1}>
            <Divider orientation="horizontal" />
          </Box>
        </Hidden>
        <Box className={classes.alarmTimesPartOfDayWrapper}>
          <CheckboxInput
            id="triggerTimes.evening"
            label={tCommon('partsOfDay.evening')}
            setTouchedWhenCheckedOnLoad={true}
          />
          <AlarmTimesInput
            id="triggerTimes.eveningTimes"
            disabled={!values.triggerTimes.evening}
            groupCheckboxId={'triggerTimes.evening'}
          />
          <ErrorMessage
            name="triggerTimes.eveningTimes"
            className={commonClasses.customErrorMessage}
            component="div"
          />
        </Box>
      </Box>
      <Box>
        <ErrorMessage
          name="triggerTimes"
          className={commonClasses.customErrorMessage}
          component="div"
        >
          {(x) => {
            if (typeof x === 'object') {
              return null;
            }

            return <div className={commonClasses.customErrorMessage}>{x}</div>;
          }}
        </ErrorMessage>
      </Box>
    </>
  );
}
