import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from '../ProtectedRoute';
import { EDIT_SUBPATH } from '../../constants';
import { MedicPage } from '../../pages/medics/MedicPage';

export function MedicRoutes() {
  return (
    <Routes>
      <Route
        path={`${EDIT_SUBPATH}`}
        element={
          <ProtectedRoute requireFunctionality="MEDICS_READ">
            <MedicPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/*"
        element={
          <ProtectedRoute requireFunctionality="MEDICS_READ">
            <MedicPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
