import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useInstallationStepperFormStyles = makeStyles((theme: Theme) => ({
  root: {
    '&::before': {
      content: 'none',
    },
  },
  formWrapper: {
    maxWidth: '100%',
    flexGrow: 1,
  },
  headerText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontFamily: 'Inter',
    padding: theme.spacing(2.5),
    borderRadius: theme.spacing(1),
    lineHeight: 1.29,
    minWidth: 100,
  },
  title: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 800,
    padding: theme.spacing(1, 0),
    textAlign: 'center',
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 300,
    paddingLeft: theme.spacing(0.25),
  },
  content: {
    minHeight: 428,
    maxWidth: '100%',
    width: '100%',
    padding: theme.spacing(2),
  },
  closeButtonWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  closeButton: {
    height: `${theme.typography.pxToRem(32)} !important`,
    width: `${theme.typography.pxToRem(32)} !important`,
    border: 'unset !important',
  },
}));
