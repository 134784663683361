import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0.25, 0.5),
    borderRadius: theme.spacing(0.25),
    border: 'solid 1px #e5eefd',
    backgroundColor: '#f5f8fe',
  },
  textWrapper: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
    lineHeight: 1.33,
    textAlign: 'center',
    color: '#054ac7',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
