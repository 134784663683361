import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ProgressIndicator } from '../../components/ProgressIndicator';
import { Note, NoteEditInput } from '../../models';
import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import { PatientNoteForm, PatientNoteFormValues } from './PatientNoteForm';
import { useEntityActionSnackbar } from '../../hooks';

export function NoteSubPage() {
  const { t } = useTranslation('note');
  const { enqueueEntityUpdatedSnackbar } = useEntityActionSnackbar();
  const { id, noteId } = useParams<{ id: string; noteId: string }>();
  const [{ data, isLoading }] = useGetApi<Note>(
    ApiRoutes.PatientNote(id as string, noteId as string),
  );

  function handleSubmit(values: PatientNoteFormValues) {
    return makeApiRequest<never, NoteEditInput>(
      'PUT',
      ApiRoutes.PatientNote(id as string, noteId as string),
      values,
    );
  }

  function handleSubmitCompleted() {
    // go back two steps - first one is edit subpage, second one is patient notes list
    history.go(-2);

    enqueueEntityUpdatedSnackbar(t('note'), { context: 'female' });
  }

  if (isLoading) {
    return <ProgressIndicator />;
  }

  if (data == null) {
    return <div>{t('error.noteNotFound')}</div>;
  }

  return (
    <PatientNoteForm
      initialValues={{ ...data, author: data.author || t('author.default') }}
      onSubmit={handleSubmit}
      onSubmitCompleted={handleSubmitCompleted}
    />
  );
}
