import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useAlarmFormPartialRepetitionStyles = makeStyles((theme: Theme) => ({
  alarmDatesWrapper: {
    display: 'flex',
    flex: 1,
    marginLeft: theme.spacing(6),
    columnGap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      margin: theme.spacing(0, 2),
    },
  },
  alarmTimesWrapper: {
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 2),
    },
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
}));
