import { useParams } from 'react-router-dom';
import { ApiRoutes, useGetApi } from '../../services/api';
import { PatientDevicePairing } from '../../models';
import React from 'react';
import { PatientDevicePairingForm } from './PatientDevicePairingForm';
import { useTranslation } from 'react-i18next';
import { PatientDevicePairingFormSkeleton } from './PatientDevicePairingFormSkeleton';

export function PairingSubPage() {
  const { t } = useTranslation('patientDevicePairing');
  const { id, pairingId } = useParams<{ id: string; pairingId: string }>();

  const [{ data: patientDevicePairing, isLoading: isPatientDevicePairingLoading }] =
    useGetApi<PatientDevicePairing>(
      ApiRoutes.PatientDevicePairing(id as string, pairingId as string),
    );

  if (isPatientDevicePairingLoading) {
    return <PatientDevicePairingFormSkeleton />;
  }

  if (patientDevicePairing == null) {
    return <div>{t('error.pairingNotFound')}</div>;
  }

  return <PatientDevicePairingForm initialValues={patientDevicePairing} />;
}
