import { Box } from '@mui/material';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  instanceOfQuestionnaireResponseItemBoolean,
  instanceOfQuestionnaireResponseItemCoding,
  instanceOfQuestionnaireResponseItemDouble,
  instanceOfQuestionnaireResponseItemString,
} from '../../../../guards';
import {
  AnswerOption,
  QuestionnaireQuestion,
  QuestionnaireResponseItem,
  QuestionnaireResponseItemBoolean,
  QuestionnaireResponseItemCoding,
  QuestionnaireResponseItemDouble,
  QuestionnaireResponseItemString,
} from '../../../../models';
import { getIconByQuestionType } from '../../../../utils';
import { QuestionnaireResponseAnswerList } from '../AnswerList';
import { useStyles } from './styles';

interface Props {
  question: QuestionnaireQuestion;
  index: number;
  questionAnswer?: QuestionnaireResponseItem;
}

export function QuestionnaireResponseQuestion({ question, index, questionAnswer }: Props) {
  const { t } = useTranslation('questionnaire');
  const classes = useStyles();

  const selectedAnswers: AnswerOption[] = [];
  const notSelectedAnswers: AnswerOption[] = [];

  function getQuestionAnswer() {
    if (instanceOfQuestionnaireResponseItemString(questionAnswer)) {
      return (questionAnswer as QuestionnaireResponseItemString).valueString;
    } else if (instanceOfQuestionnaireResponseItemDouble(questionAnswer)) {
      return (questionAnswer as QuestionnaireResponseItemDouble).valueDouble;
    } else if (instanceOfQuestionnaireResponseItemBoolean(questionAnswer)) {
      return (questionAnswer as QuestionnaireResponseItemBoolean).valueBoolean
        ? t('question.answer.booleanTrue')
        : t('question.answer.booleanFalse');
    } else if (instanceOfQuestionnaireResponseItemCoding(questionAnswer)) {
      const patientQuestionAnswers = (questionAnswer as QuestionnaireResponseItemCoding)
        .valueCoding;

      question.questionnaireAnswerOptions.forEach((qa) => {
        if (patientQuestionAnswers.includes(qa.id)) {
          selectedAnswers.push({
            id: qa.id,
            value: qa.value,
            initialValue: qa.initialValue,
            score: qa.score,
          });
        } else {
          notSelectedAnswers.push({
            id: qa.id,
            value: qa.value,
            initialValue: qa.initialValue,
            score: qa.score,
          });
        }
      });

      return (
        <React.Fragment>
          <QuestionnaireResponseAnswerList answers={selectedAnswers} selected={true} />
          <QuestionnaireResponseAnswerList answers={notSelectedAnswers} selected={false} />
        </React.Fragment>
      );
    }

    return '';
  }

  function addAnswerWrapper(children: ReactNode) {
    return <Box className={classes.questionAnswer}>{children}</Box>;
  }

  return (
    <Box mx={0.5} mb={2}>
      <Box display="flex" px={1.25} py={0.625} className={classes.questionTextLine}>
        <Box mr={1.5}>{index + 1}.</Box>
        <Box className={classes.questionText}>{question.text}</Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center" justifyContent="center">
          {getIconByQuestionType(question.type.type, true)}
        </Box>
        {addAnswerWrapper(getQuestionAnswer())}
      </Box>
    </Box>
  );
}
