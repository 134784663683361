import { useEffect, useState } from 'react';
import { useStyles } from './styles';

interface Color {
  red: number;
  green: number;
  blue: number;
}

interface Props {
  categoryIndex: number; // zero-based
  categoriesCount: number;
}

export function ScoringCategoryColorIndicator({ categoriesCount, categoryIndex }: Props) {
  const startColor: Color = { red: 0, green: 255, blue: 0 };
  const endColor: Color = { red: 255, green: 0, blue: 0 };
  const [color, setColor] = useState<Color>({ red: 0, green: 0, blue: 0 });
  const classes = useStyles();

  useEffect(() => {
    const interpolatedColor = interpolateColor(
      startColor,
      endColor,
      categoryIndex / (categoriesCount - 1) || 0,
    );
    setColor(interpolatedColor);
  }, [categoryIndex, categoriesCount]);

  return (
    <span
      className={classes.indicator}
      style={{
        backgroundColor: colorToCss(color),
      }}
    />
  );
}

function interpolateColor(startColor: Color, endColor: Color, fraction: number): Color {
  return {
    red: Math.round((endColor.red - startColor.red) * fraction + startColor.red),
    green: Math.round((endColor.green - startColor.green) * fraction + startColor.green),
    blue: Math.round((endColor.blue - startColor.blue) * fraction + startColor.blue),
  };
}

function colorToCss(color: Color) {
  return `rgb(${color.red}, ${color.green}, ${color.blue})`;
}
