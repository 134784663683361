import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const usePatientDevicePairingFormStyles = makeStyles((theme: Theme) => ({
  input: {
    minHeight: theme.typography.pxToRem(100),
    '& .MuiInputBase-root': {
      minHeight: theme.typography.pxToRem(100),
      alignItems: 'start',
    },
  },
  backButton: {
    borderRadius: theme.typography.pxToRem(20),
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    '@supports (column-gap: 8px)': {
      columnGap: theme.spacing(1),
    },
    '@supports not (column-gap: 8px)': {
      '&>*': {
        margin: theme.spacing(0, 0.6),
      },
      '&>*:first-child': {
        marginRight: theme.spacing(0.6),
        marginLeft: 0,
      },
      '&>*:last-child': {
        marginLeft: theme.spacing(0.6),
        marginRight: 0,
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));
