import { InputAdornment, TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { getInputErrorText } from '../InputErrorText';
import { NumberFormatInput } from './NumberFormatInput';
import { useFormikDisableContext } from '../../context/formikDisable';
import { useDebouncedInput } from '../../hooks';
import React, { FocusEventHandler, ReactEventHandler, ReactNode } from 'react';

interface Props {
  id: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  integerOnly?: boolean;
  placeholder?: string;
  className?: string;
  maxDecimalNumbersCount?: number;
  debounceInput?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  onMouseEnter?: ReactEventHandler<HTMLDivElement>;
  onMouseLeave?: ReactEventHandler<HTMLDivElement>;
  onSelect?: ReactEventHandler<HTMLDivElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: () => void;
  showErrorMessage?: boolean;
}

export function NumberInput({
  id,
  label,
  required,
  disabled,
  integerOnly,
  placeholder,
  className,
  startAdornment,
  endAdornment,
  maxDecimalNumbersCount,
  onMouseEnter,
  onMouseLeave,
  onSelect,
  onBlur,
  onChange,
  debounceInput = false,
  showErrorMessage = true,
}: Props) {
  const [{ value, onChange: formikOnChange, ...field }, meta, { setTouched }] = useField(id);
  const { debouncedValue, onChangeDebounced } = useDebouncedInput(value, formikOnChange);
  const { isSubmitting } = useFormikContext();
  const { isDisabled } = useFormikDisableContext();

  const errorMessage = meta.touched ? meta.error : null;
  // Fix for warning: `value` prop on `input` should not be null.
  let fieldValue = debounceInput ? debouncedValue : value;
  fieldValue = fieldValue === null ? '' : fieldValue;
  if (maxDecimalNumbersCount && fieldValue != '') {
    fieldValue = parseFloat(fieldValue).toFixed(maxDecimalNumbersCount);
  }

  return (
    <TextField
      {...field}
      placeholder={placeholder}
      value={fieldValue}
      onChange={(e) => {
        if (maxDecimalNumbersCount) {
          e.target.value =
            e.target.value != null
              ? parseFloat(e.target.value).toFixed(maxDecimalNumbersCount)
              : '';
        }
        if (onChange) {
          onChange();
        }

        debounceInput ? onChangeDebounced(e) : formikOnChange(e);
        setTouched(true);
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onSelect={onSelect}
      onBlur={onBlur}
      id={id}
      label={label}
      error={!!errorMessage}
      helperText={showErrorMessage && getInputErrorText(errorMessage)}
      disabled={isSubmitting || isDisabled || disabled}
      InputLabelProps={{
        htmlFor: id,
      }}
      margin="normal"
      required={required}
      variant="outlined"
      fullWidth={true}
      InputProps={{
        // eslint-disable-next-line
        inputComponent: NumberFormatInput as any,
        startAdornment: startAdornment ? (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ) : undefined,
        endAdornment: endAdornment ? (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ) : undefined,
      }}
      inputProps={{ integerOnly }}
      className={className}
    />
  );
}
