export const FONT_SIZES = {
  h1: '32px',
  h2: '24px',
  h3: '18.72px',
  h4: '16px',
  h5: '13.28px',
  h6: '10.72px',
};

type Bold = 'bold';
export const FONT_WEIGHTS = {
  bold: 'bold' as Bold,
  semibold: 600,
  light: 200,
};
