import { makeStyles } from '@mui/styles';

export const useChangeLogPageStyles = makeStyles(() => ({
  listWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
  progressIndicatorWrapper: {
    width: '100%',
    alignItems: 'center',
  },
}));
