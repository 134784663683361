import { usePatientDevicePairingFormStyles } from './styles';
import { Box } from '@mui/material';
import React from 'react';
import { Skeleton } from '@mui/lab';
import { SKELETON_ANIMATION } from '../../constants';

export function PatientDevicePairingFormSkeleton() {
  const classes = usePatientDevicePairingFormStyles();
  const inputHeight = 50;
  const buttonHeight = 40;

  return (
    <Box display="flex" flexDirection="column">
      <Box className={classes.formRow} my={1}>
        <Skeleton
          variant="rectangular"
          animation={SKELETON_ANIMATION}
          height={inputHeight}
          width="100%"
        />
        <Skeleton
          variant="rectangular"
          animation={SKELETON_ANIMATION}
          height={inputHeight}
          width="100%"
        />
      </Box>
      <Box my={1}>
        <Skeleton variant="rectangular" animation={SKELETON_ANIMATION} width="100%" height={100} />
      </Box>
      <Box display="flex" width="100%" justifyContent="flex-end" my={1}>
        <Skeleton
          variant="rectangular"
          animation={SKELETON_ANIMATION}
          width="100px"
          height={buttonHeight}
        />
      </Box>
    </Box>
  );
}
