import * as Yup from 'yup';
import { TextInput } from '../../../../components/TextInput';
import { SubmitButton } from '../../../../components/SubmitButton';
import { Form, SubmitFormCompletedHandler, SubmitFormHandler } from '../../../../components/Form';
import { PasswordInput } from '../../../../components/PasswordInput';
import { LoginUserResponse } from '../../../../models';
import { Box } from '@mui/material';
import { ScaseLogoWithText } from '../../../../components/Icons';
import { useTranslation } from 'react-i18next';

export interface LoginFormValues {
  username: string;
  password: string;
}

interface Props {
  onSubmit: SubmitFormHandler<LoginFormValues, LoginUserResponse>;
  onSubmitCompleted: SubmitFormCompletedHandler<LoginUserResponse>;
}

export function LoginForm({ onSubmit, onSubmitCompleted }: Props) {
  const { t } = useTranslation('login');
  const defaultFormValues = {
    username: '',
    password: '',
  };

  const schema: Yup.SchemaOf<LoginFormValues> = Yup.object({
    username: Yup.string().required(t('username.validation.required')),
    password: Yup.string().required(t('password.validation.required')),
  });

  return (
    <Form<LoginFormValues, LoginUserResponse>
      initialValues={defaultFormValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      onSubmitCompleted={onSubmitCompleted}
    >
      <Box display="flex" flexDirection="column" width="100%">
        <ScaseLogoWithText />
        <TextInput id="username" label={t('username.label')} required={true} />
        <PasswordInput id="password" label={t('password.label')} required={true} />
        <SubmitButton fullWidth={true} buttonText={t('submitButton')} />
      </Box>
    </Form>
  );
}
