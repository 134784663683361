import { useCallback, useEffect, useReducer, useState } from 'react';
import {
  HttpTransportType,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import { getApiVersion, getPreviousApiVersion, getUrl, hasPreviousApiVersion } from '../utils';
import { Hub, HubMeta, HubState } from './types';
import { HubLogger, LoggerCurrentUserProvider } from './hubLogger';
import { hubReducer, HubReducerType } from './reducer';
import { API_VERSION_HEADER } from '../constants';
import { useCurrentUser } from '../../../context';
import { ACCESS_TOKEN } from '../../../constants';

type CallbackFn<T> = (data: T) => Promise<void> | (() => Promise<void>);
interface HubOptions {
  queryParams?: Record<string, string>;
}

// 0, 2, 10, 30 second delays before reconnect attempts.
const RETRY_DELAYS_IN_MILLISECONDS = [0, 2000, 10000, 30000];

export function useHub<T>(
  hub: Hub,
  message: string,
  callback: CallbackFn<T>,
  options?: HubOptions,
): [HubState, HubMeta] {
  const { user } = useCurrentUser();
  const currentUserProvider = useCallback(() => user, [user]);
  const [connection] = useState(
    buildConnection(hub.route, currentUserProvider, getApiVersion() || '', options),
  );
  const [previousVersionConnection] = useState(
    buildPreviousVersionConnection(hub.route, currentUserProvider, options),
  );
  const [lastMessageReceivedTimestamp, setLastMessageReceivedTimestamp] = useState<Date | null>(
    null,
  );
  const [state, dispatch] = useReducer<HubReducerType>(hubReducer, {
    isError: false,
    isDisconnected: false,
    isReconnecting: false,
  });
  const callbackMemoized = useCallback(
    (data: T) => {
      setLastMessageReceivedTimestamp(new Date());
      callback(data);
    },
    [callback],
  );

  useEffect(() => {
    if (connection.state === HubConnectionState.Disconnected) {
      connection.start().catch(() => dispatch({ type: 'HUB_ERROR' }));
    }

    if (previousVersionConnection?.state === HubConnectionState.Disconnected) {
      previousVersionConnection?.start().catch(() => dispatch({ type: 'HUB_ERROR' }));
    }

    connection.onreconnecting(handleReconnecting);
    connection.onreconnected(handleReconnected);
    connection.onclose(handleClose);
    connection.on(message, callbackMemoized);

    previousVersionConnection?.onreconnecting(handleReconnecting);
    previousVersionConnection?.onreconnected(handleReconnected);
    previousVersionConnection?.onclose(handleClose);
    previousVersionConnection?.on(message, callbackMemoized);

    return () => {
      connection.stop();
      previousVersionConnection?.stop();
    };
  }, []);

  function handleClose() {
    dispatch({ type: 'HUB_DISCONNECTED' });
  }

  function handleReconnecting() {
    dispatch({ type: 'HUB_RECONNECTING' });
  }

  function handleReconnected() {
    dispatch({ type: 'HUB_RECONNECTED' });
  }

  return [state, { lastMessageReceivedTimestamp }];
}

function buildPreviousVersionConnection(
  hubName: string,
  currentUserProvider: LoggerCurrentUserProvider,
  options?: HubOptions,
) {
  if (process.env.NODE_ENV !== 'production') {
    return null;
  }

  if (!hasPreviousApiVersion()) {
    return null;
  }

  const previousApiVersion = getPreviousApiVersion();
  if (!previousApiVersion) {
    return null;
  }

  return buildConnection(hubName, currentUserProvider, previousApiVersion, options);
}

function buildConnection(
  hubName: string,
  currentUserProvider: LoggerCurrentUserProvider,
  apiVersion: string,
  options?: HubOptions,
) {
  const queryParams = new URLSearchParams(options?.queryParams || {});
  queryParams.append(API_VERSION_HEADER, apiVersion);
  const token = localStorage.getItem(ACCESS_TOKEN) ?? '';

  const logLevel = process.env.NODE_ENV == 'production' ? LogLevel.Warning : LogLevel.Information;

  return new HubConnectionBuilder()
    .withUrl(getUrl(hubName) + `?${queryParams.toString()}`, {
      withCredentials: false,
      transport: HttpTransportType.LongPolling,
      accessTokenFactory: () => token,
    })
    .withAutomaticReconnect(RETRY_DELAYS_IN_MILLISECONDS)
    .configureLogging(new HubLogger(logLevel, currentUserProvider))
    .build();
}
