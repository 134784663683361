import amplitude from 'amplitude-js';
import { CurrentUserResult } from '../models';

export const initAmplitude = () => {
  const amplitudeApiKeyEnv = process.env.REACT_APP_AMPLITUDE;
  const isTestPlatform = process.env.REACT_APP_IS_TEST_PLATFORM?.toLowerCase() === 'true';

  if (!amplitudeApiKeyEnv || isTestPlatform) {
    return;
  }

  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE ?? '');
};

const setAmplitudeUserId = (userId: number | string | null) => {
  amplitude.getInstance().setUserId(userId !== null ? String(userId) : userId);
};

// eslint-disable-next-line
const setAmplitudeUserProperties = (properties: any) => {
  amplitude.getInstance().setUserProperties(properties);
};

export const setAmplitudeUserIdAndUserProperties = (user: CurrentUserResult) => {
  setAmplitudeUserId(user.id);
  setAmplitudeUserProperties({
    externalId: user.externalId,
    username: user.username,
    userRole: user.userRole,
    host: location.host,
  });
};

export const resetAmplitudeUserId = () => {
  setAmplitudeUserId(null);
};

// eslint-disable-next-line
export const sendAmplitudeEvent = (eventType: string, eventProperties?: any) => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};
