import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PatientGridModel } from '../../models';
import { useStyles } from './styles';

interface Props {
  patient: PatientGridModel;
}

export function PatientDevicePairingPurposeGridColumn({ patient }: Props) {
  const classes = useStyles();
  const { t } = useTranslation('patient');

  if (typeof patient.purpose === 'undefined') {
    return (
      <Button className={classes.purposeButtonNotPaired}>
        <Box px={2} py={0.5}>
          {t('purpose.notPaired', { context: patient.sex })}
        </Box>
      </Button>
    );
  }

  if (!patient.purpose || patient.purpose === '') {
    return (
      <Button className={classes.purposeButton}>
        <Box px={2} py={0.5}>
          {t('purpose.notSet')}
        </Box>
      </Button>
    );
  }

  return <>{patient.purpose}</>;
}
