import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  circle: {
    borderRadius: 15,
    minWidth: 10,
    width: 10,
    height: 10,
    padding: 0,
  },
  online: {
    backgroundColor: 'limegreen',
  },
  offline: {
    backgroundColor: 'grey',
  },
});
