import { Navigation, RoutePath } from '../types';
import { matchPath } from 'react-router-dom';

export function isMatch(routePath: RoutePath | string, pathName: string, shouldBeExact = true) {
  // Create a path object for matchPath
  const path = typeof routePath === 'string' ? routePath : routePath.path;

  if (!path) {
    // If path is undefined or invalid, ensure no match is returned
    return false;
  }

  const pathObject = {
    path: path as string,
    end: shouldBeExact,
  };

  // Now path is guaranteed to be a string or string array and not undefined
  return !!matchPath(pathObject, pathName);
}

export function getTabsValue(nav: Navigation, pathName: string, shouldBeExact = true) {
  for (const [_, value] of Object.entries(nav)) {
    if (value.route.path === '/') {
      if (pathName === '/') {
        return value.tab;
      } else {
        continue;
      }
    }

    if (isMatch(value.route, pathName, shouldBeExact)) {
      return value.tab;
    }

    if (value.subRoutes) {
      for (const [_, subRouteValue] of Object.entries(value.subRoutes)) {
        if (subRouteValue.path === '/') {
          if (pathName === '/') {
            return value.tab;
          } else {
            continue;
          }
        }
        if (isMatch(subRouteValue, pathName, shouldBeExact)) {
          return value.tab;
        }
      }
    }
  }
  return '';
}
