import { PatientQuestionnairesGrid } from '../../../components/Grids';
import { ProgressIndicator } from '../../../components/ProgressIndicator';
import {
  CreatePatientQuestionnnaireRequest,
  PatientQuestionnaire,
  Questionnaire,
} from '../../../models';
import { ApiRoutes, makeApiRequest, useGetApi } from '../../../services/api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  PatientQuestionnaireAssignForm,
  PatientQuestionnaireAssignFormValues,
} from './PatientQuestionnaireAssignForm';
import { useEntityActionSnackbar } from '../../../hooks';

interface Props {
  patientId: string;
}

interface QuestionnaireOption {
  id: number;
  title: string;
}

export function PatientQuestionnaires({ patientId }: Props) {
  const { t } = useTranslation(['questionnaire', 'snackbar']);
  const { enqueueEntityAssignedSnackbar, enqueueEntityDeletedSnackbar } = useEntityActionSnackbar();
  const navigate = useNavigate();
  const [
    { data: patientQuestionnaires, isLoading: arePatientQuestionnairesLoading },
    { refetch: refetchPatientQuestionnaires },
  ] = useGetApi<PatientQuestionnaire[]>(ApiRoutes.PatientQuestionnaires(patientId));
  const [
    { data: allQuestionnaires, isLoading: areAllQuestionnairesLoading },
    { refetch: refetchAllQuestionnaires },
  ] = useGetApi<Questionnaire[]>(ApiRoutes.Questionnaires);

  function handleRowClick(row: PatientQuestionnaire) {
    navigate(`/patients/${patientId}/questionnaires/${row.id}`);
  }

  async function handleDelete(questionnaireId: number) {
    await makeApiRequest('DELETE', ApiRoutes.PatientQuestionnaire(patientId, questionnaireId));
  }

  async function handleDeleteCompleted() {
    await refetchPatientQuestionnaires();

    enqueueEntityDeletedSnackbar(t('questionnaire:questionnaire'));
  }

  function handleSubmit(values: PatientQuestionnaireAssignFormValues) {
    const input: CreatePatientQuestionnnaireRequest = {
      questionnaireTemplateId: values.questionnaire?.id ?? -1,
    };

    return makeApiRequest<never, CreatePatientQuestionnnaireRequest>(
      'POST',
      ApiRoutes.PatientQuestionnaires(patientId),
      input,
    );
  }

  async function handleSubmitCompleted() {
    await refetchAllQuestionnaires();
    await refetchPatientQuestionnaires();

    enqueueEntityAssignedSnackbar(t('questionnaire:questionnaire'));
  }

  if (arePatientQuestionnairesLoading || areAllQuestionnairesLoading) {
    return <ProgressIndicator />;
  }

  if (!patientQuestionnaires) {
    return <div>{t('questionnaire:error.patientQuestionnairesNotFound')}</div>;
  }

  return (
    <>
      <PatientQuestionnaireAssignForm
        questionnaireOptions={mapQuestionnairesToQuestionnaireOptions(allQuestionnaires ?? [])}
        onSubmit={handleSubmit}
        onSubmitCompleted={handleSubmitCompleted}
      />
      <PatientQuestionnairesGrid
        data={patientQuestionnaires}
        onRowClick={handleRowClick}
        onDelete={handleDelete}
        onDeleteCompleted={handleDeleteCompleted}
      />
    </>
  );
}

function mapQuestionnairesToQuestionnaireOptions(
  questionnaires: Questionnaire[],
): QuestionnaireOption[] {
  return questionnaires.map((q) => {
    return { id: q.id, title: q.title };
  });
}
