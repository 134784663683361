import { Box, Button } from '@mui/material';
import { PatientNoteForm, PatientNoteFormValues } from '../../notes/PatientNoteForm';
import { AddButton } from '../../../components/AddButton';
import { SubmitFormCompletedHandler, SubmitFormHandler } from '../../../components/Form';
import { useCurrentUser } from '../../../context';
import { useShowHide } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { RequireFunctionality } from '../../../components/RequireFunctionality';
import { CloseIconWithCircleBackground } from '../../../components/Icons';
import { PATIENT_NOTES_CREATE_POLICY } from '../../../constants/Policies';

export interface ShowHideFormProps {
  onSubmit: SubmitFormHandler<PatientNoteFormValues>;
  onSubmitCompleted: SubmitFormCompletedHandler;
}

export function ShowHideForm({ onSubmit, onSubmitCompleted }: ShowHideFormProps) {
  const { t } = useTranslation(['note', 'common']);
  const { user } = useCurrentUser();
  const { isVisible, show, hide } = useShowHide();

  if (!isVisible) {
    return (
      <Box display="flex" justifyContent="flex-end" mb={1} mr={1}>
        <AddButton onAddClick={show} />
      </Box>
    );
  }
  return (
    <RequireFunctionality functionality={PATIENT_NOTES_CREATE_POLICY}>
      <Box>
        <Box display="flex" justifyContent="flex-end" mr={1} mb={1}>
          <Button variant="outlined" startIcon={<CloseIconWithCircleBackground />} onClick={hide}>
            {t('common:cancel')}
          </Button>
        </Box>
        <Box mb={0}>
          <PatientNoteForm
            initialValues={{
              createdAt: new Date(),
              text: '',
              author: user?.name || t('note:author.default'),
            }}
            onSubmit={onSubmit}
            onSubmitCompleted={onSubmitCompleted}
            isNewNote={true}
          />
        </Box>
      </Box>
    </RequireFunctionality>
  );
}
