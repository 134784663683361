import { GridRenderCellParams } from '@mui/x-data-grid';
import { Department } from '../../../models';
import { DeviceStateEnum } from '../../../models/enums/DeviceStateEnum';

export function DepartmentCellRenderer(params: GridRenderCellParams): string {
  return (params.value as Department[]).map((department) => department.name).join(', ');
}

export function DeviceStateCellRenderer(params: GridRenderCellParams): string {
  const value = params.value;

  const stateTextMap: { [key: number]: string } = {
    [DeviceStateEnum.Outreach]: 'Outreach',
    [DeviceStateEnum.Telemonitoring]: 'Telemonitoring',
    [DeviceStateEnum.B2C]: 'B2C',
    [DeviceStateEnum.Deployment]: 'Deployment',
  };

  const stateText = stateTextMap[value as DeviceStateEnum] || 'Neznámy stav';
  return stateText;
}
