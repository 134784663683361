import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { FONT_SIZES } from '../../../../Constants';

interface Props {
  children: React.ReactElement | string | number;
  style?: Record<string, unknown>;
}

const styles = StyleSheet.create({
  cellWrapper: {
    flexGrow: 1,
    borderBottom: '1px solid black !important',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
    padding: '0.75px',
    paddingTop: '1.5px',
    marginHorizontal: '-0.5px',
  },
  cell: {
    display: 'flex',
    width: '100%',
    padding: '4px',
    alignItems: 'stretch',
    flexGrow: 1,
    fontSize: FONT_SIZES.h6,
  },
});

export function TableCell({ children, style }: Props) {
  return (
    <View style={styles.cellWrapper}>
      <Text style={[styles.cell, style ? style : {}]}>{children}</Text>
    </View>
  );
}
