import { Box } from '@mui/material';
import { AlarmTypesInput } from '../../AlarmTypesInput';
import { AutoComplete } from '../../Autocomplete';
import { ErrorMessage, useField } from 'formik';
import React from 'react';
import { useAlarmFormStyles } from './styles';
import { AlarmTypeWithLabel } from '../../../models';
import { useAlarmFormPartialCommonStyles } from '../styles';
import { AlarmTypesTabs } from '../../../types';
import { TabPanel } from '../../TabPanel';
import { useTranslation } from 'react-i18next';
import { AlarmTypeNavTab, AlarmTypeNavTabs } from '../../NavTabs';

interface Props {
  alarmTypeOptions: AlarmTypeWithLabel[];
  reminderId: number | undefined;
  autocompleteDrugNames: string[];
  selectedTab: AlarmTypesTabs;
  setSelectedTab: (type: AlarmTypesTabs) => void;
  isUsedInDialog?: boolean;
}

export function AlarmFormPartialTypes({
  alarmTypeOptions,
  reminderId,
  autocompleteDrugNames,
  selectedTab,
  setSelectedTab,
  isUsedInDialog = false,
}: Props) {
  const [{ value: typeIds }, , { setValue: setTypeIds, setTouched: setTypeIdsTouched }] =
    useField<number[]>('typeIds');
  const [, , { setValue: setDrugName, setTouched: setDrugNameTouched }] = useField<string | null>(
    'drugName',
  );
  const classes = useAlarmFormStyles();
  const commonClasses = useAlarmFormPartialCommonStyles();

  const { t: tAlarm } = useTranslation('alarm');

  const handleChange = (event: React.SyntheticEvent, newValue: string | number) => {
    // do something only when clicking on another tab (not currently selected)
    const newTabValue = newValue as AlarmTypesTabs;
    if (selectedTab != newTabValue) {
      setSelectedTab(newTabValue);

      switch (newTabValue) {
        case AlarmTypesTabs.Sensor:
          setTypeIds([]);
          setTypeIdsTouched(false);
          setDrugName(null);
          break;
        case AlarmTypesTabs.Notes:
          if (reminderId) {
            setTypeIds([reminderId]);
          }
          setDrugName(null);
          setDrugNameTouched(false);
          break;
      }
    }
  };

  return (
    <>
      <Box className={classes.tabsWrapper}>
        <AlarmTypeNavTabs value={selectedTab} onChange={handleChange}>
          <AlarmTypeNavTab
            label={tAlarm('formDialog.alarmTypes.tabs.sensor')}
            {...a11yProps(AlarmTypesTabs.Sensor)}
          />
          <AlarmTypeNavTab
            label={tAlarm('formDialog.alarmTypes.tabs.notes')}
            {...a11yProps(AlarmTypesTabs.Notes)}
          />
        </AlarmTypeNavTabs>
      </Box>
      <TabPanel
        value={selectedTab}
        index={AlarmTypesTabs.Sensor}
        tabName={'alarm-type'}
        setDialogMinHeight={isUsedInDialog}
      >
        <Box className={classes.alarmTypesMeasurementWrapper}>
          <AlarmTypesInput
            id="typeIds"
            alarmTypeOptions={alarmTypeOptions.filter((x) => x.id !== reminderId)}
          />
        </Box>
      </TabPanel>
      <TabPanel
        value={selectedTab}
        index={AlarmTypesTabs.Notes}
        tabName={'alarm-type'}
        setDialogMinHeight={isUsedInDialog}
      >
        <Box display="flex" width="100%" height="100%" justifyContent="center">
          <Box className={classes.noteSectionWrapper}>
            <Box className={classes.noteInputTitle}>
              {tAlarm('formDialog.alarmTypes.noteTitle')}
            </Box>
            <AutoComplete<string>
              id="drugName"
              label={''}
              placeholder={tAlarm('formDialog.alarmTypes.notePlaceholder')}
              options={autocompleteDrugNames}
              required={typeIds.includes(reminderId ?? -1)}
              getOptionLabel={(x) => x}
              freeSolo={true}
              variant="outlined"
              multiline={true}
            />
          </Box>
        </Box>
      </TabPanel>
      <Box className={commonClasses.customErrorMessageMinHeight}>
        <ErrorMessage name="typeIds" className={commonClasses.customErrorMessage} component="div" />
      </Box>
    </>
  );
}

function a11yProps(index: AlarmTypesTabs) {
  return {
    id: `alarm-type-tab-${index}`,
    'aria-controls': `alarm-type-tabpanel-${index}`,
  };
}
