import {
  QuestionnaireResponseItemBoolean,
  QuestionnaireResponseItemCoding,
  QuestionnaireResponseItemDouble,
  QuestionnaireResponseItemString,
} from '../models';

export function instanceOfQuestionnaireResponseItemString(
  // eslint-disable-next-line
  object: any,
): object is QuestionnaireResponseItemString {
  return 'valueString' in object && object.valueString != null;
}

export function instanceOfQuestionnaireResponseItemDouble(
  // eslint-disable-next-line
  object: any,
): object is QuestionnaireResponseItemDouble {
  return 'valueDouble' in object && object.valueDouble;
}

export function instanceOfQuestionnaireResponseItemBoolean(
  // eslint-disable-next-line
  object: any,
): object is QuestionnaireResponseItemBoolean {
  return 'valueBoolean' in object && object.valueBoolean != null;
}

export function instanceOfQuestionnaireResponseItemCoding(
  // eslint-disable-next-line
  object: any,
): object is QuestionnaireResponseItemCoding {
  return 'valueCoding' in object && object.valueCoding;
}
