import { CircularProgress, Tooltip } from '@mui/material';
import { useFormikContext } from 'formik';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ClassNameMap } from '@mui/styles/withStyles/withStyles';
import { Button, Variant } from '../Button';

interface Props {
  buttonText?: string;
  fullWidth?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  variant?: Variant;
  color?: 'primary' | 'secondary';
  classes?: Partial<ClassNameMap>;
  importClasses?: boolean;
  disabledTooltipText?: string;
  forceSubmitting?: boolean;
  onSubmit?: () => Promise<void>;
}

export const SubmitButton = ({
  buttonText,
  fullWidth,
  startIcon,
  endIcon,
  variant,
  color,
  classes,
  disabledTooltipText,
  forceSubmitting,
  onSubmit,
}: Props) => {
  const { t } = useTranslation('common');
  const { isSubmitting: isSubmittingFormikContext, isValid } = useFormikContext();

  const isSubmitting = isSubmittingFormikContext || forceSubmitting;
  const isDisabled = !isValid || isSubmitting;
  const text = buttonText || t('submit');

  let ButtonNode = (
    <Button
      {...(onSubmit ? { onClick: () => onSubmit() } : { type: 'submit' })}
      color={color || 'primary'}
      variant={variant || 'contained'}
      fullWidth={fullWidth}
      disabled={isDisabled}
      {...(!isSubmitting ? { startIcon: startIcon, endIcon: endIcon } : {})}
      classes={classes}
      text={isSubmitting ? <CircularProgress size={24} /> : text}
    />
  );

  if (!isValid) {
    ButtonNode = (
      <Tooltip title={disabledTooltipText ?? t('enterMissingDataToSave')} placement="right-end">
        <span>{ButtonNode}</span>
      </Tooltip>
    );
  }

  return ButtonNode;
};
