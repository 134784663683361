import { useTranslation } from 'react-i18next';
import { DailyPlanTemplate } from '../../../models';
import { DeleteHandler, Grid, GridColumnDefinition, RowClickHandler } from '../../Grid';
import { DeleteItemCompletedHandler } from '../../DeleteButton';
import { EDIT_SUBPATH } from '../../../constants';

interface Props {
  data: DailyPlanTemplate[];
  onDelete: DeleteHandler;
  onDeleteCompleted: DeleteItemCompletedHandler;
  onRowClick?: RowClickHandler<DailyPlanTemplate>;
}

export function DailyPlanTemplatesGrid({ data, onDelete, onDeleteCompleted, onRowClick }: Props) {
  const { t } = useTranslation('dailyPlanTemplate');
  const columns: GridColumnDefinition<DailyPlanTemplate>[] = [
    {
      field: 'name',
      headerName: t('name.label'),
      flex: 1,
    },
  ];

  function getEditPath(id: number) {
    return `/daily-plan-templates/${id}/${EDIT_SUBPATH}`;
  }

  return (
    <Grid
      columns={columns}
      rows={data}
      getEditPath={getEditPath}
      onDelete={onDelete}
      onDeleteCompleted={onDeleteCompleted}
      onRowClick={onRowClick}
    />
  );
}
