import { useStyles } from './styles';
import { Link } from 'react-router-dom';
import { MouseEventHandler } from 'react';
import { ReactComponent as ScaseText } from './../../../assets/icons/ScaseText.svg';
import { ReactComponent as MenuCloseIcon } from './../../../assets/icons/MenuCloseIcon.svg';
import { ReactComponent as MenuOpenIcon } from './../../../assets/icons/MenuOpenIcon.svg';
import { Box } from '@mui/material';
import { useDashboardMenuContext } from '../../../context/dashboardMenu';

interface Props {
  onNavigationListItemClick?: MouseEventHandler;
}

export function DashboardHeader({ onNavigationListItemClick }: Props) {
  const { isDrawerOpen, handleDrawerToggle } = useDashboardMenuContext();
  const classes = useStyles({ isDrawerOpen: isDrawerOpen });

  return (
    <Box className={classes.logoAndActionButton}>
      {isDrawerOpen && (
        <Link to="/" onClick={onNavigationListItemClick}>
          <ScaseText />
        </Link>
      )}
      <div onClick={handleDrawerToggle} className={classes.menuButton}>
        {isDrawerOpen ? <MenuCloseIcon /> : <MenuOpenIcon />}
      </div>
    </Box>
  );
}
