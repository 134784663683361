import { Box, useTheme } from '@mui/material';
import { Skeleton } from '@mui/material';
import { SKELETON_ANIMATION } from '../../constants';
import { ROW_HEIGHT } from '../Grid/constants';
import React from 'react';

interface Props {
  rowCount?: number;
}

export function GridSkeleton({ rowCount = 10 }: Props) {
  const theme = useTheme();
  const tableSkeletonWidths = [100, 90, 70, 100, 80];
  return (
    <>
      {[...Array(rowCount)].map((x, idx) => (
        <Box key={idx} my={1}>
          <Skeleton
            variant="rectangular"
            animation={SKELETON_ANIMATION}
            width={`${tableSkeletonWidths[idx % tableSkeletonWidths.length]}%`}
            height={ROW_HEIGHT - 2 * parseInt(theme.spacing(1))}
          />
        </Box>
      ))}
      <Box my={1} display="flex" width="100%" justifyContent="flex-end">
        <Skeleton
          variant="rectangular"
          animation={SKELETON_ANIMATION}
          width="100px"
          height={ROW_HEIGHT - 2 * parseInt(theme.spacing(1))}
        />
      </Box>
    </>
  );
}
