import { Tab, Tooltip } from '@mui/material';
import { useRequireFunctionality } from '../../hooks';
import { useMeasurementTypeStyles } from './styles';
import { StyledTabProps } from './StyledTab';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useFormikDisableContext } from '../../context/formikDisable';
import { Link } from 'react-router-dom';

export function StyledMeasurementTypeTab({
  requireFunctionality,
  label,
  to,
  forceValidity = false,
  unsetMinHeight = false,
  ...props
}: StyledTabProps) {
  const classes = useMeasurementTypeStyles({
    unsetMinHeight: unsetMinHeight,
  });
  const { hasFunctionality } = useRequireFunctionality(requireFunctionality);
  const { isValid: isValidFormik } = useFormikContext();
  const { isDisabled } = useFormikDisableContext();
  const { t } = useTranslation('common');

  const isValid = forceValidity || isValidFormik;

  if (!hasFunctionality) {
    return null;
  }

  let labelNode = label ? <span className={classes.label}>{label}</span> : <></>;

  if (!isValid) {
    labelNode = (
      <Tooltip title={t('enterMissingData')} placement="bottom-start">
        {labelNode}
      </Tooltip>
    );
  }

  return (
    <Tab
      component={Link}
      disableRipple={true}
      disabled={isDisabled || !isValid}
      label={labelNode}
      style={{ pointerEvents: 'auto' }}
      {...(isValid ? { to: to } : { to: '#' })}
      {...props}
      classes={{
        root: classes.root,
        selected: classes.selected,
        //wrapper: classes.wrapper,
        labelIcon: classes.labelIcon,
      }}
    />
  );
}
