import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  menuWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
}));
