import { Organization } from '../../models';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { DeleteCompletedHandler, DeleteHandler } from '../../components/Grid';
import { OrganizationsGrid } from '../../components/Grids/OrganizationGrid';

interface Props {
  organizations: Organization[] | null;
  onDelete?: DeleteHandler;
  onDeleteCompleted?: DeleteCompletedHandler<Organization>;
}

export function OrganizationsList({ organizations, onDelete, onDeleteCompleted }: Props) {
  const navigate = useNavigate();

  function onRowClick(row: Organization) {
    navigate(`/organizations/${row.id}`);
  }

  return (
    <Box width="100%">
      {organizations && (
        <OrganizationsGrid
          data={organizations}
          onDelete={onDelete}
          onDeleteCompleted={onDeleteCompleted}
          onRowClick={onRowClick}
        />
      )}
    </Box>
  );
}
