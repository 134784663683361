import { Legend, Surface, Symbols } from 'recharts';
import { useTranslation } from 'react-i18next';
import { useGlucometerLegendStyles } from './styles';
import { foodTypeColors } from './colors';
import { Props } from 'recharts/types/component/DefaultLegendContent';
import { Box } from '@mui/material';
import { ThresholdsSubLegend } from './ThresholdsSubLegend';

export function GlucometerLegend({ payload }: Props) {
  const { t } = useTranslation('measurement');
  const classes = useGlucometerLegendStyles();

  return (
    <div className={classes.wrapper}>
      <Box display="flex" flexDirection="row">
        <div className={classes.defaultLegendWrapper}>
          <Legend payload={payload} />
        </div>
        <Box display="flex" flexDirection="row">
          <Box marginRight={1}>
            <Surface
              className={classes.icon}
              width={10}
              height={10}
              viewBox={{ x: 0, y: 0, width: 10, height: 10 }}
            >
              <Symbols cx={5} cy={5} type="circle" fill={foodTypeColors.beforeFood} />
            </Surface>
            <span className="recharts-text">{t('BEFORE_FOOD')}</span>
          </Box>
          <div>
            <Surface
              className={classes.icon}
              width={10}
              height={10}
              viewBox={{ x: 0, y: 0, width: 10, height: 10 }}
            >
              <Symbols cx={5} cy={5} type="circle" fill={foodTypeColors.afterFood} />
            </Surface>
            <span className="recharts-text">{t('AFTER_FOOD')}</span>
          </div>
        </Box>
      </Box>
      <ThresholdsSubLegend />
    </div>
  );
}
