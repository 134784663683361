import { Box } from '@mui/material';

interface IconProps {
  width?: number;
  height?: number;
}

export function CloseIcon({ width = 12, height = 12 }: IconProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <svg
        width={`${width}`}
        height={`${height}`}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.8296 1.15142C2.37784 0.701968 1.6454 0.701968 1.19365 1.15142C0.74189 1.60087 0.74189 2.32957 1.19365 2.77903L5.40555 6.96944L1.15742 11.1959C0.705665 11.6453 0.705665 12.374 1.15742 12.8235C1.60918 13.2729 2.34162 13.2729 2.79337 12.8235L7.0415 8.59704L11.2534 12.7875C11.7052 13.2369 12.4376 13.2369 12.8894 12.7875C13.3411 12.338 13.3411 11.6093 12.8894 11.1599L8.67745 6.96944L12.8532 2.81504C13.3049 2.36559 13.3049 1.63688 12.8532 1.18743C12.4014 0.73798 11.669 0.73798 11.2172 1.18743L7.0415 5.34183L2.8296 1.15142Z"
          fill="currentColor"
        />
      </svg>
    </Box>
  );
}
