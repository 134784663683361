export const thresholdColors = {
  minErrorLight: '#FFF8F8',
  minErrorDark: '#ff5858',
  minWarningLight: '#FFF8ED',
  minWarningDark: '#ff9900',
  maxWarningLight: '#FFF8ED',
  maxWarningDark: '#ff9900',
  maxErrorLight: '#FFF8F8',
  maxErrorDark: '#ff5858',
};
