import { Box } from '@mui/material';

interface IconProps {
  width?: number;
  height?: number;
}

export function DeleteGridIcon({ width = 14, height = 16 }: IconProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <svg
        width={`${width}`}
        height={`${height}`}
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.86009 2.51758L0.506819 3.57654C0.426374 3.81763 0.467146 4.08472 0.615705 4.29089C0.764265 4.49695 1.00462 4.62012 1.25877 4.62012H1.46324L2.27598 14.6702C2.33629 15.416 2.96922 16.0002 3.71678 16.0002H10.1188C10.8664 16.0002 11.4993 15.416 11.5597 14.6703L12.3724 4.62012H12.7417C12.9958 4.62012 13.2362 4.49695 13.3847 4.29077C13.5333 4.08459 13.5741 3.81763 13.4936 3.57654L13.1404 2.51758C13.0058 2.11426 12.6297 1.84326 12.2046 1.84326H9.23558V0.876587C9.23558 0.393433 8.84263 0.000244141 8.35972 0.000244141H5.64073C5.1577 0.000244141 4.76488 0.393433 4.76488 0.876587V1.84326H1.79576C1.37071 1.84326 0.994612 2.11426 0.86009 2.51758ZM5.70238 0.937744H8.29808V1.84326H5.70238V0.937744ZM3.71678 15.0627C3.45408 15.0627 3.23167 14.8572 3.21043 14.5946L2.40379 4.62012H11.4319L10.6251 14.5946C10.6039 14.8572 10.3815 15.0627 10.1188 15.0627H3.71678ZM12.251 2.81421L12.5406 3.68262H1.4597L1.74937 2.81421C1.75609 2.79419 1.77464 2.78076 1.79576 2.78076H12.2046C12.2257 2.78076 12.2444 2.79419 12.251 2.81421ZM4.68077 14.562C4.68907 14.562 4.69725 14.5619 4.70555 14.5614C4.96409 14.548 5.1627 14.3274 5.14927 14.0689L4.70921 5.61892C4.69566 5.36038 4.47581 5.1614 4.21666 5.1752C3.95823 5.18862 3.7595 5.4092 3.77293 5.66775L4.21312 14.1177C4.22606 14.3679 4.43309 14.562 4.68077 14.562ZM9.76649 14.1187C9.75282 14.3686 9.54604 14.562 9.29884 14.562C9.2903 14.562 9.28151 14.5617 9.27284 14.5612C9.01442 14.5472 8.8163 14.3262 8.83034 14.0677L9.2914 5.61774C9.30544 5.35919 9.52638 5.16107 9.78493 5.17523C10.0434 5.18927 10.2415 5.41022 10.2274 5.66876L9.76649 14.1187ZM6.5262 14.0932C6.5262 14.3521 6.73604 14.562 6.99495 14.562C7.25386 14.562 7.4637 14.3521 7.4637 14.0932V5.64327C7.4637 5.38436 7.25386 5.17452 6.99495 5.17452C6.73604 5.17452 6.5262 5.38436 6.5262 5.64327V14.0932Z"
          fill="currentColor"
        />
      </svg>
    </Box>
  );
}
