import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QuestionnaireQuestion } from '../../../../models';
import { QuestionnairePreviewQuestion } from '../Question';
import { useStyles } from './styles';

interface Props {
  questions: QuestionnaireQuestion[];
}

export function QuestionnairePreviewQuestionsList({ questions }: Props) {
  const { t } = useTranslation('questionnaire');
  const classes = useStyles();
  return (
    <Box width="100%">
      <Box component="span" className={classes.questionsPreviewText}>
        {t('preview')}
      </Box>
      <Box mt={1}>
        {questions.map((question, index) => (
          <QuestionnairePreviewQuestion key={question.id} question={question} index={index} />
        ))}
      </Box>
    </Box>
  );
}
