import * as Yup from 'yup';
import { TextInput } from '../../components/TextInput';
import { SubmitFormCompletedHandler, SubmitFormHandler } from '../../components/Form';
import { CreateDailyPlanTemplateResult } from '../../models';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CancelButton } from '../../components/CancelButton';
import { FormButton } from '../../components/FormButton';
import { EntityForm } from '../../components/EntityForm';

export interface DailyPlanTemplateFormValues {
  name: string;
}

interface Props {
  initialValues?: DailyPlanTemplateFormValues;
  onSubmit: SubmitFormHandler<DailyPlanTemplateFormValues, CreateDailyPlanTemplateResult>;
  onSubmitCompleted: SubmitFormCompletedHandler<CreateDailyPlanTemplateResult>;
  onCancel?: () => void;
}

export function DailyPlanTemplateForm({
  onSubmit,
  onSubmitCompleted,
  onCancel,
  initialValues,
}: Props) {
  const { t } = useTranslation('dailyPlanTemplate');

  const defaultFormValues = {
    name: '',
  };

  const schema: Yup.SchemaOf<DailyPlanTemplateFormValues> = Yup.object({
    name: Yup.string().required(t('name.validation.required')),
  });

  return (
    <EntityForm<DailyPlanTemplateFormValues, CreateDailyPlanTemplateResult>
      initialValues={initialValues || defaultFormValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      onSubmitCompleted={onSubmitCompleted}
    >
      <Box display="flex" flexDirection="column" width="100%">
        <TextInput id="name" label={t('name.label')} required={true} />
        <Box display="flex" justifyContent={'flex-end'}>
          {onCancel && (
            <Box marginRight={2}>
              <CancelButton onClick={onCancel} />
            </Box>
          )}
          <FormButton />
        </Box>
      </Box>
    </EntityForm>
  );
}
