import { InputAdornment, TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { getInputErrorText } from '../InputErrorText';
import React from 'react';
import { useFormikDisableContext } from '../../context/formikDisable';
import { useDebouncedInput } from '../../hooks';

export type TextInputType = 'password' | 'email' | 'date' | 'time' | 'text' | 'number';

export interface TextInputProps {
  id: string;
  label: string;
  required?: boolean;
  type?: TextInputType;
  className?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  multiline?: boolean;
  disabled?: boolean;
  rows?: number;
  rowsMax?: number;
  helperText?: string;
  debounceInput?: boolean;
}

export function TextInput({
  id,
  label,
  required,
  type,
  className,
  startAdornment,
  endAdornment,
  multiline,
  disabled,
  rows,
  rowsMax,
  helperText,
  debounceInput = false,
}: TextInputProps) {
  // Idealy we would get the required flag from validationSchema
  // from formik context (useFormikContext) to have one source of thruth about
  // field requirements. However, there is a bug that validationSchema
  // is not included in formik context even tho the type definitions say otherwise.
  // see: https://github.com/formium/formik/pull/2521
  const [{ value, onChange, ...field }, meta, { setTouched }] = useField(id);
  const { debouncedValue, onChangeDebounced } = useDebouncedInput(value, onChange);
  const { isSubmitting } = useFormikContext();
  const { isDisabled } = useFormikDisableContext();

  const errorMessage = meta.touched ? meta.error : null;
  // Fix for warning: `value` prop on `input` should not be null.
  let fieldValue = debounceInput ? debouncedValue : value;
  fieldValue = fieldValue === null ? '' : fieldValue;

  return (
    <TextField
      {...field}
      value={fieldValue}
      onChange={(e) => {
        debounceInput ? onChangeDebounced(e) : onChange(e);
      }}
      /*
       * If input is debounced, we want to save it even after losing focus to prevent
       * quick submit without properly saved value into state.
       */
      {...(debounceInput
        ? {
            onBlur: (e) => {
              onChange(e);
              setTouched(true);
            },
          }
        : {})}
      id={id}
      label={label}
      type={type}
      error={!!errorMessage}
      helperText={getInputErrorText(errorMessage) || helperText}
      disabled={isSubmitting || isDisabled || disabled}
      className={className}
      InputLabelProps={{
        htmlFor: id,
      }}
      margin="normal"
      required={required}
      variant="outlined"
      fullWidth={true}
      multiline={multiline}
      minRows={rows}
      maxRows={rowsMax}
      InputProps={{
        startAdornment: startAdornment ? (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ) : undefined,
        endAdornment: endAdornment ? (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ) : undefined,
      }}
    />
  );
}
