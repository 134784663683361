export function getTodayDateWithCertainHour(hours: number) {
  const today = new Date();
  return new Date(today.getFullYear(), today.getMonth(), today.getDate(), hours, 0, 0);
}

export function toLocaleDateTimeString(datetime: Date | string) {
  const date = new Date(datetime);
  return `${date.toLocaleDateString()}`;
}

export function getTimeString(date?: Date, withSeconds = false) {
  let timeString = '';

  if (date == null) {
    return timeString;
  }

  if (typeof date !== 'object') {
    date = new Date(date);
  }

  timeString += `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  }`;

  if (withSeconds) {
    timeString += `:${date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()}`;
  }

  return timeString;
}

export function getLocaleStringWith2DigitsFormat(date: Date) {
  return date.toLocaleString([], {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
}
