import { Box } from '@mui/material';

interface IconProps {
  width?: number;
  height?: number;
  primaryColor?: string;
}

export function QuestionTypeBooleanIcon({ width, height, primaryColor }: IconProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <svg
        width={`${width ?? 30}`}
        height={`${height ?? 31}`}
        viewBox="0 0 30 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d)">
          <circle cx="15" cy="14.5" r="10" fill="white" />
          <path
            d="M9 16.6233H14.5283V11.095H9V16.6233ZM13.0048 14.5713L12.4764 15.0998L11.7642 14.3876L11.052 15.0998L10.5235 14.5713L11.2357 13.8591L10.5235 13.1469L11.052 12.6185L11.7642 13.3307L12.4764 12.6185L13.0048 13.1469L12.2926 13.8591L13.0048 14.5713Z"
            fill={`${primaryColor ?? '#6B6B6B'}`}
          />
          <path
            d="M15.4717 11.095V15.5234C15.4717 15.5276 19.5308 16.6233 19.5186 16.6233H21V11.095H15.4717ZM17.0378 14.142L17.5662 13.6136L17.9121 13.9597L18.9055 12.9661L19.4339 13.4946L17.9121 15.0161L17.0378 14.142Z"
            fill={`${primaryColor ?? '#6B6B6B'}`}
          />
          <path
            d="M18.5981 17.0104C17.8694 16.7983 16.2143 16.3165 15.4717 16.1003C15.4796 16.2169 15.6772 19.1351 15.6907 19.3344L16.6819 18.2113L17.8382 19.6548L18.4215 19.1876L17.2651 17.744L18.5981 17.0104Z"
            fill={`${primaryColor ?? '#6B6B6B'}`}
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0.5"
            width="30"
            height="30"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
        </defs>
      </svg>
    </Box>
  );
}
