import axios from 'axios';

import { ApiResponse } from './types';
import { CaptureApiError, getRequestHeaders, getUrl, RequestHeaders } from './utils';

type ApiRequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export async function makeApiRequest<
  TResponse = never,
  TData = Record<string, never>,
  TParams = unknown,
>(
  method: ApiRequestMethod,
  endpoint: string,
  data?: TData,
  params?: TParams,
  reqHeaders?: RequestHeaders,
): Promise<ApiResponse<TResponse>> {
  const url = getUrl(endpoint);
  const headers = getRequestHeaders();
  const requestHeaders: RequestHeaders = {
    ...headers,
    ...reqHeaders,
  };

  try {
    const result = await axios(url, {
      method,
      data,
      params,
      withCredentials: false,
      headers: requestHeaders,
    });

    return { data: result.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      CaptureApiError(error, requestHeaders);
      const responseData =
        (error?.response?.data as { title?: string; description?: string }) ||
        'No description available';
      return {
        error: {
          title: responseData.title || error.message,
          description: responseData.description || 'No description available',
        },
      };
    }
    const errorPayload = {};
    return { error: errorPayload };
  }
}
