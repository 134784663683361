import { StyleSheet, View } from '@react-pdf/renderer';
import { TableCell } from '../TableCell';
import React, { ReactElement, useMemo } from 'react';

export interface ExportRowData {
  value: number | string | ReactElement;
  style?: Record<string, unknown>;
  width?: number;
}

interface Props {
  data: ExportRowData[];
  showTopBorder?: boolean;
}

export function TableRow({ data, showTopBorder }: Props) {
  const oneColDynamicWidth = useMemo(() => getOneColDynamicWidth(data), [data]);

  const styles = StyleSheet.create({
    row: {
      flexDirection: 'row',
      alignItems: 'stretch',
    },
    valueCol: {
      width: `${oneColDynamicWidth}%`,
    },
    topBorder: {
      borderTop: '1px solid black !important',
      marginTop: 1,
    },
  });

  return (
    <View style={styles.row} wrap={false}>
      {data.map((data, idx) => (
        <View
          style={[
            styles.valueCol,
            showTopBorder ? styles.topBorder : {},
            data.width ? { width: `${data.width * 100}%` } : {},
          ]}
          key={idx}
        >
          <TableCell style={data.style}>{data.value}</TableCell>
        </View>
      ))}
    </View>
  );
}

const getOneColDynamicWidth = (data: ExportRowData[]): number => {
  const columnsWithAssignedWidth = data.filter((x) => x.width).map((x) => x.width) as number[];
  const alreadyAssignedWidth =
    columnsWithAssignedWidth.reduce((partialSum: number, a: number) => partialSum + a, 0) * 100;
  const remainingWidth = 100 - alreadyAssignedWidth;
  const remainingColumnsCount = data.length - columnsWithAssignedWidth.length;
  return columnsWithAssignedWidth.length >= data.length
    ? 0
    : remainingWidth / remainingColumnsCount;
};
