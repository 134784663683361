import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  page: {
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  boxShadowAndBorderRadius: {
    marginTop: theme.typography.pxToRem(-3),
    boxShadow: `0px ${theme.typography.pxToRem(1)} ${theme.typography.pxToRem(
      5,
    )} rgba(0, 0, 0, 0.15)`,
    borderRadius: `0px 0px ${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(10)}`,
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
}));

export const usePatientFiltrationFormStyles = makeStyles(() => ({
  form: {
    display: 'flex',
  },
}));

export const usePatientFormStyles = makeStyles((theme: Theme) => ({
  dividerMargin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  bold: {
    fontWeight: 'bold',
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    '@supports (column-gap: 8px)': {
      columnGap: theme.spacing(1),
    },
    '@supports not (column-gap: 8px)': {
      '&>*': {
        margin: theme.spacing(0, 0.6),
      },
      '&>*:first-child': {
        marginRight: theme.spacing(0.6),
        marginLeft: 0,
      },
      '&>*:last-child': {
        marginLeft: theme.spacing(0.6),
        marginRight: 0,
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

export const usePatientPageStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  pairingButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(1, 0, 3),
    [theme.breakpoints.down('sm')]: {
      '&>button': {
        width: '90%',
        minHeight: '3.5rem',
      },
    },
  },
}));
