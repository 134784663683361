import { useStyles } from './styles';
import { Box, Button } from '@mui/material';
import { useDashboardMenuContext } from '../../../../context/dashboardMenu';
import { useTranslation } from 'react-i18next';

export function DashboardMobileHeader() {
  const { t } = useTranslation('navigation');
  const { handleDrawerToggle } = useDashboardMenuContext();
  const classes = useStyles();

  return (
    <Box className={classes.headerWrapper}>
      <Box className={classes.moreOptions}>{t('sidebar.moreOptions')}</Box>
      <Button onClick={handleDrawerToggle} className={classes.cancel}>
        {t('sidebar.cancel')}
      </Button>
    </Box>
  );
}
