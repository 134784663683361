import { createTheme, responsiveFontSizes } from '@mui/material';

export const theme = responsiveFontSizes(
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      fontFamily: ['"Source Sans Pro"', 'Roboto', 'Arial', 'sans-serif'].join(','),
      button: {
        textTransform: 'none',
        fontWeight: 600,
        fontSize: 14,
        fontStyle: 'normal',
      },
    },
    palette: {
      primary: {
        light: '#1464f4',
        main: '#195de5',
        dark: '#082caf',
        100: '#dae2fd',
        200: '#b5c4fc',
        300: '#8fa7fa',
        400: '#6a89f9',
        500: '#456cf7',
        600: '#3756c6',
        700: '#294194',
        800: '#1c2b63',
        900: '#0e1631',
        A100: '#BEDCFB',
        A200: '#B8C2E0',
        A400: '#6f768b',
        A700: '#0e263e',
      },
      secondary: {
        main: '#ea132f',
        light: '#ff4961',
        100: '#363b44',
      },
      success: {
        light: '#2cdbce',
        main: '#02b881',
      },
      text: {
        primary: '#303030',
        secondary: '#B5B5B5',
      },
      common: {
        white: '#fff',
        black: '#000',
      },
      error: {
        main: '#ef0707',
        dark: '#AF0808',
      },
      warning: {
        main: '#f90',
      },
      grey: {
        100: '#f7f7f7',
        A100: '#F9FAFC',
        200: '#E0E0E0',
        A200: '#EEFDFE',
        300: '#ddd',
        400: '#595e6c',
        A400: '#8794aa',
        500: '#6B6B6B',
        600: '#27335d',
        A700: '#1b1e22',
      },
      mode: 'light',
    },
  }),
);
