import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import { TextInput, TextInputProps } from '../TextInput';

export function PasswordInput(props: TextInputProps) {
  const [showPassword, setShowPassword] = useState(false);
  const handleToggleShowPassword = () => setShowPassword(!showPassword);
  return (
    <TextInput
      {...props}
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <IconButton onClick={handleToggleShowPassword} size="large">
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      }
    />
  );
}
