import { View } from '@react-pdf/renderer';
import React from 'react';
import { QuestionnaireInfo } from '../QuestionnaireInfo';
import { QuestionnaireQuestionsWithAnswers } from '../QuestionnaireQuestionsWithAnswers';
import { QuestionnaireResponse } from '../../../../../models';
import { NoDataText } from '../../common/NoDataText';
import { useTranslation } from 'react-i18next';
import { SectionTitle } from '../../common/SectionTitle';
import { commonReactPdfStyles } from '../../commonReactPdfStyles';

interface Props {
  questionnaireResponses: QuestionnaireResponse[];
}

export function QuestionnaireResponses({ questionnaireResponses }: Props) {
  const { t } = useTranslation(['questionnaire', 'common']);

  return (
    <View style={commonReactPdfStyles.sectionView}>
      <View style={{ display: 'flex', flexDirection: 'column' }}>
        <SectionTitle title={t('questionnaire:title.list')} />
        {questionnaireResponses.length > 0 ? (
          questionnaireResponses.map((response) => (
            <>
              <QuestionnaireInfo
                title={response.questionnaireTitle}
                description={response.questionnaireDescription}
                answeredAt={response.answeredOnDatetime}
              />
              <QuestionnaireQuestionsWithAnswers items={response.items} />
            </>
          ))
        ) : (
          <NoDataText />
        )}
      </View>
    </View>
  );
}
