import { SystemEvent } from '../../../models';
import { Box, Hidden } from '@mui/material';
import { SystemEventListItem } from './SystemEventListItem';
import { SystemEventListHeader } from './SystemEventListHeader';
import { ProgressIndicator } from '../../../components/ProgressIndicator';
import InfiniteScroll from 'react-infinite-scroll-component';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  systemEvents: SystemEvent[];
  isLoading?: boolean;
  hasMore: boolean;
  getNextPage: () => Promise<void>;
}

export function SystemEventsList({ systemEvents, isLoading, hasMore, getNextPage }: Props) {
  const { t } = useTranslation('systemEvent');

  return (
    <Box flex={1} flexDirection="column" display="flex">
      <Hidden mdDown={true}>
        <SystemEventListHeader />
      </Hidden>
      {isLoading && <ProgressIndicator size="small" />}
      <InfiniteScroll
        dataLength={systemEvents.length}
        next={getNextPage}
        hasMore={hasMore}
        loader={<ProgressIndicator size="small" height={50} />}
        endMessage={
          <Box textAlign={'center'}>
            <b>{t('noMoreEventsMessage')}</b>
          </Box>
        }
      >
        {systemEvents.map((systemEvent) => (
          <SystemEventListItem
            key={`${systemEvent.entityType}-${systemEvent.entity.id}-${systemEvent.action}-${systemEvent.actionTimestamp}`}
            systemEvent={systemEvent}
          />
        ))}
      </InfiniteScroll>
    </Box>
  );
}
