import { Box, List, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useStyles } from './styles';
import { RequireFunctionality } from '../../../RequireFunctionality';
import { useTranslation } from 'react-i18next';
import { MouseEventHandler } from 'react';
import { ReactComponent as DailyPlanNavIcon } from '../../../../assets/icons/DailyPlanNavIcon.svg';
import { ReactComponent as QuestionnaireNavIcon } from '../../../../assets/icons/QuestionnaireNavIcon.svg';
import { ReactComponent as SupportIcon } from '../../../../assets/icons/SupportIcon.svg';
import { ReactComponent as LogOutIcon } from '../../../../assets/icons/LogOutIcon.svg';
import { ReactComponent as UserBadgeIcon } from '../../../../assets/icons/UserBadgeIcon.svg';
import {
  DAILY_PLAN_TEMPLATES_PATH,
  PROFILE_PATH,
  SCASE_SUPPORT_EXTERNAL_LINK,
} from '../../../../constants';
import { DashboardMobileNavItem } from '../NavItem';
import { isPathSelected } from '../../../../utils';
import { useCurrentUser } from '../../../../context';

interface PagePath {
  primary: string;
  secondary?: string;
}

interface Props {
  onNavigationListItemClick?: MouseEventHandler;
}

export function DashboardMobileNavigation({ onNavigationListItemClick }: Props) {
  const { pathname } = useLocation();
  const { user } = useCurrentUser();
  const theme = useTheme();
  const { t } = useTranslation('navigation');
  const classes = useStyles();

  return (
    <List>
      <Box className={classes.menuWrapper}>
        <RequireFunctionality functionality="DAILY_PLAN_TEMPLATES_READ">
          <DashboardMobileNavItem
            to={DAILY_PLAN_TEMPLATES_PATH.primary}
            text={t('sidebar.dailyPlanTemplates')}
            selected={isPathSelected(DAILY_PLAN_TEMPLATES_PATH, pathname)}
            onClick={onNavigationListItemClick}
            icon={<DailyPlanNavIcon />}
          />
        </RequireFunctionality>
        <RequireFunctionality functionality="QUESTIONNAIRE_TEMPLATES_READ">
          <DashboardMobileNavItem
            to={questionnairesPath.primary}
            text={t('sidebar.questionnaires')}
            selected={isPathSelected(questionnairesPath, pathname)}
            onClick={onNavigationListItemClick}
            icon={<QuestionnaireNavIcon />}
          />
        </RequireFunctionality>
        <DashboardMobileNavItem
          to={SCASE_SUPPORT_EXTERNAL_LINK}
          isExternalUrl={true}
          text={t('sidebar.footer.contactSupport')}
          selected={false}
          onClick={onNavigationListItemClick}
          icon={<SupportIcon />}
        />
        <DashboardMobileNavItem
          to={profile.primary}
          text={t('sidebar.profile')}
          selected={isPathSelected(profile, pathname)}
          onClick={onNavigationListItemClick}
          disabled={!user?.hasAccessToProfile}
          icon={<UserBadgeIcon />}
        />
        <DashboardMobileNavItem
          to={logout.primary}
          text={t('sidebar.footer.logout')}
          selected={isPathSelected(logout, pathname)}
          onClick={onNavigationListItemClick}
          icon={<LogOutIcon />}
          iconColor={theme.palette.error.main}
        />
      </Box>
    </List>
  );
}

const questionnairesPath: PagePath = {
  primary: '/questionnaires',
  secondary: '/questionnaire',
};

const profile: PagePath = {
  primary: PROFILE_PATH,
};

const logout: PagePath = {
  primary: '/logout',
};
