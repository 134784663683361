import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PlusIconWithCircleBackground } from '../Icons';
import { useStyles } from './styles';

export interface AddButtonProps {
  onAddClick: () => void;
  disabled?: boolean;
  isIconOnlyButton?: boolean;
  icon?: React.ReactNode;
  text?: string;
  fullWidth?: boolean;
  id?: string;
}

export function AddButton({
  onAddClick,
  disabled,
  icon,
  text,
  fullWidth = false,
  ...props
}: AddButtonProps) {
  const { t } = useTranslation('common');

  const iconProps = {
    color: 'inherit',
    backgroundColor: 'inherit',
  };

  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      startIcon={icon ?? <PlusIconWithCircleBackground {...(disabled ? iconProps : {})} />}
      onClick={onAddClick}
      disabled={disabled}
      className={classes.addButton}
      fullWidth={fullWidth}
      id={props.id}
    >
      {text ?? t('add')}
    </Button>
  );
}
