import { ReactNode, useState } from 'react';
import { ThresholdsButton, ThresholdsType, ThresholdsTypeWithDefault } from '../../models';
import { ThresholdsContext } from './Context';

interface Props {
  children: ReactNode;
}

export function ThresholdsContextProvider({ children }: Props) {
  const [selectedTextField, setSelectedTextField] = useState<ThresholdsTypeWithDefault>('default');
  const [hoveringOverButton, setHoveringOverButton] = useState<ThresholdsButton>({
    type: null,
    action: null,
  });
  const [hoveringOverTextField, setHoveringOverTextField] = useState<ThresholdsType | null>(null);

  const [defaultSelectedTextField, setDefaultSelectedTextField] =
    useState<ThresholdsType>('maxError');

  const defaultState: ThresholdsButton = { type: null, action: null };

  const removeHoveringOverButton = () => {
    setHoveringOverButton(defaultState);
  };

  return (
    <ThresholdsContext.Provider
      value={{
        selectedTextField,
        setSelectedTextField,
        hoveringOverTextField,
        setHoveringOverTextField,
        defaultSelectedTextField,
        setDefaultSelectedTextField,
        hoveringOverButton,
        setHoveringOverButton,
        removeHoveringOverButton,
      }}
    >
      {children}
    </ThresholdsContext.Provider>
  );
}
