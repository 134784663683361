import { Box } from '@mui/material';

interface IconProps {
  width?: number;
  height?: number;
  primaryColor?: string;
}

export function QuestionnaireResponseIcon({ width, height, primaryColor }: IconProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <svg
        width={`${width ?? 32}`}
        height={`${height ?? 32}`}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="15.5" fill="white" stroke={`${primaryColor ?? '#259509'}`} />
        <path
          d="M22.5253 9H9.47467C8.11017 9 7 10.0726 7 11.3909V19.1073C7 20.4231 8.10533 21.4937 9.46588 21.4982V25L14.6742 21.4985H22.5253C23.8898 21.4985 25 20.4259 25 19.1073V11.3909C25 10.0726 23.8898 9 22.5253 9ZM20.1823 17.9674H11.8177V16.9484H20.1823V17.9674ZM20.1823 15.7936H11.8177V14.7746H20.1823V15.7936ZM20.1823 13.6197H11.8177V12.6007H20.1823V13.6197Z"
          fill={`${primaryColor ?? '#259509'}`}
        />
      </svg>
    </Box>
  );
}
