import { getCookieConsentValue } from 'react-cookie-consent';
import { CONSENT_COOKIE_NAME } from '../../constants';
import { GA4React } from 'ga-4-react';

export function isGoogleAnalyticsConsentGranted() {
  const consent = getCookieConsentValue(CONSENT_COOKIE_NAME);

  return !!consent;
}

export async function initializeGoogleAnalytics() {
  try {
    const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
    const isTestPlatform = process.env.REACT_APP_IS_TEST_PLATFORM?.toLowerCase() === 'true';
    if (!googleAnalyticsId || isTestPlatform) {
      return;
    }

    // set 'persistent value' of name app_name to hostname to easily differentiate
    // between different deployments
    // eslint-disable-next-line
    const ga4 = new GA4React(googleAnalyticsId, { app_name: window.location.hostname } as any);
    await ga4.initialize();
    // we raise pageview event here because initialization is run asynchronously and otherwise
    // we might skip this pageview
    ga4.pageview(window.location.pathname + window.location.search);
  } catch {
    /*
     * adblock might block loading of google analytics
     * and web can function properly without google analytics loaded
     * therefor we ignore this error
     */
  }
}
