import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NEW_SUBPATH } from '../../constants';
import { useFunctionalityMode } from '../../context/functionality';
import { Form, FormProps } from '../Form';
import { FormikValues } from 'formik';
import { FormMode } from '../../types';

export interface Props<TEntityFormValues, TEntityResponseData, TEntityValidationSchema>
  extends FormProps<TEntityFormValues, TEntityResponseData, TEntityValidationSchema> {
  forceIsNew?: boolean;
  forceDirectEditableMode?: boolean;
  forceReadOnlyMode?: boolean;
}

export function EntityForm<
  TEntityFormValues extends FormikValues,
  TEntityResponseData = never,
  TEntityValidationSchema = TEntityFormValues,
>({
  forceIsNew = false,
  forceDirectEditableMode = false,
  forceReadOnlyMode = false,
  ...props
}: Props<TEntityFormValues, TEntityResponseData, TEntityValidationSchema>) {
  const location = useLocation();
  const [isNew, setIsNew] = useState(forceIsNew);

  useEffect(() => {
    setIsNew(location.pathname.endsWith(`/${NEW_SUBPATH}`) || forceIsNew);
  }, [location, forceIsNew]);

  const { hasUpdateFunctionality, hasCreateFunctionality } = useFunctionalityMode();

  let formMode: FormMode = 'readonly';
  if (!forceReadOnlyMode) {
    if (hasUpdateFunctionality && !isNew) {
      formMode = forceDirectEditableMode ? 'editable' : 'toggle';
    }
    if (hasCreateFunctionality && isNew) {
      formMode = 'editable';
    }
  }

  return (
    <Form<TEntityFormValues, TEntityResponseData, TEntityValidationSchema>
      {...props}
      formMode={formMode}
    >
      {props.children}
    </Form>
  );
}
