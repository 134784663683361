import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  menuItemRoot: {
    margin: theme.spacing(0, 1.25),
    borderRadius: theme.spacing(0.5),
    '&:hover, &:focus': {
      color: '#003491',
      backgroundColor: 'rgba(0, 86, 241, 0.06)',
    },
    padding: 0,
  },
  menuItemText: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    padding: theme.spacing(1.5, 2),
    fontWeight: 'normal',
    lineHeight: 1.29,
    letterSpacing: theme.typography.pxToRem(0.4),
  },
}));
