import { LoginForm, LoginFormValues } from './LoginForm';
import { Link, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useCurrentUser } from '../../../context';
import { ApiRoutes, makeApiRequest } from '../../../services/api';
import { Page } from '../../../components/Page';
import { Box, Typography } from '@mui/material';
import { useStyles } from './styles';
import { LoginUserRequest, LoginUserResponse } from '../../../models';
import { sendAmplitudeEvent } from '../../../utils/amplitude';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../constants';
import { useRequireFunctionality } from '../../../hooks';

interface LocationState {
  next: { pathname: string };
}

export function LoginPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isAuthenticated, reset } = useCurrentUser();
  const location = useLocation();
  const state = location.state as LocationState;
  const { t } = useTranslation('login');
  const { hasFunctionality: hasOrganizationFunctionality } =
    useRequireFunctionality('ORGANIZATIONS_READ');

  async function handleSubmit(values: LoginFormValues) {
    const requestData: LoginUserRequest = {
      username: values.username,
      password: values.password,
    };

    return makeApiRequest<LoginUserResponse, LoginUserRequest>(
      'POST',
      ApiRoutes.LoginUserToken,
      requestData,
    );
  }

  async function handleSubmitCompleted(response?: LoginUserResponse) {
    if (response && response.resetPasswordToken) {
      navigate(`/reset-password/${encodeURIComponent(response.resetPasswordToken)}`);
    } else if (response && response.token) {
      reset(response.token.accessToken);
      if (state && state.next) {
        navigate(state.next.pathname);
      }
    }
    sendAmplitudeEvent('User log in');
  }

  if (isAuthenticated) {
    if (hasOrganizationFunctionality) {
      return <Navigate to="/organizations" />;
    }

    return <Navigate to="/" />;
  }

  return (
    <Page noPaper={true}>
      <Box
        display="flex"
        width="100%"
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box className={classes.loginWrapper}>
          <LoginForm onSubmit={handleSubmit} onSubmitCompleted={handleSubmitCompleted} />
        </Box>
        <Link
          to={ROUTES.FORGOTTEN_PASSWORD.path as string}
          className={classes.forgottenPasswordLink}
        >
          {t('forgottenYourPassword')}
        </Link>
        <Typography display="inline" color="textPrimary" variant="body2">
          {t('support.label')}
          <Typography display="inline" color="primary" variant="body2">
            {' support@m.scase.io'}
          </Typography>
        </Typography>
      </Box>
    </Page>
  );
}
