import React from 'react';
import { Dialog } from '@mui/material';
import { AlarmFormValues } from '../AlarmForm';
import { AlarmType } from '../../models';
import { SubmitFormCompletedHandler, SubmitFormHandler } from '../Form';
import { AddButton } from '../AddButton';
import { useNewAlarmDialogStyles } from './styles';
import { AlarmStepperForm } from '../AlarmStepperForm';
import { useErrorSnackbar } from '../../hooks';

interface Props {
  alarmTypes: AlarmType[];
  autocompleteDrugNames: string[];
  onSubmit: SubmitFormHandler<AlarmFormValues>;
  onSubmitCompleted: SubmitFormCompletedHandler;
  initialValues?: AlarmFormValues;
}

export function NewAlarmDialog({
  alarmTypes,
  autocompleteDrugNames,
  onSubmit,
  onSubmitCompleted,
  initialValues,
}: Props) {
  const classes = useNewAlarmDialogStyles();
  const { enqueueCommonErrorSnackbar } = useErrorSnackbar();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values: AlarmFormValues) => {
    handleClose();
    return onSubmit(values);
  };

  const handleSubmitError = () => {
    enqueueCommonErrorSnackbar();
  };

  return (
    <>
      <AddButton onAddClick={handleClickOpen} id="dailyPlanTemplateItemAddBtn" />
      <Dialog
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        open={open}
        classes={{ root: classes.root }}
      >
        <AlarmStepperForm
          onSubmit={handleSubmit}
          onSubmitCompleted={onSubmitCompleted}
          onSubmitError={handleSubmitError}
          alarmTypes={alarmTypes}
          autocompleteDrugNames={autocompleteDrugNames}
          initialValues={initialValues}
          onClose={handleClose}
        />
      </Dialog>
    </>
  );
}
