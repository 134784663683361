import { makeStyles } from '@mui/styles';

const COLUMN_GAP_MARGIN = 0.2;
export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    '&>*': {
      margin: theme.spacing(COLUMN_GAP_MARGIN, COLUMN_GAP_MARGIN),
    },
  },
  day: {
    fontFamily: 'Inter',
    textAlign: 'center',
    borderRadius: theme.spacing(0.25),
    border: 'none',
    minWidth: 33,
  },
  dayText: {
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 300,
    lineHeight: 1.33,
  },
  workDay: {
    backgroundColor: '#0065f0',
  },
  weekend: {
    backgroundColor: '#f5160f',
  },
}));
