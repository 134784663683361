import { MeasurementIcon } from '../../../components/Icons';
import {
  MeasurementValue,
  MeasuringDeviceType,
  MeasuringDeviceTypeEnum,
  SystemEventMeasurement,
} from '../../../models';
import { SystemEventListItemContainer } from './SystemEventListItemContainer';
import { SystemEventListItemAction } from './SystemEventListItemAction';
import { SystemEventListItemValues } from './SystemEventListItemValues';
import { SystemEventListItemEntityPropertyValue } from './SystemEventListItemEntityPropertyValue';
import { SystemEventListItemActionPatient } from './SystemEventListItemActionPatient';
import * as NavigationConstants from '../../../constants/NavigationConstants';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { SYSTEM_EVENT_ICON_SIZE } from './styles';
import {
  getMeasurementDisplayValue,
  getStyleByCalculatedThresholds,
  sortMeasurementValuesByMeasuringDevice,
} from '../../../utils';
import { useTheme } from '@mui/material';
import { URL_SELECTED_MEASUREMENT } from '../../../constants';

interface Props {
  systemEvent: SystemEventMeasurement;
}

export function SystemEventMeasurementListItem({ systemEvent }: Props) {
  const [measurementValues, setMeasurementValues] = useState<MeasurementValue[]>([]);
  const { t } = useTranslation(['measurement', 'systemEvent']);
  const theme = useTheme();

  useEffect(() => {
    setMeasurementValues(
      sortMeasurementValuesByMeasuringDevice(
        systemEvent.entity.measurementValues,
        systemEvent.entity.measuringDevice,
      ),
    );
  }, [systemEvent.entity.measuringDevice, systemEvent.entity.measurementValues]);

  const patientMeasurementSublinkPath = measuringDeviceNameToPathName(
    systemEvent.entity.measuringDevice,
    systemEvent.entity.id,
  );

  return (
    <SystemEventListItemContainer
      linkTo={`/patients/${systemEvent.entity.patient.id}/measurements/${patientMeasurementSublinkPath}`}
    >
      <SystemEventListItemAction
        icon={
          <MeasurementIcon
            width={SYSTEM_EVENT_ICON_SIZE}
            height={SYSTEM_EVENT_ICON_SIZE}
            color={theme.palette.primary.main}
            measuringDevice={systemEvent.entity.measuringDevice}
          />
        }
        actionName={t('systemEvent:action.measurementCreated')}
        systemEvent={systemEvent}
      />
      <SystemEventListItemValues>
        <SystemEventListItemActionPatient patient={systemEvent.entity.patient} />
        <SystemEventListItemEntityPropertyValue
          propertyName={t('measurement:measuredByName')}
          propertyValue={systemEvent.entity.createdByUser.name || '--'}
          showTooltip={true}
        />
        {measurementValues.map((measurement) => (
          <SystemEventListItemEntityPropertyValue
            key={`${systemEvent.entity.id}-${measurement.type}`}
            propertyName={t(`measurement:${measurement.type}` as const)}
            propertyValue={getMeasurementDisplayValueWithStyle(measurement, t)}
          />
        ))}
      </SystemEventListItemValues>
    </SystemEventListItemContainer>
  );
}

function getMeasurementDisplayValueWithStyle(measurement: MeasurementValue, t: TFunction) {
  const displayValue = getMeasurementDisplayValue(measurement, t);
  const style = getStyleByCalculatedThresholds(measurement);
  return <span {...style}>{displayValue}</span>;
}

function measuringDeviceNameToPathName(
  measuringDevice: MeasuringDeviceType,
  measurementId?: number,
) {
  let path = '';

  if (measuringDevice == MeasuringDeviceTypeEnum.WEIGHT) {
    path = NavigationConstants.WEIGHT_PATH;
  } else if (measuringDevice == MeasuringDeviceTypeEnum.HEIGHT) {
    path = NavigationConstants.HEIGHT_PATH;
  } else if (measuringDevice == MeasuringDeviceTypeEnum.GLUCOMETER) {
    path = NavigationConstants.GLUCOMETER_PATH;
  } else if (measuringDevice == MeasuringDeviceTypeEnum.THERMOMETER) {
    path = NavigationConstants.THERMOMETER_PATH;
  } else if (measuringDevice == MeasuringDeviceTypeEnum.PULSE_OXIMETER) {
    path = NavigationConstants.PULSE_OXIMETER_PATH;
  } else if (measuringDevice == MeasuringDeviceTypeEnum.BLOOD_PRESSURE_MONITOR) {
    path = NavigationConstants.BLOOD_PRESSURE_MONITOR_PATH;
  }

  if (!path) {
    return path;
  }

  path = `${path}?${NavigationConstants.DEFAULT_MEASUREMENT_SORT_PATH_PARAM}`;
  if (measurementId !== undefined) {
    path += `&${URL_SELECTED_MEASUREMENT}=${measurementId}`;
  }

  return path;
}
