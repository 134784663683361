import { Box } from '@mui/material';
import { theme } from '../../assets/style';

interface IconProps {
  width?: number;
  height?: number;
  primaryColor?: string;
  secondaryColor?: string;
}

export function PatientCardIcon({
  width,
  height,
  primaryColor = theme.palette.primary.main,
  secondaryColor = theme.palette.primary.dark,
}: IconProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <svg
        width={`${width ?? 16}`}
        height={`${height ?? 15}`}
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip094)">
          <g clipPath="url(#clip194)">
            <path
              d="M11.2752 12.4584H9.52517C9.28367 12.4584 9.08767 12.2624 9.08767 12.0209V11.1715C9.08767 10.9341 8.9465 10.7387 8.82809 10.6168C8.33342 10.1064 8.12225 9.40986 8.24884 8.70461C8.40634 7.82786 9.1285 7.10744 10.0047 6.95228C10.651 6.83561 11.3084 7.01119 11.8054 7.42827C12.303 7.84536 12.5877 8.45611 12.5877 9.10477C12.5877 9.68927 12.3573 10.2382 11.939 10.6506C11.8358 10.7527 11.7127 10.9172 11.7127 11.1219V12.0214C11.7127 12.2624 11.5167 12.4584 11.2752 12.4584Z"
              fill="url(#paint0_linear94)"
            />
            <path
              d="M13.4629 14.5001H7.33789C7.09639 14.5001 6.90039 14.3041 6.90039 14.0626V12.9735C6.90039 12.4596 7.28481 12.0244 7.79464 11.9608L9.61697 11.7327L11.1296 11.7292L13.0061 11.9602C13.516 12.0238 13.9004 12.4596 13.9004 12.9735V14.0626C13.9004 14.3041 13.7044 14.5001 13.4629 14.5001Z"
              fill="url(#paint1_linear94)"
            />
            <path
              d="M7.04688 1.66667H6.40521C6.21271 0.995833 5.59437 0.5 4.85938 0.5C4.12437 0.5 3.50604 0.995833 3.31354 1.66667H2.67188C2.43271 1.66667 2.23438 1.865 2.23438 2.10417V2.97917C2.23438 3.53917 2.69521 4 3.25521 4H6.46354C7.02354 4 7.48438 3.53917 7.48438 2.97917V2.10417C7.48438 1.865 7.28604 1.66667 7.04688 1.66667Z"
              fill="url(#paint2_linear94)"
            />
            <path
              d="M8.21289 2.25H8.06706V2.97917C8.06706 3.86583 7.34956 4.58333 6.46289 4.58333H3.25456C2.36789 4.58333 1.65039 3.86583 1.65039 2.97917V2.25H1.50456C0.617891 2.25 -0.0996094 2.9675 -0.0996094 3.85417V11.1458C-0.0996094 12.0325 0.617891 12.75 1.50456 12.75H5.74539C5.84456 11.7933 6.56789 11 7.52456 10.825C7.44289 10.6967 7.37289 10.5567 7.31456 10.4167H2.08789C1.84872 10.4167 1.65039 10.2183 1.65039 9.97917C1.65039 9.74 1.84872 9.54167 2.08789 9.54167H7.07539C7.02872 9.20917 7.03456 8.865 7.09289 8.52083H2.08789C1.84872 8.52083 1.65039 8.3225 1.65039 8.08333C1.65039 7.84417 1.84872 7.64583 2.08789 7.64583H7.38456C7.84539 6.70083 8.74956 5.98917 9.79956 5.8025H9.81706V3.85417C9.81706 2.9675 9.09956 2.25 8.21289 2.25ZM7.62956 6.625H2.08789C1.84872 6.625 1.65039 6.42667 1.65039 6.1875C1.65039 5.94833 1.84872 5.75 2.08789 5.75H7.62956C7.86872 5.75 8.06706 5.94833 8.06706 6.1875C8.06706 6.42667 7.86872 6.625 7.62956 6.625Z"
              fill="url(#paint3_linear94)"
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear94"
            x1="12.1665"
            y1="7.47434"
            x2="8.37877"
            y2="9.97791"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={primaryColor} />
            <stop offset="0.9996" stopColor={secondaryColor} />
          </linearGradient>
          <linearGradient
            id="paint1_linear94"
            x1="13.2266"
            y1="12.008"
            x2="11.6356"
            y2="15.3731"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={primaryColor} />
            <stop offset="0.9996" stopColor={secondaryColor} />
          </linearGradient>
          <linearGradient
            id="paint2_linear94"
            x1="6.979"
            y1="0.852061"
            x2="4.44463"
            y2="4.03481"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={primaryColor} />
            <stop offset="0.9996" stopColor={secondaryColor} />
          </linearGradient>
          <linearGradient
            id="paint3_linear94"
            x1="8.86246"
            y1="3.30618"
            x2="1.27148"
            y2="9.30847"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={primaryColor} />
            <stop offset="0.9996" stopColor={secondaryColor} />
          </linearGradient>
          <clipPath id="clip094">
            <rect width="15" height="14" fill="white" transform="translate(0.400391 0.5)" />
          </clipPath>
          <clipPath id="clip194">
            <rect width="14" height="14" fill="white" transform="translate(-0.0996094 0.5)" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
}
