import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MeasurementThresholds,
  MeasurementValueWithUnit,
  MeasuringDeviceType,
  Optional,
  WebMeasurement,
} from '../../../models';
import { MeasurementsGrid } from '../../../components/Grids';
import { useStyles } from './styles';
import { LatestMeasurement, MeasurementChart, MeasurementChartExtensibleProps } from './components';
import {
  areNumbers,
  areStrings,
  sortWebMeasurementsByMeasuringDevice,
  translateAndFixWebMeasurement,
} from '../../../utils';
import { useGridContext } from '../../../context';
import { useSelectedMeasurementContext } from '../../../context/selectedMeasurement';
import { GridSortDirection } from '@mui/x-data-grid';
import { NoMeasurements } from './components/NoMeasurements';

interface Props {
  measuringDevice: MeasuringDeviceType;
  deviceMeasurements?: WebMeasurement[];
  measuringDeviceId: Optional<number>;
  patientId: string;
  measurementThresholds: Optional<MeasurementThresholds>;
  chart?: MeasurementChartExtensibleProps;
  transformChartMeasurements?: (measurements: WebMeasurement[]) => WebMeasurement[];
  onThresholdsSubmitCompleted: () => Promise<void>;
  isThresholdsModalOpen?: boolean;
}

export function DeviceMeasurement({
  measuringDevice,
  deviceMeasurements,
  chart,
  transformChartMeasurements,
}: Props) {
  const { t } = useTranslation('measurement');
  const { selectedMeasurement } = useSelectedMeasurementContext();
  const classes = useStyles();
  const [sortedMeasurements, setSortedMeasurements] = useState<WebMeasurement[]>([]);
  const [chartMeasurements, setChartMeasurements] = useState<WebMeasurement[]>([]);
  const [sortedAndTranslatedMeasurements, setSortedAndTranslatedMeasurements] = useState<
    WebMeasurement[]
  >([]);
  const [latestMeasurement, setLatestMeasurement] = useState<WebMeasurement>();

  const { pageSize, sortModel, onPageChange } = useGridContext();

  // sort data
  useEffect(() => {
    if (!deviceMeasurements) {
      return;
    }

    if (deviceMeasurements.length === 0) {
      return;
    }

    let data: WebMeasurement[] = deviceMeasurements.map((x) => ({ ...x }));

    // sort data
    data = sortWebMeasurementsByMeasuringDevice(data, measuringDevice);

    let chartData = data;
    if (transformChartMeasurements) {
      chartData = transformChartMeasurements(data);
    }

    setSortedMeasurements(data);
    setChartMeasurements(chartData);
  }, [deviceMeasurements]);

  // translate data
  useEffect(() => {
    setSortedAndTranslatedMeasurements(translateAndFixWebMeasurement(sortedMeasurements, t));
  }, [sortedMeasurements]);

  useEffect(() => {
    if (sortedAndTranslatedMeasurements && sortedAndTranslatedMeasurements.length > 0) {
      const latestMeasurement = sortedAndTranslatedMeasurements?.reduce((a, b) =>
        a.measuredOnDatetime > b.measuredOnDatetime ? a : b,
      );

      setLatestMeasurement(latestMeasurement);
    }
  }, [sortedAndTranslatedMeasurements]);

  const getSortFn = (field: keyof WebMeasurement, sort: GridSortDirection) => {
    if (field == 'measuredOnDatetime') {
      return (a: WebMeasurement, b: WebMeasurement) =>
        sort == 'desc'
          ? new Date(b.measuredOnDatetime).getTime() - new Date(a.measuredOnDatetime).getTime()
          : new Date(a.measuredOnDatetime).getTime() - new Date(b.measuredOnDatetime).getTime();
    } else {
      return (a: WebMeasurement, b: WebMeasurement) => {
        const aField = a[field] as MeasurementValueWithUnit;
        const bField = b[field] as MeasurementValueWithUnit;
        const aFieldValue = aField.value;
        const bFieldValue = bField.value;
        if (sort == 'desc') {
          if (areNumbers(aFieldValue, bFieldValue)) {
            return Number(aFieldValue) - Number(bFieldValue);
          } else if (areStrings(aFieldValue, bFieldValue)) {
            return String(aFieldValue).localeCompare(String(bFieldValue));
          }
        } else {
          if (areNumbers(aFieldValue, bFieldValue)) {
            return Number(bFieldValue) - Number(aFieldValue);
          } else if (areStrings(aFieldValue, bFieldValue)) {
            return String(bFieldValue).localeCompare(String(aFieldValue));
          }
        }

        return 0;
      };
    }
  };

  useEffect(() => {
    if (sortedAndTranslatedMeasurements && sortedAndTranslatedMeasurements.length > 0) {
      const _sortedAndTranslatedMeasurements = [...sortedAndTranslatedMeasurements];
      if (selectedMeasurement && typeof selectedMeasurement !== 'undefined') {
        let calculatedPage: number | null = null;
        if (sortModel && sortModel.length > 0) {
          calculatedPage =
            _sortedAndTranslatedMeasurements
              .sort(getSortFn(sortModel[0].field as keyof WebMeasurement, sortModel[0].sort))
              .findIndex((x) => x.id == selectedMeasurement) / pageSize;
        } else {
          calculatedPage =
            _sortedAndTranslatedMeasurements.findIndex((x) => x.id == selectedMeasurement) / 10;
        }
        if (calculatedPage !== null) {
          onPageChange(Math.floor(calculatedPage));
        }
      }
    }
  }, [selectedMeasurement]);

  if (!deviceMeasurements) {
    return <NoMeasurements />;
  }

  if (deviceMeasurements?.length == 0) {
    return <NoMeasurements />;
  }

  return (
    <div>
      <LatestMeasurement latestMeasurement={latestMeasurement} />
      <Box className={classes.graphWrapper}>
        <MeasurementChart data={chartMeasurements} {...(chart ?? {})} />
      </Box>
      <Box mt={4}>
        <MeasurementsGrid data={sortedAndTranslatedMeasurements} />
      </Box>
    </div>
  );
}
