import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

interface StyleProps {
  isDrawerOpen: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  actionsContainer: {
    display: 'flex',
    columnGap: ({ isDrawerOpen }) => (isDrawerOpen ? theme.spacing(2) : 0),
    flexDirection: ({ isDrawerOpen }) => (isDrawerOpen ? 'row' : 'column'),
    alignItems: ({ isDrawerOpen }) => (isDrawerOpen ? 'center' : 'center'),
    rowGap: theme.spacing(2),
    backgroundColor: ({ isDrawerOpen }) => (isDrawerOpen ? 'inherit' : '#F9FAFC'),
    padding: ({ isDrawerOpen }) => (isDrawerOpen ? 0 : theme.spacing(5, 0)),
    marginLeft: ({ isDrawerOpen }) => (isDrawerOpen ? theme.spacing(1.5) : 2),
  },
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.A200,
    '&:hover, &:focus': {
      color: theme.palette.primary.main,
    },
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
  },
}));
