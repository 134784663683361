import { useNavigate, useLocation } from 'react-router-dom';
import { ProgressIndicator } from '../../components/ProgressIndicator';
import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import { QuestionnaireCreateInput, QuestionnaireCreateResult, QuestionType } from '../../models';
import { Page } from '../../components/Page';
import { QuestionnaireForm, QuestionnaireFormValues } from './QuestionnaireForm';
import { useTranslation } from 'react-i18next';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import { PageTopBar } from '../../components/PageTopBar';
import React from 'react';
import { Box } from '@mui/material';
import { useEntityActionSnackbar } from '../../hooks';

interface LocationState {
  questionnaire: QuestionnaireCreateInput;
}

export function CreateQuestionnairePage() {
  const { t } = useTranslation('questionnaire');
  const { enqueueEntityCreatedSnackbar } = useEntityActionSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as LocationState;

  const [{ data: questionTypes, isLoading: isQuestionTypesLoading }] = useGetApi<QuestionType[]>(
    ApiRoutes.QuestionTypes,
  );

  async function handleSubmit(values: QuestionnaireFormValues) {
    return makeApiRequest<QuestionnaireCreateResult, QuestionnaireCreateInput>(
      'POST',
      ApiRoutes.Questionnaires,
      values,
    );
  }

  async function handleSubmitCompleted(data?: QuestionnaireCreateResult) {
    if (data) {
      navigate(`/questionnaires/${data.id}`);
    }

    enqueueEntityCreatedSnackbar(t('questionnaire'));
  }

  if (isQuestionTypesLoading) {
    return <ProgressIndicator />;
  }

  if (questionTypes == null) {
    return <div>{t('error.questionTypesNotFound')}</div>;
  }

  return (
    <>
      <StickyTopAppBar>
        <PageTopBar title={t('title.new')} hideLastUpdated={true} />
      </StickyTopAppBar>
      <Box mt={2}>
        <Page>
          <QuestionnaireForm
            onSubmit={handleSubmit}
            onSubmitCompleted={handleSubmitCompleted}
            questionTypes={questionTypes}
            initialValues={locationState?.questionnaire}
          />
        </Page>
      </Box>
    </>
  );
}
