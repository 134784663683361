import React, { useState } from 'react';
import { useDeactivateButtonStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';

interface Props {
  state: boolean;
  medicId: number;
  onDeactivate?: (medicId: number) => Promise<void>;
  onDeactivateCompleted?: () => Promise<void>;
}

export function DeactivateButton({ state, medicId, onDeactivate, onDeactivateCompleted }: Props) {
  const { t } = useTranslation('common');
  const classes = useDeactivateButtonStyles();
  const [isDeactivating, setisDeactivating] = useState(false);
  const [isActive, setIsActive] = useState(state);

  async function handleDeactivating() {
    setisDeactivating(true);
    if (onDeactivate) {
      await onDeactivate(medicId);
    }
    setisDeactivating(false);
    if (onDeactivateCompleted) {
      await onDeactivateCompleted();
    }
    setIsActive(!isActive);
  }

  return (
    <React.Fragment>
      <Button
        text={isActive ? t('deactivate') : t('activate')}
        color="secondary"
        variant="outlined"
        onClick={async (e) => {
          e.stopPropagation();
          await handleDeactivating();
        }}
        disabled={isDeactivating}
        fullWidth={false}
        classes={classes}
      />
    </React.Fragment>
  );
}
