import { Box, Hidden, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Questionnaire } from '../../../../models';
import { QuestionnaireNewIcon } from '../../../Icons';
import { useStyles } from './styles';
import { QuestionnaireScoringCategoryWithScore } from '../../QuestionnaireScoringCategoryWithScore';

interface Props {
  questionnaire: Questionnaire;
  score?: number;
  datetime?: Date;
}

export function QuestionnairePreviewHeader({ questionnaire, score, datetime }: Props) {
  const { t } = useTranslation(['common', 'questionnaire']);
  const classes = useStyles();

  return (
    <Box display="flex" width="100%">
      <Hidden mdDown={true}>
        <Box mr={2}>
          <QuestionnaireNewIcon />
        </Box>
      </Hidden>
      <Box width="100%" p={1}>
        <Box className={classes.titleAndDuplicateWrapper}>
          <Box>
            <Box className={classes.questionnaireHeaderTitle} id="questionnaireTitle">
              {questionnaire.title}
            </Box>
            <Box className={classes.questionnaireHeaderDate} id="questionnaireCreatedOnDate">
              {new Date(datetime ?? questionnaire.createdOnDatetime).toLocaleString()}
            </Box>
          </Box>
        </Box>
        <Box className={classes.questionnaireHeaderDescription} id="questionnaireDescription">
          {questionnaire.description}
        </Box>
        {score != null && (
          <Box display="flex" alignItems="center">
            <Box marginRight={1}>
              <Typography id="questionnaireScoreLabel">
                {t('questionnaire:question.score.label')}:
              </Typography>
            </Box>
            <QuestionnaireScoringCategoryWithScore
              score={score}
              scoringCategories={questionnaire.scoringCategories}
              includeCategoryName={true}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
