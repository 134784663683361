import { Dot } from 'recharts';
import { ChartPayload, DotExtendedProps } from './types';

interface Props extends DotExtendedProps {
  payload?: ChartPayload;
}

export function ExtendedDot({ payload, getStroke, getStrokeWidth, getFill, ...other }: Props) {
  const white = '#FFFFFF';
  let stroke = white;
  let strokeWidth = undefined;
  let fill = white;

  if (payload) {
    if (getStroke) {
      stroke = getStroke(payload);
    }
    if (getStrokeWidth) {
      strokeWidth = getStrokeWidth(payload);
    }
    if (getFill) {
      fill = getFill(payload);
    }
  }

  return <Dot {...other} stroke={stroke} fill={fill} r={4} strokeWidth={strokeWidth} />;
}
