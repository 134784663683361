import { Box } from '@mui/material';
import { SystemEvent } from '../../../models';
import { useSystemEventListItemActionStyles } from './styles';
import { SystemEventListItemActionName } from './SystemEventListItemActionName';
import { SystemEventListItemActionTimestamp } from './SystemEventListItemActionTimestamp';

interface Props {
  icon: React.ReactNode;
  actionName: string;
  systemEvent: SystemEvent;
}

export function SystemEventListItemAction({ icon, actionName, systemEvent }: Props) {
  const classes = useSystemEventListItemActionStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.actionNameAndDatetimeContainer}>
        <Box display="flex" flexDirection="row">
          <Box display="flex" height="100%" justifyContent="center" alignItems="center">
            {icon}
            <SystemEventListItemActionName actionName={actionName} systemEvent={systemEvent} />
          </Box>
        </Box>
        <SystemEventListItemActionTimestamp systemEvent={systemEvent} />
      </Box>
    </Box>
  );
}
