import * as React from 'react';
import { ReactNode } from 'react';
import { FunctionalityModeContext } from './Context';
import { Functionality } from '../../models';
import { useRequireFunctionality } from '../../hooks';

interface Props {
  children: ReactNode;
  createFunctionality?: Functionality | Functionality[];
  readFunctionality?: Functionality | Functionality[];
  updateFunctionality?: Functionality | Functionality[];
  deleteFunctionality?: Functionality | Functionality[];
}

export function FunctionalityProvider({
  children,
  createFunctionality,
  readFunctionality,
  updateFunctionality,
  deleteFunctionality,
}: Props) {
  const { hasFunctionality: hasCreateFunctionality } = useRequireFunctionality(createFunctionality);
  const { hasFunctionality: hasReadFunctionality } = useRequireFunctionality(readFunctionality);
  const { hasFunctionality: hasUpdateFunctionality } = useRequireFunctionality(updateFunctionality);
  const { hasFunctionality: hasDeleteFunctionality } = useRequireFunctionality(deleteFunctionality);

  return (
    <FunctionalityModeContext.Provider
      value={{
        hasCreateFunctionality,
        hasReadFunctionality,
        hasUpdateFunctionality,
        hasDeleteFunctionality,
      }}
    >
      {children}
    </FunctionalityModeContext.Provider>
  );
}
