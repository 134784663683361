import { MeasurementType } from './MeasurementType';

export enum MeasuringDeviceTypeEnum {
  THERMOMETER = 'Thermometer',
  BLOOD_PRESSURE_MONITOR = 'BloodPressureMonitor',
  GLUCOMETER = 'Glucometer',
  PULSE_OXIMETER = 'PulseOximeter',
  WEIGHT = 'Weight',
  HEIGHT = 'Height',
  MOVEMENT_PAIN = 'MovementPain',
  PROBE_FLUID_INTAKE = 'ProbeFluidIntake',
  INTRAVENOUS_FLUID_INTAKE = 'IntravenousFluidIntake',
  URINE_OUTPUT = 'UrineOutput',
}

export type MeasuringDeviceType = `${MeasuringDeviceTypeEnum}`;

export interface MeasuringDevice {
  id: number;
  name: MeasuringDeviceType;
}

export interface MeasuringDeviceWithType extends MeasuringDevice {
  measurementTypes: MeasurementType[];
}

export interface MeasuringDeviceWithLabel extends MeasuringDevice {
  label: string;
}
