import { useCallback, useState } from 'react';

export interface ShowHideResult {
  isVisible: boolean;
  show: () => void;
  hide: () => void;
}

export function useShowHide(initialVisible = false): ShowHideResult {
  const [isVisible, setIsVisible] = useState(initialVisible);

  const show = useCallback(() => {
    setIsVisible(true);
  }, []);

  const hide = useCallback(() => {
    setIsVisible(false);
  }, []);

  return {
    isVisible,
    show,
    hide,
  };
}
