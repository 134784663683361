import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../../../../components/PatientHeader/MoreOptionsButton/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  content: string;
}

export function ExportAsTextModal({ isOpen, onClose, content }: Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'patient', 'snackbar', 'error', 'report']);

  const handleCopyToClipboard = async () => {
    await navigator.clipboard.writeText(content);
    enqueueSnackbar(t('common:copyToClipboard.copiedToClipboard'));
  };

  return isOpen ? (
    <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={classes.closeButton}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <TextField
          label={t('report:title')}
          required={false}
          className={classes.input}
          margin="normal"
          variant="outlined"
          fullWidth={true}
          multiline={true}
          value={content}
          minRows={20}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCopyToClipboard} variant="outlined" className={classes.dialogButton}>
          {t('common:copyToClipboard.button')}
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <></>
  );
}
