import React from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

export const UrineOutputIcon = React.memo(function UrineOutputIconComponent({
  width,
  height,
  color,
}: Props) {
  return (
    <svg
      width={width || 25}
      height={height || 25}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.3225 0C35.7796 3.30023 30.6714 10.7968 30.5838 14.3813C30.4742 18.8619 33.2487 22.656 38.3225 22.656C43.3964 22.656 46.2074 19.2955 45.9881 14.3813C45.8126 10.4499 40.8047 3.1557 38.3225 0Z"
        fill={`${color ?? '#27335D'}`}
      />
      <path
        d="M22.9677 11.328H29.726C29.2198 12.5859 28.8679 13.8107 28.8423 14.8564C28.8292 15.3943 28.8519 15.9263 28.9104 16.448H20V14.272C20 12.6461 21.3287 11.328 22.9677 11.328Z"
        fill={`${color ?? '#27335D'}`}
      />
      <path
        d="M23.3548 18.56H29.364C29.5156 19.0245 29.6999 19.4738 29.9168 19.904H24.7097V27.9424C24.7097 28.9359 25.613 29.6893 26.5997 29.5186L33.7871 28.275C34.8601 28.0893 35.9456 28.161 36.9601 28.4649C37.6241 28.6638 38.3871 28.2177 38.3871 27.5296V24.6714C38.8535 24.6599 39.3054 24.623 39.742 24.5615V55.872C39.742 60.361 36.0736 64 31.5484 64C27.0232 64 23.3548 60.361 23.3548 55.872V51.392H29.9355C30.5412 51.392 31.0323 50.9049 31.0323 50.304C31.0323 49.7031 30.5412 49.216 29.9355 49.216H23.3548V44.16H27.3548C27.9606 44.16 28.4516 43.6729 28.4516 43.072C28.4516 42.4711 27.9606 41.984 27.3548 41.984H23.3548V36.992H29.9355C30.5412 36.992 31.0323 36.5049 31.0323 35.904C31.0323 35.3031 30.5412 34.816 29.9355 34.816H23.3548V18.56Z"
        fill={`${color ?? '#27335D'}`}
      />
    </svg>
  );
});
