import { SelectInput, SelectInputProps, SelectOption } from './SelectInput';
import { IntegrationTypeEnum } from '../../models';

type Props = Omit<SelectInputProps, 'options'>;

export function IntegrationTypeSelectInput(props: Props) {
  const integrationTypeSelectOptions: SelectOption[] = Object.entries(IntegrationTypeEnum)
    .filter((x) => isNaN(Number(x[0])))
    .map(([label, value]) => ({
      value,
      label,
    }));

  return <SelectInput {...props} options={integrationTypeSelectOptions} />;
}
