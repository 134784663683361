import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  alarmsGridRoot: {
    '& .MuiDataGrid-root': {
      padding: 0,
    },
    '& .MuiDataGrid-columnsContainer': {
      border: 'none',
      borderRadius: 'unset',
      backgroundColor: theme.palette.common.white,
      boxShadow: 'unset',
      color: '#40434a',
      '&:before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        height: '1px',
        width: '100%',
        borderBottom: '1px solid #d4d7e3',
        zIndex: 100,
      },
    },
    '& .MuiDataGrid-columnHeaderWrapper': {
      backgroundColor: theme.palette.common.white,
      color: '#40434a',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontFamily: 'Inter',
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 600,
    },
    '& .MuiDataGrid-root .MuiDataGrid-cell': {
      borderBottom: 'none !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
    },
    '& .MuiDataGrid-row': {
      position: 'relative',
      borderRadius: theme.spacing(0.5),
      border: 'solid 1px transparent',
      '&:before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        height: '1px',
        width: '100%',
        borderBottom: '1px solid #f4f5f6',
        zIndex: 100,
      },
      '& div[data-field="edit"], & div[data-field="delete"]': {
        padding: `0 ${theme.spacing(1)} !important`,
        minWidth: 'unset !important',
      },
      '&:hover, &:focus': {
        backgroundColor: '#f5f8fe',
        borderRadius: theme.spacing(0.5),
        border: 'solid 1px #0065f0',
        '&:before': {
          content: 'none',
        },
        '& div[data-entity-type="repetitionChip"]': {
          backgroundColor: '#e5eefd',
        },
      },
    },
  },
}));
