import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { IntegrationTypeEnum } from '../../models';
import { useState } from 'react';
import { IntegrationToken } from '../../models/IntegrationToken';
import { ApiRoutes, makeApiRequest } from '../../services/api';
import { AdminProfile } from '../../models/adminProfile';

interface TokenComponentProps {
  adminProfile: AdminProfile;
}

export function IntegrationTokenComponent({ adminProfile }: TokenComponentProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [tokenValue, setTokenValue] = useState('');

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const handleTokenSave = async () => {
    const url = `${ApiRoutes.AdminProfile}/token`;
    const data: IntegrationToken = {
      token: tokenValue,
    };

    await makeApiRequest<never, IntegrationToken>('POST', url, data);
    closeDialog();
  };

  return (
    <Box>
      {adminProfile?.integrationType === IntegrationTypeEnum.IRSoft && (
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              openDialog();
            }}
          >
            Integrácia
          </Button>
          {isDialogOpen && (
            <Dialog
              open={isDialogOpen}
              onClose={closeDialog}
              PaperProps={{
                style: { width: '40%' },
              }}
            >
              <DialogTitle>Zadaj token pre integráciu</DialogTitle>
              <DialogContent>
                <TextField
                  id="token"
                  label="token"
                  margin="normal"
                  variant="outlined"
                  value={tokenValue}
                  fullWidth={true}
                  onChange={(e) => {
                    setTokenValue(e.target.value);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDialog} variant="contained" color="primary">
                  Zavrieť
                </Button>
                <Button onClick={handleTokenSave} variant="contained" color="primary">
                  Uložiť
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Box>
      )}
    </Box>
  );
}
