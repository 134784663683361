import { useNavigate, useParams } from 'react-router-dom';
import { ProgressIndicator } from '../../components/ProgressIndicator';
import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import { Department, Device, DeviceCreateOrEditInput, DeviceCreateResult } from '../../models';
import { Page } from '../../components/Page';
import { useTranslation } from 'react-i18next';
import { FunctionalityProvider } from '../../context/functionality';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import React from 'react';
import { useEntityActionSnackbar, useRequireFunctionality } from '../../hooks';
import { DeviceFormValues, DeviceForm } from './DeviceForm';
import { DeviceHeader } from '../../components/DeviceHeader';

export function DevicePage() {
  const { t } = useTranslation('device');
  const { enqueueEntityUpdatedSnackbar } = useEntityActionSnackbar();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [
    { data: device, isLoading: isDeviceLoading },
    { refetch: refetchDevice },
    { lastFetchDatetime },
  ] = useGetApi<Device>(ApiRoutes.Device(id as string));
  const { hasFunctionality: hasOrganizationFunctionality } =
    useRequireFunctionality('ORGANIZATIONS_READ');

  const [{ data: departments, isLoading: areDepartmentsLoading }] = useGetApi<Department[]>(
    ApiRoutes.Departments,
  );
  async function onSubmit(values: DeviceFormValues) {
    const input: DeviceCreateOrEditInput = {
      ...values,
    };
    return makeApiRequest<never, DeviceCreateOrEditInput>(
      'PUT',
      ApiRoutes.Device(id as string),
      input,
    );
  }

  async function onSubmitCompleted(data?: DeviceCreateResult) {
    await refetchDevice();
    navigate(`/devices/${id}`);

    enqueueEntityUpdatedSnackbar(t('device', { context: data?.chassisId }), {
      context: data?.chassisId,
    });
  }

  if (isDeviceLoading || areDepartmentsLoading) {
    return <ProgressIndicator />;
  }

  if (device == null) {
    return <div>{t('error.deviceNotFound')}</div>;
  }

  if (!hasOrganizationFunctionality && departments == null) {
    return <div>{t('error.departmentNotFound')}</div>;
  }

  const { departments: deviceDepartments, ...deviceFields } = device;

  return (
    <FunctionalityProvider
      createFunctionality="DEVICES_CREATE"
      readFunctionality="DEVICES_READ"
      updateFunctionality="DEVICES_UPDATE"
      deleteFunctionality="DEVICES_DELETE"
    >
      <StickyTopAppBar>
        <DeviceHeader device={device} lastUpdated={lastFetchDatetime} />
      </StickyTopAppBar>
      <Page noPaper={true} requireFunctionality="DEVICES_READ">
        <DeviceForm
          initialValues={{
            ...deviceFields,
            departmentIds: deviceDepartments.map((d) => d.id),
          }}
          onSubmit={onSubmit}
          onSubmitCompleted={onSubmitCompleted}
          departments={departments || []}
        />
      </Page>
    </FunctionalityProvider>
  );
}
