import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: 'rgba(12, 22, 36, 0.04)',
      alignItems: 'center',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  endDateAndIdWrapper: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  sectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: theme.spacing(0.5, 1),
      marginRight: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      marginRight: 'unset',
    },
  },
  sectionTitle: {
    [theme.breakpoints.down('lg')]: {
      paddingBottom: '0',
      color: '#6c7688',
      fontSize: 10,
      '&:after': {
        content: "':'",
        marginRight: theme.spacing(0.5),
      },
    },
  },
  sectionValue: {
    fontFamily: 'Inter',
    fontSize: 12,
    lineHeight: 1.33,
    color: '#a3a9bd',
    [theme.breakpoints.down('lg')]: {
      color: theme.palette.grey.A700,
    },
  },
}));
