import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { FormAddButton } from '../AddButton';
import { FieldArray, FieldArrayRenderProps, useField, useFormikContext } from 'formik';
import { FormDeleteIconButton } from '../DeleteIconButton';
import { TextInput } from '../TextInput';
import { Room, RoomFields } from '../../models';
import { useTranslation } from 'react-i18next';
import { useRoomsInputStyles } from './styles';
import { DepartmentFormValues } from '../../pages/department/DepartmentForm';

interface Props {
  id: string;
}

export function RoomsInput({ id }: Props) {
  const emptyValue: RoomFields = {
    name: '',
    bedCount: 1,
    note: '',
  };
  const { t } = useTranslation('department');
  const [{ value }] = useField<Room[]>(id);
  const classes = useRoomsInputStyles();

  const formikContext = useFormikContext<DepartmentFormValues>();
  const { setFieldValue, initialValues } = formikContext;

  // V useEffect overíme, či je pole prázdne pri prvom renderovaní komponenty.
  useEffect(() => {
    const isNew = !initialValues.name && initialValues.name === '';
    if (isNew && (!value || !Array.isArray(value) || value.length === 0)) {
      setFieldValue(id, [emptyValue]);
    }
  }, []);

  function renderInputs(arrayHelpers: FieldArrayRenderProps) {
    if (!value || !Array.isArray(value) || value.length === 0) {
      return null;
    }

    return value.map((value, index) => (
      <Box key={index} display="flex" marginY={1} data-testid={`room-${index}`}>
        <Box width="100%">
          <Box className={classes.formRow}>
            <Box width="15%">
              <TextInput id={`${id}[${index}].name`} label={t('room.name.label')} required={true} />
            </Box>
            <Box width="10%">
              <TextInput
                id={`${id}[${index}].bedCount`}
                data-testid={`${id}[${index}].bedCount`}
                label={t('room.bedCount.label')}
                required={true}
              />
            </Box>
            <Box width="75%" display="flex">
              <TextInput
                id={`${id}[${index}].note`}
                data-testid={`${id}[${index}].note`}
                label={t('room.note.label')}
                required={false}
              />
              <FormDeleteIconButton onDeleteClick={() => arrayHelpers.remove(index)} />
            </Box>
          </Box>
        </Box>
      </Box>
    ));
  }

  function renderArray(arrayHelpers: FieldArrayRenderProps) {
    return (
      <React.Fragment>
        <Box marginTop={3}>
          {value && value.length > 0 && (
            <React.Fragment>
              <Typography variant="body1">
                {t('room.title.list', {
                  count: value.length,
                })}
              </Typography>
              <hr />
            </React.Fragment>
          )}
          {renderInputs(arrayHelpers)}
          <Box display="flex" justifyContent="space-between">
            <FormAddButton
              onAddClick={() => arrayHelpers.push(emptyValue)}
              text={t('room.addButton.text')}
            />
          </Box>
        </Box>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <FieldArray name={id} render={renderArray} />
    </React.Fragment>
  );
}
