import { Box, Link, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React, { ReactNode, Ref } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useStyles } from './styles';

interface Props {
  to: string;
  isExternalUrl?: boolean;
  selected: boolean;
  text: string;
  icon?: ReactNode;
  showIcon?: boolean;
  showText?: boolean;
  disableGutters?: boolean;
  textClassName?: string;
  selectedTextClassName?: string;
  listItemClassName?: string;
  selectedListItemClassName?: string;
  iconAndTextContainerClassName?: string;
  disabled?: boolean;
}

export function LinkListItem({
  selected,
  to,
  text,
  icon,
  isExternalUrl = false,
  showIcon = true,
  showText = true,
  disableGutters = false,
  textClassName,
  selectedTextClassName,
  listItemClassName,
  selectedListItemClassName,
  iconAndTextContainerClassName,
  disabled = false,
}: Props) {
  const classes = useStyles();
  const renderLink = React.useMemo(
    () =>
      React.forwardRef((props, ref) => <RouterLink to={to} ref={ref as Ref<never>} {...props} />),
    [],
  );

  return (
    <ListItem
      component={!isExternalUrl ? renderLink : Link}
      {...(isExternalUrl ? { href: to, target: '_blank' } : {})}
      button={true}
      disabled={disabled}
      disableGutters={disableGutters}
      selected={selected}
      className={listItemClassName}
      classes={{ selected: selectedListItemClassName }}
    >
      <Box className={iconAndTextContainerClassName}>
        <Box display="flex" alignItems="center" justifyContent="center">
          {showIcon && icon && (
            <ListItemIcon classes={{ root: classes.listItemIconRoot }}>{icon}</ListItemIcon>
          )}
        </Box>
        {showText && text && (
          <Box display="flex" alignItems="center" justifyContent="center" overflow="hidden">
            <ListItemText
              primary={text}
              classes={{
                primary: `${textClassName} ${selected ? selectedTextClassName : ''}`,
              }}
            />
          </Box>
        )}
      </Box>
    </ListItem>
  );
}
