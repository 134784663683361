import { Box } from '@mui/material';
import { useStyles } from './styles';
import { ReactNode } from 'react';

interface Props {
  title: string;
  subtitle?: string;
  image?: ReactNode;
  textOverImage?: boolean;
}

export function NoRecords({ title, subtitle, image, textOverImage = false }: Props) {
  const classes = useStyles();
  return (
    <Box className={`${classes.wrapper} ${textOverImage ? classes.wrapperTextOverImage : ''}`}>
      {image && (
        <Box
          className={`${classes.imageWrapper} ${
            textOverImage ? classes.imageWrapperTextOverImage : ''
          }`}
        >
          {image}
        </Box>
      )}
      <Box className={`${textOverImage ? classes.textWrapperTextOverImage : ''}`}>
        <Box className={classes.title}>{title}</Box>
        {subtitle && <Box className={classes.subtitle}>{subtitle}</Box>}
      </Box>
    </Box>
  );
}
