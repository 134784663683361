import { Box } from '@mui/material';
import { useSystemEventListSubItemStyles, SystemEventListSubItemStyleProps } from './styles';

interface Props extends SystemEventListSubItemStyleProps {
  children: React.ReactNode;
}

export function SystemEventListSubItem({ children, ...rest }: Props) {
  const classes = useSystemEventListSubItemStyles(rest);

  return (
    <Box display="flex" flexDirection="column" className={classes.container}>
      {children}
    </Box>
  );
}
