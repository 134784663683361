import { StyledTabs } from '../StyledTabs';

interface Props {
  value: string;
  children: React.ReactNode;
  id: string;
}

export function NavTabs({ value, children, ...props }: Props) {
  return (
    <StyledTabs value={value} onChange={() => {}} {...props}>
      {children}
    </StyledTabs>
  );
}
