import { SubmitButton } from '../SubmitButton';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { ReactNode } from 'react';

interface Props {
  entityName?: string;
  actionText?: string;
  fullWidth?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  height?: number | 'auto';
  width?: number | 'auto';
  invertColors?: boolean;
  disabledTooltipText?: string;
  forceSubmitting?: boolean;
  onSubmit?: () => Promise<void>;
}

export interface StyleProps {
  height: number | 'auto';
  width: number | 'auto';
  invertColors?: boolean;
}

export function SaveSubmitButton({
  entityName,
  actionText,
  height = 'auto',
  width = 'auto',
  invertColors = false,
  forceSubmitting = false,
  ...props
}: Props) {
  const { t } = useTranslation('common');
  const classes = useStyles({
    height: height,
    width: width,
    invertColors: invertColors,
  });

  let buttonText = actionText ?? t('save');
  if (entityName) {
    buttonText += ` ${entityName}`;
  }

  return (
    <SubmitButton
      {...props}
      forceSubmitting={forceSubmitting}
      buttonText={buttonText}
      variant="contained"
      color="primary"
      classes={classes}
    />
  );
}
