import React from 'react';
import { Box } from '@mui/material';
import { useTabPanelStyles } from './styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  tabName: string;
  setDialogMinHeight?: boolean;
}

export function TabPanel({
  index,
  value,
  tabName,
  children,
  setDialogMinHeight = false,
}: TabPanelProps) {
  const classes = useTabPanelStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${tabName}-tabpanel-${index}`}
      aria-labelledby={`${tabName}-tab-${index}`}
      className={setDialogMinHeight ? classes.tabPanelDialogMinHeight : ''}
    >
      {value === index && <Box className={classes.childrenWrapper}>{children}</Box>}
    </div>
  );
}
