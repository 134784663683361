import { PatientDevicePairing as PatientDevicePairingModel } from '../../../../../models';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from '@react-pdf/renderer';
import { SectionTitle } from '../../common/SectionTitle';
import { NoDataText } from '../../common/NoDataText';
import React from 'react';
import { PatientDevicePairing } from '../PatientDevicePairing';
import { commonReactPdfStyles } from '../../commonReactPdfStyles';

interface Props {
  patientDevicePairings: PatientDevicePairingModel[];
}

export function PatientDevicePairings({ patientDevicePairings }: Props) {
  const { t } = useTranslation(['patientDevicePairing', 'common']);

  const styles = StyleSheet.create({
    pairingWrapper: {
      marginVertical: 10,
    },
  });

  return (
    <View style={commonReactPdfStyles.sectionView}>
      <SectionTitle title={t('patientDevicePairing:title')} />
      {patientDevicePairings.length > 0 ? (
        patientDevicePairings.map((pairing) => (
          <View key={pairing.id} style={styles.pairingWrapper}>
            <PatientDevicePairing patientDevicePairing={pairing} />
          </View>
        ))
      ) : (
        <NoDataText />
      )}
    </View>
  );
}
