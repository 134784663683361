import React from 'react';
import { Tabs } from '@mui/material';
import { useMeasurementStyles } from './styles';

interface StyledTabsProps {
  value: number | string;
  onChange: (event: React.SyntheticEvent, newValue: number | string) => void;
  children: React.ReactNode;
}

export function StyledMeasurementsTabs({ ...props }: StyledTabsProps) {
  const { onChange, value, children } = props;
  const classes = useMeasurementStyles();
  return (
    <Tabs
      value={value}
      onChange={onChange}
      children={children}
      classes={classes}
      TabIndicatorProps={{ children: <span className={classes.indicator} /> }}
      id="measurement-nav-tabs"
    />
  );
}
