import { Group } from '../../models';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { DeleteCompletedHandler, DeleteHandler } from '../../components/Grid';
import { GroupsGrid } from '../../components/Grids/GroupGrid';

interface Props {
  groups: Group[] | null;
  onDelete?: DeleteHandler;
  onDeleteCompleted?: DeleteCompletedHandler<Group>;
}

export function GroupsList({ groups, onDelete, onDeleteCompleted }: Props) {
  const navigate = useNavigate();

  function onRowClick(row: Group) {
    navigate(`/groups/${row.id}`);
  }

  return (
    <Box width="100%">
      {groups && (
        <GroupsGrid
          data={groups}
          onDelete={onDelete}
          onDeleteCompleted={onDeleteCompleted}
          onRowClick={onRowClick}
        />
      )}
    </Box>
  );
}
