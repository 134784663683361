import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import {
  AnswerOptionCreateInput,
  Questionnaire,
  QuestionnaireEditInput,
  QuestionnaireQuestionCreateInput,
  QuestionnaireUpdateResult,
  QuestionType,
} from '../../models';
import { useNavigate, useParams } from 'react-router-dom';
import { useEntityActionSnackbar, usePageEditability } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { QuestionnaireForm, QuestionnaireFormValues } from './QuestionnaireForm';
import React, { useEffect } from 'react';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import { PageTopBar } from '../../components/PageTopBar';
import { Box } from '@mui/material';
import { Page } from '../../components/Page';
import { QuestionnaireFormLoading } from './QuestionnaireFormLoading';

export function QuestionnairePage() {
  const { questionnaireId } = useParams<{ questionnaireId: string }>();
  const navigate = useNavigate();
  const { isEditable } = usePageEditability();
  const { t } = useTranslation(['questionnaire', 'common', 'snackbar']);
  const { enqueueWarningSnackbar, enqueueEntityUpdatedSnackbar } = useEntityActionSnackbar();
  const questionnaireReadUrl = `/questionnaires/${questionnaireId}`;

  const [{ data: questionTypes, isLoading: isQuestionTypesLoading }] = useGetApi<QuestionType[]>(
    ApiRoutes.QuestionTypes,
  );

  const [{ data: questionnaire, isLoading: isQuestionnaireLoading }, , { lastFetchDatetime }] =
    useGetApi<Questionnaire>(ApiRoutes.Questionnaire(questionnaireId as string));

  function handleSubmit(values: QuestionnaireFormValues) {
    const input: QuestionnaireEditInput = {
      ...values,
    };

    return makeApiRequest<QuestionnaireUpdateResult, QuestionnaireEditInput>(
      'PUT',
      ApiRoutes.Questionnaire(questionnaireId as string),
      input,
    );
  }

  useEffect(() => {
    if (isEditable && questionnaire != null && questionnaire.isAssigned) {
      navigate(questionnaireReadUrl);
      enqueueWarningSnackbar(t('questionnaire:warning.questionnaireCantBeEditedBecauseIsAssigned'));
    }
  }, [questionnaire]);

  async function handleSubmitCompleted() {
    navigate(questionnaireReadUrl);
    enqueueEntityUpdatedSnackbar(t('questionnaire:questionnaire'));
  }

  const isLoading = isQuestionnaireLoading || isQuestionTypesLoading;

  function getRenderComponent() {
    if (questionnaire == null) {
      return <div>{t('questionnaire:error.questionnaireNotFound')}</div>;
    }

    if (questionTypes == null) {
      return <div>{t('questionnaire:error.questionTypesNotFound')}</div>;
    }

    return (
      <QuestionnaireForm<QuestionnaireUpdateResult>
        initialValues={questionnaireToQuestionnaireForm(questionnaire)}
        onSubmit={handleSubmit}
        onSubmitCompleted={handleSubmitCompleted}
        questionTypes={questionTypes}
      />
    );
  }

  return (
    <>
      <StickyTopAppBar>
        <PageTopBar title={questionnaire?.title ?? '-'} lastUpdated={lastFetchDatetime} />
      </StickyTopAppBar>
      <Box mt={2}>
        <Page>{isLoading ? <QuestionnaireFormLoading /> : getRenderComponent()}</Page>
      </Box>
    </>
  );
}

function questionnaireToQuestionnaireForm(questionnaire: Questionnaire): QuestionnaireFormValues {
  return {
    id: questionnaire.id,
    title: questionnaire.title,
    description: questionnaire.description,
    items: questionnaire.items.map(
      (x) =>
        ({
          id: x.id,
          questionTypeId: x.type.id,
          text: x.text,
          index: x.index,
          questionnaireAnswerOptions: x.questionnaireAnswerOptions.map(
            (y) =>
              ({
                id: y.id,
                value: y.value,
                initialValue: y.initialValue,
                score: y.score,
              }) as AnswerOptionCreateInput,
          ),
          initialValue:
            x.initialValueDouble ??
            x.initialValueInt ??
            x.initialValueBoolean ??
            x.initialValueString,
        }) as QuestionnaireQuestionCreateInput,
    ),
    scoringCategories: questionnaire.scoringCategories,
  } as QuestionnaireFormValues;
}
