import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles<Theme, { hasError: boolean }>((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%', // Ensures the form control fills the parent
  },
  selectEmpty: {
    border: (props) => (props.hasError ? `0.5px solid ${theme.palette.error.main}` : 'none'),
    borderRadius: 5, // Apply border-radius from theme for consistency
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: (props) => (props.hasError ? `${theme.palette.error.main}` : 'inherit'), // Ensure the notched outline also changes color
    },
  },
  label: {
    backgroundColor: theme.palette.background.paper, // Ensures background matches form field
    padding: '0 5px', // Padding to prevent the label from being crossed out by the border
    color: (props) => (props.hasError ? theme.palette.error.main : 'inherit'), // Change color conditionally
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
      padding: '0 5px',
    },
    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
}));
