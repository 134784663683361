import { Medic } from '../../models';
import React from 'react';
import { useRequireFunctionality } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { MoreOptionsButton } from './MoreOptionsButton';
import {
  ResetOnetimePasswordCompletedHandler,
  ResetOnetimePasswordHandler,
} from '../ResetOnetimePasswordButton';
import { ResetPasswordCompletedHandler, ResetPasswordHandler } from '../ResetPasswordButton';
import { PageTopBarDetailed } from '../PageTopBarDetailed';

interface Props {
  medic: Medic;
  lastUpdated: Date | null;
  onResetOnetimePassword?: ResetOnetimePasswordHandler;
  onResetOnetimePasswordCompleted?: ResetOnetimePasswordCompletedHandler;
  onResetPasscode?: ResetPasswordHandler;
  onResetPasscodeCompleted?: ResetPasswordCompletedHandler;
}

export function MedicHeader({
  medic,
  lastUpdated,
  onResetOnetimePassword,
  onResetOnetimePasswordCompleted,
  onResetPasscode,
  onResetPasscodeCompleted,
}: Props) {
  const { checkHasFunctionality } = useRequireFunctionality();
  const { t } = useTranslation('medic');
  return (
    <PageTopBarDetailed
      entityName={t('title.medic', {
        name: `${medic.firstName} ${medic.lastName}`,
        context: medic.sex,
      })}
      lastUpdated={lastUpdated}
      buttonsComponent={
        <>
          {checkHasFunctionality('MEDICS_UPDATE') && (
            <MoreOptionsButton
              medic={medic}
              onResetOnetimePassword={onResetOnetimePassword}
              onResetOnetimePasswordCompleted={onResetOnetimePasswordCompleted}
              onResetPasscode={onResetPasscode}
              onResetPasscodeCompleted={onResetPasscodeCompleted}
            />
          )}
        </>
      }
    />
  );
}
