import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { BOTTOM_NAV_HEIGHT } from '../../constants';

export const useStyles = makeStyles((theme: Theme) => ({
  bottomNavWrapper: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  bottomNavRoot: {
    minHeight: BOTTOM_NAV_HEIGHT,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  bottomNavAction: {
    '& .MuiBottomNavigationAction-wrapper': {
      transition: theme.transitions.create('color', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter,
      }),
      color: '#B8C2E0',
    },
    '& .MuiBottomNavigationAction-wrapper>svg': {
      width: 44,
      height: 44,
    },
  },
  bottomNavActionLabel: {
    display: 'none',
  },
  bottomNavActionSelected: {
    paddingTop: `${theme.spacing(2)} !important`,
    '& .MuiBottomNavigationAction-wrapper': {
      color: '#0056F1',
    },
  },
}));
