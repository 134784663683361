import { Box } from '@mui/material';
import React from 'react';
import { AnswerOption } from '../../../../models';
import { useStyles } from './styles';

interface Props {
  answers: AnswerOption[];
  selected: boolean;
}

export function QuestionnaireResponseAnswerList({ answers, selected }: Props) {
  const classes = useStyles();

  function formatAnswerOptionText(answerOption: AnswerOption) {
    let text = answerOption.value;

    if (answerOption.score != null) {
      text += ` (${answerOption.score})`;
    }

    return text;
  }

  return (
    <React.Fragment>
      {answers.map((sa) => {
        return (
          <Box
            key={sa.id}
            className={`${classes.questionAnswerOption} ${
              selected
                ? classes.selectedQuestionAnswerOption
                : classes.notSelectedQuestionAnswerOption
            }`}
            px={1}
            py={0.4}
            mr={0.5}
          >
            {formatAnswerOptionText(sa)}
          </Box>
        );
      })}
    </React.Fragment>
  );
}
