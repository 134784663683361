import * as Yup from 'yup';
import { Box } from '@mui/material';
import { EntityForm } from '../../../components/EntityForm';
import { SubmitFormHandler } from '../../../components/Form';
import {
  MeasuringDeviceTypeEnum,
  MeasuringDeviceWithLabel,
  MeasuringDeviceWithType,
  Optional,
} from '../../../models';
import React from 'react';
import { MeasuringDevicesTypesInput } from '../../../components/MeasuringDeviceTypesInput';
import { ErrorMessage } from 'formik';
import { useExportPatientFormCommonStyles, useExportPatientFormStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '../../../components/DatePicker';
import { firstLetterToLower, measuringDeviceOptionsSortFn } from '../../../utils';
import { Skeleton } from '@mui/lab';
import { useRequireFunctionality } from '../../../hooks';
import { CheckboxIconInput } from '../../../components/CheckboxIconInput';
import { IconWrapper } from '../../../components/IconWrapper';
import { ReactComponent as QuestionnaireNavIcon } from '../../../assets/icons/QuestionnaireNavIcon.svg';
import { ReactComponent as UserBadgeIcon } from '../../../assets/icons/UserBadgeIcon.svg';
import { ReactComponent as NoteIcon } from '../../../assets/icons/NoteIcon.svg';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import {
  PATIENT_NOTES_READ_POLICY,
  PATIENT_QUESTIONNAIRES_READ_POLICY,
} from '../../../constants/Policies';
import { ExportByTypeFormButton } from './components/ExportByTypeFormButton';
import { ExportTypes } from '../../../types';
import { ExportAsTextInfoTooltip } from './components/ExportAsTextInfoTooltip';

export interface ExportPatientFormValues {
  includePatientDetail: boolean;
  includePairings: boolean;
  includeAnsweredQuestionnaires: boolean;
  includeNotes: boolean;
  from?: Optional<Date>;
  to?: Optional<Date>;
  deviceIds: number[];
  exportType?: ExportTypes;
}

interface Props {
  initialValues?: ExportPatientFormValues;
  onSubmit: SubmitFormHandler<ExportPatientFormValues>;
  measuringDevices?: MeasuringDeviceWithType[] | null;
  areMeasuringDevicesLoading: boolean;
  noResetFormOnSubmitCompleted?: boolean;
  forceSubmitting?: boolean;
}

export function ExportPatientForm({
  initialValues,
  onSubmit,
  measuringDevices,
  areMeasuringDevicesLoading,
  noResetFormOnSubmitCompleted,
  forceSubmitting,
}: Props): JSX.Element {
  const { t } = useTranslation(['common', 'report', 'measurement']);
  const { checkHasFunctionality } = useRequireFunctionality();
  const commonClasses = useExportPatientFormCommonStyles();
  const classes = useExportPatientFormStyles();
  const schema: Yup.SchemaOf<ExportPatientFormValues> = Yup.object()
    .shape(
      {
        includePatientDetail: Yup.boolean().required(),
        includePairings: Yup.boolean().required(),
        includeNotes: Yup.boolean().required(),
        includeAnsweredQuestionnaires: Yup.boolean().required(),
        from: Yup.date()
          .nullable()
          .typeError(t('report:from.validation.validDate'))
          .when('to', {
            is: (value: boolean) => value,
            then: Yup.date()
              .nullable()
              .typeError(t('report:from.validation.validDate'))
              .max(Yup.ref('to'), ({ max }) =>
                t('report:from.validation.maxDate', {
                  max: new Date(max).toLocaleDateString(),
                }),
              ),
          }),
        to: Yup.date()
          .nullable()
          .typeError(t('report:to.validation.validDate'))
          .when('from', {
            is: (value: boolean) => value,
            then: Yup.date()
              .nullable()
              .typeError(t('report:to.validation.validDate'))
              .min(Yup.ref('from'), ({ min }) =>
                t('report:to.validation.minDate', {
                  min: new Date(min).toLocaleDateString(),
                }),
              ),
          }),
        deviceIds: Yup.array().of(Yup.number().required()).required(),
        exportType: Yup.mixed<ExportTypes>().oneOf(Object.values(ExportTypes)),
      },
      [['from', 'to']],
    )
    .test({
      name: 'atLeastOneCheckboxSelected',
      test: function (values) {
        const formValues = values as ExportPatientFormValues;
        const {
          includeAnsweredQuestionnaires,
          includePatientDetail,
          includePairings,
          includeNotes,
          deviceIds,
        } = formValues;

        const isValid =
          includeAnsweredQuestionnaires ||
          includePatientDetail ||
          includeNotes ||
          includePairings ||
          deviceIds.length > 0;

        if (isValid) {
          return true;
        }

        return this.createError({
          path: 'deviceIds',
          message: t('common:validation.field.atLeastOneOption'),
        });
      },
    });

  const measuringDevicesOptions: MeasuringDeviceWithLabel[] = (measuringDevices ?? [])
    .sort(measuringDeviceOptionsSortFn)
    .filter((x) => x.name != MeasuringDeviceTypeEnum.HEIGHT)
    .map((measuringType) => ({
      id: measuringType.id,
      name: measuringType.name,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      label: t(`measurement:measuringDevices.${firstLetterToLower(measuringType.name)}` as any),
    }));

  const defaultFormValues: ExportPatientFormValues = {
    includePatientDetail: true,
    includePairings: false,
    includeNotes: false,
    includeAnsweredQuestionnaires: false,
    from: null,
    to: null,
    deviceIds: [],
    exportType: ExportTypes.PDF,
  };

  return (
    <EntityForm<ExportPatientFormValues>
      initialValues={initialValues || defaultFormValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      forceDirectEditableMode={true}
      noResetFormOnSubmitCompleted={noResetFormOnSubmitCompleted}
    >
      <Box className={classes.formTitle}>{t('report:title')}</Box>
      <Box className={classes.additionalSettings}>
        <Box className={classes.flexWrapMoreItems}>
          <Box>
            <CheckboxIconInput
              id="includePatientDetail"
              label={t('report:includePatientDetail')}
              icon={
                <IconWrapper size={20}>
                  <UserBadgeIcon />
                </IconWrapper>
              }
            />
          </Box>
          {checkHasFunctionality('PATIENT_DEVICE_PAIRINGS_READ') && (
            <Box>
              <CheckboxIconInput
                id="includePairings"
                label={t('report:includePairings')}
                icon={
                  <IconWrapper size={20}>
                    <PhonelinkIcon fontSize="small" />
                  </IconWrapper>
                }
              />
            </Box>
          )}
          {checkHasFunctionality(PATIENT_NOTES_READ_POLICY) && (
            <Box>
              <CheckboxIconInput
                id="includeNotes"
                label={t('report:includeNotes')}
                icon={
                  <IconWrapper size={20}>
                    <NoteIcon />
                  </IconWrapper>
                }
              />
            </Box>
          )}
          {checkHasFunctionality(PATIENT_QUESTIONNAIRES_READ_POLICY) && (
            <Box>
              <CheckboxIconInput
                id="includeAnsweredQuestionnaires"
                label={t('report:includeAnsweredQuestionnaires')}
                icon={
                  <IconWrapper size={20}>
                    <QuestionnaireNavIcon />
                  </IconWrapper>
                }
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box className={`${classes.dateWrapper} ${classes.flexRowPadding}`}>
        <DatePicker id="from" label={t('report:from.label')} />
        <DatePicker id="to" label={t('report:to.label')} />
      </Box>
      <Box>
        <Box className={classes.sensorsSubtitle}>{t('report:sensors.title')}</Box>
        <Box className={classes.flexWrapMoreItems}>
          {!areMeasuringDevicesLoading ? (
            <MeasuringDevicesTypesInput
              id="deviceIds"
              measuringDevicesOptions={measuringDevicesOptions}
              setAsInitiallyTouched={true}
            />
          ) : (
            [...Array(5)].map((e, i) => <Skeleton variant="rectangular" key={i} height={60} />)
          )}
        </Box>
        <Box className={commonClasses.customErrorMessageMinHeight}>
          <ErrorMessage
            name="deviceIds"
            className={commonClasses.customErrorMessage}
            component="div"
          />
        </Box>
        <Box className={commonClasses.customErrorMessageMinHeight}>
          <ErrorMessage name="test" className={commonClasses.customErrorMessage} component="div" />
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Box display="flex" marginRight={1}>
          <ExportAsTextInfoTooltip />
          <ExportByTypeFormButton
            text={t('common:exportAsText')}
            forceSubmitting={forceSubmitting}
            exportType={ExportTypes.TXT}
          />
        </Box>
        <ExportByTypeFormButton
          text={t('common:exportToPdf')}
          forceSubmitting={forceSubmitting}
          exportType={ExportTypes.PDF}
        />
      </Box>
    </EntityForm>
  );
}
