import React from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

export const BloodPressureIcon = React.memo(function BloodPressureIconComponent({
  width,
  height,
  color,
}: Props) {
  return (
    <svg
      width={width || 16}
      height={height || 18}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.92009 11.7672C1.85926 11.9004 0.0820312 12.4968 0.0820312 14.0263C0.0820312 15.7723 1.8615 16.465 3.91102 16.465C4.81175 16.465 5.69754 16.3165 6.55403 16.1729C7.55448 16.0052 8.51495 15.8442 9.41256 15.9344C9.5947 17.0985 11.3141 17.8818 12.3365 17.9845C13.7614 18.1277 14.9754 17.2631 15.1007 16.0166C15.2259 14.7701 14.2081 13.6813 12.7833 13.5382C11.7608 13.4354 9.87982 13.8818 9.46975 14.9864C8.10289 14.8906 6.78232 15.0872 5.68813 15.2501C5.01959 15.3496 4.43555 15.4365 3.97709 15.4365C2.8771 15.4365 1.08937 15.1008 1.08937 13.9591C1.08937 13.1299 2.3649 12.8321 3.06298 12.7312C3.39426 13.0706 4.25865 13.2719 5.19973 13.3916C5.26532 13.4 5.32992 13.4096 5.39941 13.4199C5.80551 13.4804 6.37841 13.5658 8.28864 13.5658C9.18512 13.5658 13.6572 13.5103 13.6572 12.4154V1.67824C13.6572 0.583231 9.18512 0.527832 8.28864 0.527832C7.39215 0.527832 2.92009 0.583267 2.92009 1.67824V3.92055C1.56822 4.51395 0.619286 5.86176 0.619286 7.43019C0.619286 8.99862 1.56822 10.3464 2.92009 10.9398V11.7672ZM12.4787 1.67824C11.7005 1.48799 10.1101 1.29476 8.28864 1.29476C6.46715 1.29476 4.87671 1.48799 4.09857 1.67824C4.87671 1.86845 6.46715 2.06168 8.28864 2.06168C10.1101 2.06168 11.7005 1.86845 12.4787 1.67824ZM7.52168 7.43026C7.52168 5.73873 6.14546 4.36253 4.45394 4.36253C2.76241 4.36253 1.3862 5.73873 1.3862 7.43026C1.3862 9.12179 2.76241 10.498 4.45394 10.498C6.14546 10.498 7.52168 9.12179 7.52168 7.43026ZM3.71687 7.60956C3.71687 7.32944 3.87537 7.09443 4.10035 6.96056V5.69224C4.10035 5.48027 4.27186 5.30876 4.48383 5.30876C4.69579 5.30876 4.8673 5.48027 4.8673 5.69224V6.96056C5.09228 7.09443 5.25078 7.32948 5.25078 7.60956C5.25078 8.03234 4.90663 8.37649 4.48386 8.37649C4.06109 8.37649 3.71687 8.03234 3.71687 7.60956Z"
        fill={color ?? '#6B6B6B'}
      />
    </svg>
  );
});
