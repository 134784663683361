import { MedicProfile, MedicProfileUpdateInput, ResetPasscodeResponse } from '../../../models';
import { ApiRoutes, makeApiRequest, useGetApi } from '../../../services/api';
import { ErrorPage } from '../../error';
import { ProgressIndicator } from '../../../components/ProgressIndicator';
import { Page } from '../../../components/Page';
import { MedicProfileForm, MedicProfileFormValues } from './MedicProfileForm';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageTopBar } from '../../../components/PageTopBar';
import { StickyTopAppBar } from '../../../components/StickyTopAppBar';
import React from 'react';
import { Box } from '@mui/material';
import { useEntityActionSnackbar } from '../../../hooks';

export function MedicProfilePage() {
  const [{ data, isLoading, isError }, { refetch }, { lastFetchDatetime }] =
    useGetApi<MedicProfile>(ApiRoutes.Profile);
  const navigate = useNavigate();
  const { t } = useTranslation('profile');
  const { enqueueEntityUpdatedSnackbar } = useEntityActionSnackbar();

  if (isLoading) {
    return <ProgressIndicator />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  async function handleResetPasscode(): Promise<string | null> {
    const apiResponse = await makeApiRequest<ResetPasscodeResponse, null>(
      'POST',
      ApiRoutes.ResetPasscode,
    );

    if (apiResponse.error || !apiResponse.data) {
      navigate('/error');
      return null;
    }

    return apiResponse.data.passcode;
  }

  function handleSubmit(values: MedicProfileFormValues) {
    return makeApiRequest<never, MedicProfileUpdateInput>('PUT', ApiRoutes.Profile, values);
  }

  async function handleSubmitCompleted() {
    await refetch();
    navigate(-1);

    enqueueEntityUpdatedSnackbar(t('title'));
  }

  return (
    <>
      <StickyTopAppBar>
        <PageTopBar title={t('title')} lastUpdated={lastFetchDatetime} />
      </StickyTopAppBar>
      <Box mt={2}>
        <Page>
          {data && (
            <MedicProfileForm
              onSubmit={handleSubmit}
              onSubmitCompleted={handleSubmitCompleted}
              onResetPasscode={handleResetPasscode}
              initialValues={data}
            />
          )}
        </Page>
      </Box>
    </>
  );
}
