import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import { ProgressIndicator } from '../../components/ProgressIndicator';
import {
  Medic,
  ResetOnetimePasswordRequest,
  ResetOnetimePasswordResponse,
  ResetPasscodeResponse,
} from '../../models';
import { Page } from '../../components/Page';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FunctionalityProvider } from '../../context/functionality';
import { MedicsList } from './MedicsList';
import { PageTopBar } from '../../components/PageTopBar';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import React, { useEffect, useState } from 'react';
import { containsSubstringInStringArray, isSubstring } from '../../utils';
import { useEntityActionSnackbar } from '../../hooks';

export function ListMedicsPage() {
  const navigate = useNavigate();
  const { enqueueEntityDeletedSnackbar } = useEntityActionSnackbar();
  const { t } = useTranslation('medic');
  const [
    { isLoading: isMedicsLoading, isError, data: medics },
    { refetch: refetchMedics },
    { lastFetchDatetime },
  ] = useGetApi<Medic[]>(ApiRoutes.Medics);

  const [filteredMedics, setFilteredMedics] = useState(medics);

  useEffect(() => {
    setFilteredMedics(medics);
  }, [medics]);

  async function handleDelete(id: number) {
    await makeApiRequest('DELETE', ApiRoutes.Medic(id));
  }

  async function handleDeleteCompleted(deletedMedic: Medic) {
    await refetchMedics();

    enqueueEntityDeletedSnackbar(t('medic', { context: deletedMedic.sex }), {
      context: deletedMedic.sex,
    });
  }

  function handleSearch(searchedText: string) {
    if (!medics) {
      setFilteredMedics([]);
    } else {
      if (searchedText === '') {
        setFilteredMedics(medics);
      } else {
        const searchedTextToLower = searchedText.toLowerCase();
        const searchedMedics = medics.filter((medic) => {
          const { firstName, lastName, username, email, phone, qualifications } = medic;
          return (
            isSubstring(firstName, searchedTextToLower) ||
            isSubstring(lastName, searchedTextToLower) ||
            isSubstring(username, searchedTextToLower) ||
            isSubstring(email, searchedTextToLower) ||
            isSubstring(phone, searchedTextToLower) ||
            containsSubstringInStringArray(
              qualifications.map((q) => q.name),
              searchedTextToLower,
            )
          );
        });
        setFilteredMedics(searchedMedics);
      }
    }
  }

  async function handleResetOnetimePassword(medicId: number): Promise<string | null> {
    const apiResponse = await makeApiRequest<
      ResetOnetimePasswordResponse,
      ResetOnetimePasswordRequest
    >('POST', ApiRoutes.ResetOnetimePassword, { medicId });

    if (apiResponse.error || !apiResponse.data) {
      navigate('/error');
      return null;
    }

    return apiResponse.data.onetimePassword;
  }

  async function handleResetPasscode(medicId?: number): Promise<string | null> {
    if (medicId == null) {
      navigate('/error');
      return null;
    }

    const apiResponse = await makeApiRequest<ResetPasscodeResponse>(
      'POST',
      ApiRoutes.ResetMedicPasscode(medicId),
    );

    if (apiResponse.error || !apiResponse.data) {
      navigate('/error');
      return null;
    }

    return apiResponse.data.passcode;
  }

  async function onDeactivate(medicId: number): Promise<void> {
    if (medicId == null) {
      navigate('/error');
      return;
    }
    const url = ApiRoutes.UpdateActiveMedic(medicId);
    const apiResponse = await makeApiRequest('POST', url);

    if (apiResponse.error || !apiResponse.data) {
      navigate('/error');
      return;
    }
  }

  if (isMedicsLoading) {
    return <ProgressIndicator />;
  }

  return (
    <FunctionalityProvider
      createFunctionality="MEDICS_CREATE"
      readFunctionality="MEDICS_READ"
      updateFunctionality="MEDICS_UPDATE"
      deleteFunctionality="MEDICS_DELETE"
    >
      <>
        <StickyTopAppBar>
          <PageTopBar
            title={t('title.list')}
            addNewRedirectPath={'/medics/new'}
            newRecordDescriptionText={t('title.new')}
            onSearch={handleSearch}
            lastUpdated={lastFetchDatetime}
          />
        </StickyTopAppBar>
        <Page fullWidth={true} noPaper={true} requireFunctionality="MEDICS_READ">
          {!isMedicsLoading && !isError && (
            <MedicsList
              medics={filteredMedics}
              onDelete={handleDelete}
              onDeleteCompleted={handleDeleteCompleted}
              onResetOnetimePassword={handleResetOnetimePassword}
              onResetPasscode={handleResetPasscode}
              onDeactivate={onDeactivate}
            />
          )}
        </Page>
      </>
    </FunctionalityProvider>
  );
}
