import React from 'react';
import { useRequireFunctionality } from '../../hooks';
import { NoPagePermissionError } from './NoPagePermissionError';
import { RequireFunctionalityProps } from './types';

interface Props extends RequireFunctionalityProps {
  children: React.ReactNode;
}

export function RequirePageFunctionality({ children, functionality }: Props): JSX.Element {
  const { hasFunctionality } = useRequireFunctionality(functionality);

  if (!hasFunctionality) {
    return <NoPagePermissionError />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}
