import { useStyles } from './styles';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ThresholdsType, ThresholdsTypeWithDefault } from '../../models';
import React from 'react';

interface ComponentProps {
  type: ThresholdsTypeWithDefault;
  defaultType: ThresholdsType;
}

export interface StylesProps {
  top: string;
  left: string;
  bgColor: string;
}

interface InternalProps extends StylesProps {
  imagePath: string;
  label: string;
}

export function ThresholdsImage({ type, defaultType }: ComponentProps) {
  const theme = useTheme();
  const { t } = useTranslation('thresholds');
  const getThresholdsImageBySelectedType = (type: ThresholdsTypeWithDefault): InternalProps => {
    switch (type) {
      case 'maxError':
        return {
          imagePath: '/img/thresholds/ThresholdsMaxError.png',
          top: '3%',
          left: '52%',
          bgColor: theme.palette.error.main,
          label: t('maxError.label'),
        };
      case 'maxWarning':
        return {
          imagePath: '/img/thresholds/ThresholdsMaxWarning.png',
          top: '15.8%',
          left: '52%',
          bgColor: theme.palette.warning.main,
          label: t('maxWarning.label'),
        };
      case 'minWarning':
        return {
          imagePath: '/img/thresholds/ThresholdsMinWarning.png',
          top: '70.8%',
          left: '60.5%',
          bgColor: theme.palette.warning.main,
          label: t('minWarning.label'),
        };
      case 'minError':
        return {
          imagePath: '/img/thresholds/ThresholdsMinError.png',
          top: '82.5%',
          left: '60.5%',
          bgColor: theme.palette.error.main,
          label: t('minError.label'),
        };
      case 'default':
        return getThresholdsImageBySelectedType(defaultType);
    }
  };

  const internalProps = getThresholdsImageBySelectedType(type);

  const classes = useStyles({
    top: internalProps.top,
    left: internalProps.left,
    bgColor: internalProps.bgColor,
  });

  return (
    <Box className={classes.imageWrapper}>
      <img src={internalProps.imagePath} />
      <span className={classes.imageText}>{internalProps.label}</span>
    </Box>
  );
}
