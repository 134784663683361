import { Box } from '@mui/material';
import { theme } from '../../assets/style';

interface IconProps {
  width?: number;
  height?: number;
  primaryColor?: string;
  secondaryColor?: string;
}

export function PatientNoteIcon({
  width,
  height,
  primaryColor = theme.palette.primary.main,
  secondaryColor = theme.palette.primary.dark,
}: IconProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <svg
        width={`${width ?? 15}`}
        height={`${height ?? 15}`}
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip095)">
          <g clipPath="url(#clip195)">
            <path
              d="M6.85352 1.66667H7.49518C7.68768 0.995833 8.30602 0.5 9.04102 0.5C9.77602 0.5 10.3943 0.995833 10.5868 1.66667H11.2285C11.4677 1.66667 11.666 1.865 11.666 2.10417V2.97917C11.666 3.53917 11.2052 4 10.6452 4H7.43685C6.87685 4 6.41602 3.53917 6.41602 2.97917V2.10417C6.41602 1.865 6.61435 1.66667 6.85352 1.66667Z"
              fill="url(#paint0_linear95)"
            />
            <path
              d="M5.6875 2.25H5.83333V2.97917C5.83333 3.86583 6.55083 4.58333 7.4375 4.58333H10.6458C11.5325 4.58333 12.25 3.86583 12.25 2.97917V2.25H12.3958C13.2825 2.25 14 2.9675 14 3.85417V11.1458C14 12.0325 13.2825 12.75 12.3958 12.75H8.09083L7.9625 12.0325C7.90417 11.7058 7.7525 11.4142 7.51917 11.175L7.0525 10.7083H11.8125C12.0517 10.7083 12.25 10.51 12.25 10.2708C12.25 10.0317 12.0517 9.83333 11.8125 9.83333H6.27083C6.24167 9.83333 6.21833 9.83333 6.18917 9.845H6.18333L4.08333 7.745V3.85417C4.08333 2.9675 4.80083 2.25 5.6875 2.25ZM6.27083 8.8125H11.8125C12.0517 8.8125 12.25 8.61417 12.25 8.375C12.25 8.13583 12.0517 7.9375 11.8125 7.9375H6.27083C6.03167 7.9375 5.83333 8.13583 5.83333 8.375C5.83333 8.61417 6.03167 8.8125 6.27083 8.8125ZM6.27083 6.91667H11.8125C12.0517 6.91667 12.25 6.71833 12.25 6.47917C12.25 6.24 12.0517 6.04167 11.8125 6.04167H6.27083C6.03167 6.04167 5.83333 6.24 5.83333 6.47917C5.83333 6.71833 6.03167 6.91667 6.27083 6.91667Z"
              fill="url(#paint1_linear95)"
            />
            <path
              d="M6.69261 14.5001C6.80753 14.5001 6.91953 14.4546 7.00178 14.3717C7.10269 14.2708 7.14819 14.1273 7.12311 13.9862L6.81394 12.2332C6.79878 12.1452 6.75561 12.0635 6.69261 11.9999L2.36136 7.66924C1.82936 7.13608 1.30669 7.28016 1.02086 7.56599L0.299276 8.28758C-0.0991406 8.68541 -0.0991406 9.33291 0.299276 9.73133L4.63053 14.0626C4.69353 14.1262 4.77519 14.1687 4.86386 14.1839L6.61678 14.4931C6.64186 14.4977 6.66753 14.5001 6.69261 14.5001ZM4.93969 13.7534H4.93386H4.93969Z"
              fill="url(#paint2_linear95)"
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear95"
            x1="6.92139"
            y1="0.852061"
            x2="9.45576"
            y2="4.03481"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={primaryColor} />
            <stop offset="0.9996" stopColor={secondaryColor} />
          </linearGradient>
          <linearGradient
            id="paint1_linear95"
            x1="5.03793"
            y1="3.30618"
            x2="12.6289"
            y2="9.30847"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={primaryColor} />
            <stop offset="0.9996" stopColor={secondaryColor} />
          </linearGradient>
          <linearGradient
            id="paint2_linear95"
            x1="0.686755"
            y1="8.03351"
            x2="5.9374"
            y2="12.3926"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={primaryColor} />
            <stop offset="0.9996" stopColor={secondaryColor} />
          </linearGradient>
          <clipPath id="clip095">
            <rect width="15" height="14" fill="white" transform="translate(0 0.5)" />
          </clipPath>
          <clipPath id="clip195">
            <rect width="14" height="14" fill="white" transform="matrix(-1 0 0 1 14 0.5)" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
}
