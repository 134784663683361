import { Card, CardContent } from '@mui/material';
import React from 'react';
import { theme } from '../../assets/style';
import { NavTab, NavTabPanel, NavTabs } from '../../components/NavTabs';
import { ProtectedRoute } from '../../components/ProtectedRoute';
import { Navigation } from '../../types';
import { getTabsValue, isMatch } from '../../utils';
import { useStyles } from './styles';
import { ScaseDeviceIcon } from '../../components/Icons';
import { useLocation, Navigate as RouterNavigation, Route, Routes } from 'react-router-dom';
import { SystemThresholds } from './thresholds';
import { useTranslation } from 'react-i18next';
import { ROUTES, SYSTEM_THRESHOLDS_PATH } from '../../constants';

export function AdminDashboardNavigation() {
  const location = useLocation();
  const { t } = useTranslation('thresholds');
  const classes = useStyles();
  const nav: Navigation = {
    systemThresholds: {
      tab: SYSTEM_THRESHOLDS_PATH,
      route: {
        path: SYSTEM_THRESHOLDS_PATH,
      },
      subRoutes: {},
    },
  };

  return (
    <React.Fragment>
      <NavTabs
        value={getTabsValue(nav, location.pathname, false)}
        aria-label="admin dashboard tabs"
        id="admin-dashboard-tabs"
      >
        <NavTab
          label={t('thresholds')}
          to={nav.systemThresholds.route.path as string}
          icon={
            <ScaseDeviceIcon
              width={22}
              height={16}
              {...(isMatch(nav.systemThresholds.route, location.pathname, false)
                ? {}
                : {
                    primaryColor: theme.palette.grey['500'],
                    secondaryColor: theme.palette.grey['500'],
                  })}
            />
          }
          value={nav.systemThresholds.tab}
          requireFunctionality="SYSTEM_THRESHOLDS_READ"
        />
        <NavTab label={''} to={''} value={''} hide={true} />
      </NavTabs>
      <Card className={`${classes.fullWidth} ${classes.boxShadowAndBorderRadius}`}>
        <CardContent>
          <NavTabPanel>
            <Routes>
              <Route
                path="/thresholds/*"
                element={
                  <ProtectedRoute requireFunctionality="SYSTEM_THRESHOLDS_READ">
                    <SystemThresholds />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/"
                element={<RouterNavigation replace to={ROUTES.SYSTEM_THRESHOLDS.path as string} />}
              />
            </Routes>
          </NavTabPanel>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
