import { MEASUREMENT_TYPES_SORT_MAP } from '../../constants';
import {
  MeasurementTypesNavTabFunctionalities,
  MeasurementTypesNavTabsEntry,
  MeasuringDeviceType,
} from '../../models';
import { MeasurementNavTab } from './MeasurementNavTab';
import { getTabsValue, isMatch } from '../../utils';
import {
  BloodPressureIcon,
  GlucomerIcon,
  OximeterIcon,
  ThermometerIcon,
  WeightIcon,
} from '../Icons';
import { useMediaQuery, useTheme } from '@mui/material';
import { Navigation } from '../../types';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { MeasurementNavTabs } from './MeasurementNavTabs';
import HeightIcon from '@mui/icons-material/Height';
import { MovementPainIcon } from '../Icons/MovementPainIcon';
import { ProbeFluidIntakeIcon } from '../Icons/ProbeFluidIntakeIcon';
import { IntravenousFluidIntakeIcon } from '../Icons/IntravenousFluidIntakeIcon';
import { UrineOutputIcon } from '../Icons/UrineOutputIcon';

interface Props {
  nav: Navigation;
  measurementTypesFunctionalities: MeasurementTypesNavTabFunctionalities;
  ariaLabel: string;
}

export function MeasurementNavTabsWrapper({
  nav,
  measurementTypesFunctionalities,
  ariaLabel,
}: Props) {
  const theme = useTheme();
  const location = useLocation();
  const { t } = useTranslation('measurement');
  const mdDownMatches = useMediaQuery(theme.breakpoints.down('lg'));
  const lgUpMatches = useMediaQuery(theme.breakpoints.up('md'));

  function getTabText(isActive: boolean, text: string) {
    if (lgUpMatches) {
      return text;
    }
    return isActive && !mdDownMatches ? text : '';
  }

  const measurementTypesNavTabs: MeasurementTypesNavTabsEntry = {
    Glucometer: {
      label: getTabText(
        isMatch(nav.glucometer.route, location.pathname),
        t('measuringDevices.glucometer'),
      ),
      icon: (
        <GlucomerIcon
          {...(isMatch(nav.glucometer.route, location.pathname, false)
            ? { color: theme.palette.common.white }
            : { color: theme.palette.grey['600'] })}
        />
      ),
      value: nav.glucometer.tab,
      requiredFunctionality: measurementTypesFunctionalities.Glucometer,
    },
    Thermometer: {
      label: getTabText(
        isMatch(nav.thermometer.route, location.pathname, false),
        t('measuringDevices.thermometer'),
      ),
      icon: (
        <ThermometerIcon
          {...(isMatch(nav.thermometer.route, location.pathname, false)
            ? { color: theme.palette.common.white }
            : { color: theme.palette.grey['600'] })}
        />
      ),
      value: nav.thermometer.tab,
      requiredFunctionality: measurementTypesFunctionalities.Thermometer,
    },
    BloodPressureMonitor: {
      label: getTabText(
        isMatch(nav.bloodPressure.route, location.pathname, false),
        t('measuringDevices.bloodPressureMonitor'),
      ),
      icon: (
        <BloodPressureIcon
          {...(isMatch(nav.bloodPressure.route, location.pathname, false)
            ? { color: theme.palette.common.white }
            : { color: theme.palette.grey['600'] })}
        />
      ),
      value: nav.bloodPressure.tab,
      requiredFunctionality: measurementTypesFunctionalities.BloodPressureMonitor,
    },
    PulseOximeter: {
      label: getTabText(
        isMatch(nav.pulseOximeter.route, location.pathname, false),
        t('measuringDevices.pulseOximeter'),
      ),
      icon: (
        <OximeterIcon
          {...(isMatch(nav.pulseOximeter.route, location.pathname, false)
            ? { color: theme.palette.common.white }
            : { color: theme.palette.grey['600'] })}
        />
      ),
      value: nav.pulseOximeter.tab,
      requiredFunctionality: measurementTypesFunctionalities.PulseOximeter,
    },
    Weight: {
      label: getTabText(
        isMatch(nav.weight.route, location.pathname, false),
        t('measuringDevices.weight'),
      ),
      icon: (
        <WeightIcon
          {...(isMatch(nav.weight.route, location.pathname, false)
            ? { color: theme.palette.common.white }
            : { color: theme.palette.grey['600'] })}
        />
      ),
      value: nav.weight.tab,
      requiredFunctionality: measurementTypesFunctionalities.Weight,
    },
    Height: {
      label: getTabText(
        isMatch(nav.height.route, location.pathname, false),
        t('measuringDevices.height'),
      ),
      icon: <HeightIcon />,
      value: nav.height.tab,
      requiredFunctionality: measurementTypesFunctionalities.Height,
    },
    MovementPain: {
      label: getTabText(
        isMatch(nav.movementPain.route, location.pathname, false),
        t('measuringDevices.movementPain'),
      ),
      icon: (
        <MovementPainIcon
          {...(isMatch(nav.movementPain.route, location.pathname, false)
            ? { color: theme.palette.common.white }
            : { color: theme.palette.grey['600'] })}
        />
      ),
      value: nav.movementPain.tab,
      requiredFunctionality: measurementTypesFunctionalities.MovementPain,
    },
    ProbeFluidIntake: {
      label: getTabText(
        isMatch(nav.probeFluidIntake.route, location.pathname, false),
        t('measuringDevices.probeFluidIntake'),
      ),
      icon: (
        <ProbeFluidIntakeIcon
          {...(isMatch(nav.probeFluidIntake.route, location.pathname, false)
            ? { color: theme.palette.common.white }
            : { color: theme.palette.grey['600'] })}
        />
      ),
      value: nav.probeFluidIntake.tab,
      requiredFunctionality: measurementTypesFunctionalities.ProbeFluidIntake,
    },
    IntravenousFluidIntake: {
      label: getTabText(
        isMatch(nav.intravenousFluidIntake.route, location.pathname, false),
        t('measuringDevices.intravenousFluidIntake'),
      ),
      icon: (
        <IntravenousFluidIntakeIcon
          {...(isMatch(nav.intravenousFluidIntake.route, location.pathname, false)
            ? { color: theme.palette.common.white }
            : { color: theme.palette.grey['600'] })}
        />
      ),
      value: nav.intravenousFluidIntake.tab,
      requiredFunctionality: measurementTypesFunctionalities.ProbeFluidIntake,
    },
    UrineOutput: {
      label: getTabText(
        isMatch(nav.urineOutput.route, location.pathname, false),
        t('measuringDevices.urineOutput'),
      ),
      icon: (
        <UrineOutputIcon
          {...(isMatch(nav.urineOutput.route, location.pathname, false)
            ? { color: theme.palette.common.white }
            : { color: theme.palette.grey['600'] })}
        />
      ),
      value: nav.urineOutput.tab,
      requiredFunctionality: measurementTypesFunctionalities.UrineOutput,
    },
  };

  return (
    <MeasurementNavTabs value={getTabsValue(nav, location.pathname)} aria-label={ariaLabel}>
      {Object.entries(MEASUREMENT_TYPES_SORT_MAP)
        // eslint-disable-next-line
        .sort(([_, valueA], [__, valueB]) => {
          return valueA - valueB;
        })
        .map(([key, value]) => {
          const measuringDevice = key as MeasuringDeviceType;
          return (
            <MeasurementNavTab
              key={value}
              label={measurementTypesNavTabs[measuringDevice].label}
              icon={measurementTypesNavTabs[measuringDevice].icon}
              value={measurementTypesNavTabs[measuringDevice].value}
              to={measurementTypesNavTabs[measuringDevice].value}
              requireFunctionality={measurementTypesNavTabs[measuringDevice].requiredFunctionality}
            />
          );
        })}
      <MeasurementNavTab label={''} value={''} hide={true} />
    </MeasurementNavTabs>
  );
}
