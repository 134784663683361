import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  sectionTitle: {
    display: 'flex',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.4,
    letterSpacing: -0.1,
    color: '#8794aa',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      paddingBottom: theme.spacing(1),
    },
  },
}));
