import { createContext, useContext } from 'react';
import { DashboardMenuContextType } from './types';

export const DashboardMenuContext = createContext<DashboardMenuContextType>({
  isDrawerOpen: false,
  handleDrawerToggle: () => {},
  drawerWidthCurrent: 240,
  drawerWidthClosed: 80,
  handleDrawerClose: () => {},
});
export const useDashboardMenuContext = () => useContext(DashboardMenuContext);
