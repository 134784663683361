import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

interface Props<T> {
  validationSchema: Yup.SchemaOf<T>;
}

export function RevalidateOnSchemaChange<T>({ validationSchema }: Props<T>) {
  const { validateForm } = useFormikContext();
  useEffect(() => {
    validateForm();
  }, [validationSchema, validateForm]);
  return null;
}
