import * as Yup from 'yup';
import { AlarmFormValues } from '../../AlarmStepperForm';
import { useTranslation } from 'react-i18next';

export function AlarmFormPartialRepetitionSchema() {
  const { t: tAlarm } = useTranslation('alarm');
  return Yup.object({
    repetitionDays: Yup.array().of(Yup.number().required()),
    repetitionIntervalIsActive: Yup.boolean().required(),
    repetitionInterval: Yup.number().when('repetitionIntervalIsActive', {
      is: (val: boolean) => val,
      then: Yup.number()
        .required(tAlarm('eachXDays.validation.required'))
        .min(0, tAlarm('eachXDays.validation.minValue', { min: 0 })),
    }),
    startOnDatetime: Yup.date()
      .required(tAlarm('startOnDatetime.validation.required'))
      .typeError(tAlarm('startOnDatetime.validation.validDate')),
    endOnDatetime: Yup.date()
      .notRequired()
      .nullable()
      .typeError(tAlarm('endOnDatetime.validation.validDate'))
      .min(Yup.ref('startOnDatetime'), ({ min }) =>
        tAlarm('startOnDatetime.validation.minDate', {
          min: new Date(min).toLocaleDateString(),
        }),
      ),
  }).test({
    name: 'repetitionSelectedTest',
    test: function (values) {
      const alarm = values as AlarmFormValues;

      const isValid = alarm.repetitionIntervalIsActive || alarm.repetitionDays.length > 0;

      if (isValid) {
        return true;
      }

      return this.createError({
        path: 'repetitionDays',
        message: tAlarm('repetition.validation.atLeastOneDayOrOther', { min: 1 }),
      });
    },
  });
}
