import { Box, Divider } from '@mui/material';
import { DatePicker } from '../../DatePicker';
import { AlarmRepetitionIntervalsInput } from '../../AlarmRepetitionIntervalsInput';
import { ErrorMessage } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAlarmFormPartialRepetitionStyles } from './styles';
import { useAlarmFormPartialCommonStyles } from '../styles';

interface Props {
  marginLeft?: number;
}

export function AlarmFormPartialRepetition({ marginLeft }: Props) {
  const { t: tAlarm } = useTranslation('alarm');
  const classes = useAlarmFormPartialRepetitionStyles();
  const commonClasses = useAlarmFormPartialCommonStyles();
  return (
    <>
      <Box className={classes.alarmDatesWrapper} style={{ marginLeft: marginLeft }}>
        <Box display="flex" flex={1}>
          <DatePicker
            id="startOnDatetime"
            label={tAlarm('startOnDatetime.label')}
            required={true}
            setHoursAndMinutesToZeroInUtc={true}
          />
        </Box>
        <Box display="flex" flex={1}>
          <DatePicker
            id="endOnDatetime"
            label={tAlarm('endOnDatetime.label')}
            setHoursAndMinutesToZeroInUtc={true}
          />
        </Box>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.alarmTimesWrapper} style={{ marginLeft: marginLeft }}>
        <AlarmRepetitionIntervalsInput
          daysId="repetitionDays"
          repetitionIntervalIsActiveId="repetitionIntervalIsActive"
          repetitionIntervalId="repetitionInterval"
        />
      </Box>
      <ErrorMessage
        name="repetitionDays"
        className={commonClasses.customErrorMessage}
        component="div"
      />
    </>
  );
}
