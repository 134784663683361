import { Tabs } from '@mui/material';
import { useStyles } from './styles';

interface StyledTabsProps {
  value: number | string;
  onChange: (event: React.SyntheticEvent, newValue: number | string) => void;
  children: React.ReactNode;
}

export function StyledTabs({ ...props }: StyledTabsProps) {
  const { onChange, value, children } = props;
  const classes = useStyles();
  return (
    <Tabs
      value={value}
      onChange={onChange}
      children={children}
      variant="scrollable"
      scrollButtons="auto"
      TabIndicatorProps={{ children: <span /> }}
      classes={classes}
    />
  );
}
