import { Box, Typography } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

export function NavTabPanel({ children }: Props) {
  return (
    <Box>
      <Typography component={'div'}>{children}</Typography>
    </Box>
  );
}
