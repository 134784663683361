import { Typography } from '@mui/material';
import { SystemEvent } from '../../../models';
import { useSystemEventListItemActionTimestampStyles } from './styles';
import { DATE_TIME_LOCALE_WITHOUT_SECONDS } from '../../../constants';

interface Props {
  systemEvent: SystemEvent;
}

export function SystemEventListItemActionTimestamp({ systemEvent }: Props) {
  const classes = useSystemEventListItemActionTimestampStyles();
  const timestamp = systemEvent.actionTimestamp;

  return (
    <Typography color="textPrimary" variant="body1" className={classes.typography}>
      {new Date(timestamp).toLocaleString([], DATE_TIME_LOCALE_WITHOUT_SECONDS)}
    </Typography>
  );
}
