import React from 'react';
import { SaveSubmitButton } from '../SaveSubmitButton';
import { EditFormButton } from '../EditFormButton';
import { useFormikDisableContext } from '../../context/formikDisable';

interface Props {
  actionText?: string;
  disabledTooltipText?: string;
  forceSubmitting?: boolean;
  entityName?: string;
  onSubmit?: () => Promise<void>;
}

export function FormButton({
  actionText,
  disabledTooltipText,
  entityName,
  forceSubmitting = false,
  onSubmit,
}: Props) {
  const { isEditable, formMode } = useFormikDisableContext();

  switch (formMode) {
    case 'readonly':
      return <></>;
    case 'editable':
      return (
        <SaveSubmitButton
          entityName={entityName}
          actionText={actionText}
          disabledTooltipText={disabledTooltipText}
          forceSubmitting={forceSubmitting}
          onSubmit={onSubmit}
        />
      );
    case 'toggle':
      return isEditable ? (
        <SaveSubmitButton
          entityName={entityName}
          actionText={actionText}
          disabledTooltipText={disabledTooltipText}
          forceSubmitting={forceSubmitting}
          onSubmit={onSubmit}
        />
      ) : (
        <EditFormButton />
      );
  }

  return <></>;
}
