import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useExportPatientFormCommonStyles = makeStyles((theme: Theme) => ({
  customErrorMessage: {
    color: theme.palette.error.main,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      textAlign: 'center',
    },
  },
  customErrorMessageMinHeight: {
    minHeight: '1.5em',
  },
}));

export const useExportPatientFormStyles = makeStyles((theme: Theme) => ({
  formTitle: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 500,
    lineHeight: 1.33,
    letterSpacing: -0.96,
    color: theme.palette.primary.A700,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: theme.spacing(2, 0, 1),
  },
  sensorsSubtitle: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.33,
    letterSpacing: -0.96,
    color: theme.palette.primary.A700,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: theme.spacing(2, 0, 1),
  },
  flexWrapMoreItems: {
    display: 'flex',
    flexWrap: 'wrap',
    '&>*': {
      minWidth: `calc(50% - ${theme.spacing(1.4)})`,
      margin: theme.spacing(0.25, 0.7),
    },
    [theme.breakpoints.down('md')]: {
      flexWrap: 'no-wrap',
      '&>*': {
        minWidth: '100%',
        margin: theme.spacing(0.125, 0),
      },
    },
  },
  flexRowPadding: {
    display: 'flex',
    '&>*': {
      margin: theme.spacing(0, 0.6),
    },
    '&>*:first-child': {
      marginRight: theme.spacing(0.6),
      marginLeft: 0,
    },
    '&>*:last-child': {
      marginLeft: theme.spacing(0.6),
      marginRight: 0,
    },
  },
  flexRowPaddingOneColumn: {
    '&>*': {
      margin: theme.spacing(0, 0.6),
    },
    [theme.breakpoints.down('md')]: {
      '&>*': {
        margin: theme.spacing(0, 0),
      },
    },
  },
  additionalSettings: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  dateWrapper: {
    display: 'flex',
    margin: theme.spacing(0, 0.65),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, 0),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '&>*, &>*:last-child, &>*:first-child': {
        margin: theme.spacing(0.5, 0),
      },
    },
  },
}));

export const useExportPatientStyles = makeStyles(() => ({
  wrapper: {
    '& a': {
      textDecoration: 'none',
    },
  },
}));
