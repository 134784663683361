import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export interface StyleProps {
  size: number;
  color: string;
}

export const useStyles = makeStyles<Theme, StyleProps>(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: ({ size }) => size,
    height: ({ size }) => size,
    color: ({ color }) => color,
    fontSize: ({ size }) => size,
  },
}));
