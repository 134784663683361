import React, { ReactElement, ReactNode } from 'react';
import { Tooltip, Zoom } from '@mui/material';
import { useStyles } from './styles';

type Placement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

interface Props {
  title: NonNullable<ReactNode>;
  children: ReactElement;
  placement?: Placement;
}

export function CustomTooltip({ title, placement, children }: Props) {
  const classes = useStyles();
  return (
    <Tooltip
      title={title}
      placement={placement}
      classes={{ tooltip: classes.tooltip }}
      className={classes.safariTooltipFix}
      TransitionComponent={Zoom}
    >
      {children}
    </Tooltip>
  );
}
