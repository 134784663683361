import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ApiRoutes, makeApiRequest } from '../../../services/api';
import { Page } from '../../../components/Page';
import { ResetPasswordForm, ResetPasswordFormValues } from './ResetPasswordForm';
import { useTranslation } from 'react-i18next';
import { ResetPasswordRequest } from '../../../models';
import { Box, Typography } from '@mui/material';
import { useStyles } from './styles';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import React from 'react';

export function ResetPasswordPage() {
  const params = useParams();
  const token = params.token as string;
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation('resetPassword');

  function handleSubmit(values: ResetPasswordFormValues) {
    return makeApiRequest<never, ResetPasswordRequest>('POST', ApiRoutes.ResetPassword(token), {
      newPassword: values.newPassword,
    });
  }

  function handleSubmitCompleted() {
    navigate('/login', { replace: true });
  }

  if (!token) {
    <Navigate to="/login" />;
  }

  return (
    <Page noPaper={true}>
      <Box
        display="flex"
        width="100%"
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box className={classes.formWrapper}>
          <Box className={classes.textsWrapper}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box className={classes.iconWrapper}>
                <LockOpenTwoToneIcon fontSize={'inherit'} />
              </Box>
            </Box>
            <Typography align="center" variant="h5" className={classes.title}>
              {t('title.resetPassword')}
            </Typography>
            <Typography align="center" variant="body2" className={classes.subtitle}>
              {t('subtitle')}
            </Typography>
          </Box>
          <ResetPasswordForm onSubmit={handleSubmit} onSubmitCompleted={handleSubmitCompleted} />
        </Box>
      </Box>
    </Page>
  );
}
