import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from '../ProtectedRoute';
import { EDIT_SUBPATH } from '../../constants';
import { DailyPlanTemplatePage } from '../../pages/dailyPlanTemplates';
import { AlarmActiveShowHideFormProvider } from '../../context/alarmActiveShowHideForm';

export function DailyPlanRoutes() {
  return (
    <Routes>
      <Route
        path={`${EDIT_SUBPATH}`}
        element={
          <ProtectedRoute requireFunctionality="DAILY_PLAN_TEMPLATES_READ">
            <AlarmActiveShowHideFormProvider>
              <DailyPlanTemplatePage />
            </AlarmActiveShowHideFormProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/*"
        element={
          <ProtectedRoute requireFunctionality="DAILY_PLAN_TEMPLATES_READ">
            <AlarmActiveShowHideFormProvider>
              <DailyPlanTemplatePage />
            </AlarmActiveShowHideFormProvider>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
