import { Card, CardContent, useMediaQuery, useTheme } from '@mui/material';
import { PatientForm, PatientFormValues } from './PatientForm';
import { useStyles } from './styles';
import React from 'react';
import { PatientNotes } from './notes/PatientNotes';
import { PatientMeasurements } from './measurements';
import { AlarmSubPage, ListAlarmsSubPage } from './alarms';
import {
  Department,
  IdentifierType,
  MeasuringDeviceWithType,
  Patient,
  PatientUpdateResult,
} from '../../models';
import { PatientQuestionnaireResponsePage, PatientQuestionnaires } from './questionnaires';
import { NavTab, NavTabPanel, NavTabs } from '../../components/NavTabs';
import { useTranslation } from 'react-i18next';
import { ProtectedRoute } from '../../components/ProtectedRoute';
import { NoteSubPage } from '../notes/NoteSubPage';
import { useLocation } from 'react-router';
import { SubmitFormCompletedHandler, SubmitFormHandler } from '../../components/Form';
import {
  PatientAlarmIcon,
  PatientCardIcon,
  PatientNoteIcon,
  PatientQuestionnaireIcon,
  ScaseDeviceIcon,
} from '../../components/Icons';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { Navigation } from '../../types';
import {
  getHeightUnitFromMeasuringDevices,
  getWeightUnitFromMeasuringDevices,
  isMatch,
  isNullOrUndefined,
} from '../../utils';
import { GridContextProvider } from '../../context';
import * as NavigationConstants from '../../constants/NavigationConstants';
import { EDIT_SUBPATH, ROUTES, THRESHOLD_ROUTES } from '../../constants';
import { FunctionalityProvider } from '../../context/functionality';
import { SelectedMeasurementContextProvider } from '../../context/selectedMeasurement';
import { AlarmActiveShowHideFormProvider } from '../../context/alarmActiveShowHideForm';
import { ExportPatient } from './export';
import { IconWrapper } from '../../components/IconWrapper';
import { ListPairingsSubPage } from './pairings';
import { PairingSubPage } from '../pairings';
import {
  MEASUREMENTS_READ_POLICY,
  PATIENT_ALARMS_CREATE_POLICY,
  PATIENT_ALARMS_DELETE_POLICY,
  PATIENT_ALARMS_READ_POLICY,
  PATIENT_ALARMS_UPDATE_POLICY,
  PATIENT_NOTES_CREATE_POLICY,
  PATIENT_NOTES_DELETE_POLICY,
  PATIENT_NOTES_READ_POLICY,
  PATIENT_NOTES_UPDATE_POLICY,
  PATIENT_QUESTIONNAIRES_DELETE_POLICY,
  PATIENT_QUESTIONNAIRES_READ_POLICY,
  PATIENTS_DELETE_POLICY,
  PATIENTS_READ_POLICY,
  PATIENTS_UPDATE_POLICY,
} from '../../constants/Policies';
import { Route, Routes } from 'react-router-dom';

interface Props {
  id: string;
  patient: Patient;
  patientFetchDatetime: Date | null;
  onSubmit: SubmitFormHandler<PatientFormValues, PatientUpdateResult>;
  onSubmitCompleted: SubmitFormCompletedHandler<PatientUpdateResult>;
  identifierTypes: IdentifierType[];
  departments: Department[];
  measuringDevices: MeasuringDeviceWithType[];
}

export function PatientNavigation({
  id,
  patient,
  patientFetchDatetime,
  onSubmit,
  onSubmitCompleted,
  identifierTypes,
  measuringDevices,
  departments,
}: Props) {
  const { t } = useTranslation([
    'patient',
    'measurement',
    'note',
    'alarm',
    'alarmGroup',
    'questionnaire',
    'medic',
    'patientDevicePairing',
    'common',
  ]);
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const tabletMatches = useMediaQuery(theme.breakpoints.down('md'));

  const patientPath = `/patients/${id}`;
  const nav: Navigation = {
    patient: {
      tab: patientPath,
      route: {
        path: ROUTES.PATIENT_DETAIL.path,
      },
      subRoutes: {},
    },
    measurements: {
      tab: `${patientPath}/measurements/${NavigationConstants.BLOOD_PRESSURE_MONITOR_PATH}?${NavigationConstants.DEFAULT_MEASUREMENT_SORT_PATH_PARAM}`,
      route: {
        path: ROUTES.PATIENT_MEASUREMENTS.path,
      },
      subRoutes: {
        pulseOximeter: {
          path: ROUTES.PATIENT_MEASUREMENTS_PULSE_OXIMETER.path,
        },
        pulseOximeterThresholds: {
          path: THRESHOLD_ROUTES.PATIENT_MEASUREMENTS_PULSE_OXIMETER_THRESHOLDS.path,
        },
        thermometer: {
          path: ROUTES.PATIENT_MEASUREMENTS_THERMOMETER.path,
        },
        thermometerThresholds: {
          path: THRESHOLD_ROUTES.PATIENT_MEASUREMENTS_THERMOMETER_THRESHOLDS.path,
        },
        bloodPressure: {
          path: ROUTES.PATIENT_MEASUREMENTS_BLOOD_PRESSURE.path,
        },
        bloodPressureThresholds: {
          path: THRESHOLD_ROUTES.PATIENT_MEASUREMENTS_BLOOD_PRESSURE_THRESHOLDS.path,
        },
        glucometer: {
          path: ROUTES.PATIENT_MEASUREMENTS_GLUCOMETER.path,
        },
        glucometerThresholds: {
          path: THRESHOLD_ROUTES.PATIENT_MEASUREMENTS_GLUCOMETER_THRESHOLDS.path,
        },
        weight: {
          path: ROUTES.PATIENT_MEASUREMENTS_WEIGHT.path,
        },
        weightThresholds: {
          path: THRESHOLD_ROUTES.PATIENT_MEASUREMENTS_WEIGHT_THRESHOLDS.path,
        },
        height: {
          path: ROUTES.PATIENT_MEASUREMENTS_HEIGHT.path,
        },
        heightThresholds: {
          path: THRESHOLD_ROUTES.PATIENT_MEASUREMENTS_HEIGHT_THRESHOLDS.path,
        },
      },
    },
    export: {
      tab: `${patientPath}/export`,
      route: {
        path: ROUTES.PATIENT_EXPORT.path,
      },
      subRoutes: {},
    },
    notes: {
      tab: `${patientPath}/notes?${NavigationConstants.DEFAULT_NOTE_SORT_PATH_PARAM}`,
      route: {
        path: ROUTES.NOTES.path,
      },
      subRoutes: {
        edit: {
          path: ROUTES.NOTE_DETAIL.path,
        },
      },
    },
    alarms: {
      tab: `${patientPath}/alarms`,
      route: {
        path: ROUTES.PATIENT_ALARMS.path,
      },
      subRoutes: {
        edit: {
          path: ROUTES.PATIENT_ALARM_DETAIL.path,
        },
      },
    },
    questionnaires: {
      tab: `${patientPath}/questionnaires?sort=status:desc`,
      route: {
        path: ROUTES.PATIENT_QUESTIONNAIRES.path,
      },
      subRoutes: {
        response: {
          path: ROUTES.PATIENT_QUESTIONNAIRE_RESPONSE.path,
        },
      },
    },
    pairings: {
      tab: `${patientPath}/pairings`, //?${NavigationConstants.DEFAULT_PAIRING_SORT_PATH_PARAM}
      route: {
        path: ROUTES.PATIENT_PAIRINGS.path,
      },
      subRoutes: {
        detail: {
          path: ROUTES.PATIENT_PAIRING_DETAIL.path,
        },
      },
    },
  };

  function getTabsValue(nav: Navigation, pathname: string): string {
    if (pathname.includes('measurements')) {
      return nav.measurements.tab;
    } else if (pathname.includes('notes')) {
      return nav.notes.tab;
    } else if (pathname.includes('alarms')) {
      return nav.alarms.tab;
    } else if (pathname.includes('questionnaires')) {
      return nav.questionnaires.tab;
    } else if (pathname.includes('pairings')) {
      return nav.pairings.tab;
    } else if (pathname.includes('export')) {
      return nav.export.tab;
    } else {
      return nav.patient.tab; // Default tab
    }
  }

  function isSelectedTab(pathname: string): boolean {
    const regex = /^\/patients\/\d+(\/edit)?$/;
    if (regex.test(pathname)) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <React.Fragment>
      <NavTabs
        value={getTabsValue(nav, location.pathname)}
        aria-label="patient tabs"
        id="patient-nav-tabs"
      >
        <NavTab
          label={!tabletMatches ? t('patient:card', { context: patient.sex }) : ''}
          to={nav.patient.tab}
          icon={
            <PatientCardIcon
              {...(isSelectedTab(location.pathname)
                ? {}
                : {
                    primaryColor: theme.palette.error['500'],
                    secondaryColor: theme.palette.grey['500'],
                  })}
            />
          }
          value={nav.patient.tab}
          requireFunctionality={PATIENTS_READ_POLICY}
        />
        <NavTab
          label={!tabletMatches ? t('measurement:title.list') : ''}
          to={nav.measurements.tab}
          icon={
            <ScaseDeviceIcon
              width={22}
              height={16}
              {...(isMatch(nav.measurements.route, location.pathname, false)
                ? {}
                : {
                    primaryColor: theme.palette.grey['500'],
                    secondaryColor: theme.palette.grey['500'],
                  })}
            />
          }
          value={nav.measurements.tab}
          requireFunctionality={MEASUREMENTS_READ_POLICY}
        />
        <NavTab
          label={!tabletMatches ? t('note:title.list') : ''}
          to={nav.notes.tab}
          icon={
            <PatientNoteIcon
              {...(isMatch(nav.notes.route, location.pathname, false)
                ? {}
                : {
                    primaryColor: theme.palette.grey['500'],
                    secondaryColor: theme.palette.grey['500'],
                  })}
            />
          }
          value={nav.notes.tab}
          requireFunctionality={PATIENT_NOTES_READ_POLICY}
        />
        <NavTab
          label={!tabletMatches ? t('alarmGroup:title.list') : ''}
          to={nav.alarms.tab}
          icon={
            <PatientAlarmIcon
              {...(isMatch(nav.alarms.route, location.pathname, false)
                ? {}
                : {
                    primaryColor: theme.palette.grey['500'],
                    secondaryColor: theme.palette.grey['500'],
                  })}
            />
          }
          value={nav.alarms.tab}
          requireFunctionality={PATIENT_ALARMS_READ_POLICY}
        />
        <NavTab
          label={!tabletMatches ? t('questionnaire:title.list') : ''}
          to={nav.questionnaires.tab}
          icon={
            <PatientQuestionnaireIcon
              {...(isMatch(nav.questionnaires.route, location.pathname, false)
                ? {}
                : {
                    primaryColor: theme.palette.grey['500'],
                    secondaryColor: theme.palette.grey['500'],
                  })}
            />
          }
          value={nav.questionnaires.tab}
          requireFunctionality={PATIENT_QUESTIONNAIRES_READ_POLICY}
        />
        <NavTab
          label={!tabletMatches ? t('patientDevicePairing:title') : ''}
          to={nav.pairings.tab}
          icon={
            <IconWrapper
              size={14}
              {...(isMatch(nav.pairings.route, location.pathname, false)
                ? { color: theme.palette.primary.main }
                : {})}
            >
              <PhonelinkIcon fontSize="small" />
            </IconWrapper>
          }
          value={nav.pairings.tab}
          requireFunctionality={'PATIENT_DEVICE_PAIRINGS_READ'}
        />
        <NavTab
          label={!tabletMatches ? t('common:export') : ''}
          to={nav.export.tab}
          icon={
            <IconWrapper
              size={14}
              {...(isMatch(nav.export.route, location.pathname, false)
                ? { color: theme.palette.primary.main }
                : {})}
            >
              <SystemUpdateAltIcon fontSize="small" />
            </IconWrapper>
          }
          value={nav.export.tab}
          requireFunctionality={'PATIENT_EXPORT'}
        />
      </NavTabs>
      <Card className={`${classes.fullWidth} ${classes.boxShadowAndBorderRadius}`}>
        <CardContent>
          <NavTabPanel>
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedRoute requireFunctionality={PATIENTS_READ_POLICY}>
                    <FunctionalityProvider
                      createFunctionality="PATIENTS_CREATE"
                      readFunctionality={PATIENTS_READ_POLICY}
                      updateFunctionality={PATIENTS_UPDATE_POLICY}
                      deleteFunctionality={PATIENTS_DELETE_POLICY}
                    >
                      <PatientForm<PatientUpdateResult>
                        initialValues={{
                          ...patient,
                          identifierTypeId: patient.identifierTypeId ?? undefined,
                          civilStatus: patient.civilStatus ?? undefined,
                          departmentIds: patient.departments.map((d) => d.id),
                          weight: !isNullOrUndefined(patient.weight) ? patient.weight : null,
                          height: !isNullOrUndefined(patient.height) ? patient.height : null,
                          sex: patient.sex ?? undefined,
                          roomId: patient.room?.id,
                        }}
                        onSubmit={onSubmit}
                        onSubmitCompleted={onSubmitCompleted}
                        identifierTypes={identifierTypes}
                        departments={departments}
                        weightUnit={getWeightUnitFromMeasuringDevices(measuringDevices)}
                        heightUnit={getHeightUnitFromMeasuringDevices(measuringDevices)}
                      />
                    </FunctionalityProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/measurements/*"
                element={
                  <ProtectedRoute requireFunctionality={MEASUREMENTS_READ_POLICY}>
                    <SelectedMeasurementContextProvider>
                      <PatientMeasurements patientId={id} />
                    </SelectedMeasurementContextProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path={`/export`}
                element={
                  <ProtectedRoute requireFunctionality={['PATIENT_EXPORT']}>
                    <FunctionalityProvider readFunctionality={'PATIENT_EXPORT'}>
                      <ExportPatient
                        patient={patient}
                        patientFetchDatetime={patientFetchDatetime}
                      />
                    </FunctionalityProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path={`/pairings/*`}
                element={
                  <ProtectedRoute requireFunctionality={['PATIENT_DEVICE_PAIRINGS_READ']}>
                    <FunctionalityProvider
                      readFunctionality={'PATIENT_DEVICE_PAIRINGS_READ'}
                      updateFunctionality={'PATIENT_DEVICE_PAIRINGS_UPDDATE'}
                    >
                      <GridContextProvider>
                        <ListPairingsSubPage />
                      </GridContextProvider>
                    </FunctionalityProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path={`/pairings/:pairingId`}
                element={
                  <ProtectedRoute requireFunctionality={['PATIENT_DEVICE_PAIRINGS_READ']}>
                    <FunctionalityProvider readFunctionality={'PATIENT_DEVICE_PAIRINGS_READ'}>
                      <PairingSubPage />
                    </FunctionalityProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/notes/*"
                element={
                  <ProtectedRoute requireFunctionality={PATIENT_NOTES_READ_POLICY}>
                    <FunctionalityProvider
                      createFunctionality={PATIENT_NOTES_CREATE_POLICY}
                      readFunctionality={PATIENT_NOTES_READ_POLICY}
                      updateFunctionality={PATIENT_NOTES_UPDATE_POLICY}
                      deleteFunctionality={PATIENT_NOTES_DELETE_POLICY}
                    >
                      <GridContextProvider>
                        <PatientNotes patientId={id} />
                      </GridContextProvider>
                    </FunctionalityProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path={`/notes/:noteId/${EDIT_SUBPATH}`}
                element={
                  <ProtectedRoute requireFunctionality={PATIENT_NOTES_READ_POLICY}>
                    <FunctionalityProvider
                      createFunctionality={PATIENT_NOTES_CREATE_POLICY}
                      readFunctionality={PATIENT_NOTES_READ_POLICY}
                      updateFunctionality={PATIENT_NOTES_UPDATE_POLICY}
                      deleteFunctionality={PATIENT_NOTES_DELETE_POLICY}
                    >
                      <NoteSubPage />
                    </FunctionalityProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path={`/alarms`}
                element={
                  <ProtectedRoute requireFunctionality={PATIENT_ALARMS_READ_POLICY}>
                    <FunctionalityProvider
                      createFunctionality={PATIENT_ALARMS_CREATE_POLICY}
                      readFunctionality={PATIENT_ALARMS_READ_POLICY}
                      updateFunctionality={PATIENT_ALARMS_UPDATE_POLICY}
                      deleteFunctionality={PATIENT_ALARMS_DELETE_POLICY}
                    >
                      <GridContextProvider>
                        <AlarmActiveShowHideFormProvider>
                          <ListAlarmsSubPage patientId={id} />
                        </AlarmActiveShowHideFormProvider>
                      </GridContextProvider>
                    </FunctionalityProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path={`/alarms/:alarmId/*`}
                element={
                  <ProtectedRoute requireFunctionality={PATIENT_ALARMS_READ_POLICY}>
                    <FunctionalityProvider
                      createFunctionality={PATIENT_ALARMS_CREATE_POLICY}
                      readFunctionality={PATIENT_ALARMS_READ_POLICY}
                      updateFunctionality={PATIENT_ALARMS_UPDATE_POLICY}
                      deleteFunctionality={PATIENT_ALARMS_DELETE_POLICY}
                    >
                      <AlarmSubPage />
                    </FunctionalityProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path={`/questionnaires`}
                element={
                  <ProtectedRoute requireFunctionality={PATIENT_QUESTIONNAIRES_READ_POLICY}>
                    <GridContextProvider>
                      <FunctionalityProvider
                        readFunctionality={PATIENT_QUESTIONNAIRES_READ_POLICY}
                        deleteFunctionality={PATIENT_QUESTIONNAIRES_DELETE_POLICY}
                      >
                        <PatientQuestionnaires patientId={id} />
                      </FunctionalityProvider>
                    </GridContextProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path={`/questionnaires/:questionnaireId`}
                element={
                  <ProtectedRoute requireFunctionality={PATIENT_QUESTIONNAIRES_READ_POLICY}>
                    <PatientQuestionnaireResponsePage />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </NavTabPanel>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
