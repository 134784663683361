import { useTranslation } from 'react-i18next';
import { Optional } from '../../models';
import { DAYS } from '../../constants';
import { isNullOrUndefined } from '../../utils';

interface Props {
  repetitionInterval?: Optional<number>;
  repetitionIntervalIsActive?: boolean;
  repetitionDays: Optional<number[]>;
}

export function AlarmRepetition(props: Props) {
  const { t } = useTranslation('common');

  function getRepetitionStringValue(
    repetitionDays: Optional<number[]>,
    repetitionIntervalIsActive: boolean,
    repetitionInterval?: Optional<number>,
  ) {
    if (repetitionDays && repetitionDays.length > 0 && DAYS) {
      if (
        repetitionDays.length == DAYS.length &&
        DAYS.every((v) => repetitionDays.includes(v.id))
      ) {
        return t('eachDay', { count: 1 });
      }
      const selectedDays = repetitionDays.sort();
      const selectedDaysLabels = selectedDays.map((x) => {
        const day = DAYS.find((d) => d.id == x);
        if (!day) {
          return undefined;
        }
        return t(`days.${day.name}` as const);
      });

      return selectedDaysLabels.join(', ');
    } else if (repetitionIntervalIsActive && !isNullOrUndefined(repetitionInterval)) {
      if (repetitionInterval == 0) {
        return t('onlyOnce');
      }

      return t('eachDay', { count: repetitionInterval ?? 0 });
    }

    return t('notSelected');
  }

  return (
    <>
      {getRepetitionStringValue(
        props.repetitionDays,
        props.repetitionIntervalIsActive ?? props.repetitionInterval != null,
        props.repetitionInterval,
      )}
    </>
  );
}
