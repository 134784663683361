import { AlarmTypeEnum, AlarmTypeName, Day, MeasuringDeviceType } from '../models';

export const ASIDE_MENU_CLOSED_WIDTH = 64;
export const ASIDE_MENU_OPEN_WIDTH = 240;

export const STICKY_TOPBAR_CLOSED_HEIGHT = 64;
export const STICKY_TOPBAR_FULL_HEIGHT = 120;

export const ASIDE_MENU_FOOTER_HEIGHT = 65;
export const BOTTOM_NAV_HEIGHT = 80;
export const BOTTOM_NAV_TOP_INDENT = 20;

export const QUESTIONNAIRE_TITLE_MAX_LENGTH = 115;
export const QUESTION_TEXT_MAX_LENGTH = 250;
export const QUESTION_ANSWER_OPTIONS_MIN_COUNT = 2;
export const QUESTION_ANSWER_OPTIONS_MAX_COUNT = 20;

export const GRID_DEFAULT_PAGE_SIZE = 10;

export const GRID_DEFAULT_PAGE_SIZE_PAGINATION_DISABLED = 100;

export const THRESHOLD_DOT_STROKE_WIDTH = 2;
export const SELECTED_DOT_STROKE_WIDTH = 4;

export const URL_PAGE = 'page';
export const URL_SORT = 'sort';
export const URL_SELECTED_MEASUREMENT = 'selectedMeasurement';
export const URL_SEARCH = 'search';
export const URL_KEY_VALUE_SEPARATOR = ':';

export const EDIT_SUBPATH = 'edit';
export const NEW_SUBPATH = 'new';

export const THRESHOLDS_SUBPATH = 'thresholds';

export const CONSENT_COOKIE_NAME = 'scaseConsentCookie';

export const GRID_FIELD_EDIT = 'edit';
export const GRID_FIELD_DELETE = 'delete';

export const DATE_TIME_LOCALE_WITHOUT_SECONDS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

export const DATE_LOCALE: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

export const GRID_FIELDS_STOP_PROPAGATION = [GRID_FIELD_EDIT, GRID_FIELD_DELETE];

export const PATIENT_MIN_WEIGHT_LIMIT = 0;
export const PATIENT_MAX_WEIGHT_LIMIT = 500;

export const PATIENT_MIN_HEIGHT_LIMIT = 0;
export const PATIENT_MAX_HEIGHT_LIMIT = 300;

export const SKELETON_ANIMATION = 'wave';

export const MEASUREMENT_TYPES_SORT_MAP: {
  [key in MeasuringDeviceType]: number;
} = {
  BloodPressureMonitor: 1,
  Thermometer: 2,
  PulseOximeter: 3,
  Glucometer: 4,
  Weight: 5,
  Height: 6,
  MovementPain: 7,
  ProbeFluidIntake: 8,
  IntravenousFluidIntake: 9,
  UrineOutput: 10,
};

export const ALARM_TYPE_NAME_SORT_ORDER: AlarmTypeName[] = [
  AlarmTypeEnum.GLUCOMETER,
  AlarmTypeEnum.PULSE_OXIMETER,
  AlarmTypeEnum.BLOOD_PRESSURE_MONITOR,
  AlarmTypeEnum.THERMOMETER,
  AlarmTypeEnum.WEIGHT,
  AlarmTypeEnum.REMINDER,
];

export const BLOOD_PRESSURE_MONITOR_SORT_MAP = { systolic: 1, diastolic: 2, pulse: 3 };
export const THERMOMETER_SORT_MAP = { temperature: 1, type: 2 };
export const PULSE_OXYMETER_SORT_MAP = { pulse: 1, oxygenSaturation: 2 };
export const GLUCOMETER_SORT_MAP = { glucose: 1, foodType: 2 };

export const GLUCOMETER_FOOD_TYPE_CODING_SORT_MAP = { BEFORE_FOOD: 1, AFTER_FOOD: 2 };

export const DAYS: Day[] = [
  {
    id: 0,
    name: 'monday',
  },
  {
    id: 1,
    name: 'tuesday',
  },
  {
    id: 2,
    name: 'wednesday',
  },
  {
    id: 3,
    name: 'thursday',
  },
  {
    id: 4,
    name: 'friday',
  },
  {
    id: 5,
    name: 'saturday',
  },
  {
    id: 6,
    name: 'sunday',
  },
];

export interface PagePath {
  primary: string;
  secondary?: string;
}

export const PATIENTS_PATH: PagePath = {
  primary: '/patients',
  secondary: '/patient',
};

export const MEDICS_PATH: PagePath = {
  primary: '/medics',
  secondary: '/medic',
};

export const QUESTIONNAIRES_PATH: PagePath = {
  primary: '/questionnaires',
  secondary: '/questionnaire',
};

export const DAILY_PLAN_TEMPLATES_PATH: PagePath = {
  primary: '/daily-plan-templates',
  secondary: '/daily-plan-template',
};

export const ORGANIZATIONS_PATH: PagePath = {
  primary: '/organizations',
  secondary: '/organizations',
};

export const GROUPS_PATH: PagePath = {
  primary: '/groups',
  secondary: '/groups',
};

export const DEPARTMENTS_PATH: PagePath = {
  primary: '/departments',
  secondary: '/departments',
};

export const DEVICES_PATH: PagePath = {
  primary: '/devices',
  secondary: '/devices',
};

export const CHANGE_LOG_PATH: PagePath = {
  primary: '/changelogs',
};

export const DASHBOARD_PATH: PagePath = {
  primary: '/',
};

export const EVENTS_CHANGE_LOG_PAGE_SIZE = 20;

export const SELECT_ORGANIZATION_KEY = 'organization';

export const ACCESS_TOKEN = 'access_token';
