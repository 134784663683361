import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  menuItemRoot: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.29,
    letterSpacing: theme.typography.pxToRem(0.4),
    margin: theme.spacing(0, 1.25),
    padding: theme.spacing(1, 1.25),
    borderRadius: theme.spacing(0.5),
    '&:hover, &:focus': {
      color: '#003491',
      backgroundColor: 'rgba(0, 86, 241, 0.06)',
    },
  },
  dialogButton: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 1.71,
    letterSpacing: -0.14,
  },
  archiveButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  input: {
    minWidth: 550,
    [theme.breakpoints.down('sm')]: {
      minWidth: 165,
    },
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
}));
