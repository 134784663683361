import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export function PasswordValuesSchema() {
  const { t } = useTranslation('installation');
  return Yup.object({
    newPassword: Yup.string()
      .required(t('newPassword.validation.required'))
      .min(8, ({ min }) => t('newPassword.validation.minLength', { length: min }))
      .max(255, ({ max }) => t('newPassword.validation.maxLength', { length: max }))
      .test('Contains lowercase', t('newPassword.validation.lowercase'), hasLowerCase)
      .test('Contains uppercase', t('newPassword.validation.uppercase'), hasUpperCase),
    confirmPassword: Yup.string()
      .required(t('confirmPassword.validation.required'))
      .oneOf([Yup.ref('newPassword')], t('confirmPassword.validation.matchingPasswords')),
  });
}

function hasLowerCase(str?: string): boolean {
  if (!str) {
    return false;
  }

  return str.toUpperCase() !== str;
}

function hasUpperCase(str?: string) {
  if (!str) {
    return false;
  }

  return str.toLowerCase() !== str;
}
