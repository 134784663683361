import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PatientGridModel } from '../../models';
import { CalendarIconWithBackground } from '../Icons';
import { useStyles } from './styles';

interface Props {
  patient: PatientGridModel;
}

export function PatientDevicePairingExpectedEndDateGridColumn({ patient }: Props) {
  const { t } = useTranslation('patient');
  const classes = useStyles();

  // undefined means there is no pairing
  if (typeof patient.expectedEndDate === 'undefined') {
    return (
      <Button className={classes.endDateButtonNotPaired}>
        <Box px={2} py={0.5}>
          {t('scaseEndDate.notPaired', { context: patient.sex })}
        </Box>
      </Button>
    );
  }

  // null means there is pairing but without limit
  if (!patient.expectedEndDate) {
    return (
      <Button
        startIcon={
          <CalendarIconWithBackground id={patient.id} primaryColor="black" secondaryColor="black" />
        }
        className={classes.endDateButton}
      >
        {t('scaseEndDate.noLimit')}
      </Button>
    );
  }

  const endDate = new Date(String(patient.expectedEndDate));
  const endDateLocaleDateString = endDate.toLocaleDateString();
  const now = new Date();

  return (
    <Button
      startIcon={
        endDate < now ? (
          <CalendarIconWithBackground
            id={patient.id}
            primaryColor="white"
            secondaryColor="white"
            primaryBgColor="#F41414"
            secondaryBgColor="#AF0808"
          />
        ) : (
          <CalendarIconWithBackground id={patient.id} />
        )
      }
      className={classes.endDateButton}
    >
      {endDateLocaleDateString}
    </Button>
  );
}
