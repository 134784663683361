import { ReactNode } from 'react';
import { Device, Patient } from '../../../models';
import { UnpairPatientButton, UnpairPatientButtonProps } from './UnpairPatientButton';
import { PairPatientButton } from './PairPatientButton';
import { RequireFunctionality } from '../../../components/RequireFunctionality';

interface Props extends UnpairPatientButtonProps {
  patient: Patient;
  devices: Device[];
  onPairDone: () => Promise<void>;
}

export function PatientPairingButton({ patient, devices, onPairDone, ...unpairProps }: Props) {
  if (patient.devicePairing) {
    return (
      <RequirePairingFunctionalityWrapper>
        <UnpairPatientButton {...unpairProps} patient={patient} />
      </RequirePairingFunctionalityWrapper>
    );
  }

  return (
    <RequirePairingFunctionalityWrapper>
      <PairPatientButton patient={patient} devices={devices} onPairDone={onPairDone} />
    </RequirePairingFunctionalityWrapper>
  );
}

function RequirePairingFunctionalityWrapper({ children }: { children: ReactNode }) {
  return <RequireFunctionality functionality="DEVICE_PAIRING">{children}</RequireFunctionality>;
}
