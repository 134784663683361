import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(12),
    fontFamily: 'Inter',
    backgroundColor: '#363b44',
    fontWeight: 300,
    lineHeight: 1.5,
  },
  safariTooltipFix: {
    '&:after': {
      /*
       * Safari shows own tooltip by default, when text displays an ellipsis.
       * This style blocks it, so there are not dual tooltips on Safari.
       */
      content: '""',
      display: 'block',
    },
    /*
     * We can not be sure that ellipsed text is direct children of component - so apply this fix
     * to all its children to avoid potential dual tooltips on Safari.
     */
    '& *': {
      '&:after': {
        content: '""',
        display: 'block',
      },
    },
  },
}));
