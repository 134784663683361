import { ReactNode, useEffect, useState } from 'react';
import { FormikDisableContext } from '.';
import { useLocation } from 'react-router-dom';
import { FormMode } from '../../types';
import { EDIT_SUBPATH, NEW_SUBPATH } from '../../constants';

interface Props {
  children: ReactNode;
  formMode?: FormMode;
}

export function FormikDisableProvider({ children, formMode = 'editable' }: Props) {
  const location = useLocation();
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    const isEditRoute = location.pathname.endsWith(`/${EDIT_SUBPATH}`);
    const isNewRoute = location.pathname.endsWith(`/${NEW_SUBPATH}`);

    switch (formMode) {
      case 'readonly':
        setIsEditable(false);
        break;
      case 'editable':
        setIsEditable(true);
        break;
      case 'toggle':
        setIsEditable(isEditRoute || isNewRoute);
        break;
    }
  }, [formMode, location.pathname]);

  return (
    <FormikDisableContext.Provider
      value={{
        isDisabled: !isEditable,
        isEditable: isEditable,
        formMode,
      }}
    >
      {children}
    </FormikDisableContext.Provider>
  );
}
