import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(15),
  },
  moreOptions: {
    color: theme.palette.common.black,
    fontWeight: 600,
    padding: theme.spacing(2, 4),
  },
  cancel: {
    fontSize: theme.typography.pxToRem(15),
    fontFamily: 'Inter',
    fontWeight: 'normal',
    color: theme.palette.primary.A700,
    padding: theme.spacing(2, 4),
    borderRadius: theme.typography.pxToRem(10),
    '&:hover, &:focus': {
      color: theme.palette.primary.main,
    },
  },
}));
