import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  searchBar: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0,
    },
  },
}));
