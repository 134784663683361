import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  content: {
    maxWidth: '900px',
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  dividerMargin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  bold: {
    fontWeight: 'bold',
  },
  pageTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    '@supports (column-gap: 8px)': {
      columnGap: theme.spacing(1),
    },
    '@supports not (column-gap: 8px)': {
      '&>*': {
        margin: theme.spacing(0, 0.6),
      },
      '&>*:first-child': {
        marginRight: theme.spacing(0.6),
        marginLeft: 0,
      },
      '&>*:last-child': {
        marginLeft: theme.spacing(0.6),
        marginRight: 0,
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  fullWidth: {
    width: '100%',
  },
  boxShadowAndBorderRadius: {
    marginTop: theme.typography.pxToRem(-3),
    boxShadow: `0px ${theme.typography.pxToRem(1)} ${theme.typography.pxToRem(
      5,
    )} rgba(0, 0, 0, 0.15)`,
    borderRadius: `0px 0px ${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(10)}`,
  },
  notificationCheckboxesWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: theme.typography.pxToRem(20),
    columnGap: theme.typography.pxToRem(40),
    rowGap: theme.typography.pxToRem(10),
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  notificationGroupWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  notificationGroupHeader: {
    fontWeight: 'bold',
  },
}));
