import { LineColors } from './MeasurementChart';
import { Box, useTheme } from '@mui/material';
import { useMeasurementTooltipStyles } from './styles';
import { ChartKey, ChartMeasurement } from './types';
import { useTranslation } from 'react-i18next';
import { Measurands } from '../../../../../models';
import { getColorByThresholds } from '../../../../../utils';
import { TooltipProps } from 'recharts';
import { useSelectedMeasurementContext } from '../../../../../context/selectedMeasurement';

interface Props {
  lineColors: LineColors;
  measurementUnits: Measurands;
}

export function MeasurementTooltip({
  active,
  payload,
  lineColors,
  measurementUnits,
}: TooltipProps<number | string, ChartKey> & Props) {
  const classes = useMeasurementTooltipStyles();
  const { t } = useTranslation('measurement');
  const theme = useTheme();
  const { selectedMeasurement } = useSelectedMeasurementContext();
  if (payload && payload[0]) {
    const data = payload[0].payload as ChartMeasurement;
    if (active || data.id.value == selectedMeasurement) {
      const measuredOnDatetime = data.measuredOnDatetime.value;

      return (
        <Box className={classes.container}>
          <Box className={classes.dateAndTimeContainer}>
            <Box className={classes.dateContainer}>
              {new Date(measuredOnDatetime).toLocaleString([], {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              })}
            </Box>
            <Box className={classes.timeContainer}>
              {new Date(measuredOnDatetime).toLocaleString([], {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Box>
          </Box>
          {Object.entries(data)
            .filter(([key]) => key != 'id' && key != 'measuredOnDatetime')
            .map(([key, value]) => {
              const chartKey = key as ChartKey;
              let textColor = null;
              if (value && value.value != null) {
                textColor = getColorByThresholds(value)?.dark;
              }
              return (
                <Box
                  key={key}
                  className={classes.measurementTypeContainer}
                  style={{ color: lineColors[chartKey] }}
                >
                  <Box className={classes.measurementTypeKey}>
                    {t(`${key}` as ChartKey)}
                    <Box
                      className={classes.measurementUnit}
                      style={{
                        color: textColor != null ? textColor : theme.palette.primary.dark,
                      }}
                    >
                      {measurementUnits[chartKey]?.unit
                        ? ` (${measurementUnits[chartKey]?.unit})`
                        : ''}
                    </Box>
                  </Box>
                  <Box
                    className={classes.measurementTypeValue}
                    style={{
                      color: textColor != null ? textColor : 'inherit',
                      fontWeight: textColor != null ? 700 : 'inherit',
                    }}
                  >
                    {value?.displayValue ?? (value?.value as string)}
                  </Box>
                </Box>
              );
            })}
        </Box>
      );
    }
  }

  return null;
}
