import { QuestionnaireResponseItem } from '../../../../../models';
import { FONT_SIZES, FONT_WEIGHTS } from '../../../../Constants';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

interface Props {
  question: QuestionnaireResponseItem;
}

const styles = StyleSheet.create({
  wrapper: {
    fontSize: FONT_SIZES.h6,
    fontWeight: FONT_WEIGHTS.semibold,
    display: 'flex',
    flexDirection: 'row',
  },
  text: { marginHorizontal: 2 },
});

export function QuestionnaireQuestion({ question }: Props) {
  const questionIndex = question.questionnaireItemOrdinalNumber + 1;
  return (
    <View style={styles.wrapper}>
      <Text>{questionIndex}.</Text>
      <Text style={styles.text}>{question.questionnaireItemText}</Text>
    </View>
  );
}
