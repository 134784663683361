import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import { MeasuringDeviceType, WebMeasurement } from '../../../../../models';
import { MeasurementTableRows } from '../MeasurementTableRows';
import { FONT_SIZES } from '../../../../Constants';
import {
  sortWebMeasurementsByMeasuringDevice,
  translateAndFixWebMeasurement,
} from '../../../../../utils';
import { useTranslation } from 'react-i18next';
import { MeasurementTableHeader } from '../MeasurementTableHeader';
import { NoDataText } from '../../common/NoDataText';

const styles = StyleSheet.create({
  tableContainer: {
    fontFamily: 'SegoeUI',
    flexDirection: 'column',
    width: '100%',
    fontSize: FONT_SIZES.h6,
  },
  rows: {
    display: 'flex',
    flexDirection: 'column',
  },
});

interface Props {
  measurements: WebMeasurement[];
  measuringDevice: MeasuringDeviceType;
}

export function MeasurementsTable({ measurements, measuringDevice }: Props) {
  const { t: tMeasurement } = useTranslation('measurement');

  if (measurements && measurements.length === 0) {
    return <NoDataText />;
  }

  return (
    <View style={styles.tableContainer}>
      <MeasurementTableHeader
        measurements={translateAndFixWebMeasurement(
          sortWebMeasurementsByMeasuringDevice(measurements, measuringDevice),
          tMeasurement,
        )}
      />
      <View style={styles.rows}>
        <MeasurementTableRows
          measurements={translateAndFixWebMeasurement(
            sortWebMeasurementsByMeasuringDevice(measurements, measuringDevice),
            tMeasurement,
          )}
        />
      </View>
    </View>
  );
}
