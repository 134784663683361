import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
  ASIDE_MENU_CLOSED_WIDTH,
  STICKY_TOPBAR_CLOSED_HEIGHT,
  STICKY_TOPBAR_FULL_HEIGHT,
} from '../../constants';

export interface StyleProps {
  scrollTrigger: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  navbar: {
    transition: theme.transitions.create(['minHeight', 'height', 'padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
  },
  navbarFullHeight: {
    height: STICKY_TOPBAR_FULL_HEIGHT,
    [theme.breakpoints.down('lg')]: {
      minHeight: STICKY_TOPBAR_FULL_HEIGHT,
      height: 'auto',
      [theme.breakpoints.down('lg')]: {
        minHeight: 'unset',
      },
    },
  },
  navbarClosedHeight: {
    height: STICKY_TOPBAR_CLOSED_HEIGHT,
  },
  appbarRoot: {
    transition: theme.transitions.create(['minHeight', 'height', 'padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    padding: ({ scrollTrigger }) => (!scrollTrigger ? theme.spacing(4) : theme.spacing(0, 4)),
    backgroundColor: theme.palette.grey.A200,
    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 3px 5px',
    [theme.breakpoints.down('xl')]: {
      paddingLeft: `${ASIDE_MENU_CLOSED_WIDTH}px !important`,
    },
    [theme.breakpoints.down('lg')]: {
      padding: `${theme.spacing(2)} !important`,
    },
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(3, 1, 1)} !important`,
      marginBottom: theme.spacing(2),
    },
  },
}));
