import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const usePatientPairingStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const usePatientDevicePairingDetailedStyles = makeStyles((theme: Theme) => ({
  title: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
    color: '#828282',
  },
  valueNotPairedOrNotSet: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
  },
  externalIdValue: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: '22px',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
  },
  expectedEndDateValue: {
    fontWeight: 900,
    fontSize: '20px',
    lineHeight: '24.63px',
    color: theme.palette.primary.dark,
    whiteSpace: 'nowrap',
  },
  purposeValue: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
    color: theme.palette.text.primary,
    wordWrap: 'break-word',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: theme.spacing(1, 0, 3, 0),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      rowGap: theme.spacing(0.5),
    },
  },
}));

export const useUnpairButtonStyles = makeStyles((theme: Theme) => ({
  unpairButton: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 1.71,
    letterSpacing: -0.14,
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1, 6),
      borderRadius: theme.spacing(1),
    },
  },
}));

export const usePairButtonStyles = makeStyles((theme: Theme) => ({
  pairButton: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 1.71,
    letterSpacing: -0.14,
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1, 6),
      borderRadius: theme.spacing(1),
    },
  },
}));

export const useUnpairDialogStyles = makeStyles((theme: Theme) => ({
  cancelButton: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 1.71,
    letterSpacing: -0.14,
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1, 6),
      borderRadius: theme.spacing(1),
    },
  },
  input: {
    minWidth: 550,
    [theme.breakpoints.down('sm')]: {
      minWidth: 165,
    },
  },
}));
