import { MeasuringDeviceType } from '../../models';
import { BloodPressureIcon, GlucomerIcon, OximeterIcon, ThermometerIcon, WeightIcon } from '.';
import React from 'react';
import HeightIcon from '@mui/icons-material/Height';
import { Box } from '@mui/material';

interface Props {
  measuringDevice: MeasuringDeviceType;
  width?: number;
  height?: number;
  color?: string;
}

export const MeasurementIcon = React.memo(function MeasurementIconComponent({
  measuringDevice,
  ...props
}: Props) {
  if (measuringDevice === 'Weight') {
    return <WeightIcon {...props} />;
  }
  if (measuringDevice === 'Height') {
    const { width, height, color } = props;
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        color={color}
        width={width}
        height={height}
      >
        <HeightIcon color="inherit" />
      </Box>
    );
  }
  if (measuringDevice === 'Glucometer') {
    return <GlucomerIcon {...props} />;
  }
  if (measuringDevice === 'Thermometer') {
    return <ThermometerIcon {...props} />;
  }
  if (measuringDevice === 'PulseOximeter') {
    return <OximeterIcon {...props} />;
  }
  if (measuringDevice === 'BloodPressureMonitor') {
    return <BloodPressureIcon {...props} />;
  }

  return null;
});
