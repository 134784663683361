import { useFormikContext } from 'formik';
import { AddButton, AddButtonProps } from './AddButton';
import { useFormikDisableContext } from '../../context/formikDisable';
import { AddCircleButton } from './AddCircleButton';

export function FormAddButton({ isIconOnlyButton = false, ...props }: AddButtonProps) {
  const { isSubmitting } = useFormikContext();
  const { isDisabled } = useFormikDisableContext();

  if (isIconOnlyButton) {
    return <AddCircleButton {...props} disabled={props.disabled || isSubmitting || isDisabled} />;
  }

  return <AddButton {...props} disabled={props.disabled || isSubmitting || isDisabled} />;
}
