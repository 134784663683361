import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '../../components/Page';
import {
  QuestionnairePreviewHeader,
  QuestionnairePreviewQuestionsList,
  QuestionnaireScoringCategoriesList,
} from '../../components/Questionnaire';
import { FunctionalityProvider } from '../../context/functionality';
import { Questionnaire } from '../../models';
import { useQuestionnairePreviewPageStyles } from './styles';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import { PageTopBar } from '../../components/PageTopBar';
import { EditFormButton } from '../../components/EditFormButton';

interface Props {
  questionnaire: Questionnaire | null;
  lastFetchDatetime?: Date | null;
  showOnlyMainContent?: boolean;
  datetime?: Date;
  showEditButton?: boolean;
}

export function QuestionnairePreviewPage({
  questionnaire,
  lastFetchDatetime,
  showOnlyMainContent = false,
  datetime,
  showEditButton = false,
}: Props) {
  const { t } = useTranslation('questionnaire');
  const classes = useQuestionnairePreviewPageStyles();

  useEffect(() => {
    questionnaire?.items.sort((a, b) => (a.index > b.index ? 1 : -1));
  }, [questionnaire]);

  if (questionnaire == null) {
    return <div>{t('error.questionnaireNotFound')}</div>;
  }

  const mainContent = (
    <>
      <Box display="flex" width="100%" marginBottom={3}>
        <QuestionnairePreviewHeader questionnaire={questionnaire} datetime={datetime} />
      </Box>
      <Box className={classes.scoringCategoriesPreviewAndPatients}>
        <Box display="flex" flex={3} flexDirection="column" marginRight={2}>
          {questionnaire.scoringCategories && questionnaire.scoringCategories.length > 0 && (
            <Box marginBottom={2} mx={2}>
              <QuestionnaireScoringCategoriesList
                scoringCategories={questionnaire.scoringCategories}
              />
            </Box>
          )}
          <Box marginBottom={2} mx={2}>
            <QuestionnairePreviewQuestionsList questions={questionnaire.items} />
          </Box>
        </Box>
      </Box>
      {showEditButton && (
        <Box display="flex" width="100%" justifyContent="flex-end" p={1}>
          <EditFormButton disabled={questionnaire.isAssigned} />
        </Box>
      )}
    </>
  );

  if (showOnlyMainContent) {
    return mainContent;
  }

  return (
    <FunctionalityProvider
      createFunctionality="QUESTIONNAIRE_TEMPLATES_CREATE"
      readFunctionality="QUESTIONNAIRE_TEMPLATES_READ"
      updateFunctionality="QUESTIONNAIRE_TEMPLATES_UPDATE"
      deleteFunctionality="QUESTIONNAIRE_TEMPLATES_DELETE"
    >
      <>
        <StickyTopAppBar>
          <PageTopBar title={`${t('title.questionnaire')}`} lastUpdated={lastFetchDatetime} />
        </StickyTopAppBar>

        <Box mt={2}>
          <Page>{mainContent}</Page>
        </Box>
      </>
    </FunctionalityProvider>
  );
}
