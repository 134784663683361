import { ILogger, LogLevel } from '@microsoft/signalr';
import * as Sentry from '@sentry/react';
import { CurrentUser } from '../../../models';

export type LoggerCurrentUserProvider = () => CurrentUser | undefined;

export class HubLogger implements ILogger {
  private readonly minimumLogLevel: LogLevel;
  private readonly currentUserProvider: LoggerCurrentUserProvider;

  constructor(minimumLogLevel: LogLevel, currentUserProvider: LoggerCurrentUserProvider) {
    this.minimumLogLevel = minimumLogLevel;
    this.currentUserProvider = currentUserProvider;
  }

  public log(logLevel: LogLevel, message: string): void {
    this.sentrylog(logLevel, message);
    this.consoleLog(logLevel, message);
  }

  private sentrylog(logLevel: LogLevel, message: string): void {
    if (
      logLevel == LogLevel.Error &&
      message?.startsWith("Failed to start the transport 'WebSockets'")
    ) {
      const user = this.currentUserProvider();
      Sentry.captureEvent({
        level: 'warning',
        message,
        user: {
          id: user?.id?.toString(),
          username: user?.username,
        },
      });
    }
  }

  private consoleLog(logLevel: LogLevel, message: string): void {
    if (logLevel >= this.minimumLogLevel) {
      switch (logLevel) {
        case LogLevel.Critical:
        case LogLevel.Error:
          console.error(`[${new Date().toISOString()}] ${LogLevel[logLevel]}: ${message}`);
          break;
        case LogLevel.Warning:
          console.warn(`[${new Date().toISOString()}] ${LogLevel[logLevel]}: ${message}`);
          break;
        case LogLevel.Information:
          console.info(`[${new Date().toISOString()}] ${LogLevel[logLevel]}: ${message}`);
          break;
        default:
          // console.debug only goes to attached debuggers in Node, so we use console.log for Trace and Debug
          console.log(`[${new Date().toISOString()}] ${LogLevel[logLevel]}: ${message}`);
          break;
      }
    }
  }
}
