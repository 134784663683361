import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { ProgressIndicator } from '../../../components/ProgressIndicator';
import { useCurrentUser } from '../../../context';
import { ApiRoutes, makeApiRequest } from '../../../services/api';
import { sendAmplitudeEvent } from '../../../utils/amplitude';

export function LogoutPage() {
  const { reset, isAuthenticated, isLoading } = useCurrentUser();

  useEffect(() => {
    async function logoutUser() {
      sendAmplitudeEvent('User log out');
      await makeApiRequest('POST', ApiRoutes.LogoutUser);
      await reset();
    }

    logoutUser();
  }, []);

  if (isLoading || isAuthenticated) {
    return <ProgressIndicator />;
  }

  return <Navigate to="/login" />;
}
