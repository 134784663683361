import { CircularProgress } from '@mui/material';
import { useProgressIndicatorStyles } from './styles';

type ProgressIndicatorSize = 'large' | 'medium' | 'small';

export interface ProgressIndicatorStyleProps {
  height?: number;
}

interface Props extends ProgressIndicatorStyleProps {
  size?: ProgressIndicatorSize;
}

export function ProgressIndicator({ size = 'medium', ...styleProps }: Props) {
  const classes = useProgressIndicatorStyles(styleProps);

  return (
    <div className={classes.container}>
      <CircularProgress color="secondary" size={sizes[size]} />
    </div>
  );
}

const sizes: Record<ProgressIndicatorSize, string> = {
  large: '6rem',
  medium: '4rem',
  small: '2rem',
};
