import { Patient } from '../../../models';
import { ApiRoutes, makeApiRequest, useGetApi } from '../../../services/api';
import { PatientsList } from '../../patients/PatientsList';
import { isSubstring } from '../../../utils';
import React, { useEffect, useState } from 'react';
import { PageTopBarSearch, PageTopBarSearchRef } from '../../../components/PageTopBarSearch';
import { Box, useTheme } from '@mui/material';
import { useStyles } from './styles';
import { Skeleton } from '@mui/material';
import { ROW_HEIGHT } from '../../../components/Grid/constants';
import { SKELETON_ANIMATION } from '../../../constants';
import { useGridContext } from '../../../context';
import { GridSkeleton } from '../../../components/GridSkeleton';

interface Props {
  medicId: string;
}

export function MedicPatients({ medicId }: Props) {
  const [
    { data: medicPatients, isLoading: areMedicPatientsLoading },
    { refetch: refetchMedicPatients },
  ] = useGetApi<Patient[]>(ApiRoutes.MedicPatients(medicId));
  const [{ data: patients, isLoading: arePatientsLoading }, { refetch: refetchPatients }] =
    useGetApi<Patient[]>(ApiRoutes.Patients);

  const classes = useStyles();
  const { goToFirstPage } = useGridContext();
  const theme = useTheme();

  const pageTopBarSearchRef = React.useRef<PageTopBarSearchRef>(null);

  const [filteredPatients, setFilteredPatients] = useState(patients);

  useEffect(() => {
    setFilteredPatients(patients);
  }, [patients]);

  async function onSaveSelection(selectedPatientIds: number[]) {
    await makeApiRequest<never, number[]>(
      'POST',
      ApiRoutes.MedicPatients(medicId),
      selectedPatientIds,
    );

    pageTopBarSearchRef?.current?.handleRemoveSearchText();
    goToFirstPage();

    await refetchMedicPatients();
    await refetchPatients();
  }

  function handleSearch(searchedText: string) {
    if (!patients) {
      setFilteredPatients([]);
    } else {
      if (searchedText === '') {
        setFilteredPatients(patients);
      } else {
        const searchedTextToLower = searchedText.toLowerCase();
        const searchedPatients = patients.filter((patient) => {
          const { lastName, username, identificationNumber } = patient;
          return (
            isSubstring(lastName, searchedTextToLower) ||
            isSubstring(username, searchedTextToLower) ||
            isSubstring(identificationNumber, searchedTextToLower)
          );
        });
        setFilteredPatients(searchedPatients);
      }
    }
  }

  const isLoading = areMedicPatientsLoading || arePatientsLoading;

  return (
    <>
      <Box className={classes.searchBar}>
        {isLoading ? (
          <Box display="flex" flexDirection="row">
            <Skeleton
              variant="rectangular"
              animation={SKELETON_ANIMATION}
              width="100%"
              height={ROW_HEIGHT}
              style={{
                marginRight: theme.spacing(1),
              }}
            />
            <Skeleton
              variant="circular"
              animation={SKELETON_ANIMATION}
              width={ROW_HEIGHT - 2 * parseInt(theme.spacing(1))}
              height={ROW_HEIGHT - 2 * parseInt(theme.spacing(1))}
              style={{
                margin: 'auto',
              }}
            />
          </Box>
        ) : (
          <PageTopBarSearch onSearch={handleSearch} ref={pageTopBarSearchRef} />
        )}
      </Box>
      {isLoading ? (
        <Box mx={1}>
          <GridSkeleton />
        </Box>
      ) : (
        <PatientsList
          patients={filteredPatients}
          selectedPatients={medicPatients ?? []}
          isSelectionGrid={true}
          disableEdit={true}
          onSaveSelection={onSaveSelection}
          showColumns={['lastName', 'username', 'identificationNumber']}
          columnsWidth={{
            lastName: { flex: 0.33, minWidth: 200 },
            username: { flex: 0.33, minWidth: 150 },
            identificationNumber: { flex: 0.33, minWidth: 150 },
          }}
        />
      )}
    </>
  );
}
