import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.typography.pxToRem(3),
    },
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
}));

export const useMeasurementStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    width: '100%',
    justifyContent: 'center',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  flexContainer: {
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(1),
  },
}));

export const useMeasurementTypesStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: '#eff1f3',
    padding: theme.typography.pxToRem(6),
    borderRadius: theme.typography.pxToRem(4),
    width: 'max-content',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  flexContainer: {
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(1),
  },
}));
