import { SelectInput, SelectInputProps, SelectOption } from './SelectInput';
import { SexEnum } from '../../models';
import { useTranslation } from 'react-i18next';

type Props = Omit<SelectInputProps, 'options'>;

export function SexSelectInput(props: Props) {
  const { t } = useTranslation('common');

  const sexSelectOptions: SelectOption[] = Object.entries(SexEnum).map((x) => ({
    value: x[1],
    label: t(`sex.${x[1]}` as const),
  }));

  return <SelectInput {...props} options={sexSelectOptions} />;
}
