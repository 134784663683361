import { Box } from '@mui/material';
import { InstallationMemberInput } from '../../../../components/InstallationMemberInput';

export function MemberForm() {
  return (
    <>
      <Box>
        <InstallationMemberInput id="installationMembers" />
      </Box>
    </>
  );
}
