import { PatientDevicePairing as PatientDevicePairingModel } from '../../../../../models';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { isNullOrEmpty } from '../../../../../utils';
import { DATE_LOCALE } from '../../../../../constants';
import { FONT_SIZES, FONT_WEIGHTS } from '../../../../Constants';

interface Props {
  patientDevicePairing: PatientDevicePairingModel;
}

const styles = StyleSheet.create({
  wrapper: {
    fontSize: FONT_SIZES.h6,
  },
  duration: {
    fontSize: FONT_SIZES.h5,
  },
  subHeader: {
    fontSize: FONT_SIZES.h5,
    marginBottom: 3,
    flexDirection: 'row',
  },
  semibold: {
    fontWeight: FONT_WEIGHTS.semibold,
  },
  light: {
    fontWeight: FONT_WEIGHTS.light,
  },
  paddingLeft: {
    paddingLeft: 15,
  },
});

export function PatientDevicePairing({ patientDevicePairing }: Props) {
  const { t } = useTranslation('patientDevicePairing');
  const isOngoing = patientDevicePairing.endDate == null;

  function getDurationText() {
    const startDate = patientDevicePairing.startDate
      ? new Date(patientDevicePairing.startDate).toLocaleString([], DATE_LOCALE)
      : '';

    const endDate =
      !isOngoing && patientDevicePairing.endDate
        ? new Date(patientDevicePairing.endDate).toLocaleString([], DATE_LOCALE)
        : t('export.ongoing');

    return `${startDate} - ${endDate}`;
  }

  return (
    <View style={styles.wrapper}>
      <Text style={styles.duration}>{getDurationText()}</Text>
      <View style={{ marginVertical: 2 }}>
        <View style={styles.subHeader}>
          <Text style={styles.semibold}>{t('grid.purpose')}</Text>
          <Text style={[styles.light, { marginLeft: 5 }]}>
            <Text>{t('export.author')}: </Text>
            <Text>
              {!isNullOrEmpty(patientDevicePairing.pairingUserName)
                ? patientDevicePairing.pairingUserName
                : '--'}
            </Text>
          </Text>
        </View>
        <Text style={[styles.paddingLeft]}>
          {!isNullOrEmpty(patientDevicePairing.purpose) ? patientDevicePairing.purpose : '--'}
        </Text>
      </View>
      {!isOngoing && (
        <View style={{ marginVertical: 2 }}>
          <View style={styles.subHeader}>
            <Text style={styles.semibold}>{t('grid.conclusion')}</Text>
            <Text style={[styles.light, { marginLeft: 5 }]}>
              <Text>{t('export.author')}: </Text>
              <Text>
                {!isNullOrEmpty(patientDevicePairing.unpairingUserName)
                  ? patientDevicePairing.unpairingUserName
                  : '--'}
              </Text>
            </Text>
          </View>
          <Text style={[styles.paddingLeft]}>
            {!isNullOrEmpty(patientDevicePairing.conclusion)
              ? patientDevicePairing.conclusion
              : '--'}
          </Text>
        </View>
      )}
    </View>
  );
}
