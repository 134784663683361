import * as Yup from 'yup';
import { TextInput } from '../../components/TextInput';
import { SubmitFormCompletedHandler, SubmitFormHandler } from '../../components/Form';
import {
  AnswerOptionCreateInput,
  QuestionnaireQuestionCreateInput,
  QuestionnaireScoringCategory,
  QuestionType,
} from '../../models';
import { Box, Hidden } from '@mui/material';
import {
  QuestionnaireScoringCategoriesInput,
  QuestionsInput,
  SelectedQuestionInput,
} from '../../components/Questionnaire';
import {
  QUESTION_ANSWER_OPTIONS_MAX_COUNT,
  QUESTION_ANSWER_OPTIONS_MIN_COUNT,
  QUESTION_TEXT_MAX_LENGTH,
  QUESTIONNAIRE_TITLE_MAX_LENGTH,
} from '../../constants';
import { QuestionnaireNewIcon } from '../../components/Icons';
import { useTranslation } from 'react-i18next';
import {
  SelectedQuestionIndexContext,
  SelectedQuestionIndexProvider,
} from '../../context/selectedQuestionIndex';
import { useQuestionnaireFormStyles } from './styles';
import { EntityForm } from '../../components/EntityForm';
import { FormButton } from '../../components/FormButton';

export interface QuestionnaireFormValues {
  title: string;
  description: string;
  items: QuestionnaireQuestionCreateInput[];
  scoringCategories: QuestionnaireScoringCategory[];
}

interface Props<TResponseData = never> {
  initialValues?: QuestionnaireFormValues;
  onSubmit: SubmitFormHandler<QuestionnaireFormValues, TResponseData>;
  onSubmitCompleted: SubmitFormCompletedHandler<TResponseData>;
  questionTypes: QuestionType[];
}

export function QuestionnaireForm<TResponseData = never>({
  initialValues,
  onSubmit,
  onSubmitCompleted,
  questionTypes,
}: Props<TResponseData>) {
  const classes = useQuestionnaireFormStyles();
  const { t } = useTranslation('questionnaire');
  const defaultFormValues: QuestionnaireFormValues = {
    title: '',
    description: '',
    items: [
      {
        questionTypeId: questionTypes[0]?.id,
        text: '',
        index: 0,
        questionnaireAnswerOptions: [],
        initialValue: '',
      },
    ],
    scoringCategories: [],
  };

  const numberTypeIds = questionTypes.filter((q) => q.isInteger || q.isDouble).map((q) => q.id);
  const selectionTypeIds = questionTypes.filter((q) => q.isSelection).map((q) => q.id);
  const selectionAndMultiselectionTypeIds = questionTypes
    .filter((q) => q.isSelection || q.isMultiselection)
    .map((q) => q.id);

  function isNumberQuestionType(val: number) {
    return numberTypeIds.includes(val);
  }

  function isSelectionQuestionType(val: number) {
    return selectionTypeIds.includes(val);
  }

  function isSelectionOrMultiSelectionQuestionType(val: number) {
    return selectionAndMultiselectionTypeIds.includes(val);
  }

  const schema: Yup.SchemaOf<QuestionnaireFormValues> = Yup.object({
    title: Yup.string()
      .required(t('titleField.validation.required'))
      .max(QUESTIONNAIRE_TITLE_MAX_LENGTH, ({ max }) =>
        t('titleField.validation.maxLength', { length: max }),
      ),
    description: Yup.string().required(t('description.validation.required')),
    items: Yup.array().of(
      Yup.object({
        index: Yup.number().required(t('question.index.validation.required')),
        text: Yup.string()
          .required(t('question.text.validation.required'))
          .max(QUESTION_TEXT_MAX_LENGTH, ({ max }) =>
            t('question.text.validation.maxLength', { length: max }),
          ),
        questionTypeId: Yup.number()
          .required(t('question.type.validation.required'))
          .oneOf(
            questionTypes.map((option) => option.id),
            t('question.type.validation.oneOf'),
          ),
        initialValue: Yup.mixed().when('questionTypeId', {
          is: (val: number) => isNumberQuestionType(val),
          then: Yup.number().typeError(t('question.initialValue.validation.number')).nullable(),
          otherwise: Yup.string(),
        }),
        questionnaireAnswerOptions: Yup.array()
          .when('questionTypeId', {
            is: (val: number) => isSelectionOrMultiSelectionQuestionType(val),
            then: Yup.array()
              .required(t('question.answerOptionArray.validation.required'))
              .min(QUESTION_ANSWER_OPTIONS_MIN_COUNT, ({ min }) =>
                t('question.answerOptionArray.validation.minLength', { length: min }),
              )
              .max(QUESTION_ANSWER_OPTIONS_MAX_COUNT, ({ max }) =>
                t('question.answerOptionArray.validation.maxLength', { length: max }),
              ),
          })
          .when('questionTypeId', {
            is: (val: number) => isSelectionQuestionType(val),
            then: Yup.array().test(
              'selectionTypeOnlyOneInitialValueTest',
              t('question.answerOptionArray.validation.selectionTypeOnlyOneInitialValue'),
              (answerOptions) => {
                const questionAnswerOptions = answerOptions as AnswerOptionCreateInput[];

                if (questionAnswerOptions.filter((qa) => qa.initialValue).length > 1) {
                  return false;
                }

                return true;
              },
            ),
          })
          .of(
            Yup.object({
              value: Yup.string().required(t('question.answerOption.validation.required')),
              initialValue: Yup.boolean().required(),
              score: Yup.number().notRequired().nullable(),
            }),
          ),
      }),
    ),
    scoringCategories: Yup.array().of(
      Yup.object({
        name: Yup.string().required(t('scoringCategory.name.validation.required')),
        scoreFrom: Yup.number().required(t('scoringCategory.scoreFrom.validation.required')),
        scoreTo: Yup.number()
          .typeError(t('scoringCategory.scoreTo.validation.number'))
          .required(t('scoringCategory.scoreTo.validation.required'))
          .test('more than Score from + 1', function (value, context) {
            const atLeastValue = (context.parent.scoreFrom || 0) + 1;
            if (value == null || value < atLeastValue) {
              return context.createError({
                path: context.path,
                message: t('scoringCategory.scoreTo.validation.moreThanScoreFrom', {
                  value: atLeastValue,
                }),
              });
            }

            return true;
          }),
      }),
    ),
  });

  return (
    <EntityForm<QuestionnaireFormValues, TResponseData>
      initialValues={initialValues || defaultFormValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      onSubmitCompleted={onSubmitCompleted}
      noResetFormOnSubmitCompleted={true}
    >
      <Box display="flex">
        <Hidden mdDown={true}>
          <Box mt={2} mr={2}>
            <QuestionnaireNewIcon />
          </Box>
        </Hidden>
        <Box width="100%">
          <TextInput
            id="title"
            label={t('titleField.label')}
            required={true}
            debounceInput={true}
          />
          <TextInput
            id="description"
            label={t('description.label')}
            required={true}
            multiline={true}
            rows={3}
            rowsMax={8}
            debounceInput={true}
          />
        </Box>
      </Box>
      <QuestionnaireScoringCategoriesInput id="scoringCategories" />
      <Box className={classes.questionsAndSelectedQuestionContainer}>
        <SelectedQuestionIndexProvider>
          <Box className={classes.questionsInput}>
            <QuestionsInput id="items" questionTypes={questionTypes} />
          </Box>
          <SelectedQuestionIndexContext.Consumer>
            {(value) =>
              value.selectedIndex >= 0 && (
                <Box
                  boxShadow={2}
                  borderRadius="10px"
                  className={`${classes.selectedQuestionSticky} ${classes.selectedQuestionStickySafari} ${classes.selectedQuestionContainer}`}
                >
                  <SelectedQuestionInput id="items" questionTypes={questionTypes} />
                </Box>
              )
            }
          </SelectedQuestionIndexContext.Consumer>
        </SelectedQuestionIndexProvider>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <FormButton entityName={t('questionnaire')} />
      </Box>
    </EntityForm>
  );
}
