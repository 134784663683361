import { ReactNode, useEffect, useState } from 'react';
import { DashboardMenuContext } from '.';
import { useMediaQuery, useTheme } from '@mui/material';
import { ASIDE_MENU_CLOSED_WIDTH, ASIDE_MENU_OPEN_WIDTH } from '../../constants';

interface Props {
  children: ReactNode;
}

export function DashboardMenuProvider({ children }: Props) {
  const drawerWidthOpen = ASIDE_MENU_OPEN_WIDTH;
  const drawerWidthClosed = ASIDE_MENU_CLOSED_WIDTH;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.only('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [isDrawerOpen, setIsDrawerOpen] = useState(!isTablet);
  const [drawerWidthCurrent, setCurrentDrawerWidth] = useState(ASIDE_MENU_OPEN_WIDTH);

  useEffect(() => {
    setCurrentDrawerWidth(isDrawerOpen ? drawerWidthOpen : drawerWidthClosed);
  }, [isDrawerOpen]);

  useEffect(() => {
    if (isDesktop) {
      setIsDrawerOpen(true);
    }
  }, [isDesktop]);

  useEffect(() => {
    if (isMobile || isTablet) {
      setIsDrawerOpen(false);
    }
  }, [isMobile, isTablet]);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <DashboardMenuContext.Provider
      value={{
        drawerWidthCurrent: drawerWidthCurrent,
        drawerWidthClosed: drawerWidthClosed,
        isDrawerOpen: isDrawerOpen,
        handleDrawerToggle: handleDrawerToggle,
        handleDrawerClose: handleDrawerClose,
      }}
    >
      {children}
    </DashboardMenuContext.Provider>
  );
}
