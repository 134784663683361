import * as Yup from 'yup';
import { Box, Divider, Hidden } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SubmitFormCompletedHandler, SubmitFormHandler } from '../../../../../components/Form';
import {
  MeasurandType,
  MeasurandTypes,
  MeasurandWithThresholds,
  MeasurementThresholds,
  Optional,
} from '../../../../../models';
import { FormButton } from '../../../../../components/FormButton';
import { useTranslation } from 'react-i18next';
import { EntityForm } from '../../../../../components/EntityForm';
import { useThresholdsFormStyles } from './styles';
import { MeasurementTypeNavTab, MeasurementTypeNavTabs } from '../../../../../components/NavTabs';
import { Navigation } from '../../../../../types';
import {
  camelize,
  getKeyByTypeSubtypeAndSubtypeCoding,
  getTabsValue,
  kebabCaseToUpperSnakeCase,
  kebabize,
} from '../../../../../utils';
import { useParams, useLocation } from 'react-router-dom';
import { ThresholdInput } from '../ThresholdInput';
import { ThresholdsHelperText } from '../ThresholdsHelperText';
import { ThresholdsErrorMessages } from '../ThresholdsErrorMessages';
import { useThresholdsContext } from '../../../../../context/thresholds';
import { ThresholdsInfoMessage } from '../ThresholdsInfoMessage';
import { CancelButton } from '../../../../../components/CancelButton';
import { ThresholdsGuidelineInfo } from '../ThresholdsGuidelineInfo';
import { ThresholdsImage } from '../../../../../components/ThresholdsImage';
import { PATIENT_THRESHOLDS_READ_POLICY } from '../../../../../constants/Policies';

export interface ThresholdsNullable {
  isMinErrorCurrentlyActiveOnForm: boolean;
  minError?: number | null;
  isMinWarningCurrentlyActiveOnForm: boolean;
  minWarning?: number | null;
  isMaxWarningCurrentlyActiveOnForm: boolean;
  maxWarning?: number | null;
  isMaxErrorCurrentlyActiveOnForm: boolean;
  maxError?: number | null;
  isMinErrorDisabled?: boolean;
  isMinWarningDisabled?: boolean;
  isMaxWarningDisabled?: boolean;
  isMaxErrorDisabled?: boolean;
  isMinErrorChanged?: boolean;
  isMinWarningChanged?: boolean;
  isMaxWarningChanged?: boolean;
  isMaxErrorChanged?: boolean;
}

export type MeasurandThresholds = {
  [key in keyof MeasurandTypes]?: ThresholdsNullable;
};

export interface MeasurementThresholdsFormValues extends MeasurandThresholds {
  typeId?: number;
  subtypeId?: number;
  subtypeCodingId?: number;
}

interface Props<TResponseData = never> {
  initialValues?: MeasurementThresholdsFormValues;
  onSubmit: SubmitFormHandler<MeasurementThresholdsFormValues, TResponseData>;
  onSubmitCompleted: SubmitFormCompletedHandler<TResponseData>;
  measurementThresholds: Optional<MeasurementThresholds>;
  handleModalClose?: () => void;
  getRoutePath: (
    measuringDevice: string,
    measurand: MeasurandWithThresholds,
    patientId?: string | number,
  ) => string;
  getRoutePathRegex: (measuringDevice: string, measurand: MeasurandWithThresholds) => string;
  isSystemThresholds?: boolean;
}

export function ThresholdsForm<TResponseData = never>({
  initialValues,
  onSubmit,
  onSubmitCompleted,
  measurementThresholds,
  handleModalClose,
  getRoutePath,
  getRoutePathRegex,
  isSystemThresholds = false,
}: Props<TResponseData>) {
  const { t: tThresholds } = useTranslation('thresholds');
  const { t: tMeasurement } = useTranslation('measurement');
  const {
    selectedTextField,
    hoveringOverTextField,
    defaultSelectedTextField,
    setDefaultSelectedTextField,
  } = useThresholdsContext();
  const location = useLocation();
  const { patientId, measurementType, measurementSubtype, measurementSubtypeCoding } = useParams<{
    patientId: string;
    measurementType: string;
    measurementSubtype: string;
    measurementSubtypeCoding: string;
  }>();
  const [selectedMeasurands, setSelectedMeasurands] = useState<
    MeasurandWithThresholds[] | undefined
  >(
    getThresholdsMeasurandsByUrlTypeSubtypeAndSubtypeCoding(
      measurementThresholds,
      measurementType as string,
      measurementSubtype as string,
      measurementSubtypeCoding as string,
    ),
  );

  useEffect(() => {
    setSelectedMeasurands(
      getThresholdsMeasurandsByUrlTypeSubtypeAndSubtypeCoding(
        measurementThresholds,
        measurementType as string,
        measurementSubtype as string,
        measurementSubtypeCoding as string,
      ),
    );
  }, [measurementThresholds, measurementType, measurementSubtype, measurementSubtypeCoding]);

  useEffect(() => {
    if (initialValues && selectedMeasurands) {
      const measurementData =
        initialValues[
          getKeyByTypeSubtypeAndSubtypeCoding(selectedMeasurands[0]) as keyof MeasurandTypes
        ];
      if (measurementData) {
        if (!measurementData.isMaxErrorDisabled) {
          setDefaultSelectedTextField('maxError');
        } else if (!measurementData.isMaxWarningDisabled) {
          setDefaultSelectedTextField('maxWarning');
        } else if (!measurementData.isMinWarningDisabled) {
          setDefaultSelectedTextField('minWarning');
        } else if (!measurementData.isMinErrorDisabled) {
          setDefaultSelectedTextField('minError');
        }
      }
    }
  }, [selectedMeasurands]);

  const classes = useThresholdsFormStyles();
  let defaultFormValues: MeasurementThresholdsFormValues = {};
  if (measurementThresholds && measurementThresholds.measurands) {
    defaultFormValues = Object.fromEntries(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(measurementThresholds?.measurands).map(([_, v]) => [
        getKeyByTypeSubtypeAndSubtypeCoding(v),
        {
          id: v.typeId,
          maxWarning: null,
          isMaxWarningCurrentlyActiveOnForm: false,
          isMaxWarningDisabled: false,
          maxError: null,
          isMaxErrorCurrentlyActiveOnForm: false,
          isMaxErrorDisabled: false,
          minWarning: null,
          isMinWarningCurrentlyActiveOnForm: false,
          isMinWarningDisabled: false,
          minError: null,
          isMinErrorCurrentlyActiveOnForm: false,
          isMinErrorDisabled: false,
        },
      ]),
    );
  }

  const schema: Yup.SchemaOf<MeasurementThresholdsFormValues> = Yup.lazy((measurands) => {
    return Yup.object(
      Object.fromEntries(
        Object.keys(measurands).map((key) => [
          key,
          Yup.object().shape(
            {
              id: Yup.number(),
              maxError: Yup.number()
                .transform((v) => (v === '' || Number.isNaN(v) ? null : v))
                .notRequired()
                .nullable()
                .when('isMaxErrorCurrentlyActiveOnForm', {
                  is: (value: boolean) => value === true,
                  then: Yup.number()
                    .transform((v) => (v === '' || Number.isNaN(v) ? null : v))
                    .required(tThresholds('maxError.required'))
                    .nullable()
                    .when('maxWarning', {
                      is: (value: number | string) => value != '' && value != null,
                      then: Yup.number()
                        .transform((v) => (v === '' || Number.isNaN(v) ? null : v))
                        .required(tThresholds('maxError.required'))
                        .nullable()
                        .moreThan(
                          Yup.ref('maxWarning'),
                          tThresholds('maxError.moreThan', {
                            fieldToCompare: tThresholds('maxWarning.label'),
                            value: '${more}',
                          }),
                        ),
                    }),
                }),
              maxWarning: Yup.number()
                .notRequired()
                .nullable()
                .when('isMaxWarningCurrentlyActiveOnForm', {
                  is: (value: boolean) => value === true,
                  then: Yup.number()
                    .transform((v) => (v === '' || Number.isNaN(v) ? null : v))
                    .required(tThresholds('maxWarning.required'))
                    .nullable()
                    .when('maxError', {
                      is: (value: number | string) => value != '' && value != null,
                      then: Yup.number()
                        .required(tThresholds('maxWarning.required'))
                        .nullable()
                        .when('minWarning', {
                          is: (value: number | string) => value != '' && value != null,
                          then: Yup.number()
                            .transform((v) => (v === '' || Number.isNaN(v) ? null : v))
                            .required(tThresholds('maxWarning.required'))
                            .nullable()
                            .lessThan(
                              Yup.ref('maxError'),
                              tThresholds('maxWarning.lessThan', {
                                fieldToCompare: tThresholds('maxError.label'),
                                value: '${less}',
                              }),
                            )
                            .moreThan(
                              Yup.ref('minWarning'),
                              tThresholds('maxWarning.moreThan', {
                                fieldToCompare: tThresholds('minWarning.label'),
                                value: '${more}',
                              }),
                            ),
                          otherwise: Yup.number()
                            .transform((v) => (v === '' || Number.isNaN(v) ? null : v))
                            .required(tThresholds('maxWarning.required'))
                            .nullable()
                            .lessThan(
                              Yup.ref('maxError'),
                              tThresholds('minWarning.lessThan', {
                                fieldToCompare: tThresholds('maxWarning.label'),
                                value: '${less}',
                              }),
                            ),
                        }),
                    }),
                }),
              minWarning: Yup.number()
                .transform((v) => (v === '' || Number.isNaN(v) ? null : v))
                .notRequired()
                .nullable()
                .when('isMinWarningCurrentlyActiveOnForm', {
                  is: (value: boolean) => value === true,
                  then: Yup.number()
                    .transform((v) => (v === '' || Number.isNaN(v) ? null : v))
                    .required(tThresholds('minWarning.required'))
                    .nullable()
                    .when('maxWarning', {
                      is: (value: number | string) => value != '' && value != null,
                      then: Yup.number()
                        .required(tThresholds('minWarning.required'))
                        .nullable()
                        .when('minError', {
                          is: (value: number | string) => value != '' && value != null,
                          then: Yup.number()
                            .transform((v) => (v === '' || Number.isNaN(v) ? null : v))
                            .required(tThresholds('minWarning.required'))
                            .nullable()
                            .lessThan(
                              Yup.ref('maxWarning'),
                              tThresholds('minWarning.lessThan', {
                                fieldToCompare: tThresholds('maxWarning.label'),
                                value: '${less}',
                              }),
                            )
                            .moreThan(
                              Yup.ref('minError'),
                              tThresholds('minWarning.moreThan', {
                                fieldToCompare: tThresholds('minError.label'),
                                value: '${more}',
                              }),
                            ),
                          otherwise: Yup.number()
                            .transform((v) => (v === '' || Number.isNaN(v) ? null : v))
                            .required(tThresholds('minWarning.required'))
                            .nullable()
                            .lessThan(
                              Yup.ref('maxWarning'),
                              tThresholds('minWarning.lessThan', {
                                fieldToCompare: tThresholds('maxWarning.label'),
                                value: '${less}',
                              }),
                            ),
                        }),
                    }),
                }),
              minError: Yup.number()
                .transform((v) => (v === '' || Number.isNaN(v) ? null : v))
                .notRequired()
                .nullable(true)
                .when('isMinErrorCurrentlyActiveOnForm', {
                  is: (value: boolean) => value === true,
                  then: Yup.number()
                    .transform((v) => (v === '' || Number.isNaN(v) ? null : v))
                    .required(tThresholds('minError.required'))
                    .nullable(true)
                    .when('minWarning', {
                      is: (value: number | string) => value != '' && value != null,
                      then: Yup.number()
                        .transform((v) => (v === '' || Number.isNaN(v) ? null : v))
                        .required(tThresholds('minError.required'))
                        .nullable(true)
                        .lessThan(
                          Yup.ref('minWarning'),
                          tThresholds('minError.lessThan', {
                            fieldToCompare: tThresholds('minWarning.label'),
                            value: '${less}',
                          }),
                        ),
                    }),
                }),
              isMaxErrorCurrentlyActiveOnForm: Yup.boolean().required(),
              isMaxWarningCurrentlyActiveOnForm: Yup.boolean().required(),
              isMinWarningCurrentlyActiveOnForm: Yup.boolean().required(),
              isMinErrorCurrentlyActiveOnForm: Yup.boolean().required(),
            },
            [
              ['maxError', 'maxWarning'],
              ['minError', 'minWarning'],
              ['maxWarning', 'minWarning'],
            ],
          ),
        ]),
      ),
    );
    // https://github.com/jquense/yup/issues/1190
    // Seems like it's a TypeScript types bug. There's no way to use lazy schema because it is raising compiler error.
    // To avoid, I am now casting output to any but would be nice to actually fix the interface.
    // eslint-disable-next-line
  }) as any;

  const nav: Navigation = {};
  const measurementTypes = measurementThresholds?.measurands.map((x) => x);

  if (measurementTypes) {
    for (const measurementType of measurementTypes) {
      const measuringDeviceKebabized = kebabize(measurementThresholds?.measuringDevice ?? '');
      if (measuringDeviceKebabized != '') {
        const key = getKeyByTypeSubtypeAndSubtypeCoding(measurementType);
        nav[key] = {
          tab: `${getRoutePath(measuringDeviceKebabized, measurementType, patientId)}`,
          route: {
            path: `${getRoutePathRegex(measuringDeviceKebabized, measurementType)}`,
          },
          subRoutes: {},
        };
      }
    }
  }

  return (
    <EntityForm<MeasurementThresholdsFormValues, TResponseData>
      initialValues={initialValues || defaultFormValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      onSubmitCompleted={onSubmitCompleted}
      forceDirectEditableMode={true}
    >
      {!isSystemThresholds && <Box className={classes.formHeader}>{tThresholds('thresholds')}</Box>}
      <ThresholdsHelperText />
      <ThresholdsInfoMessage />
      <ThresholdsErrorMessages
        measurementType={
          selectedMeasurands ? getKeyByTypeSubtypeAndSubtypeCoding(selectedMeasurands[0]) : null
        }
      />
      <Box className={classes.chooseMeasurementType}>{tThresholds('chooseMeasurementType')}:</Box>
      <MeasurementTypeNavTabs
        value={getTabsValue(nav, location.pathname, false)}
        aria-label="measurement types tabs"
      >
        {measurementTypes &&
          measurementTypes.map((x, index) => (
            <MeasurementTypeNavTab
              key={index}
              label={`${tMeasurement(x.type as MeasurandType)}${
                x.subtypeCoding != null ? ` - ${tMeasurement(x.subtypeCoding)}` : ''
              }`}
              value={nav[getKeyByTypeSubtypeAndSubtypeCoding(x)].tab}
              requireFunctionality={PATIENT_THRESHOLDS_READ_POLICY}
            />
          ))}
      </MeasurementTypeNavTabs>
      <Box>
        <Box className={classes.imageAndFormWrapper}>
          <Hidden mdDown={true}>
            <Box display="flex" alignItems="center">
              <ThresholdsImage
                type={hoveringOverTextField ?? selectedTextField}
                defaultType={defaultSelectedTextField}
              />
            </Box>
          </Hidden>
          <Box className={classes.formWrapper}>
            {selectedMeasurands?.map((m, idx) => {
              const type = getKeyByTypeSubtypeAndSubtypeCoding(m);
              return (
                <Box key={idx} display="flex" flexDirection="column" height="100%">
                  <Box className={classes.formThresholdsGroupWrapper}>
                    <Box className={classes.formThresholdsMaxAndMinWrapper}>
                      <ThresholdInput
                        numberInputId={`${type}.maxError`}
                        isDisabledId={`${type}.isMaxErrorDisabled`}
                        label={tThresholds('maxError.label')}
                        isThresholdActiveId={`${type}.isMaxErrorCurrentlyActiveOnForm`}
                        isChangedId={`${type}.isMaxErrorChanged`}
                        type={'maxError'}
                        systemThresholdValue={m.systemThresholds?.maxError}
                      />
                      <ThresholdInput
                        numberInputId={`${type}.maxWarning`}
                        isDisabledId={`${type}.isMaxWarningDisabled`}
                        label={tThresholds('maxWarning.label')}
                        isThresholdActiveId={`${type}.isMaxWarningCurrentlyActiveOnForm`}
                        isChangedId={`${type}.isMaxWarningChanged`}
                        type={'maxWarning'}
                        systemThresholdValue={m.systemThresholds?.maxWarning}
                      />
                    </Box>
                    <Box className={classes.formThresholdsMaxAndMinWrapper}>
                      <ThresholdInput
                        numberInputId={`${type}.minWarning`}
                        isDisabledId={`${type}.isMinWarningDisabled`}
                        label={tThresholds('minWarning.label')}
                        isThresholdActiveId={`${type}.isMinWarningCurrentlyActiveOnForm`}
                        isChangedId={`${type}.isMinWarningChanged`}
                        type={'minWarning'}
                        systemThresholdValue={m.systemThresholds?.minWarning}
                      />
                      <ThresholdInput
                        numberInputId={`${type}.minError`}
                        isDisabledId={`${type}.isMinErrorDisabled`}
                        label={tThresholds('minError.label')}
                        isThresholdActiveId={`${type}.isMinErrorCurrentlyActiveOnForm`}
                        isChangedId={`${type}.isMinErrorChanged`}
                        type={'minError'}
                        systemThresholdValue={m.systemThresholds?.minError}
                      />
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
        {selectedMeasurands?.map(
          (m, idx) =>
            !!m &&
            !!m.sourceLink && <ThresholdsGuidelineInfo key={idx} measurandWithThresholds={m} />,
        )}
      </Box>
      <Divider />
      <Box className={classes.formButtonsWrapper}>
        <CancelButton onClick={() => handleModalClose && handleModalClose()} />
        <FormButton />
      </Box>
    </EntityForm>
  );
}

const getThresholdsMeasurandsByUrlTypeSubtypeAndSubtypeCoding = (
  measurementThresholds: Optional<MeasurementThresholds>,
  measurementType: string,
  measurementSubtype: string | null,
  measurementSubtypeCoding: string | null,
) => {
  return measurementThresholds?.measurands.filter(
    (x) =>
      x.type == camelize(measurementType) &&
      x.subtype == (measurementSubtype != null ? camelize(measurementSubtype) : undefined) &&
      x.subtypeCoding ==
        (measurementSubtypeCoding != null
          ? kebabCaseToUpperSnakeCase(measurementSubtypeCoding)
          : undefined),
  );
};
