import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from '../ProtectedRoute';
import { EDIT_SUBPATH } from '../../constants';
import { FunctionalityProvider } from '../../context/functionality';
import { QuestionnairePage } from '../../pages/questionnaires/QuestionnairePage';

export function QuestionnaireRoutes() {
  return (
    <Routes>
      <Route
        path={`${EDIT_SUBPATH}`}
        element={
          <ProtectedRoute requireFunctionality="QUESTIONNAIRE_TEMPLATES_READ">
            <FunctionalityProvider
              createFunctionality="QUESTIONNAIRE_TEMPLATES_CREATE"
              readFunctionality="QUESTIONNAIRE_TEMPLATES_READ"
              updateFunctionality="QUESTIONNAIRE_TEMPLATES_UPDATE"
              deleteFunctionality="QUESTIONNAIRE_TEMPLATES_DELETE"
            >
              <QuestionnairePage />
            </FunctionalityProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/*"
        element={
          <ProtectedRoute requireFunctionality="QUESTIONNAIRE_TEMPLATES_UPDATE">
            <FunctionalityProvider
              createFunctionality="QUESTIONNAIRE_TEMPLATES_CREATE"
              readFunctionality="QUESTIONNAIRE_TEMPLATES_READ"
              updateFunctionality="QUESTIONNAIRE_TEMPLATES_UPDATE"
              deleteFunctionality="QUESTIONNAIRE_TEMPLATES_DELETE"
            >
              <QuestionnairePage />
            </FunctionalityProvider>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
