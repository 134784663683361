import * as Yup from 'yup';
import { SaveSubmitButton } from '../../../components/SaveSubmitButton';
import { Form, SubmitFormCompletedHandler, SubmitFormHandler } from '../../../components/Form';
import { ListDailyPlanTemplate } from '../../../models';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CancelButton } from '../../../components/CancelButton';
import { SelectInput, SelectOption } from '../../../components/SelectInput';
import { useEffect, useState } from 'react';
import { useAlarmsFromTemplateFormStyles } from './styles';

export interface AlarmGroupWithTemplatesFormValues {
  templateId?: number;
}

interface Props {
  initialValues?: AlarmGroupWithTemplatesFormValues;
  onSubmit: SubmitFormHandler<AlarmGroupWithTemplatesFormValues>;
  onSubmitCompleted: SubmitFormCompletedHandler;
  onCancel?: () => void;
  dailyPlanTemplates: ListDailyPlanTemplate[];
}

export function AlarmsFromTemplateForm({
  onSubmit,
  onSubmitCompleted,
  onCancel,
  initialValues,
  dailyPlanTemplates,
}: Props) {
  const classes = useAlarmsFromTemplateFormStyles();
  const [dailyPlanTemplateOptions, setDailyPlanTemplateOptions] = useState<SelectOption[]>([]);
  const { t } = useTranslation(['alarmGroup', 'dailyPlanTemplate', 'common']);

  useEffect(() => {
    const options = dailyPlanTemplates.map((template) => ({
      value: template.id,
      label: template.name,
    }));
    setDailyPlanTemplateOptions(options);
  }, [dailyPlanTemplates]);

  const defaultFormValues = {
    templateId: undefined,
  };

  const schema: Yup.SchemaOf<AlarmGroupWithTemplatesFormValues> = Yup.object({
    templateId: Yup.number().required(t('dailyPlanTemplate:template.validation.required')),
  });

  return (
    <Form<AlarmGroupWithTemplatesFormValues>
      initialValues={initialValues || defaultFormValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      onSubmitCompleted={onSubmitCompleted}
    >
      <Box display="flex" flexDirection="column" width="100%">
        <Typography variant="h6">{t('dailyPlanTemplate:chooseTemplate')}</Typography>
        <SelectInput
          id="templateId"
          label={t('dailyPlanTemplate:template.label')}
          options={dailyPlanTemplateOptions}
        />
        <Box display="flex" justifyContent={'flex-end'}>
          {onCancel && (
            <Box marginRight={2} className={classes.buttonWrapper}>
              <CancelButton onClick={onCancel} fullWidth={true} />
            </Box>
          )}
          <Box className={classes.buttonWrapper}>
            <SaveSubmitButton fullWidth={true} actionText={t('common:add')} />
          </Box>
        </Box>
      </Box>
    </Form>
  );
}
