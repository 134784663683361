import { Page } from '../../components/Page';
import { FunctionalityProvider } from '../../context/functionality';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { PageTopBar } from '../../components/PageTopBar';
import { AlarmTemplateSubPage } from './AlarmTemplateSubPage';

export function DailyPlanTemplateAlarmPage() {
  const { t } = useTranslation('dailyPlanTemplate');
  return (
    <FunctionalityProvider
      createFunctionality="DAILY_PLAN_TEMPLATES_CREATE"
      readFunctionality="DAILY_PLAN_TEMPLATES_READ"
      updateFunctionality="DAILY_PLAN_TEMPLATES_UPDATE"
      deleteFunctionality="DAILY_PLAN_TEMPLATES_DELETE"
    >
      <>
        <StickyTopAppBar>
          <PageTopBar title={t('title.template')} hideLastUpdated={true} />
        </StickyTopAppBar>
        <Box mt={2}>
          <Page requireFunctionality="DAILY_PLAN_TEMPLATES_READ">
            <AlarmTemplateSubPage />
          </Page>
        </Box>
      </>
    </FunctionalityProvider>
  );
}
