import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface DeleteIconButtonProps {
  onDeleteClick: () => void;
  disabled?: boolean;
}

export function DeleteIconButton({ onDeleteClick, disabled }: DeleteIconButtonProps) {
  return (
    <IconButton aria-label="delete button" onClick={onDeleteClick} disabled={disabled} size="large">
      <CloseIcon />
    </IconButton>
  );
}
