import MaterialAutocomplete from '@mui/material/Autocomplete';
import { InputProps, TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { useFormikDisableContext } from '../../context/formikDisable';
import React from 'react';
import { getInputErrorText } from '../InputErrorText';
import { ClassNameMap } from '@mui/styles/withStyles/withStyles';
import { useTranslation } from 'react-i18next';

interface Props<T> {
  id: string;
  label: string;
  required?: boolean;
  getOptionLabel: (option: string | T) => string;
  options: T[];
  placeholder?: string;
  hideErrorMessage?: boolean;
  classes?: Partial<ClassNameMap>;
  textFieldInputProps?: Partial<InputProps>;
  noOptionsText?: string;
  freeSolo?: boolean;
  multiline?: boolean;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
}

export function AutoComplete<T>({
  id,
  label,
  required,
  options,
  getOptionLabel,
  placeholder,
  hideErrorMessage = false,
  classes,
  textFieldInputProps,
  noOptionsText,
  freeSolo = false,
  multiline = false,
  variant = 'standard',
}: Props<T>) {
  const { t } = useTranslation('common');
  const [{ value, onChange: _, ...field }, meta] = useField(id);
  const { isSubmitting, setFieldValue } = useFormikContext();
  const { isDisabled } = useFormikDisableContext();

  const errorMessage = meta.touched ? meta.error : null;

  return (
    <MaterialAutocomplete
      {...field}
      options={options}
      getOptionLabel={getOptionLabel}
      id={id}
      autoComplete={true}
      value={value}
      defaultValue={null}
      noOptionsText={noOptionsText ?? t('autoComplete.noOptions')}
      forcePopupIcon={false}
      onChange={(_, newValue) => {
        setFieldValue(id, newValue);
      }}
      {...(freeSolo ? { onInputChange: (_, value) => setFieldValue(id, value) } : {})}
      disabled={isSubmitting || isDisabled}
      fullWidth={true}
      freeSolo={freeSolo}
      classes={classes}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin="normal"
          variant={variant}
          required={required}
          error={!hideErrorMessage && !!errorMessage}
          helperText={!hideErrorMessage ? getInputErrorText(errorMessage) : null}
          placeholder={placeholder}
          multiline={multiline}
          InputProps={{ ...params.InputProps, ...textFieldInputProps }}
          InputLabelProps={{
            ...params.InputLabelProps,
            htmlFor: id,
          }}
        />
      )}
    />
  );
}
