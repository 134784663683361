import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import {
  AlarmGridModel,
  AlarmType,
  AlarmTypeEnum,
  CreateAlarmInput,
  DailyPlanTemplate,
  TriggerTime,
  UpdateDailyPlanTemplateInput,
} from '../../models';
import { Page } from '../../components/Page';
import { useNavigate, useParams } from 'react-router-dom';
import { FunctionalityProvider } from '../../context/functionality';
import { ProgressIndicator } from '../../components/ProgressIndicator';
import { useTranslation } from 'react-i18next';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import { PageTopBar } from '../../components/PageTopBar';
import { Box } from '@mui/material';
import { getTriggerTimesFromAlarmFormValues } from '../../components/AlarmComponent';
import { DailyPlanTemplateForm, DailyPlanTemplateFormValues } from './DailyPlanTemplateForm';
import { AlarmFormValues } from '../../components/AlarmForm';
import { useEntityActionSnackbar } from '../../hooks';
import { useAlarmActiveShowHideForm } from '../../context/alarmActiveShowHideForm';
import { NoAlarms } from '../../components/NoAlarms';
import { AlarmsGrid } from '../../components/Grids/AlarmsGrid';
import { NewAlarmDialog } from '../../components/NewAlarmDialog';
import { EDIT_SUBPATH } from '../../constants';

export function DailyPlanTemplatePage() {
  const { t } = useTranslation(['alarm', 'dailyPlanTemplate']);
  const { isAnyFormVisible, hideAllForms } = useAlarmActiveShowHideForm();
  const {
    enqueueEntityCreatedSnackbar,
    enqueueEntityUpdatedSnackbar,
    enqueueEntityDeletedSnackbar,
  } = useEntityActionSnackbar();
  const { id } = useParams();
  const navigate = useNavigate();
  const [
    { data: alarmGroup, isLoading: isDailyPlanTemplateLoading },
    { refetch: refetchDailyPlanTemplate },
    { lastFetchDatetime },
  ] = useGetApi<DailyPlanTemplate>(ApiRoutes.DailyPlanTemplate(id as string));
  const [{ data: alarmTypes, isLoading: areAlarmTypesLoading }] = useGetApi<AlarmType[]>(
    ApiRoutes.AlarmTypes,
  );
  const [
    { data: autocompleteDrugNames, isLoading: areAutocompleteDrugNamesLoading },
    { refetch: refetchAutocompleteDrugNames },
  ] = useGetApi<string[]>(ApiRoutes.AutocompleteDrugNames);

  const reminderId = alarmTypes?.find((alarmType) => alarmType.type === AlarmTypeEnum.REMINDER)?.id;

  function handleSubmit(values: DailyPlanTemplateFormValues) {
    return makeApiRequest<never, UpdateDailyPlanTemplateInput>(
      'PUT',
      ApiRoutes.DailyPlanTemplate(id as string),
      values,
    );
  }

  function handleSubmitAlarm(values: AlarmFormValues) {
    const triggerTimes: TriggerTime[] = getTriggerTimesFromAlarmFormValues(values);

    return makeApiRequest<never, CreateAlarmInput>(
      'POST',
      ApiRoutes.DailyPlanTemplateAlarms(id as string),
      {
        ...values,
        typeIds: values.typeIds,
        startOnDatetime: values.startOnDatetime || new Date(),
        endOnDatetime: values.endOnDatetime,
        repetitionInterval: values.repetitionIntervalIsActive ? values.repetitionInterval : null,
        repetitionDays: !values.repetitionIntervalIsActive ? values.repetitionDays : null,
        triggerTimes: triggerTimes,
        drugName:
          reminderId != null && values.typeIds.includes(reminderId) ? values.drugName : null,
      },
    );
  }

  async function handleSubmitCompleted() {
    await refetchDailyPlanTemplate();
    navigate(`/daily-plan-templates/${id}`);
    enqueueEntityUpdatedSnackbar(t('dailyPlanTemplate:title.template'));
  }

  async function handleSubmitAlarmCompleted() {
    await refetchDailyPlanTemplate();
    await refetchAutocompleteDrugNames();

    hideAllForms();

    enqueueEntityCreatedSnackbar(t('alarm:alarm'));
  }

  async function handleAlarmDelete(alarmId: number) {
    await makeApiRequest('DELETE', ApiRoutes.DailyPlanTemplateAlarm(id as string, alarmId));

    enqueueEntityDeletedSnackbar(t('alarm:alarm'));
  }

  function getEditPath(alarmId: number) {
    return `/daily-plan-templates/${id}/alarms/${alarmId}/${EDIT_SUBPATH}`;
  }

  function handleRowClick(row: AlarmGridModel) {
    navigate(`/daily-plan-templates/${id}/alarms/${row.id}`);
  }

  if (isDailyPlanTemplateLoading || areAlarmTypesLoading || areAutocompleteDrugNamesLoading) {
    return <ProgressIndicator />;
  }

  if (!alarmGroup) {
    return <div>{t('dailyPlanTemplate:error.dailyPlanTemplateNotFound')}</div>;
  }

  if (!alarmTypes) {
    return <div>{t('dailyPlanTemplate:error.alarmTypesNotFound')}</div>;
  }

  return (
    <FunctionalityProvider
      createFunctionality="DAILY_PLAN_TEMPLATES_CREATE"
      readFunctionality="DAILY_PLAN_TEMPLATES_READ"
      updateFunctionality="DAILY_PLAN_TEMPLATES_UPDATE"
      deleteFunctionality="DAILY_PLAN_TEMPLATES_DELETE"
    >
      <>
        <StickyTopAppBar>
          <PageTopBar
            title={t('dailyPlanTemplate:title.template')}
            lastUpdated={lastFetchDatetime}
          />
        </StickyTopAppBar>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box mt={2} width="50%">
            <Page requireFunctionality="DAILY_PLAN_TEMPLATES_READ">
              <DailyPlanTemplateForm
                initialValues={{ name: alarmGroup?.name ?? '' }}
                onSubmit={handleSubmit}
                onSubmitCompleted={handleSubmitCompleted}
              />
              <FunctionalityProvider
                createFunctionality="DAILY_PLAN_TEMPLATES_CREATE"
                readFunctionality="DAILY_PLAN_TEMPLATES_READ"
                updateFunctionality="DAILY_PLAN_TEMPLATES_UPDATE"
                deleteFunctionality="DAILY_PLAN_TEMPLATES_DELETE"
              >
                <Box py={1}>
                  <NewAlarmDialog
                    onSubmit={handleSubmitAlarm}
                    onSubmitCompleted={handleSubmitAlarmCompleted}
                    alarmTypes={alarmTypes ?? []}
                    autocompleteDrugNames={autocompleteDrugNames || []}
                  />
                </Box>
                <Box width="100%">
                  {!alarmGroup?.alarms || alarmGroup?.alarms.length === 0 ? (
                    !isAnyFormVisible && <NoAlarms />
                  ) : (
                    <AlarmsGrid
                      alarms={alarmGroup?.alarms ?? []}
                      alarmTypes={alarmTypes ?? []}
                      onDelete={handleAlarmDelete}
                      onDeleteCompleted={refetchDailyPlanTemplate}
                      getEditPath={getEditPath}
                      onRowClick={handleRowClick}
                    />
                  )}
                </Box>
              </FunctionalityProvider>
            </Page>
          </Box>
        </Box>
      </>
    </FunctionalityProvider>
  );
}
