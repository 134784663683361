import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { ASIDE_MENU_FOOTER_HEIGHT } from '../../../constants';

interface StyleProps {
  isDrawerOpen: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    height: theme.typography.pxToRem(ASIDE_MENU_FOOTER_HEIGHT),
    minHeight: theme.typography.pxToRem(ASIDE_MENU_FOOTER_HEIGHT),
    transition: theme.transitions.create(['height', 'rowGap'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    backgroundColor: '#EFF2F6',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: theme.spacing(1.5, 3),
    rowGap: theme.spacing(3.5),
  },
}));
