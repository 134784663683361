import React from 'react';
import { Box, Typography } from '@mui/material';
import { FormAddButton } from '../AddButton';
import { FieldArray, FieldArrayRenderProps, useField } from 'formik';
import { FormDeleteIconButton } from '../DeleteIconButton';
import { TextInput } from '../TextInput';
import { InstallationMemberData } from '../../models';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '../DatePicker/DatePicker';

interface Props {
  id: string;
}

export function InstallationMemberInput({ id }: Props) {
  const emptyValue: InstallationMemberData = {
    name: '',
    surname: '',
    email: '',
    birthday: new Date(),
  };
  const { t } = useTranslation('installation');
  const [{ value }] = useField<InstallationMemberData[]>(id);

  function renderInputs(arrayHelpers: FieldArrayRenderProps) {
    if (!value || !Array.isArray(value) || value.length === 0) {
      return null;
    }

    return value.map((value, index) => (
      <Box key={index} display="flex" marginY={1} data-testid={`installation-member-${index}`}>
        <Box width="100%">
          <Box display="flex">
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography variant="body1">
                {`${value.name} ${value.surname}` ||
                  t('member.title.default', {
                    index: index + 1,
                  })}
              </Typography>
              <FormDeleteIconButton onDeleteClick={() => arrayHelpers.remove(index)} />
            </Box>
          </Box>
          <Box>
            <TextInput id={`${id}[${index}].name`} label={t('member.name.label')} required={true} />
            <TextInput
              id={`${id}[${index}].surname`}
              data-testid="role"
              label={t('member.surname.label')}
              required={true}
            />
            <TextInput
              id={`${id}[${index}].email`}
              data-testid={`${id}[${index}].email`}
              label={t('member.email.label')}
              required={true}
            />
            <DatePicker
              id={`${id}[${index}].birthday`}
              label={t('birthday.label')}
              required={true}
            />
          </Box>
        </Box>
      </Box>
    ));
  }

  function renderArray(arrayHelpers: FieldArrayRenderProps) {
    return (
      <React.Fragment>
        {renderInputs(arrayHelpers)}
        <Box display="flex" justifyContent="space-between" marginTop={2}>
          <FormAddButton
            onAddClick={() => arrayHelpers.push(emptyValue)}
            text={t('memberStep.addButton')}
          />
        </Box>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <FieldArray name={id} render={renderArray} />
    </React.Fragment>
  );
}
