import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

interface Props {
  onClick?: () => void;
  fullWidth?: boolean;
}

export function CancelButton({ onClick, fullWidth = false }: Props) {
  const { t } = useTranslation('common');
  const classes = useStyles();
  return (
    <Button variant="contained" onClick={onClick} classes={classes} fullWidth={fullWidth}>
      {t('cancel')}
    </Button>
  );
}
