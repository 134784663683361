import * as Yup from 'yup';
import { TextInput } from '../../../../components/TextInput';
import { SubmitButton } from '../../../../components/SubmitButton';
import { Form, SubmitFormCompletedHandler, SubmitFormHandler } from '../../../../components/Form';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';

export interface ForgottenPasswordFormValues {
  usernameOrEmail: string;
}

interface Props {
  onSubmit: SubmitFormHandler<ForgottenPasswordFormValues>;
  onSubmitCompleted: SubmitFormCompletedHandler;
}

export function ForgottenPasswordForm({ onSubmit, onSubmitCompleted }: Props) {
  const { t } = useTranslation('forgottenPassword');
  const defaultFormValues = {
    usernameOrEmail: '',
  };

  const schema: Yup.SchemaOf<ForgottenPasswordFormValues> = Yup.object({
    usernameOrEmail: Yup.string().required(t('usernameOrEmail.validation.required')),
  });

  return (
    <Form<ForgottenPasswordFormValues>
      initialValues={defaultFormValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      onSubmitCompleted={onSubmitCompleted}
    >
      <Box display="flex" flexDirection="column" width="100%">
        <TextInput id="usernameOrEmail" label={t('usernameOrEmail.label')} required={true} />
        <SubmitButton fullWidth={true} buttonText={t('sendLoginLinkButton')} />
      </Box>
    </Form>
  );
}
