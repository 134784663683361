import {
  AlarmType,
  AlarmTypeName,
  MeasurandTypes,
  MeasurandWithThresholds,
  MeasurementValue,
  MeasuringDeviceType,
  MeasuringDeviceTypeEnum,
  MeasuringDeviceWithType,
  Patient,
  PatientGridModel,
  WebMeasurement,
} from '../models';
import {
  BLOOD_PRESSURE_MONITOR_SORT_MAP,
  GLUCOMETER_FOOD_TYPE_CODING_SORT_MAP,
  GLUCOMETER_SORT_MAP,
  MEASUREMENT_TYPES_SORT_MAP,
  PULSE_OXYMETER_SORT_MAP,
  THERMOMETER_SORT_MAP,
} from '../constants';
import { snakeCaseToPascalCase } from './utils';

export const alarmTypeOptionsSortFn = (a: AlarmType, b: AlarmType) => {
  return alarmTypeNameOptionsSortFn(a.type, b.type);
};

export const measuringDeviceOptionsSortFn = (
  a: MeasuringDeviceWithType,
  b: MeasuringDeviceWithType,
) => {
  const aIdx = MEASUREMENT_TYPES_SORT_MAP[a.name] ?? 0;
  const bIdx = MEASUREMENT_TYPES_SORT_MAP[b.name] ?? 0;
  if (aIdx > bIdx) {
    return 1;
  } else if (aIdx < bIdx) {
    return -1;
  } else {
    return 0;
  }
};

export const alarmTypeNameOptionsSortFn = (a: AlarmTypeName, b: AlarmTypeName) => {
  const aIdx = MEASUREMENT_TYPES_SORT_MAP[snakeCaseToPascalCase(a) as MeasuringDeviceType] ?? 0;
  const bIdx = MEASUREMENT_TYPES_SORT_MAP[snakeCaseToPascalCase(b) as MeasuringDeviceType] ?? 0;
  if (aIdx > bIdx) {
    return 1;
  } else if (aIdx < bIdx) {
    return -1;
  } else {
    return 0;
  }
};

export function getSortMapForMeasuringDevice(measuringDevice?: MeasuringDeviceType) {
  if (!measuringDevice) {
    return null;
  }

  if (measuringDevice == MeasuringDeviceTypeEnum.BLOOD_PRESSURE_MONITOR) {
    return BLOOD_PRESSURE_MONITOR_SORT_MAP;
  } else if (measuringDevice == MeasuringDeviceTypeEnum.GLUCOMETER) {
    return GLUCOMETER_SORT_MAP;
  } else if (measuringDevice == MeasuringDeviceTypeEnum.THERMOMETER) {
    return THERMOMETER_SORT_MAP;
  } else if (measuringDevice == MeasuringDeviceTypeEnum.PULSE_OXIMETER) {
    return PULSE_OXYMETER_SORT_MAP;
  }

  return null;
}

export function getSortMapForCoding(
  type: string,
  subtype: string | null,
  measuringDevice?: MeasuringDeviceType,
) {
  if (!measuringDevice || !subtype) {
    return null;
  }

  if (
    measuringDevice == MeasuringDeviceTypeEnum.GLUCOMETER &&
    type == 'glucose' &&
    subtype == 'foodType'
  ) {
    return GLUCOMETER_FOOD_TYPE_CODING_SORT_MAP;
  }

  return null;
}

export function sortMeasurandsWithThresholds(
  sortMap: Record<string, number>,
  measurands?: MeasurandWithThresholds[],
) {
  if (!measurands) {
    return null;
  }

  return measurands.sort(
    (a, b) =>
      (sortMap[a.type as keyof typeof sortMap] ?? 0) -
      (sortMap[b.type as keyof typeof sortMap] ?? 0),
  );
}

export function sortMeasurandsWithThresholdsSubtypeCodings(
  sortMap: Record<string, number>,
  measurands?: MeasurandWithThresholds[],
) {
  if (!measurands) {
    return null;
  }

  return measurands.sort(
    (a, b) =>
      (sortMap[a.subtypeCoding as keyof typeof sortMap] ?? 0) -
      (sortMap[b.subtypeCoding as keyof typeof sortMap] ?? 0),
  );
}

export function sortMeasurandsWithThresholdsByMeasuringDevice(
  measuringDevice: MeasuringDeviceType,
  measurands?: MeasurandWithThresholds[],
) {
  if (!measurands) {
    return null;
  }

  const sortMap = getSortMapForMeasuringDevice(measuringDevice);

  if (!sortMap) {
    return measurands;
  }

  let sortedMeasurementValues = sortMeasurandsWithThresholds(sortMap, measurands);

  if (sortedMeasurementValues) {
    const codingSortMap = getSortMapForCoding(
      sortedMeasurementValues[0].type,
      sortedMeasurementValues[0].subtype,
      measuringDevice,
    );

    if (codingSortMap) {
      sortedMeasurementValues = sortMeasurandsWithThresholdsSubtypeCodings(
        codingSortMap,
        sortedMeasurementValues,
      );
    }
  }

  return sortedMeasurementValues;
}

export function sortWebMeasurements(
  measurements: WebMeasurement[],
  sortMap: Record<string, number>,
) {
  return measurements.map((e) => {
    return Object.keys(e)
      .sort((e1, e2) => {
        return (
          (sortMap[e1 as keyof MeasurandTypes] ?? 0) - (sortMap[e2 as keyof MeasurandTypes] ?? 0)
        );
      })
      .reduce(function (acc, key) {
        acc[key as keyof MeasurandTypes] = e[key as keyof MeasurandTypes];
        return acc;
      }, {} as WebMeasurement);
  });
}

export function sortWebMeasurementsByMeasuringDevice(
  measurements: WebMeasurement[],
  measuringDevice: MeasuringDeviceType,
) {
  const sortMap = getSortMapForMeasuringDevice(measuringDevice);

  if (!sortMap) {
    return measurements;
  }

  return sortWebMeasurements(measurements, sortMap);
}

export function sortMeasurementValues(
  measurementValues: MeasurementValue[],
  sortMap: Record<string, number>,
) {
  return measurementValues.sort(
    (a, b) =>
      (sortMap[a.type as keyof typeof sortMap] ?? 0) -
      (sortMap[b.type as keyof typeof sortMap] ?? 0),
  );
}

export function sortMeasurementValuesByMeasuringDevice(
  measurements: MeasurementValue[],
  measuringDevice: MeasuringDeviceType,
) {
  const sortMap = getSortMapForMeasuringDevice(measuringDevice);

  if (!sortMap) {
    return measurements;
  }

  return sortMeasurementValues(measurements, sortMap);
}

export function sortPatientsBySelectedPatientsAndDevicePairing(
  patients: Patient[],
  selectedPatients?: Patient[],
): PatientGridModel[] {
  const selectedPatientsIds = selectedPatients?.map((x) => x.id);
  return patients
    .map((value) => {
      const v = value as PatientGridModel;
      v.purpose = value.devicePairing?.purpose;
      v.expectedEndDate = value.devicePairing?.expectedEndDate;
      return v;
    })
    .sort((a, b) => {
      if (selectedPatientsIds?.includes(a.id) && !selectedPatientsIds?.includes(b.id)) {
        return -1;
      } else if (!selectedPatientsIds?.includes(a.id) && selectedPatientsIds?.includes(b.id)) {
        return 1;
      }

      if (a.devicePairing == null && b.devicePairing != null) {
        return 1;
      } else if (b.devicePairing == null && a.devicePairing != null) {
        return -1;
      }
      return a.id > b.id ? -1 : 1;
    });
}
