import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnswerOption, QuestionnaireQuestion } from '../../../../models';
import { getIconByQuestionType } from '../../../../utils';
import { useStyles } from './styles';

interface Props {
  question: QuestionnaireQuestion;
  index: number;
}

export function QuestionnairePreviewQuestion({ question, index }: Props) {
  const classes = useStyles();
  const { t } = useTranslation('questionnaire');

  function formatQuestionAnswerOptions(answerOption: AnswerOption) {
    let value = answerOption.value;

    if (answerOption.score != null) {
      value += ` (${answerOption.score})`;
    }

    return value;
  }

  function getInitialValueByType(_question: QuestionnaireQuestion) {
    if (_question.type.isString) {
      return _question.initialValueString;
    } else if (_question.type.isInteger) {
      return _question.initialValueInt;
    } else if (_question.type.isDouble) {
      return _question.initialValueDouble;
    } else if (_question.type.isBoolean) {
      return _question.initialValueBoolean
        ? t('question.answer.booleanTrue')
        : t('question.answer.booleanFalse');
    }
  }

  return (
    <Box mx={0.5} mb={2}>
      <Box display="flex" px={1.25} py={0.625} className={classes.questionTextLine}>
        <Box mr={1.5}>{index + 1}.</Box>
        <Box className={classes.questionText} id={`question[${index}].text`}>
          {question.text}
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center" justifyContent="center">
          {getIconByQuestionType(question.type.type)}
        </Box>
        <Box
          className={classes.questionSampleAnswer}
          ml={0.3}
          display="flex"
          alignItems="center"
          id={`question[${index}].initialValue`}
        >
          {question.questionnaireAnswerOptions?.length > 0 ? (
            <React.Fragment>
              {question.questionnaireAnswerOptions
                .filter((ansOpt) => ansOpt.initialValue)
                .map((ansOpt, answerOptIndex) => (
                  <Box
                    key={answerOptIndex}
                    className={classes.questionAnswerOption}
                    p={0.5}
                    mr={0.5}
                    id={`question[${index}].answerOption[${answerOptIndex}].initialValue`}
                  >
                    {formatQuestionAnswerOptions(ansOpt)}
                  </Box>
                ))}
              {question.questionnaireAnswerOptions
                .filter((ansOpt) => !ansOpt.initialValue)
                .map((ansOpt, answerOptIndex) => (
                  <Box
                    key={answerOptIndex}
                    className={`${classes.questionAnswerOption} ${classes.questionAnswerOptionNotInitial}`}
                    p={0.5}
                    mr={0.5}
                    id={`question[${index}].answerOption[${answerOptIndex}].notInitialValue`}
                  >
                    {formatQuestionAnswerOptions(ansOpt)}
                  </Box>
                ))}
            </React.Fragment>
          ) : (
            getInitialValueByType(question)
          )}
        </Box>
      </Box>
    </Box>
  );
}
