import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from '../ProtectedRoute';
import { EDIT_SUBPATH } from '../../constants';
import { OrganizationPage } from '../../pages/organization/OrganizationPage';

export function OrganizationRoutes() {
  return (
    <Routes>
      <Route
        path={`${EDIT_SUBPATH}`}
        element={
          <ProtectedRoute requireFunctionality="ORGANIZATIONS_READ">
            <OrganizationPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/*"
        element={
          <ProtectedRoute requireFunctionality="ORGANIZATIONS_READ">
            <OrganizationPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
