import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useAlarmFormStyles = makeStyles((theme: Theme) => ({
  alarmTypesWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: theme.spacing(5, 5),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(5, 0),
    },
  },
  alarmTypesMeasurementWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    margin: 0,
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  alarmTypesReminderWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 0,
    rowGap: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  noteInputTitle: {
    fontFamily: 'Inter',
    textAlign: 'center',
    color: '#515966',
    fontSize: theme.typography.pxToRem(13),
  },
  noteSectionWrapper: {
    maxWidth: 500,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabsWrapper: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'center',
  },
}));
