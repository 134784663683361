import { makeStyles } from '@mui/styles';

export const usePatientNoteFormStyles = makeStyles((theme) => ({
  input: {
    minHeight: theme.typography.pxToRem(100),
    '& .MuiInputBase-root': {
      minHeight: theme.typography.pxToRem(100),
      alignItems: 'start',
    },
  },
}));
