import React from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

export const ThermometerIcon = React.memo(function ThermometerIconComponent({
  width,
  height,
  color,
}: Props) {
  return (
    <svg
      width={width || 9}
      height={height || 19}
      viewBox="0 0 9 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.39078 2.14515H6.11445C5.91763 0.917161 4.87474 -0.0191247 3.62863 0.000296703C2.26726 0.0214802 1.18519 1.19481 1.18519 2.60303V12.158C1.18519 12.3152 1.12734 12.467 1.02178 12.5802C0.355114 13.2949 -0.0133703 14.2481 0.000370448 15.2625C0.0286297 17.3463 1.70371 19.0286 3.71849 18.9996C6.93011 18.9534 8.51456 14.945 6.31326 12.582C6.20719 12.4682 6.14815 12.3161 6.14815 12.158V11.0322H6.9093C7.22849 11.0322 7.503 10.7784 7.51786 10.4487C7.53371 10.0967 7.26274 9.80643 6.92593 9.80643H6.14815V7.2016H7.65004C7.96923 7.2016 8.24374 6.94778 8.2586 6.61804C8.27445 6.26604 8.00348 5.97579 7.66667 5.97579H6.14815V3.37096H8.40741C8.74423 3.37096 9.01519 3.08071 8.99934 2.72871C8.98448 2.39897 8.70997 2.14515 8.39078 2.14515ZM3.56778 16.6214C2.32282 16.5305 1.82819 14.8741 2.83874 14.0789C2.98652 13.9626 3.07408 13.7824 3.07408 13.5904V6.26114C3.07408 5.93101 3.31948 5.64705 3.63833 5.63172C3.97863 5.61533 4.25926 5.89562 4.25926 6.24394V13.5826C4.25926 13.7751 4.34245 13.96 4.49097 14.0761C5.57812 14.9257 4.92422 16.7204 3.56778 16.6214Z"
        fill={color ?? '#6B6B6B'}
      />
    </svg>
  );
});
