import { useMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { EDIT_SUBPATH } from '../constants';

interface Result {
  isEditable: boolean;
}

export function usePageEditability(): Result {
  const matchEditSubPath = useMatch(`*${EDIT_SUBPATH}`);
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    setIsEditable(!!matchEditSubPath);
  }, [matchEditSubPath]);

  return {
    isEditable,
  };
}
