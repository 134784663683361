import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from '@mui/material';

export function useDebouncedInput(
  // eslint-disable-next-line
  value: any,
  // eslint-disable-next-line
  onChange: (e: React.ChangeEvent<any>) => void,
  waitInterval?: number,
) {
  const [debouncedValue, setDebouncedValue] = useState<string>(value ?? '');

  useEffect(() => {
    setDebouncedValue(value ?? '');
  }, [value]);

  const onChangeDebouncedInternal = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange(e);
    }, waitInterval ?? 300),
    [],
  );

  const onChangeDebounced = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDebouncedValue(e.target.value);
    onChangeDebouncedInternal(e);
  };

  return { debouncedValue, onChangeDebounced };
}
