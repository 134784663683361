import { Note } from '../../../../../models';
import React from 'react';
import { PatientNote } from '../PatientNote';
import { StyleSheet, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { NoDataText } from '../../common/NoDataText';
import { SectionTitle } from '../../common/SectionTitle';
import { commonReactPdfStyles } from '../../commonReactPdfStyles';

interface Props {
  patientNotes: Note[];
}

export function PatientNotes({ patientNotes }: Props) {
  const { t } = useTranslation(['note', 'common']);

  const styles = StyleSheet.create({
    noteWrapper: {
      marginVertical: 10,
    },
  });

  return (
    <View style={commonReactPdfStyles.sectionView}>
      <SectionTitle title={t('note:title.list')} />
      {patientNotes.length > 0 ? (
        patientNotes.map((note) => (
          <View key={note.id} style={styles.noteWrapper}>
            <PatientNote note={note} />
          </View>
        ))
      ) : (
        <NoDataText />
      )}
    </View>
  );
}
