import { Button } from '../Button';
import { ReactComponent as ArrowRight } from '../../assets/icons/ArrowRight.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/ArrowLeft.svg';
import { MobileStepper } from '@mui/material';
import React from 'react';
import { useFormStepperStyles } from './styles';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { CustomTooltip } from '../CustomTooltip';

interface Props {
  stepsCount: number;
  activeStep: number;
  onBackClick: () => void;
  onNextClick: () => void;
}

export function FormStepper({ stepsCount, activeStep, onBackClick, onNextClick }: Props) {
  const classes = useFormStepperStyles();
  const { t } = useTranslation('common');

  const { isValid, submitForm } = useFormikContext();

  const maxStepIndex = stepsCount - 1;
  const isLastStep = activeStep === maxStepIndex;

  let nextButton = (
    <Button
      text={t('next')}
      onClick={onNextClick}
      disabled={!isValid || isLastStep}
      color="primary"
      endIcon={<ArrowRight />}
    />
  );

  let submitButton = (
    <Button text={t('submit')} disabled={!isValid} color="primary" onClick={submitForm} />
  );

  if (!isValid) {
    nextButton = (
      <CustomTooltip title={t('enterMissingData')} placement="top">
        <span>{nextButton}</span>
      </CustomTooltip>
    );

    submitButton = (
      <CustomTooltip title={t('enterMissingData')} placement="top">
        <span>{submitButton}</span>
      </CustomTooltip>
    );
  }

  return (
    <MobileStepper
      variant="dots"
      classes={{ root: classes.stepperRoot }}
      steps={stepsCount}
      position="static"
      activeStep={activeStep}
      nextButton={isLastStep ? submitButton : nextButton}
      backButton={
        <Button
          text={t('back')}
          onClick={onBackClick}
          disabled={activeStep === 0}
          color="primary"
          startIcon={<ArrowLeft />}
        />
      }
    />
  );
}
