// PATIENTS
import { Functionality } from '../../models';

export const PATIENTS_READ_POLICY: Functionality[] = [
  'PATIENTS_READ',
  'PATIENTS_ASSIGNED_TO_MEDIC_READ',
  'PATIENTS_SELF_READ',
];

export const PATIENTS_UPDATE_POLICY: Functionality[] = [
  'PATIENTS_UPDATE',
  'PATIENTS_ASSIGNED_TO_MEDIC_UPDATE',
];

export const PATIENTS_DELETE_POLICY: Functionality[] = [
  'PATIENTS_DELETE',
  'PATIENTS_ASSIGNED_TO_MEDIC_DELETE',
];
