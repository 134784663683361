import * as Yup from 'yup';
import React, { ReactNode } from 'react';
import { UserForm } from './UserForm/UserForm';
import { Optional } from '../../../models';
import { InstallationMemberData } from '../../../models/InstallationMemberData';
import { MemberForm } from './MemberForm/MemberForm';
import { ConfirmationForm } from './ConfirmationForm/ConfirmationForm';
import { Form, SubmitFormCompletedHandler, SubmitFormHandler } from '../../../components/Form';
import { UserFormValuesSchema } from './UserForm';
import { MemberFormValuesSchema } from './MemberForm';
import { Box } from '@mui/material';
import { RevalidateOnSchemaChange } from '../../../components/RevalidateOnSchemaChange';
import { FormStepper } from '../../../components/FormStepper';
import { ScaseLogoWithText } from '../../../components/Icons';
import { useTranslation } from 'react-i18next';
import { PasswordForm, PasswordValuesSchema } from './PasswordForm';
import { useInstallationStepperFormStyles } from './styles';
import { ConfigurationValuesSchema } from './ConfirmationForm';

export interface InstallationFormValues
  extends UserFormValues,
    PasswordFormValues,
    MemberFormValues,
    ConfigurationFormValues {}

export interface UserFormValues {
  name: string;
  surname: string;
  email: string;
  birthday: Date;
  chassisId: Optional<string>;
}

export interface PasswordFormValues {
  newPassword: string;
  confirmPassword: string;
}

export interface MemberFormValues {
  installationMembers: InstallationMemberData[];
}

export interface ConfigurationFormValues {
  bussinesCondition: boolean;
  gdpr: boolean;
}

type Step = {
  title: string;
  subtitle?: string;
  content: ReactNode;
  validationSchema: Yup.SchemaOf<
    UserFormValues | PasswordFormValues | MemberFormValues | ConfigurationFormValues
  >;
};

interface InstallationFormStepperProps {
  chassisId: string;
  email: string;
  onSubmit: SubmitFormHandler<InstallationFormValues>;
  onSubmitCompleted: SubmitFormCompletedHandler;
  onSubmitError: () => void;
}

export function InstallationStepperForm({
  chassisId,
  email,
  onSubmit,
  onSubmitCompleted,
  onSubmitError,
}: InstallationFormStepperProps) {
  const { t } = useTranslation('installation');
  const classes = useInstallationStepperFormStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const steps: Step[] = [
    {
      title: t('installation'),
      subtitle: t('createUserStep.subtitle'),
      content: <UserForm />,
      validationSchema: UserFormValuesSchema(),
    },
    {
      title: t('installation'),
      subtitle: t('passwordStep.subtitle'),
      content: <PasswordForm />,
      validationSchema: PasswordValuesSchema(),
    },
    {
      title: t('installation'),
      subtitle: t('memberStep.subtitle'),
      content: <MemberForm />,
      validationSchema: MemberFormValuesSchema(),
    },
    {
      title: t('installation'),
      subtitle: t('configurationStep.subtitle'),
      content: <ConfirmationForm />,
      validationSchema: ConfigurationValuesSchema(),
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const defaultFormValues: InstallationFormValues = {
    name: '',
    surname: '',
    birthday: new Date(),
    email: email,
    chassisId: chassisId,
    newPassword: '',
    confirmPassword: '',
    installationMembers: [],
    gdpr: false,
    bussinesCondition: false,
  };

  const currentStepValidationSchema = steps[activeStep].validationSchema;
  return (
    <Form<
      InstallationFormValues,
      never,
      UserFormValues | PasswordFormValues | MemberFormValues | ConfigurationFormValues
    >
      initialValues={defaultFormValues}
      validationSchema={currentStepValidationSchema}
      onSubmit={onSubmit}
      onSubmitCompleted={onSubmitCompleted}
      onSubmitError={onSubmitError}
      formMode={'editable'}
    >
      <>
        <Box>
          <RevalidateOnSchemaChange<
            UserFormValues | PasswordFormValues | MemberFormValues | ConfigurationFormValues
          >
            validationSchema={currentStepValidationSchema}
          />
          <Box display="flex" flexDirection="column">
            <ScaseLogoWithText />
          </Box>
          <Box className={classes.headerText}>
            <Box display="flex" flexDirection="column">
              <Box className={classes.title}>{steps[activeStep].title}</Box>
              <Box className={classes.subtitle}>{steps[activeStep].subtitle}</Box>
            </Box>
          </Box>
          <Box>{steps[activeStep].content}</Box>
          <Box></Box>
          <FormStepper
            activeStep={activeStep}
            stepsCount={steps.length}
            onBackClick={handleBack}
            onNextClick={handleNext}
          />
        </Box>
      </>
    </Form>
  );
}
