import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
    border: 'solid 2px #f3f4f7',
    padding: theme.spacing(0.75, 0.5),
    transition: theme.transitions.create('border', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest,
    }),
    '&:focus-within': {
      border: 'solid 2px #0065f0',
    },
  },
  root: {
    '& .MuiTextField-root': {
      margin: 0,
      padding: theme.spacing(0, 0, 0, 2),
    },
  },
  option: {
    padding: theme.spacing(1.5, 1.25),
    margin: theme.spacing(0, 1),
    borderRadius: theme.spacing(0.5),
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.71,
    letterSpacing: -0.14,
    '&[data-focus="true"], &:hover, &:focus': {
      backgroundColor: '#e5eefd66',
      color: '#054ac7F2',
      textShadow: '0.5px 0 0 currentColor',
    },
    '&[aria-selected="true"]': {
      backgroundColor: '#e5eefd',
      color: '#054ac7',
      textShadow: '-0.5px 0 0 currentColor',
    },
  },
  input: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.71,
    letterSpacing: -0.14,
  },
}));
