import { GridCellParams } from '@mui/x-data-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Department } from '../../../models';
import {
  DeleteCompletedHandler,
  DeleteHandler,
  Grid,
  GridColumnDefinition,
  RowClickHandler,
} from '../../Grid';
import { EDIT_SUBPATH } from '../../../constants';

interface Props {
  data: Department[];
  onDelete?: DeleteHandler;
  onDeleteCompleted?: DeleteCompletedHandler<Department>;
  onRowClick?: RowClickHandler<Department>;
}

export function DepartmentsGrid({ data, onDelete, onDeleteCompleted, onRowClick }: Props) {
  const { t } = useTranslation('department');
  const columns: GridColumnDefinition<Department>[] = [
    {
      field: 'name',
      headerName: t('name.label'),
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridCellParams) => <strong>{params.row.name}</strong>,
    },
    {
      field: 'building',
      headerName: t('building.label'),
      minWidth: 250,
      flex: 2,
    },
    {
      field: 'floor',
      headerName: t('floor.label'),
      minWidth: 250,
      flex: 2,
    },
    {
      field: 'medicAuthentication',
      headerName: t('medicAuthentication.label'),
      type: 'boolean',
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridCellParams) =>
        params.value ? <strong>Yes</strong> : <strong>No</strong>,
    },
  ];

  function getEditPath(id: number) {
    return `/departments/${id}/${EDIT_SUBPATH}`;
  }

  return (
    <Grid
      columns={columns}
      rows={data}
      getEditPath={getEditPath}
      onDelete={onDelete}
      onDeleteCompleted={onDeleteCompleted}
      onRowClick={onRowClick}
    />
  );
}
