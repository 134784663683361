import React from 'react';
import { Optional, PatientDevicePairing, SexEnum } from '../../../models';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { useScrollTrigger } from '../../../hooks';
import { OnlineStatusCircle } from '../../StatusCircle';
import { getCurrentDatetimeLocale } from '../../../i18n';
import { CustomTooltip } from '../../CustomTooltip';
import { formatDistance } from 'date-fns';
import { DATE_TIME_LOCALE_WITHOUT_SECONDS } from '../../../constants';

interface Props {
  patientDevicePairing: PatientDevicePairing | undefined;
  sectionTitleClass: string;
  patientSex: Optional<SexEnum>;
}

export function PatientHeaderDevicePairingInfo({
  patientDevicePairing,
  patientSex,
  sectionTitleClass,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation('patient');

  const scrollTrigger = useScrollTrigger();

  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const showTitle = isTabletOrMobile || (!isTabletOrMobile && !scrollTrigger);

  function getDeviceExternalIdDisplayValue() {
    if (!patientDevicePairing || !patientDevicePairing.deviceExternalId) {
      return t('deviceExternalId.notPaired', { context: patientSex });
    }

    return patientDevicePairing.deviceChassisId;
  }

  function getExpectedEndDateDisplayValue() {
    if (!patientDevicePairing) {
      return t('scaseEndDate.notPaired', { context: patientSex });
    }
    if (!patientDevicePairing.expectedEndDate) {
      return t('scaseEndDate.noLimit');
    }

    return new Date(patientDevicePairing.expectedEndDate).toLocaleDateString();
  }

  function getLastSeenDisplayValue() {
    if (!patientDevicePairing) {
      return t('lastSeen.notPaired', { context: patientSex });
    }
    if (!patientDevicePairing.latestOnline) {
      return '';
    }

    return new Date(patientDevicePairing.latestOnline).toLocaleString(
      [],
      DATE_TIME_LOCALE_WITHOUT_SECONDS,
    );
  }

  function getLastSeenDisplayValueShort() {
    if (!patientDevicePairing) {
      return t('lastSeen.notPaired', { context: patientSex });
    }
    if (!patientDevicePairing.latestOnline) {
      return '-';
    }

    const latest = new Date(patientDevicePairing.latestOnline);

    if (isDeviceOnline()) {
      return t('lastSeen.now');
    }
    return formatDistance(latest, new Date(), {
      addSuffix: true,
      locale: getCurrentDatetimeLocale(),
    });
  }

  function isDeviceOnline() {
    if (!patientDevicePairing) {
      return false;
    }
    if (!patientDevicePairing.latestOnline) {
      return false;
    }

    const latest = new Date(patientDevicePairing.latestOnline);
    const onlineNow = 20 * 60 * 1000; // 20 minutes
    const timeDiff = Date.now() - latest.getTime();
    return timeDiff < onlineNow;
  }

  if (!patientDevicePairing) {
    return <></>;
  }

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.endDateAndIdWrapper}>
        <Box className={classes.sectionWrapper}>
          {showTitle && (
            <Box className={`${sectionTitleClass} ${classes.sectionTitle}`}>
              {t('deviceExternalId.label')}
            </Box>
          )}
          <Box className={classes.sectionValue}>{getDeviceExternalIdDisplayValue()}</Box>
        </Box>
        <Box className={classes.sectionWrapper}>
          {showTitle && (
            <Box className={`${sectionTitleClass} ${classes.sectionTitle}`}>
              {t('scaseEndDate.labelShort')}
            </Box>
          )}
          <Box className={classes.sectionValue}>{getExpectedEndDateDisplayValue()}</Box>
        </Box>
      </Box>
      <Box className={classes.sectionWrapper}>
        {showTitle && (
          <Box className={`${sectionTitleClass} ${classes.sectionTitle}`}>
            {t('lastSeen.label')}
          </Box>
        )}
        <CustomTooltip title={getLastSeenDisplayValue()} placement="bottom-end">
          <Box display="flex" alignItems="center">
            <Box className={classes.sectionValue} marginRight="5px">
              {getLastSeenDisplayValueShort()}
            </Box>
            <OnlineStatusCircle isOnline={isDeviceOnline()} />
          </Box>
        </CustomTooltip>
      </Box>
    </Box>
  );
}
