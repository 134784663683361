import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const SYSTEM_EVENT_ICON_SIZE = 32;
const ACTION_NAME_COLUMN_SIZE = 200;
const ACTION_TIMESTAMP_COLUMN_SIZE = 140;

export const useSystemEventListItemContainerStyles = makeStyles((theme: Theme) => ({
  container: {
    boxShadow:
      '0 0.3125rem 0.625rem rgba(0, 0, 0, 0.05), 0 0.0625rem 0.3125rem rgba(0, 0, 0, 0.15)',
    borderRadius: 30,
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    maxWidth: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
      rowGap: theme.spacing(1),
      alignItems: 'flex-start',
    },
  },
}));

export const useSystemEventListItemActionNameStyles = makeStyles((theme: Theme) => ({
  container: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: ACTION_NAME_COLUMN_SIZE,
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '100%',
    },
  },
  typography: {
    fontWeight: 700,
  },
}));

export const useSystemEventListItemActionTimestampStyles = makeStyles((theme: Theme) => ({
  typography: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: ACTION_TIMESTAMP_COLUMN_SIZE,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
      margin: 0,
    },
  },
}));

export const useSystemEventListItemEntityPropertyValueStyles = makeStyles(() => ({
  propertyNameTypography: {
    fontWeight: 700,
  },
  propertyValueMaxWidth: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
  },
}));

export interface SystemEventListSubItemStyleProps {
  unlimitedWidth?: boolean;
}

export const useSystemEventListSubItemStyles = makeStyles<Theme, SystemEventListSubItemStyleProps>(
  (theme: Theme) => ({
    container: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      overflow: 'auto',
      maxWidth: ({ unlimitedWidth }) => (unlimitedWidth ? '100%' : 150),
    },
  }),
);

export const useSystemEventListHeaderStyles = makeStyles((theme: Theme) => ({
  container: {
    boxShadow:
      '0 0.3125rem 0.625rem rgba(0, 0, 0, 0.05), 0 0.0625rem 0.3125rem rgba(0, 0, 0, 0.15)',
    borderRadius: 30,
    margin: theme.spacing(1),
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  actionNameColumn: {
    width: SYSTEM_EVENT_ICON_SIZE + ACTION_NAME_COLUMN_SIZE + theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  actionTimestampColumn: {
    width: ACTION_TIMESTAMP_COLUMN_SIZE,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  actionDataColumn: {
    marginLeft: theme.spacing(2),
  },
}));

export const useSystemEventListItemActionStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
      rowGap: theme.spacing(1),
      alignItems: 'flex-start',
    },
  },
  actionNameAndDatetimeContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 0),
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      rowGap: theme.spacing(1),
      alignItems: 'flex-start',
    },
  },
}));

export const useSystemEventListItemValuesStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '100%',
    [theme.breakpoints.down('lg')]: {
      columnGap: theme.spacing(3),
      padding: theme.spacing(0, 0),
      '&>div': {
        margin: 0,
      },
    },
    [theme.breakpoints.down('md')]: {
      rowGap: theme.spacing(1),
      alignItems: 'flex-start',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      rowGap: theme.spacing(1),
      alignItems: 'flex-start',
    },
  },
}));
