import { Department } from '../../models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTopBarDetailed } from '../PageTopBarDetailed';

interface Props {
  department: Department;
  lastUpdated: Date | null;
}

export function DepartmentHeader({ department, lastUpdated }: Props) {
  const { t } = useTranslation('department');
  return (
    <PageTopBarDetailed
      entityName={t('title.department', { name: department.name, context: department.building })}
      lastUpdated={lastUpdated}
    />
  );
}
