import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

interface StyleProps {
  isDrawerOpen: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  navigationBox: {
    background: theme.palette.grey.A100,
    color: theme.palette.primary.A400,
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'normal',
    height: '100%',
    overflow: 'hidden',
    borderRight: '3px solid #f8f9fa',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      margin: '0 !important',
      width: '100%',
      borderRadius: 0,
    },
  },
  headerAndNavigationContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
  },
  navigationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.palette.primary.main}B3 ${theme.palette.grey.A100}`,
    scrollbarGutter: 'always',
    '&::-webkit-scrollbar': {
      width: 3,
      border: `1px solid ${theme.palette.grey.A100}`,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.grey.A100,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `${theme.palette.primary.A100}B3`,
      borderRadius: 20,
    },
  },
  navigationFooterWrapper: {
    position: 'sticky',
    bottom: 0,
  },
}));
