import { useStyles } from './styles';
import { BottomNavigation, BottomNavigationAction, Button, Paper } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as DashboardNavIcon } from '../../assets/icons/DashboardNavIcon.svg';
import { ReactComponent as PatientNavIcon } from '../../assets/icons/PatientNavIcon.svg';
import { ReactComponent as MedicNavIcon } from '../../assets/icons/MedicNavIcon.svg';
import { ReactComponent as MenuNavIcon } from '../../assets/icons/MenuNavIcon.svg';
import { Navigation } from '../../types';
import { useDashboardMenuContext } from '../../context/dashboardMenu';
import { userHasFunctionality } from '../RequireFunctionality';
import {
  DAILY_PLAN_TEMPLATES_PATH,
  MEDICS_PATH,
  PATIENTS_PATH,
  QUESTIONNAIRES_PATH,
} from '../../constants';
import { getTabsValue } from '../../utils';
import { PATIENTS_READ_POLICY } from '../../constants/Policies';

export function BottomNavigationMobile() {
  const nav: Navigation = {
    dashboard: {
      tab: '/',
      route: {
        path: '/',
      },
      subRoutes: {},
    },
    patients: {
      tab: PATIENTS_PATH.primary,
      route: {
        path: PATIENTS_PATH.primary,
      },
      subRoutes: {},
    },
    medics: {
      tab: MEDICS_PATH.primary,
      route: {
        path: MEDICS_PATH.primary,
      },
      subRoutes: {},
    },
    questionnaires: {
      tab: QUESTIONNAIRES_PATH.primary,
      route: {
        path: QUESTIONNAIRES_PATH.primary,
      },
      subRoutes: {},
    },
    alarmGroupsTemplates: {
      tab: DAILY_PLAN_TEMPLATES_PATH.primary,
      route: {
        path: DAILY_PLAN_TEMPLATES_PATH.primary,
      },
      subRoutes: {},
    },
  };

  const classes = useStyles();
  const location = useLocation();
  const { handleDrawerToggle } = useDashboardMenuContext();

  return (
    <Paper className={classes.bottomNavWrapper} elevation={3}>
      <BottomNavigation
        value={getTabsValue(nav, location.pathname, false)}
        classes={{ root: classes.bottomNavRoot }}
      >
        <BottomNavigationAction
          component={Link}
          to={nav.dashboard.tab}
          icon={<DashboardNavIcon />}
          value={nav.dashboard.tab}
          classes={{
            root: classes.bottomNavAction,
            label: classes.bottomNavActionLabel,
            selected: classes.bottomNavActionSelected,
          }}
        />
        {userHasFunctionality(PATIENTS_READ_POLICY) && (
          <BottomNavigationAction
            component={Link}
            to={nav.patients.tab}
            icon={<PatientNavIcon />}
            value={nav.patients.tab}
            classes={{
              root: classes.bottomNavAction,
              label: classes.bottomNavActionLabel,
              selected: classes.bottomNavActionSelected,
            }}
          />
        )}
        {userHasFunctionality('MEDICS_READ') && (
          <BottomNavigationAction
            component={Link}
            to={nav.medics.tab}
            icon={<MedicNavIcon />}
            value={nav.medics.tab}
            classes={{
              root: classes.bottomNavAction,
              label: classes.bottomNavActionLabel,
              selected: classes.bottomNavActionSelected,
            }}
          />
        )}
        <BottomNavigationAction
          component={Button}
          onClick={handleDrawerToggle}
          icon={<MenuNavIcon />}
          value={'menu'}
          classes={{
            root: classes.bottomNavAction,
            label: classes.bottomNavActionLabel,
            selected: classes.bottomNavActionSelected,
          }}
        />
      </BottomNavigation>
    </Paper>
  );
}
