import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

interface StyleProps {
  isDrawerOpen: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  colorWhite: {
    color: theme.palette.common.white,
  },
  colorPrimaryDark: {
    color: theme.palette.primary.dark,
  },
  dividerLight: {
    backgroundColor: '#eaebed',
  },
  listItemSelected: {
    backgroundColor: `rgba(102, 154, 247, 0.06) !important`,
    borderRadius: theme.typography.pxToRem(4),
    color: theme.palette.primary.main,
  },
  menuWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: theme.typography.pxToRem(22),
    padding: ({ isDrawerOpen }) => theme.spacing(0.5, isDrawerOpen ? 2 : 1),
  },
  menuSubheader: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 'bold',
    letterSpacing: theme.typography.pxToRem(0.6),
    width: '100%',
    minHeight: '30px',
    padding: theme.spacing(1, 4),
    color: '#b8bed1',
  },
}));
