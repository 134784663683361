import { GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Medic } from '../../../models'; //Qualification
import {
  DeleteCompletedHandler,
  DeleteHandler,
  Grid,
  GridColumnDefinition,
  RowClickHandler,
} from '../../Grid';
import { useFunctionalityMode } from '../../../context/functionality';
import {
  ResetOnetimePasswordButton,
  ResetOnetimePasswordCompletedHandler,
  ResetOnetimePasswordHandler,
} from '../../ResetOnetimePasswordButton';
import { ResetPasscodeButton } from '../../ResetPasscodeButton';
import { ResetPasswordCompletedHandler, ResetPasswordHandler } from '../../ResetPasswordButton';
import { EDIT_SUBPATH } from '../../../constants';
import { DepartmentCellRenderer } from '../Renderer';
import { DeactivateButton } from '../../DeactivateButton';

interface Props {
  data: Medic[];
  onDelete?: DeleteHandler;
  onDeleteCompleted?: DeleteCompletedHandler<Medic>;
  onResetOnetimePassword?: ResetOnetimePasswordHandler;
  onResetOnetimePasswordCompleted?: ResetOnetimePasswordCompletedHandler;
  onResetPasscode?: ResetPasswordHandler;
  onResetPasscodeCompleted?: ResetPasswordCompletedHandler;
  onRowClick?: RowClickHandler<Medic>;
  onDeactivate?: (medicId: number) => Promise<void>;
  onDeactivateCompleted?: () => Promise<void>;
}

export function MedicsGrid({
  data,
  onDelete,
  onDeleteCompleted,
  onResetOnetimePassword,
  onResetOnetimePasswordCompleted,
  onResetPasscode,
  onResetPasscodeCompleted,
  onRowClick,
  onDeactivate,
  onDeactivateCompleted,
}: Props) {
  const { t } = useTranslation('medic');
  const columns: GridColumnDefinition<Medic>[] = [
    {
      field: 'firstName',
      headerName: t('firstName.label'),
      minWidth: 100,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <strong>{params.value}</strong>,
    },
    {
      field: 'lastName',
      headerName: t('lastName.label'),
      minWidth: 100,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => <strong>{params.value}</strong>,
    },

    {
      field: 'email',
      headerName: t('email.label'),
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'departments',
      headerName: t('departments.label'),
      flex: 2,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: DepartmentCellRenderer,
    },
    {
      field: 'phone',
      headerName: t('phone.label'),
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'username',
      headerName: t('username.label'),
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'isActive',
      headerName: t('isActive.label'),
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const medic = params.row as Medic;
        return (
          <DeactivateButton
            medicId={medic.id}
            state={medic.isActive}
            onDeactivate={onDeactivate}
            onDeactivateCompleted={onDeactivateCompleted}
          />
        );
      },
    },
  ];

  const { hasUpdateFunctionality } = useFunctionalityMode();

  if (onResetOnetimePassword && hasUpdateFunctionality) {
    columns.push({
      field: 'onetimePassword',
      headerName: t('onetimePassword.label'),
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const medicId = params.id as number;
        return (
          <ResetOnetimePasswordButton
            medicId={medicId}
            onResetOnetimePassword={onResetOnetimePassword}
            onResetOnetimePasswordCompleted={onResetOnetimePasswordCompleted}
          />
        );
      },
    });
  }

  if (onResetPasscode && hasUpdateFunctionality) {
    columns.push({
      field: 'passcode',
      headerName: t('passcode.label'),
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const medicId = params.id as number;
        return (
          <ResetPasscodeButton
            buttonText={t('passcode.resetButton')}
            dialogTitle={t('passcode.label')}
            medicId={medicId}
            color="secondary"
            onResetPasscode={onResetPasscode}
            onResetPasscodeCompleted={onResetPasscodeCompleted}
          />
        );
      },
    });
  }

  function getEditPath(id: number) {
    return `/medics/${id}/${EDIT_SUBPATH}`;
  }

  return (
    <Grid
      columns={columns}
      rows={data}
      getEditPath={getEditPath}
      onDelete={onDelete}
      onDeleteCompleted={onDeleteCompleted}
      onRowClick={onRowClick}
    />
  );
}
