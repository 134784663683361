import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useContactPersonsInputStyles = makeStyles((theme: Theme) => ({
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    columnGap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));
