import { Box, Typography } from '@mui/material';
import { SystemEvent } from '../../../models';
import { useSystemEventListItemActionNameStyles } from './styles';
import { DATE_TIME_LOCALE_WITHOUT_SECONDS } from '../../../constants';

interface Props {
  actionName: string;
  systemEvent: SystemEvent;
}

export function SystemEventListItemActionName({ actionName, systemEvent }: Props) {
  const classes = useSystemEventListItemActionNameStyles();

  return (
    <Box display="flex" flexDirection="column" className={classes.container}>
      <Typography color="primary" variant="h6" className={classes.typography}>
        {actionName}
      </Typography>
      {systemEvent.entityTimestamp != null && (
        <Typography color="textSecondary" variant="caption">
          {new Date(systemEvent.entityTimestamp).toLocaleString(
            [],
            DATE_TIME_LOCALE_WITHOUT_SECONDS,
          )}
        </Typography>
      )}
    </Box>
  );
}
