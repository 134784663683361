import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { ProgressIndicatorStyleProps } from './ProgresIndicator';

export const useProgressIndicatorStyles = makeStyles<Theme, ProgressIndicatorStyleProps>(() => ({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: ({ height }) => height || 400,
  },
}));
