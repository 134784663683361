import { createContext, useContext } from 'react';
import { FunctionalityMode } from './types';

export const FunctionalityModeContext = createContext<FunctionalityMode>({
  hasCreateFunctionality: false,
  hasReadFunctionality: false,
  hasUpdateFunctionality: false,
  hasDeleteFunctionality: false,
});
export const useFunctionalityMode = () => useContext(FunctionalityModeContext);
