import React, { ChangeEvent, Ref, useEffect, useState } from 'react';
import { Box, IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from '@mui/material';

import { ReactComponent as CloseIcon } from '../../assets/icons/CloseIcon.svg';
import { ReactComponent as CloseIconWithBackground } from '../../assets/icons/CloseIconWithBackground.svg';
import { ReactComponent as ListIcon } from '../../assets/icons/ListIcon.svg';
import { ReactComponent as ListIconWithBackground } from '../../assets/icons/ListIconWithBackground.svg';
import { useDebouncedInput } from '../../hooks';
import qs from 'query-string';
import { URL_SEARCH } from '../../constants';
import { areUrlSearchParamsEqual } from '../../utils';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

export interface PageTopBarSearchRef {
  handleRemoveSearchText(): void;
}

interface Props {
  onSearch?: (searchedText: string) => void;
}

export const PageTopBarSearch = React.forwardRef<PageTopBarSearchRef, Props>(
  ({ onSearch }: Props, ref: Ref<PageTopBarSearchRef>) => {
    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const { t } = useTranslation('common');
    const [searchedText, setSearchedText] = useState('');
    const { debouncedValue, onChangeDebounced } = useDebouncedInput(
      searchedText,
      searchTextOnChange,
    );
    const [isTextFieldSelected, setIsTextFieldSelected] = useState(false);
    const isRemoveSearchTextButtonDisabled = searchedText === '';
    const searchEnabled = !!onSearch;

    React.useImperativeHandle(ref, () => ({
      handleRemoveSearchText: () => {
        handleRemoveSearchText();
      },
    }));

    function removeSearchFromUrl() {
      const searchQuery = qs.parse(location.search, { arrayFormat: 'comma' });
      delete searchQuery[URL_SEARCH];
      navigate({ search: qs.stringify(searchQuery, { arrayFormat: 'comma' }) }, { replace: true });
    }

    function searchTextOnChange(event: ChangeEvent<HTMLInputElement>) {
      const eSearchedText = event.target.value;
      setSearchedText(eSearchedText);
      if (eSearchedText === '') {
        removeSearchFromUrl();
      } else {
        const searchQuery = qs.parse(location.search, { arrayFormat: 'comma' });
        searchQuery[URL_SEARCH] = eSearchedText;

        const currentSearchQuery = qs.parse(qs.pick(location.search, [URL_SEARCH]), {
          arrayFormat: 'comma',
        });

        // change url only if it changes
        if (!areUrlSearchParamsEqual(currentSearchQuery[URL_SEARCH], searchQuery[URL_SEARCH])) {
          navigate(
            { search: qs.stringify(searchQuery, { arrayFormat: 'comma' }) },
            { replace: true },
          );
        }
      }
    }

    function handleRemoveSearchText() {
      setSearchedText('');
      removeSearchFromUrl();
    }

    useEffect(() => {
      if (onSearch) {
        onSearch(searchedText);
      }
    }, [searchedText]);

    useEffect(() => {
      if (searchEnabled) {
        // handle provided params on load
        const query = qs.parse(qs.pick(location.search, [URL_SEARCH]), {
          arrayFormat: 'comma',
        });

        if (URL_SEARCH in query) {
          const searchQuery = query[URL_SEARCH];
          if (searchQuery !== null) {
            setSearchedText(String(searchQuery));
          }
        } else {
          setSearchedText('');
        }
      }
    }, [location.search]);

    return (
      <Box className={classes.searchWrapper}>
        <TextField
          placeholder={t('searchPlaceholder')}
          fullWidth={true}
          value={debouncedValue}
          onChange={onChangeDebounced}
          onSelect={() => setIsTextFieldSelected(true)}
          onBlur={() => setIsTextFieldSelected(false)}
          autoFocus={true}
          classes={{
            root: isTabletOrMobile
              ? classes.searchTextFieldRoot
              : classes.searchTextFieldRootDesktop,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  className={classes.removeSearchTextButton}
                  disabled={isRemoveSearchTextButtonDisabled}
                  onClick={handleRemoveSearchText}
                  disableRipple={true}
                  size="large"
                >
                  {isTabletOrMobile ? (
                    !isRemoveSearchTextButtonDisabled ? (
                      <CloseIcon />
                    ) : (
                      <ListIcon />
                    )
                  ) : isTextFieldSelected || !isRemoveSearchTextButtonDisabled ? (
                    <CloseIconWithBackground />
                  ) : (
                    <ListIconWithBackground />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  },
);
