import { useStyles } from './styles';
import { Link } from 'react-router-dom';
import { sendAmplitudeEvent } from '../../../utils/amplitude';
import { Page } from '../../../components/Page';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../constants';
import { ForgottenPasswordForm, ForgottenPasswordFormValues } from './ForgottenPasswordForm';
import { ApiRoutes, makeApiRequest } from '../../../services/api';
import React from 'react';
import { useEntityActionSnackbar } from '../../../hooks';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';

export function ForgottenPasswordPage() {
  const { t } = useTranslation(['forgottenPassword', 'common']);
  const classes = useStyles();
  const { enqueueSnackbar } = useEntityActionSnackbar();

  function handleSubmit(values: ForgottenPasswordFormValues) {
    return makeApiRequest('POST', ApiRoutes.ForgottenPassword, values);
  }

  async function handleSubmitCompleted() {
    enqueueSnackbar(t('forgottenPassword:emailSentConfirmation'));
    sendAmplitudeEvent('User forgotten password');
  }

  return (
    <Page noPaper={true}>
      <Box
        display="flex"
        width="100%"
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box className={classes.forgottenPasswordWrapper}>
          <Box className={classes.textsWrapper}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box className={classes.iconWrapper}>
                <LockTwoToneIcon fontSize={'inherit'} />
              </Box>
            </Box>
            <Typography align="center" variant="h5" className={classes.title}>
              {t('forgottenPassword:title')}
            </Typography>
            <Typography align="center" variant="body2" className={classes.subtitle}>
              {t('forgottenPassword:subtitle')}
            </Typography>
          </Box>
          <ForgottenPasswordForm
            onSubmit={handleSubmit}
            onSubmitCompleted={handleSubmitCompleted}
          />
        </Box>
        <Link to={ROUTES.LOGIN.path as string} className={classes.backToLoginLink}>
          {t('forgottenPassword:backToLoginLink')}
        </Link>
      </Box>
    </Page>
  );
}
