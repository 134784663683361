import { Box, Divider, List } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useStyles } from './styles';
import { RequireFunctionality } from '../../RequireFunctionality';
import { useTranslation } from 'react-i18next';
import { MouseEventHandler } from 'react';
import { ReactComponent as MedicNavIcon } from './../../../assets/icons/MedicNavIcon.svg';
import { ReactComponent as DailyPlanNavIcon } from './../../../assets/icons/DailyPlanNavIcon.svg';
import { ReactComponent as QuestionnaireNavIcon } from './../../../assets/icons/QuestionnaireNavIcon.svg';
import { ReactComponent as DashboardNavIcon } from './../../../assets/icons/DashboardNavIcon.svg';
import { ReactComponent as PatientNavIcon } from './../../../assets/icons/PatientNavIcon.svg';
import { ReactComponent as DeviceNavIcon } from './../../../assets/icons/DeviceNavIcon.svg';
import { ReactComponent as DepartmentNavIcon } from './../../../assets/icons/DepartmentNavIcon.svg';
import { ReactComponent as OrganizationNavIcon } from './../../../assets/icons/OrganizationNavIcon.svg';
import { useDashboardMenuContext } from '../../../context/dashboardMenu';
import { DashboardNavItem } from '../NavItem';
import {
  DAILY_PLAN_TEMPLATES_PATH,
  CHANGE_LOG_PATH,
  DEPARTMENTS_PATH,
  DEVICES_PATH,
  MEDICS_PATH,
  ORGANIZATIONS_PATH,
  PATIENTS_PATH,
  QUESTIONNAIRES_PATH,
  GROUPS_PATH,
} from '../../../constants';
import { isPathSelected } from '../../../utils';
import { useRequireFunctionality } from '../../../hooks';
import { PATIENTS_READ_POLICY } from '../../../constants/Policies';

interface Props {
  onNavigationListItemClick?: MouseEventHandler;
}

export function DashboardNavigation({ onNavigationListItemClick }: Props) {
  const { pathname } = useLocation();
  const { t } = useTranslation('navigation');
  const { isDrawerOpen } = useDashboardMenuContext();
  const { checkHasFunctionality } = useRequireFunctionality();
  const classes = useStyles({ isDrawerOpen });

  return (
    <List>
      <Box className={classes.menuWrapper}>
        {checkHasFunctionality(['CHANGE_LOGS_READ']) && (
          <>
            <DashboardNavItem
              to={CHANGE_LOG_PATH.primary}
              text={t('sidebar.dashboard')}
              selected={isPathSelected(CHANGE_LOG_PATH, pathname)}
              onClick={onNavigationListItemClick}
              icon={<DashboardNavIcon />}
              selectedClassName={classes.listItemSelected}
              desktopDrawerOpen={isDrawerOpen}
            />
          </>
        )}
        <RequireFunctionality functionality={PATIENTS_READ_POLICY}>
          <DashboardNavItem
            to={PATIENTS_PATH.primary}
            text={t('sidebar.patients')}
            selected={isPathSelected(PATIENTS_PATH, pathname)}
            onClick={onNavigationListItemClick}
            icon={<PatientNavIcon />}
            selectedClassName={classes.listItemSelected}
            desktopDrawerOpen={isDrawerOpen}
          />
        </RequireFunctionality>
        <RequireFunctionality functionality="MEDICS_READ">
          <DashboardNavItem
            to={MEDICS_PATH.primary}
            text={t('sidebar.medics')}
            selected={isPathSelected(MEDICS_PATH, pathname)}
            onClick={onNavigationListItemClick}
            icon={<MedicNavIcon />}
            selectedClassName={classes.listItemSelected}
            desktopDrawerOpen={isDrawerOpen}
          />
        </RequireFunctionality>
      </Box>
      {checkHasFunctionality(['DAILY_PLAN_TEMPLATES_READ', 'QUESTIONNAIRE_TEMPLATES_READ']) && (
        <>
          <Box mb={1} mt={3}>
            <Divider variant="fullWidth" className={classes.dividerLight} />
          </Box>
          <Box className={classes.menuSubheader}>
            <Box>{isDrawerOpen && t('sidebar.templates')}</Box>
          </Box>
          <Box className={classes.menuWrapper}>
            <RequireFunctionality functionality="DAILY_PLAN_TEMPLATES_READ">
              <DashboardNavItem
                to={DAILY_PLAN_TEMPLATES_PATH.primary}
                text={t('sidebar.dailyPlanTemplates')}
                selected={isPathSelected(DAILY_PLAN_TEMPLATES_PATH, pathname)}
                onClick={onNavigationListItemClick}
                icon={<DailyPlanNavIcon />}
                selectedClassName={classes.listItemSelected}
                desktopDrawerOpen={isDrawerOpen}
              />
            </RequireFunctionality>
            <RequireFunctionality functionality="QUESTIONNAIRE_TEMPLATES_READ">
              <DashboardNavItem
                to={QUESTIONNAIRES_PATH.primary}
                text={t('sidebar.questionnaires')}
                selected={isPathSelected(QUESTIONNAIRES_PATH, pathname)}
                onClick={onNavigationListItemClick}
                icon={<QuestionnaireNavIcon />}
                selectedClassName={classes.listItemSelected}
                desktopDrawerOpen={isDrawerOpen}
              />
            </RequireFunctionality>
          </Box>
        </>
      )}
      {checkHasFunctionality(['DEVICES_READ', 'DEPARTMENTS_READ', 'ORGANIZATIONS_READ']) && (
        <>
          <Box mb={1} mt={3}>
            <Divider variant="fullWidth" className={classes.dividerLight} />
          </Box>
          <Box className={classes.menuSubheader}>
            <Box>{isDrawerOpen && t('sidebar.settings')}</Box>
          </Box>
          <Box className={classes.menuWrapper}>
            <RequireFunctionality functionality="DEVICES_READ">
              <DashboardNavItem
                to={DEVICES_PATH.primary}
                text={t('sidebar.devices')}
                selected={isPathSelected(DEVICES_PATH, pathname)}
                onClick={onNavigationListItemClick}
                icon={<DeviceNavIcon />}
                selectedClassName={classes.listItemSelected}
                desktopDrawerOpen={isDrawerOpen}
              />
            </RequireFunctionality>
          </Box>
          <Box className={classes.menuWrapper}>
            <RequireFunctionality functionality="DEPARTMENTS_READ">
              <DashboardNavItem
                to={DEPARTMENTS_PATH.primary}
                text={t('sidebar.departments')}
                selected={isPathSelected(DEPARTMENTS_PATH, pathname)}
                onClick={onNavigationListItemClick}
                icon={<DepartmentNavIcon />}
                selectedClassName={classes.listItemSelected}
                desktopDrawerOpen={isDrawerOpen}
              />
            </RequireFunctionality>
          </Box>
          <Box className={classes.menuWrapper}>
            <RequireFunctionality functionality="ORGANIZATIONS_READ">
              <DashboardNavItem
                to={ORGANIZATIONS_PATH.primary}
                text={t('sidebar.organizations')}
                selected={isPathSelected(ORGANIZATIONS_PATH, pathname)}
                onClick={onNavigationListItemClick}
                icon={<OrganizationNavIcon />}
                selectedClassName={classes.listItemSelected}
                desktopDrawerOpen={isDrawerOpen}
              />
            </RequireFunctionality>
          </Box>
          <Box className={classes.menuWrapper}>
            <RequireFunctionality functionality="ORGANIZATIONS_READ">
              <DashboardNavItem
                to={GROUPS_PATH.primary}
                text={t('sidebar.groups')}
                selected={isPathSelected(GROUPS_PATH, pathname)}
                onClick={onNavigationListItemClick}
                icon={<OrganizationNavIcon />}
                selectedClassName={classes.listItemSelected}
                desktopDrawerOpen={isDrawerOpen}
              />
            </RequireFunctionality>
          </Box>
        </>
      )}
    </List>
  );
}
