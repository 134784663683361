import { DatePicker } from '@mui/lab';
import { Box, debounce, TextField } from '@mui/material';
import { useState } from 'react';
import { usePatientPairingStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { Device, Patient } from '../../../models';
import { useCurrentUser } from '../../../context';
import { AddButton } from '../../../components/AddButton';
import { CancelButton } from '../../../components/CancelButton';
import { SelectDeviceInput } from '../../../components/SelectDeviceInput/SelectDeviceInput';
import { ApiRoutes, makeApiRequest } from '../../../services/api';

const MAX_PURPOSE_LENGTH = 500;

interface PatientPairingValue {
  patientId: string;
  pairingUserId?: string;
  deviceId: string;
  purpose?: string;
  expectedEndDate?: string;
}

interface Props {
  patient: Patient;
  devices: Device[];
  onDone: () => void;
  onClose: () => void;
}

export function PatientPairing({ patient, devices, onDone, onClose }: Props) {
  const classes = usePatientPairingStyles();
  const { t } = useTranslation('patient');
  const [purpose, setPurpose] = useState<string | null>(null);
  const [expectedEndDate, setExpectedEndDate] = useState<Date | null>(null);
  const { user } = useCurrentUser();
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>('');

  const handleDeviceChange = (id: string) => {
    setSelectedDeviceId(id);
  };

  const value: PatientPairingValue = {
    patientId: patient.externalId,
    pairingUserId: user?.externalId,
    deviceId: '',
  };

  if (purpose) {
    value.purpose = purpose;
  }

  if (expectedEndDate && !isNaN(expectedEndDate.valueOf())) {
    value.expectedEndDate = expectedEndDate.toISOString();
  }

  function getPurposeLength() {
    return purpose?.length || 0;
  }

  async function onAdd() {
    value.deviceId = selectedDeviceId;
    await makeApiRequest<never, PatientPairingValue>('POST', ApiRoutes.PatientPairings, value);
    onDone();
  }

  async function onCancel() {
    onClose();
  }

  return (
    <div>
      <div className={classes.container}>
        <SelectDeviceInput
          devices={devices}
          selectedDeviceId={selectedDeviceId}
          onChange={handleDeviceChange}
        />
        <DatePicker
          id="expectedEndDate"
          margin="normal"
          value={expectedEndDate}
          fullWidth={true}
          maxDate={undefined}
          disablePast={true}
          onChange={setExpectedEndDate}
          label={t('scaseEndDate.label', { context: patient.sex })}
          format="MM/dd/yyyy"
          inputVariant="outlined"
        />
        <TextField
          id="purpose"
          label={t('purpose.label')}
          margin="normal"
          fullWidth={true}
          onChange={debounce((e) => {
            setPurpose(e.target.value);
          }, 300)}
          multiline={true}
          minRows={2}
          variant="outlined"
          error={getPurposeLength() > MAX_PURPOSE_LENGTH}
          helperText={
            getPurposeLength() > MAX_PURPOSE_LENGTH
              ? 'Invalid input'
              : `${getPurposeLength()}/${MAX_PURPOSE_LENGTH}`
          }
        />
      </div>
      <Box marginTop={2} display="flex" justifyContent="space-between">
        <CancelButton onClick={onCancel} />
        <AddButton onAddClick={onAdd} text="Pair" disabled={selectedDeviceId === ''} />
      </Box>
    </div>
  );
}
