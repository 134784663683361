import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  questionTextLine: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(23),
    borderRadius: theme.typography.pxToRem(5),
    fontStyle: 'normal',
    fontWeight: 'bold',
    boxShadow: `0px ${theme.typography.pxToRem(1)} ${theme.typography.pxToRem(
      5,
    )} rgba(0, 0, 0, 0.15)`,
    marginBottom: theme.spacing(0.5),
  },
  questionText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(23),
    color: theme.palette.text.secondary,
  },
  questionAnswer: {
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(23),
    marginLeft: theme.spacing(0.3),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    rowGap: theme.spacing(0.5),
  },
}));
