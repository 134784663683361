import { useNavigate, useLocation } from 'react-router-dom';
import { GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { areUrlSearchParamsEqual } from '../utils';
import { URL_KEY_VALUE_SEPARATOR, URL_SORT } from '../constants';

interface SortObject {
  [key: string]: GridSortDirection;
}

function shallowEqual(object1: GridSortModel, object2: GridSortModel) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    if (object1[key as keyof GridSortModel] !== object2[key as keyof GridSortModel]) {
      return false;
    }
  }
  return true;
}

export function useGridUrlSort() {
  const navigate = useNavigate();
  const location = useLocation();
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  useEffect(() => {
    const searchQuery = qs.parse(qs.pick(location.search, [URL_SORT]), { arrayFormat: 'comma' });
    // get SORT from URL
    if (URL_SORT in searchQuery) {
      const _sortModel: GridSortModel = [];
      let sortQuery = searchQuery[URL_SORT];
      if (sortQuery && !Array.isArray(sortQuery)) {
        sortQuery = [sortQuery];
      }

      const sort: SortObject = {};
      for (const q of sortQuery as string[]) {
        const arr = q.split(URL_KEY_VALUE_SEPARATOR);
        if (arr.length != 2) {
          continue;
        }
        const [key, value] = arr;

        if (!['asc', 'desc'].includes(value)) {
          continue;
        }

        sort[key] = value as GridSortDirection;
      }

      Object.entries(sort).forEach(([key, value]) => {
        _sortModel.push({
          field: key,
          sort: value,
        });
      });

      setSortModel(_sortModel);
    } else {
      setSortModel([]);
    }
  }, [location.search]);

  function handleSortModelChange(_sortModel: GridSortModel) {
    if (!shallowEqual(sortModel, _sortModel)) {
      const searchQuery = qs.parse(location.search, { arrayFormat: 'comma' });
      searchQuery[URL_SORT] = [] as string[];
      const newSortModel = _sortModel;
      if (newSortModel.length > 0) {
        newSortModel.forEach((x) => {
          (searchQuery[URL_SORT] as string[]).push(`${x.field}${URL_KEY_VALUE_SEPARATOR}${x.sort}`);
        });
      }

      const currentSortQuery = qs.parse(qs.pick(location.search, [URL_SORT]), {
        arrayFormat: 'comma',
      });

      if (!areUrlSearchParamsEqual(currentSortQuery[URL_SORT], searchQuery[URL_SORT])) {
        navigate(
          {
            pathname: location.pathname,
            search: `?${qs.stringify(searchQuery, { arrayFormat: 'comma' })}`,
          },
          { replace: true },
        );
      }
    }
  }

  return { sortModel, onSortModelChange: handleSortModelChange };
}
