import { getCurrentDatetimeLocale } from '../../i18n';
import { Box } from '@mui/material';
import { useStyles } from './styles';

interface Props {
  id?: string;
  date: Date;
  className?: string;
  testId?: string;
}

export function ShortMonthDate({ id, date, className, testId }: Props) {
  const classes = useStyles();
  const datetimeLocale = getCurrentDatetimeLocale();
  return (
    <Box
      className={`${classes.date} ${className}`}
      data-testid={testId}
      id={id}
      data-val={new Date(date).toISOString()}
    >
      {new Date(date).toLocaleDateString(datetimeLocale.code, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })}
    </Box>
  );
}
