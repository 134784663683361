import { useCurrentUser } from '../../context';
import { AdminProfilePage } from './admin/AdminProfilePage';
import { MedicProfilePage } from './medic';

export function ProfilePage() {
  const { user } = useCurrentUser();

  if (user && user.hasAccessToProfile) {
    return <MedicProfilePage />;
  } else if (user && user.hasAccessToAdminProfile) {
    return <AdminProfilePage />;
  }

  return null;
}
