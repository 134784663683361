import {
  CalculatedThresholds,
  MeasurementThresholds,
  MeasurementValue,
  MeasuringDeviceType,
} from '../models';
import { thresholdColors } from '../constants/colors';
import { theme } from '../assets/style';
import { kebabize } from './utils';
import { sortMeasurandsWithThresholdsByMeasuringDevice } from './sortUtils';

export interface LightAndDarkColors {
  light: string;
  dark: string;
}

export interface ClassNameAndPriority {
  class: string;
  priority: number;
}

export const getColorByThresholds = (
  thresholds: CalculatedThresholds,
): LightAndDarkColors | null => {
  if (thresholds?.isMaxError) {
    return {
      light: thresholdColors.maxErrorLight,
      dark: thresholdColors.maxErrorDark,
    };
  } else if (thresholds?.isMinError) {
    return {
      light: thresholdColors.minErrorLight,
      dark: thresholdColors.minErrorDark,
    };
  } else if (thresholds?.isMaxWarning) {
    return {
      light: thresholdColors.maxWarningLight,
      dark: thresholdColors.maxWarningDark,
    };
  } else if (thresholds?.isMinWarning) {
    return {
      light: thresholdColors.minWarningLight,
      dark: thresholdColors.minWarningDark,
    };
  } else {
    return null;
  }
};

export const getClassNameAndPriorityByThresholds = (
  thresholds: CalculatedThresholds,
): ClassNameAndPriority | null => {
  if (thresholds.isMaxError) {
    return {
      class: 'maxError',
      priority: 1,
    };
  } else if (thresholds.isMinError) {
    return {
      class: 'minError',
      priority: 1,
    };
  } else if (thresholds.isMaxWarning) {
    return {
      class: 'maxWarning',
      priority: 5,
    };
  } else if (thresholds.isMinWarning) {
    return {
      class: 'minWarning',
      priority: 5,
    };
  } else {
    return null;
  }
};

export const getStyleByThresholds = (thresholds: CalculatedThresholds, isHighlighted?: boolean) => {
  const color = getColorByThresholds(thresholds);
  if (color == null) {
    return {};
  }

  return {
    style: {
      color: isHighlighted ? color.light : color.dark,
      backgroundColor: isHighlighted ? color.dark : 'inherit',
      borderRadius: theme.typography.pxToRem(2),
    },
  };
};

export const getStyleByCalculatedThresholds = (measurement: MeasurementValue) => {
  let color = null;
  if (measurement.isMaxError != null && measurement.isMaxError) {
    color = thresholdColors.maxErrorDark;
  } else if (measurement.isMaxWarning != null && measurement.isMaxWarning) {
    color = thresholdColors.maxWarningDark;
  } else if (measurement.isMinError != null && measurement.isMinError) {
    color = thresholdColors.minErrorDark;
  } else if (measurement.isMinWarning != null && measurement.isMinWarning) {
    color = thresholdColors.minWarningDark;
  }

  if (color == null) {
    return {};
  }

  return {
    style: {
      color: color,
      fontWeight: 'bold',
    },
  };
};

export function getSortedThresholdsByDeviceId(
  deviceId: number | null,
  measurementThresholds: MeasurementThresholds[] | null,
) {
  if (!deviceId) {
    return null;
  }

  if (!measurementThresholds) {
    return null;
  }

  const mThresholds = measurementThresholds.filter(
    (measurementThreshold) => measurementThreshold.id == deviceId,
  )[0];

  const sortedMeasurementValues = sortMeasurandsWithThresholdsByMeasuringDevice(
    mThresholds?.measuringDevice as MeasuringDeviceType,
    mThresholds?.measurands,
  );

  const modifiedDeviceThresholds = mThresholds;
  if (sortedMeasurementValues) {
    modifiedDeviceThresholds['measurands'] = sortedMeasurementValues;
  }

  return modifiedDeviceThresholds;
}

export function getThresholdsByDeviceName(
  deviceName: string,
  measurementThresholds: MeasurementThresholds[] | null,
) {
  if (measurementThresholds) {
    return measurementThresholds.find(
      (measurementThreshold) => kebabize(measurementThreshold.measuringDevice) == deviceName,
    );
  }
  return null;
}
