import { useNavigate } from 'react-router-dom';
import { ProgressIndicator } from '../../components/ProgressIndicator';
import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import {
  Department,
  MedicCreateOrEditInput,
  MedicCreateResult,
  Qualification,
  UserRole,
} from '../../models';
import { MedicForm, MedicFormValues } from './MedicForm';
import { Page } from '../../components/Page';
import { useTranslation } from 'react-i18next';
import { FunctionalityProvider } from '../../context/functionality';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import { PageTopBar } from '../../components/PageTopBar';
import React from 'react';
import { Box } from '@mui/material';
import { useEntityActionSnackbar } from '../../hooks';

export function CreateMedicPage() {
  const { t } = useTranslation('medic');
  const navigate = useNavigate();
  const { enqueueEntityCreatedSnackbar } = useEntityActionSnackbar();
  const [{ data: qualifications, isLoading: areQualificationsLoading }] = useGetApi<
    Qualification[]
  >(ApiRoutes.Qualifications);
  const [{ data: departments, isLoading: areDepartmentsLoading }] = useGetApi<Department[]>(
    ApiRoutes.Departments,
  );

  const [{ data: userRoles, isLoading: areUserRolesLoading }] = useGetApi<UserRole[]>(
    ApiRoutes.UserRoles,
  );

  async function handleSubmit(values: MedicFormValues) {
    const input: MedicCreateOrEditInput = {
      ...values,
      email: values.email || null,
    };
    return makeApiRequest<MedicCreateResult, MedicCreateOrEditInput>(
      'POST',
      ApiRoutes.Medics,
      input,
    );
  }

  async function handleSubmitCompleted(data?: MedicCreateResult) {
    if (!data) {
      navigate(-1);

      return;
    }

    navigate(`/medics/${data.id}`, { replace: true });

    enqueueEntityCreatedSnackbar(t('medic', { context: data?.sex }), { context: data?.sex });
  }

  if (areQualificationsLoading || areUserRolesLoading || areDepartmentsLoading) {
    return <ProgressIndicator />;
  }

  if (qualifications == null) {
    return <div>{t('error.qualificationsNotFound')}</div>;
  }

  if (userRoles == null) {
    return <div>{t('error.userRolesNotFound')}</div>;
  }

  if (departments == null) {
    return <div>{t('error.departmentsNotFound')}</div>;
  }

  return (
    <FunctionalityProvider
      createFunctionality="MEDICS_CREATE"
      readFunctionality="MEDICS_READ"
      updateFunctionality="MEDICS_UPDATE"
      deleteFunctionality="MEDICS_DELETE"
    >
      <>
        <StickyTopAppBar>
          <PageTopBar title={t('title.new')} hideLastUpdated={true} />
        </StickyTopAppBar>
        <Box mt={2}>
          <Page requireFunctionality="MEDICS_READ">
            <MedicForm<MedicCreateResult>
              onSubmit={handleSubmit}
              onSubmitCompleted={handleSubmitCompleted}
              qualifications={qualifications}
              userRoles={userRoles}
              departments={departments}
            />
          </Page>
        </Box>
      </>
    </FunctionalityProvider>
  );
}
