import { Box } from '@mui/material';
import { useLatestMeasurementColumn } from './styles';
import { ReactNode } from 'react';
import { Optional } from '../../../../models';

interface Props {
  headerTitle: string;
  primaryText: string | ReactNode;
  secondaryText?: Optional<string>;
}

export function LatestMeasurementColumn({ headerTitle, primaryText, secondaryText }: Props) {
  const classes = useLatestMeasurementColumn();
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.headerText}>{headerTitle}</Box>
      <Box className={classes.primaryText}>{primaryText}</Box>
      <Box className={classes.secondaryText}>{secondaryText ?? '\u00A0'} </Box>
    </Box>
  );
}
