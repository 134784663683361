import React from 'react';
import { theme } from '../../assets/style';

interface Props {
  width?: number;
  height?: number;
  primaryColor?: string;
  secondaryColor?: string;
}

export const ScaseDeviceIcon = React.memo(function ScaseDeviceIconComponent({
  width,
  height,
  primaryColor = theme.palette.primary.main,
  secondaryColor = theme.palette.primary.dark,
}: Props) {
  return (
    <svg
      width={width || 34}
      height={height || 28}
      viewBox="0 0 34 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0989)">
        <path
          d="M33.6197 14.9285C33.6242 14.3069 33.4952 13.7502 33.0786 12.7316C32.703 11.8121 32.7005 10.8455 32.696 10.167C32.6945 9.96026 32.694 9.84824 32.6925 9.57066C32.8719 9.60883 32.9237 9.50424 32.9097 8.78203C32.8968 8.0866 32.963 7.17306 32.8564 6.8246C32.821 6.70861 32.7328 6.75718 32.6606 6.76164C32.6596 6.36014 32.6825 6.18567 32.6825 5.92692C32.6825 4.98166 31.9785 3.41085 30.2147 3.41085L15.6786 3.43911C15.6722 2.52656 14.9925 0.7213 12.8745 0.649923C10.731 0.577058 9.96467 2.42198 9.99307 3.45051C9.99307 3.45051 6.12214 3.43217 4.09823 3.45001C2.07433 3.46786 1.63088 5.51997 1.63088 5.51997L0.926849 5.81341C0.926849 5.81341 0.729043 5.84513 0.654305 6.21293C0.579567 6.58072 0.596009 6.94158 0.60149 7.34506C0.60149 7.34506 0.589532 9.82544 0.599995 10.1908C0.607967 10.4619 0.667758 10.9729 0.694165 11.3581C0.720573 11.7432 0.725057 13.5415 0.725057 13.5415V23.9919C0.720573 25.0026 1.22231 26.3291 2.7659 26.5259C2.7659 26.5259 9.79078 27.3531 16.0199 27.3531C22.4599 27.3531 29.7533 26.6706 31.2839 26.4867C32.8146 26.3028 33.6591 25.187 33.6591 24.2284L33.6197 14.9285ZM30.7164 19.5348C30.6497 19.5735 30.4882 19.6468 30.4982 19.8342C30.5057 19.9789 30.4917 24.212 30.4917 24.212C30.4917 24.212 30.536 25.2336 29.414 25.2331C28.2506 25.2331 4.41213 25.2331 4.41213 25.2331C4.41213 25.2331 3.67173 25.2549 3.67173 24.1283C3.67173 22.7518 3.67173 14.3029 3.67173 14.3029C3.67173 14.3029 3.67173 13.3294 4.87501 13.3294H29.3477C29.3477 13.3294 30.4718 13.2045 30.4783 14.5527C30.4847 15.901 30.4783 18.6827 30.4783 18.6827C30.4783 18.6827 30.4623 18.8974 30.6766 18.9786C30.9282 19.0748 30.9212 19.4163 30.7164 19.5348Z"
          fill="url(#paint0_linear989)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear989"
          x1="30.4764"
          y1="3.33409"
          x2="10.6478"
          y2="23.8875"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3003" stopColor={primaryColor} />
          <stop offset="0.9996" stopColor={secondaryColor} />
        </linearGradient>
        <clipPath id="clip0989">
          <rect
            width="33.0636"
            height="26.7052"
            fill="white"
            transform="translate(0.595703 0.647339)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});
