import { useStyles } from './styles';
import { Box, Divider } from '@mui/material';
import { MouseEventHandler } from 'react';
import { DashboardMobileHeader } from '../Header';
import { DashboardMobileNavigation } from '../Navigation';

interface Props {
  onNavigationListItemClick?: MouseEventHandler;
}

export function DashboardMobileDrawerContent({ onNavigationListItemClick }: Props) {
  const classes = useStyles();

  return (
    <Box className={classes.navigationBox}>
      <Box className={classes.headerAndNavigationContainer}>
        <DashboardMobileHeader />
        <Divider classes={{ root: classes.divider }} />
        <Box className={classes.navigationContainer}>
          <DashboardMobileNavigation onNavigationListItemClick={onNavigationListItemClick} />
        </Box>
      </Box>
    </Box>
  );
}
