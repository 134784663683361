import { Box, Button, useTheme } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { PatientQuestionnaire, QuestionnaireStatus } from '../../../models';
import { DeleteHandler, Grid, GridColumnDefinition, RowClickHandler } from '../../Grid';
import { QuestionnaireResponseIcon } from '../../Icons';
import { QuestionnaireScoringCategoryWithScore } from '../../Questionnaire';
import { useStyles } from './styles';
import { DeleteItemCompletedHandler } from '../../DeleteButton';
import { isNullOrUndefined } from '../../../utils';
import { userHasFunctionality } from '../../RequireFunctionality';
import { PATIENT_QUESTIONNAIRES_DELETE_POLICY } from '../../../constants/Policies';
import { CustomTooltip } from '../../CustomTooltip';
import { OnlineStatusCircle } from '../../StatusCircle';
import { DeviceSyncEntityState } from '../../../models/enums/DeviceSyncEntityState';

interface Props {
  data: PatientQuestionnaire[];
  onRowClick?: RowClickHandler<PatientQuestionnaire>;
  onDelete?: DeleteHandler;
  onDeleteCompleted?: DeleteItemCompletedHandler;
}

export function PatientQuestionnairesGrid({
  data,
  onRowClick,
  onDelete,
  onDeleteCompleted,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation('questionnaire');
  const theme = useTheme();

  const columns: GridColumnDefinition<PatientQuestionnaire>[] = [
    {
      field: 'title',
      headerName: t('titleField.label'),
      minWidth: 200,
      flex: 0.35,
      renderCell: (params: GridCellParams) => <strong>{params.row.value}</strong>,
    },
    {
      field: 'status',
      headerName: t('status.label'),
      minWidth: 130,
      flex: 0.21,
      renderCell: (params: GridCellParams) => {
        const isFilled = params.value === QuestionnaireStatus.Filled;
        const color = isFilled ? theme.palette.success.main : theme.palette.text.secondary;
        return (
          <Button
            startIcon={<QuestionnaireResponseIcon primaryColor={color} />}
            className={classes.statusButton}
            style={{ color: color }}
          >
            {isFilled
              ? t(`status.${QuestionnaireStatus.Filled}` as const)
              : t(`status.${QuestionnaireStatus.Empty}` as const)}
          </Button>
        );
      },
    },
    {
      field: 'answeredOnDatetime',
      headerName: t('answeredAt.label'),
      minWidth: 130,
      flex: 0.21,
      renderCell: (params: GridCellParams) => {
        if (isNullOrUndefined(params.value)) {
          return <></>;
        }

        return <Box>{new Date(params.value as string).toLocaleString()}</Box>;
      },
    },
    {
      field: 'score',
      headerName: t('question.score.label'),
      minWidth: 190,
      flex: 0.35,
      renderCell: (params: GridCellParams) => {
        const currentRow = params.row as PatientQuestionnaire;
        const score = currentRow.score;
        if (!score) {
          return <></>;
        }
        const categories = currentRow.scoringCategories || [];
        return (
          <QuestionnaireScoringCategoryWithScore
            score={score}
            scoringCategories={categories}
            includeCategoryName={true}
          />
        );
      },
    },
    {
      field: 'deviceSyncEntityState',
      headerName: ' ',
      headerAlign: 'right',
      align: 'right',
      flex: 0.25,
      width: 20,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <CustomTooltip
          title={
            (params.row as PatientQuestionnaire).syncDate !== null
              ? `${(params.row as PatientQuestionnaire).syncDate}`
              : '-'
          }
          placement="bottom-end"
        >
          <Box height={50} display="flex" alignItems="center">
            <OnlineStatusCircle
              isOnline={
                (params.row as PatientQuestionnaire).deviceSyncEntityState ===
                DeviceSyncEntityState.Sync
              }
            />
          </Box>
        </CustomTooltip>
      ),
    },
  ];

  function renderDeleteButtonCondition(params: GridCellParams) {
    const status = params.id;

    return (
      userHasFunctionality(PATIENT_QUESTIONNAIRES_DELETE_POLICY) &&
      status !== QuestionnaireStatus.Filled
    );
  }

  return (
    <Grid
      columns={columns}
      rows={data}
      getRowClassName={(row) =>
        row.status === QuestionnaireStatus.Empty ? `${classes.unansweredQuestionnaireRow}` : ''
      }
      onRowClick={onRowClick}
      onDelete={onDelete}
      onDeleteCompleted={onDeleteCompleted}
      renderDeleteButtonCondition={renderDeleteButtonCondition}
    />
  );
}
