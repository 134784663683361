import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.grey.A400,
    fontFamily: 'Inter',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      margin: 'auto',
    },
  },
  mainSubtitle: {
    display: 'flex',
  },
  mainTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  mainTitle: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 500,
    lineHeight: 1.33,
    letterSpacing: -0.96,
    color: theme.palette.primary.A700,
    marginRight: theme.spacing(1.25),
  },
  addNewButton: {
    backgroundColor: theme.palette.primary.A100,
    color: theme.palette.primary.main,
    padding: theme.spacing(0.25),
    borderRadius: theme.spacing(1),
    minWidth: 'unset',
    '&:focus, &:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down('lg')]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontFamily: 'Inter',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.71,
      letterSparcing: -0.14,
      fontWeight: 500,
      padding: theme.spacing(1.25, 3),
      '&:focus, &:hover': {
        backgroundColor: theme.palette.primary.A100,
        color: theme.palette.primary.main,
      },
    },
  },
  lastUpdatedWrapper: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 500,
    lineHeight: 1.4,
    letterSpacing: -0.1,
    display: 'flex',
    color: '#8794aa',
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      paddingBottom: theme.spacing(0.25),
    },
  },
  lastUpdatedValue: {
    marginLeft: theme.spacing(0.25),
    fontWeight: 'bold',
  },
  accordionRoot: {
    width: '100%',
    padding: 0,
    backgroundColor: theme.palette.grey.A200,
  },
  accordionSummaryContent: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  sectionWrapper: {
    minWidth: 0,
    paddingRight: 40,
    [theme.breakpoints.down('lg')]: {
      paddingRight: 0,
    },
  },
  title: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: -0.96,
    color: '#0e263e',
    overflow: 'hidden',
    whiteSpace: 'nowrap' /* Don't forget this one */,
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('lg')]: {
      display: 'inline-block',
    },
  },
  descAndButtonWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontFamily: 'Inter',
    lineHeight: 1.29,
    letterSpacing: 0.4,
    fontWeight: 'normal',
    color: theme.palette.grey.A700,
    '&>*': {
      margin: theme.spacing(0, 0.6),
    },
    '&>*:first-child': {
      marginRight: theme.spacing(0.6),
      marginLeft: 0,
    },
    '&>*:last-child': {
      marginLeft: theme.spacing(0.6),
      marginRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      caretColor: theme.palette.primary.main,
      color: theme.palette.secondary['100'],
      fontFamily: 'Inter',
      fontSize: theme.typography.pxToRem(14),
      justifyContent: 'space-between',
      lineHeight: 1.29,
      letterSpacing: 0.4,
    },
  },
}));
