import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { FONT_SIZES } from '../../../../Constants';

const styles = StyleSheet.create({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: FONT_SIZES.h6,
    height: 70,
    marginTop: 'auto',
    paddingTop: 10,
    width: '100%',
  },
  horizontalLine: {
    display: 'flex',
    marginHorizontal: 'auto',
    marginBottom: 10,
    height: 2,
    width: '95%',
    borderTop: '1px solid #676767',
  },
  logoAndPage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    paddingVertical: 5,
    marginHorizontal: 5,
  },
  logo: {
    height: '100%',
    width: 'auto',
    padding: 5,
  },
});

export function Footer() {
  return (
    <View style={styles.footer} fixed={true}>
      <View style={styles.horizontalLine} />
      <View style={styles.logoAndPage}>
        <Image source="/img/scase-logo.png" style={styles.logo} />
        <Text render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
      </View>
    </View>
  );
}
