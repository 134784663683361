import { useStyles } from './styles';
import { Box } from '@mui/material';
import { ReactComponent as UserBadgeIcon } from '../../assets/icons/UserBadgeIcon.svg';
import { useCurrentUser } from '../../context';
import { useDashboardMenuContext } from '../../context/dashboardMenu';
import { Link, useLocation } from 'react-router-dom';
import { isMatch } from '../../utils';
import { PROFILE_PATH } from '../../constants';

export interface UserProfileStyleProps {
  hasAccessToProfile: boolean;
}

export function UserProfileButton() {
  const { user } = useCurrentUser();
  const classes = useStyles({
    hasAccessToProfile: !!user && (user.hasAccessToProfile || user.hasAccessToAdminProfile),
  });
  const location = useLocation();
  const { isDrawerOpen } = useDashboardMenuContext();

  const isProfileActive = isMatch({ path: PROFILE_PATH }, location.pathname, false);

  return (
    <Link to={PROFILE_PATH} className={classes.link}>
      <Box className={`${classes.container} ${isProfileActive ? classes.containerActive : ''}`}>
        <UserBadgeIcon className={classes.icon} />
        {isDrawerOpen && <Box className={classes.profileName}>{user?.name ?? user?.username}</Box>}
      </Box>
    </Link>
  );
}
