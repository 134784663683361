import { useTranslation } from 'react-i18next';
import { InstallationMemberData } from '../../../../models';
import { Box, Grid, Typography } from '@mui/material';
import { InstallationFormValues } from '../InstallationStepperForm';
import { useFormikContext } from 'formik';
import { CheckboxInput } from '../../../../components/CheckboxInput';

export function ConfirmationForm() {
  const { values: formValues } = useFormikContext<InstallationFormValues>();
  const { t } = useTranslation('installation');
  const renderMemberData = (members: InstallationMemberData[]) =>
    members.map((member, index) => (
      <Box key={index}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography display="inline">{t('fullName.label')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography display="inline">
              {member.name} {member.surname}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography display="inline">{t('email.label')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography display="inline">{member.email}</Typography>
          </Grid>
        </Grid>
      </Box>
    ));

  return (
    <>
      <Box>
        <h3>User Information:</h3>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography display="inline">{t('fullName.label')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography display="inline">
              {formValues.name} {formValues.surname}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography display="inline">{t('email.label')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography display="inline">{formValues.email}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography display="inline">{t('chassisId.label')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography display="inline">{formValues.chassisId}</Typography>
          </Grid>
        </Grid>
      </Box>
      <h3>{t('memberStep.subtitle')}</h3>
      <Box>
        {formValues.installationMembers && renderMemberData(formValues.installationMembers)}
      </Box>
      <Box>
        <CheckboxInput id="gdpr" label={t('gdpr.label')} />
        <CheckboxInput id="bussinesCondition" label={t('bussinesCondition.label')} />
      </Box>
    </>
  );
}
