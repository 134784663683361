import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Patient, PatientColumnsWidth, PatientGridModel } from '../../../models';
import {
  DeleteCompletedHandler,
  DeleteHandler,
  Grid,
  GridColumnDefinition,
  RowClickHandler,
} from '../../Grid';
import { PatientDevicePairingExpectedEndDateGridColumn } from '../../PatientDevicePairingExpectedEndDateGridColumn';
import { useEffect, useState } from 'react';
import { PatientDevicePairingPurposeGridColumn } from '../../PatientDevicePairingPurposeGridColumn';
import { EDIT_SUBPATH } from '../../../constants';
import {
  compareGridCellValueWithNullOrUndefined,
  sortPatientsBySelectedPatientsAndDevicePairing,
} from '../../../utils';
import { DepartmentCellRenderer } from '../Renderer';

interface Props {
  patients: Patient[];
  onDelete?: DeleteHandler;
  onDeleteCompleted?: DeleteCompletedHandler<Patient>;
  onRowClick?: RowClickHandler<Patient>;
  isSelectionGrid?: boolean;
  selectedPatients?: Patient[];
  onGoBack?: () => void;
  onSaveSelection?: (patientIds: number[]) => Promise<void>;
  showColumns?: (keyof PatientGridModel)[] | 'all';
  columnsWidth?: PatientColumnsWidth;
  columnBuffer?: number;
  disableEdit?: boolean;
}

export function PatientsGrid({
  patients,
  onDelete,
  onDeleteCompleted,
  onRowClick,
  isSelectionGrid = false,
  selectedPatients,
  onGoBack,
  onSaveSelection,
  showColumns = 'all',
  columnsWidth,
  columnBuffer,
  disableEdit = false,
  ...props
}: Props) {
  const [selectedPatientIds, setSelectedPatientsIds] = useState(
    selectedPatients?.map((pat) => pat.id),
  );

  const { t } = useTranslation('patient');
  const [sortedPatients, setSortedPatients] = useState<PatientGridModel[]>(
    sortPatientsBySelectedPatientsAndDevicePairing(patients, selectedPatients),
  );

  useEffect(() => {
    setSortedPatients(sortPatientsBySelectedPatientsAndDevicePairing(patients, selectedPatients));
    setSelectedPatientsIds(selectedPatients?.map((pat) => pat.id));
  }, [patients]);

  const allColumns: GridColumnDefinition<PatientGridModel>[] = [
    {
      field: 'firstName',
      headerName: t('firstName.label'),
      minWidth: 100,
      flex: 4,
      renderCell: (params: GridRenderCellParams) => <strong>{params.value}</strong>,
    },
    {
      field: 'lastName',
      headerName: t('lastName.label'),
      minWidth: 100,
      flex: 4,
      renderCell: (params: GridRenderCellParams) => <strong>{params.value}</strong>,
    },
    {
      field: 'username',
      headerName: t('username.label'),
      flex: 2,
      minWidth: 175,
      renderCell: (params: GridRenderCellParams) => <strong>{params.value}</strong>,
    },
    {
      field: 'departments',
      headerName: t('departments.label'),
      flex: 2,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: DepartmentCellRenderer,
    },
    {
      field: 'expectedEndDate',
      headerName: t('scaseEndDate.label'),
      flex: 2,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (_, __, param1, param2) => {
        const p1 = param1.value;
        const p2 = param2.value;

        if (p1 == null || p2 == null) {
          return compareGridCellValueWithNullOrUndefined(p1, p2);
        }
        return new Date(p1 as Date).getTime() - new Date(p2 as Date).getTime();
      },
      renderCell: (params: GridRenderCellParams) => {
        return (
          <PatientDevicePairingExpectedEndDateGridColumn patient={params.row as PatientGridModel} />
        );
      },
    },
    {
      field: 'purpose',
      headerName: t('purpose.label'),
      flex: 4,
      minWidth: 250,
      sortComparator: (_, __, param1, param2) => {
        const p1 = param1.value;
        const p2 = param2.value;

        if (p1 == null || p2 == null) {
          return compareGridCellValueWithNullOrUndefined(p1, p2);
        }
        return (p1 as string).localeCompare(p2 as string);
      },
      renderCell: (params: GridRenderCellParams) => (
        <PatientDevicePairingPurposeGridColumn patient={params.row as PatientGridModel} />
      ),
    },
  ];

  function getEditPath(id: number) {
    return `/patients/${id}/${EDIT_SUBPATH}`;
  }

  return (
    <Grid
      {...props}
      columns={getColumns(allColumns, showColumns, columnsWidth)}
      rows={sortedPatients}
      selectedIds={isSelectionGrid ? selectedPatientIds : undefined}
      {...(!disableEdit ? { getEditPath: getEditPath } : {})}
      onDelete={onDelete}
      onDeleteCompleted={onDeleteCompleted}
      onRowClick={onRowClick}
      onGoBack={onGoBack}
      onSaveSelection={isSelectionGrid ? onSaveSelection : undefined}
      columnBuffer={columnBuffer}
    />
  );
}

function getColumns(
  allColumns: GridColumnDefinition<PatientGridModel>[],
  showColumns: (keyof PatientGridModel)[] | 'all',
  columnsWidth?: PatientColumnsWidth,
) {
  if (showColumns == 'all') {
    return allColumns;
  } else {
    const columns: GridColumnDefinition<PatientGridModel>[] = [];
    const showColumnsWithId = ['id', ...showColumns];

    for (const col of showColumnsWithId) {
      const foundColumn = allColumns.find((x) => x.field === col);
      if (foundColumn) {
        if (columnsWidth) {
          const columnWidth = columnsWidth[col as keyof PatientGridModel];
          if (columnWidth) {
            const { flex, width, minWidth } = columnWidth;

            if (minWidth) {
              foundColumn.minWidth = minWidth;
            }

            if (flex) {
              foundColumn.flex = flex;
            }

            if (width) {
              foundColumn.width = width;
            }
          }
        }
        columns.push(foundColumn);
      }
    }

    return columns;
  }
}
