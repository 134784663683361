import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export function ConfigurationValuesSchema() {
  const { t } = useTranslation('installation');
  return Yup.object({
    bussinesCondition: Yup.boolean()
      .required(t('bussinesCondition.validation.required'))
      .oneOf([true], t('bussinesCondition.validation.mustBeTrue')),
    gdpr: Yup.boolean()
      .required(t('gdpr.validation.required'))
      .oneOf([true], t('gdpr.validation.mustBeTrue')),
  });
}
