import { useNavigate } from 'react-router-dom';
import { ProgressIndicator } from '../../components/ProgressIndicator';
import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import { Department, IdentifierType, PatientCreateInput, PatientCreateResult } from '../../models';
import { MeasuringDeviceWithType } from '../../models/MeasuringDevice';
import { PatientForm, PatientFormValues } from './PatientForm';
import { Page } from '../../components/Page';
import { useTranslation } from 'react-i18next';
import { FunctionalityProvider } from '../../context/functionality';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import { PageTopBar } from '../../components/PageTopBar';
import React from 'react';
import { Box } from '@mui/material';
import {
  getHeightUnitFromMeasuringDevices,
  getWeightUnitFromMeasuringDevices,
  isNullOrUndefined,
} from '../../utils';
import { useEntityActionSnackbar } from '../../hooks';
import { PATIENTS_READ_POLICY } from '../../constants/Policies';

export function CreatePatientPage() {
  const { t } = useTranslation(['patient', 'common']);
  const { enqueueEntityCreatedSnackbar } = useEntityActionSnackbar();
  const navigate = useNavigate();
  const [{ data: identifierTypes, isLoading: areIdentifierTypesLoading }] = useGetApi<
    IdentifierType[]
  >(ApiRoutes.IdentifierTypes);
  const [{ data: departments, isLoading: aredepartmentsLoading }] = useGetApi<Department[]>(
    ApiRoutes.Departments,
  );

  const [{ data: measuringDevices, isLoading: areMeasuringDevicesLoading }] = useGetApi<
    MeasuringDeviceWithType[]
  >(ApiRoutes.MeasuringDevices);

  async function handleSubmit(values: PatientFormValues) {
    const input: PatientCreateInput = {
      ...values,
      email: values.email || null,
      phone: values.phone || null,
      addressStreet: values.addressStreet || null,
      addressState: values.addressState || null,
      identifierTypeId: values.identifierTypeId || null,
      identificationNumber: values.identificationNumber || null,
      civilStatus: values.civilStatus || null,
      weight: !isNullOrUndefined(values.weight) ? values.weight : null,
      height: !isNullOrUndefined(values.height) ? values.height : null,
    };
    return makeApiRequest<PatientCreateResult, PatientCreateInput>(
      'POST',
      ApiRoutes.Patients,
      input,
    );
  }

  async function handleSubmitCompleted(data?: PatientCreateResult) {
    if (!data) {
      navigate(-1);

      return;
    }

    navigate(`/patients/${data.id}`, { replace: true });

    enqueueEntityCreatedSnackbar(t('patient:patient', { context: data.sex }), {
      context: data.sex,
    });
  }

  if (areIdentifierTypesLoading || areMeasuringDevicesLoading || aredepartmentsLoading) {
    return <ProgressIndicator />;
  }

  if (identifierTypes == null) {
    return <div>{t('patient:error.identifierTypesNotFound')}</div>;
  }

  if (measuringDevices == null) {
    return <div>{t('common:error.measuringDevicesNotFound')}</div>;
  }

  if (departments == null) {
    return <div>{t('patient:error.departmentsNotFound')}</div>;
  }

  return (
    <FunctionalityProvider
      createFunctionality="PATIENTS_CREATE"
      readFunctionality={PATIENTS_READ_POLICY}
    >
      <>
        <StickyTopAppBar>
          <PageTopBar title={t('patient:title.new')} hideLastUpdated={true} />
        </StickyTopAppBar>
        <Box mt={2}>
          <Page requireFunctionality="PATIENTS_CREATE">
            <PatientForm
              onSubmit={handleSubmit}
              onSubmitCompleted={handleSubmitCompleted}
              identifierTypes={identifierTypes}
              departments={departments}
              weightUnit={getWeightUnitFromMeasuringDevices(measuringDevices)}
              heightUnit={getHeightUnitFromMeasuringDevices(measuringDevices)}
            />
          </Page>
        </Box>
      </>
    </FunctionalityProvider>
  );
}
