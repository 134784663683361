import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export function AlarmFormPartialTypesSchema(reminderId: number | undefined) {
  const { t: tAlarm } = useTranslation('alarm');
  return Yup.object({
    name: Yup.string(),
    typeIds: Yup.array()
      .of(Yup.number().required())
      .required()
      .min(1, tAlarm('alarmTypes.validation.minArrayValue', { min: 1 })),
    drugName: Yup.string().when('typeIds', {
      is: (val: number[]) => val.includes(reminderId ?? -1),
      then: Yup.string().nullable().required(tAlarm('note.validation.required')),
      otherwise: Yup.string().nullable().notRequired(),
    }),
  });
}
