import { Box } from '@mui/material';
import { theme } from '../../assets/style';

interface IconProps {
  width?: number;
  height?: number;
  primaryColor?: string;
  secondaryColor?: string;
}

export function PatientAlarmIcon({
  width,
  height,
  primaryColor = theme.palette.primary.main,
  secondaryColor = theme.palette.primary.dark,
}: IconProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <svg
        width={`${width ?? 16}`}
        height={`${height ?? 15}`}
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip093)">
          <path
            d="M12.9952 1.79949H12.3455V1.14975C12.3455 0.759898 12.0856 0.5 11.6957 0.5C11.3059 0.5 11.046 0.759898 11.046 1.14975V1.79949H4.54854V1.14975C4.54854 0.759898 4.28864 0.5 3.89879 0.5C3.50894 0.5 3.24904 0.759898 3.24904 1.14975V1.79949H1.94955C1.62468 1.79949 1.2998 2.05939 1.2998 2.44924V11.5457C1.2998 11.9355 1.62468 12.1954 1.94955 12.1954H5.913C5.45818 11.4157 5.19828 10.5061 5.19828 9.59645C5.19828 6.73756 7.53737 4.39848 10.3963 4.39848C11.6308 4.39848 12.7353 4.8533 13.645 5.56802V2.44924C13.645 2.12437 13.3201 1.79949 12.9952 1.79949Z"
            fill="url(#paint0_linear93)"
          />
          <path
            d="M10.4014 5.70312C8.25724 5.70312 6.50293 7.45744 6.50293 9.6016C6.50293 11.7458 8.25724 13.5001 10.4014 13.5001C12.5456 13.5001 14.2999 11.7458 14.2999 9.6016C14.2999 7.45744 12.5456 5.70312 10.4014 5.70312ZM11.7009 10.2513H10.4014C10.0116 10.2513 9.75166 9.99145 9.75166 9.6016V7.65236C9.75166 7.26252 10.0116 7.00262 10.4014 7.00262C10.7913 7.00262 11.0512 7.26252 11.0512 7.65236V8.95186H11.7009C12.0907 8.95186 12.3506 9.21175 12.3506 9.6016C12.3506 9.99145 12.0907 10.2513 11.7009 10.2513Z"
            fill="url(#paint1_linear93)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear93"
            x1="12.4566"
            y1="1.67643"
            x2="3.83315"
            y2="9.2973"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={primaryColor} />
            <stop offset="0.9996" stopColor={secondaryColor} />
          </linearGradient>
          <linearGradient
            id="paint1_linear93"
            x1="13.5493"
            y1="6.48741"
            x2="7.84663"
            y2="11.2619"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={primaryColor} />
            <stop offset="0.9996" stopColor={secondaryColor} />
          </linearGradient>
          <clipPath id="clip093">
            <rect width="15" height="14" fill="white" transform="translate(0.299805 0.5)" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
}
