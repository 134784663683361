import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  upperText: {
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(15.4),
    letterSpacing: theme.typography.pxToRem(-0.2),
    fontWeight: 600,
    color: theme.palette.primary.dark,
  },
  lowerText: {
    fontSize: theme.typography.pxToRem(24.6),
    lineHeight: theme.typography.pxToRem(26),
    letterSpacing: theme.typography.pxToRem(-0.25),
    fontWeight: 800,
    color: theme.palette.primary.dark,
  },
}));
