import { NumericFormat } from 'react-number-format';

interface Props {
  inputRef: (instance: typeof NumericFormat | null) => void;
  onChange: (event: { target: { name: string; value?: number } }) => void;
  name: string;
  thousandSeparator?: string;
  decimalSeparator?: string;
  prefix?: string;
  integerOnly?: number;
}

export function NumberFormatInput({
  inputRef,
  onChange,
  name,
  thousandSeparator,
  decimalSeparator,
  prefix,
  integerOnly,
  ...other
}: Props) {
  return (
    <NumericFormat
      {...other}
      name={name}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      prefix={prefix}
      decimalScale={integerOnly ? 0 : undefined}
    />
  );
}
