import React from 'react';
import { Device, Patient } from '../../models';
import { Box } from '@mui/material';
import { useStyles } from './styles';
import { PatientHeaderDevicePairingInfo } from './DevicePairingInfo';
import { PatientHeaderPatientProfile } from './PatientProfile';
import { PatientPairingButton } from '../../pages/patients/components';
import { MoreOptionsButton } from './MoreOptionsButton';
import { useRequireFunctionality } from '../../hooks';
import { PageTopBarDetailed } from '../PageTopBarDetailed';
import { PATIENTS_DELETE_POLICY, PATIENTS_UPDATE_POLICY } from '../../constants/Policies';

interface Props {
  patient: Patient;
  devices: Device[];
  onPairDone: () => Promise<void>;
  onUnpair: (conclusion: string) => Promise<void>;
  onUnpairCompleted: () => Promise<void>;
  onPatientArchiveCompleted: () => Promise<void>;
  lastUpdated: Date | null;
}

export function PatientHeader({
  patient,
  devices,
  onPairDone,
  onUnpair,
  onUnpairCompleted,
  onPatientArchiveCompleted,
  lastUpdated,
}: Props) {
  const classes = useStyles();
  const { checkHasFunctionality } = useRequireFunctionality();

  return (
    <PageTopBarDetailed
      entityName={`${patient.firstName} ${patient.lastName}`}
      lastUpdated={lastUpdated}
      middleSectionComponent={
        <>
          <Box className={classes.flexColumn} style={{ flex: 5 }}>
            <PatientHeaderPatientProfile
              patient={patient}
              sectionTitleClass={classes.sectionTitle}
            />
          </Box>
          <Box className={classes.flexColumn} style={{ flex: 3 }}>
            <PatientHeaderDevicePairingInfo
              patientDevicePairing={patient.devicePairing}
              sectionTitleClass={classes.sectionTitle}
              patientSex={patient.sex}
            />
          </Box>
        </>
      }
      buttonsComponent={
        <>
          <PatientPairingButton
            patient={patient}
            devices={devices}
            onPairDone={onPairDone}
            onUnpair={onUnpair}
            onUnpairCompleted={onUnpairCompleted}
          />
          {checkHasFunctionality([...PATIENTS_DELETE_POLICY, ...PATIENTS_UPDATE_POLICY]) && (
            <MoreOptionsButton
              onPatientArchiveCompleted={onPatientArchiveCompleted}
              patientSex={patient.sex}
            />
          )}
        </>
      }
    />
  );
}
