import { useField, useFormikContext } from 'formik';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Department, Room } from '../../models';
import { useFormikDisableContext } from '../../context/formikDisable';
import { FormControl, FormHelperText } from '@mui/material';
import { getInputErrorText } from '../InputErrorText';
import { useEffect, useState } from 'react';
import { PatientFormValues } from '../../pages/patients/PatientForm';

interface DepartmentRoomInputProps {
  id: string;
  label: string;
  required?: boolean;
  departments: Department[];
}

interface Option extends Room {
  id: string;
  departmentName: string;
}

export function DepartmentRoomInput({
  id,
  label,
  required,
  departments,
}: DepartmentRoomInputProps) {
  const [field, meta] = useField(id);
  const { setFieldValue, isSubmitting, values } = useFormikContext<PatientFormValues>();
  const [selectedRoom, setSelectedRoom] = useState<Option | null>(null);
  const { isDisabled } = useFormikDisableContext();
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    const selectedIds = values.departmentIds;
    const newOptions: Option[] = departments
      .filter((department) => selectedIds.includes(department.id))
      .flatMap((department) =>
        department.rooms.map((room) => ({ departmentName: department.name, ...room })),
      );
    setOptions(newOptions);
  }, [values.departmentIds, departments]);

  useEffect(() => {
    const roomOption = options.find((option) => option.id === field.value);
    setSelectedRoom(roomOption || null);
  }, [field.value, options]);

  const errorMessage = meta.touched ? meta.error : null;

  return options.length > 0 ? (
    <FormControl required={required} variant="outlined" fullWidth={true} margin="normal">
      <Autocomplete
        {...field}
        id={id}
        options={options.sort((a, b) => -b.departmentName.localeCompare(a.departmentName))}
        groupBy={(option) => option.departmentName}
        getOptionLabel={(option) => option.name}
        multiple={false}
        value={selectedRoom}
        onChange={(event, value) => {
          setFieldValue(field.name, value ? value.id : null);
          setSelectedRoom(value);
        }}
        disabled={isSubmitting || isDisabled}
        renderInput={(params) => (
          <TextField {...params} label={label} error={!!errorMessage} variant="outlined" />
        )}
      />
      <FormHelperText error={true}>{getInputErrorText(errorMessage)}</FormHelperText>
    </FormControl>
  ) : null;
}
