import React from 'react';
import { Box, InputAdornment } from '@mui/material';
import { Form, SubmitFormCompletedHandler, SubmitFormHandler } from '../../../components/Form';
import * as Yup from 'yup';
import { AutoComplete } from '../../../components/Autocomplete';
import { SaveSubmitButton } from '../../../components/SaveSubmitButton';
import { ReactComponent as AssignIcon } from '../../../assets/icons/AssignIcon.svg';
import { ReactComponent as TextIcon } from '../../../assets/icons/TextIcon.svg';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

export interface QuestionnaireAutocompleteOption {
  id: number;
  title: string;
}

export interface PatientQuestionnaireAssignFormValues {
  questionnaire: QuestionnaireFormSubvalues | null;
}

interface QuestionnaireFormSubvalues {
  id: number | null;
  title: string | null;
}

interface Props<TResponseData = never> {
  initialValues?: PatientQuestionnaireAssignFormValues;
  onSubmit: SubmitFormHandler<PatientQuestionnaireAssignFormValues, TResponseData>;
  onSubmitCompleted?: SubmitFormCompletedHandler<TResponseData>;
  questionnaireOptions: QuestionnaireAutocompleteOption[];
}

export function PatientQuestionnaireAssignForm({
  questionnaireOptions,
  onSubmit,
  onSubmitCompleted,
  initialValues,
}: Props) {
  const { t } = useTranslation(['common', 'questionnaire']);
  const classes = useStyles();
  const defaultFormValues: PatientQuestionnaireAssignFormValues = {
    questionnaire: null,
  };

  const schema: Yup.SchemaOf<PatientQuestionnaireAssignFormValues> = Yup.object({
    questionnaire: Yup.object({
      id: Yup.number().default(-1).nullable().required(),
      title: Yup.string().default('').nullable().required(),
    })
      .nullable()
      .required(),
  });

  return (
    <Form
      initialValues={initialValues || defaultFormValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      onSubmitCompleted={onSubmitCompleted}
      disableXPadding={true}
    >
      <Box className={classes.wrapper}>
        <AutoComplete<QuestionnaireAutocompleteOption>
          label=""
          options={questionnaireOptions}
          getOptionLabel={(option: string | QuestionnaireAutocompleteOption) =>
            typeof option === 'string' ? option : option.title
          }
          id="questionnaire"
          placeholder={t('questionnaire:autoComplete.placeholder')}
          noOptionsText={t('questionnaire:autoComplete.noOptions')}
          hideErrorMessage={true}
          classes={{ root: classes.root, option: classes.option, input: classes.input }}
          textFieldInputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ color: '#A3A9BD' }}
                >
                  <TextIcon />
                </Box>
              </InputAdornment>
            ),
          }}
        />
        <Box display="flex" justifyContent="center" alignItems="center" ml={2}>
          <SaveSubmitButton
            actionText={t('common:assign')}
            disabledTooltipText={t('questionnaire:autoComplete.submitDisableTooltip')}
            endIcon={<AssignIcon />}
            height={32}
            width={90}
            invertColors={true}
          />
        </Box>
      </Box>
    </Form>
  );
}
