import { Box } from '@mui/material';

interface IconProps {
  width?: number;
  height?: number;
  primaryColor?: string;
}

export function QuestionTypeDefaultIcon({ width, height, primaryColor }: IconProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <svg
        width={`${width ?? 30}`}
        height={`${height ?? 31}`}
        viewBox="0 0 30 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d)">
          <circle cx="15" cy="14.5" r="10" fill="white" />
          <path
            d="M19.0323 10.4839H10.9677C10.4355 10.4839 10 10.9194 10 11.4516C10 11.9839 10.4355 12.4194 10.9677 12.4194H19.0323C19.5645 12.4194 20 11.9839 20 11.4516C20 10.9194 19.5645 10.4839 19.0323 10.4839Z"
            fill="url(#paint0_linear)"
          />
          <path
            d="M19.0323 14.0322H10.9677C10.4355 14.0322 10 14.4677 10 15C10 15.5322 10.4355 15.9677 10.9677 15.9677H19.0323C19.5645 15.9677 20 15.5322 20 15C20 14.4677 19.5645 14.0322 19.0323 14.0322Z"
            fill="url(#paint1_linear)"
          />
          <path
            d="M19.0323 17.5806H10.9677C10.4355 17.5806 10 18.0161 10 18.5483C10 19.0806 10.4355 19.5161 10.9677 19.5161H19.0323C19.5645 19.5161 20 19.0806 20 18.5483C20 18.0161 19.5645 17.5806 19.0323 17.5806Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0.5"
            width="30"
            height="30"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="19.0374"
            y1="10.6786"
            x2="18.4062"
            y2="13.4087"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={`${primaryColor ?? '#6B6B6B'}`} />
            <stop offset="0.9996" stopColor={`${primaryColor ?? '#6B6B6B'}`} />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="19.0374"
            y1="14.2269"
            x2="18.4062"
            y2="16.957"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={`${primaryColor ?? '#6B6B6B'}`} />
            <stop offset="0.9996" stopColor={`${primaryColor ?? '#6B6B6B'}`} />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="19.0374"
            y1="17.7753"
            x2="18.4062"
            y2="20.5054"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={`${primaryColor ?? '#6B6B6B'}`} />
            <stop offset="0.9996" stopColor={`${primaryColor ?? '#6B6B6B'}`} />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}
