import { SystemEventPatient } from '../../../models';
import { SystemEventListItemContainer } from './SystemEventListItemContainer';
import { SystemEventListItemValues } from './SystemEventListItemValues';
import { SystemEventListItemEntityPropertyValue } from './SystemEventListItemEntityPropertyValue';
import { SystemEventListItemAction } from './SystemEventListItemAction';
import { useTranslation } from 'react-i18next';
import { SYSTEM_EVENT_ICON_SIZE } from './styles';
import { PatientCardIcon } from '../../../components/Icons';

interface Props {
  systemEvent: SystemEventPatient;
}

export function SystemEventPatientListItem({ systemEvent }: Props) {
  const { t } = useTranslation(['patient', 'systemEvent']);

  return (
    <SystemEventListItemContainer linkTo={`/patients/${systemEvent.entity.id}`}>
      <SystemEventListItemAction
        icon={<PatientCardIcon width={SYSTEM_EVENT_ICON_SIZE} height={SYSTEM_EVENT_ICON_SIZE} />}
        actionName={
          systemEvent.action === 'created'
            ? t('systemEvent:action.patientCreated')
            : t('systemEvent:action.patientUpdated')
        }
        systemEvent={systemEvent}
      />
      <SystemEventListItemValues>
        <SystemEventListItemEntityPropertyValue
          propertyName={t('patient:username.label')}
          propertyValue={systemEvent.entity.username}
        />
      </SystemEventListItemValues>
    </SystemEventListItemContainer>
  );
}
