import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  date: Date | null | undefined;
  calculateFrom?: Date;
  showTime?: boolean;
  showSeconds?: boolean;
}

export function RelativeDateTime({
  date,
  calculateFrom,
  showTime = true,
  showSeconds = false,
}: Props) {
  const { t } = useTranslation('common');

  function getRelativeDateString(someDateTimeStamp: Date, calculateFrom: Date = new Date()) {
    const today = new Date(calculateFrom);
    const yesterday = new Date(calculateFrom).setDate(today.getDate() - 1);

    if (someDateTimeStamp.toLocaleDateString() == today.toLocaleDateString()) {
      return t ? t('today') : 'Today';
    } else if (someDateTimeStamp.toLocaleDateString() == new Date(yesterday).toLocaleDateString()) {
      return t ? t('yesterday') : 'Yesterday';
    }

    return someDateTimeStamp.toLocaleDateString();
  }

  if (!date) {
    return <>-</>;
  }

  let dateTimeDisplayValue = `${getRelativeDateString(date, calculateFrom)}`;

  if (showTime) {
    dateTimeDisplayValue += ` ${date.toLocaleTimeString(
      [],
      !showSeconds
        ? {
            hour: '2-digit',
            minute: '2-digit',
          }
        : {},
    )}`;
  }

  return <>{dateTimeDisplayValue}</>;
}
