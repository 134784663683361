import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { FONT_SIZES, FONT_WEIGHTS } from '../../../../Constants';
import React from 'react';
import { DATE_TIME_LOCALE_WITHOUT_SECONDS } from '../../../../../constants';

interface Props {
  title: string;
  answeredAt: Date;
  description: string;
}

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
  },
  title: {
    fontSize: FONT_SIZES.h4,
    fontWeight: FONT_WEIGHTS.semibold,
  },
  answeredAt: {
    fontSize: FONT_SIZES.h5,
    fontWeight: FONT_WEIGHTS.light,
  },
  description: {
    fontSize: FONT_SIZES.h6,
  },
  smallMarginVertical: {
    marginVertical: 1,
  },
});

export function QuestionnaireInfo({ title, answeredAt, description }: Props) {
  return (
    <View style={styles.wrapper}>
      <Text style={[styles.smallMarginVertical, styles.title]}>{title}</Text>
      <Text style={[styles.smallMarginVertical, styles.answeredAt]}>
        {new Date(answeredAt).toLocaleString([], DATE_TIME_LOCALE_WITHOUT_SECONDS)}
      </Text>
      <Text style={[styles.smallMarginVertical, styles.description]}>{description}</Text>
    </View>
  );
}
