import { PatientAlarmIcon } from '../../../components/Icons';
import { AlarmRepetition } from '../../../components/AlarmRepetition';
import { SystemEventAlarm } from '../../../models';
import { SystemEventListItemContainer } from './SystemEventListItemContainer';
import { SystemEventListItemEntityPropertyValue } from './SystemEventListItemEntityPropertyValue';
import { SystemEventListItemActionPatient } from './SystemEventListItemActionPatient';
import { SystemEventListItemAction } from './SystemEventListItemAction';
import { SystemEventListItemValues } from './SystemEventListItemValues';
import { useTranslation } from 'react-i18next';
import { SYSTEM_EVENT_ICON_SIZE } from './styles';
import { alarmTypeNameOptionsSortFn } from '../../../utils';

interface Props {
  systemEvent: SystemEventAlarm;
}

export function SystemEventAlarmListItem({ systemEvent }: Props) {
  const { t } = useTranslation(['alarm', 'systemEvent']);
  const alarm = systemEvent.entity;
  const typeNames = alarm.types
    .sort(alarmTypeNameOptionsSortFn)
    .map((x) => t(`alarm:alarmType.${x}` as const))
    .join(', ');

  return (
    <SystemEventListItemContainer linkTo={`/patients/${alarm.patient.id}/alarms/${alarm.id}`}>
      <SystemEventListItemAction
        icon={<PatientAlarmIcon width={SYSTEM_EVENT_ICON_SIZE} height={SYSTEM_EVENT_ICON_SIZE} />}
        actionName={
          systemEvent.action === 'created'
            ? t('systemEvent:action.alarmCreated')
            : t('systemEvent:action.alarmUpdated')
        }
        systemEvent={systemEvent}
      />
      <SystemEventListItemValues>
        <SystemEventListItemActionPatient patient={alarm.patient} />
        <SystemEventListItemEntityPropertyValue
          propertyName={t('alarm:name.label')}
          propertyValue={alarm.drugName ?? typeNames}
        />
        <SystemEventListItemEntityPropertyValue
          propertyName={t('alarm:startOnDatetime.label')}
          propertyValue={new Date(alarm.startOnDatetime).toLocaleDateString([], {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })}
        />
        <SystemEventListItemEntityPropertyValue
          propertyName={t('alarm:endOnDatetime.label')}
          propertyValue={
            alarm.endOnDatetime
              ? new Date(alarm.endOnDatetime).toLocaleDateString([], {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                })
              : '-'
          }
        />
        <SystemEventListItemEntityPropertyValue
          propertyName={t('alarm:repetition.label')}
          propertyValue={
            <AlarmRepetition
              repetitionInterval={alarm.repetitionInterval}
              repetitionDays={alarm.repetitionDays}
            />
          }
          unlimitedWidth={true}
        />
        <SystemEventListItemEntityPropertyValue
          propertyName={t('alarm:type.label')}
          propertyValue={typeNames}
          unlimitedWidth={true}
        />
      </SystemEventListItemValues>
    </SystemEventListItemContainer>
  );
}
