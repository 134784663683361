import { Box } from '@mui/material';
import { useSystemEventListItemValuesStyles } from './styles';

interface Props {
  children: React.ReactNode;
}

export function SystemEventListItemValues({ children }: Props) {
  const classes = useSystemEventListItemValuesStyles();

  return <Box className={classes.container}>{children}</Box>;
}
