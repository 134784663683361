import { useTheme } from '@mui/material';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { FONT_SIZES } from '../../../../Constants';
import React from 'react';

interface Props {
  title: string;
  minPresenceAhead?: number;
}

export function SectionTitle({ title, minPresenceAhead = 50 }: Props) {
  const theme = useTheme();

  const styles = StyleSheet.create({
    title: {
      fontSize: FONT_SIZES.h3,
      paddingBottom: 5,
      color: theme.palette.grey['500'],
    },
  });

  return (
    <View style={styles.title} minPresenceAhead={minPresenceAhead}>
      <Text>{title}</Text>
    </View>
  );
}
