import { makeStyles } from '@mui/styles';

export const useTabPanelStyles = makeStyles(() => ({
  tabPanelDialogMinHeight: {
    height: 340,
    minHeight: 300,
  },
  childrenWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));
