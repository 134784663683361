import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const PX_TO_SPACING = 0.125;

export const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    display: 'flex',
    borderRadius: theme.spacing(0.5),
    width: '100%',
    boxShadow: `0px 0px 0px ${theme.spacing(PX_TO_SPACING)} #b3bad0`,
    padding: theme.spacing(1, 1.5),
    margin: theme.spacing(0.5, 0),
    transition: theme.transitions.create(['border', 'margin', 'boxShadow', 'backgroundColor'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      boxShadow: `0px 0px 0px ${theme.spacing(PX_TO_SPACING)} #669af6`,
      '& .MuiFormControlLabel-label:not(.Mui-disabled) > div': {
        color: '#0065f0',
      },
    },
  },
  formControlLabelRoot: {
    marginRight: 0,
    width: '100%',
  },
  formControlLabelLabel: {
    width: '100%',
  },
  checkboxLabel: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    paddingLeft: theme.spacing(1),
    fontWeight: 500,
    letterSpacing: theme.typography.pxToRem(0.3),
    textAlign: 'left',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    userSelect: 'none',
  },
  checkboxLabelIcon: {
    transition: theme.transitions.create(['color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
  },
  checkboxLabelIconNotActive: {
    color: 'transparent',
  },
  checkboxLabelIconActive: {
    color: '#009b51',
  },
  formControlActive: {
    boxShadow: `0px 0px 0px ${theme.spacing(PX_TO_SPACING * 2)} #009b51 !important`,
    backgroundColor: '#f5f8fe',
  },
  formControlNotActive: {},
  checkboxLabelActive: {
    color: '#0a3e9d',
  },
  checkboxLabelNotActive: {
    color: '#363b44',
  },
}));
