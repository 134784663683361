import { Box } from '@mui/material';

interface IconProps {
  width?: number;
  height?: number;
  primaryColor?: string;
}

export function QuestionTypeMultiselectIcon({ width, height, primaryColor }: IconProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <svg
        width={`${width ?? 30}`}
        height={`${height ?? 31}`}
        viewBox="0 0 30 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d)">
          <circle cx="15" cy="14.5" r="10" fill="white" />
          <path
            d="M15.2849 16.6274L12.1417 19.7705C11.9877 19.923 11.7865 20 11.5853 20C11.3842 20 11.183 19.923 11.029 19.7705L9.45739 18.1989C9.15092 17.8925 9.15092 17.3943 9.45739 17.0878C9.76385 16.7814 10.262 16.7814 10.5685 17.0878L11.5853 18.1031L14.1722 15.5162C14.4786 15.2098 14.9768 15.2098 15.2833 15.5162C15.5898 15.8227 15.5913 16.3193 15.2849 16.6274ZM15.2849 10.341L12.1417 13.4842C11.9877 13.6366 11.7865 13.7136 11.5853 13.7136C11.3842 13.7136 11.183 13.6366 11.029 13.4842L9.45739 11.9126C9.15092 11.6061 9.15092 11.1079 9.45739 10.8014C9.76385 10.495 10.262 10.495 10.5685 10.8014L11.5853 11.8167L14.1722 9.22985C14.4786 8.92338 14.9768 8.92338 15.2833 9.22985C15.5898 9.53631 15.5913 10.0329 15.2849 10.341ZM21.0149 18.4284H17.8717C17.438 18.4284 17.0859 18.0764 17.0859 17.6426C17.0859 17.2088 17.438 16.8568 17.8717 16.8568H21.0149C21.4487 16.8568 21.8007 17.2088 21.8007 17.6426C21.8007 18.0764 21.4487 18.4284 21.0149 18.4284ZM21.0149 12.142H17.8717C17.438 12.142 17.0859 11.79 17.0859 11.3562C17.0859 10.9225 17.438 10.5704 17.8717 10.5704H21.0149C21.4487 10.5704 21.8007 10.9225 21.8007 11.3562C21.8007 11.79 21.4487 12.142 21.0149 12.142Z"
            fill={`${primaryColor ?? '#6B6B6B'}`}
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0.5"
            width="30"
            height="30"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="2.5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
        </defs>
      </svg>
    </Box>
  );
}
