import { useSnackbar as useSnackbarExternal } from 'notistack';
import { useTranslation } from 'react-i18next';

interface Result {
  enqueueCommonErrorSnackbar: () => void;
  enqueueErrorSnackbar: (errorText: string) => void;
}

export function useErrorSnackbar(): Result {
  const { t } = useTranslation('error');
  const { enqueueSnackbar } = useSnackbarExternal();

  function enqueueCommonErrorSnackbar() {
    enqueueSnackbar(t('generic'), { variant: 'error' });
  }

  function enqueueErrorSnackbar(errorText: string) {
    enqueueSnackbar(errorText, { variant: 'error' });
  }

  return {
    enqueueCommonErrorSnackbar,
    enqueueErrorSnackbar,
  };
}
