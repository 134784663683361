import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useAlarmFormPartialCommonStyles = makeStyles((theme: Theme) => ({
  customErrorMessage: {
    color: theme.palette.error.main,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      textAlign: 'center',
    },
  },
  customErrorMessageMinHeight: {
    minHeight: '1.5em',
  },
}));
