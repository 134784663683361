import { createContext, useContext } from 'react';
import { SelectedQuestionIndexContextType } from './types';

export const SelectedQuestionIndexContext = createContext<SelectedQuestionIndexContextType>({
  selectedIndex: -1,
  previousSelectedIndex: -1,
  setSelectedIndex: () => {},
  isQuestionMoving: false,
  setIsQuestionMoving: () => {},
});
export const useSelectedQuestionIndex = () => useContext(SelectedQuestionIndexContext);
