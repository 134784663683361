import { Functionality } from '../../models';

export const PATIENT_QUESTIONNAIRES_READ_POLICY: Functionality[] = [
  'PATIENT_QUESTIONNAIRES_READ',
  'PATIENT_ASSIGNED_TO_MEDIC_QUESTIONNAIRES_READ',
];

export const PATIENT_QUESTIONNAIRES_DELETE_POLICY: Functionality[] = [
  'PATIENT_QUESTIONNAIRES_DELETE',
  'PATIENT_ASSIGNED_TO_MEDIC_QUESTIONNAIRES_DELETE',
];
