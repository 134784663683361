import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useAlarmFormPartialTimesStyles = makeStyles((theme: Theme) => ({
  alarmTimesWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  alarmTimesPartOfDayWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 200,
  },
}));
