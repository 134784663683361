import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { UserProfileStyleProps } from './UserProfileButton';

export const useStyles = makeStyles<Theme, UserProfileStyleProps>((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: theme.spacing(1),
    cursor: 'pointer',
    pointerEvents: ({ hasAccessToProfile }) => (hasAccessToProfile ? 'auto' : 'none'),
    '&:hover, &:focus': {
      color: theme.palette.primary.main,
      '&>*': {
        color: theme.palette.primary.main,
      },
    },
  },
  icon: {
    color: theme.palette.primary.A200,
    minHeight: 25,
    minWidth: 25,
    height: 25,
    width: 25,
    maxHeight: 25,
    maxWidth: 25,
  },
  profileName: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(11),
    fontWeight: 500,
    color: '#6f768b',
    lineHeight: 1.6,
    maxWidth: 70,
    maxHeight: 30,
    overflow: 'hidden',
    whiteSpace: 'pre-wrap',
    textOverflow: 'ellipsis',
  },
  containerActive: {
    color: theme.palette.primary.main,
    '&>*': {
      color: theme.palette.primary.main,
    },
  },
  link: {
    textDecoration: 'none',
    pointerEvents: ({ hasAccessToProfile }) => (hasAccessToProfile ? 'auto' : 'none'),
  },
}));
