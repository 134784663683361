import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export interface StyleProps {
  scrollTrigger: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxWidth: 950,
      margin: 'auto',
    },
  },
  accordionRoot: {
    width: '100%',
    padding: 0,
    backgroundColor: theme.palette.grey.A200,
  },
  accordionSummaryContent: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionDetailsRoot: {
    padding: theme.spacing(2, 2, 1),
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  flexRowPadding: {
    '&>*': {
      margin: theme.spacing(0, 0.6),
    },
    '&>*:first-child': {
      marginRight: theme.spacing(0.6),
      marginLeft: 0,
    },
    '&>*:last-child': {
      marginLeft: theme.spacing(0.6),
      marginRight: 0,
    },
  },
  sectionTitle: {
    display: 'flex',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.4,
    letterSpacing: -0.1,
    color: '#8794aa',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      paddingBottom: theme.spacing(1),
    },
  },
  sectionEntityNameWrapper: {
    minWidth: 0,
    paddingRight: 40,
    [theme.breakpoints.down('lg')]: {
      paddingRight: 0,
    },
  },
  entityNameWrapper: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  entityName: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: -0.96,
    color: theme.palette.primary.A700,
    overflow: 'hidden',
    whiteSpace: 'nowrap' /* Don't forget this one */,
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('lg')]: {
      display: 'inline-block',
    },
  },
  buttonsWrapper: {
    display: 'flex',
    height: '100%',
    alignItems: ({ scrollTrigger }) => (!scrollTrigger ? 'flex-end' : 'center'),
    justifyContent: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  lastUpdatedWrapper: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 500,
    lineHeight: 1.4,
    letterSpacing: -0.1,
    paddingBottom: theme.spacing(1),
  },
  lastUpdatedValue: {
    marginLeft: theme.spacing(0.25),
    fontWeight: 'bold',
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
}));
