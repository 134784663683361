import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PasswordInput } from '../../../../components/PasswordInput';

export function PasswordForm() {
  const { t } = useTranslation('installation');

  return (
    <>
      <Box>
        <PasswordInput id="newPassword" label={t('newPassword.label')} required={true} />
        <PasswordInput id="confirmPassword" label={t('confirmPassword.label')} required={true} />
      </Box>
    </>
  );
}
