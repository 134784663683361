import { Document, View } from '@react-pdf/renderer';
import { Measurement, MeasuringDeviceType, MeasuringDeviceWithType, Patient } from '../../models';
import { ValuesForExport } from '../../pages/patients/export';
import { MeasurementsTable } from './components/measurements/MeasurementsTable';
import { useTranslation } from 'react-i18next';
import {
  firstLetterToLower,
  getHeightUnitFromMeasuringDevices,
  getWeightUnitFromMeasuringDevices,
  mapMeasurementsToWebMeasurements,
} from '../../utils';
import React, { useMemo } from 'react';
import { PageWithHeaderAndFooter } from './components/common/PageWithHeaderAndFooter';
import { PatientDetail } from './components/detail/PatientDetail';
import { QuestionnaireResponses } from './components/questionnaires/QuestionnaireResponses';
import { PatientNotes } from './components/notes/PatientNotes';
import { SectionTitle } from './components/common/SectionTitle';
import { PatientDevicePairings } from './components/devicePairings/PatientDevicePairings';
import { commonReactPdfStyles } from './components/commonReactPdfStyles';

interface Props {
  patient: Patient;
  patientFetchDatetime: Date | null;
  valuesForExport: ValuesForExport;
}

export const ExportPatientPdf = React.memo(
  ({ patient, valuesForExport, patientFetchDatetime }: Props) => {
    const { t: tMeasurement } = useTranslation('measurement');

    const measurementsDateTimes = useMemo<number[]>(
      () =>
        (
          Object.values(valuesForExport.measurements)
            .flat()
            .filter((x) => x !== undefined) as Measurement[]
        ).map((x) => new Date(x.measuredOnDatetime).getTime()),
      [valuesForExport.measurements],
    );

    const measurementMinDate = useMemo(
      () =>
        measurementsDateTimes.length > 0 ? new Date(Math.min(...measurementsDateTimes)) : null,
      [measurementsDateTimes],
    );

    const measurementMaxDate = useMemo(
      () =>
        measurementsDateTimes.length > 0 ? new Date(Math.max(...measurementsDateTimes)) : null,
      [measurementsDateTimes],
    );

    const pageHeaderData = useMemo(
      () => ({
        patient: patient,
        exportMeasurementsFormValues: valuesForExport.exportFormValues,
        measurementMinDate: measurementMinDate,
        measurementMaxDate: measurementMaxDate,
      }),
      [patient, valuesForExport, measurementMinDate, measurementMaxDate],
    );

    const devicesForExport = useMemo<MeasuringDeviceWithType[]>(
      () =>
        valuesForExport.measuringDevices.filter((x) =>
          valuesForExport.exportFormValues.deviceIds.includes(x.id),
        ),
      [valuesForExport.exportFormValues.deviceIds],
    );

    return (
      <Document>
        <PageWithHeaderAndFooter pageHeaderData={pageHeaderData}>
          {valuesForExport.exportFormValues.includePatientDetail && (
            <PatientDetail
              patient={patient}
              patientFetchDatetime={patientFetchDatetime}
              identifierTypes={valuesForExport.identifierTypes}
              weightUnit={getWeightUnitFromMeasuringDevices(valuesForExport.measuringDevices)}
              heightUnit={getHeightUnitFromMeasuringDevices(valuesForExport.measuringDevices)}
            />
          )}
          {valuesForExport.exportFormValues.includePairings &&
            valuesForExport.hasPatientDevicePairingsReadFunctionality && (
              <PatientDevicePairings
                patientDevicePairings={valuesForExport.patientDevicePairings}
              />
            )}
          {valuesForExport.exportFormValues.includeNotes &&
            valuesForExport.hasNotesReadFunctionality && (
              <PatientNotes patientNotes={valuesForExport.patientNotes} />
            )}
          {valuesForExport.exportFormValues.includeAnsweredQuestionnaires &&
            valuesForExport.hasQuestionnaireReadFunctionality && (
              <QuestionnaireResponses
                questionnaireResponses={valuesForExport.questionnaireResponses}
              />
            )}
          {devicesForExport.length > 0 &&
            devicesForExport.map((x) => {
              return (
                <View key={x.id} style={commonReactPdfStyles.sectionView}>
                  <SectionTitle
                    title={tMeasurement(
                      `measuringDevices.${firstLetterToLower(
                        x.name as MeasuringDeviceType,
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      )}` as any,
                    )}
                  />
                  <MeasurementsTable
                    measurements={mapMeasurementsToWebMeasurements(
                      valuesForExport.measurements[x.name as MeasuringDeviceType],
                    )}
                    measuringDevice={x.name as MeasuringDeviceType}
                  />
                </View>
              );
            })}
        </PageWithHeaderAndFooter>
      </Document>
    );
  },
);
