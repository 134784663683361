import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export interface StyleProps {
  isDrawerOpen: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  navigationItemWrapper: {
    lineHeight: theme.typography.pxToRem(15),
    borderRadius: theme.typography.pxToRem(4),
    '&:hover': {
      backgroundColor: 'inherit',
      '& .MuiListItemIcon-root, & .MuiListItemText-root': {
        color: `${theme.palette.primary.main} !important`,
      },
    },
  },
  listItemIconRoot: {
    minWidth: theme.typography.pxToRem(30),
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listItemWrapper: {
    display: 'flex',
    borderRadius: theme.typography.pxToRem(4),
    height: theme.typography.pxToRem(45),
    justifyContent: ({ isDrawerOpen }) => (isDrawerOpen ? 'flex-start' : 'flex-start'),
    width: '100%',
  },
  navigationItemText: {
    fontWeight: 'normal',
    letterSpacing: theme.typography.pxToRem(0.24),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
  },
  navigationItemTextSelected: {
    fontWeight: 600,
  },
  navigationItemIconAndText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: ({ isDrawerOpen }) => (isDrawerOpen ? 'center' : 'flex-end'),
    gap: theme.typography.pxToRem(12),
    padding: ({ isDrawerOpen }) => theme.spacing(1, isDrawerOpen ? 2 : 1),
    borderRadius: theme.typography.pxToRem(4),
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(12),
    letterSpacing: theme.typography.pxToRem(0.24),
    fontWeight: 500,
  },
}));
