import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import { ProgressIndicator } from '../../components/ProgressIndicator';
import { Questionnaire } from '../../models';
import { QuestionnairesGrid } from '../../components/Grids';
import { Page } from '../../components/Page';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { FunctionalityProvider } from '../../context/functionality';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import { PageTopBar } from '../../components/PageTopBar';
import { isSubstring } from '../../utils';
import { useEntityActionSnackbar } from '../../hooks';
import { Box } from '@mui/material';

export function ListQuestionnairesPage() {
  const { t } = useTranslation('questionnaire');
  const { enqueueEntityDeletedSnackbar } = useEntityActionSnackbar();
  const [
    { data: questionnaires, isError, isLoading },
    { refetch: refetchQuestionnaires },
    { lastFetchDatetime },
  ] = useGetApi<Questionnaire[]>(ApiRoutes.Questionnaires);
  const navigate = useNavigate();
  const [filteredQuestionnaires, setFilteredQuestionnaires] = useState(questionnaires);

  useEffect(() => {
    setFilteredQuestionnaires(questionnaires);
  }, [questionnaires]);

  async function handleDelete(id: number) {
    await makeApiRequest('DELETE', ApiRoutes.Questionnaire(id));
  }

  async function handleDeleteCompleted() {
    await refetchQuestionnaires();
    enqueueEntityDeletedSnackbar(t('questionnaire'));
  }

  function onRowClick(row: Questionnaire) {
    navigate(`/questionnaires/${row.id}`);
  }

  function handleSearch(searchedText: string) {
    if (!questionnaires) {
      setFilteredQuestionnaires([]);
    } else {
      if (searchedText === '') {
        setFilteredQuestionnaires(questionnaires);
      } else {
        const searchedTextToLower = searchedText.toLowerCase();
        const searchedMedics = questionnaires.filter((questionnaire) => {
          const { title, description } = questionnaire;
          return (
            isSubstring(title, searchedTextToLower) || isSubstring(description, searchedTextToLower)
          );
        });
        setFilteredQuestionnaires(searchedMedics);
      }
    }
  }

  if (isLoading) {
    return <ProgressIndicator />;
  }

  return (
    <FunctionalityProvider
      createFunctionality="QUESTIONNAIRE_TEMPLATES_CREATE"
      readFunctionality="QUESTIONNAIRE_TEMPLATES_READ"
      updateFunctionality="QUESTIONNAIRE_TEMPLATES_UPDATE"
      deleteFunctionality="QUESTIONNAIRE_TEMPLATES_DELETE"
    >
      <>
        <StickyTopAppBar>
          <PageTopBar
            title={t('title.list')}
            addNewRedirectPath={'/questionnaires/new'}
            newRecordDescriptionText={t('title.new')}
            onSearch={handleSearch}
            lastUpdated={lastFetchDatetime}
          />
        </StickyTopAppBar>
        <Page noPaper={true} fullWidth={true} requireFunctionality="QUESTIONNAIRE_TEMPLATES_READ">
          <Box width="100%">
            {!isLoading && !isError && filteredQuestionnaires && (
              <QuestionnairesGrid
                data={filteredQuestionnaires}
                onDelete={handleDelete}
                onDeleteCompleted={handleDeleteCompleted}
                onRowClick={onRowClick}
              />
            )}
          </Box>
        </Page>
      </>
    </FunctionalityProvider>
  );
}
