import * as Yup from 'yup';
import { Box, Divider, Hidden } from '@mui/material';
import { isValid, parseISO } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AlarmType, AlarmTypeEnum, AlarmTypeWithLabel, Optional } from '../../models';
import { alarmTypeOptionsSortFn, getTodayDateWithCertainHour } from '../../utils';
import { CancelButton } from '../CancelButton';
import { Form, SubmitFormCompletedHandler, SubmitFormHandler } from '../Form';
import { FormButton } from '../FormButton';
import StethoscopeIcon from '../../assets/icons/StethoscopeIcon.svg';
import CalendarIcon from '../../assets/icons/CalendarIcon.svg';
import { IconTitle } from '../IconTitle';
import {
  AlarmFormPartialRepetition,
  AlarmFormPartialRepetitionSchema,
  AlarmFormPartialTimes,
  AlarmFormPartialTimesSchema,
  AlarmFormPartialTypes,
  AlarmFormPartialTypesSchema,
} from '../AlarmFormPartial';
import { AlarmTypesTabs } from '../../types';

interface TriggerTimes {
  morning: boolean;
  morningTimes: Date[];
  afternoon: boolean;
  afternoonTimes: Date[];
  evening: boolean;
  eveningTimes: Date[];
}

export interface AlarmFormValues {
  drugName?: Optional<string>;
  typeIds: number[];
  triggerTimes: TriggerTimes;
  repetitionDays: number[];
  repetitionIntervalIsActive: boolean;
  repetitionInterval?: number;
  startOnDatetime?: Date;
  endOnDatetime?: Optional<Date>;
}

interface Props {
  alarmTypes: AlarmType[];
  initialValues?: AlarmFormValues;
  onSubmit: SubmitFormHandler<AlarmFormValues>;
  onSubmitCompleted: SubmitFormCompletedHandler;
  autocompleteDrugNames: string[];
  onCancel?: () => void;
  isNewAlarm?: boolean;
}

export function AlarmForm({
  onSubmit,
  onSubmitCompleted,
  onCancel,
  initialValues,
  alarmTypes,
  autocompleteDrugNames,
  isNewAlarm = false,
}: Props) {
  const today = new Date(new Date().setUTCHours(0, 0, 0, 0));
  const { t: tAlarm } = useTranslation('alarm');
  const { t: tCommon } = useTranslation('common');

  const reminderId = alarmTypes.find((alarmType) => alarmType.type === AlarmTypeEnum.REMINDER)?.id;

  const [typeStepSelectedTab, setTypeStepSelectedTab] = React.useState(
    reminderId && initialValues?.typeIds.includes(reminderId)
      ? AlarmTypesTabs.Notes
      : AlarmTypesTabs.Sensor,
  );

  const defaultFormValues: AlarmFormValues = {
    drugName: '',
    typeIds: [],
    triggerTimes: {
      morning: false,
      morningTimes: [getTodayDateWithCertainHour(8) as Date],
      afternoon: false,
      afternoonTimes: [getTodayDateWithCertainHour(12) as Date],
      evening: false,
      eveningTimes: [getTodayDateWithCertainHour(18) as Date],
    },
    repetitionDays: [],
    repetitionIntervalIsActive: false,
    repetitionInterval: 2,
    startOnDatetime: today,
    endOnDatetime: null,
  };

  const alarmTypeOptions: AlarmTypeWithLabel[] = alarmTypes
    .sort(alarmTypeOptionsSortFn)
    .map((alarmType) => ({
      id: alarmType.id,
      type: alarmType.type,
      label: tAlarm(`alarmType.${alarmType.type}` as const),
    }));

  const schema: Yup.SchemaOf<AlarmFormValues> = AlarmFormPartialTypesSchema(reminderId)
    .concat(AlarmFormPartialRepetitionSchema())
    .concat(AlarmFormPartialTimesSchema());

  const getStartAndEndDateTimeStringValue = (values: AlarmFormValues) => {
    let val = '';

    if (
      values.startOnDatetime &&
      isValid(new Date(values.startOnDatetime)) &&
      isValid(parseISO(new Date(values.startOnDatetime).toISOString()))
    ) {
      val += `${tCommon('from', {
        field: new Date(values.startOnDatetime).toLocaleDateString(),
      })}`;
      if (
        values.endOnDatetime &&
        isValid(new Date(values.endOnDatetime)) &&
        isValid(parseISO(new Date(values.endOnDatetime).toISOString()))
      ) {
        val += ` ${tCommon('to', {
          field: new Date(values.endOnDatetime).toLocaleDateString(),
        })}`;
      }
    }

    return val != '' ? val : tCommon('notSelected');
  };

  return (
    <Form<AlarmFormValues>
      initialValues={initialValues || defaultFormValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      onSubmitCompleted={onSubmitCompleted}
      formMode={isNewAlarm ? 'editable' : 'toggle'}
    >
      {({ values }) => {
        return (
          <Box display="flex" flexDirection="column" width="100%">
            <Box display="flex" alignItems="center">
              <Hidden smDown={true}>
                <img src={StethoscopeIcon} alt="Stethoscope icon" />
              </Hidden>
              <IconTitle
                upperText={tCommon('choose')}
                lowerText={tAlarm('alarmTypes.label', { count: 2 })}
              />
            </Box>
            <AlarmFormPartialTypes
              alarmTypeOptions={alarmTypeOptions}
              reminderId={reminderId}
              autocompleteDrugNames={autocompleteDrugNames}
              selectedTab={typeStepSelectedTab}
              setSelectedTab={setTypeStepSelectedTab}
            />
            <AlarmFormPartialTimes />
            <Box my={1}>
              <Divider />
            </Box>
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
              <Box display="flex">
                <Hidden smDown={true}>
                  <Box>
                    <img src={CalendarIcon} alt="Calendar icon" />
                  </Box>
                </Hidden>
                <IconTitle
                  upperText={tAlarm('interval')}
                  lowerText={getStartAndEndDateTimeStringValue(values)}
                />
              </Box>
            </Box>
            <AlarmFormPartialRepetition />
            <Box display="flex" justifyContent={'flex-end'} marginTop={4}>
              {onCancel && (
                <Box marginRight={2}>
                  <CancelButton onClick={onCancel} />
                </Box>
              )}
              <FormButton />
            </Box>
          </Box>
        );
      }}
    </Form>
  );
}
