import { ApiRoutes, useGetApi } from '../../../services/api';
import { PatientDevicePairing } from '../../../models';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';
import { RequireFunctionality } from '../../../components/RequireFunctionality';
import { FunctionalityProvider } from '../../../context/functionality';
import { PatientPairingsGrid } from '../../../components/Grids/PatientPairingsGrid';
import { GridSkeleton } from '../../../components/GridSkeleton';
import { Box } from '@mui/material';

export function ListPairingsSubPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [{ data: patientDevicePairings, isLoading: arePatientDevicePairingsLoading }] = useGetApi<
    PatientDevicePairing[]
  >(ApiRoutes.PatientDevicePairings(id as string));

  function handleRowClick(row: PatientDevicePairing) {
    navigate(`/patients/${id}/pairings/${row.id}`);
  }

  if (arePatientDevicePairingsLoading) {
    return (
      <Box mx={1}>
        <GridSkeleton />
      </Box>
    );
  }

  return (
    <RequireFunctionality functionality={'PATIENT_DEVICE_PAIRINGS_READ'}>
      <FunctionalityProvider readFunctionality={'PATIENT_DEVICE_PAIRINGS_READ'}>
        <PatientPairingsGrid
          patientPairings={patientDevicePairings ?? []}
          onRowClick={handleRowClick}
        />
      </FunctionalityProvider>
    </RequireFunctionality>
  );
}
