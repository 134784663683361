import React from 'react';
import { ResetPasswordButton } from '../ResetPasswordButton';
import { Typography } from '@mui/material';
import { useResetPasscodeButtonStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { Variant } from '../Button';
import { ClassNameMap } from '@mui/styles/withStyles/withStyles';

export type ResetPasscodeHandler = (medicId?: number) => Promise<string | null>;
export type ResetPasscodeCompletedHandler = () => Promise<void>;

interface Props {
  medicId?: number;
  buttonText?: string;
  dialogTitle?: string;
  onResetPasscode: ResetPasscodeHandler;
  onResetPasscodeCompleted?: ResetPasscodeCompletedHandler;
  isOpen?: boolean;
  color?: 'primary' | 'secondary';
  showStartIcon?: boolean;
  fullWidth?: boolean;
  variant?: Variant;
  classes?: Partial<ClassNameMap>;
}

export function ResetPasscodeButton({
  medicId,
  onResetPasscode,
  onResetPasscodeCompleted,
  buttonText,
  dialogTitle,
  isOpen = false,
  color,
  showStartIcon,
  fullWidth,
  variant,
  classes,
}: Props) {
  const { t } = useTranslation('profile');
  const componentClasses = useResetPasscodeButtonStyles();

  function handleResetPasscode() {
    return onResetPasscode(medicId);
  }

  return (
    <ResetPasswordButton
      buttonText={buttonText ?? t('passcode.resetButton')}
      onResetPassword={handleResetPasscode}
      onResetPasswordCompleted={onResetPasscodeCompleted}
      dialogTitle={dialogTitle ?? t('passcode.label')}
      color={color ?? 'primary'}
      dialogContent={(password) => (
        <Typography variant="h4" className={componentClasses.passcode}>
          {password}
        </Typography>
      )}
      isOpen={isOpen}
      showStartIcon={showStartIcon}
      fullWidth={fullWidth}
      variant={variant}
      classes={classes}
    />
  );
}
