import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { areUrlSearchParamsEqual } from '../utils';
import { GRID_DEFAULT_PAGE_SIZE, URL_PAGE } from '../constants';

export function useGridUrlPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(GRID_DEFAULT_PAGE_SIZE);
  const [currentRowsCount, setCurrentRowsCount] = useState<number>(0);

  useEffect(() => {
    if (currentRowsCount > 0) {
      const searchQuery = qs.parse(qs.pick(location.search, [URL_PAGE]), { arrayFormat: 'comma' });

      // get PAGE from url
      if (URL_PAGE in searchQuery) {
        const pageQuery = searchQuery[URL_PAGE];
        if (pageQuery) {
          // extract from array, if is in array
          const pageFromQuery = Array.isArray(pageQuery) ? pageQuery[0] : pageQuery;
          // check if is valid number, otherwise 0
          let _page = Number.isInteger(Number(pageFromQuery)) ? Number(pageFromQuery) - 1 : 0;
          // check if it is not negative number or it is bigger than max page count
          const maxPage = Math.ceil(currentRowsCount / pageSize) - 1;
          if (_page < 0) {
            _page = 0;
          } else if (_page > maxPage) {
            _page = maxPage;
          }

          setCurrentPage(_page);
        }
      } else {
        goToFirstPage();
      }
    }
  }, [location.search, currentRowsCount]);

  function handlePageChange(page: number) {
    const searchQuery = qs.parse(location.search, { arrayFormat: 'comma' });
    searchQuery[URL_PAGE] = String(page + 1);

    const currentPageQuery = qs.parse(qs.pick(location.search, [URL_PAGE]), {
      arrayFormat: 'comma',
    });

    if (!areUrlSearchParamsEqual(currentPageQuery[URL_PAGE], searchQuery[URL_PAGE])) {
      navigate(`${location.pathname}?${qs.stringify(searchQuery, { arrayFormat: 'comma' })}`, {
        replace: true,
      });
    }
  }

  function goToFirstPage() {
    if (currentPage != 0) {
      handlePageChange(0);
    }
  }

  return {
    currentPage,
    onPageChange: handlePageChange,
    goToFirstPage,
    setCurrentPage,
    pageSize: pageSize,
    onPageSizeChange: setPageSize,
    onCurrentRowsCountChange: setCurrentRowsCount,
  };
}
