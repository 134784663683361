import { useStyles } from './styles';
import { Box, Divider } from '@mui/material';
import { UserProfileButton } from '../../UserProfileButton';
import { useDashboardMenuContext } from '../../../context/dashboardMenu';
import { DashboardFooterActions } from '../FooterActions';

export function DashboardFooter() {
  const { isDrawerOpen } = useDashboardMenuContext();
  const classes = useStyles({ isDrawerOpen: isDrawerOpen });

  return (
    <Box className={classes.container}>
      <UserProfileButton />
      {isDrawerOpen && (
        <>
          <Box px={2} height="70%">
            <Divider orientation="vertical" variant="middle" />
          </Box>
          <DashboardFooterActions />
        </>
      )}
    </Box>
  );
}
