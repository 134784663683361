import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { enUS, ka, mk, sk, sr, sq, ro, cs } from './locales';
import { Language, Translations } from './types';

type Resources = {
  [key in Language]: Translations;
};

const FALLBACK_LANGUAGE: Language = 'enUS';
const resources: Resources = {
  enUS,
  // non-english translations might be missing some keys / translations,
  // but that's ok because it fallbacks to english translation
  // eslint-disable-next-line
  ka: ka as any,
  // eslint-disable-next-line
  mk: mk as any,
  // eslint-disable-next-line
  sk: sk as any,
  // eslint-disable-next-line
  sr: sr as any,
  // eslint-disable-next-line
  sq: sq as any,
  // eslint-disable-next-line
  ro: ro as any,
  // eslint-disable-next-line
  cs: cs as any,
};

export function init() {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: FALLBACK_LANGUAGE,
      resources,

      debug: process.env.NODE_ENV === 'development',

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },

      detection: {
        order: [
          'navigator',
          'querystring',
          'cookie',
          'localStorage',
          'htmlTag',
          'path',
          'subdomain',
        ],
      },
    });

  i18n.options.react = {
    ...i18n.options.react,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'b', 'code'],
  };
}

export function getCurrentLanguage(): Language {
  let detectedLanguage = i18n.language as Language;
  detectedLanguage = detectedLanguage.split('-', 1)[0] as Language;

  if (resources[detectedLanguage]) {
    return detectedLanguage;
  }

  return FALLBACK_LANGUAGE;
}
