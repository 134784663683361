import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  questionAnswerOption: {
    boxShadow: `0 ${theme.typography.pxToRem(1)} ${theme.typography.pxToRem(3)} rgba(0, 0, 0, 0.1)`,
    borderRadius: theme.typography.pxToRem(15),
    fontWeight: 900,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(23),
    padding: theme.spacing(0.5, 1),
  },
  selectedQuestionAnswerOption: {
    color: theme.palette.text.primary,
  },
  notSelectedQuestionAnswerOption: {
    color: '#CCC',
    fontWeight: 'normal',
  },
}));
