import { SelectInput, SelectInputProps, SelectOption } from '../SelectInput';
import { UserRole } from '../../models';

type Props = Omit<SelectInputProps, 'options'> & { userRoles: UserRole[] };

export function UserRoleInput(props: Props) {
  const { userRoles } = props;
  return <SelectInput {...props} options={userRolesToSelectOptions(userRoles)} />;
}

function userRolesToSelectOptions(userRoles: UserRole[]): SelectOption[] {
  return userRoles.map((q) => ({
    value: q.id,
    label: q.name,
  }));
}
