import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useCustomLegendStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  defaultLegendWrapper: {
    '& > div': {
      position: 'relative !important',
    },
  },
}));

export const useGlucometerLegendStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  defaultLegendWrapper: {
    '& > div': {
      position: 'relative !important',
    },
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
}));

export const useThresholdsLegendStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  defaultLegendWrapper: {
    '& > div': {
      position: 'relative !important',
    },
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
}));

export const useGraphStyles = makeStyles((theme: Theme) => ({
  graphWrapper: {
    display: 'block',
    '& .recharts-legend-wrapper': {
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0',
      },
    },
  },
}));

export const useMeasurementTooltipStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.typography.pxToRem(5),
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.grey['300']}`,
    margin: theme.spacing(0.5, 1),
    fontFamily: 'Inter',
    letterSpacing: '-0.2px',
  },
  dateAndTimeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: theme.spacing(2),
    backgroundColor: '#e9ebf2',
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(1, 1.5),
  },
  dateContainer: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  timeContainer: {
    fontWeight: 'normal',
    color: '#aabdf3',
  },
  measurementTypeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: theme.spacing(2),
    padding: theme.spacing(0.5, 1.5),
  },
  measurementTypeKey: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    letterSpacing: theme.typography.pxToRem(-0.48),
  },
  measurementTypeValue: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    letterSpacing: theme.typography.pxToRem(-0.56),
  },
  measurementUnit: {
    fontSize: theme.typography.pxToRem(10),
    letterSpacing: theme.typography.pxToRem(-0.17),
    paddingLeft: theme.spacing(0.2),
  },
}));
