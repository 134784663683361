import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const ReminderIcon = React.memo(function ReminderIconComponent({ width, height }: Props) {
  return (
    <svg
      width={width || 18}
      height={height || 19}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5047 0.942647C13.7619 -0.314216 15.7998 -0.314216 17.0571 0.942647C18.3139 2.19989 18.3139 4.23785 17.0571 5.49505C16.8056 5.74644 16.3981 5.74644 16.1466 5.49505L12.5047 1.85312C12.2533 1.6017 12.2533 1.19411 12.5047 0.942647ZM5.46722 0.94346C4.19417 -0.297896 2.15587 -0.272203 0.914473 1.00085C-0.304699 2.25114 -0.30485 4.24542 0.914171 5.4959C1.16563 5.74729 1.57322 5.74729 1.82464 5.4959L5.46722 1.85397C5.71861 1.60251 5.71861 1.19492 5.46722 0.94346ZM14.0996 14.5452C16.8061 11.7208 16.7107 7.23717 13.8863 4.53062C11.062 1.82406 6.57832 1.91955 3.87176 4.74386C1.24636 7.48354 1.24636 11.8055 3.87176 14.5452L2.03146 16.9985C1.81811 17.283 1.87576 17.6866 2.16023 17.8999C2.44471 18.1133 2.84833 18.0556 3.06169 17.7712L4.84532 15.3926C7.31279 17.1904 10.6585 17.1904 13.1259 15.3926L14.9096 17.7712C15.1229 18.0557 15.5266 18.1133 15.811 17.8999C16.0955 17.6866 16.1532 17.2829 15.9398 16.9985L14.0996 14.5452ZM9.62961 9.65793C9.62961 9.79727 9.58441 9.93283 9.50084 10.0443L7.56912 12.6199C7.35576 12.9044 6.95214 12.9621 6.66766 12.7487C6.38319 12.5353 6.3255 12.1317 6.53889 11.8472L8.34184 9.44352V5.7945C8.34184 5.43886 8.63013 5.15058 8.98576 5.15058C9.3414 5.15058 9.62968 5.43886 9.62968 5.7945V9.65793H9.62961Z"
        fill="#6B6B6B"
      />
    </svg>
  );
});
