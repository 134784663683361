import { Routes, Route, useLocation } from 'react-router-dom';
import { LoginPage, ResetPasswordPage } from '../../pages/auth';
import { Dashboard } from '../Dashboard/Dashboard';
import { ProtectedRoute } from '../ProtectedRoute';
import { useEffect } from 'react';
import { ROUTES, RouteWithName, THRESHOLD_ROUTES } from '../../constants';
import { sendAmplitudeEvent } from '../../utils/amplitude';
import { LogoutPage } from '../../pages/auth/logout';
import { ForgottenPasswordPage } from '../../pages/auth/forgottenPassword';
import { InstallationPage } from '../../pages/instalation';

export function AppRoutes() {
  const location = useLocation();

  useEffect(() => {
    trackAmplitude();
  }, [history]);

  function trackAmplitude() {
    const path = getPath();
    if (path) {
      sendAmplitudeEvent(path.name, {
        route: path.path,
        path: location.pathname,
        search: location.search,
      });
    }
  }

  function getPath(): RouteWithName | null {
    let resultNamedRoute: RouteWithName | null = null;
    for (const namedRoute of Object.values(THRESHOLD_ROUTES)) {
      if (location.pathname === namedRoute.path) {
        resultNamedRoute = { ...namedRoute, name: namedRoute.name };
        break; // Assuming exact match is needed
      }
    }

    if (!resultNamedRoute) {
      for (const namedRoute of Object.values(ROUTES)) {
        if (location.pathname === namedRoute.path) {
          resultNamedRoute = { ...namedRoute };
          break; // Assuming exact match is needed
        }
      }
    }

    return resultNamedRoute;
  }

  return (
    <Routes>
      <Route
        path={ROUTES.DASHBOARD.path as string}
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route path={ROUTES.INSTALLATION.path as string} element={<InstallationPage />}></Route>
      <Route path={ROUTES.LOGIN.path as string} element={<LoginPage />}></Route>
      <Route path={ROUTES.LOGOUT.path as string} element={<LogoutPage />}></Route>
      <Route path={ROUTES.RESET_PASSWORD.path as string} element={<ResetPasswordPage />}></Route>
      <Route
        path={ROUTES.FORGOTTEN_PASSWORD.path as string}
        element={<ForgottenPasswordPage />}
      ></Route>
    </Routes>
  );
}
