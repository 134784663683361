import { Box } from '@mui/material';
import React from 'react';
import { ErrorMessage } from 'formik';
import WarningIcon from '@mui/icons-material/Warning';
import { useThresholdsErrorMessagesStyles } from './styles';

interface Props {
  measurementType: string | null;
}

export function ThresholdsErrorMessages({ measurementType }: Props) {
  const classes = useThresholdsErrorMessagesStyles();

  if (measurementType == null) {
    return <></>;
  }

  return (
    <Box>
      <ErrorMessage name={`${measurementType}.maxError`} component="div">
        {(errorMessage) => (
          <Box className={classes.errorMessageWrapper}>
            <WarningIcon />
            <Box>{errorMessage}</Box>
          </Box>
        )}
      </ErrorMessage>
      <ErrorMessage name={`${measurementType}.maxWarning`} component="div">
        {(errorMessage) => (
          <Box className={classes.errorMessageWrapper}>
            <WarningIcon />
            <Box>{errorMessage}</Box>
          </Box>
        )}
      </ErrorMessage>
      <ErrorMessage name={`${measurementType}.minWarning`} component="div">
        {(errorMessage) => (
          <Box className={classes.errorMessageWrapper}>
            <WarningIcon />
            <Box>{errorMessage}</Box>
          </Box>
        )}
      </ErrorMessage>
      <ErrorMessage name={`${measurementType}.minError`} component="div">
        {(errorMessage) => (
          <Box className={classes.errorMessageWrapper}>
            <WarningIcon />
            <Box>{errorMessage}</Box>
          </Box>
        )}
      </ErrorMessage>
    </Box>
  );
}
