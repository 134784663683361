import React from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

export const MovementPainIcon = React.memo(function MovementPainIconComponent({
  width,
  height,
  color,
}: Props) {
  return (
    <svg
      width={width || 25}
      height={height || 25}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M60 32C60 47.464 47.464 60 32 60C16.536 60 4 47.464 4 32C4 16.536 16.536 4 32 4C47.464 4 60 16.536 60 32ZM56.9103 32C56.9103 45.7576 45.7576 56.9103 32 56.9103C29.2382 56.9103 26.5814 56.4609 24.0987 55.6312C28.9032 54.8814 32.5793 50.7252 32.5793 45.7103C32.5793 43.5336 32.1704 41.8024 31.0746 40.2387C30.7621 39.7926 30.1348 39.7502 29.7188 40.1017L29.4808 40.3028C29.0513 40.6657 29.0158 41.3062 29.3179 41.7804C30.0356 42.9068 30.2621 44.1285 30.2621 45.7103C30.2621 49.9763 26.8039 53.4345 22.5379 53.4345C18.272 53.4345 14.8138 49.9763 14.8138 45.7103C14.8138 41.4444 18.272 37.9862 22.5379 37.9862C23.5065 37.9862 24.2255 37.9566 24.969 38.1714C25.4615 38.3136 26.0201 38.1925 26.3077 37.7682L26.5001 37.4843C26.8273 37.0015 26.6753 36.3326 26.1284 36.1299C25.0271 35.7219 23.9311 35.669 22.5379 35.669C16.9922 35.669 12.4966 40.1646 12.4966 45.7103C12.4966 46.4116 12.5684 47.096 12.7052 47.7567C9.19525 43.4638 7.08966 37.9778 7.08966 32C7.08966 18.2424 18.2424 7.08966 32 7.08966C32.1188 7.08966 32.2375 7.09049 32.3559 7.09215L22.1517 24.0828L33.931 27.7517L24.6829 42.4982C24.0693 42.0877 23.3316 41.8483 22.5379 41.8483C20.405 41.8483 18.6759 43.5774 18.6759 45.7103C18.6759 47.8433 20.405 49.5724 22.5379 49.5724C24.6709 49.5724 26.4 47.8433 26.4 45.7103C26.4 44.4093 25.7567 43.2585 24.7708 42.5588L47.0621 24.0828L36.6345 20.4138L44.4708 10.4312C51.9079 14.7406 56.9103 22.7861 56.9103 32Z"
        fill={`${color ?? '#27335D'}`}
      />
    </svg>
  );
});
