import { Box } from '@mui/material';
import { useStyles } from './styles';
import { CustomTooltip } from '../../../../CustomTooltip';
import { TimePartComparator } from '../../../../../utils';
import { getCurrentDatetimeLocale } from '../../../../../i18n';
import { GridRowId } from '@mui/x-data-grid';

interface Props {
  rowId?: GridRowId;
  times: Date[];
}

export function LoopCell({ rowId, times }: Props) {
  const classes = useStyles();
  const datetimeLocale = getCurrentDatetimeLocale();

  const timesWithSameDate = times
    .map((x) => {
      return new Date(x);
    })
    .sort(TimePartComparator);

  const amTimes = timesWithSameDate.filter((x) => x.getHours() < 12);
  const pmTimes = timesWithSameDate.filter((x) => x.getHours() >= 12);

  function getTimesDisplayString(times: Date[]): string {
    return times
      .map((time) =>
        time
          .toLocaleTimeString(datetimeLocale.code, {
            hour: '2-digit',
            minute: '2-digit',
          })
          .replace('AM', '')
          .replace('PM', '')
          .trim(),
      )
      .join(' / ');
  }

  const amTimesDisplayString = getTimesDisplayString(amTimes);
  const pmTimesDisplayString = getTimesDisplayString(pmTimes);

  const amLabel = 'AM:';
  const pmLabel = 'PM:';

  const amTimesDisplayStringWithLabel = `${amLabel} ${amTimesDisplayString}`;
  const pmTimesDisplayStringWithLabel = `${pmLabel} ${pmTimesDisplayString}`;

  return (
    <Box>
      {amTimes.length > 0 && (
        <Box className={classes.amPmWrapper}>
          <CustomTooltip title={amTimesDisplayStringWithLabel}>
            <Box className={classes.tooltip}>
              <Box className={classes.amPmLabel}>{amLabel}</Box>
              <Box
                className={classes.timesWrapper}
                data-testid="amTimes"
                id={`row[${rowId}].amTimes`}
              >
                {amTimesDisplayString}
              </Box>
            </Box>
          </CustomTooltip>
        </Box>
      )}
      {pmTimes.length > 0 && (
        <Box className={classes.amPmWrapper}>
          <CustomTooltip title={pmTimesDisplayStringWithLabel}>
            <Box className={classes.tooltip}>
              <Box className={classes.amPmLabel}>{pmLabel}</Box>
              <Box
                className={classes.timesWrapper}
                data-testid="pmTimes"
                id={`row[${rowId}].pmTimes`}
              >
                {pmTimesDisplayString}
              </Box>
            </Box>
          </CustomTooltip>
        </Box>
      )}
    </Box>
  );
}
