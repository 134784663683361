import { Box } from '@mui/material';
import { theme } from '../../assets/style';

interface IconProps {
  width?: number;
  height?: number;
  primaryColor?: string;
  secondaryColor?: string;
  textColor?: string;
  id?: string;
}

export function ScaseLogoWithText({
  width,
  height,
  primaryColor = theme.palette.primary.main,
  secondaryColor = theme.palette.primary.dark,
  textColor,
  id = 'default',
}: IconProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <svg
        width={`${width ?? 142}`}
        height={`${height ?? 223}`}
        viewBox="0 0 142 223"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter={`url(#filter0_dd_999_${id})`}>
          <path
            d="M13.25 53.4872C13.25 43.582 21.2797 35.5524 31.1848 35.5524H110.815C120.72 35.5524 128.75 43.582 128.75 53.4872V133.118C128.75 143.023 120.72 151.052 110.815 151.052H31.1848C21.2797 151.052 13.25 143.023 13.25 133.118V53.4872Z"
            fill={`url(#paint0_linear_999_${id})`}
          />
          <path
            d="M13.871 53.4872C13.871 43.925 21.6226 36.1733 31.1848 36.1733H110.815C120.377 36.1733 128.129 43.925 128.129 53.4872V133.118C128.129 142.68 120.377 150.431 110.815 150.431H31.1848C21.6226 150.431 13.871 142.68 13.871 133.118V53.4872Z"
            stroke="white"
            strokeWidth="1.24194"
          />
        </g>
        <path
          d="M38.1213 94.1643C40.6202 94.1643 43.0056 93.1462 44.7094 91.6757L71.2884 65.3136C61.4062 55.5852 45.5045 55.4767 35.6223 65.2052C29.7157 71.0875 27.3303 79.1191 28.4662 86.6983C28.4662 86.8114 28.4662 86.5852 28.4662 86.6983C29.6021 90.9969 33.3505 94.1643 38.1213 94.1643Z"
          fill={`url(#paint1_linear_999_${id})`}
        />
        <path
          d="M106.524 65.3258C97.0715 55.8393 81.9925 55.5005 72.2023 64.4223L45.0825 91.5266C45.0825 91.5266 44.97 91.6395 44.8575 91.6395C43.1695 93.2206 40.8064 94.1241 38.3307 94.1241C33.6044 94.1241 29.7784 90.849 28.7656 86.4446C28.7656 86.5575 28.7656 86.5575 28.7656 86.6704C28.7656 86.7834 28.7656 86.8963 28.7656 87.0092C28.7656 87.1222 28.7656 87.1222 28.7656 87.2351C28.7656 87.348 28.7656 87.5739 28.8782 87.6868V87.7998C29.7784 92.543 32.029 97.0604 35.7425 100.674L62.5247 127.553C64.7753 129.698 67.8136 130.941 71.077 130.941C74.4529 130.941 77.4912 129.586 79.6293 127.44L106.412 100.561C116.314 90.9619 116.202 75.1511 106.524 65.3258Z"
          fill={`url(#paint2_linear_999_${id})`}
        />
        <path
          d="M75.5797 107.115C74.7879 107.115 74.1091 106.773 73.6566 106.09L66.3034 94.6958L63.4753 97.6582L53.412 107.908C52.4979 108.925 48.7955 111.211 45.2453 110.774L39.1094 104.582C43.5116 107.893 48.1293 106.962 51.2108 103.859L64.9459 89.4547C65.3984 88.9989 66.0771 88.6571 66.7559 88.771C67.4346 88.885 68.1134 89.2268 68.4528 89.7965L73.7697 98L75.806 72.7057C75.9191 71.6802 76.711 70.7687 77.7291 70.6548C78.7473 70.4269 79.7654 70.9966 80.2179 72.0221L87.9105 90.1383L89.3811 85.5807C89.7204 84.6692 90.5123 83.9856 91.5305 83.9856H104.653C105.897 83.9856 106.915 85.011 106.915 86.2644C106.915 87.5177 105.897 88.5431 104.653 88.5431H93.1142L90.2861 97.2025C89.9467 98.114 89.1549 98.6837 88.2499 98.7976C87.3449 98.7976 86.4399 98.3419 86.1005 97.4303L79.6523 82.2765L77.7291 105.064C77.616 106.09 76.9373 106.887 76.0323 107.115C75.9191 107.115 75.6929 107.115 75.5797 107.115Z"
          fill="white"
        />
        <path
          d="M24.753 200C23.1166 200 21.3778 199.676 19.5368 199.028C17.7299 198.381 16.1616 197.511 14.832 196.42L18.7186 190.999C21.0369 192.704 23.1166 193.556 24.9576 193.556C25.8781 193.556 26.5258 193.437 26.9009 193.198C27.31 192.926 27.5145 192.534 27.5145 192.022C27.5145 191.681 27.3782 191.392 27.1054 191.153C26.8327 190.88 26.4577 190.642 25.9804 190.437C25.5372 190.198 25.0258 189.977 24.4462 189.772C23.8666 189.568 23.2529 189.329 22.6052 189.056C21.8551 188.749 21.088 188.391 20.3039 187.982C19.5539 187.539 18.872 187.011 18.2584 186.397C17.6447 185.783 17.1333 185.084 16.7242 184.3C16.3492 183.482 16.1616 182.544 16.1616 181.488C16.1616 180.226 16.4003 179.084 16.8776 178.061C17.389 177.038 18.0879 176.169 18.9743 175.453C19.8607 174.737 20.9176 174.192 22.1449 173.817C23.4064 173.408 24.7871 173.203 26.2872 173.203C28.4009 173.203 30.2079 173.561 31.7079 174.277C33.2421 174.959 34.5717 175.726 35.6968 176.578L31.8102 181.794C30.8897 181.113 29.9863 180.584 29.0998 180.209C28.2134 179.834 27.3782 179.647 26.594 179.647C25.0939 179.647 24.3439 180.124 24.3439 181.078C24.3439 181.419 24.4632 181.709 24.7019 181.948C24.9746 182.187 25.3326 182.408 25.7758 182.613C26.219 182.817 26.7304 183.022 27.31 183.226C27.8896 183.397 28.5032 183.601 29.151 183.84C29.901 184.113 30.6681 184.454 31.4522 184.863C32.2364 185.272 32.9353 185.783 33.5489 186.397C34.1967 186.977 34.7081 187.692 35.0831 188.545C35.4922 189.363 35.6968 190.352 35.6968 191.511C35.6968 192.704 35.4581 193.829 34.9808 194.886C34.5035 195.909 33.8046 196.812 32.8841 197.596C31.9636 198.346 30.8215 198.943 29.4578 199.386C28.0941 199.795 26.5258 200 24.753 200Z"
          fill={`url(#paint3_linear_999_${id})`}
        />
        <path
          d="M50.4366 200C48.7319 200 47.1296 199.71 45.6295 199.131C44.1635 198.551 42.885 197.699 41.794 196.574C40.703 195.449 39.8507 194.068 39.237 192.431C38.6234 190.795 38.3165 188.937 38.3165 186.857C38.3165 184.778 38.6575 182.92 39.3393 181.283C40.0212 179.647 40.9417 178.266 42.1009 177.141C43.26 176.016 44.5896 175.163 46.0897 174.584C47.6239 173.97 49.2263 173.663 50.8968 173.663C52.5333 173.663 53.9311 173.936 55.0903 174.482C56.2835 175.027 57.3404 175.692 58.2609 176.476L55.3971 180.311C54.7152 179.732 54.0334 179.289 53.3515 178.982C52.7037 178.641 51.9878 178.47 51.2037 178.47C49.1581 178.47 47.5046 179.238 46.2431 180.772C44.9817 182.272 44.351 184.3 44.351 186.857C44.351 189.38 44.9647 191.409 46.192 192.943C47.4534 194.443 49.0729 195.193 51.0502 195.193C52.0389 195.193 52.9594 194.988 53.8118 194.579C54.6982 194.136 55.4994 193.625 56.2153 193.045L58.6189 196.932C57.4256 197.954 56.113 198.722 54.6811 199.233C53.2833 199.744 51.8685 200 50.4366 200Z"
          fill={`url(#paint4_linear_999_${id})`}
        />
        <path
          d="M67.6269 200C65.4109 200 63.6211 199.335 62.2573 198.006C60.8936 196.642 60.2118 194.852 60.2118 192.636C60.2118 191.306 60.4845 190.13 61.03 189.107C61.6096 188.05 62.496 187.147 63.6892 186.397C64.9166 185.647 66.4508 185.016 68.2918 184.505C70.1669 183.993 72.3999 183.601 74.991 183.329C74.9569 182.681 74.8546 182.067 74.6842 181.488C74.5478 180.874 74.2921 180.346 73.9171 179.902C73.5761 179.425 73.1159 179.067 72.5363 178.828C71.9567 178.556 71.2408 178.419 70.3885 178.419C69.1611 178.419 67.9508 178.658 66.7576 179.135C65.5984 179.613 64.4563 180.192 63.3313 180.874L61.1834 176.936C62.5812 176.05 64.1495 175.283 65.8882 174.635C67.6269 173.987 69.502 173.663 71.5135 173.663C74.6501 173.663 76.9854 174.601 78.5196 176.476C80.0879 178.317 80.872 180.993 80.872 184.505V199.386H76.0138L75.6047 196.625H75.4001C74.275 197.579 73.0647 198.381 71.7692 199.028C70.5078 199.676 69.127 200 67.6269 200ZM69.5191 195.397C70.5419 195.397 71.4624 195.159 72.2806 194.682C73.1329 194.204 74.0364 193.522 74.991 192.636V187.011C73.2864 187.215 71.8545 187.488 70.6953 187.829C69.5361 188.17 68.5986 188.562 67.8826 189.005C67.1667 189.414 66.6553 189.892 66.3485 190.437C66.0416 190.982 65.8882 191.562 65.8882 192.176C65.8882 193.301 66.2291 194.119 66.911 194.63C67.5929 195.142 68.4622 195.397 69.5191 195.397Z"
          fill={`url(#paint5_linear_999_${id})`}
        />
        <path
          d="M93.3819 200C91.6432 200 89.9215 199.676 88.2168 199.028C86.5463 198.347 85.0974 197.511 83.87 196.523L86.6315 192.738C87.7566 193.625 88.8646 194.324 89.9556 194.835C91.0806 195.312 92.2909 195.551 93.5864 195.551C94.9502 195.551 95.9559 195.278 96.6036 194.733C97.2514 194.153 97.5753 193.42 97.5753 192.534C97.5753 192.022 97.4219 191.579 97.115 191.204C96.8082 190.795 96.3991 190.437 95.8877 190.13C95.3763 189.823 94.7967 189.551 94.149 189.312C93.5012 189.039 92.8534 188.766 92.2057 188.494C91.3875 188.187 90.5522 187.829 89.6999 187.42C88.8475 187.011 88.0805 186.516 87.3986 185.937C86.7508 185.357 86.2054 184.692 85.7622 183.942C85.3531 183.158 85.1485 182.238 85.1485 181.181C85.1485 178.965 85.9667 177.158 87.6032 175.76C89.2396 174.362 91.4727 173.663 94.3024 173.663C96.0411 173.663 97.6094 173.97 99.0072 174.584C100.405 175.198 101.615 175.896 102.638 176.681L99.9277 180.26C99.0413 179.613 98.1378 179.101 97.2173 178.726C96.3309 178.317 95.3934 178.112 94.4047 178.112C93.1432 178.112 92.2057 178.385 91.592 178.931C91.0125 179.442 90.7226 180.09 90.7226 180.874C90.7226 181.385 90.859 181.829 91.1318 182.204C91.4386 182.545 91.8307 182.851 92.308 183.124C92.7853 183.397 93.3307 183.653 93.9444 183.891C94.5922 184.13 95.257 184.368 95.9388 184.607C96.7912 184.914 97.6435 185.272 98.4958 185.681C99.3481 186.056 100.115 186.533 100.797 187.113C101.513 187.693 102.076 188.408 102.485 189.261C102.928 190.079 103.149 191.068 103.149 192.227C103.149 193.318 102.928 194.341 102.485 195.295C102.076 196.216 101.462 197.034 100.644 197.75C99.8254 198.432 98.8026 198.977 97.5753 199.386C96.348 199.795 94.9502 200 93.3819 200Z"
          fill={`url(#paint6_linear_999_${id})`}
        />
        <path
          d="M117.605 200C115.866 200 114.247 199.71 112.746 199.131C111.246 198.517 109.934 197.648 108.809 196.523C107.684 195.397 106.797 194.034 106.149 192.431C105.536 190.795 105.229 188.937 105.229 186.857C105.229 184.812 105.553 182.971 106.201 181.334C106.848 179.698 107.701 178.317 108.758 177.192C109.849 176.067 111.093 175.198 112.491 174.584C113.889 173.97 115.32 173.663 116.786 173.663C118.491 173.663 119.991 173.953 121.287 174.533C122.582 175.112 123.656 175.931 124.508 176.987C125.395 178.044 126.06 179.306 126.503 180.772C126.946 182.238 127.168 183.84 127.168 185.579C127.168 186.158 127.134 186.704 127.065 187.215C127.031 187.727 126.98 188.136 126.912 188.443H110.957C111.229 190.693 112.013 192.431 113.309 193.659C114.639 194.852 116.326 195.449 118.372 195.449C119.463 195.449 120.468 195.295 121.389 194.988C122.344 194.647 123.281 194.187 124.202 193.608L126.196 197.29C125.003 198.074 123.673 198.722 122.207 199.233C120.741 199.744 119.207 200 117.605 200ZM110.905 184.454H122.054C122.054 182.51 121.628 180.993 120.775 179.902C119.957 178.777 118.679 178.215 116.94 178.215C115.44 178.215 114.127 178.743 113.002 179.8C111.877 180.857 111.178 182.408 110.905 184.454Z"
          fill={`url(#paint7_linear_999_${id})`}
        />
        <defs>
          <filter
            id={`filter0_dd_999_${id}`}
            x="0.830645"
            y="29.3427"
            width="140.339"
            height="140.339"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="6.20968" />
            <feGaussianBlur stdDeviation="6.20968" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="0.951227" />
            <feGaussianBlur stdDeviation="1.90245" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id={`paint0_linear_999_${id}`}
            x1="71"
            y1="35.5524"
            x2="71"
            y2="151.052"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#EFEFEF" />
          </linearGradient>
          <linearGradient
            id={`paint1_linear_999_${id}`}
            x1="32.9051"
            y1="60.7911"
            x2="55.6899"
            y2="101.723"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={primaryColor} />
            <stop offset="0.9996" stopColor={secondaryColor} />
          </linearGradient>
          <linearGradient
            id={`paint2_linear_999_${id}`}
            x1="38.0674"
            y1="63.6645"
            x2="84.51"
            y2="145.336"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={primaryColor} />
            <stop offset="0.9996" stopColor={secondaryColor} />
          </linearGradient>
          <linearGradient
            id={`paint3_linear_999_${id}`}
            x1="116.354"
            y1="175.899"
            x2="105.861"
            y2="212.728"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              offset="0.3003"
              stopColor={textColor ? textColor : primaryColor ?? theme.palette.primary.main}
            />

            <stop
              offset="0.9996"
              stopColor={`${textColor ? textColor : primaryColor ?? theme.palette.primary.dark}`}
            />
          </linearGradient>
          <linearGradient
            id={`paint4_linear_999_${id}`}
            x1="116.354"
            y1="175.899"
            x2="105.861"
            y2="212.728"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              offset="0.3003"
              stopColor={`${textColor ? textColor : primaryColor ?? theme.palette.primary.main}`}
            />
            <stop
              offset="0.9996"
              stopColor={`${textColor ? textColor : primaryColor ?? theme.palette.primary.dark}`}
            />
          </linearGradient>
          <linearGradient
            id={`paint5_linear_999_${id}`}
            x1="116.354"
            y1="175.899"
            x2="105.861"
            y2="212.728"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              offset="0.3003"
              stopColor={`${textColor ? textColor : primaryColor ?? theme.palette.primary.main}`}
            />
            <stop
              offset="0.9996"
              stopColor={`${textColor ? textColor : primaryColor ?? theme.palette.primary.dark}`}
            />
          </linearGradient>
          <linearGradient
            id={`paint6_linear_999_${id}`}
            x1="116.354"
            y1="175.899"
            x2="105.861"
            y2="212.728"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              offset="0.3003"
              stopColor={`${textColor ? textColor : primaryColor ?? theme.palette.primary.main}`}
            />
            <stop
              offset="0.9996"
              stopColor={`${textColor ? textColor : primaryColor ?? theme.palette.primary.dark}`}
            />
          </linearGradient>
          <linearGradient
            id={`paint7_linear_999_${id}`}
            x1="116.354"
            y1="175.899"
            x2="105.861"
            y2="212.728"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              offset="0.3003"
              stopColor={`${textColor ? textColor : primaryColor ?? theme.palette.primary.main}`}
            />
            <stop
              offset="0.9996"
              stopColor={`${textColor ? textColor : primaryColor ?? theme.palette.primary.dark}`}
            />
          </linearGradient>
          ;
        </defs>
      </svg>
    </Box>
  );
}
