import axios from 'axios';
import * as React from 'react';
import { useState, useRef, useCallback } from 'react';

import { apiReducer, ApiReducerType, ApiRequestState } from './reducer';
import { CaptureApiError, getRequestHeaders, getUrl } from './utils';

type RefetchFunction = () => Promise<void>;
type SetNewUrlFunction = (newUrl: string) => void;

export interface GetApiActions {
  refetch: RefetchFunction;
  setNewUrl: SetNewUrlFunction;
}

export interface GetApiMeta {
  lastFetchDatetime: Date | null;
}

export interface UseGetApiOptions {
  enabled?: boolean;
}

export function useGetApi<TResult, TParams = unknown>(
  endpoint: string,
  params?: TParams,
  options: UseGetApiOptions = { enabled: true },
): [ApiRequestState<TResult>, GetApiActions, GetApiMeta] {
  const { enabled = true } = options;

  const [url, setUrl] = useState(getUrl(endpoint));
  const [lastFetchDatetime, setLastFetchDatetime] = useState<Date | null>(null);
  const [state, dispatch] = React.useReducer<ApiReducerType<TResult>>(apiReducer, {
    isLoading: true,
    isError: false,
    data: null,
  });

  const setNewUrl = useCallback((newUrl: string) => setUrl(getUrl(newUrl)), []);

  const didCancel = useRef(false);
  const fetchData = useCallback(async () => {
    dispatch({ type: 'API_REQUEST_INIT' });
    const headers = getRequestHeaders();
    try {
      const result = await axios(url, {
        withCredentials: false,
        params,
        headers: headers,
      });
      if (!didCancel.current) {
        setLastFetchDatetime(new Date());
        dispatch({ type: 'API_REQUEST_SUCCESS', payload: result.data });
      }
    } catch (error) {
      if (!didCancel.current) {
        if (axios.isAxiosError(error)) {
          CaptureApiError(error, headers);
        }
        dispatch({ type: 'API_REQUEST_FAILURE', payload: {} });
      }
    }
  }, [url, params]);

  React.useEffect(() => {
    didCancel.current = false;

    if (enabled && url) {
      fetchData();
    }

    return () => {
      didCancel.current = true;
    };
  }, [url, enabled, fetchData]);

  return [state, { setNewUrl, refetch: fetchData }, { lastFetchDatetime }];
}
