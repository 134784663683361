import { GridCellParams } from '@mui/x-data-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, GridColumnDefinition } from '../../Grid';
import { Device } from '../../../models';
import { DepartmentCellRenderer } from '../Renderer';

interface Props {
  data: Device[];
}

export function OrganizationDeviceGrid({ data }: Props) {
  const { t } = useTranslation('device');
  const columns: GridColumnDefinition<Device>[] = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 250,
      flex: 2,
      renderCell: (params: GridCellParams) => <strong>{params.row.id}</strong>,
    },
    {
      field: 'chassisId',
      headerName: t('chassisId.label'),
      minWidth: 100,
      flex: 2,
    },
    {
      field: 'departments',
      headerName: t('departments.label'),
      flex: 2,
      minWidth: 250,
      align: 'center',
      headerAlign: 'center',
      renderCell: DepartmentCellRenderer,
    },
  ];

  return <Grid columns={columns} rows={data} />;
}
