import { Typography } from '@mui/material';
import CookieConsent from 'react-cookie-consent';
import { CONSENT_COOKIE_NAME } from '../../constants';
import { theme } from '../../assets/style';
import { useTranslation } from 'react-i18next';

interface Props {
  onAccept: () => void;
}

export function CookieBanner({ onAccept }: Props) {
  const { t } = useTranslation('cookie');

  return (
    <CookieConsent
      location="bottom"
      buttonText={t('button.accept')}
      cookieName={CONSENT_COOKIE_NAME}
      onAccept={onAccept}
      style={{ background: '#2B373B', zIndex: 9999, alignItems: 'center', minHeight: 80 }}
      buttonStyle={{
        backgroundColor: theme.palette.secondary.main,
        color: '#fff',
        fontSize: '1.125rem',
        fontFamily: 'PT Serif',
        borderRadius: theme.spacing(1),
        width: 150,
      }}
      enableDeclineButton={true}
      declineButtonText={t('button.decline')}
      declineButtonStyle={{
        backgroundColor: 'transparent',
        fontSize: '1.125rem',
        borderWidth: 2,
        border: 'solid',
        borderColor: theme.palette.primary.main,
        fontFamily: 'PT Serif',
        borderRadius: theme.spacing(1),
        width: 150,
      }}
    >
      <Typography>{t('consent')}</Typography>
    </CookieConsent>
  );
}
