import { QuestionnaireResponseItem } from '../../../../../models';
import { StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import { QuestionnaireQuestion } from '../QuestionnaireQuestion';
import { QuestionnaireQuestionAnswers } from '../QuestionnaireQuestionAnswers';

interface Props {
  items: QuestionnaireResponseItem[];
}

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 5,
  },
  questionAnswersWrapper: { paddingLeft: 15 },
});

export function QuestionnaireQuestionsWithAnswers({ items }: Props) {
  return (
    <View>
      {items.map((qAns) => {
        return (
          <View key={qAns.questionnaireItemOrdinalNumber} style={styles.wrapper} wrap={false}>
            <QuestionnaireQuestion question={qAns} />
            <View style={styles.questionAnswersWrapper}>
              <QuestionnaireQuestionAnswers answer={qAns} />
            </View>
          </View>
        );
      })}
    </View>
  );
}
