import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from '../ProtectedRoute';
import { EDIT_SUBPATH } from '../../constants';
import { GroupPage } from '../../pages/group/GroupPage';

export function GroupRoutes() {
  return (
    <Routes>
      <Route
        path={`${EDIT_SUBPATH}`}
        element={
          <ProtectedRoute requireFunctionality="ORGANIZATIONS_READ">
            <GroupPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/*"
        element={
          <ProtectedRoute requireFunctionality="ORGANIZATIONS_READ">
            <GroupPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
