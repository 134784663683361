import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.grey['200']}`,
    borderRadius: theme.typography.pxToRem(4),
    backgroundColor: 'rgba(8, 44, 175, 0.04)',
    padding: theme.spacing(1, 6),
    boxShadow: 'none',
    '&:hover, &:focus': {
      backgroundColor: '#C3C4C9',
      border: `${theme.typography.pxToRem(1)} solid #C3C4C9`,
      boxShadow: 'none',
    },
  },
  label: {
    color: '#082caf',
    fontSize: theme.typography.pxToRem(14),
    fontFamily: 'Inter',
    fontWeight: 'normal',
  },
}));
