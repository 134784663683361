import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
  },
  sectionValue: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: 0.4,
    lineHeight: 1.29,
    color: '#6c7688',
  },
  subsectionValue: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Inter',
    fontSize: 14,
    lineHeight: 1.29,
    letterSpacing: 0.4,
    color: theme.palette.primary.A700,
    '&::before': {
      content: '"|"',
      color: '#d4d7e3',
    },
    '&:first-child::before': {
      margin: theme.spacing(0, 2, 0, 0),
      [theme.breakpoints.down('lg')]: {
        margin: theme.spacing(0, 1, 0, 0),
      },
    },
    '&:not(first-child)::before': {
      margin: theme.spacing(0, 2),
      [theme.breakpoints.down('lg')]: {
        margin: theme.spacing(0, 1),
      },
    },
    [theme.breakpoints.down('lg')]: {
      color: theme.palette.grey.A700,
      fontWeight: 'bold',
    },
  },
  purpose: {
    display: 'block',
    maxWidth: 250,
    overflow: 'hidden',
    whiteSpace: 'nowrap' /* Don't forget this one */,
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  unit: { color: '#8794aa', marginLeft: theme.spacing(0.5) },
  defaultText: { color: '#8794aa' },
}));
