import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ScoringCategoriesTitle } from '../../ScoringCategoriesTitle';
import { ScoringCategoryColorIndicator } from '../../ScoringCategoryColorIndicator';
import { QuestionnaireScoringCategory } from '../../../../models';

interface Props {
  scoringCategories: QuestionnaireScoringCategory[];
}

export function QuestionnaireScoringCategoriesList({ scoringCategories }: Props) {
  const [sortedScoringCategories, setSortedScoringCategories] = useState<
    QuestionnaireScoringCategory[]
  >([]);

  useEffect(() => {
    const sorted = (scoringCategories || []).sort((a, b) => a.scoreFrom - b.scoreFrom);
    setSortedScoringCategories(sorted);
  }, []);

  return (
    <Box>
      <ScoringCategoriesTitle />
      {sortedScoringCategories.map((scoringCategory, index) => (
        <Box key={index}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box marginRight={1}>
              <ScoringCategoryColorIndicator
                categoryIndex={index}
                categoriesCount={sortedScoringCategories.length}
              />
            </Box>
            <Typography variant="subtitle1" id={`questionnaireScoringCategory[${index}].name`}>
              {scoringCategory.name}
            </Typography>
          </Box>
          <Box paddingLeft={2}>
            <Typography
              variant="caption"
              color="textSecondary"
              id={`questionnaireScoringCategory[${index}].interval`}
            >{`${scoringCategory.scoreFrom} - ${scoringCategory.scoreTo}`}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
