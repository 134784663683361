import React, { useRef } from 'react';
import { Box } from '@mui/material';
import { QuestionnaireQuestionCreateInput, QuestionType } from '../../../models';
import { FieldArrayRenderProps } from 'formik';
import { FormDeleteIconButton } from '../../DeleteIconButton';
import { getIconByQuestionType } from '../../../utils';
import { useStyles } from './styles';
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import { QuestionListItemText } from '../QuestionListItemText';
import { useSelectedQuestionIndex } from '../../../context/selectedQuestionIndex';
import { useTranslation } from 'react-i18next';
import { useFormikDisableContext } from '../../../context/formikDisable';

interface DragItem {
  index: number;
  type: string;
}

interface QuestionListItemProps {
  index: number;
  isSelected: boolean;
  question: QuestionnaireQuestionCreateInput;
  questionType?: QuestionType;
  arrayHelpers: FieldArrayRenderProps;
  onMoveCard: (dragIndex: number, dropIndex: number, arrayHelpers: FieldArrayRenderProps) => void;
  hasErrorState: boolean;
}

const ItemTypes = {
  QUESTIONNAIRE_QUESTION: 'questionnaireQuestion',
};

export function QuestionListItem({
  index,
  isSelected,
  question,
  questionType,
  arrayHelpers,
  onMoveCard,
  hasErrorState,
}: QuestionListItemProps) {
  const classes = useStyles();
  const { t: tQuestionnaire } = useTranslation('questionnaire');
  const { selectedIndex, setSelectedIndex } = useSelectedQuestionIndex();
  const ref = useRef<HTMLDivElement>(null);

  const { isEditable } = useFormikDisableContext();

  function shouldMove(monitor: DropTargetMonitor, dragIndex: number, dropIndex: number): boolean {
    if (!ref.current) {
      return false;
    }

    // Don't replace items with themselves
    if (dragIndex === dropIndex) {
      return false;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = ref.current?.getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < dropIndex && hoverClientY < hoverMiddleY) {
      return false;
    }

    // Dragging upwards
    if (dragIndex > dropIndex && hoverClientY > hoverMiddleY) {
      return false;
    }

    return true;
  }

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.QUESTIONNAIRE_QUESTION,
    collect(monitor: DropTargetMonitor<DragItem>) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor<DragItem>) {
      const dragIndex = item.index;
      const hoverIndex = index;

      if (!shouldMove(monitor, dragIndex, hoverIndex)) {
        return;
      }

      // Time to actually perform the action
      onMoveCard(dragIndex, hoverIndex, arrayHelpers);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging: _ }, drag] = useDrag({
    type: ItemTypes.QUESTIONNAIRE_QUESTION,
    item: () => {
      return { index };
    },
    canDrag: isEditable,
    // eslint-disable-next-line
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <React.Fragment>
      <div
        ref={ref}
        data-handler-id={handlerId}
        id={`question[${index}]`}
        className={classes.cursorPointer}
      >
        <Box key={index} display="flex" boxShadow={isSelected ? 2 : undefined} borderRadius="5px">
          <Box
            display="flex"
            width="85%"
            alignItems="center"
            onClick={() => setSelectedIndex(index)}
            className={classes.questionListItemContainer}
          >
            <Box display="flex" width="100%" alignItems="center" ml={0.2}>
              <Box display="flex" alignItems="center" justifyContent="center" mr={1}>
                {getIconByQuestionType(questionType?.type, isSelected, hasErrorState)}
              </Box>
              <Box display="flex" flexDirection="column" flexWrap="wrap" overflow="hidden">
                <QuestionListItemText
                  questionText={question.text}
                  isSelected={isSelected}
                  hasErrorState={hasErrorState}
                  id={`question[${index}].text`}
                />
                <Box
                  className={`${classes.questionType} ${
                    hasErrorState ? classes.questionTypeError : ''
                  }`}
                >
                  {questionType
                    ? tQuestionnaire(`question.types.${questionType?.type}` as const)
                    : ''}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" width="15%" alignItems="center" justifyContent="center">
            <FormDeleteIconButton
              onDeleteClick={() => {
                if (selectedIndex >= index) {
                  // keep selected right question when selected or below selected is removed
                  setSelectedIndex(selectedIndex - 1);
                }
                arrayHelpers.remove(index);
              }}
            />
          </Box>
        </Box>
      </div>
    </React.Fragment>
  );
}
