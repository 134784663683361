import { Box } from '@mui/material';

interface IconProps {
  width?: number;
  height?: number;
}

export function EditIcon({ width = 15, height = 15 }: IconProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <svg
        width={`${width}`}
        height={`${height}`}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.03639 12.915H2.12368L6.33524 12.0032C6.41728 11.9896 6.49329 11.9517 6.55344 11.8946L13.689 4.79538C14.2103 4.2779 14.5011 3.57405 14.4964 2.84149C14.4974 2.10323 14.2075 1.39401 13.689 0.865855C13.1689 0.347254 12.4615 0.057909 11.7251 0.0626047C10.9841 0.0560806 10.2733 0.35465 9.7612 0.887588L2.64736 7.98678C2.58463 8.03701 2.53889 8.10521 2.51642 8.18217L1.59996 12.3722C1.57494 12.5227 1.62356 12.6759 1.7309 12.7848C1.81147 12.8665 1.92128 12.9134 2.03639 12.915ZM11.7251 0.931013C12.7736 0.930929 13.6236 1.77652 13.6237 2.81967C13.6237 2.82694 13.6237 2.83421 13.6236 2.84149C13.6307 3.33844 13.4337 3.81677 13.0781 4.16579L10.394 1.47372C10.7476 1.12412 11.2266 0.92881 11.7251 0.931013ZM9.783 2.10335L12.467 4.77369L6.24791 10.9394L3.56387 8.29071L9.783 2.10335ZM3.21474 9.15915L5.37505 11.3084L2.60372 11.9163L3.21474 9.15915Z"
          fill="currentColor"
        />
        <path
          d="M15.892 15.2597H1.27164C1.03059 15.2597 0.835205 15.4541 0.835205 15.6939C0.835205 15.9337 1.03059 16.1281 1.27164 16.1281H15.892C16.1331 16.1281 16.3285 15.9337 16.3285 15.6939C16.3285 15.4541 16.1331 15.2597 15.892 15.2597Z"
          fill="currentColor"
        />
      </svg>
    </Box>
  );
}
