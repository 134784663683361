import * as React from 'react';
import { ReactNode } from 'react';
import { CurrentUserResult, Functionality } from '../../models';
import { CurrentUserContext } from './Context';
import { ApiRoutes, makeApiRequest } from '../../services/api';
import { resetAmplitudeUserId, setAmplitudeUserIdAndUserProperties } from '../../utils/amplitude';
import { currentUserReducer, CurrentUserReducerType } from './reducer';
import { ACCESS_TOKEN } from '../../constants';
interface Props {
  children: ReactNode;
}

export function CurrentUserProvider({ children }: Props) {
  const [state, dispatch] = React.useReducer<CurrentUserReducerType>(currentUserReducer, {
    isLoading: true,
    isAuthenticated: false,
    user: undefined,
  });

  async function fetchCurrentUser() {
    try {
      dispatch({ type: 'CURRENT_USER_FETCH_INIT' });
      const { error, data } = await makeApiRequest<CurrentUserResult>('GET', ApiRoutes.CurrentUser);
      if (error) {
        dispatch({ type: 'CURRENT_USER_FETCH_FAILURE' });
        return;
      }

      if (data) {
        setAmplitudeUserIdAndUserProperties(data);
        dispatch({
          type: 'CURRENT_USER_FETCH_SUCCESS',
          user: {
            id: data.id,
            externalId: data.externalId,
            name: data.name,
            username: data.username,
            userType: data.userType,
            userRole: data.userRole,
            functionalities: transformFunctionalities(data),
            hasAccessToProfile: data.userType === 'MEDIC',
            hasAccessToAdminProfile: data.userType === 'ADMIN',
          },
        });
        return;
      }

      dispatch({ type: 'CURRENT_USER_FETCH_FAILURE' });
    } catch {
      // empty
    }
  }

  async function reset(token?: string) {
    if (token) {
      localStorage.setItem(ACCESS_TOKEN, token);
    } else {
      localStorage.removeItem(ACCESS_TOKEN);
    }
    resetAmplitudeUserId();
    return fetchCurrentUser();
  }

  React.useEffect(() => {
    fetchCurrentUser();
  }, []);

  return (
    <CurrentUserContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        isLoading: state.isLoading,
        reset,
        user: state.user,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
}

function transformFunctionalities(user: CurrentUserResult): Set<Functionality> {
  const functionalities = new Set<Functionality>();

  for (const functionality of user.functionalities) {
    functionalities.add(functionality);
  }

  return functionalities;
}
