import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import { Device } from '../../models';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

interface SelectDeviceInputProps {
  devices: Device[];
  selectedDeviceId: string;
  onChange: (deviceId: string) => void;
}

export function SelectDeviceInput({ devices, selectedDeviceId, onChange }: SelectDeviceInputProps) {
  const { t } = useTranslation('patient');
  const classes = useStyles({ hasError: selectedDeviceId === '' });

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as string);
  };

  return (
    <FormControl
      required={true}
      variant="outlined"
      fullWidth={true}
      className={classes.formControl}
      margin="normal"
    >
      <InputLabel id="device-select-label" className={classes.label} shrink={!!selectedDeviceId}>
        {t('device_select')}
      </InputLabel>
      <Select
        labelId="device-select-label"
        data-testid="select-test-id"
        value={selectedDeviceId}
        onChange={handleChange}
        fullWidth={true}
        className={classes.selectEmpty}
      >
        {devices.map((device) => (
          <MenuItem key={device.id} value={device.id}>
            {device.chassisId}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
