import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { getInputErrorText } from '../InputErrorText';
import { useStyles } from './styles';
import { ChangeEvent, useEffect } from 'react';
import { CheckboxActiveCustomIcon } from '../Icons';
import { useFormikDisableContext } from '../../context/formikDisable';

export interface CheckboxInputProps {
  id: string;
  htmlId?: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  showError?: boolean;
  setTouchedWhenCheckedOnLoad?: boolean;
  blurInputsOnChange?: string[];
}

export function CheckboxInput({
  id,
  htmlId,
  label,
  required,
  disabled,
  helperText,
  checked,
  onChange,
  showError = true,
  setTouchedWhenCheckedOnLoad = false,
  blurInputsOnChange,
}: CheckboxInputProps) {
  // Idealy we would get the required flag from validationSchema
  // from formik context (useFormikContext) to have one source of thruth about
  // field requirements. However, there is a bug that validationSchema
  // is not included in formik context even tho the type definitions say otherwise.
  // see: https://github.com/formium/formik/pull/2521
  const [{ value, onChange: fieldOnChange, ...field }, meta, { setTouched }] = useField(id);
  const { isSubmitting, ...formikContext } = useFormikContext();
  const classes = useStyles();
  const { isDisabled } = useFormikDisableContext();

  const errorMessage = meta.touched ? meta.error : null;
  // Fix for warning: `value` prop on `input` should not be null.
  const fieldValue = value === null ? '' : value;

  useEffect(() => {
    if (setTouchedWhenCheckedOnLoad && value) {
      setTouched(true);
    }
  }, []);

  function handleChange(e: ChangeEvent) {
    fieldOnChange(e);

    if (blurInputsOnChange) {
      for (const blurInputOnChangeName of blurInputsOnChange) {
        formikContext.setFieldTouched(blurInputOnChangeName);
      }
    }
  }

  return (
    <FormControl
      required={required}
      error={!!errorMessage}
      component="fieldset"
      className={classes.formControl}
      id={htmlId}
    >
      <FormControlLabel
        control={
          <Checkbox
            {...field}
            onChange={handleChange}
            {...(onChange ? { onChange: onChange } : undefined)}
            checked={checked ?? fieldValue}
            id={id}
            required={required}
            disabled={isSubmitting || isDisabled || disabled}
            color="primary"
            checkedIcon={<CheckboxActiveCustomIcon />}
          />
        }
        label={label}
      />
      {showError && errorMessage && (
        <FormHelperText>{getInputErrorText(errorMessage) || helperText}</FormHelperText>
      )}
    </FormControl>
  );
}
