import { createRef, ReactNode, useCallback } from 'react';
import { SnackbarKey, SnackbarProvider as SnackbarProviderExternal } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  children: ReactNode;
}

export function SnackbarProvider({ children }: Props) {
  const notistackRef = createRef<SnackbarProviderExternal>();

  const handleSnackCloseClick = useCallback((key: SnackbarKey) => {
    if (notistackRef.current) {
      notistackRef.current.closeSnackbar(key);
    }
  }, []);

  return (
    <SnackbarProviderExternal
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      ref={notistackRef}
      action={(key: SnackbarKey) => (
        <IconButton onClick={() => handleSnackCloseClick(key)} size="small">
          <CloseIcon />
        </IconButton>
      )}
      autoHideDuration={5000}
      variant="success"
    >
      {children}
    </SnackbarProviderExternal>
  );
}
