import { Card, CardContent } from '@mui/material';
import { MeasurementNavTabsWrapper, NavTabPanel } from '../../../components/NavTabs';
import { ProtectedRoute } from '../../../components/ProtectedRoute';
import { Navigation } from '../../../types';
import { kebabize, sortMeasurandsWithThresholdsByMeasuringDevice } from '../../../utils';
import { useStyles } from './styles';
import * as NavigationConstants from '../../../constants/NavigationConstants';
import { SYSTEM_THRESHOLDS_PATH } from '../../../constants';
import { MeasurementThresholdsFormValues } from '../../patients/measurements/components/ThresholdsForm';
import React from 'react';
import {
  MeasurementThresholds,
  MeasurementTypesNavTabFunctionalities,
  MeasuringDeviceType,
  Optional,
  UpdateMeasurementThresholds,
  UpdateThresholds,
} from '../../../models';
import { ApiRoutes, makeApiRequest, useGetApi } from '../../../services/api';
import { SystemThresholdsFormWrapper } from './SystemThresholdsFormWrapper';
import { ProgressIndicator } from '../../../components/ProgressIndicator';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

export const handleSubmitThresholds = (
  values: MeasurementThresholdsFormValues,
  endpoint: string,
  deviceId: Optional<number> = null,
) => {
  const updateMeasurementThresholds: UpdateMeasurementThresholds[] = Object.entries(values).map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_, value]) => {
      return {
        measurementTypeId: value.typeId,
        measurementSubtypeId: value.subtypeId,
        measurementSubtypeCodingId: value.subtypeCodingId,
        maxError: value.isMaxErrorCurrentlyActiveOnForm
          ? value.maxError == ''
            ? null
            : parseFloat(value.maxError)
          : null,
        isMaxErrorChanged: value.isMaxErrorChanged,
        maxWarning: value.isMaxWarningCurrentlyActiveOnForm
          ? value.maxWarning == ''
            ? null
            : parseFloat(value.maxWarning)
          : null,
        isMaxWarningChanged: value.isMaxWarningChanged,
        minWarning: value.isMinWarningCurrentlyActiveOnForm
          ? value.minWarning == ''
            ? null
            : parseFloat(value.minWarning)
          : null,
        isMinWarningChanged: value.isMinWarningChanged,
        minError: value.isMinErrorCurrentlyActiveOnForm
          ? value.minError == ''
            ? null
            : parseFloat(value.minError)
          : null,
        isMinErrorChanged: value.isMinErrorChanged,
      };
    },
  );

  const input: UpdateThresholds = {
    measuringDeviceId: deviceId,
    thresholds: updateMeasurementThresholds,
  };

  return makeApiRequest<never, UpdateThresholds>('PUT', endpoint, input);
};

export function SystemThresholds() {
  const { t } = useTranslation('thresholds');
  const classes = useStyles();

  const [
    { data: measurementThresholds, isLoading: isMeasurementThresholdsLoading },
    { refetch: refetchMeasurementThresholds },
  ] = useGetApi<MeasurementThresholds[]>(ApiRoutes.SystemThresholds);

  const adminThresholdsPath = SYSTEM_THRESHOLDS_PATH;

  if (isMeasurementThresholdsLoading) {
    return <ProgressIndicator />;
  }

  if (!measurementThresholds) {
    return <div>{t('errorMessages.systemThresholdsNotFound')}</div>;
  }

  function getFirstMeasurandSubpathByMeasuringDevice(measuringDevice: string) {
    if (measurementThresholds) {
      const device = measurementThresholds.find(
        (x) => kebabize(x.measuringDevice) == measuringDevice,
      );
      if (device && device.measurands) {
        const sortedDeviceMeasurands =
          sortMeasurandsWithThresholdsByMeasuringDevice(
            measuringDevice as MeasuringDeviceType,
            device.measurands,
          ) ?? device.measurands;

        const firstMeasurand = sortedDeviceMeasurands[0];

        let path = `/${kebabize(firstMeasurand.type)}`;

        if (firstMeasurand.subtype) {
          path += `/${kebabize(firstMeasurand.subtype)}`;
        }

        if (firstMeasurand.subtypeCoding) {
          path += `/${kebabize(firstMeasurand.subtypeCoding)}`;
        }

        return path;
      }
    }
    return '';
  }

  const nav: Navigation = {
    pulseOximeter: {
      tab: `${adminThresholdsPath}/${
        NavigationConstants.PULSE_OXIMETER_PATH
      }${getFirstMeasurandSubpathByMeasuringDevice(NavigationConstants.PULSE_OXIMETER_PATH)}`,
      route: {
        path: '/admin-dashboard/thresholds/pulse-oximeter/*',
      },
      subRoutes: {},
    },
    thermometer: {
      tab: `${adminThresholdsPath}/${
        NavigationConstants.THERMOMETER_PATH
      }${getFirstMeasurandSubpathByMeasuringDevice(NavigationConstants.THERMOMETER_PATH)}`,
      route: {
        path: '/admin-dashboard/thresholds/thermometer/*',
      },
      subRoutes: {},
    },
    bloodPressure: {
      tab: `${adminThresholdsPath}/${
        NavigationConstants.BLOOD_PRESSURE_MONITOR_PATH
      }${getFirstMeasurandSubpathByMeasuringDevice(
        NavigationConstants.BLOOD_PRESSURE_MONITOR_PATH,
      )}`,
      route: {
        path: '/admin-dashboard/thresholds/blood-pressure-monitor/*',
      },
      subRoutes: {},
    },
    glucometer: {
      tab: `${adminThresholdsPath}/${
        NavigationConstants.GLUCOMETER_PATH
      }${getFirstMeasurandSubpathByMeasuringDevice(NavigationConstants.GLUCOMETER_PATH)}`,
      route: {
        path: '/admin-dashboard/thresholds/glucometer/*',
      },
      subRoutes: {},
    },
    weight: {
      tab: `${adminThresholdsPath}/${
        NavigationConstants.WEIGHT_PATH
      }${getFirstMeasurandSubpathByMeasuringDevice(NavigationConstants.WEIGHT_PATH)}`,
      route: {
        path: '/admin-dashboard/thresholds/weight/*',
      },
      subRoutes: {},
    },
    height: {
      tab: `${adminThresholdsPath}/${
        NavigationConstants.HEIGHT_PATH
      }${getFirstMeasurandSubpathByMeasuringDevice(NavigationConstants.HEIGHT_PATH)}`,
      route: {
        path: '/admin-dashboard/thresholds/height/*',
      },
      subRoutes: {},
    },
  };

  const navTabsFunctionalities: MeasurementTypesNavTabFunctionalities = {
    PulseOximeter: 'SYSTEM_THRESHOLDS_READ',
    Thermometer: 'SYSTEM_THRESHOLDS_READ',
    Glucometer: 'SYSTEM_THRESHOLDS_READ',
    BloodPressureMonitor: 'SYSTEM_THRESHOLDS_READ',
    Weight: 'SYSTEM_THRESHOLDS_READ',
    Height: 'SYSTEM_THRESHOLDS_READ',
    MovementPain: 'SYSTEM_THRESHOLDS_READ',
    ProbeFluidIntake: 'SYSTEM_THRESHOLDS_READ',
    IntravenousFluidIntake: 'SYSTEM_THRESHOLDS_READ',
    UrineOutput: 'SYSTEM_THRESHOLDS_READ',
  };

  const onSubmit = (values: MeasurementThresholdsFormValues, deviceId: number) => {
    return handleSubmitThresholds(values, ApiRoutes.SystemThresholds, deviceId);
  };

  const onSubmitCompleted = async () => {
    await refetchMeasurementThresholds();
  };

  return (
    <div>
      <MeasurementNavTabsWrapper
        nav={nav}
        measurementTypesFunctionalities={navTabsFunctionalities}
        ariaLabel={'admin panel measurements tabs'}
      />
      <Card className={classes.boxShadowAndBorderRadius}>
        <CardContent className={classes.cardContent}>
          <NavTabPanel>
            <Routes>
              <Route
                path="/pulse-oximeter/*"
                element={
                  <ProtectedRoute requireFunctionality="SYSTEM_THRESHOLDS_READ">
                    <SystemThresholdsFormWrapper
                      onSubmit={onSubmit}
                      onSubmitCompleted={onSubmitCompleted}
                      measurementThresholds={measurementThresholds}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/thermometer/*"
                element={
                  <ProtectedRoute requireFunctionality="SYSTEM_THRESHOLDS_READ">
                    <SystemThresholdsFormWrapper
                      onSubmit={onSubmit}
                      onSubmitCompleted={onSubmitCompleted}
                      measurementThresholds={measurementThresholds}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/blood-pressure-monitor/*"
                element={
                  <ProtectedRoute requireFunctionality="SYSTEM_THRESHOLDS_READ">
                    <SystemThresholdsFormWrapper
                      onSubmit={onSubmit}
                      onSubmitCompleted={onSubmitCompleted}
                      measurementThresholds={measurementThresholds}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/glucometer/*"
                element={
                  <ProtectedRoute requireFunctionality="SYSTEM_THRESHOLDS_READ">
                    <SystemThresholdsFormWrapper
                      onSubmit={onSubmit}
                      onSubmitCompleted={onSubmitCompleted}
                      measurementThresholds={measurementThresholds}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/weight/*"
                element={
                  <ProtectedRoute requireFunctionality="SYSTEM_THRESHOLDS_READ">
                    <SystemThresholdsFormWrapper
                      onSubmit={onSubmit}
                      onSubmitCompleted={onSubmitCompleted}
                      measurementThresholds={measurementThresholds}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/height/*"
                element={
                  <ProtectedRoute requireFunctionality="SYSTEM_THRESHOLDS_READ">
                    <SystemThresholdsFormWrapper
                      onSubmit={onSubmit}
                      onSubmitCompleted={onSubmitCompleted}
                      measurementThresholds={measurementThresholds}
                    />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </NavTabPanel>
        </CardContent>
      </Card>
    </div>
  );
}
