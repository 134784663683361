import React from 'react';

interface Props {
  width?: number;
  height?: number;
  primaryColor?: string;
  secondaryColor?: string;
  active?: boolean;
}

export const CheckboxActiveCustomIcon = React.memo(function CheckboxActiveCustomIconComponent({
  width,
  height,
}: Props) {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="26" height="26" rx="7" fill="url(#paint0_linear)" />
      <g filter="url(#filter0_i)">
        <rect x="3.5" y="3" width="20" height="20" rx="5" fill="url(#paint1_linear)" />
      </g>
      <rect x="4" y="3.5" width="19" height="19" rx="4.5" stroke="url(#paint2_linear)" />
      <g clipPath="url(#clip0)">
        <g clipPath="url(#clip1)">
          <path
            d="M7.5 13L11.5 17L19.5 8.49999"
            stroke="url(#paint3_linear)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i"
          x="3.5"
          y="3"
          width="20"
          height="21"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="13.5"
          y1="0"
          x2="13.5"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0E0E0" />
          <stop offset="1" stopColor="#EDEDED" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="21.5748"
          y1="5.01178"
          x2="6.94673"
          y2="17.2587"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3003" stopColor="#1464F4" />
          <stop offset="0.9996" stopColor="#082CAF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="5.6875"
          y1="4.5625"
          x2="19.3267"
          y2="25.1443"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3003" stopColor="#1464F4" />
          <stop offset="0.9996" stopColor="#082CAF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="7.5"
          y1="8.49999"
          x2="7.5"
          y2="17"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EFEFEF" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="14" height="14" fill="white" transform="translate(6.5 6)" />
        </clipPath>
        <clipPath id="clip1">
          <rect width="14" height="14" fill="white" transform="translate(6.5 5.99999)" />
        </clipPath>
      </defs>
    </svg>
  );
});
