import * as Yup from 'yup';
import { Box } from '@mui/material';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { AlarmType, AlarmTypeEnum, AlarmTypeWithLabel, Optional } from '../../models';
import { alarmTypeOptionsSortFn, getTodayDateWithCertainHour } from '../../utils';
import { Form, SubmitFormCompletedHandler, SubmitFormHandler } from '../Form';
import { useAlarmStepperFormStyles } from './styles';
import { ReactComponent as CloseIcon } from '../../assets/icons/CloseIcon.svg';
import { IconButton } from '../IconButton';
import { FormStepper } from '../FormStepper';
import { RevalidateOnSchemaChange } from '../RevalidateOnSchemaChange';
import {
  AlarmFormPartialRepetition,
  AlarmFormPartialRepetitionSchema,
  AlarmFormPartialTimes,
  AlarmFormPartialTimesSchema,
  AlarmFormPartialTypes,
  AlarmFormPartialTypesSchema,
} from '../AlarmFormPartial';
import { AlarmTypesTabs } from '../../types';

interface TriggerTimes {
  morning: boolean;
  morningTimes: Date[];
  afternoon: boolean;
  afternoonTimes: Date[];
  evening: boolean;
  eveningTimes: Date[];
}

export interface AlarmFormValues
  extends AlarmFormTypesValues,
    AlarmFormRepetitionValues,
    AlarmFormTimesValues {}

export interface AlarmFormTypesValues {
  drugName?: Optional<string>;
  typeIds: number[];
}

export interface AlarmFormRepetitionValues {
  repetitionDays: number[];
  repetitionIntervalIsActive: boolean;
  repetitionInterval?: number;
  startOnDatetime?: Date;
  endOnDatetime?: Optional<Date>;
}

export interface AlarmFormTimesValues {
  triggerTimes: TriggerTimes;
}

type Step = {
  title: string;
  subtitle?: string;
  content: ReactNode;
  validationSchema: Yup.SchemaOf<
    AlarmFormTypesValues | AlarmFormRepetitionValues | AlarmFormTimesValues
  >;
};

interface Props {
  alarmTypes: AlarmType[];
  initialValues?: AlarmFormValues;
  onSubmit: SubmitFormHandler<AlarmFormValues>;
  onSubmitCompleted: SubmitFormCompletedHandler;
  onSubmitError: () => void;
  autocompleteDrugNames: string[];
  onClose?: () => void;
}

export function AlarmStepperForm({
  onSubmit,
  onSubmitCompleted,
  onSubmitError,
  onClose,
  initialValues,
  alarmTypes,
  autocompleteDrugNames,
}: Props) {
  const today = new Date(new Date().setUTCHours(0, 0, 0, 0));
  const { t: tAlarm } = useTranslation('alarm');
  const classes = useAlarmStepperFormStyles();

  const [activeStep, setActiveStep] = React.useState(0);
  const [typeStepSelectedTab, setTypeStepSelectedTab] = React.useState(AlarmTypesTabs.Sensor);

  const alarmTypeOptions: AlarmTypeWithLabel[] = alarmTypes
    .sort(alarmTypeOptionsSortFn)
    .map((alarmType) => ({
      id: alarmType.id,
      type: alarmType.type,
      label: tAlarm(`alarmType.${alarmType.type}` as const),
    }));

  const reminderId = alarmTypes.find((alarmType) => alarmType.type === AlarmTypeEnum.REMINDER)?.id;

  const steps: Step[] = [
    {
      title: tAlarm('formDialog.alarmTypes.title'),
      subtitle: tAlarm('formDialog.alarmTypes.subtitle'),
      content: (
        <AlarmFormPartialTypes
          alarmTypeOptions={alarmTypeOptions}
          reminderId={reminderId}
          autocompleteDrugNames={autocompleteDrugNames}
          selectedTab={typeStepSelectedTab}
          setSelectedTab={setTypeStepSelectedTab}
          isUsedInDialog={true}
        />
      ),
      validationSchema: AlarmFormPartialTypesSchema(reminderId),
    },
    {
      title: tAlarm('formDialog.alarmRepetition.title'),
      subtitle: tAlarm('formDialog.alarmRepetition.subtitle'),
      content: <AlarmFormPartialRepetition marginLeft={0} />,
      validationSchema: AlarmFormPartialRepetitionSchema(),
    },
    {
      title: tAlarm('formDialog.alarmTimes.title'),
      subtitle: tAlarm('formDialog.alarmTimes.subtitle'),
      content: <AlarmFormPartialTimes />,
      validationSchema: AlarmFormPartialTimesSchema(),
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const defaultFormValues: AlarmFormValues = {
    drugName: '',
    typeIds: [],
    triggerTimes: {
      morning: false,
      morningTimes: [getTodayDateWithCertainHour(8) as Date],
      afternoon: false,
      afternoonTimes: [getTodayDateWithCertainHour(12) as Date],
      evening: false,
      eveningTimes: [getTodayDateWithCertainHour(18) as Date],
    },
    repetitionDays: [],
    repetitionIntervalIsActive: false,
    repetitionInterval: 2,
    startOnDatetime: today,
    endOnDatetime: null,
  };

  const currentStepValidationSchema = steps[activeStep].validationSchema;
  return (
    <Form<
      AlarmFormValues,
      never,
      AlarmFormTypesValues | AlarmFormRepetitionValues | AlarmFormTimesValues
    >
      initialValues={initialValues || defaultFormValues}
      validationSchema={currentStepValidationSchema}
      onSubmit={onSubmit}
      onSubmitCompleted={onSubmitCompleted}
      onSubmitError={onSubmitError}
      formMode={'editable'}
    >
      <>
        <Box className={classes.formWrapper}>
          <RevalidateOnSchemaChange<
            AlarmFormTypesValues | AlarmFormRepetitionValues | AlarmFormTimesValues
          >
            validationSchema={currentStepValidationSchema}
          />
          <Box className={classes.headerText}>
            <Box className={classes.closeButtonWrapper}>
              <IconButton
                color="primary"
                icon={<CloseIcon />}
                onClick={onClose}
                className={classes.closeButton}
                backgroundColor="#3378f3"
              />
            </Box>
            <Box display="flex" flexDirection="column">
              <Box className={classes.title}>{steps[activeStep].title}</Box>
              <Box className={classes.subtitle}>{steps[activeStep].subtitle}</Box>
            </Box>
          </Box>
          <Box className={classes.content}>{steps[activeStep].content}</Box>
          <FormStepper
            activeStep={activeStep}
            stepsCount={steps.length}
            onBackClick={handleBack}
            onNextClick={handleNext}
          />
        </Box>
      </>
    </Form>
  );
}
