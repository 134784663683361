import * as Yup from 'yup';
import { TextInput } from '../../components/TextInput';
import { SubmitFormCompletedHandler, SubmitFormHandler } from '../../components/Form';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormButton } from '../../components/FormButton';
import { EntityForm } from '../../components/EntityForm';
import { DatePicker } from '../../components/DatePicker';
import { usePatientNoteFormStyles } from './styles';

export interface PatientNoteFormValues {
  createdAt?: Date;
  author?: string;
  text: string;
}

interface Props {
  initialValues: PatientNoteFormValues;
  onSubmit: SubmitFormHandler<PatientNoteFormValues>;
  onSubmitCompleted?: SubmitFormCompletedHandler;
  isNewNote?: boolean;
}

export function PatientNoteForm({
  initialValues,
  onSubmit,
  onSubmitCompleted,
  isNewNote = false,
}: Props): JSX.Element {
  const { t } = useTranslation('note');
  const classes = usePatientNoteFormStyles();
  const schema: Yup.SchemaOf<PatientNoteFormValues> = Yup.object({
    author: Yup.string(),
    createdAt: Yup.date().required(t('createdAt.validation.required')),
    text: Yup.string()
      .required(t('text.validation.required'))
      .max(100000, ({ max }) => t('text.validation.maxLength', { length: max })),
  });

  return (
    <EntityForm<PatientNoteFormValues>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      onSubmitCompleted={onSubmitCompleted}
      forceIsNew={isNewNote}
    >
      {!isNewNote && (
        <Box>
          <TextInput id="author" label={t('author.label')} disabled={true} />
          <DatePicker id="createdAt" label={t('createdAt.label')} required={true} disabled={true} />
        </Box>
      )}
      <TextInput
        id="text"
        label={t('text.label')}
        required={true}
        className={classes.input}
        multiline={true}
      />
      <Box display="flex" justifyContent="flex-end">
        <FormButton />
      </Box>
    </EntityForm>
  );
}
