import { CircularProgress } from '@mui/material';
import { RouteProps, Navigate, useLocation } from 'react-router-dom';
import { useCurrentUser } from '../../context';
import { Functionality } from '../../models';
import { NoPagePermissionError, RequirePageFunctionality } from '../RequireFunctionality';
import { useRequireFunctionality } from '../../hooks';

export interface ProtectedRouteProps extends Omit<RouteProps, 'component' | 'render'> {
  requireFunctionality?: Functionality | Functionality[];
}

export function ProtectedRoute({ requireFunctionality, children }: ProtectedRouteProps) {
  const { hasFunctionality } = useRequireFunctionality(requireFunctionality);
  const { isAuthenticated, isLoading } = useCurrentUser();
  const location = useLocation();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace state={{ from: location.pathname }} />;
  }

  if (!hasFunctionality) {
    return <NoPagePermissionError />;
  }

  return (
    <>
      <RequirePageFunctionality functionality={requireFunctionality}>
        {children}
      </RequirePageFunctionality>
    </>
  );
}
