import { Box } from '@mui/material';

interface IconProps {
  width?: number;
  height?: number;
}

export function SaveIcon({ width = 15, height = 15 }: IconProps) {
  return (
    <Box style={{ position: 'relative', left: '0.5px' }}>
      <svg
        width={`${width}`}
        height={`${height}`}
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.8345 3.90739C13.7129 3.61709 13.5668 3.38709 13.3964 3.21772L10.8421 0.67642C10.672 0.507142 10.4408 0.361865 10.1489 0.240779C9.85715 0.119821 9.58949 0.0593262 9.34627 0.0593262H0.880568C0.637379 0.0593262 0.430551 0.143981 0.260246 0.313418C0.0899399 0.48276 0.00488281 0.6885 0.00488281 0.930575V13.1287C0.00488281 13.3709 0.0899399 13.5766 0.260246 13.746C0.430551 13.9153 0.637379 14 0.880568 14H13.1412C13.3847 14 13.5914 13.9153 13.7617 13.746C13.9318 13.5766 14.0169 13.3709 14.0169 13.1287V4.70622C14.0169 4.46411 13.9561 4.19791 13.8345 3.90739ZM5.84327 1.51137C5.84327 1.43272 5.87219 1.36479 5.92999 1.30718C5.98773 1.24984 6.05617 1.2211 6.13522 1.2211H7.88681C7.96567 1.2211 8.03408 1.24977 8.09185 1.30718C8.14978 1.36479 8.17867 1.43272 8.17867 1.51137V4.4157C8.17867 4.49447 8.14959 4.5625 8.09185 4.62001C8.03408 4.67732 7.96567 4.70609 7.88681 4.70609H6.13522C6.05617 4.70609 5.9877 4.67742 5.92999 4.62001C5.87219 4.56241 5.84327 4.49447 5.84327 4.4157V1.51137ZM10.5141 12.8383H3.50781V9.35308H10.5141V12.8383ZM12.8496 12.8383H11.6817H11.6815V9.06262C11.6815 8.82049 11.5964 8.61487 11.4261 8.44537C11.2559 8.276 11.0491 8.19125 10.8059 8.19125H3.21593C2.97265 8.19125 2.76582 8.276 2.59551 8.44537C2.4253 8.61475 2.34015 8.82049 2.34015 9.06262V12.8383H1.17245V1.22097H2.34012V4.99662C2.34012 5.23869 2.42517 5.44434 2.59548 5.61377C2.76579 5.78312 2.97261 5.86787 3.2159 5.86787H8.47065C8.71381 5.86787 8.92082 5.78312 9.09084 5.61377C9.26099 5.44443 9.34627 5.23869 9.34627 4.99662V1.22097C9.43746 1.22097 9.55597 1.25117 9.70209 1.31167C9.84818 1.37213 9.95151 1.43262 10.0123 1.49312L12.5759 4.04357C12.6367 4.10407 12.6975 4.20856 12.7583 4.35673C12.8193 4.50503 12.8496 4.62153 12.8496 4.70622V12.8383H12.8496Z"
          fill="currentColor"
        />
      </svg>
    </Box>
  );
}
