import React, { useEffect } from 'react';
import { Box, Divider, Hidden } from '@mui/material';
import { FieldArray, FieldArrayRenderProps, useField } from 'formik';
import { CheckboxInput } from '../CheckboxInput';
import { NumberInput } from '../NumberInput';
import { useTranslation } from 'react-i18next';
import { useAlarmRepetitionIntervalsInputStyles } from './styles';
import { DAYS } from '../../constants';

interface Props {
  daysId: string;
  repetitionIntervalIsActiveId: string;
  repetitionIntervalId: string;
}

export function AlarmRepetitionIntervalsInput({
  daysId,
  repetitionIntervalIsActiveId,
  repetitionIntervalId,
}: Props) {
  const [
    { value: selectedDays },
    ,
    { setValue: setSelectedDays, setTouched: setSelectedDaysTouched },
  ] = useField<number[]>(daysId);
  const [{ value: eachXDaysIsActive }, , { setValue: setEachXDaysIsActive }] = useField<boolean>(
    repetitionIntervalIsActiveId,
  );
  const classes = useAlarmRepetitionIntervalsInputStyles();
  const { t: tCommon } = useTranslation('common');
  const { t: tAlarm } = useTranslation('alarm');

  useEffect(() => {
    if (eachXDaysIsActive) {
      // remove selected days
      setSelectedDays([]);
      setSelectedDaysTouched(true);
    }
  }, [eachXDaysIsActive]);

  useEffect(() => {
    if (selectedDays.length > 0) {
      // remove each x days repetition
      setEachXDaysIsActive(false);
    }
  }, [selectedDays]);

  function renderInputs(arrayHelpers: FieldArrayRenderProps) {
    return (
      <Box>
        <Box display="flex">
          <CheckboxInput
            id={'-1'}
            label={tCommon('eachDay', { count: 1 })}
            checked={DAYS.map((x) => x.id).every((r) => selectedDays.includes(r))}
            onChange={(e) => {
              setSelectedDaysTouched(true);
              if (e.target.checked) {
                // select all days
                setSelectedDays([...DAYS.map((x) => x.id)]);
              } else {
                // deselect all days
                setSelectedDays([]);
              }
            }}
          />
        </Box>
        <Hidden smUp={true}>
          <Box mx={1} my={1}>
            <Divider orientation="horizontal" />
          </Box>
        </Hidden>
        <Box className={classes.daysWrapper}>
          {DAYS.map((day, idx) => (
            <Box key={idx}>
              <CheckboxInput
                id={daysId}
                htmlId={`repetitionDay-${day.name}`}
                label={tCommon(`days.${day.name}` as const)}
                showError={false}
                checked={selectedDays.includes(day.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    arrayHelpers.push(day.id);
                  } else {
                    const idx = selectedDays.indexOf(day.id);
                    arrayHelpers.remove(idx);
                  }
                }}
              />
            </Box>
          ))}
        </Box>
        <Hidden smUp={true}>
          <Box mx={1} my={1}>
            <Divider orientation="horizontal" />
          </Box>
        </Hidden>
        <Box display="flex" alignItems="center">
          <CheckboxInput
            id={repetitionIntervalIsActiveId}
            htmlId={'repetitionIntervalCheckbox'}
            label={tCommon('other')}
            onChange={(e) => {
              setEachXDaysIsActive(e.target.checked);
            }}
          />
          <Box maxWidth={80}>
            <NumberInput
              id={repetitionIntervalId}
              label={tAlarm('eachXDays.label')}
              disabled={!eachXDaysIsActive}
              required={eachXDaysIsActive}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  function renderArray(arrayHelpers: FieldArrayRenderProps) {
    return <React.Fragment>{renderInputs(arrayHelpers)}</React.Fragment>;
  }

  return (
    <React.Fragment>
      <FieldArray name={daysId} render={renderArray} />
    </React.Fragment>
  );
}
