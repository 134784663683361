import React, { ReactNode } from 'react';
import { CustomIconButton } from './styles';

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  color: 'primary' | 'secondary';
  icon: ReactNode;
  disabled?: boolean;
  className?: string;
  backgroundColor?: string;
}

export interface StyleProps {
  height: number | 'auto';
  width: number | 'auto';
  invertColors?: boolean;
  backgroundColor?: string;
}

export function IconButton({ onClick, icon, color, disabled, backgroundColor, className }: Props) {
  const invertColors = color !== 'primary';

  return (
    <CustomIconButton
      onClick={onClick}
      invertColors={invertColors}
      height="auto"
      width="auto"
      backgroundColor={backgroundColor}
      disabled={disabled}
      className={className}
    >
      {icon}
    </CustomIconButton>
  );
}
