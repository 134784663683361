import { Patient } from '../../../models';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { getAge } from '../../../utils';
import { useScrollTrigger } from '../../../hooks';
import { CustomTooltip } from '../../CustomTooltip';

interface Props {
  patient: Patient;
  sectionTitleClass: string;
}

export function PatientHeaderPatientProfile({ patient, sectionTitleClass }: Props) {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'patient']);
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const trigger = useScrollTrigger();
  const showTitle = isTabletOrMobile || (!isTabletOrMobile && !trigger);

  return (
    <Box>
      {showTitle && (
        <Box className={sectionTitleClass}>
          {t('patient:patientHeader.patientProfile', { context: patient.sex })}
        </Box>
      )}
      <Box className={classes.sectionValue}>
        {patient.devicePairing && patient.devicePairing.purpose && (
          <CustomTooltip title={patient.devicePairing.purpose}>
            <Box className={`${classes.subsectionValue} ${classes.purpose}`}>
              {patient.devicePairing.purpose}
            </Box>
          </CustomTooltip>
        )}
        {patient.sex && (
          <Box className={classes.subsectionValue}>{t(`common:sex.${patient.sex}` as const)}</Box>
        )}
        {patient.birthday && (
          <CustomTooltip
            title={`${new Date(patient.birthday).toLocaleDateString()}`}
            placement="bottom-end"
          >
            <Box className={classes.subsectionValue}>
              {getAge(patient.birthday)}
              <Box className={classes.unit}>{t('common:yearShort')}</Box>
            </Box>
          </CustomTooltip>
        )}
        {patient.weight !== null && typeof patient.weight !== 'undefined' && (
          <CustomTooltip
            title={`${+patient.weight.toFixed(2)} ${t('common:kg')}`}
            placement="bottom-end"
          >
            <Box className={classes.subsectionValue}>
              {Math.round(patient.weight)}
              <Box className={classes.unit}>{t('common:kg')}</Box>
            </Box>
          </CustomTooltip>
        )}
        {patient.bmi !== null && typeof patient.bmi !== 'undefined' && (
          <CustomTooltip
            title={`${+patient.bmi.toFixed(2)} ${t('common:bmi')}`}
            placement="bottom-end"
          >
            <Box className={classes.subsectionValue}>
              {Math.round(patient.bmi)}
              <Box className={classes.unit}>{t('common:bmi')}</Box>
            </Box>
          </CustomTooltip>
        )}
        {(!patient.devicePairing || (patient.devicePairing && !patient.devicePairing.purpose)) &&
          !patient.sex &&
          !patient.birthday &&
          (patient.weight === null || typeof patient.weight === 'undefined') &&
          (patient.bmi === null || typeof patient.bmi === 'undefined') && (
            <Box className={`${classes.subsectionValue} ${classes.defaultText}`}>
              {t('patient:patientHeader.addInformationAboutPatient')}
            </Box>
          )}
      </Box>
    </Box>
  );
}
