import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useResetPasscodeButtonStyles = makeStyles((theme: Theme) => ({
  passcode: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    fontSize: '2.7rem',
  },
}));
