import { useNavigate } from 'react-router-dom';
import { ApiRoutes, makeApiRequest } from '../../services/api';
import { DepartmentCreateOrEditInput, DepartmentCreateResult } from '../../models';
import { DepartmentForm, DepartmentFormValues } from './DepartmentForm';
import { Page } from '../../components/Page';
import { useTranslation } from 'react-i18next';
import { FunctionalityProvider } from '../../context/functionality';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import { PageTopBar } from '../../components/PageTopBar';
import React from 'react';
import { Box } from '@mui/material';
import { useEntityActionSnackbar } from '../../hooks';

export function CreateDepartmentPage() {
  const { t } = useTranslation('department');
  const navigate = useNavigate();
  const { enqueueEntityCreatedSnackbar } = useEntityActionSnackbar();

  async function handleSubmit(values: DepartmentFormValues) {
    const input: DepartmentCreateOrEditInput = {
      ...values,
    };
    return makeApiRequest<DepartmentCreateResult, DepartmentCreateOrEditInput>(
      'POST',
      ApiRoutes.Departments,
      input,
    );
  }

  async function handleSubmitCompleted(data?: DepartmentCreateResult) {
    if (!data) {
      navigate(-1);

      return;
    }

    navigate(`/departments/${data.id}`, { replace: true });

    enqueueEntityCreatedSnackbar(t('department', { context: data?.id }), { context: data?.id });
  }

  return (
    <FunctionalityProvider
      createFunctionality="DEPARTMENTS_CREATE"
      readFunctionality="DEPARTMENTS_READ"
      updateFunctionality="DEPARTMENTS_UPDATE"
      deleteFunctionality="DEPARTMENTS_DELETE"
    >
      <>
        <StickyTopAppBar>
          <PageTopBar title={t('title.new')} hideLastUpdated={true} />
        </StickyTopAppBar>
        <Box mt={2}>
          <Page requireFunctionality="DEPARTMENTS_READ">
            <DepartmentForm<DepartmentCreateResult>
              onSubmit={handleSubmit}
              onSubmitCompleted={handleSubmitCompleted}
            />
          </Page>
        </Box>
      </>
    </FunctionalityProvider>
  );
}
