import { styled } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { thresholdColors } from '../../constants/colors';

interface GridStyleProps {
  headerHeight: number;
  rowHeight: number;
  pageSize: number;
  disableStriped: boolean;
}

const calculateGridMinHeight = ({ pageSize, headerHeight, rowHeight }: GridStyleProps) => {
  const rowsCount = pageSize + 1; // +1 for footer
  return headerHeight + rowsCount * rowHeight + 2; // +2 to make it same as MUI calculation
};

export const CustomDataGrid = styled(DataGrid, {
  shouldForwardProp: (prop) => prop !== 'disableStriped',
})<GridStyleProps>(({ theme, disableStriped, headerHeight, rowHeight, pageSize }) => ({
  minHeight: calculateGridMinHeight({ headerHeight, rowHeight, pageSize, disableStriped }),
  width: '100%',
  borderRadius: (theme as Theme).typography.pxToRem(5),
  boxShadow:
    '0 0 1px 0 rgba(0, 0, 0, 0.04), 0 2px 6px 0 rgba(0, 0, 0, 0.04), 0 10px 20px 0 rgba(0, 0, 0, 0.04)',
  '& .MuiDataGrid-columnsContainer': {
    color: 'white',
    borderTopLeftRadius: (theme as Theme).typography.pxToRem(5),
    borderTopRightRadius: (theme as Theme).typography.pxToRem(5),
    boxShadow: 'inset 0 -5px 5px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: '#595e6c',
    fontSize: (theme as Theme).typography.pxToRem(13),
    fontWeight: 600,
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: '#595e6c',
    color: 'white',
  },
  '& .MuiDataGrid-columnSeparator': {
    color: 'transparent',
  },
  '& .MuiDataGrid-sortIcon': {
    color: theme.palette.common.white,
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: `${(theme as Theme).typography.pxToRem(15)} !important`,
  },
  '& .MuiDataGrid-cell': {
    fontSize: `${(theme as Theme).typography.pxToRem(14)} !important`,
    fontWeight: 600,
    '&:focus-within': {
      outline: 'none !important',
    },
  },
  '& .MuiDataGrid-menuIconButton': {
    color: theme.palette.common.white,
  },
  '& .MuiDataGrid-root': {
    borderRadius: (theme as Theme).typography.pxToRem(5),
  },
  '& .MuiDataGrid-root .MuiDataGrid-cell': {
    borderBottom: `${(theme as Theme).typography.pxToRem(1)} solid rgba(0, 0, 0, 0.10) !important`,
  },
  '& .MuiDataGrid-row.Mui-even:not(:hover)': {
    backgroundColor: !disableStriped
      ? theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, 0.02)'
        : 'rgba(255, 255, 255, 0.04)'
      : 'inherit',
  },
  '& .MuiDataGrid-row.customSelected': {
    backgroundColor: `${theme.palette.primary.light}1A !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.light}26 !important`,
    },
  },
  '& .MuiDataGrid-row.minError': {
    backgroundColor: `${thresholdColors.minErrorLight} !important`,
    '&::before': {
      content: '""',
      backgroundColor: thresholdColors.minErrorDark,
      display: 'block',
      width: (theme as Theme).typography.pxToRem(2),
    },
  },
  '& .MuiDataGrid-row.maxError': {
    backgroundColor: `${thresholdColors.maxErrorLight} !important`,
    '&::before': {
      content: '""',
      backgroundColor: thresholdColors.maxErrorDark,
      display: 'block',
      width: (theme as Theme).typography.pxToRem(2),
    },
    '& .MuiDataGrid-cell::hover': {
      backgroundColor: `${thresholdColors.maxErrorDark} !important`,
      color: theme.palette.common.white,
    },
  },
  '& .MuiDataGrid-row.minWarning': {
    backgroundColor: `${thresholdColors.minWarningLight} !important`,
    '&::before': {
      content: '""',
      backgroundColor: thresholdColors.minWarningDark,
      display: 'block',
      width: (theme as Theme).typography.pxToRem(2),
    },
  },
  '& .MuiDataGrid-row.maxWarning': {
    backgroundColor: `${thresholdColors.maxWarningLight} !important`,
    '&::before': {
      content: '""',
      backgroundColor: thresholdColors.maxWarningDark,
      display: 'block',
      width: (theme as Theme).typography.pxToRem(2),
    },
  },
}));

export const rowClickableStyles = {
  '& .MuiDataGrid-cell:hover': {
    cursor: 'pointer',
  },
};

export const rowEmptyStyles = {
  color: '#B5B5B5',
};

export const addNewButtonStyles = (theme: Theme) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 600,
  backgroundColor: 'white',
  boxShadow: `0px ${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(10)} rgba(0, 0, 0, 0.10), 0px ${theme.typography.pxToRem(1)} ${theme.typography.pxToRem(5)} rgba(0, 0, 0, 0.25)`,
  padding: theme.spacing(0.5, 1.5),
  '&:hover, &:focus': {
    backgroundColor: theme.palette.grey['100'],
  },
});

export const goBackAndSaveSelectionButtonStyles = (theme: Theme) => ({
  borderRadius: 10,
  border: `${theme.typography.pxToRem(1)} solid ${theme.palette.grey['200']}`,
});

export const iconWrapperStyles = (theme: Theme) => ({
  color: '#828282',
  border: `${theme.typography.pxToRem(1)} solid transparent`,
  backgroundColor: 'inherit',
  padding: theme.spacing(1),
  borderRadius: theme.typography.pxToRem(5),
  transition:
    'color .2s ease-in-out, background-color .2s ease-in-out, border .2s ease-in-out, box-shadow .2s ease-in-out',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.primary.main}`,
    boxShadow: `0 ${theme.typography.pxToRem(1)} ${theme.typography.pxToRem(3)} 0 #0000001A inset`,
  },
  '&:hover::before': {
    boxShadow: `0 0 0 ${theme.typography.pxToRem(3)} ${theme.palette.grey['200']}`,
    borderRadius: theme.typography.pxToRem(5),
    content: '""',
    position: 'absolute',
    top: -0.5,
    left: -0.5,
    right: -0.5,
    bottom: -0.5,
  },
});
