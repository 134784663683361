import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  scoringCategoriesTitle: {
    fontWeight: 900,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(23),
    background: `-webkit-linear-gradient(230.06deg, ${theme.palette.primary.main} 30.16%, ${theme.palette.primary.dark} 77.51%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
}));
