import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  repetitionChipWrapper: {
    border: 'unset',
    backgroundColor: '#f3f4f7',
    padding: theme.spacing(0.25, 1.5),
  },
  repetitionChipText: {
    fontWeight: 'normal',
  },
}));
