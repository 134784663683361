import * as React from 'react';
import { ReactNode } from 'react';
import { useShowHide } from '../../hooks';
import { AlarmActiveShowHideFormContext } from './Context';

interface Props {
  children: ReactNode;
}

export function AlarmActiveShowHideFormProvider({ children }: Props) {
  const { isVisible: isAddNewVisible, show: showAddNew, hide: hideAddNew } = useShowHide(false);
  const {
    isVisible: isAddFromTemplateVisible,
    show: showAddFromTemplate,
    hide: hideAddFromTemplate,
  } = useShowHide(false);

  const isAnyFormVisible = isAddNewVisible || isAddFromTemplateVisible;

  function hideAllForms() {
    hideAddNew();
    hideAddFromTemplate();
  }

  return (
    <AlarmActiveShowHideFormContext.Provider
      value={{
        isAddNewVisible,
        showAddNew,
        hideAddNew,
        isAddFromTemplateVisible,

        showAddFromTemplate,
        hideAddFromTemplate,
        isAnyFormVisible,
        hideAllForms,
      }}
    >
      {children}
    </AlarmActiveShowHideFormContext.Provider>
  );
}
