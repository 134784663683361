import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 300,
  },
  intallation: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(3),
  },
  intallationWrapper: {
    maxWidth: theme.typography.pxToRem(350),
    margin: 'auto',
    width: '100%',
    marginBottom: theme.spacing(1),
    boxShadow: `0px ${theme.typography.pxToRem(1)} ${theme.typography.pxToRem(
      4,
    )} rgba(0, 0, 0, 0.25)`,
    borderRadius: theme.typography.pxToRem(10),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      width: '100%',
      maxWidth: 400,
      border: 'unset',
      boxShadow: 'unset',
    },
  },
}));
