import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TextInput } from '../../TextInput';
import { NumberInput } from '../../NumberInput';
import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { QuestionnaireScoringCategory } from '../../../models';
import { useScoringCategoryInputStyles } from './styles';

interface Props {
  id: string;
  index: number;
}

export function ScoringCategoryInput({ id, index }: Props) {
  const field = `${id}[${index}]`;
  const { t } = useTranslation('questionnaire');
  const classes = useScoringCategoryInputStyles();
  const [{ value }] = useField<QuestionnaireScoringCategory>(field);
  const { setFieldValue, setFieldTouched, getFieldMeta } = useFormikContext();

  useEffect(() => {
    // when changing value of score to, change value of score from in next field
    const nextField = `${id}[${index + 1}]`;
    const { value: nextFieldValue } = getFieldMeta<QuestionnaireScoringCategory>(nextField);

    if (!nextFieldValue) {
      return;
    }

    setFieldValue(nextField, { ...nextFieldValue, scoreFrom: value.scoreTo + 1 });
    setFieldTouched(`${nextField}.scoreTo`);
  }, [value?.scoreTo]);

  return (
    <Box className={classes.container}>
      <Box flex={3}>
        <TextInput
          id={`${field}.name`}
          label={t('scoringCategory.name.label')}
          required={true}
          debounceInput={true}
        />
      </Box>
      <Box className={classes.scoresContainer}>
        <NumberInput
          id={`${field}.scoreFrom`}
          label={t('scoringCategory.scoreFrom.label')}
          required={true}
          disabled={true}
          integerOnly={true}
          debounceInput={true}
        />
        <NumberInput
          id={`${field}.scoreTo`}
          label={t('scoringCategory.scoreTo.label')}
          required={true}
          integerOnly={true}
          debounceInput={true}
        />
      </Box>
    </Box>
  );
}
