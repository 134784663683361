import * as Yup from 'yup';
import { TextInput } from '../../components/TextInput';
import { SubmitFormCompletedHandler, SubmitFormHandler } from '../../components/Form';
import { Box } from '@mui/material';
import { Optional } from '../../models';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { FormButton } from '../../components/FormButton';
import { EntityForm } from '../../components/EntityForm';
import { CheckboxInput } from '../../components/CheckboxInput';
import { RoomFields } from '../../models/Room';
import { RoomsInput } from '../../components/RoomsInput';

export interface DepartmentFormValues {
  name: string;
  building: Optional<string>;
  floor: Optional<string>;
  medicAuthentication: Optional<boolean>;
  rooms: RoomFields[];
}

interface Props<TResponseData = never> {
  initialValues?: DepartmentFormValues;
  onSubmit: SubmitFormHandler<DepartmentFormValues, TResponseData>;
  onSubmitCompleted: SubmitFormCompletedHandler<TResponseData>;
}

export function DepartmentForm<TResponseData = never>({
  initialValues,
  onSubmit,
  onSubmitCompleted,
}: Props<TResponseData>) {
  const { t } = useTranslation('department');
  const defaultFormValues: DepartmentFormValues = {
    name: '',
    building: '',
    floor: '',
    medicAuthentication: false,
    rooms: [],
  };

  const classes = useStyles();

  const schema: Yup.SchemaOf<DepartmentFormValues> = Yup.object({
    name: Yup.string().required(t('name.validation.required')),
    building: Yup.string().nullable().default(null),
    floor: Yup.string().nullable().default(null),
    medicAuthentication: Yup.boolean(),
    rooms: Yup.array().of(
      Yup.object({
        name: Yup.string().required(t('room.name.validation.required')),
        bedCount: Yup.number().required(t('room.name.validation.required')),
        note: Yup.string().nullable().default(null),
      }),
    ),
  });

  return (
    <EntityForm<DepartmentFormValues, TResponseData>
      initialValues={initialValues || defaultFormValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      onSubmitCompleted={onSubmitCompleted}
    >
      <Box className={classes.formRow}>
        <TextInput id="name" label={t('name.label')} required={true} type="text" />
      </Box>
      <Box className={classes.formRow}>
        <TextInput id="building" label={t('building.label')} type="text" />
      </Box>
      <Box className={classes.formRow}>
        <TextInput id="floor" label={t('floor.label')} type="text" />
      </Box>
      <Box className={classes.formRow}>
        <CheckboxInput id="medicAuthentication" label={t('medicAuthentication.label')} />
      </Box>
      <RoomsInput id="rooms" />
      <Box display="flex" justifyContent="flex-end">
        <FormButton />
      </Box>
    </EntityForm>
  );
}
