import { SystemEvent } from '../../../models';
import { SystemEventPatientListItem } from './SystemEventPatientListItem';
import { SystemEventMeasurementListItem } from './SystemEventMeasurementListItem';
import { SystemEventQuestionnaireResponseListItem } from './SystemEventQuestionnaireResponseListItem';
import { SystemEventPatientNoteListItem } from './SystemEventPatientNoteListItem';
import { SystemEventAlarmListItem } from './SystemEventAlarmListItem';

interface Props {
  systemEvent: SystemEvent;
}

export function SystemEventListItem({ systemEvent }: Props) {
  if (systemEvent.entityType === 'patient') {
    return <SystemEventPatientListItem systemEvent={systemEvent} />;
  }

  if (systemEvent.entityType === 'measurement') {
    return <SystemEventMeasurementListItem systemEvent={systemEvent} />;
  }

  if (systemEvent.entityType === 'questionnaire_response') {
    return <SystemEventQuestionnaireResponseListItem systemEvent={systemEvent} />;
  }

  if (systemEvent.entityType === 'patient_note') {
    return <SystemEventPatientNoteListItem systemEvent={systemEvent} />;
  }

  if (systemEvent.entityType === 'alarm') {
    return <SystemEventAlarmListItem systemEvent={systemEvent} />;
  }

  return null;
}
