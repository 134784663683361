import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  bold: {
    fontWeight: 'bold',
  },
  primaryDark: {
    color: theme.palette.primary.dark,
  },
  fontColorPrimary: {
    color: theme.palette.text.primary,
  },
  fontColorSecondary: {
    color: theme.palette.text.secondary,
  },
  boxShadowAndBorderRadius: {
    border: 0,
    boxShadow: 'none',
    borderRadius: 0,
  },
  cardContent: {
    padding: '0 !important',
    [theme.breakpoints.down('sm')]: {
      padding: '0 !important',
    },
  },
  graphWrapper: {
    padding: theme.spacing(4, 0),
    backgroundColor: '#fafbfd',
    borderRadius: theme.typography.pxToRem(4),
    border: 'solid 1px #eff4f9',
  },
}));
