import { ApiError } from './types';

export interface ApiRequestState<T> {
  isLoading: boolean;
  isError: boolean;
  data: T | null;
  error?: ApiError;
}

export type ApiReducerActions<T> =
  | ApiRequestInitAction
  | ApiRequestSuccessAction<T>
  | ApiRequestFailureAction;

export type ApiReducerDispatcher<T> = (value: ApiReducerActions<T>) => void;

interface ApiRequestInitAction {
  type: 'API_REQUEST_INIT';
}

interface ApiRequestSuccessAction<T> {
  type: 'API_REQUEST_SUCCESS';
  payload: T;
}

interface ApiRequestFailureAction {
  type: 'API_REQUEST_FAILURE';
  payload?: ApiError;
}

export type ApiReducerType<T> = (
  state: ApiRequestState<T>,
  action: ApiReducerActions<T>,
) => ApiRequestState<T>;

export function apiReducer<T>(
  state: ApiRequestState<T>,
  action: ApiReducerActions<T>,
): ApiRequestState<T> {
  switch (action.type) {
    case 'API_REQUEST_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'API_REQUEST_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case 'API_REQUEST_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      throw new Error();
  }
}
