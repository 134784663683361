import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  pageTitle: {
    fontWeight: 'bold',
    padding: theme.spacing(1.5, 1),
    width: '100%',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    msHyphens: 'auto',
    hyphens: 'auto',
  },
}));
