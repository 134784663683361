import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { v4 } from 'uuid';

import { ACCESS_TOKEN } from '../../constants';

type ExclusiveEventHintOrCaptureContext = {
  // Define the properties of the type based on your requirements
  // For example:
  eventId?: string;
  originalException?: Error;
  // Add more properties as needed
};

export type RequestHeaders = Record<string, string | undefined>;

export function getUrl(endpoint: string) {
  return `${process.env.REACT_APP_API_URL}${endpoint}`;
}

export function getRequestHeaders(): RequestHeaders {
  return {
    'Request-Id': generateRequestId(),
    Authorization: getAccessToken(),
  };
}

export function getAccessToken(): string {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return '';
  }
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  return `Bearer ${accessToken}`;
}

export function getApiVersion() {
  return process.env.REACT_APP_API_VERSION;
}

export function getPreviousApiVersion() {
  return process.env.REACT_APP_API_VERSION_OLD;
}

export function hasPreviousApiVersion() {
  const version = getPreviousApiVersion();

  return version && version !== 'none';
}

function generateRequestId() {
  return v4();
}

export function CaptureApiError(error: AxiosError, customHeaders: RequestHeaders) {
  if (error?.response?.status == 500) {
    withSentryScope(customHeaders, () => {
      const responseData = error?.response?.data as ExclusiveEventHintOrCaptureContext | undefined;
      Sentry.captureException(error, responseData);
    });
  }
  if (error?.response?.status != 401 && error?.response?.status != 403) {
    const responseData = (error?.response?.data as { title?: string; description?: string }) || {};
    const title = responseData.title || error.message;
    const description = responseData.description || 'No description available';

    withSentryScope(customHeaders, (scope) => {
      if (description) {
        scope.setContext('Details', {
          Description: description,
        });
      }

      Sentry.captureMessage(title, 'warning');
    });
  }
  return;
}

function withSentryScope(customHeaders: RequestHeaders, callback: (scope: Sentry.Scope) => void) {
  Sentry.withScope((scope) => {
    for (const [key, value] of Object.entries(customHeaders)) {
      if (value != null && value != '') {
        scope.setTag(key, value);
      }
    }

    callback(scope);
  });
}
