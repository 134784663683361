import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useScoringCategoryInputStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    columnGap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  scoresContainer: {
    display: 'flex',
    flex: 2,
    flexDirection: 'row',
    columnGap: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
    },
  },
}));
