import { PatientQuestionnaireIcon } from '../../../components/Icons';
import { SystemEventQuestionnaireResponse } from '../../../models';
import { SystemEventListItemContainer } from './SystemEventListItemContainer';
import { SystemEventListItemAction } from './SystemEventListItemAction';
import { SystemEventListItemValues } from './SystemEventListItemValues';
import { QuestionnaireScoringCategoryWithScore } from '../../../components/Questionnaire';
import { SystemEventListItemEntityPropertyValue } from './SystemEventListItemEntityPropertyValue';
import { SystemEventListItemActionPatient } from './SystemEventListItemActionPatient';
import { useTranslation } from 'react-i18next';
import { SYSTEM_EVENT_ICON_SIZE } from './styles';

interface Props {
  systemEvent: SystemEventQuestionnaireResponse;
}

export function SystemEventQuestionnaireResponseListItem({ systemEvent }: Props) {
  const { t } = useTranslation(['questionnaire', 'systemEvent']);
  const questionnaireResponse = systemEvent.entity;

  return (
    <SystemEventListItemContainer
      linkTo={`/patients/${questionnaireResponse.patient.id}/questionnaires/${questionnaireResponse.id}`}
    >
      <SystemEventListItemAction
        icon={
          <PatientQuestionnaireIcon
            width={SYSTEM_EVENT_ICON_SIZE}
            height={SYSTEM_EVENT_ICON_SIZE}
          />
        }
        actionName={t('systemEvent:action.questionnaireResponseCreated')}
        systemEvent={systemEvent}
      />
      <SystemEventListItemValues>
        <SystemEventListItemActionPatient patient={systemEvent.entity.patient} />
        {questionnaireResponse.score != null && (
          <SystemEventListItemEntityPropertyValue
            unlimitedWidth={true}
            propertyName={t('questionnaire:question.score.label')}
            propertyValue={
              <QuestionnaireScoringCategoryWithScore
                score={questionnaireResponse.score}
                scoringCategories={systemEvent.entity.scoringCategories}
                includeCategoryName={true}
              />
            }
          />
        )}
      </SystemEventListItemValues>
    </SystemEventListItemContainer>
  );
}
