import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function ErrorPage() {
  const { t } = useTranslation('error');

  return (
    <Box display="flex" flex={1} alignItems="center" justifyContent="center">
      <Typography variant="h4">{t('generic')}</Typography>
    </Box>
  );
}
