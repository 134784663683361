import { Box } from '@mui/material';
import { BackIcon } from './BackIcon';

interface Props {
  color?: string;
  backgroundColor?: string;
  circleSize?: number;
  invertColors?: boolean;
}

export function BackIconWithCircleBackground({
  color = '#ffff',
  backgroundColor = '#0E48D2',
  circleSize = 26,
  invertColors = false,
}: Props) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.15)',
        width: circleSize,
        height: circleSize,
        borderRadius: '50%',
        backgroundColor: !invertColors ? backgroundColor : color,
        color: !invertColors ? color : backgroundColor,
      }}
    >
      <BackIcon />
    </Box>
  );
}
