import {
  Form as FormikForm,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikProps,
  FormikValues,
} from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormStyles } from './styles';
import { SubmitFormCompletedHandler, SubmitFormHandler } from './types';
import { FormikDisableProvider } from '../../context/formikDisable';
import { FormMode } from '../../types';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle } from '@mui/lab';

export interface FormRenderOptions<T> {
  values: T;
}

export interface FormProps<T, TResponseData = never, TValidationSchema = T> {
  initialValues: T;
  validationSchema: Yup.SchemaOf<TValidationSchema>;
  validateOnMount?: boolean;
  onSubmit: SubmitFormHandler<T, TResponseData>;
  onSubmitCompleted?: SubmitFormCompletedHandler<TResponseData>;
  onSubmitError?: () => void;
  children: React.ReactNode | ((renderOptions: FormRenderOptions<T>) => React.ReactNode);
  noResetFormOnSubmitCompleted?: boolean;
  formMode?: FormMode;
  disableXPadding?: boolean;
}

export function Form<
  TFormValues extends FormikValues,
  TResponseData = never,
  TValidationSchema = TFormValues,
>({
  initialValues,
  validationSchema,
  validateOnMount = true,
  onSubmit,
  onSubmitCompleted,
  onSubmitError,
  children,
  noResetFormOnSubmitCompleted = false,
  formMode = 'editable',
  disableXPadding = false,
}: FormProps<TFormValues, TResponseData, TValidationSchema>) {
  const { t } = useTranslation('error');
  const [formError, setFormError] = useState<string | null>(null);
  const classes = useFormStyles();

  async function handleSubmit(
    values: TFormValues,
    { setSubmitting, setErrors, resetForm }: FormikHelpers<TFormValues>,
  ) {
    setFormError(null);

    const { data, error } = await onSubmit(values);
    if (error) {
      if (error.errors && Object.keys(error.errors).length > 0) {
        setErrors(error.errors as FormikErrors<TFormValues>);
      } else if (error.description || error.title) {
        // if error does not contain field errors
        // than show the error description or title.
        // Prefer description as it's more descriptive.
        setFormError(error.description || error.title || '');
      } else {
        // Show a generic error as a last resort for the user to know
        // that something went wrong.
        setFormError(t('generic'));
      }

      if (onSubmitError) {
        onSubmitError();
      }

      setSubmitting(false);
      return;
    }

    if (!noResetFormOnSubmitCompleted) {
      resetForm({});
    }

    setSubmitting(false);

    if (onSubmitCompleted) {
      await onSubmitCompleted(data);
    }
  }

  function render({ values }: FormikProps<TFormValues>) {
    return (
      <FormikForm
        className={`${classes.form} ${!disableXPadding ? classes.formPadding : ''}`}
        role="form"
      >
        <div>
          {typeof children === 'function' ? children({ values }) : children}
          {!!formError && (
            <Alert className={classes.alert} severity="error">
              <AlertTitle>{t('title.error')}</AlertTitle>
              {formError}
            </Alert>
          )}
        </div>
      </FormikForm>
    );
  }

  return (
    <FormikDisableProvider formMode={formMode}>
      <Formik<TFormValues>
        initialValues={initialValues}
        validateOnMount={validateOnMount}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {render}
      </Formik>
    </FormikDisableProvider>
  );
}
