import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSystemEventListItemContainerStyles } from './styles';

interface Props {
  linkTo: string;
  children: React.ReactNode;
}

export function SystemEventListItemContainer({ children, linkTo }: Props) {
  const classes = useSystemEventListItemContainerStyles();

  return (
    <Box
      flex={1}
      flexDirection="row"
      display="flex"
      alignItems="center"
      className={classes.container}
    >
      <Link to={linkTo} className={classes.link}>
        <Box className={classes.content}>{children}</Box>
      </Link>
    </Box>
  );
}
