import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
    minWidth: theme.typography.pxToRem(60),
    border: `${theme.typography.pxToRem(1)} solid #ddd`,
    borderBottom: 'none',
    borderTopLeftRadius: theme.typography.pxToRem(10),
    borderTopRightRadius: theme.typography.pxToRem(10),
    color: 'grey',
    background: `linear-gradient(${theme.palette.common.white}, #eee)`,
    paddingBottom: 0,
    paddingTop: 0,
    maxWidth: '350px',
    borderRadius: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(10)} 0 0`,
    boxShadow: `0 ${theme.typography.pxToRem(-3)} ${theme.typography.pxToRem(
      5,
    )} 0 rgba(0, 0, 0, 0.05)`,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
  },
  selected: {
    backgroundColor: theme.palette.common.white,
    borderBottom: 'none',
    color: 'black',
    background: 'white',
  },
  wrapper: {
    flexDirection: 'row',
    '&>*:first-child': {
      marginBottom: '0 !important',
    },
  },
  labelIcon: {
    minHeight: theme.typography.pxToRem(45),
  },
  label: {
    marginLeft: theme.spacing(1),
  },
}));

export const useMeasurementStyles = makeStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    fontFamily: 'Rubik',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(13),
    minWidth: theme.typography.pxToRem(150),
    maxWidth: 'none',
    border: `solid ${theme.typography.pxToRem(0.5)} rgba(39, 51, 93, 0.5)`,
    borderRadius: theme.typography.pxToRem(4),
    color: theme.palette.grey['600'],
    background: `white`,
    flex: 1,
    height: theme.typography.pxToRem(64),
  },
  selected: {
    backgroundImage: 'linear-gradient(to top, #4675f6, #3e4df5)',
    color: 'white !important',
  },
  wrapper: {
    flexDirection: 'row',
    position: 'relative',
    '&>*:first-child': {
      marginBottom: '0 !important',
    },
    '&>span': {
      textAlign: 'left',
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-around',
      padding: theme.spacing(0, 0),
      height: '100%',
    },
  },
  flexContainer: {
    wrap: 'wrap',
  },
  labelIcon: {
    minHeight: theme.typography.pxToRem(80),
  },
  label: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
    },
  },
}));

interface StyleProps {
  unsetMinHeight: boolean;
}

export const useMeasurementTypeStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: {
    textTransform: 'uppercase',
    fontFamily: 'Inter',
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(12),
    border: 0,
    borderRadius: theme.typography.pxToRem(4),
    color: '#1464f4',
    background: `white`,
    flex: 1,
    padding: theme.spacing(1),
    height: theme.typography.pxToRem(28),
    backgroundColor: 'transparent',
    minHeight: ({ unsetMinHeight }) => (unsetMinHeight ? 'unset' : '48px'),
    width: 'fit-content',
    maxWidth: theme.typography.pxToRem(87),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
    },
  },
  selected: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 1px 12px 0 rgba(20, 46, 110, 0.06)',
  },
  wrapper: {
    flexDirection: 'row',
    position: 'relative',
    '&>*:first-child': {
      marginBottom: '0 !important',
    },
    '&>span': {
      width: '100%',
      textAlign: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-around',
      padding: theme.spacing(0, 0),
      height: '100%',
    },
  },
  flexContainer: {
    wrap: 'wrap',
  },
  labelIcon: {
    minHeight: theme.typography.pxToRem(45),
  },
  label: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
    },
  },
}));
