import { Tabs } from '@mui/material';
import { useMeasurementTypesStyles } from './styles';
import { ClassNameMap } from '@mui/styles/withStyles/withStyles';

interface StyledTabsProps {
  value: number | string;
  onChange: (event: React.SyntheticEvent, newValue: number | string) => void;
  children: React.ReactNode;
  classes?: Partial<ClassNameMap>;
}

export function StyledMeasurementTypesTabs({ classes, ...props }: StyledTabsProps) {
  const { onChange, value, children } = props;
  const componentClasses = useMeasurementTypesStyles();
  return (
    <Tabs
      value={value}
      onChange={onChange}
      children={children}
      TabIndicatorProps={{ children: <span /> }}
      classes={classes ?? componentClasses}
    />
  );
}
