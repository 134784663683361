import React from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

export const WeightIcon = React.memo(function WeightIconComponent({ width, height, color }: Props) {
  return (
    <svg
      width={width || 16}
      height={height || 18}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.38809 0H12.6119C14.6214 0 16.1892 1.73738 15.9815 3.73411L14.8966 14.1636C14.7173 15.888 13.2625 17.1981 11.527 17.1981H8.50387V13.1498C8.50387 12.8718 8.27827 12.6464 8.00001 12.6464C7.72175 12.6464 7.49616 12.8718 7.49616 13.1498V17.1981H4.47298C2.73755 17.1981 1.28276 15.888 1.10336 14.1636L0.0184969 3.73411C-0.189191 1.73738 1.37859 0 3.38809 0ZM10.9703 8.66756L13.7157 5.51496C13.8807 5.32555 13.8807 5.04366 13.7157 4.85424C12.1934 3.10615 10.1635 2.14342 8.00001 2.14342C5.83651 2.14342 3.80662 3.10611 2.28432 4.85421C2.11936 5.04366 2.11936 5.32555 2.28432 5.51496L5.02974 8.66756C5.12544 8.77747 5.26406 8.84055 5.40988 8.84055H10.5901C10.7359 8.84055 10.8746 8.77744 10.9703 8.66756ZM11.3146 4.9208L11.7517 4.40338C12.0698 4.6365 12.3735 4.90035 12.6596 5.19389L10.3606 7.83387H9.01886L8.22757 6.12165C8.13795 5.9277 7.86201 5.9277 7.77239 6.12165L6.98111 7.83387H5.63935L3.34038 5.19389C3.62613 4.90082 3.92921 4.63737 4.24667 4.40456L4.68546 4.92077C4.78512 5.04018 4.92839 5.10182 5.07272 5.10182C5.18649 5.10182 5.3009 5.06353 5.39512 4.9851C5.60882 4.8071 5.63767 4.48971 5.45948 4.27618L5.11971 3.8611C5.86458 3.47354 6.66647 3.23861 7.49618 3.17086V3.79076C7.49618 4.06876 7.72177 4.29411 8.00003 4.29411C8.27829 4.29411 8.50389 4.06876 8.50389 3.79076V3.17086C9.3336 3.23861 10.1355 3.47354 10.8803 3.86114L10.5406 4.27621C10.3624 4.48974 10.3912 4.80713 10.6049 4.98513C10.6992 5.06359 10.8135 5.10185 10.9273 5.10185C11.0716 5.10185 11.2149 5.04022 11.3146 4.9208Z"
        fill={color ?? '#6B6B6B'}
      />
    </svg>
  );
});
