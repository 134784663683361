import { useTranslation } from 'react-i18next';
import { NoRecords } from '../../../../../components/NoRecords';

export function NoMeasurements() {
  const { t } = useTranslation('measurement');
  return (
    <NoRecords
      title={t('error.deviceMeasurementsNotFound')}
      image={<img src="/img/img-device.png" alt="Device image" />}
    />
  );
}
