import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { QuestionnaireScoringCategory } from '../../../models';
import { ScoringCategoryColorIndicator } from '../ScoringCategoryColorIndicator';

interface Props {
  score: number;
  scoringCategories: QuestionnaireScoringCategory[];
  includeCategoryName?: boolean;
}

export function QuestionnaireScoringCategoryWithScore({
  score,
  scoringCategories,
  includeCategoryName,
}: Props) {
  const [categories, setCategories] = useState<QuestionnaireScoringCategory[]>([]);

  useEffect(() => {
    const sortedCategories = (scoringCategories || []).sort((a, b) => a.scoreFrom - b.scoreFrom);
    setCategories(sortedCategories);
  }, [scoringCategories]);

  const index = getScoringCategoryIndex(score || 0, categories);

  return (
    <Box display="flex" alignItems="center">
      <Box marginRight={1}>
        <ScoringCategoryColorIndicator categoriesCount={categories.length} categoryIndex={index} />
      </Box>
      <Typography>{score}</Typography>
      {includeCategoryName && scoringCategories.length > index && (
        <Box marginLeft={1}>
          <Typography
            id={`questionnaireHeaderScoringCategory[${index}].name`}
          >{`(${scoringCategories[index].name})`}</Typography>
        </Box>
      )}
    </Box>
  );
}

function getScoringCategoryIndex(
  score: number,
  scoringCategories: QuestionnaireScoringCategory[],
): number {
  for (let i = scoringCategories.length - 1; i >= 0; i--) {
    if (score >= scoringCategories[i].scoreFrom) {
      return i;
    }
  }

  return 0;
}
