import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import { ProgressIndicator } from '../../components/ProgressIndicator';
import { Page } from '../../components/Page';
import { useTranslation } from 'react-i18next';
import { FunctionalityProvider } from '../../context/functionality';
import { PatientsList } from './PatientsList';
import { Patient } from '../../models';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import React, { useEffect, useState } from 'react';
import { PageTopBar } from '../../components/PageTopBar';
import { getFirstMeasurementTypePath, isSubstring } from '../../utils';
import * as NavigationConstants from '../../constants/NavigationConstants';
import { useNavigate } from 'react-router';
import { useEntityActionSnackbar, useRequireFunctionality } from '../../hooks';
import {
  PATIENTS_DELETE_POLICY,
  PATIENTS_READ_POLICY,
  PATIENTS_UPDATE_POLICY,
} from '../../constants/Policies';

export function ListPatientsPage() {
  const navigate = useNavigate();
  const { enqueueEntityDeletedSnackbar } = useEntityActionSnackbar();
  const { hasFunctionality: hasCreatePatientFunctionality } =
    useRequireFunctionality('PATIENTS_CREATE');
  const { t } = useTranslation('patient');
  const [
    { data: patients, isLoading: arePatientsLoading },
    { refetch: refetchPatients },
    { lastFetchDatetime },
  ] = useGetApi<Patient[]>(ApiRoutes.Patients);
  const [filteredPatients, setFilteredPatients] = useState(patients);

  useEffect(() => {
    setFilteredPatients(patients);
  }, [patients]);

  async function handleDelete(id: number) {
    await makeApiRequest('DELETE', ApiRoutes.Patient(id));
  }

  async function handleDeleteCompleted(deletedPatient: Patient) {
    await refetchPatients();

    enqueueEntityDeletedSnackbar(t('patient', { context: deletedPatient.sex }), {
      context: deletedPatient.sex,
    });
  }

  function handleSearch(searchedText: string) {
    if (!patients) {
      setFilteredPatients([]);
    } else {
      if (searchedText === '') {
        setFilteredPatients(patients);
      } else {
        const searchedTextToLower = searchedText.toLowerCase();
        const searchedPatients = patients.filter((patient) => {
          const { firstName, lastName, username, identificationNumber, devicePairing } = patient;
          return (
            isSubstring(firstName, searchedTextToLower) ||
            isSubstring(lastName, searchedTextToLower) ||
            isSubstring(username, searchedTextToLower) ||
            isSubstring(identificationNumber, searchedTextToLower) ||
            isSubstring(devicePairing?.purpose, searchedTextToLower)
          );
        });
        setFilteredPatients(searchedPatients);
      }
    }
  }

  function onRowClick(row: Patient) {
    navigate(
      `/patients/${row.id}/measurements/${getFirstMeasurementTypePath()}?${
        NavigationConstants.DEFAULT_MEASUREMENT_SORT_PATH_PARAM
      }`,
    );
  }

  if (arePatientsLoading) {
    return <ProgressIndicator />;
  }

  return (
    <FunctionalityProvider
      createFunctionality="PATIENTS_CREATE"
      readFunctionality={PATIENTS_READ_POLICY}
      updateFunctionality={PATIENTS_UPDATE_POLICY}
      deleteFunctionality={PATIENTS_DELETE_POLICY}
    >
      <>
        <StickyTopAppBar>
          <PageTopBar
            title={t('title.list')}
            {...(hasCreatePatientFunctionality ? { addNewRedirectPath: '/patients/new' } : {})}
            newRecordDescriptionText={t('title.new')}
            onSearch={handleSearch}
            lastUpdated={lastFetchDatetime}
          />
        </StickyTopAppBar>
        <Page noPaper={true} fullWidth={true} requireFunctionality={PATIENTS_READ_POLICY}>
          <PatientsList
            patients={filteredPatients}
            onDelete={handleDelete}
            onDeleteCompleted={handleDeleteCompleted}
            onRowClick={onRowClick}
          />
        </Page>
      </>
    </FunctionalityProvider>
  );
}
