import { IdentifierType, Optional, Patient } from '../../../../../models';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { TableRow } from '../../common/TableRow';
import { useTranslation } from 'react-i18next';
import {
  getDisplayValueFromStringArray,
  getDoubleDisplayValue,
  isNullOrUndefined,
} from '../../../../../utils';
import { DATE_TIME_LOCALE_WITHOUT_SECONDS } from '../../../../../constants';
import { FONT_SIZES, FONT_WEIGHTS } from '../../../../Constants';

interface Props {
  patient: Patient;
  patientFetchDatetime: Date | null;
  identifierTypes: IdentifierType[];
  weightUnit?: Optional<string>;
  heightUnit?: Optional<string>;
}

const styles = StyleSheet.create({
  patientDataDisclaimer: {
    fontSize: FONT_SIZES.h6,
    fontWeight: FONT_WEIGHTS.light,
    marginBottom: 2,
  },
});

export function PatientDetail({
  patient,
  patientFetchDatetime,
  identifierTypes,
  weightUnit,
  heightUnit,
}: Props) {
  const { t } = useTranslation(['common', 'patient', 'report']);

  const identifierDisplayValue = identifierTypes.find(
    (x) => x.id == patient.identifierTypeId,
  )?.type;

  const nonExistingValue = '--';
  const firstColumnWidth = 0.35;

  return (
    <View>
      {patientFetchDatetime && (
        <Text style={styles.patientDataDisclaimer}>
          {t('report:patientDataDisclaimer', {
            dateTime: new Date(patientFetchDatetime).toLocaleDateString(
              [],
              DATE_TIME_LOCALE_WITHOUT_SECONDS,
            ),
          })}
        </Text>
      )}
      <TableRow
        data={[
          { value: t('patient:sex.label'), width: firstColumnWidth },
          { value: patient.sex ? t(`common:sex.${patient.sex}` as const) : nonExistingValue },
        ]}
        showTopBorder={true}
      />
      <TableRow
        data={[
          { value: t('patient:birthday.label'), width: firstColumnWidth },
          {
            value: patient.birthday
              ? new Date(patient.birthday).toLocaleDateString()
              : nonExistingValue,
          },
        ]}
      />
      <TableRow
        data={[
          { value: t('patient:weight.label'), width: firstColumnWidth },
          {
            value:
              !isNullOrUndefined(patient.weight) && patient.weight
                ? `${getDoubleDisplayValue(patient.weight)} ${weightUnit}`
                : nonExistingValue,
          },
        ]}
      />
      <TableRow
        data={[
          { value: t('patient:height.label'), width: firstColumnWidth },
          {
            value:
              !isNullOrUndefined(patient.height) && patient.height
                ? `${getDoubleDisplayValue(patient.height)} ${heightUnit}`
                : nonExistingValue,
          },
        ]}
      />
      <TableRow
        data={[
          { value: t('common:bmi'), width: firstColumnWidth },
          {
            value:
              !isNullOrUndefined(patient.bmi) && patient.bmi
                ? getDoubleDisplayValue(patient.bmi)
                : nonExistingValue,
          },
        ]}
      />
      <TableRow
        data={[
          {
            value: t('patient:email.label'),
            width: firstColumnWidth,
          },
          { value: patient.email ?? nonExistingValue },
        ]}
      />
      <TableRow
        data={[
          {
            value: t('patient:phone.label'),
            width: firstColumnWidth,
          },
          { value: patient.phone ?? nonExistingValue },
        ]}
      />
      <TableRow
        data={[
          { value: t('patient:identificationNumber.label'), width: firstColumnWidth },
          {
            value: getDisplayValueFromStringArray(
              [identifierDisplayValue, patient.identificationNumber],
              nonExistingValue,
              ': ',
            ),
          },
        ]}
      />
      <TableRow
        data={[
          { value: t('patient:civilStatus.label'), width: firstColumnWidth },
          {
            value: patient.civilStatus
              ? t(`common:civilStatus.${patient.civilStatus}` as const)
              : nonExistingValue,
          },
        ]}
      />
      <TableRow
        data={[
          { value: t('patient:address.label'), width: firstColumnWidth },
          {
            value: getDisplayValueFromStringArray(
              [patient.addressStreet, patient.addressState],
              nonExistingValue,
              ', ',
            ),
          },
        ]}
      />
    </View>
  );
}
