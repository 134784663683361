import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Skeleton } from '@mui/lab';
import React from 'react';
import { SKELETON_ANIMATION } from '../../../constants';
import { useExportPatientFormStyles } from './styles';

export function ExportPatientFormLoading() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const classes = useExportPatientFormStyles();

  return (
    <Box display="flex" flexDirection="column" width="100%" p={matches ? 2 : 0}>
      <Box display="flex" width="100%">
        <Box display="flex" flexDirection="column" width="100%">
          <Skeleton
            variant="rectangular"
            animation={SKELETON_ANIMATION}
            width="100%"
            height={50}
            style={{
              marginBottom: theme.spacing(1),
            }}
          />
          <Skeleton
            variant="rectangular"
            animation={SKELETON_ANIMATION}
            width="max(30%, 240px)"
            height={40}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        width="100%"
        flexDirection="row"
        style={{
          margin: theme.spacing(2, 0),
        }}
      >
        <Skeleton
          variant="rectangular"
          animation={SKELETON_ANIMATION}
          width="50%"
          height={50}
          style={{
            marginRight: theme.spacing(1),
          }}
        />
        <Skeleton
          variant="rectangular"
          animation={SKELETON_ANIMATION}
          width="50%"
          height={50}
          style={{
            marginRight: theme.spacing(1),
          }}
        />
      </Box>
      <Box>
        <Skeleton
          variant="rectangular"
          animation={SKELETON_ANIMATION}
          width="max(40%, 300px)"
          height={40}
        />
      </Box>
      <Box className={classes.flexWrapMoreItems} style={{ margin: theme.spacing(2, 0) }}>
        {[...Array(5)].map((e, i) => (
          <Skeleton variant="rectangular" height={60} key={i} animation={SKELETON_ANIMATION} />
        ))}
      </Box>
      <Box display="flex" width="100%" justifyContent="flex-end">
        <Skeleton variant="rectangular" animation={SKELETON_ANIMATION} width="100px" height={40} />
      </Box>
    </Box>
  );
}
