import React from 'react';
import { Box } from '@mui/material';
import { useStyles } from './styles';

interface Props {
  size: number;
  children: React.ReactNode;
  color?: string;
}

export function IconWrapper({ children, size, color = 'inherit' }: Props) {
  const classes = useStyles({ size, color });
  return <Box className={classes.wrapper}>{children}</Box>;
}
