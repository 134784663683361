import React, { ReactNode } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Hidden } from '@mui/material';
import { useStyles } from './styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { RelativeDateTime } from '../RelativeDateTime';
import { useScrollTrigger } from '../../hooks';

interface Props {
  entityName: string;
  lastUpdated: Date | null;
  middleSectionComponent?: ReactNode;
  buttonsComponent?: ReactNode;
}

export function PageTopBarDetailed({
  entityName,
  lastUpdated,
  middleSectionComponent,
  buttonsComponent,
}: Props) {
  const scrollTrigger = useScrollTrigger();
  const classes = useStyles({ scrollTrigger });
  const { t } = useTranslation('common');

  return (
    <Box className={classes.wrapper}>
      <Hidden lgUp={true}>
        <Accordion elevation={0} classes={{ root: classes.accordionRoot }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="header-topbar-content"
            id="header-topbar-header"
            classes={{ content: classes.accordionSummaryContent }}
          >
            <Box
              className={`${classes.accordionSummary} ${classes.sectionEntityNameWrapper}`}
              style={{ flex: 4 }}
            >
              <Box className={`${classes.sectionTitle} ${classes.lastUpdatedWrapper}`}>
                {t('lastUpdated')}:
                <Box className={classes.lastUpdatedValue}>
                  <RelativeDateTime date={lastUpdated} />
                </Box>
              </Box>
              <Box className={classes.entityName}>{entityName}</Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
            <Box className={classes.wrapper}>
              {middleSectionComponent}
              <Box className={classes.buttonsWrapper} style={{ flex: 2 }}>
                <Box className={`${classes.flexRow} ${classes.flexRowPadding}`}>
                  {buttonsComponent}
                </Box>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Hidden>
      <Hidden lgDown={true}>
        <Box
          className={`${classes.flexColumn} ${classes.sectionEntityNameWrapper}`}
          style={{ flex: 4 }}
        >
          {!scrollTrigger && (
            <Box className={`${classes.sectionTitle} ${classes.lastUpdatedWrapper}`}>
              {t('lastUpdated')}:
              <Box className={classes.lastUpdatedValue}>
                <RelativeDateTime date={lastUpdated} />
              </Box>
            </Box>
          )}
          <Box className={classes.entityNameWrapper}>
            <Box className={classes.entityName}>{entityName}</Box>
          </Box>
        </Box>
        {middleSectionComponent}
        <Box className={classes.buttonsWrapper} style={{ flex: 2 }}>
          <Box className={`${classes.flexRow} ${classes.flexRowPadding}`}>{buttonsComponent}</Box>
        </Box>
      </Hidden>
    </Box>
  );
}
