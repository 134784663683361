import React from 'react';
import { theme } from '../../assets/style';

interface Props {
  width?: number;
  height?: number;
  primaryColor?: string;
  secondaryColor?: string;
  active?: boolean;
}

export const OximeterIconWithCircleBackground = React.memo(function OximeterIconComponent({
  width,
  height,
  primaryColor = theme.palette.primary.main,
}: Props) {
  return (
    <svg
      width={width || 35}
      height={height || 35}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d500)">
        <circle cx="17.5" cy="16.5" r="12.5" fill="white" />
        <path
          d="M20.5094 13.3604C19.2932 12.3091 18.2447 11.3993 18.2447 10.0231C18.2384 9.71953 17.989 9.47487 17.6795 9.46875C16.857 9.46875 12.0313 14.5378 12.0313 17.9746C12.0301 21.0423 14.5649 23.5301 17.6929 23.5312C19.6123 23.532 21.309 22.5963 22.334 21.1643C22.2788 21.1048 22.2292 21.0394 22.1861 20.9688H22.186L21.6174 20.0396L20.8934 21.4598C20.692 21.8536 20.2924 22.1142 19.8426 22.1414H19.8346C19.8052 22.1429 19.7775 22.1432 19.7667 22.1431H19.7645C19.3445 22.1431 18.9494 21.9373 18.7143 21.5915L17.9211 20.4249H17.1365C16.4396 20.4249 15.8743 19.87 15.8743 19.1865C15.8743 18.503 16.4396 17.9489 17.1365 17.9489H18.5965C18.996 17.9489 19.3697 18.1344 19.6071 18.4452L20.3876 16.914L20.388 16.9132C20.5938 16.5119 21.0058 16.249 21.4677 16.2316L21.4729 16.2307H21.478C21.9456 16.2201 22.3613 16.445 22.5979 16.83L22.5986 16.8309L23.358 18.0727C23.3586 18.0414 23.3588 18.0101 23.3589 17.9788V17.9746C23.3589 15.8338 21.8464 14.5187 20.5094 13.3604Z"
          fill={primaryColor}
        />
        <path
          d="M19.2786 21.2231C19.3873 21.3829 19.57 21.4779 19.7645 21.4779C19.7768 21.4779 19.789 21.4779 19.8007 21.4774C20.0086 21.4648 20.1938 21.3445 20.2872 21.1618L21.5632 18.6583L22.768 20.6274C22.8731 20.7998 23.0635 20.9052 23.2685 20.9052H24.7285C25.0509 20.9052 25.3125 20.6486 25.3125 20.3325C25.3125 20.0163 25.0509 19.7597 24.7285 19.7597H23.599L22.017 17.1732C21.9072 16.9945 21.7203 16.8909 21.4937 16.896C21.2806 16.9041 21.0896 17.0255 20.9938 17.2122L19.6921 19.766L19.0824 18.8691C18.9738 18.7099 18.7916 18.6142 18.5965 18.6142H17.1365C16.8142 18.6142 16.5525 18.8708 16.5525 19.187C16.5525 19.5031 16.8142 19.7597 17.1365 19.7597H18.2841L19.2786 21.2231Z"
          fill={primaryColor}
        />
      </g>
      <defs>
        <filter
          id="filter0_d500"
          x="0"
          y="0"
          width="35"
          height="35"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
});
