import { Box } from '@mui/material';
import { CustomTooltip } from '../../../../../components/CustomTooltip';
import React from 'react';
import { useStyles } from './styles';
import { Trans, useTranslation } from 'react-i18next';

export function ExportAsTextInfoTooltip() {
  const classes = useStyles();
  const { t } = useTranslation('report');

  return (
    <CustomTooltip title={<Trans t={t} i18nKey="exportAsTextInfoTooltip" />}>
      <Box display="flex" alignItems="center" style={{ marginRight: 5 }}>
        <span className={classes.questionMarkWrapper}>?</span>
      </Box>
    </CustomTooltip>
  );
}
