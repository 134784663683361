import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export function MemberFormValuesSchema() {
  const { t } = useTranslation('installation');
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return Yup.object({
    installationMembers: Yup.array()
      .of(
        Yup.object({
          name: Yup.string().required(t('name.validation.required')),
          surname: Yup.string().required(t('surname.validation.required')),
          email: Yup.string().required().email(t('email.validation.email')).nullable(),
          birthday: Yup.date()
            .required(t('birthday.validation.required'))
            .max(
              tomorrow,
              t('birthday.validation.maxDate', { max: tomorrow.toLocaleDateString() }),
            ),
        }),
      )
      .min(0),
  });
}
