import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useAlarmRepetitionIntervalsInputStyles = makeStyles((theme: Theme) => ({
  daysWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
}));
