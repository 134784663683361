import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const COLUMN_GAP_MARGIN = 0.4;
export const useStyles = makeStyles((theme: Theme) => ({
  sensorsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&>*': {
      margin: theme.spacing(COLUMN_GAP_MARGIN, COLUMN_GAP_MARGIN),
    },
  },
  sensorWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));
