import { useTranslation } from 'react-i18next';
import { FONT_SIZES } from '../../../../Constants';
import {
  QuestionnaireResponseItem,
  QuestionnaireResponseItemBoolean,
  QuestionnaireResponseItemCoding,
  QuestionnaireResponseItemDouble,
  QuestionnaireResponseItemString,
} from '../../../../../models';
import {
  instanceOfQuestionnaireResponseItemBoolean,
  instanceOfQuestionnaireResponseItemCoding,
  instanceOfQuestionnaireResponseItemDouble,
  instanceOfQuestionnaireResponseItemString,
} from '../../../../../guards';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { ReactNode } from 'react';

interface Props {
  answer: QuestionnaireResponseItem;
}

const styles = StyleSheet.create({
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  answer: { fontSize: FONT_SIZES.h6, marginRight: 2 },
});

export function QuestionnaireQuestionAnswers({ answer }: Props) {
  const { t } = useTranslation('questionnaire');

  function wrapAnswerWithTextComponent(
    children: ReactNode | number | string,
    key?: number | string,
  ): ReactNode {
    return (
      <Text style={styles.answer} key={key}>
        {children}
      </Text>
    );
  }

  function getQuestionAnswer(questionAnswer: QuestionnaireResponseItem): ReactNode {
    const nonSelectionAnswer = getNonSelectionQuestionAnswerContent(questionAnswer);

    if (nonSelectionAnswer != null) {
      return wrapAnswerWithTextComponent(getNonSelectionQuestionAnswerContent(questionAnswer));
    }

    if (instanceOfQuestionnaireResponseItemCoding(questionAnswer)) {
      const patientQuestionAnswers = (questionAnswer as QuestionnaireResponseItemCoding)
        .valueCodingText;
      return (
        <View style={styles.flexColumn}>
          {patientQuestionAnswers.map((x, idx) => {
            return wrapAnswerWithTextComponent(`- ${x}`, idx);
          })}
        </View>
      );
    }
  }

  function getNonSelectionQuestionAnswerContent(questionAnswer: QuestionnaireResponseItem) {
    if (instanceOfQuestionnaireResponseItemString(questionAnswer)) {
      return (questionAnswer as QuestionnaireResponseItemString).valueString;
    } else if (instanceOfQuestionnaireResponseItemDouble(questionAnswer)) {
      return (questionAnswer as QuestionnaireResponseItemDouble).valueDouble;
    } else if (instanceOfQuestionnaireResponseItemBoolean(questionAnswer)) {
      return (questionAnswer as QuestionnaireResponseItemBoolean).valueBoolean
        ? t('question.answer.booleanTrue')
        : t('question.answer.booleanFalse');
    }

    return null;
  }

  return <>{getQuestionAnswer(answer)}</>;
}
