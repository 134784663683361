import { GridKeyValue, GridColDef } from '@mui/x-data-grid';
import {
  AlarmTypeEnum,
  AlarmTypeName,
  AllMeasurements,
  AnswerOptionCreateInput,
  Measurands,
  MeasurandTypes,
  MeasurandWithThresholds,
  Measurement,
  MeasurementSubtypeCoding,
  MeasurementValue,
  MeasurementValueWithUnit,
  MeasuringDeviceType,
  MeasuringDeviceTypeEnum,
  Optional,
  Questionnaire,
  QuestionnaireCreateInput,
  QuestionnaireQuestionCreateInput,
  WebMeasurement,
} from '../models';
import {
  GLUCOMETER_PATH,
  MEASUREMENT_TYPES_PATHS,
  MEASUREMENT_TYPES_SORT_MAP,
  PagePath,
} from '../constants';
import { differenceInYears } from 'date-fns';
import { MeasuringDeviceWithType as MeasuringDeviceModel } from '../models/MeasuringDevice';
import { ReactComponent as PressureIcon } from '../assets/icons/PressureIcon.svg';
import { ReactComponent as GlucometerIcon } from '../assets/icons/GlucometerIcon.svg';
import { ReactComponent as PulseIcon } from '../assets/icons/PulseIcon.svg';
import { ReactComponent as SensorIcon } from '../assets/icons/SensorIcon.svg';
import { ReactComponent as ThermometerIcon } from '../assets/icons/ThermometerIcon.svg';
import React from 'react';
import { Box } from '@mui/material';
import { TFunction } from 'i18next';
import {
  instanceOfMeasurementValueDouble,
  instanceOfMeasurementValueInt,
  instanceOfMeasurementValueString,
} from '../guards';
import * as Yup from 'yup';

export function compareGridCellValueWithNullOrUndefined(
  p1: GridKeyValue,
  p2: GridKeyValue,
): number {
  if (!p1 && !p2) {
    if (p1 === undefined && p2 === null) {
      return -1;
    } else if (p1 === null && p2 === undefined) {
      return 1;
    }
    return 0;
  } else if (!p1) {
    return -1;
  } else if (!p2) {
    return 1;
  }
  return 0;
}

export function arrayMax(arr: number[]) {
  return arr.reduce(function (p, v) {
    return p > v ? p : v;
  });
}

export function mapQuestionnaireToInputModel(
  questionnaire: Questionnaire,
): QuestionnaireCreateInput {
  return {
    title: questionnaire.title,
    description: questionnaire.description,
    scoringCategories: questionnaire.scoringCategories,
    items: questionnaire.items.map((qItem) => {
      return {
        index: qItem.index,
        text: qItem.text,
        questionTypeId: qItem.type.id,
        initialValue:
          qItem.initialValueString ??
          qItem.initialValueDouble?.toString() ??
          qItem.initialValueInt?.toString() ??
          qItem.initialValueBoolean?.toString(),
        questionnaireAnswerOptions: qItem.questionnaireAnswerOptions?.map((qAns) => {
          return {
            value: qAns.value,
            initialValue: qAns.initialValue,
            score: qAns.score,
          } as AnswerOptionCreateInput;
        }),
      } as QuestionnaireQuestionCreateInput;
    }),
  } as QuestionnaireCreateInput;
}

export const areUrlSearchParamsEqual = (
  t1: string | (string | null)[] | null,
  t2: string | (string | null)[] | null,
) => {
  if (t1 == null && t2 == null) {
    return true;
  } else if (t1 == null || t2 == null) {
    return false;
  }

  if (!Array.isArray(t1) && !Array.isArray(t2)) {
    return t1 === t2;
  } else if (!Array.isArray(t1) && Array.isArray(t2)) {
    return t2.length == 1 && t2.includes(t1);
  } else if (Array.isArray(t1) && !Array.isArray(t2)) {
    return t1.length == 1 && t1.includes(t2);
  } else if (Array.isArray(t1) && Array.isArray(t2)) {
    return t1.length === t2.length && t1.every((value, index) => value === t2[index]);
  }

  return false;
};

export function getDeviceIdFromMeasurements(
  measuringDeviceName: MeasuringDeviceType,
  measurements?: AllMeasurements,
) {
  if (!measurements || !measurements[measuringDeviceName]) {
    return null;
  }
  const measuringDeviceMeasurements = measurements[measuringDeviceName] ?? [];

  return measuringDeviceMeasurements.length > 0
    ? measuringDeviceMeasurements[0].measuringDeviceId
    : null;
}

export const kebabize = (val: string) => {
  return val
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .replace(/_{1,}/g, '-')
    .toLowerCase();
};

export const camelize = (s: string) => s.replace(/-./g, (x) => x.toUpperCase()[1]);

export function getMeasurementGridColumnsWidth(downSmMatches: boolean, oneColLength: number) {
  const measurementValueWidth: Pick<GridColDef, 'width' | 'flex'> = {};
  const dateTimeWidth: Pick<GridColDef, 'width' | 'flex'> = {};
  downSmMatches ? (measurementValueWidth.width = 150) : (measurementValueWidth.flex = oneColLength);
  downSmMatches ? (dateTimeWidth.width = 160) : (dateTimeWidth.flex = oneColLength);

  return [measurementValueWidth, dateTimeWidth];
}

export function getMeasurementDisplayValue(measurement: MeasurementValue, t: TFunction) {
  let displayValue = '-';

  if (instanceOfMeasurementValueInt(measurement)) {
    displayValue = measurement.valueInt?.toString();
  } else if (instanceOfMeasurementValueDouble(measurement) && measurement.valueDouble != null) {
    displayValue = getDoubleDisplayValue(measurement.valueDouble);
  } else if (instanceOfMeasurementValueString(measurement)) {
    displayValue = t(measurement.valueString);
  }

  if (measurement.unit) {
    displayValue += ' ' + measurement.unit;
  }

  return displayValue;
}

export function getDoubleDisplayValue(value: number | string) {
  // Double value can have only zero at decimal digits and it should not be displayed,
  // so if it is integer, do not transform it with Number.ToFixed method.
  let displayValue = !Number.isInteger(Number(value))
    ? Number(value).toFixed(1)
    : Number(value).toString();

  // Number.toFixed method can round value - for example 103.99 to 104.0
  // Only zero at decimal digits should not be displayed - so we need check it
  // again and remove decimal digits if there is zero only.
  if (Number.isInteger(Number(displayValue))) {
    displayValue = Number(displayValue).toString();
  }

  return displayValue;
}

export const snakeCaseToPascalCase = (str: string) => {
  return str
    .toLowerCase()
    .split('_')
    .map((x) => {
      return x
        .split('')
        .map((letter, idx) => {
          return idx === 0 ? letter.toUpperCase() : letter.toLowerCase();
        })
        .join('');
    })
    .join('');
};

export const kebabCaseToUpperSnakeCase = (str: string) => {
  return str
    .toLowerCase()
    .split('_')
    .map((x) => {
      return x
        .split('')
        .map((letter) => {
          return letter == '-' ? '_' : letter.toUpperCase();
        })
        .join('');
    })
    .join('');
};

export function firstLetterToLower(string: string): string {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export const transformMeasurandTypeSubTypeAndSubTypeCoding = (
  measurand: MeasurandWithThresholds,
  transformFn: (val: string) => string,
) => {
  let { type, subtype, subtypeCoding } = measurand;
  type = transformFn(type);
  subtype = subtype != null ? transformFn(subtype) : null;
  subtypeCoding =
    subtypeCoding != null ? (transformFn(subtypeCoding) as MeasurementSubtypeCoding) : null;

  return { type, subtype, subtypeCoding };
};

export const pascalCaseToCamelCase = (str: string) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export const getFirstMeasurementType = () => {
  const firstMeasurementType = Object.entries(MEASUREMENT_TYPES_SORT_MAP)
    // eslint-disable-next-line
    .sort(([_, valueA], [__, valueB]) => {
      return valueA - valueB;
    })
    // eslint-disable-next-line
    .map(([key, _]) => key)[0] as MeasuringDeviceType;

  return firstMeasurementType ? firstMeasurementType : MeasuringDeviceTypeEnum.GLUCOMETER;
};

export const getFirstMeasurementTypePath = () => {
  const firstMeasurementType = getFirstMeasurementType();
  return firstMeasurementType ? MEASUREMENT_TYPES_PATHS[firstMeasurementType] : GLUCOMETER_PATH;
};

export const getKeyByTypeSubtypeAndSubtypeCoding = (
  measurandThresholds: MeasurandWithThresholds,
) => {
  const { typeId, type, subtypeId, subtype, subtypeCodingId, subtypeCoding } = measurandThresholds;
  let result = `${typeId}-${type}`;

  if (subtypeId && subtype) {
    result += `_${subtypeId}-${subtype}`;
  }
  if (subtypeCodingId && subtypeCoding) {
    result += `_${subtypeCodingId}-${subtypeCoding}`;
  }

  return result;
};

export function isValidWebUrl(urlString: string) {
  let url;

  try {
    url = new URL(urlString);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

// eslint-disable-next-line
export const isNumber = (a: any) => {
  return !isNaN(+a);
};

// eslint-disable-next-line
export const areNumbers = (a: any, b: any) => {
  return isNumber(a) && isNumber(b);
};

// eslint-disable-next-line
export const areStrings = (a: any, b: any) => {
  return typeof a === 'string' && typeof b === 'string';
};

export function isPathSelected(pagePath: PagePath, pathname: string) {
  if (pagePath.primary === '/') {
    return pathname === '/';
  }

  return (
    pathname.startsWith(pagePath.primary) ||
    (!!pagePath.secondary && pathname.startsWith(pagePath.secondary + '/'))
  );
}

export function getAge(dateString: string | Date) {
  const today = new Date();
  return differenceInYears(today, new Date(dateString));
}

export function isSubstring(value: Optional<string>, subvalue: string) {
  if (value === null || typeof value === 'undefined') {
    return false;
  }

  return value.toLowerCase().includes(subvalue);
}

export function containsSubstringInStringArray(values: string[], subvalue: string) {
  if (values.length === 0) {
    return false;
  }

  return values.filter((v) => v.toLowerCase().includes(subvalue)).length > 0;
}

export function getUnitFromMeasuringDevicesByNameAndType(
  measuringDevices: MeasuringDeviceModel[],
  name: MeasuringDeviceType,
  type: keyof MeasurandTypes,
) {
  return measuringDevices
    ?.find((x) => x.name === name)
    ?.measurementTypes.find((x) => x.type === type)?.unit;
}

export function getWeightUnitFromMeasuringDevices(measuringDevices: MeasuringDeviceModel[]) {
  return getUnitFromMeasuringDevicesByNameAndType(
    measuringDevices,
    MeasuringDeviceTypeEnum.WEIGHT,
    'weight',
  );
}

export function getHeightUnitFromMeasuringDevices(measuringDevices: MeasuringDeviceModel[]) {
  return getUnitFromMeasuringDevicesByNameAndType(
    measuringDevices,
    MeasuringDeviceTypeEnum.HEIGHT,
    'height',
  );
}

export function isNullOrUndefined(value: unknown) {
  return value === null || typeof value === 'undefined';
}

export function isNullOrEmpty(value: string | undefined | null) {
  return value == null || value.trim() == '' || typeof value === 'undefined';
}

export function replaceDashWithSpace(value: string) {
  return value.replace('-', ' ');
}

export function TimePartComparator(a: Date, b: Date) {
  if (a.getHours() == b.getHours()) {
    if (a.getMinutes() == b.getMinutes()) {
      return a.getSeconds() - b.getSeconds();
    } else {
      return a.getMinutes() - b.getMinutes();
    }
  } else {
    return a.getHours() - b.getHours();
  }
}

type AlarmTypeIconColors = {
  [key in AlarmTypeName]: {
    active: string;
  };
};

const MEASURING_DEVICE_ICON_COLORS: MeasuringDeviceIconColors = {
  BloodPressureMonitor: {
    active: '#00B7FA',
  },
  Glucometer: {
    active: '#6E2F9B',
  },
  PulseOximeter: {
    active: '#F2166E',
  },
  Height: {
    active: '#6C7688',
  },
  Thermometer: {
    active: '#F79E05',
  },
  Weight: {
    active: '#6C7688',
  },
  MovementPain: {
    active: '#6C7688',
  },
  ProbeFluidIntake: {
    active: '#6C7688',
  },
  IntravenousFluidIntake: {
    active: '#6C7688',
  },
  UrineOutput: {
    active: '#6C7688',
  },
};

const ALARM_TYPE_ICON_COLORS: AlarmTypeIconColors = {
  BLOOD_PRESSURE_MONITOR: {
    active: MEASURING_DEVICE_ICON_COLORS.BloodPressureMonitor.active,
  },
  GLUCOMETER: {
    active: MEASURING_DEVICE_ICON_COLORS.Glucometer.active,
  },
  PULSE_OXIMETER: {
    active: MEASURING_DEVICE_ICON_COLORS.PulseOximeter.active,
  },
  REMINDER: {
    active: '#6C7688',
  },
  THERMOMETER: {
    active: MEASURING_DEVICE_ICON_COLORS.Thermometer.active,
  },
  WEIGHT: {
    active: MEASURING_DEVICE_ICON_COLORS.Weight.active,
  },
};

export function mapAlarmTypeNameToIcon(typeName: AlarmTypeName, isActive = false) {
  const icon = getIconByAlarmTypeName(typeName);
  const color = isActive ? ALARM_TYPE_ICON_COLORS[typeName].active : '#8794aa';

  return (
    <Box color={color} display="flex" alignItems="center" justifyContent="center">
      {icon}
    </Box>
  );
}

function getIconByAlarmTypeName(typeName: AlarmTypeName) {
  switch (typeName) {
    case AlarmTypeEnum.BLOOD_PRESSURE_MONITOR:
      return <PressureIcon />;
    case AlarmTypeEnum.GLUCOMETER:
      return <GlucometerIcon />;
    case AlarmTypeEnum.PULSE_OXIMETER:
      return <PulseIcon />;
    case AlarmTypeEnum.REMINDER:
      return <SensorIcon />;
    case AlarmTypeEnum.THERMOMETER:
      return <ThermometerIcon />;
    case AlarmTypeEnum.WEIGHT:
      return <SensorIcon />;
  }
}

type MeasuringDeviceIconColors = {
  [key in MeasuringDeviceType]: {
    active: string;
  };
};

export function mapMeasuringDeviceToIcon(deviceType: MeasuringDeviceType, isActive = false) {
  const icon = getIconByMeasuringDevice(deviceType);
  const color = isActive ? MEASURING_DEVICE_ICON_COLORS[deviceType].active : '#8794aa';

  return (
    <Box color={color} display="flex" alignItems="center" justifyContent="center">
      {icon}
    </Box>
  );
}

function getIconByMeasuringDevice(deviceType: MeasuringDeviceType) {
  switch (deviceType) {
    case MeasuringDeviceTypeEnum.BLOOD_PRESSURE_MONITOR:
      return <PressureIcon />;
    case MeasuringDeviceTypeEnum.GLUCOMETER:
      return <GlucometerIcon />;
    case MeasuringDeviceTypeEnum.PULSE_OXIMETER:
      return <PulseIcon />;
    case MeasuringDeviceTypeEnum.HEIGHT:
      return <SensorIcon />;
    case MeasuringDeviceTypeEnum.THERMOMETER:
      return <ThermometerIcon />;
    case MeasuringDeviceTypeEnum.WEIGHT:
      return <SensorIcon />;
  }
}

export function mapMeasurementsToWebMeasurements(measurements?: Measurement[]) {
  const webMeasurements: WebMeasurement[] = [];
  measurements?.forEach((measurement) => {
    const flattenMeasurement: WebMeasurement = {
      id: 0,
      measuredOnDatetime: new Date(),
      measuredByName: '--',
    };
    flattenMeasurement.id = measurement.id;
    flattenMeasurement.measuredOnDatetime = measurement.measuredOnDatetime;
    flattenMeasurement.measuredByName = measurement.createdByUser.name || '--';
    measurement.measurementValues.forEach((measurementValue) => {
      const measurementType = measurementValue.type as keyof Measurands;
      if (instanceOfMeasurementValueInt(measurementValue)) {
        flattenMeasurement[measurementType] = {
          value: measurementValue.valueInt,
        };
      } else if (instanceOfMeasurementValueDouble(measurementValue)) {
        flattenMeasurement[measurementType] = {
          value: measurementValue.valueDouble,
        };
      } else if (instanceOfMeasurementValueString(measurementValue)) {
        flattenMeasurement[measurementType] = {
          value: measurementValue.valueString,
        };
      }
      const measurement = flattenMeasurement[measurementType];
      if (measurement) {
        flattenMeasurement[measurementType] = {
          ...measurement,
          unit: measurementValue.unit,
          isMaxError: measurementValue.isMaxError,
          isMaxWarning: measurementValue.isMaxWarning,
          isMinWarning: measurementValue.isMinWarning,
          isMinError: measurementValue.isMinError,
        };
      }
    });

    webMeasurements.push(flattenMeasurement);
  });
  return webMeasurements;
}

export function trimString(val: string, maxChars = 500) {
  return val.length > maxChars ? val.substring(0, maxChars) + '...' : val;
}

export function translateAndFixWebMeasurement(measurements: WebMeasurement[], t: TFunction) {
  const translatedAndFixedData: WebMeasurement[] = measurements.map((x) => ({ ...x }));

  for (const measurement of translatedAndFixedData) {
    const keys = Object.keys(measurement) as Array<keyof WebMeasurement>;
    for (const key of keys) {
      if (key === 'id' || key === 'measuredOnDatetime' || key == 'measuredByName') {
        continue;
      }

      const measurementWithUnit = measurement[key] as MeasurementValueWithUnit;

      if (typeof measurementWithUnit.value === 'string') {
        // translate measurement value
        // eslint-disable-next-line
        measurementWithUnit.value = t(`${measurementWithUnit.value}` as any) as string;
      } else if (
        // all floats should have fixed 1 decimal number
        typeof measurementWithUnit.value === 'number' &&
        !Number.isInteger(measurementWithUnit.value)
      ) {
        measurementWithUnit.value = getDoubleDisplayValue(measurementWithUnit.value);
      }
    }
  }
  return translatedAndFixedData;
}

export function getSanitizedString(val: string, replacement = '-') {
  return val.replace(/[^a-z0-9]/gi, replacement);
}

export function getDisplayValueFromStringArray(
  array: (string | undefined | null)[],
  nonExistingValueString = '-',
  delimiter = ' ',
) {
  let result = array.filter((x) => !isNullOrUndefined(x)).join(delimiter);

  if (result == '') {
    result += nonExistingValueString;
  }

  return result;
}

export async function isValidEmail(email: string) {
  const emailSchema = Yup.object().shape({
    email: Yup.string().email('Neplatná emailová adresa').required('Email je povinný'),
  });

  try {
    await emailSchema.validate({ email });
    return true;
  } catch (error) {
    return false;
  }
}
