import React from 'react';

export function getInputErrorText(error: string | string[] | null | undefined) {
  if (!error) {
    return null;
  }

  if (Array.isArray(error)) {
    return error.map((e, i) => (
      <React.Fragment key={i}>
        {e}
        <br />
      </React.Fragment>
    ));
  }

  return error;
}
