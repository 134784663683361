import { Locale } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { ka } from 'date-fns/locale/ka';
import { mk } from 'date-fns/locale/mk';
import { sk } from 'date-fns/locale/sk';
import { sr } from 'date-fns/locale/sr';
import { sq } from 'date-fns/locale/sq';
import { ro } from 'date-fns/locale/ro';
import { cs } from 'date-fns/locale/cs';
import { Language } from './types';
import { getCurrentLanguage } from './i18n';

type Locales = {
  [key in Language]: Locale;
};

const datetimeLocales: Locales = {
  enUS,
  ka,
  mk,
  sk,
  sr,
  sq,
  ro,
  cs,
};

export function getCurrentDatetimeLocale() {
  const lang = getCurrentLanguage();
  return datetimeLocales[lang];
}
