import { Typography } from '@mui/material';
import React from 'react';
import { ResetPasswordButton } from '../ResetPasswordButton';
import { useTranslation } from 'react-i18next';
import { useResetOnetimePasswordButtonStyles } from './styles';
import { Variant } from '../Button';
import { ClassNameMap } from '@mui/styles/withStyles/withStyles';

export type ResetOnetimePasswordHandler = (medicId: number) => Promise<string | null>;
export type ResetOnetimePasswordCompletedHandler = () => Promise<void>;

interface Props {
  medicId: number;
  onResetOnetimePassword: ResetOnetimePasswordHandler;
  onResetOnetimePasswordCompleted?: ResetOnetimePasswordCompletedHandler;
  isOpen?: boolean;
  showStartIcon?: boolean;
  buttonText?: string;
  fullWidth?: boolean;
  variant?: Variant;
  classes?: Partial<ClassNameMap>;
}

export function ResetOnetimePasswordButton({
  medicId,
  onResetOnetimePassword,
  onResetOnetimePasswordCompleted,
  isOpen = false,
  showStartIcon,
  buttonText,
  fullWidth,
  variant,
  classes,
}: Props) {
  const componentClasses = useResetOnetimePasswordButtonStyles();
  const { t } = useTranslation('medic');

  function handleResetOnetimePassword() {
    return onResetOnetimePassword(medicId);
  }

  return (
    <ResetPasswordButton
      buttonText={buttonText ?? t('onetimePassword.resetButton')}
      onResetPassword={handleResetOnetimePassword}
      onResetPasswordCompleted={onResetOnetimePasswordCompleted}
      dialogTitle={t('onetimePassword.label')}
      color="secondary"
      dialogContent={(password) => (
        <>
          <Typography variant="h4" className={componentClasses.onetimePassword}>
            {password}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {t('onetimePassword.passwordVisibilityInfo')}
          </Typography>
        </>
      )}
      isOpen={isOpen}
      showStartIcon={showStartIcon}
      fullWidth={fullWidth}
      variant={variant}
      classes={classes}
    />
  );
}
