import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from '../ProtectedRoute';
import { EDIT_SUBPATH } from '../../constants';
import { PATIENTS_READ_POLICY } from '../../constants/Policies';
import { PatientPage } from '../../pages/patients/PatientPage';

export function PatientRoutes() {
  return (
    <Routes>
      <Route
        path="/*"
        element={
          <ProtectedRoute requireFunctionality={PATIENTS_READ_POLICY}>
            <PatientPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${EDIT_SUBPATH}`}
        element={
          <ProtectedRoute requireFunctionality={PATIENTS_READ_POLICY}>
            <PatientPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
