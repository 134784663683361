import { Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import React, { Ref } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  to: string;
  children: React.ReactNode;
}

export function LinkButton({ to, children, ...restProps }: Props & ButtonProps) {
  const renderLink = React.useMemo(
    () =>
      React.forwardRef((props, ref) => <RouterLink to={to} ref={ref as Ref<never>} {...props} />),
    [],
  );

  return (
    <Button component={renderLink} {...restProps}>
      {children}
    </Button>
  );
}
