import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  questionMarkWrapper: {
    border: `1px solid ${theme.palette.grey[400]}`,
    color: theme.palette.grey[400],
    width: 20,
    height: 20,
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: 1.2,
    fontWeight: 600,
    userSelect: 'none',
  },
}));
