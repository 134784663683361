import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Patient } from '../../../models';
import { UnpairButton } from './UnpairButton';
import { useUnpairDialogStyles } from './styles';

export interface UnpairPatientButtonProps {
  patient: Patient;
  onUnpair: (conclusion: string) => Promise<void>;
  onUnpairCompleted?: () => Promise<void>;
}

export function UnpairPatientButton({
  patient,
  onUnpair,
  onUnpairCompleted,
}: UnpairPatientButtonProps) {
  const [conclusion, setConclussion] = useState('');
  const classes = useUnpairDialogStyles();
  const { t } = useTranslation(['common', 'patient']);
  const [isUnpairing, setIsUnpairing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  function handleClick() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }

  async function handleUnpair() {
    setIsUnpairing(true);
    await onUnpair(conclusion);
    setIsUnpairing(false);
    setIsOpen(false);
    if (onUnpairCompleted) {
      await onUnpairCompleted();
    }
  }

  return (
    <React.Fragment>
      <UnpairButton onClick={handleClick}>{t('patient:unpair')}</UnpairButton>
      {isOpen && (
        <Dialog open={isOpen} onClose={handleClose}>
          <DialogTitle>
            {t('patient:unpairConfirmation.title', { context: patient.sex })}
          </DialogTitle>
          <DialogContent>
            <TextField
              label={t('patient:unpairConfirmation.conclusion')}
              required={false}
              className={classes.input}
              margin="normal"
              variant="outlined"
              fullWidth={true}
              multiline={true}
              minRows={15}
              maxRows={20}
              onChange={(e) => setConclussion(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              disabled={isUnpairing}
              variant="outlined"
              className={classes.cancelButton}
            >
              {t('common:cancel')}
            </Button>
            <UnpairButton onClick={handleUnpair} disabled={isUnpairing}>
              {isUnpairing ? <CircularProgress size={24} color="secondary" /> : t('patient:unpair')}
            </UnpairButton>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
}
