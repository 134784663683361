import { StyleSheet, Text } from '@react-pdf/renderer';
import React from 'react';
import { FONT_SIZES, FONT_WEIGHTS } from '../../../../Constants';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  noData: {
    fontSize: FONT_SIZES.h5,
    fontWeight: FONT_WEIGHTS.light,
  },
});

export function NoDataText() {
  const { t } = useTranslation('common');
  return <Text style={styles.noData}>{t('noData')}</Text>;
}
