import React, { useCallback, useEffect } from 'react';
import { Box, Divider } from '@mui/material';
import { FieldArray, FieldArrayRenderProps, useField, useFormikContext } from 'formik';
import {
  QuestionnaireCreateInput,
  QuestionnaireQuestionCreateInput,
  QuestionType,
} from '../../../models';
import { FormAddButton } from '../../AddButton';
import { arrayMax } from '../../../utils';
import { useStyles } from './styles';
import { QuestionListItem } from '../QuestionListItem';
import { useTranslation } from 'react-i18next';
import { useSelectedQuestionIndex } from '../../../context/selectedQuestionIndex';

interface Props {
  id: string;
  questionTypes: QuestionType[];
}

export function QuestionsInput({ id, questionTypes }: Props) {
  const { t } = useTranslation('questionnaire');
  const [{ value }] = useField<QuestionnaireQuestionCreateInput[]>(id);

  const { selectedIndex, setSelectedIndex, setIsQuestionMoving } = useSelectedQuestionIndex();
  const classes = useStyles();
  const { setFieldTouched, errors } = useFormikContext<QuestionnaireCreateInput>();

  const onMoveCard = useCallback(
    (dragIndex: number, hoverIndex: number, arrayHelpers: FieldArrayRenderProps) => {
      setIsQuestionMoving(true);
      // drop selected question on unselected one - mark as selected question where dropped
      if (dragIndex == selectedIndex) {
        setSelectedIndex(hoverIndex);
      }

      // drop unselected question on selected one:
      //    - when moving downwards - select index of question before selected one (it is new selected question position)
      //    - when moving upwards - select index of question after selected one (it is new selected question position)
      if (hoverIndex == selectedIndex) {
        if (dragIndex < hoverIndex) {
          setSelectedIndex(selectedIndex - 1);
        } else if (dragIndex > hoverIndex) {
          setSelectedIndex(selectedIndex + 1);
        }
      }

      // drop unselected one on unselected one:
      //    - when moving questions after selected one - do nothing (position of selected question wont change)
      //    - when moving questions before selected one - do nothing (position of selected question wont change)
      //    - when moving questions across selected one:
      //        - when moving downwards - select index of question before selected one (it is new selected question position)
      //        - when moving upwards - select index of question after selected one (it is new selected question position)
      const [minDndIdx, maxDndIdx] = [dragIndex, hoverIndex].sort();
      if (minDndIdx < selectedIndex && maxDndIdx > selectedIndex) {
        if (dragIndex < hoverIndex) {
          setSelectedIndex(selectedIndex - 1);
        } else if (dragIndex > hoverIndex) {
          setSelectedIndex(selectedIndex + 1);
        }
      }

      arrayHelpers.move(dragIndex, hoverIndex);

      setFieldTouched(`${value[dragIndex]}`);
      setIsQuestionMoving(false);
    },
    [value, selectedIndex],
  );

  useEffect(() => {
    if (value == null || value.length === 0) {
      // set selected index to -1 when no questions are present
      setSelectedIndex(-1);
    }
  }, [value]);

  const emptyValue: QuestionnaireQuestionCreateInput = {
    index:
      value.length > 0
        ? arrayMax(value.map((q: QuestionnaireQuestionCreateInput) => q.index)) + 1
        : 0,
    text: '',
    initialValue: '',
    questionTypeId: questionTypes[0].id ?? -1,
    questionnaireAnswerOptions: [],
  };

  function renderInputs(arrayHelpers: FieldArrayRenderProps) {
    if (!value || !Array.isArray(value) || value.length === 0) {
      return null;
    }

    return (
      <Box>
        <Box mt={1}>
          {value.map((question, index) => {
            question.index = index;
            const isSelected = selectedIndex == index;
            const questionType = questionTypes.find((type) => type.id == question.questionTypeId);
            const hasErrorState = !!(errors.items && errors.items[index] != null);
            return (
              <QuestionListItem
                key={index}
                index={index}
                isSelected={isSelected}
                question={question}
                questionType={questionType}
                arrayHelpers={arrayHelpers}
                onMoveCard={onMoveCard}
                hasErrorState={hasErrorState}
              />
            );
          })}
        </Box>
      </Box>
    );
  }

  function renderArray(arrayHelpers: FieldArrayRenderProps) {
    return (
      <React.Fragment>
        <Box display="inline" className={classes.questionContentsTitle}>
          {t('contents')} ({value?.length ?? 0})
        </Box>
        {renderInputs(arrayHelpers)}
        <Box py={2} px={0.5}>
          <Divider />
        </Box>
        <Box display="flex" justifyContent="space-between" data-testid="question-add-button">
          <FormAddButton
            onAddClick={() => {
              arrayHelpers.push(emptyValue);
              setSelectedIndex(value.length);
            }}
            id="addQuestionBtn"
          />
        </Box>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <FieldArray name={id} render={renderArray} />
    </React.Fragment>
  );
}
