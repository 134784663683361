import { EDIT_SUBPATH, THRESHOLDS_SUBPATH } from './Constants';
import {
  BLOOD_PRESSURE_MONITOR_DIASTOLIC_SUBPATH,
  BLOOD_PRESSURE_MONITOR_PATH,
  BLOOD_PRESSURE_MONITOR_PULSE_SUBPATH,
  BLOOD_PRESSURE_MONITOR_SYSTOLIC_SUBPATH,
  GLUCOMETER_FOOD_TYPE_AFTER_FOOD_SUBPATH,
  GLUCOMETER_FOOD_TYPE_BEFORE_FOOD_SUBPATH,
  GLUCOMETER_FOOD_TYPE_SUBPATH,
  GLUCOMETER_GLUCOSE_SUBPATH,
  GLUCOMETER_PATH,
  HEIGHT_HEIGHT_SUBPATH,
  HEIGHT_PATH,
  PROFILE_PATH,
  PULSE_OXIMETER_OXYGEN_SATURATION_SUBPATH,
  PULSE_OXIMETER_PATH,
  PULSE_OXIMETER_PULSE_SUBPATH,
  SYSTEM_THRESHOLDS_PATH,
  THERMOMETER_PATH,
  THERMOMETER_TEMPERATURE_SUBPATH,
  THERMOMETER_TYPE_SUBPATH,
  WEIGHT_PATH,
  WEIGHT_WEIGHT_SUBPATH,
} from './NavigationConstants';

export type RouteName =
  | 'LOGIN'
  | 'RESET_PASSWORD'
  | 'FORGOTTEN_PASSWORD'
  | 'DASHBOARD'
  | 'CHANGE_LOG'
  | 'ERROR'
  | 'LOGOUT'
  | 'PATIENTS'
  | 'PATIENT_CREATE'
  | 'PATIENT_DETAIL'
  | 'MEDICS'
  | 'MEDIC_DETAIL'
  | 'MEDIC_CREATE'
  | 'MEDIC_PATIENTS'
  | 'GROUPS'
  | 'GROUP_DETAIL'
  | 'GROUP_CREATE'
  | 'ORGANIZATIONS'
  | 'ORGANIZATION_DETAIL'
  | 'ORGANIZATION_CREATE'
  | 'ORGANIZATION_USERS'
  | 'ORGANIZATION_DEVICES'
  | 'DEPARTMENTS'
  | 'DEPARTMENT_DETAIL'
  | 'DEPARTMENT_CREATE'
  | 'DEVICES'
  | 'DEVICE_DETAIL'
  | 'DEVICE_CREATE'
  | 'QUESTIONNAIRE_TEMPLATES'
  | 'QUESTIONNAIRE_TEMPLATE_DETAIL'
  | 'QUESTIONNAIRE_TEMPLATE_CREATE'
  | 'QUESTIONNAIRE_TEMPLATE_EDIT'
  | 'DAILY_PLAN_TEMPLATES'
  | 'DAILY_PLAN_TEMPLATE_CREATE'
  | 'DAILY_PLAN_TEMPLATE_DETAIL'
  | 'DAILY_PLAN_TEMPLATE_ALARM_DETAIL'
  | 'ADMIN_DASHBOARD'
  | 'PROFILE'
  | 'SYSTEM_THRESHOLDS'
  | 'PATIENT_MEASUREMENTS'
  | 'NOTES'
  | 'NOTE_DETAIL'
  | 'PATIENT_ALARMS'
  | 'PATIENT_ALARM_DETAIL'
  | 'PATIENT_QUESTIONNAIRES'
  | 'PATIENT_QUESTIONNAIRE_RESPONSE'
  | 'PATIENT_PAIRINGS'
  | 'PATIENT_PAIRING_DETAIL'
  | 'PATIENT_EXPORT'
  | 'PATIENT_MEASUREMENTS_PULSE_OXIMETER'
  | 'PATIENT_MEASUREMENTS_THERMOMETER'
  | 'PATIENT_MEASUREMENTS_BLOOD_PRESSURE'
  | 'PATIENT_MEASUREMENTS_GLUCOMETER'
  | 'PATIENT_MEASUREMENTS_WEIGHT'
  | 'PATIENT_MEASUREMENTS_HEIGHT'
  | 'INSTALLATION';

export type ThresholdRouteName =
  | 'PATIENT_MEASUREMENTS_PULSE_OXIMETER_THRESHOLDS'
  | 'PATIENT_MEASUREMENTS_THERMOMETER_THRESHOLDS'
  | 'PATIENT_MEASUREMENTS_BLOOD_PRESSURE_THRESHOLDS'
  | 'PATIENT_MEASUREMENTS_GLUCOMETER_THRESHOLDS'
  | 'PATIENT_MEASUREMENTS_WEIGHT_THRESHOLDS'
  | 'PATIENT_MEASUREMENTS_HEIGHT_THRESHOLDS'
  | 'SYSTEM_THRESHOLDS_PULSE_OXIMETER'
  | 'SYSTEM_THRESHOLDS_THERMOMETER'
  | 'SYSTEM_THRESHOLDS_BLOOD_PRESSURE'
  | 'SYSTEM_THRESHOLDS_GLUCOMETER'
  | 'SYSTEM_THRESHOLDS_WEIGHT'
  | 'SYSTEM_THRESHOLDS_HEIGHT';

export interface RouteWithName {
  path: string | readonly string[] | undefined;
  name: string;
}

export type CustomRoute = {
  [key in RouteName]: RouteWithName;
};

export type ThresholdCustomRoute = {
  [key in ThresholdRouteName]: RouteWithName;
};

export const ROUTES: CustomRoute = {
  LOGIN: {
    path: '/login',
    name: 'Login',
  },
  RESET_PASSWORD: {
    path: '/reset-password/:token',
    name: 'Reset password',
  },
  FORGOTTEN_PASSWORD: {
    path: '/forgotten-password',
    name: 'Forgotten password',
  },
  DASHBOARD: {
    path: '/*',
    name: 'Dashboard',
  },
  CHANGE_LOG: {
    path: '/changelogs',
    name: 'Dashboard',
  },
  ERROR: {
    path: '/error',
    name: 'Error',
  },
  LOGOUT: {
    path: '/logout',
    name: 'Log out',
  },
  PATIENTS: {
    path: '/patients',
    name: 'Patients list',
  },
  PATIENT_CREATE: {
    path: '/patients/new',
    name: 'Patient create',
  },
  PATIENT_DETAIL: {
    path: `/patients/:id/*`,
    name: 'Patient detail',
  },
  MEDICS: {
    path: '/medics',
    name: 'Medics list',
  },
  MEDIC_DETAIL: {
    path: `/medics/:id/*`,
    name: 'Medic detail',
  },
  MEDIC_CREATE: {
    path: '/medics/new',
    name: 'Medic create',
  },
  MEDIC_PATIENTS: {
    path: '/medics/:medicId/patients',
    name: 'Medic patients list',
  },
  ORGANIZATIONS: {
    path: '/organizations',
    name: 'Organizations list',
  },
  ORGANIZATION_DETAIL: {
    path: `/organizations/:id/*`,
    name: 'Organization detail',
  },
  ORGANIZATION_CREATE: {
    path: '/organizations/new',
    name: 'Organization create',
  },
  GROUPS: {
    path: '/groups',
    name: 'Groups list',
  },
  GROUP_DETAIL: {
    path: `/groups/:id/*`,
    name: 'Group detail',
  },
  GROUP_CREATE: {
    path: '/groups/new',
    name: 'Group create',
  },
  ORGANIZATION_USERS: {
    path: '/organizations/:id/users',
    name: 'Organization users list',
  },
  ORGANIZATION_DEVICES: {
    path: '/organizations/:id/devices',
    name: 'Organization devices list',
  },
  DEPARTMENTS: {
    path: '/departments',
    name: 'Deparments list',
  },
  DEPARTMENT_DETAIL: {
    path: `/departments/:id/*`,
    name: 'Organization detail',
  },
  DEPARTMENT_CREATE: {
    path: '/departments/new',
    name: 'Deparments create',
  },
  DEVICES: {
    path: '/devices',
    name: 'Device list',
  },
  DEVICE_DETAIL: {
    path: `/devices/:id/*`,
    name: 'Device detail',
  },
  DEVICE_CREATE: {
    path: '/devices/new',
    name: 'Device create',
  },
  QUESTIONNAIRE_TEMPLATES: {
    path: '/questionnaires',
    name: 'Questionnaire templates list',
  },
  QUESTIONNAIRE_TEMPLATE_DETAIL: {
    path: `/questionnaires/:questionnaireId/*`,
    name: 'Questionnaire template detail',
  },
  QUESTIONNAIRE_TEMPLATE_EDIT: {
    path: `/questionnaires/:questionnaireId/${EDIT_SUBPATH}`,
    name: 'Questionnaire template edit',
  },
  QUESTIONNAIRE_TEMPLATE_CREATE: {
    path: '/questionnaires/new',
    name: 'Questionnaire template create',
  },
  DAILY_PLAN_TEMPLATES: {
    path: '/daily-plan-templates',
    name: 'Daily plan templates list',
  },
  DAILY_PLAN_TEMPLATE_CREATE: {
    path: '/daily-plan-templates/new',
    name: 'Daily plan template create',
  },
  DAILY_PLAN_TEMPLATE_DETAIL: {
    path: `/daily-plan-templates/:id/*`,
    name: 'Daily plan template detail',
  },
  DAILY_PLAN_TEMPLATE_ALARM_DETAIL: {
    path: `/daily-plan-templates/:dailyPlanTemplateId/alarms/:id/${EDIT_SUBPATH}`,
    name: 'Daily plan template alarm detail',
  },
  ADMIN_DASHBOARD: {
    path: '/admin-dashboard/*',
    name: 'Admin dashboard',
  },
  PROFILE: {
    path: `${PROFILE_PATH}/(${EDIT_SUBPATH})?`,
    name: 'Profile',
  },
  SYSTEM_THRESHOLDS: {
    path: SYSTEM_THRESHOLDS_PATH,
    name: 'System thresholds',
  },
  PATIENT_MEASUREMENTS: {
    path: '/patients/:patientId/measurements',
    name: 'Patient measurements',
  },
  NOTES: {
    path: '/patients/:patientId/notes',
    name: 'Patient notes',
  },
  NOTE_DETAIL: {
    path: `/patients/:patientId/notes/:noteId/(${EDIT_SUBPATH})?`,
    name: 'Patient note detail',
  },
  PATIENT_ALARMS: {
    path: '/patients/:patientId/alarms',
    name: 'Patient alarms list',
  },
  PATIENT_ALARM_DETAIL: {
    path: `/patients/:patientId/alarms/:id/(${EDIT_SUBPATH})?`,
    name: 'Patient alarm detail',
  },
  PATIENT_QUESTIONNAIRES: {
    path: '/patients/:patientId/questionnaires',
    name: 'Patient questionnaires list',
  },
  PATIENT_QUESTIONNAIRE_RESPONSE: {
    path: '/patients/:patientId/questionnaires/:questionnaireId',
    name: 'Patient questionnaire response',
  },
  PATIENT_EXPORT: {
    path: `/patients/:id/export`,
    name: 'Patient export',
  },
  PATIENT_PAIRINGS: {
    path: `/patients/:patientId/pairings`,
    name: 'Patient pairings',
  },
  PATIENT_PAIRING_DETAIL: {
    path: `/patients/:patientId/pairings/:pairingId`,
    name: 'Patient pairing detail',
  },
  PATIENT_MEASUREMENTS_PULSE_OXIMETER: {
    path: `/patients/:patientId/measurements/:measuringDevice(${PULSE_OXIMETER_PATH})`,
    name: 'Patient measurements - pulse oximeter',
  },
  PATIENT_MEASUREMENTS_THERMOMETER: {
    path: `/patients/:patientId/measurements/:measuringDevice(${THERMOMETER_PATH})`,
    name: 'Patient measurements - thermometer',
  },
  PATIENT_MEASUREMENTS_BLOOD_PRESSURE: {
    path: `/patients/:patientId/measurements/:measuringDevice(${BLOOD_PRESSURE_MONITOR_PATH})`,
    name: 'Patient measurements - blood pressure',
  },
  PATIENT_MEASUREMENTS_GLUCOMETER: {
    path: `/patients/:patientId/measurements/:measuringDevice(${GLUCOMETER_PATH})`,
    name: 'Patient measurements - glucometer',
  },
  PATIENT_MEASUREMENTS_WEIGHT: {
    path: `/patients/:patientId/measurements/:measuringDevice(${WEIGHT_PATH})`,
    name: 'Patient measurements - weight',
  },
  PATIENT_MEASUREMENTS_HEIGHT: {
    path: `/patients/:patientId/measurements/:measuringDevice(${HEIGHT_PATH})`,
    name: 'Patient measurements - height',
  },
  INSTALLATION: {
    path: '/installation/:token/:chassisId/:encodedEmail',
    name: 'Installation',
  },
};

export const THRESHOLD_ROUTES: ThresholdCustomRoute = {
  PATIENT_MEASUREMENTS_PULSE_OXIMETER_THRESHOLDS: {
    path: `/patients/:patientId(\\d+)/measurements/:measuringDevice(${PULSE_OXIMETER_PATH})/${THRESHOLDS_SUBPATH}/:measurementType(${PULSE_OXIMETER_PULSE_SUBPATH}|${PULSE_OXIMETER_OXYGEN_SATURATION_SUBPATH})/(${EDIT_SUBPATH})?`,
    name: 'Patient measurements thresholds - pulse oximeter',
  },
  PATIENT_MEASUREMENTS_THERMOMETER_THRESHOLDS: {
    path: `/patients/:patientId(\\d+)/measurements/:measuringDevice(${THERMOMETER_PATH})/${THRESHOLDS_SUBPATH}/:measurementType(${THERMOMETER_TEMPERATURE_SUBPATH}|${THERMOMETER_TYPE_SUBPATH})/(${EDIT_SUBPATH})?`,
    name: 'Patient measurements thresholds - thermometer',
  },
  PATIENT_MEASUREMENTS_BLOOD_PRESSURE_THRESHOLDS: {
    path: `/patients/:patientId(\\d+)/measurements/:measuringDevice(${BLOOD_PRESSURE_MONITOR_PATH})/${THRESHOLDS_SUBPATH}/:measurementType(${BLOOD_PRESSURE_MONITOR_SYSTOLIC_SUBPATH}|${BLOOD_PRESSURE_MONITOR_DIASTOLIC_SUBPATH}|${BLOOD_PRESSURE_MONITOR_PULSE_SUBPATH})/(${EDIT_SUBPATH})?`,
    name: 'Patient measurements thresholds - blood pressure',
  },
  PATIENT_MEASUREMENTS_GLUCOMETER_THRESHOLDS: {
    path: [
      `/patients/:patientId(\\d+)/measurements/:measuringDevice(${GLUCOMETER_PATH})/${THRESHOLDS_SUBPATH}/:measurementType(${GLUCOMETER_GLUCOSE_SUBPATH}|${GLUCOMETER_FOOD_TYPE_SUBPATH})/(${EDIT_SUBPATH})?`,
      `/patients/:patientId(\\d+)/measurements/:measuringDevice(${GLUCOMETER_PATH})/${THRESHOLDS_SUBPATH}/:measurementType(${GLUCOMETER_GLUCOSE_SUBPATH})/:measurementSubtype(${GLUCOMETER_FOOD_TYPE_SUBPATH})/:measurementSubtypeCoding(${GLUCOMETER_FOOD_TYPE_BEFORE_FOOD_SUBPATH}|${GLUCOMETER_FOOD_TYPE_AFTER_FOOD_SUBPATH})/(${EDIT_SUBPATH})?`,
    ],
    name: 'Patient measurements thresholds - glucometer',
  },
  PATIENT_MEASUREMENTS_WEIGHT_THRESHOLDS: {
    path: `/patients/:patientId(\\d+)/measurements/:measuringDevice(${WEIGHT_PATH})/${THRESHOLDS_SUBPATH}/:measurementType(${WEIGHT_WEIGHT_SUBPATH})/(${EDIT_SUBPATH})?`,
    name: 'Patient measurements thresholds - weight',
  },
  PATIENT_MEASUREMENTS_HEIGHT_THRESHOLDS: {
    path: `/patients/:patientId(\\d+)/measurements/:measuringDevice(${HEIGHT_PATH})/${THRESHOLDS_SUBPATH}/:measurementType(${HEIGHT_HEIGHT_SUBPATH})/(${EDIT_SUBPATH})?`,
    name: 'Patient measurements thresholds - height',
  },
  SYSTEM_THRESHOLDS_PULSE_OXIMETER: {
    path: `${SYSTEM_THRESHOLDS_PATH}/:measuringDevice(${PULSE_OXIMETER_PATH})/:measurementType(${PULSE_OXIMETER_PULSE_SUBPATH}|${PULSE_OXIMETER_OXYGEN_SATURATION_SUBPATH})?/(${EDIT_SUBPATH})?`,
    name: 'System thresholds - pulse oximeter',
  },
  SYSTEM_THRESHOLDS_THERMOMETER: {
    path: `${SYSTEM_THRESHOLDS_PATH}/:measuringDevice(${THERMOMETER_PATH})/:measurementType(${THERMOMETER_TEMPERATURE_SUBPATH}|${THERMOMETER_TYPE_SUBPATH})?/(${EDIT_SUBPATH})?`,
    name: 'System thresholds - thermometer',
  },
  SYSTEM_THRESHOLDS_BLOOD_PRESSURE: {
    path: `${SYSTEM_THRESHOLDS_PATH}/:measuringDevice(${BLOOD_PRESSURE_MONITOR_PATH})/:measurementType(${BLOOD_PRESSURE_MONITOR_SYSTOLIC_SUBPATH}|${BLOOD_PRESSURE_MONITOR_DIASTOLIC_SUBPATH}|${BLOOD_PRESSURE_MONITOR_PULSE_SUBPATH})?/(${EDIT_SUBPATH})?`,
    name: 'System thresholds - blood pressure',
  },
  SYSTEM_THRESHOLDS_GLUCOMETER: {
    path: [
      `${SYSTEM_THRESHOLDS_PATH}/:measuringDevice(${GLUCOMETER_PATH})/:measurementType(${GLUCOMETER_GLUCOSE_SUBPATH}|${GLUCOMETER_FOOD_TYPE_SUBPATH})?/(${EDIT_SUBPATH})?`,
      `${SYSTEM_THRESHOLDS_PATH}/:measuringDevice(${GLUCOMETER_PATH})/:measurementType(${GLUCOMETER_GLUCOSE_SUBPATH})?/:measurementSubtype(${GLUCOMETER_FOOD_TYPE_SUBPATH})?/:measurementSubtypeCoding(${GLUCOMETER_FOOD_TYPE_BEFORE_FOOD_SUBPATH}|${GLUCOMETER_FOOD_TYPE_AFTER_FOOD_SUBPATH})?/(${EDIT_SUBPATH})?`,
    ],
    name: 'System thresholds - glucometer',
  },
  SYSTEM_THRESHOLDS_WEIGHT: {
    path: `${SYSTEM_THRESHOLDS_PATH}/:measuringDevice(${WEIGHT_PATH})/:measurementType(${WEIGHT_WEIGHT_SUBPATH})?`,
    name: 'System thresholds - weight',
  },
  SYSTEM_THRESHOLDS_HEIGHT: {
    path: `${SYSTEM_THRESHOLDS_PATH}/:measuringDevice(${HEIGHT_PATH})/:measurementType(${HEIGHT_HEIGHT_SUBPATH})?`,
    name: 'System thresholds - height',
  },
};
