import { ProgressIndicator } from '../../../components/ProgressIndicator';
import { PatientNoteFormValues } from '../../notes/PatientNoteForm';
import { Note, NoteEditInput } from '../../../models';
import { NotesGrid } from '../../../components/Grids';
import { ApiRoutes, makeApiRequest, useGetApi } from '../../../services/api';
import { ShowHideForm } from './ShowHideForm';
import { useEntityActionSnackbar } from '../../../hooks';
import { useFunctionalityMode } from '../../../context/functionality';
import { useTranslation } from 'react-i18next';

interface Props {
  patientId: string;
}

export function PatientNotes({ patientId }: Props) {
  const { enqueueEntityCreatedSnackbar, enqueueEntityDeletedSnackbar } = useEntityActionSnackbar();
  const { t } = useTranslation('note');
  const { hasCreateFunctionality } = useFunctionalityMode();

  const [{ isLoading: isPatientNotesLoading, data: patientNotes }, { refetch: refetchNotes }] =
    useGetApi<Note[]>(ApiRoutes.PatientNotes(patientId));

  function handleCreateNote(values: PatientNoteFormValues) {
    return makeApiRequest<never, NoteEditInput>('POST', ApiRoutes.PatientNotes(patientId), values);
  }

  async function handleCreateNoteCompleted() {
    await refetchNotes();
    enqueueEntityCreatedSnackbar(t('note'), { context: 'female' });
  }

  async function handleDelete(id: number) {
    await makeApiRequest('DELETE', ApiRoutes.PatientNote(patientId, id));
  }

  async function handleDeleteCompleted() {
    await refetchNotes();

    enqueueEntityDeletedSnackbar(t('note'), { context: 'female' });
  }

  if (isPatientNotesLoading) {
    return <ProgressIndicator />;
  }

  return (
    <div>
      {hasCreateFunctionality && (
        <ShowHideForm onSubmit={handleCreateNote} onSubmitCompleted={handleCreateNoteCompleted} />
      )}
      {!!patientNotes && (
        <NotesGrid
          data={patientNotes}
          patientId={patientId}
          onDelete={handleDelete}
          onDeleteCompleted={handleDeleteCompleted}
        />
      )}
    </div>
  );
}
