import { GridCellParams, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Note } from '../../../models';
import { DeleteHandler, Grid, GridColumnDefinition } from '../../Grid';
import { DeleteItemCompletedHandler } from '../../DeleteButton';
import { useNavigate } from 'react-router';
import { useMediaQuery, useTheme } from '@mui/material';
import { EDIT_SUBPATH } from '../../../constants';

interface Props {
  data: Note[];
  patientId: string;
  onDelete: DeleteHandler;
  onDeleteCompleted: DeleteItemCompletedHandler;
}

export function NotesGrid({ data, patientId, onDelete, onDeleteCompleted }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation('note');
  const theme = useTheme();
  const xsDownMatches = useMediaQuery(theme.breakpoints.down('sm'));
  const smDownMatches = useMediaQuery(theme.breakpoints.down('md'));

  function getCreatedAtFormattedValue<T extends GridRenderCellParams>(params: T) {
    return new Date(params.value as string).toLocaleString();
  }

  function getEditPath(id: number) {
    return `/patients/${patientId}/notes/${id}/${EDIT_SUBPATH}`;
  }

  function onRowClick(row: Note) {
    navigate(`/patients/${patientId}/notes/${row.id}`);
  }

  const textGridColumn: GridColumnDefinition<Note> = {
    field: 'text',
    headerName: t('text.label'),
    ...(smDownMatches ? { width: 200 } : { flex: 1 }),
  };

  const columns: GridColumnDefinition<Note>[] = [
    {
      field: 'author',
      headerName: t('author.label'),
      width: 150,
      renderCell: (params: GridCellParams) => <strong>{params.row.value}</strong>,
    },
    {
      field: 'createdAt',
      headerName: t('createdAt.label'),
      width: 180,
      renderCell: getCreatedAtFormattedValue,
      valueFormatter: getCreatedAtFormattedValue,
    },
  ];

  if (xsDownMatches) {
    columns.splice(1, 0, textGridColumn);
  } else {
    columns.push(textGridColumn);
  }

  return (
    <Grid
      columns={columns}
      rows={data}
      getEditPath={getEditPath}
      onDelete={onDelete}
      onDeleteCompleted={onDeleteCompleted}
      onRowClick={onRowClick}
    />
  );
}
