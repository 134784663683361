import { DeleteIconButton, DeleteIconButtonProps } from './DeleteIconButton';
import { useFormikContext } from 'formik';
import { useFormikDisableContext } from '../../context/formikDisable';

export function FormDeleteIconButton(props: DeleteIconButtonProps) {
  const { isSubmitting } = useFormikContext();
  const { isDisabled } = useFormikDisableContext();

  return <DeleteIconButton {...props} disabled={props.disabled || isSubmitting || isDisabled} />;
}
