import React, { forwardRef, ReactNode, useImperativeHandle } from 'react';
import { Box, Button, Menu } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useStyles } from './styles';

interface Props {
  menuItems: ReactNode[];
}

export interface CanCloseMenu {
  closeMenu(): void;
}

export const PageTopBarDetailedMoreOptionsButton = forwardRef<CanCloseMenu, Props>(
  ({ menuItems }: Props, ref) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    useImperativeHandle(ref, () => ({
      closeMenu() {
        handleClose();
      },
    }));

    return (
      <Box>
        <Button
          id="header-top-bar-button"
          variant="contained"
          disableElevation={true}
          aria-controls="header-top-bar-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          classes={{ root: classes.buttonRoot }}
        >
          <MoreHorizIcon />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          elevation={0}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          classes={{ paper: classes.menuPaperRoot }}
          disableAutoFocusItem={true}
        >
          {menuItems}
        </Menu>
      </Box>
    );
  },
);
