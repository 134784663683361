import { GridCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { DeleteItemButton } from '../DeleteButton';
import { Box, IconButton } from '@mui/material';
import {
  DeleteCompletedHandler,
  DeleteHandler,
  GridColumnDefinition,
  RowClickHandler,
  RowEditDisabledHandler,
} from './Grid';
import { EditGridIcon } from '../Icons';
import { GRID_FIELD_DELETE, GRID_FIELD_EDIT } from '../../constants';
import { CustomTooltip } from '../CustomTooltip';

export function getEditColumn<T>(
  getEditPath: (id: number) => string,
  onRowClick?: RowClickHandler<T>,
  getRowEditDisabled?: RowEditDisabledHandler<T>,
  tooltipText?: string,
): GridColumnDefinition<T> {
  return {
    field: GRID_FIELD_EDIT,
    headerName: '',
    width: 40,
    sortable: false,
    hideSortIcons: false,
    filterable: false,
    disableColumnMenu: true,
    renderHeader: () => <span />,
    renderCell: (params: GridCellParams) => {
      const thisRowId = params.id as number;
      const isDisabled = getRowEditDisabled ? getRowEditDisabled(params.row as T) : false;

      let content = (
        <Box
          {...(onRowClick ? { onClick: () => onRowClick(params.row as T) } : {})}
          width="100%"
          height="100%"
        >
          <span onClick={(e) => e.stopPropagation()}>
            <IconButton
              component={Link}
              to={getEditPath(thisRowId)}
              disabled={isDisabled}
              size="large"
            >
              <EditGridIcon />
            </IconButton>
          </span>
        </Box>
      );

      if (isDisabled && tooltipText) {
        content = <CustomTooltip title={tooltipText}>{content}</CustomTooltip>;
      }

      return content;
    },
  };
}

export function getDeleteColumn<T>(
  onDeleteHandler: DeleteHandler,
  onDeleteCompletedHandler?: DeleteCompletedHandler<T>,
  onRowClick?: RowClickHandler<T>,
  renderDeleteButtonCondition?: (params: GridCellParams) => boolean,
): GridColumnDefinition<T> {
  return {
    field: GRID_FIELD_DELETE,
    headerName: '',
    width: 40,
    sortable: false,
    hideSortIcons: false,
    filterable: false,
    disableColumnMenu: true,
    renderHeader: () => <span />,
    renderCell: (params: GridCellParams) => {
      async function handleDelete() {
        const thisRowId = params.id as number;
        await onDeleteHandler(thisRowId);
      }

      async function handleDeleteCompleted() {
        if (onDeleteCompletedHandler) {
          await onDeleteCompletedHandler(params.row as T);
        }
      }

      const DeleteItemButtonComponent = (
        <DeleteItemButton onDelete={handleDelete} onDeleteCompleted={handleDeleteCompleted} />
      );

      return (
        <Box
          {...(onRowClick ? { onClick: () => onRowClick(params.row as T) } : {})}
          width="100%"
          height="100%"
        >
          <span onClick={(e) => e.stopPropagation()}>
            {renderDeleteButtonCondition
              ? renderDeleteButtonCondition(params) && DeleteItemButtonComponent
              : DeleteItemButtonComponent}
          </span>
        </Box>
      );
    },
  };
}
