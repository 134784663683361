import * as Yup from 'yup';
import { TextInput } from '../../components/TextInput';
import { SubmitFormCompletedHandler, SubmitFormHandler } from '../../components/Form';
import { Box, Divider, Typography } from '@mui/material';
import { QualificationsInput } from '../../components/QualificationsInput';
import { Department, Optional, Qualification, SexEnum, UserRole } from '../../models';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { FormButton } from '../../components/FormButton';
import { SexSelectInput } from '../../components/SelectInput';
import { EntityForm } from '../../components/EntityForm';
import { UserRoleInput } from '../../components/UserRoleInput';
import { DepartmentInput } from '../../components/DepartmentsInput';

export interface MedicFormValues {
  firstName: string;
  lastName: string;
  username: string;
  email: Optional<string>;
  phone: string;
  sex: Optional<SexEnum>;
  userRoleId: Optional<number>;
  qualificationIds: number[];
  departmentIds: string[];
  isActive: boolean;
}

interface Props<TResponseData = never> {
  initialValues?: MedicFormValues;
  onSubmit: SubmitFormHandler<MedicFormValues, TResponseData>;
  onSubmitCompleted: SubmitFormCompletedHandler<TResponseData>;
  qualifications: Qualification[];
  userRoles: UserRole[];
  departments: Department[];
}

export function MedicForm<TResponseData = never>({
  initialValues,
  onSubmit,
  onSubmitCompleted,
  qualifications,
  userRoles,
  departments,
}: Props<TResponseData>) {
  const { t } = useTranslation('medic');
  const defaultFormValues: MedicFormValues = {
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    phone: '',
    sex: undefined,
    userRoleId: userRoles.find((x) => x.isDefaultMedicRole)?.id,
    qualificationIds: [],
    departmentIds: [],
    isActive: true,
  };

  const classes = useStyles();
  const schema: Yup.SchemaOf<MedicFormValues> = Yup.object({
    firstName: Yup.string().required(t('firstName.validation.required')),
    lastName: Yup.string().required(t('lastName.validation.required')),
    username: Yup.string().required(t('username.validation.required')),
    email: Yup.string().email(t('email.validation.email')).nullable().notRequired(),
    phone: Yup.string().nullable().required(t('phone.validation.required')),
    sex: Yup.mixed<SexEnum>()
      .required(t('sex.validation.required'))
      .oneOf(Object.values(SexEnum), t('sex.validation.oneOf')),
    userRoleId: Yup.number().required(t('userRole.validation.required')),
    qualificationIds: Yup.array().of(Yup.number().required(t('qualification.validation.required'))),
    departmentIds: Yup.array()
      .min(1, t('department.validation.min'))
      .required(t('department.validation.required'))
      .of(Yup.string().required(t('department.validation.requiredItem'))),
    isActive: Yup.boolean().required('test'),
  });

  return (
    <EntityForm<MedicFormValues, TResponseData>
      initialValues={initialValues || defaultFormValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      onSubmitCompleted={onSubmitCompleted}
    >
      <Box className={classes.formRow}>
        <TextInput id="firstName" label={t('firstName.label')} required={true} />
        <TextInput id="lastName" label={t('lastName.label')} required={true} />
      </Box>
      <Box className={classes.formRow}>
        <UserRoleInput
          id="userRoleId"
          label={t('userRole.label')}
          required={true}
          userRoles={userRoles}
        />
        <TextInput id="username" label={t('username.label')} required={true} />
      </Box>
      <Box className={classes.formRow}>
        <TextInput id="email" label={t('email.label')} type="email" />
        <TextInput id="phone" label={t('phone.label')} required={true} />
      </Box>
      <Box className={classes.formRow}>
        <SexSelectInput id="sex" label={t('sex.label')} required={true} />
      </Box>
      <Box>
        <Divider classes={{ root: classes.dividerMargin }} />
        <Typography variant="h6" component={'div'} className={classes.bold}>
          {t('departments.title')}
        </Typography>
        <DepartmentInput id="departmentIds" departments={departments} required={true} />
      </Box>
      <Box>
        <Divider classes={{ root: classes.dividerMargin }} />
        <Typography variant="h6" component={'div'} className={classes.bold}>
          {t('qualifications.title')}
        </Typography>
        <QualificationsInput id="qualificationIds" qualifications={qualifications} />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <FormButton />
      </Box>
    </EntityForm>
  );
}
