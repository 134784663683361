import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import { ProgressIndicator } from '../../components/ProgressIndicator';
import { Department } from '../../models';
import { Page } from '../../components/Page';
import { useTranslation } from 'react-i18next';
import { FunctionalityProvider } from '../../context/functionality';
import { DepartmentsList } from './DepartmentsList';
import { PageTopBar } from '../../components/PageTopBar';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import React, { useEffect, useState } from 'react';
import { isSubstring } from '../../utils';
import { useEntityActionSnackbar } from '../../hooks';

export function ListDepartmentsPage() {
  const { enqueueEntityDeletedSnackbar } = useEntityActionSnackbar();
  const { t } = useTranslation('department');
  const [
    { isLoading: isDepartmentsLoading, isError, data: departments },
    { refetch: refetchDepartments },
    { lastFetchDatetime },
  ] = useGetApi<Department[]>(ApiRoutes.Departments);

  const [filteredDepartments, setFilteredDepartments] = useState(departments);

  useEffect(() => {
    setFilteredDepartments(departments);
  }, [departments]);

  async function handleDelete(id: number) {
    await makeApiRequest('DELETE', ApiRoutes.Department(id));
  }

  async function handleDeleteCompleted(department: Department) {
    await refetchDepartments();

    enqueueEntityDeletedSnackbar(t('department', { context: department.name }), {
      context: department.name,
    });
  }

  function handleSearch(searchedText: string) {
    if (!departments) {
      setFilteredDepartments([]);
    } else {
      if (searchedText === '') {
        setFilteredDepartments(departments);
      } else {
        const searchedTextToLower = searchedText.toLowerCase();
        const searchedDepartments = departments.filter((department) => {
          const { name } = department;
          return isSubstring(name, searchedTextToLower);
        });
        setFilteredDepartments(searchedDepartments);
      }
    }
  }

  if (isDepartmentsLoading) {
    return <ProgressIndicator />;
  }

  return (
    <FunctionalityProvider
      createFunctionality="DEPARTMENTS_CREATE"
      readFunctionality="DEPARTMENTS_READ"
      updateFunctionality="DEPARTMENTS_UPDATE"
      deleteFunctionality="DEPARTMENTS_DELETE"
    >
      <>
        <StickyTopAppBar>
          <PageTopBar
            title={t('title.list')}
            addNewRedirectPath={'/departments/new'}
            newRecordDescriptionText={t('title.new')}
            onSearch={handleSearch}
            lastUpdated={lastFetchDatetime}
          />
        </StickyTopAppBar>
        <Page fullWidth={true} noPaper={true} requireFunctionality="DEPARTMENTS_READ">
          {!isDepartmentsLoading && !isError && (
            <DepartmentsList
              departments={filteredDepartments}
              onDelete={handleDelete}
              onDeleteCompleted={handleDeleteCompleted}
            />
          )}
        </Page>
      </>
    </FunctionalityProvider>
  );
}
