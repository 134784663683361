import { Hub } from './types';

const hubRoutePrefix = '/hub';

export const Hubs: Record<string, Hub> = {
  systemEvents: {
    route: `${hubRoutePrefix}/system-events`,
    messages: {
      newSystemEvent: 'newSystemEvent',
    },
  },
  pairing: {
    route: `${hubRoutePrefix}/pairing`,
    messages: {
      patientPairedToDevice: 'patientPairedToDevice',
    },
  },
};
