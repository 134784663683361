import * as Yup from 'yup';
import { TextInput } from '../../../components/TextInput';
import { Form, SubmitFormCompletedHandler, SubmitFormHandler } from '../../../components/Form';
import { Box, Divider, Typography } from '@mui/material';
import { Optional } from '../../../models';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { FormButton } from '../../../components/FormButton';
import { CheckboxInput } from '../../../components/CheckboxInput';
import { ResetPasscodeButton } from '../../../components/ResetPasscodeButton';
import { ResetPasswordHandler } from '../../../components/ResetPasswordButton';

export interface MedicProfileFormValues {
  email: Optional<string>;
  phone: string;
  smsNotifyCritical: boolean;
  emailNotifyCritical: boolean;
}

interface Props {
  initialValues?: MedicProfileFormValues;
  onSubmit: SubmitFormHandler<MedicProfileFormValues>;
  onSubmitCompleted: SubmitFormCompletedHandler;
  onResetPasscode: ResetPasswordHandler;
}

export function MedicProfileForm({
  initialValues,
  onSubmit,
  onSubmitCompleted,
  onResetPasscode,
}: Props) {
  const { t } = useTranslation('medic');
  const defaultFormValues: MedicProfileFormValues = {
    email: '',
    phone: '',
    smsNotifyCritical: false,
    emailNotifyCritical: false,
  };

  const classes = useStyles();
  const schema: Yup.SchemaOf<MedicProfileFormValues> = Yup.object({
    email: Yup.string()
      .email(t('email.validation.email'))
      .nullable()
      .notRequired()
      .when(['emailNotifyCritical', 'emailNotifyWarning'], {
        is: (emailNotifyCritical: boolean, emailNotifyWarning: boolean) =>
          emailNotifyCritical || emailNotifyWarning,
        then: Yup.string()
          .email(t('email.validation.email'))
          .required(t('email.validation.required'))
          .nullable(),
      }),
    phone: Yup.string().nullable().required(t('phone.validation.required')),
    smsNotifyCritical: Yup.boolean().required(),
    emailNotifyCritical: Yup.boolean().required(),
  });

  return (
    <Form<MedicProfileFormValues>
      initialValues={initialValues || defaultFormValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      onSubmitCompleted={onSubmitCompleted}
      formMode="toggle"
    >
      <Box className={classes.formRow}>
        <TextInput id="email" label={t('email.label')} type="email" />
        <TextInput id="phone" label={t('phone.label')} required={true} />
      </Box>
      <Box>
        <Divider classes={{ root: classes.dividerMargin }} />
        <Typography variant="h6" component={'div'} className={classes.bold}>
          {t('notifications.label')}
        </Typography>
        <Typography variant="body2">{t('notifications.infoText')}</Typography>
        <Box className={classes.notificationCheckboxesWrapper}>
          <Box className={classes.notificationGroupWrapper}>
            <Box>
              <CheckboxInput
                id="smsNotifyCritical"
                label={t('notifications.sms')}
                setTouchedWhenCheckedOnLoad={true}
              />
            </Box>
            <Box>
              <CheckboxInput
                id="emailNotifyCritical"
                label={t('notifications.email')}
                setTouchedWhenCheckedOnLoad={true}
                blurInputsOnChange={['email']}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <Divider classes={{ root: classes.dividerMargin }} />
        <ResetPasscodeButton onResetPasscode={onResetPasscode} />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <FormButton />
      </Box>
    </Form>
  );
}
