import React, { useEffect } from 'react';
import { FieldArray } from 'formik';
import { Department } from '../../models/Department';
import { CheckboxInput } from '../CheckboxInput';
import { Typography } from '@mui/material';
import { useField } from 'formik';
import { useFormikContext } from 'formik';

interface Props {
  departments: Department[];
  id: string;
  required?: boolean;
  onSelected?: (selectedIds: string[]) => void;
}

export function DepartmentInput({ departments, id, required = false, onSelected }: Props) {
  const [, meta] = useField(id);

  const formikContext = useFormikContext();

  useEffect(() => {
    formikContext.setFieldTouched(id, required, false);
  }, []);

  return (
    <>
      <FieldArray name={id}>
        {({ form }) => (
          <div>
            {departments.map((department) => (
              <div key={department.id}>
                <CheckboxInput
                  id={`${id}-${department.id}`}
                  label={department.name}
                  checked={form.values[id].includes(department.id)}
                  onChange={(event) => {
                    let newValues;
                    if (event.target.checked) {
                      newValues = [...form.values[id], department.id];
                      form.setFieldValue(id, newValues);
                    } else {
                      newValues = form.values[id].filter(
                        (deptId: string) => deptId !== department.id,
                      );
                      form.setFieldValue(id, newValues);
                    }
                    form.setFieldTouched(id, true);
                    if (onSelected) {
                      onSelected(newValues);
                    }
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </FieldArray>
      {meta.touched &&
        meta.error && ( // Update this line
          <Typography color="error">{meta.error}</Typography>
        )}
    </>
  );
}
