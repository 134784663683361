import React from 'react';
import { Box } from '@mui/material';
import { useField } from 'formik';
import { CheckboxIconInput } from '../CheckboxIconInput';
import { AlarmCheckBoxIcon } from '../Icons';
import { AlarmTypeWithLabel } from '../../models';
import { useAlarmTypesInputStyles } from './styles';

interface Props {
  id: string;
  alarmTypeOptions: AlarmTypeWithLabel[];
}

export function AlarmTypesInput({ id, alarmTypeOptions }: Props) {
  const [{ value }, , { setValue }] = useField<number[]>(id);
  const classes = useAlarmTypesInputStyles();

  return (
    <React.Fragment>
      {alarmTypeOptions.map((alarmType, index) => (
        <Box key={index} className={classes.alarmTypesWrapper}>
          <CheckboxIconInput
            id={id}
            htmlId={`sensor-${alarmType.type}`}
            label={alarmType.label}
            icon={
              <AlarmCheckBoxIcon
                alarmType={alarmType.type}
                isActive={value.includes(alarmType.id)}
              />
            }
            checked={value.includes(alarmType.id)}
            showError={false}
            onChange={(e) => {
              if (e.target.checked) {
                // if setting device measurement alarm, add measurement alarm
                setValue([...value, alarmType.id]);
              } else {
                // remove selected alarm from array
                setValue([...value.filter((x) => x != alarmType.id)]);
              }
            }}
          />
        </Box>
      ))}
    </React.Fragment>
  );
}
