import { ReactNode } from 'react';
import { GridContext } from '.';
import { useGridUrlPage } from '../../hooks/useGridUrlPage';
import { useGridUrlSort } from '../../hooks';

interface Props {
  children: ReactNode;
}

export function GridContextProvider({ children }: Props) {
  const {
    currentPage,
    onPageChange,
    goToFirstPage,
    pageSize,
    onPageSizeChange,
    onCurrentRowsCountChange,
  } = useGridUrlPage();
  const { sortModel, onSortModelChange } = useGridUrlSort();

  return (
    <GridContext.Provider
      value={{
        currentPage: currentPage,
        onPageChange: onPageChange,
        goToFirstPage: goToFirstPage,
        pageSize: pageSize,
        onPageSizeChange: onPageSizeChange,
        onCurrentRowsCountChange: onCurrentRowsCountChange,
        sortModel: sortModel,
        onSortModelChange: onSortModelChange,
      }}
    >
      {children}
    </GridContext.Provider>
  );
}
