import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

interface Props {
  isSelected: boolean;
  questionText: string;
  hasErrorState: boolean;
  id: string;
}

export function QuestionListItemText({ isSelected, questionText, hasErrorState, id }: Props) {
  const { t } = useTranslation('questionnaire');
  const classes = useStyles();

  const isQuestionTextEmpty = questionText.length > 0;

  return (
    <Box
      id={id}
      className={`${classes.questionText} ${
        hasErrorState ? classes.questionTextError : isSelected ? classes.questionTextSelected : ''
      }`}
    >
      {isQuestionTextEmpty ? questionText : t('question.emptyText')}
    </Box>
  );
}
