import { Box, Hidden, useMediaQuery, useTheme } from '@mui/material';
import { Skeleton } from '@mui/lab';
import { SKELETON_ANIMATION } from '../../constants';
import React from 'react';

export function QuestionnaireFormLoading() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box display="flex" flexDirection="column" width="100%" p={matches ? 2 : 0}>
      <Box display="flex" width="100%">
        <Hidden mdDown={true}>
          <Skeleton
            variant="circular"
            animation={SKELETON_ANIMATION}
            width={50}
            height={50}
            style={{
              marginRight: theme.spacing(1),
            }}
          />
        </Hidden>
        <Box display="flex" flexDirection="column" width="100%">
          <Skeleton
            variant="rectangular"
            animation={SKELETON_ANIMATION}
            width="100%"
            height={50}
            style={{
              marginBottom: theme.spacing(1),
            }}
          />
          <Skeleton
            variant="rectangular"
            animation={SKELETON_ANIMATION}
            width="100%"
            height={150}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        width="100%"
        flexDirection="column"
        style={{
          marginBottom: theme.spacing(1),
        }}
      >
        <Skeleton
          variant="text"
          animation={SKELETON_ANIMATION}
          width="25%"
          height={50}
          style={{
            marginRight: theme.spacing(1),
          }}
        />
        <Skeleton
          variant="rectangular"
          animation={SKELETON_ANIMATION}
          width="100px"
          height={40}
          style={{
            marginRight: theme.spacing(1),
          }}
        />
      </Box>
      <Box display="flex" width="100%" flexDirection={matches ? 'column' : 'row'}>
        <Box
          display="flex"
          width={matches ? '100%' : '50%'}
          flexDirection="column"
          style={{
            marginBottom: theme.spacing(1),
            marginRight: theme.spacing(1),
          }}
        >
          <Skeleton
            variant="text"
            animation={SKELETON_ANIMATION}
            width="100px"
            height={50}
            style={{
              marginRight: theme.spacing(1),
            }}
          />
          {[...Array(5)].map((x, idx) => (
            <Skeleton
              key={idx}
              variant="rectangular"
              animation={SKELETON_ANIMATION}
              width="100%"
              height={40}
              style={{
                marginBottom: theme.spacing(1),
              }}
            />
          ))}
        </Box>
        <Box width={matches ? '100%' : '50%'}>
          <Skeleton
            variant="rectangular"
            animation={SKELETON_ANIMATION}
            width="100%"
            height={200}
            style={{
              marginBottom: theme.spacing(1),
              marginRight: theme.spacing(1),
            }}
          />
        </Box>
      </Box>
      <Box display="flex" width="100%" justifyContent="flex-end">
        <Skeleton variant="rectangular" animation={SKELETON_ANIMATION} width="100px" height={40} />
      </Box>
    </Box>
  );
}
