import { Tab, TabProps } from '@mui/material';
import { useRequireFunctionality } from '../../hooks';
import { Functionality } from '../../models';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';

export interface StyledTabProps extends TabProps {
  label: string;
  icon?: string | React.ReactElement;
  value?: string;
  requireFunctionality?: Functionality | Functionality[];
  forceValidity?: boolean;
  to?: string;
  hide?: boolean;
  unsetMinHeight?: boolean;
}

export function StyledTab({
  requireFunctionality,
  to,
  label,
  hide = false,
  ...props
}: StyledTabProps) {
  const classes = useStyles();
  const { hasFunctionality } = useRequireFunctionality(requireFunctionality);

  if (!hasFunctionality) {
    return null;
  }

  return (
    <Tab
      component={Link}
      to={to}
      disableRipple={true}
      wrapped={true}
      label={label ? <span className={classes.label}>{label}</span> : ''}
      {...props}
      classes={{
        root: classes.root,
        selected: classes.selected,
        wrapped: classes.wrapper,
        labelIcon: classes.labelIcon,
      }}
      {...(hide ? { style: { display: 'none' } } : {})}
    />
  );
}
