import { Patient, PatientColumnsWidth, PatientGridModel } from '../../models';
import { PatientsGrid } from '../../components/Grids';
import React from 'react';
import { Box } from '@mui/material';
import { DeleteCompletedHandler, DeleteHandler, RowClickHandler } from '../../components/Grid';

interface Props {
  patients: Patient[] | null;
  onDelete?: DeleteHandler;
  onDeleteCompleted?: DeleteCompletedHandler<Patient>;
  isSelectionGrid?: boolean;
  selectedPatients?: Patient[];
  onGoBack?: () => void;
  onRowClick?: RowClickHandler<Patient>;
  onSaveSelection?: (patientIds: number[]) => Promise<void>;
  showColumns?: (keyof PatientGridModel)[] | 'all';
  columnsWidth?: PatientColumnsWidth;
  disableEdit?: boolean;
}

export function PatientsList({ patients, onDelete, onDeleteCompleted, ...props }: Props) {
  return (
    <Box width="100%">
      {patients && (
        <PatientsGrid
          {...props}
          patients={patients}
          onDelete={onDelete}
          onDeleteCompleted={onDeleteCompleted}
        />
      )}
    </Box>
  );
}
