import { createContext, useContext } from 'react';
import { GridContextType } from './types';

export const GridContext = createContext<GridContextType>({
  currentPage: 0,
  onPageChange: () => {},
  goToFirstPage: () => {},
  pageSize: 10,
  onPageSizeChange: () => {},
  onCurrentRowsCountChange: () => {},
  sortModel: undefined,
  onSortModelChange: () => {},
});
export const useGridContext = () => useContext(GridContext);
