import React, { Fragment } from 'react';
import { StyleSheet } from '@react-pdf/renderer';
import { MeasurementValueWithUnit, WebMeasurement } from '../../../../../models';
import { TableRow } from '../../common/TableRow';
import { thresholdColors } from '../../../../../constants/colors';
import { DATE_TIME_LOCALE_WITHOUT_SECONDS } from '../../../../../constants';
import { FONT_WEIGHTS } from '../../../../Constants';

interface Props {
  measurements: WebMeasurement[];
}

export function MeasurementTableRows({ measurements }: Props) {
  if (measurements.length <= 0) {
    return <></>;
  }

  const styles = StyleSheet.create({
    minError: {
      fontWeight: FONT_WEIGHTS.bold,
      color: thresholdColors.minErrorDark,
      backgroundColor: thresholdColors.minErrorLight,
    },
    minWarning: {
      fontWeight: FONT_WEIGHTS.bold,
      color: thresholdColors.minWarningDark,
      backgroundColor: thresholdColors.minWarningLight,
    },
    maxWarning: {
      fontWeight: FONT_WEIGHTS.bold,
      color: thresholdColors.maxWarningDark,
      backgroundColor: thresholdColors.maxWarningLight,
    },
    maxError: {
      fontWeight: FONT_WEIGHTS.bold,
      color: thresholdColors.maxErrorDark,
      backgroundColor: thresholdColors.maxErrorLight,
    },
  });

  const rows = measurements.map((measurement, idx) => {
    let additionalStyles = {};
    return (
      <TableRow
        key={idx}
        data={[
          {
            value: new Date(measurement.measuredOnDatetime).toLocaleString(
              [],
              DATE_TIME_LOCALE_WITHOUT_SECONDS,
            ),
          },
          {
            value: measurement.measuredByName,
          },
          ...Object.entries(measurement)
            .filter(
              ([key]) => key !== 'id' && key !== 'measuredOnDatetime' && key !== 'measuredByName',
            )
            .map(([_, val]) => {
              if (val) {
                const measurement = val as MeasurementValueWithUnit;
                additionalStyles = {};
                if (measurement.isMaxError) {
                  additionalStyles = styles.maxError;
                } else if (measurement.isMinError) {
                  additionalStyles = styles.minError;
                } else if (measurement.isMaxWarning) {
                  additionalStyles = styles.maxWarning;
                } else if (measurement.isMinWarning) {
                  additionalStyles = styles.minWarning;
                }
              }
              return {
                value: String((val as MeasurementValueWithUnit).value),
                style: additionalStyles,
              };
            }),
        ]}
      />
    );
  });
  return <Fragment>{rows}</Fragment>;
}
