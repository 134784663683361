import { useTranslation } from 'react-i18next';
import { NoRecords } from '../NoRecords';

export function NoAlarms() {
  const { t } = useTranslation('alarm');
  return (
    <NoRecords
      title={t('noAlarmsCallToAction.title')}
      subtitle={t('noAlarmsCallToAction.subtitle')}
      image={<img src="/img/daily-plan.png" alt="Device image" />}
      textOverImage={true}
    />
  );
}
