import { Drawer, Hidden, useMediaQuery, useTheme } from '@mui/material';
import { Route } from 'react-router';
import { CreatePatientPage, ListPatientsPage } from '../../pages/patients';
import { ListMedicsPage } from '../../pages/medics/ListMedicsPage';
import { CreateMedicPage } from '../../pages/medics/CreateMedicPage';
import { ListQuestionnairesPage } from '../../pages/questionnaires';
import { ProtectedRoute } from '../ProtectedRoute';
import { CreateQuestionnairePage } from '../../pages/questionnaires/CreateQuestionnairePage';
import { ErrorPage } from '../../pages/error';
import { GridContextProvider } from '../../context';
import { DashboardDrawerContent } from './DrawerContent';
import { useDashboardMenuContext } from '../../context/dashboardMenu';
import { useStyles } from './styles';
import { DashboardMobileDrawerContent } from './Mobile/DrawerContent';
import { BottomNavigationMobile } from '../BottomNavigationMobile';
import { ROUTES } from '../../constants';
import { FunctionalityProvider } from '../../context/functionality';
import { PATIENTS_READ_POLICY } from '../../constants/Policies';
import { ListOrganizationsPage } from '../../pages/organization/ListOrganizationsPage';
import { CreateOrganizationPage } from '../../pages/organization/CreateOrganizationPage';
import { ListDepartmentsPage } from '../../pages/department/ListDepartmentsPage';
import { CreateDepartmentPage } from '../../pages/department/CreateDepartmentPage';
import { ListDevicesPage } from '../../pages/device/ListDevicesPage';
import { DevicePage } from '../../pages/device/DevicePage';
import { CreateDevicePage } from '../../pages/device/CreateDevicePage';
import { ChangeLogPage } from '../../pages/changeLog';
import { Navigate, Routes } from 'react-router-dom';
import { DailyPlanRoutes, DepartmentRoutes } from '../Routes';
import { MedicRoutes } from '../Routes/MedicRoutes';
import { NotFoundPage } from '../../pages/notFound/NotFoundPage';
import { OrganizationRoutes } from '../Routes/OrganizationRoutes';
import {
  CreateDailyPlanTemplatePage,
  DailyPlanTemplateAlarmPage,
  ListDailyPlanTemplatesPage,
} from '../../pages/dailyPlanTemplates';
import { QuestionnaireRoutes } from '../Routes/QuestionnaireRoutes';
import { ProfilePage } from '../../pages/profile';
import { AdminDashboardPage } from '../../pages/admin';
import { PatientRoutes } from '../Routes/PatientRoutes';
import { ListGroupsPage } from '../../pages/group/ListGroupsPage';
import { CreateGroupPage } from '../../pages/group/CreateGroupPage';
import { GroupRoutes } from '../Routes/GroupRoutes';

export interface StyleProps {
  drawerWidthCurrent: number;
  drawerWidthClosed: number;
  isDrawerOpen: boolean;
}

export function Dashboard(): JSX.Element {
  const { isDrawerOpen, drawerWidthCurrent, drawerWidthClosed, handleDrawerClose } =
    useDashboardMenuContext();
  const classes = useStyles({
    drawerWidthCurrent,
    drawerWidthClosed,
    isDrawerOpen,
  } as StyleProps);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getVariant = (): 'permanent' | 'persistent' | 'temporary' => {
    if (isMobile) {
      return 'temporary';
    }

    if (isTablet) {
      return isDrawerOpen ? 'temporary' : 'permanent';
    }

    return 'permanent';
  };

  const getAnchor = (): 'left' | 'right' | 'top' | 'bottom' => {
    if (isMobile) {
      return 'bottom';
    }

    return 'left';
  };

  /*const getDrawerClass = () => {
    if (isMobile) {
      return classes.drawerPaperMobile;
    }

    return classes.drawerPaper;
  };*/

  const getDrawerContent = () => {
    if (isMobile) {
      return <DashboardMobileDrawerContent onNavigationListItemClick={handleDrawerClose} />;
    }

    return (
      <DashboardDrawerContent
        {...(isTablet ? { onNavigationListItemClick: handleDrawerClose } : {})}
      />
    );
  };

  return (
    <div className={classes.root}>
      <nav className={`${classes.navigation} ${classes.bold}`}>
        <Hidden smUp={true} implementation="css">
          <BottomNavigationMobile />
        </Hidden>
        <Drawer
          variant={getVariant()}
          anchor={getAnchor()}
          open={isDrawerOpen}
          sx={{
            width: drawerWidthCurrent,
            borderRight: 'none',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidthCurrent,
              boxSizing: 'border-box',
              borderRight: 'none',
            },
          }}
        >
          {getDrawerContent()}
        </Drawer>
      </nav>
      <main className={classes.content}>
        <Routes>
          <Route path={'*'} element={<NotFoundPage />} />
          <Route path={ROUTES.ERROR.path as string} element={<ErrorPage />} />
          <Route
            path={'/'}
            element={
              <ProtectedRoute>
                <Navigate to="/patients" />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.CHANGE_LOG.path as string}
            element={
              <ProtectedRoute requireFunctionality="ADMIN_PANEL">
                <ChangeLogPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.PATIENTS.path as string}
            element={
              <ProtectedRoute requireFunctionality={PATIENTS_READ_POLICY}>
                <GridContextProvider>
                  <ListPatientsPage />
                </GridContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.PATIENT_CREATE.path as string}
            element={
              <ProtectedRoute requireFunctionality="PATIENTS_CREATE">
                <CreatePatientPage />
              </ProtectedRoute>
            }
          />
          <Route path={ROUTES.PATIENT_DETAIL.path as string} element={<PatientRoutes />} />
          <Route
            path={ROUTES.MEDICS.path as string}
            element={
              <ProtectedRoute requireFunctionality="MEDICS_READ">
                <GridContextProvider>
                  <ListMedicsPage />
                </GridContextProvider>
              </ProtectedRoute>
            }
          />
          <Route path={ROUTES.MEDIC_DETAIL.path as string} element={<MedicRoutes />} />
          <Route
            path={ROUTES.MEDIC_CREATE.path as string}
            element={
              <ProtectedRoute requireFunctionality="MEDICS_CREATE">
                <CreateMedicPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.ORGANIZATIONS.path as string}
            element={
              <ProtectedRoute requireFunctionality="ORGANIZATIONS_READ">
                <GridContextProvider>
                  <ListOrganizationsPage />
                </GridContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.ORGANIZATION_DETAIL.path as string}
            element={<OrganizationRoutes />}
          />
          <Route
            path={ROUTES.ORGANIZATION_CREATE.path as string}
            element={
              <ProtectedRoute requireFunctionality="ORGANIZATIONS_CREATE">
                <CreateOrganizationPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.GROUPS.path as string}
            element={
              <ProtectedRoute requireFunctionality="ORGANIZATIONS_READ">
                <GridContextProvider>
                  <ListGroupsPage />
                </GridContextProvider>
              </ProtectedRoute>
            }
          />
          <Route path={ROUTES.GROUP_DETAIL.path as string} element={<GroupRoutes />} />
          <Route
            path={ROUTES.GROUP_CREATE.path as string}
            element={
              <ProtectedRoute requireFunctionality="ORGANIZATIONS_CREATE">
                <CreateGroupPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.DEPARTMENTS.path as string}
            element={
              <ProtectedRoute requireFunctionality="DEPARTMENTS_READ">
                <GridContextProvider>
                  <ListDepartmentsPage />
                </GridContextProvider>
              </ProtectedRoute>
            }
          />
          <Route path={ROUTES.DEPARTMENT_DETAIL.path as string} element={<DepartmentRoutes />} />
          <Route
            path={ROUTES.DEPARTMENT_CREATE.path as string}
            element={
              <ProtectedRoute requireFunctionality="DEPARTMENTS_CREATE">
                <CreateDepartmentPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.DEVICES.path as string}
            element={
              <ProtectedRoute requireFunctionality="DEVICES_READ">
                <GridContextProvider>
                  <ListDevicesPage />
                </GridContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.DEVICE_DETAIL.path as string}
            element={
              <ProtectedRoute requireFunctionality="DEVICES_READ">
                <DevicePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.DEVICE_CREATE.path as string}
            element={
              <ProtectedRoute requireFunctionality="DEVICES_CREATE">
                <CreateDevicePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.QUESTIONNAIRE_TEMPLATES.path as string}
            element={
              <ProtectedRoute requireFunctionality="QUESTIONNAIRE_TEMPLATES_READ">
                <GridContextProvider>
                  <ListQuestionnairesPage />
                </GridContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.QUESTIONNAIRE_TEMPLATE_DETAIL.path as string}
            element={<QuestionnaireRoutes />}
          />
          <Route
            path={ROUTES.QUESTIONNAIRE_TEMPLATE_CREATE.path as string}
            element={
              <ProtectedRoute requireFunctionality="QUESTIONNAIRE_TEMPLATES_CREATE">
                <FunctionalityProvider createFunctionality="QUESTIONNAIRE_TEMPLATES_CREATE">
                  <CreateQuestionnairePage />
                </FunctionalityProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.DAILY_PLAN_TEMPLATES.path as string}
            element={
              <ProtectedRoute requireFunctionality="DAILY_PLAN_TEMPLATES_READ">
                <GridContextProvider>
                  <ListDailyPlanTemplatesPage />
                </GridContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.DAILY_PLAN_TEMPLATE_CREATE.path as string}
            element={
              <ProtectedRoute requireFunctionality="DAILY_PLAN_TEMPLATES_CREATE">
                <CreateDailyPlanTemplatePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.DAILY_PLAN_TEMPLATE_DETAIL.path as string}
            element={<DailyPlanRoutes />}
          />
          <Route
            path={ROUTES.DAILY_PLAN_TEMPLATE_ALARM_DETAIL.path as string}
            element={
              <ProtectedRoute requireFunctionality="DAILY_PLAN_TEMPLATES_READ">
                <DailyPlanTemplateAlarmPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.ADMIN_DASHBOARD.path as string}
            element={
              <ProtectedRoute requireFunctionality="ADMIN_PANEL">
                <AdminDashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.PROFILE.path as string}
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          />
        </Routes>
        <div className={classes.toolbar} />
      </main>
    </div>
  );
}
