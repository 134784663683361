interface IconProps {
  width?: number;
  height?: number;
}

export function PlusIcon({ width = 14, height = 14 }: IconProps) {
  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8801 8.11994H8.11994V12.8801C8.11994 13.4984 7.61832 14 7 14C6.38168 14 5.88006 13.4984 5.88006 12.8801V8.11994H1.11994C0.501626 8.11994 0 7.61832 0 7C0 6.38168 0.501626 5.88006 1.11994 5.88006H5.88006V1.11994C5.88006 0.501626 6.38168 0 7 0C7.61832 0 8.11994 0.501626 8.11994 1.11994V5.88006H12.8801C13.4984 5.88006 14 6.38168 14 7C14 7.61832 13.4984 8.11994 12.8801 8.11994Z"
        fill="currentColor"
      />
    </svg>
  );
}
