import { ProgressIndicator } from '../../components/ProgressIndicator';
import { Page } from '../../components/Page';
import {
  NewSystemEventMessage,
  SystemEvent,
  SystemEventsCursor,
  SystemEventSearchInput,
  SystemEventsPaged,
  SystemEventsPagedInput,
} from '../../models';
import { ApiRoutes, Hubs, makeApiRequest, useHub } from '../../services/api';
import { SystemEventsList } from './components';

import { useChangeLogPageStyles } from './styles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import { PageTopBar } from '../../components/PageTopBar';
import { HubStateInfo } from '../../components/Hub';
import { EVENTS_CHANGE_LOG_PAGE_SIZE } from '../../constants';
import { Alert, AlertTitle } from '@mui/lab';

export function ChangeLogPage() {
  const classes = useChangeLogPageStyles();
  const { t } = useTranslation(['changeLog', 'error']);

  const [systemEvents, setSystemEvents] = React.useState<SystemEvent[] | null>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lastFetchDatetime, setLastFetchDatetime] = useState<Date | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [nextPageCursor, setNextPageCursor] = useState<SystemEventsCursor>();
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isNewSystemEventLoading, setIsNewSystemEventLoading] = useState(false);
  const [newSystemEvents, setNewSystemEvents] = useState<SystemEvent[]>([]);

  const [hubState, { lastMessageReceivedTimestamp }] = useHub<NewSystemEventMessage>(
    Hubs.systemEvents,
    Hubs.systemEvents.messages.newSystemEvent,
    handleNewSystemEvent,
  );

  async function getNextEvents() {
    const { error, data: pagedResponse } = await makeApiRequest<
      SystemEventsPaged,
      SystemEventsPagedInput
    >('POST', ApiRoutes.SystemEvents, {
      cursor: nextPageCursor,
      pageSize: EVENTS_CHANGE_LOG_PAGE_SIZE,
    });
    if (error) {
      setIsError(true);
      return;
    }
    if (pagedResponse?.data) {
      setLastFetchDatetime(new Date());
      setSystemEvents((prevItems) => [...(prevItems || []), ...pagedResponse.data]);
    }

    if (pagedResponse?.cursor) {
      setNextPageCursor(pagedResponse.cursor);
    }
    setHasMore(pagedResponse?.hasMore ?? false);
  }

  async function refetchEvents() {
    setSystemEvents(null);
    setNextPageCursor(undefined);
    setIsLoading(true);
    getNextEvents().then(() => setIsLoading(false));
  }

  useEffect(() => {
    setIsLoading(true);
    getNextEvents().then(() => setIsLoading(false));
  }, []);

  async function handleNewSystemEvent(data: NewSystemEventMessage) {
    if (!data || !data.systemEvents) {
      return;
    }

    setIsNewSystemEventLoading(true);

    const newSystemEventsResponse = await makeApiRequest<SystemEvent[], SystemEventSearchInput>(
      'POST',
      ApiRoutes.SystemEventsSearch,
      { filters: data.systemEvents },
    );

    if (newSystemEventsResponse.error || !newSystemEventsResponse.data) {
      refetchEvents();
    } else {
      setNewSystemEvents((prevState) => [...(newSystemEventsResponse.data || []), ...prevState]);
    }

    setIsNewSystemEventLoading(false);
  }

  function wrapWithPage(children: React.ReactNode) {
    return (
      <>
        <StickyTopAppBar>
          <PageTopBar
            title={t('changeLog:title.title')}
            lastUpdated={lastMessageReceivedTimestamp || lastFetchDatetime}
          />
        </StickyTopAppBar>
        <Page noPaper={true} fullWidth={true}>
          {children}
        </Page>
      </>
    );
  }

  if (isLoading) {
    return wrapWithPage(
      <Box className={classes.progressIndicatorWrapper}>
        <ProgressIndicator />
      </Box>,
    );
  }

  if (!isLoading && systemEvents && !isError) {
    return wrapWithPage(
      <Box className={classes.listWrapper}>
        <HubStateInfo hubState={hubState} />
        <SystemEventsList
          systemEvents={
            newSystemEvents.length > 0 ? [...newSystemEvents, ...systemEvents] : systemEvents
          }
          isLoading={isNewSystemEventLoading}
          hasMore={hasMore}
          getNextPage={getNextEvents}
        />
      </Box>,
    );
  }

  return wrapWithPage(
    <Alert severity="error">
      <AlertTitle>{t('error:title.error')}</AlertTitle>
      {t('error:generic')}
    </Alert>,
  );
}
