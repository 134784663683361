import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  cursorPointer: {
    cursor: 'pointer',
  },
  questionListItemContainer: {
    padding: theme.spacing(1.5, 0),
  },
  questionType: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  questionContentsTitle: {
    fontWeight: 900,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(23),
    background: `-webkit-linear-gradient(230.06deg, ${theme.palette.primary.main} 30.16%, ${theme.palette.primary.dark} 77.51%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  questionTypeError: {
    background: `-webkit-linear-gradient(230.06deg, #F41414 30.16%, #AF0808 77.51%);`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
}));
