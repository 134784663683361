import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    borderRadius: '4px',
    boxShadow: '0 4px 12px -1px rgba(39, 51, 93, 0.1)',
    border: 'solid 1px #efefef',
    backgroundColor: theme.palette.common.white,
    margin: theme.spacing(4, 0),
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      flexWrap: 'no-wrap',
    },
  },
  column: {
    flex: '1 0 auto',
    minWidth: 150,
    maxWidth: '100%',
  },
  measuredByName: {
    display: 'flex',
    maxWidth: 500,
    color: theme.palette.grey['400'],
    minWidth: 0,
  },
  measuredByNameText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    verticalAlign: 'middle',
    lineHeight: 1.3,
    fontSize: theme.typography.pxToRem(30),
    fontWeight: 300,
    letterSpacing: theme.typography.pxToRem(-1.84),
  },
}));

export const useLatestMeasurementColumn = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    fontFamily: 'Inter',
    textAlign: 'center',
  },
  headerText: {
    backgroundColor: theme.palette.grey['100'],
    fontSize: theme.typography.pxToRem(12),
    letterSpacing: theme.typography.pxToRem(-0.2),
    width: '100%',
    padding: theme.spacing(0.75, 1),
  },
  primaryText: {
    fontSize: theme.typography.pxToRem(40),
    fontWeight: 300,
    lineHeight: 1,
    letterSpacing: theme.typography.pxToRem(-3.2),
    color: theme.palette.grey['400'],
    flex: 1,
    padding: theme.spacing(1.5, 2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  secondaryText: {
    fontSize: theme.typography.pxToRem(11),
    fontWeight: 600,
    lineHeight: 1,
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 1, 1.25),
  },
}));

export const useThresholdInputStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      rowGap: theme.spacing(1),
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'normal',
    justifyContent: 'flex-end',
    lineHeight: 1.5,
    color: 'rgba(0, 0, 0, 0.9)',
    marginRight: theme.spacing(1),
    width: theme.typography.pxToRem(120),
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      maxWidth: 'unset',
      flex: '1',
      textAlign: 'left',
      width: '100%',
      justifyContent: 'flex-start',
    },
  },
  numberInput: {
    width: theme.typography.pxToRem(150),
    minWidth: theme.typography.pxToRem(150),
    maxWidth: theme.typography.pxToRem(150),
    margin: theme.spacing(0, 1),
    '& .MuiInputBase-input': {
      fontFamily: 'Inter',
      fontSize: theme.typography.pxToRem(17),
      fontWeight: 'normal',
      letterSpacing: theme.typography.pxToRem(0.56),
      textAlign: 'center',
      padding: theme.spacing(1.5, 2),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      maxWidth: 'unset',
      width: '100%',
      margin: 0,
    },
  },
  inputButtonCommon: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
    borderRadius: theme.typography.pxToRem(4),
    padding: theme.spacing(0, 3),
    maxWidth: theme.typography.pxToRem(170),
    height: '100%',
    width: theme.typography.pxToRem(170),
    minHeight: theme.typography.pxToRem(43),
    hyphens: 'auto',
    [theme.breakpoints.down('sm')]: {
      minWidth: theme.typography.pxToRem(150),
      width: '100%',
      maxWidth: 'unset',
      flex: 1,
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(0),
    },
  },
  inputButtonLabelCommon: {
    width: theme.typography.pxToRem(90),
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  buttonTooltip: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: 1,
    },
  },
  addLimitButton: {
    color: theme.palette.success.light,
    backgroundColor: 'rgba(44, 219, 206, 0.06)',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.common.white,
    },
  },
  deleteLimitButton: {
    color: theme.palette.error.main,
    backgroundColor: 'rgba(239, 7, 7, 0.06)',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
    },
  },
  inputStartAdornment: {
    color: theme.palette.error.main,
    fontFamily: 'Rubik',
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 'normal',
  },
  inputStartAdornmentError: { color: theme.palette.error.main },
  inputStartAdornmentWarning: { color: theme.palette.warning.main },
  disabled: {
    color: 'rgba(0, 0, 0, 0.4)',
  },
}));

export const useThresholdsErrorMessagesStyles = makeStyles((theme: Theme) => ({
  errorMessageWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(0.5, 1),
    fontWeight: 500,
    lineHeight: 1.5,
    color: theme.palette.error.main,
    columnGap: theme.spacing(1),
  },
}));

export const useThresholdsInfoMessageStyles = makeStyles((theme: Theme) => ({
  common: {
    display: 'flex',
    height: theme.typography.pxToRem(20),
    columnGap: theme.spacing(1),
    padding: theme.spacing(0, 1),
    alignItems: 'center',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
    lineHeight: 1.5,
    [theme.breakpoints.down('sm')]: {
      height: theme.typography.pxToRem(60),
    },
  },
  placeholder: {
    color: 'transparent',
  },
  addStatus: {
    color: theme.palette.success.main,
  },
  deleteStatus: {
    color: theme.palette.error.main,
  },
}));

export const useThresholdsHelperTextStyles = makeStyles((theme: Theme) => ({
  helperText: {
    fontFamily: 'Inter',
    padding: theme.spacing(1, 2),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.5,
    color: 'rgba(0, 0, 0, 0.6)',
  },
}));

export const useThresholdsGuidelineInfoStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 4,
    backgroundColor: '#eaeef1',
    display: 'flex',
    flexDirection: 'row',
  },
  text: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  iconWrapper: {
    verticalAlign: 'top',
    paddingRight: theme.spacing(0.5),
  },
  link: {
    fontWeight: 500,
    color: '#1464f4',
    textDecoration: 'none',
  },
}));
