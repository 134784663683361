import { Box } from '@mui/material';
import { QuestionnaireQuestion, QuestionnaireResponseItem } from '../../../../models';
import { QuestionnaireResponseQuestion } from '../Question';

interface Props {
  questions: QuestionnaireQuestion[];
  questionsAnswers: QuestionnaireResponseItem[];
}

export function QuestionnaireResponseQuestionsList({ questions, questionsAnswers }: Props) {
  return (
    <Box width="100%">
      <Box mt={3}>
        {questions.map((question, index) => (
          <QuestionnaireResponseQuestion
            key={question.id}
            question={question}
            index={index}
            questionAnswer={questionsAnswers.find((qa) => qa.questionnaireItemId === question.id)}
          />
        ))}
      </Box>
    </Box>
  );
}
