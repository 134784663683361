import { IconButton } from '@mui/material';
import { PlusIconWithCircleBackground } from '../Icons';
import { AddButtonProps } from './AddButton';

export function AddCircleButton({ onAddClick, disabled, id }: AddButtonProps) {
  const iconProps = {
    color: 'inherit',
    backgroundColor: 'inherit',
  };

  return (
    <IconButton onClick={onAddClick} disabled={disabled} id={id} size="large">
      <PlusIconWithCircleBackground
        circleSize={35}
        invertColors={true}
        {...(disabled ? iconProps : {})}
      />
    </IconButton>
  );
}
