import { Button, CircularProgress } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Alert } from '@mui/lab';
import { HubState } from '../../../services/api';
import { useTranslation } from 'react-i18next';

interface Props {
  hubState: HubState;
}

export function HubStateInfo({ hubState }: Props) {
  const { t } = useTranslation(['hub', 'common']);

  function renderAlert(text: string, isReconnecting?: boolean) {
    return (
      <Alert
        severity="info"
        icon={isReconnecting ? <CircularProgress size="1.25rem" color="inherit" /> : undefined}
        action={
          isReconnecting ? undefined : (
            <Button
              variant="contained"
              color="primary"
              startIcon={<RefreshIcon />}
              onClick={() => window.location.reload()}
            >
              {t('common:reload')}
            </Button>
          )
        }
      >
        {text}
      </Alert>
    );
  }

  if (hubState.isError) {
    return renderAlert(t('hub:state.error'));
  }

  if (hubState.isDisconnected) {
    return renderAlert(t('hub:state.disconnected'));
  }

  if (hubState.isReconnecting) {
    return renderAlert(t('hub:state.reconnecting'), true);
  }

  return null;
}
