import { Medic } from '../../models';
import { MedicsGrid } from '../../components/Grids';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { DeleteCompletedHandler, DeleteHandler } from '../../components/Grid';
import { ResetOnetimePasswordHandler } from '../../components/ResetOnetimePasswordButton';
import { ResetPasswordHandler } from '../../components/ResetPasswordButton';

interface Props {
  medics: Medic[] | null;
  onDelete?: DeleteHandler;
  onDeleteCompleted?: DeleteCompletedHandler<Medic>;
  onResetOnetimePassword?: ResetOnetimePasswordHandler;
  onResetPasscode?: ResetPasswordHandler;
  onDeactivate?: (medicId: number) => Promise<void>;
  onDeactivateCompleted?: () => Promise<void>;
}

export function MedicsList({
  medics,
  onDelete,
  onDeleteCompleted,
  onResetOnetimePassword,
  onResetPasscode,
  onDeactivate,
  onDeactivateCompleted,
}: Props) {
  const navigate = useNavigate();

  function onRowClick(row: Medic) {
    navigate(`/medics/${row.id}`);
  }
  return (
    <Box width="100%">
      {medics && (
        <MedicsGrid
          data={medics}
          onDelete={onDelete}
          onDeleteCompleted={onDeleteCompleted}
          onResetOnetimePassword={onResetOnetimePassword}
          onResetPasscode={onResetPasscode}
          onRowClick={onRowClick}
          onDeactivate={onDeactivate}
          onDeactivateCompleted={onDeactivateCompleted}
        />
      )}
    </Box>
  );
}
