import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export interface StyleProps {
  isDrawerOpen: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
  navigationItemWrapper: {
    lineHeight: theme.typography.pxToRem(15),
    borderRadius: theme.typography.pxToRem(4),
    padding: theme.spacing(2, 0),
    '&:hover': {
      backgroundColor: 'inherit',
      '& .MuiListItemIcon-root, & .MuiListItemText-root': {
        color: `${theme.palette.primary.main} !important`,
      },
    },
  },
  listItemIconRoot: {
    minWidth: theme.typography.pxToRem(32),
    minHeight: theme.typography.pxToRem(32),
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(3),
    '&>svg': {
      width: theme.typography.pxToRem(32),
      height: theme.typography.pxToRem(32),
    },
  },
  listItemWrapper: {
    display: 'flex',
    borderRadius: theme.typography.pxToRem(4),
    justifyContent: 'flex-start',
    width: '100%',
  },
  navigationItemText: {
    fontWeight: 'normal',
    letterSpacing: theme.typography.pxToRem(-0.48),
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.primary.A700,
    paddingRight: theme.spacing(1),
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'pre-wrap',
    textOverflow: 'ellipsis',
  },
  navigationItemTextSelected: {
    color: theme.palette.primary.main,
  },
  navigationItemIconAndText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(0, 4),
    borderRadius: theme.typography.pxToRem(4),
  },
}));
