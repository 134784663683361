import { Surface, Symbols } from 'recharts';
import { useTranslation } from 'react-i18next';
import { useThresholdsLegendStyles } from './styles';
import { Box } from '@mui/material';
import { thresholdColors } from '../../../../../constants/colors';

export function ThresholdsSubLegend() {
  const { t } = useTranslation('thresholds');
  const classes = useThresholdsLegendStyles();

  return (
    <Box display="flex" flexDirection="row">
      <Box marginRight={1}>
        <Surface
          className={classes.icon}
          width={10}
          height={10}
          viewBox={{ x: 0, y: 0, width: 10, height: 10 }}
        >
          <Symbols cx={5} cy={5} type="circle" fill={thresholdColors.maxErrorDark} />
        </Surface>
        <span className="recharts-text">{t('error')}</span>
      </Box>
      <div>
        <Surface
          className={classes.icon}
          width={10}
          height={10}
          viewBox={{ x: 0, y: 0, width: 10, height: 10 }}
        >
          <Symbols cx={5} cy={5} type="circle" fill={thresholdColors.maxWarningDark} />
        </Surface>
        <span className="recharts-text">{t('warning')}</span>
      </div>
    </Box>
  );
}
