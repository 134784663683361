import * as Yup from 'yup';
import { SubmitButton } from '../../../components/SubmitButton';
import { Form, SubmitFormCompletedHandler, SubmitFormHandler } from '../../../components/Form';
import { PasswordInput } from '../../../components/PasswordInput';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface ResetPasswordFormValues {
  newPassword: string;
}

interface ValidationFormValues extends ResetPasswordFormValues {
  confirmPassword: string;
}

interface Props {
  onSubmit: SubmitFormHandler<ResetPasswordFormValues>;
  onSubmitCompleted: SubmitFormCompletedHandler;
}

export function ResetPasswordForm({ onSubmit, onSubmitCompleted }: Props) {
  const { t } = useTranslation('resetPassword');
  const defaultFormValues: ValidationFormValues = {
    newPassword: '',
    confirmPassword: '',
  };

  const schema: Yup.SchemaOf<ValidationFormValues> = Yup.object({
    newPassword: Yup.string()
      .required(t('newPassword.validation.required'))
      .min(8, ({ min }) => t('newPassword.validation.minLength', { length: min }))
      .max(255, ({ max }) => t('newPassword.validation.maxLength', { length: max }))
      .test('Contains lowercase', t('newPassword.validation.lowercase'), hasLowerCase)
      .test('Contains uppercase', t('newPassword.validation.uppercase'), hasUpperCase),
    confirmPassword: Yup.string()
      .required(t('confirmPassword.validation.required'))
      .oneOf([Yup.ref('newPassword')], t('confirmPassword.validation.matchingPasswords')),
  });

  return (
    <Form<ResetPasswordFormValues>
      initialValues={defaultFormValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      onSubmitCompleted={onSubmitCompleted}
    >
      <Box display="flex" flexDirection="column" width="100%">
        <PasswordInput id="newPassword" label={t('newPassword.label')} required={true} />
        <PasswordInput id="confirmPassword" label={t('confirmPassword.label')} required={true} />
        <SubmitButton fullWidth={true} buttonText={t('submitButton')} />
      </Box>
    </Form>
  );
}

function hasLowerCase(str?: string): boolean {
  if (!str) {
    return false;
  }

  return str.toUpperCase() !== str;
}

function hasUpperCase(str?: string) {
  if (!str) {
    return false;
  }

  return str.toLowerCase() !== str;
}
