import * as Yup from 'yup';
import { TextInput } from '../../components/TextInput';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormButton } from '../../components/FormButton';
import { EntityForm } from '../../components/EntityForm';
import { PatientDevicePairing } from '../../models';
import { ApiResponse } from '../../services/api';
import { DatePicker } from '../../components/DatePicker';
import { usePatientDevicePairingFormStyles } from './styles';
import { BackIconWithCircleBackground } from '../../components/Icons';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as NavigationConstants from '../../constants/NavigationConstants';

export interface PatientDevicePairingFormValues extends PatientDevicePairing {}

interface Props {
  initialValues: PatientDevicePairingFormValues;
}

export function PatientDevicePairingForm({ initialValues }: Props): JSX.Element {
  const { t } = useTranslation(['patientDevicePairing', 'common']);
  const { id } = useParams<{ id: string }>();
  const classes = usePatientDevicePairingFormStyles();
  const navigate = useNavigate();
  const schema: Yup.SchemaOf<PatientDevicePairingFormValues> = Yup.object({
    id: Yup.number().required(),
    purpose: Yup.string().notRequired(),
    expectedEndDate: Yup.date().notRequired(),
    deviceExternalId: Yup.string().notRequired(),
    deviceChassisId: Yup.string().required(),
    pairingUserName: Yup.string().required(),
    unpairingUserName: Yup.string().notRequired(),
    startDate: Yup.date().required(),
    endDate: Yup.date().notRequired(),
    conclusion: Yup.string().notRequired(),
    latestOnline: Yup.date().notRequired(),
  });

  async function handleSubmit() {
    return new Promise<ApiResponse<never>>((resolve) => {
      return resolve({});
    });
  }

  function onGoBack() {
    navigate(`/patients/${id}/pairings?${NavigationConstants.DEFAULT_PAIRING_SORT_PATH_PARAM}`, {
      replace: true,
    });
  }

  return (
    <EntityForm<PatientDevicePairingFormValues>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      forceReadOnlyMode={true}
    >
      <Box className={classes.formRow}>
        <TextInput id="pairingUserName" label={t('patientDevicePairing:pairingAuthor.label')} />
        <DatePicker id="startDate" label={t('patientDevicePairing:monitoringStart.label')} />
      </Box>
      <TextInput
        id="purpose"
        label={t('patientDevicePairing:purpose.label')}
        className={classes.input}
        multiline={true}
      />
      {initialValues.endDate != null && (
        <>
          <Box className={classes.formRow}>
            <TextInput
              id="unpairingUserName"
              label={t('patientDevicePairing:unpairingAuthor.label')}
            />
            <DatePicker id="endDate" label={t('patientDevicePairing:monitoringEnd.label')} />
          </Box>
          <TextInput
            id="conclusion"
            label={t('patientDevicePairing:conclusion.label')}
            className={classes.input}
            multiline={true}
          />
        </>
      )}
      <Box display="flex" width="100%" justifyContent="flex-end">
        <Button
          onClick={onGoBack}
          startIcon={<BackIconWithCircleBackground invertColors={true} />}
          size="small"
          variant="text"
          className={classes.backButton}
        >
          {t('common:goBack')}
        </Button>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <FormButton />
      </Box>
    </EntityForm>
  );
}
