import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { getInputErrorText } from '../InputErrorText';
import { useStyles } from './styles';
import React, { ChangeEvent, ReactElement } from 'react';
import { theme } from '../../assets/style';
import { useFormikDisableContext } from '../../context/formikDisable';
import { ReactComponent as CheckboxIcon } from '../../assets/icons/CheckboxIcon.svg';

export interface CheckboxIconInputProps {
  id: string;
  htmlId?: string;
  label: string;
  icon: ReactElement;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  showError?: boolean;
}

export function CheckboxIconInput({
  id,
  htmlId,
  label,
  icon,
  required,
  disabled,
  helperText,
  checked,
  onChange,
  showError = true,
}: CheckboxIconInputProps) {
  const classes = useStyles();
  // Idealy we would get the required flag from validationSchema
  // from formik context (useFormikContext) to have one source of thruth about
  // field requirements. However, there is a bug that validationSchema
  // is not included in formik context even tho the type definitions say otherwise.
  // see: https://github.com/formium/formik/pull/2521
  const [{ value, onChange: fieldOnChange, ...field }, meta] = useField(id);
  const { isSubmitting } = useFormikContext();
  const { isDisabled } = useFormikDisableContext();

  const errorMessage = meta.touched ? meta.error : null;
  // Fix for warning: `value` prop on `input` should not be null.
  const fieldValue = value === null ? '' : value;

  const activeColor = theme.palette.primary.dark;
  const notActiveColor = theme.palette.grey['300'];
  const isChecked = checked ?? fieldValue;

  return (
    <FormGroup style={{ width: '100%' }}>
      <FormControl
        id={htmlId}
        required={required}
        error={!!errorMessage}
        component="fieldset"
        className={`${classes.formControl} ${
          isChecked ? classes.formControlActive : classes.formControlNotActive
        }`}
      >
        <FormControlLabel
          classes={{ root: classes.formControlLabelRoot, label: classes.formControlLabelLabel }}
          control={
            <Checkbox
              {...field}
              onChange={onChange ?? fieldOnChange}
              checked={isChecked}
              id={id}
              required={required}
              disabled={isSubmitting || isDisabled || disabled}
              color="primary"
              icon={React.cloneElement(icon, { primaryColor: notActiveColor })}
              checkedIcon={React.cloneElement(icon, { primaryColor: activeColor })}
            />
          }
          label={
            <Box
              className={`${classes.checkboxLabel} ${
                isChecked ? classes.checkboxLabelActive : classes.checkboxLabelNotActive
              }`}
            >
              {label}
              <Box display="flex" justifyContent="center" alignItems="center">
                <CheckboxIcon
                  className={`${classes.checkboxLabelIcon} ${
                    isChecked ? classes.checkboxLabelIconActive : classes.checkboxLabelIconNotActive
                  }`}
                />
              </Box>
            </Box>
          }
          labelPlacement="end"
        />
        {showError && errorMessage && (
          <FormHelperText>{getInputErrorText(errorMessage) || helperText}</FormHelperText>
        )}
      </FormControl>
    </FormGroup>
  );
}
