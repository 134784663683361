import { HubState } from './types';

type HubReducerActions =
  | HubErrorAction
  | HubReconnectingAction
  | HubReconnectedAction
  | HubDisconnectedAction;

interface HubErrorAction {
  type: 'HUB_ERROR';
}

interface HubReconnectingAction {
  type: 'HUB_RECONNECTING';
}

interface HubDisconnectedAction {
  type: 'HUB_DISCONNECTED';
}

interface HubReconnectedAction {
  type: 'HUB_RECONNECTED';
}

export type HubReducerType = (state: HubState, action: HubReducerActions) => HubState;

export function hubReducer(state: HubState, action: HubReducerActions): HubState {
  switch (action.type) {
    case 'HUB_ERROR':
      return {
        ...state,
        isReconnecting: false,
        isDisconnected: false,
        isError: true,
      };
    case 'HUB_RECONNECTED': {
      return {
        ...state,
        isReconnecting: false,
        isDisconnected: false,
        isError: false,
      };
    }
    case 'HUB_RECONNECTING': {
      return {
        ...state,
        isReconnecting: true,
        isDisconnected: false,
        isError: false,
      };
    }
    case 'HUB_DISCONNECTED': {
      return {
        ...state,
        isReconnecting: false,
        isDisconnected: true,
        isError: false,
      };
    }
  }
}
