import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useThresholdsFormStyles = makeStyles((theme: Theme) => ({
  formHeader: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 500,
    letterSpacing: theme.typography.pxToRem(-0.72),
    color: theme.palette.primary.light,
  },
  chooseMeasurementType: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 800,
    lineHeight: 1.5,
    color: 'rgba(0, 0, 0, 0.8)',
    margin: theme.spacing(3, 0, 2),
  },
  imageAndFormWrapper: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(2),
    margin: theme.spacing(2, 0),
    alignItems: 'stretch',
  },
  formWrapper: {
    display: 'flex',
    flex: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  imageWrapper: {
    flex: 1,
    margin: theme.spacing(2, 0),
    width: 400,
    maxWidth: 400,
    height: 300,
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      width: '100%',
      height: 'auto',
    },
  },
  formThresholdsGroupWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
    height: '100%',
    rowGap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      rowGap: theme.spacing(1),
    },
  },
  formThresholdsMaxAndMinWrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
  },
  formButtonsWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2),
    columnGap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  },
}));
