import { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Questionnaire } from '../../../models';
import {
  DeleteCompletedHandler,
  DeleteHandler,
  Grid,
  GridColumnDefinition,
  RowClickHandler,
} from '../../Grid';
import React from 'react';
import { EDIT_SUBPATH } from '../../../constants';

interface Props {
  data: Questionnaire[];
  onDelete?: DeleteHandler;
  onDeleteCompleted?: DeleteCompletedHandler<Questionnaire>;
  onRowClick?: RowClickHandler<Questionnaire>;
  disableEdit?: boolean;
}

export function QuestionnairesGrid({
  data,
  onDelete,
  onDeleteCompleted,
  onRowClick,
  disableEdit = false,
  ...props
}: Props) {
  const { t } = useTranslation('questionnaire');
  const columns: GridColumnDefinition<Questionnaire>[] = [
    {
      field: 'title',
      headerName: t('titleField.label'),
      flex: 0.35,
      minWidth: 250,
      renderCell: (params: GridRenderCellParams) => <strong>{params.value}</strong>,
    },
    {
      field: 'description',
      headerName: t('description.label'),
      flex: 0.65,
      minWidth: 300,
    },
  ];

  function getEditPath(id: number) {
    return `/questionnaires/${id}/${EDIT_SUBPATH}`;
  }

  function getRowEditDisabled(row: Questionnaire) {
    return row?.isAssigned ?? false;
  }

  return (
    <Grid
      {...props}
      columns={columns}
      rows={data}
      {...(!disableEdit ? { getEditPath: getEditPath } : {})}
      getRowEditDisabled={getRowEditDisabled}
      onRowClick={onRowClick}
      onDelete={onDelete}
      onDeleteCompleted={onDeleteCompleted}
    />
  );
}
