import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LockIcon from '@mui/icons-material/Lock';
import { Button, Variant } from '../Button';
import { useResetPasswordButtonStyles } from './styles';
import { ClassNameMap } from '@mui/styles/withStyles/withStyles';

export type ResetPasswordHandler = () => Promise<string | null>;
export type ResetPasswordCompletedHandler = () => Promise<void>;

interface Props {
  buttonText: string;
  dialogTitle: string;
  dialogContent: (password: string) => ReactNode;
  onResetPassword: ResetPasswordHandler;
  onResetPasswordCompleted?: ResetPasswordCompletedHandler;
  color?: 'primary' | 'secondary';
  isOpen?: boolean;
  showStartIcon?: boolean;
  fullWidth?: boolean;
  variant?: Variant;
  classes?: Partial<ClassNameMap>;
}

export function ResetPasswordButton({
  buttonText,
  onResetPassword,
  onResetPasswordCompleted,
  dialogTitle,
  dialogContent,
  color = 'primary',
  isOpen = false,
  showStartIcon = true,
  fullWidth = false,
  variant,
  classes,
}: Props) {
  const componentClasses = useResetPasswordButtonStyles();
  const { t } = useTranslation('common');
  const [isReseting, setIsReseting] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(isOpen);
  const [password, setPassword] = useState('');

  useEffect(() => {
    (async function () {
      if (isOpen) {
        await handleResetPassword();
      }
    })();
  }, []);

  async function handleResetPassword() {
    setIsReseting(true);

    const result = await onResetPassword();

    setIsReseting(false);

    if (result) {
      setPassword(result);
      setIsDialogOpen(true);
    }
  }

  async function handleDialogClose() {
    setIsDialogOpen(false);
    if (onResetPasswordCompleted) {
      await onResetPasswordCompleted();
    }
  }

  return (
    <React.Fragment>
      <Button
        text={buttonText}
        color={color}
        variant={variant}
        onClick={async (e) => {
          e.stopPropagation();
          await handleResetPassword();
        }}
        {...(showStartIcon
          ? {
              startIcon: isReseting ? <CircularProgress size={24} color="inherit" /> : <LockIcon />,
            }
          : {})}
        disabled={isReseting}
        fullWidth={fullWidth}
        classes={classes}
      />
      {isDialogOpen && (
        <Dialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          classes={{ paper: componentClasses.paper }}
        >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>{dialogContent(password)}</DialogContent>
          <DialogActions>
            <Button text={t('ok')} color="primary" onClick={handleDialogClose} />
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
}
