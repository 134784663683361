import { FieldArray, FieldArrayRenderProps, useField, useFormikContext } from 'formik';
import React from 'react';
import { Box } from '@mui/material';
import { QuestionnaireScoringCategory } from '../../../models';
import { FormAddButton } from '../../AddButton';
import { FormDeleteIconButton } from '../../DeleteIconButton';
import { ScoringCategoryInput } from './ScoringCategoryInput';
import { ScoringCategoriesTitle } from '../ScoringCategoriesTitle';
import { ScoringCategoryColorIndicator } from '../ScoringCategoryColorIndicator';

interface Props {
  id: string;
}

export function QuestionnaireScoringCategoriesInput({ id }: Props) {
  const emptyValue: QuestionnaireScoringCategory = {
    name: '',
    scoreFrom: 0,
    scoreTo: 1,
  };
  const [{ value }] = useField<QuestionnaireScoringCategory[]>(id);
  const { setFieldValue, setFieldTouched, getFieldMeta } = useFormikContext();

  // When adding new category, make sure that value of score from is the value of score to
  // of last category + 1
  function addNewScoringCategory(arrayHelpers: FieldArrayRenderProps) {
    if (!value || value.length === 0) {
      arrayHelpers.push(emptyValue);
      return;
    }

    const lastField = `${id}[${value.length - 1}]`;
    const { value: lastFieldValue } = getFieldMeta<QuestionnaireScoringCategory>(lastField);

    if (!lastFieldValue) {
      return;
    }

    const newCategory: QuestionnaireScoringCategory = {
      ...emptyValue,
      scoreFrom: (lastFieldValue.scoreTo || lastFieldValue.scoreFrom || 0) + 1,
      scoreTo: (lastFieldValue.scoreTo || lastFieldValue.scoreFrom || 0) + 2,
    };
    arrayHelpers.push(newCategory);
  }

  // When removing scoring category, change the value of score from of next category
  // to be the value of score to of previous category
  function removeScoringCategory(arrayHelpers: FieldArrayRenderProps, index: number) {
    const nextField = `${id}[${index + 1}]`;
    const previousField = `${id}[${index - 1}]`;
    const { value: nextFieldValue } = getFieldMeta<QuestionnaireScoringCategory>(nextField);
    const { value: previousFieldValue } = getFieldMeta<QuestionnaireScoringCategory>(previousField);

    if (nextFieldValue) {
      setFieldValue(nextField, {
        ...nextFieldValue,
        // when deleting first category, the next category should now start at 0
        scoreFrom: previousFieldValue ? previousFieldValue.scoreTo + 1 : 0,
      });
      setFieldTouched(`${nextField}.scoreTo`);
    }

    arrayHelpers.remove(index);
  }

  function renderInputs(arrayHelpers: FieldArrayRenderProps) {
    if (!value || !Array.isArray(value) || value.length === 0) {
      return null;
    }

    return value.map((_, index) => (
      <Box key={index} display="flex" marginY={1}>
        <Box width="100%">
          <Box display="flex">
            <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
              <Box marginRight={1}>
                <ScoringCategoryColorIndicator
                  categoryIndex={index}
                  categoriesCount={value.length}
                />
              </Box>
              <ScoringCategoryInput id={id} index={index} />
              <FormDeleteIconButton
                onDeleteClick={() => removeScoringCategory(arrayHelpers, index)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    ));
  }

  function renderArray(arrayHelpers: FieldArrayRenderProps) {
    return (
      <Box marginBottom={2}>
        <ScoringCategoriesTitle />
        {renderInputs(arrayHelpers)}
        <Box
          display="flex"
          justifyContent="space-between"
          marginTop={1}
          data-testid="score-add-button"
        >
          <FormAddButton
            onAddClick={() => addNewScoringCategory(arrayHelpers)}
            id="addScoringCategoryBtn"
          />
        </Box>
      </Box>
    );
  }

  return (
    <React.Fragment>
      <FieldArray name={id} render={renderArray} />
    </React.Fragment>
  );
}
