import { StyledMeasurementTypeTab, StyledTabProps } from '../StyledTab';
import React from 'react';

interface Props extends StyledTabProps {
  label: string;
}

export function AlarmTypeNavTab({ label, ...props }: Props) {
  return (
    <StyledMeasurementTypeTab label={label} forceValidity={true} unsetMinHeight={true} {...props} />
  );
}
