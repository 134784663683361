import { Grid, GridColumnDefinition } from '../../Grid';
import { PatientDevicePairing } from '../../../models';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  patientPairings: PatientDevicePairing[];
  getEditPath?: (id: number) => string;
  onRowClick?: (row: PatientDevicePairing) => void;
  columnBuffer?: number;
}

export function PatientPairingsGrid({
  patientPairings,
  getEditPath,
  onRowClick,
  columnBuffer,
}: Props) {
  const { t } = useTranslation('patientDevicePairing');
  function getDateFormattedValue<T extends GridRenderCellParams>(params: T) {
    if (params?.value) {
      return new Date(params.value as string).toLocaleString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    }
  }

  const columns: GridColumnDefinition<PatientDevicePairing>[] = [
    {
      field: 'startDate',
      headerName: t('grid.startDate'),
      flex: 0.17,
      minWidth: 100,
      renderCell: getDateFormattedValue,
      valueFormatter: getDateFormattedValue,
    },
    {
      field: 'endDate',
      headerName: t('grid.endDate'),
      flex: 0.17,
      minWidth: 100,
      renderCell: getDateFormattedValue,
      valueFormatter: getDateFormattedValue,
    },
    {
      field: 'purpose',
      headerName: t('grid.purpose'),
      flex: 0.3,
      minWidth: 120,
      sortable: false,
    },
    {
      field: 'conclusion',
      headerName: t('grid.conclusion'),
      flex: 0.3,
      minWidth: 120,
      sortable: false,
    },
  ];

  return (
    <Grid
      columns={columns}
      rows={patientPairings}
      getEditPath={getEditPath}
      onRowClick={onRowClick}
      columnBuffer={columnBuffer}
    />
  );
}
