import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { StylesProps } from './ThresholdsImage';

export const useStyles = makeStyles<Theme, StylesProps>((theme: Theme) => ({
  imageWrapper: {
    flex: 1,
    margin: 0,
    padding: 0,
    width: 400,
    maxWidth: 400,
    height: 'max-content',
    position: 'relative',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      width: '100%',
      height: 'auto',
    },
  },
  imageText: {
    position: 'absolute',
    whiteSpace: 'nowrap',
    left: (props) => props.left,
    top: (props) => props.top,
    color: theme.palette.common.white,
    backgroundColor: (props) => props.bgColor,
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.typography.pxToRem(4),
    '&::after': {
      content: "' '",
      position: 'absolute',
      top: '50%',
      right: '100%',
      marginTop: theme.typography.pxToRem(-5),
      borderWidth: theme.typography.pxToRem(5),
      borderStyle: 'solid',
      borderColor: (props) => `transparent ${props.bgColor} transparent transparent`,
    },
  },
}));
