import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { thresholdColors } from '../../../../constants/colors';
import { useThresholdsContext } from '../../../../context/thresholds';
import { useThresholdsHelperTextStyles } from './styles';
import { ThresholdsType, ThresholdsTypeWithDefault } from '../../../../models';

export function ThresholdsHelperText() {
  const classes = useThresholdsHelperTextStyles();
  const { selectedTextField, defaultSelectedTextField } = useThresholdsContext();
  const { t } = useTranslation('thresholds');

  const getColorBySelectedThreshold = (type: ThresholdsTypeWithDefault): string => {
    switch (type) {
      case 'maxError':
        return thresholdColors.maxErrorDark;
      case 'maxWarning':
        return thresholdColors.maxWarningDark;
      case 'minWarning':
        return thresholdColors.minWarningDark;
      case 'minError':
        return thresholdColors.minErrorDark;
      case 'default':
        return getColorBySelectedThreshold(defaultSelectedTextField);
    }
  };

  const textField: ThresholdsType =
    selectedTextField == 'default' ? defaultSelectedTextField : selectedTextField;

  return (
    <Box className={classes.helperText}>
      <Box component="span" style={{ color: getColorBySelectedThreshold(selectedTextField) }}>
        {t(`${textField}.label` as const)}:{' '}
      </Box>
      {t(`${textField}.helperText` as const)}
    </Box>
  );
}
