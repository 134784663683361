import { Chip } from '../Chip';
import { useStyles } from './styles';
import { Box } from '@mui/material';
import { DAYS } from '../../constants';
import { useTranslation } from 'react-i18next';
import { GridRowId } from '@mui/x-data-grid';

interface Props {
  rowId?: GridRowId;
  days: number[];
}

export function DayChips({ rowId, days }: Props) {
  const { t } = useTranslation('common');
  const classes = useStyles();

  function isWeekendDay(dayName = '') {
    return ['saturday', 'sunday'].includes(dayName);
  }

  return (
    <Box className={classes.wrapper}>
      {days.sort().map((x) => {
        const dayName = DAYS.find((d) => x === d.id)?.name;
        let dayDisplayValue = '',
          dayTranslated = '';
        if (dayName) {
          dayTranslated = t(`days.${dayName}` as const);
          dayDisplayValue = dayTranslated.substring(0, 3);
        }

        return (
          <Chip
            key={x}
            text={dayDisplayValue}
            wrapperClassName={`${classes.day} ${
              isWeekendDay(dayName) ? classes.weekend : classes.workDay
            }`}
            textClassName={classes.dayText}
            tooltipText={dayTranslated}
            id={`row[${rowId}].repetitionDays-${dayName}`}
          />
        );
      })}
    </Box>
  );
}
