import { Box } from '@mui/material';
import { theme } from '../../assets/style';

interface IconProps {
  width?: number;
  height?: number;
  primaryColor?: string;
  secondaryColor?: string;
}

export function QuestionnaireNewIcon({
  width,
  height,
  primaryColor = theme.palette.primary.main,
  secondaryColor = theme.palette.primary.dark,
}: IconProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <svg
        width={`${width ?? 54}`}
        height={`${height ?? 55}`}
        viewBox="0 0 54 55"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter09876_d)">
          <rect x="3" y="2" width="48" height="48" rx="9.43353" fill="url(#paint09876_linear)" />
        </g>
        <g clipPath="url(#clip09876)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.6643 19.5088C31.6227 19.5079 29.968 17.8362 29.9674 15.7738V11.4336H18.5365C17.3131 11.4371 16.3218 12.4385 16.3184 13.6746V38.3255C16.3218 39.5616 17.3131 40.563 18.5365 40.5665H35.7418C36.9656 40.563 37.9565 39.5616 37.96 38.3255V19.5088H33.6643ZM33.6643 18.0148H36.9323L31.4462 12.487V15.7738C31.4471 17.011 32.4394 18.0136 33.6643 18.0148ZM28.3504 28.7702H25.0808C24.8165 27.0345 25.8502 25.8978 26.7051 24.9577C27.2603 24.3473 27.7401 23.8197 27.7401 23.2648C27.7401 22.472 27.1734 22.1637 26.5194 22.1637C25.7783 22.1637 25.2988 22.5601 24.7321 23.0886L22.6831 21.1947C23.7076 19.9615 25.1244 19.1247 26.781 19.1247C29.3095 19.1247 31.3148 20.2258 31.3148 23.0005C31.3148 24.3207 30.5206 25.1572 29.7529 25.9658C28.9838 26.7759 28.2413 27.558 28.3504 28.7702ZM26.6938 34.4078C25.4296 34.4078 24.5141 33.3948 24.5141 32.1175C24.5141 30.8403 25.4296 29.8273 26.6938 29.8273C27.9581 29.8273 28.8735 30.8403 28.8735 32.1175C28.8735 33.3948 27.9581 34.4078 26.6938 34.4078Z"
            fill="url(#paint19876_linear)"
          />
        </g>
        <defs>
          <filter
            id="filter09876_d"
            x="0.225434"
            y="0.612717"
            width="53.5491"
            height="53.5491"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="1.38728" />
            <feGaussianBlur stdDeviation="1.38728" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint09876_linear"
            x1="27"
            y1="2"
            x2="27"
            y2="50"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#EFEFEF" />
          </linearGradient>
          <linearGradient
            id="paint19876_linear"
            x1="35.8767"
            y1="14.364"
            x2="16.4625"
            y2="26.4385"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3003" stopColor={primaryColor} />
            <stop offset="0.9996" stopColor={secondaryColor} />
          </linearGradient>
          <clipPath id="clip09876">
            <rect
              width="36.0694"
              height="29.1329"
              fill="white"
              transform="translate(9.10449 11.4336)"
            />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
}
