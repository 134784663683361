import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from '../ProtectedRoute';
import { DepartmentPage } from '../../pages/department/DepartmentPage';
import { EDIT_SUBPATH } from '../../constants';

export function DepartmentRoutes() {
  return (
    <Routes>
      <Route
        path={`${EDIT_SUBPATH}`}
        element={
          <ProtectedRoute requireFunctionality="DEPARTMENTS_READ">
            <DepartmentPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/"
        element={
          <ProtectedRoute requireFunctionality="DEPARTMENTS_READ">
            <DepartmentPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}
