import React from 'react';
import { Box } from '@mui/material';
import { Qualification } from '../../models';
import { SelectInput, SelectOption } from '../SelectInput';
import { FormAddButton } from '../AddButton';
import { FieldArray, FieldArrayRenderProps, useField } from 'formik';
import { FormDeleteIconButton } from '../DeleteIconButton';
import { useTranslation } from 'react-i18next';

interface Props {
  id: string;
  qualifications: Qualification[];
}

export function QualificationsInput({ qualifications, id }: Props) {
  const { t } = useTranslation('medic');
  const [{ value }] = useField(id);

  const qualificationSelectOptions: SelectOption[] = qualifications.map((q) => ({
    value: q.id,
    label: q.name,
  }));

  function renderInputs(arrayHelpers: FieldArrayRenderProps) {
    if (!value || !Array.isArray(value) || value.length === 0) {
      return null;
    }

    return value.map((_, index) => (
      <Box key={index} display="flex">
        <SelectInput
          id={`${id}[${index}]`}
          label={t('qualification.label')}
          required={true}
          options={qualificationSelectOptions}
        />
        <Box display="flex" alignItems="center" justifyContent="center">
          <FormDeleteIconButton onDeleteClick={() => arrayHelpers.remove(index)} />
        </Box>
      </Box>
    ));
  }

  function renderArray(arrayHelpers: FieldArrayRenderProps) {
    return (
      <React.Fragment>
        {renderInputs(arrayHelpers)}
        <Box display="flex" justifyContent="space-between">
          <FormAddButton onAddClick={() => arrayHelpers.push('')} />
        </Box>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <FieldArray name={id} render={renderArray} />
    </React.Fragment>
  );
}
