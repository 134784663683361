import { Box, ListItemIcon, Tooltip, useTheme } from '@mui/material';
import React, { MouseEventHandler, ReactNode } from 'react';
import { StyleProps, useStyles } from './styles';
import { useDashboardMenuContext } from '../../../context/dashboardMenu';
import { LinkListItem } from '../../LinkListItem';

interface Props {
  to: string;
  isExternalUrl?: boolean;
  selected: boolean;
  text: string;
  icon?: ReactNode;
  selectedClassName?: string;
  onClick?: MouseEventHandler;
  desktopDrawerOpen: boolean;
  disabled?: boolean;
}

export function DashboardNavItem({
  text,
  icon,
  selectedClassName,
  onClick,
  selected,
  ...props
}: Props) {
  const theme = useTheme();
  const { isDrawerOpen } = useDashboardMenuContext();
  const classes = useStyles({ isDrawerOpen: isDrawerOpen } as StyleProps);

  const ListItemIconContent = icon ? (
    <ListItemIcon
      classes={{ root: classes.listItemIconRoot }}
      style={{
        color: selected ? theme.palette.primary.main : theme.palette.primary.A200,
      }}
    >
      {icon}
    </ListItemIcon>
  ) : (
    <></>
  );

  let LinkListItemWrapper = (
    <Box className={classes.listItemWrapper} onClick={onClick}>
      <LinkListItem
        {...props}
        selected={selected}
        text={text}
        showText={isDrawerOpen}
        icon={ListItemIconContent}
        textClassName={classes.navigationItemText}
        selectedTextClassName={classes.navigationItemTextSelected}
        selectedListItemClassName={selectedClassName}
        iconAndTextContainerClassName={classes.navigationItemIconAndText}
        listItemClassName={`${classes.navigationItemWrapper} ${classes.listItemWrapper}`}
        disableGutters={true}
      />
    </Box>
  );

  if (!isDrawerOpen && icon && text) {
    LinkListItemWrapper = (
      <Tooltip title={text} placement="right" classes={{ tooltip: classes.tooltip }}>
        {LinkListItemWrapper}
      </Tooltip>
    );
  }

  return LinkListItemWrapper;
}
