import React, { useEffect, useState } from 'react';
import { AlarmGridModelType, AlarmTypeName } from '../../../../../models';
import { Box } from '@mui/material';
import { useStyles } from './styles';
import { CustomTooltip } from '../../../../CustomTooltip';
import { useTranslation } from 'react-i18next';
import { Chip } from '../../../../Chip';
import { alarmTypeNameOptionsSortFn, mapAlarmTypeNameToIcon } from '../../../../../utils';
import { GridRowId } from '@mui/x-data-grid';

interface Props {
  rowId?: GridRowId;
  type: AlarmGridModelType;
}

export function TypeCell({ rowId, type }: Props) {
  const classes = useStyles();
  const { t } = useTranslation('alarm');

  const [sortedSensors, setSortedSensors] = useState<AlarmTypeName[]>(getSortedSensors());

  useEffect(() => {
    setSortedSensors(getSortedSensors());
  }, [type.sensors]);

  function getSortedSensors() {
    return type.sensors.sort(alarmTypeNameOptionsSortFn);
  }

  if (type.name || type.name === '') {
    return <Chip text={type.name} tooltipText={type.name} />;
  } else if (sortedSensors && sortedSensors.length > 0) {
    return (
      <Box className={classes.sensorsWrapper}>
        {sortedSensors.map((sensor) => (
          <span
            key={sensor}
            data-testid={sensor}
            id={`row[${rowId}].icon-${sensor}`}
            className={classes.sensorWrapper}
          >
            <CustomTooltip title={t(`alarmType.${sensor}` as const)} placement="bottom">
              {mapAlarmTypeNameToIcon(sensor, true)}
            </CustomTooltip>
          </span>
        ))}
      </Box>
    );
  }

  return <></>;
}
