export enum AlarmTypeEnum {
  THERMOMETER = 'THERMOMETER',
  BLOOD_PRESSURE_MONITOR = 'BLOOD_PRESSURE_MONITOR',
  GLUCOMETER = 'GLUCOMETER',
  PULSE_OXIMETER = 'PULSE_OXIMETER',
  WEIGHT = 'WEIGHT',
  REMINDER = 'REMINDER',
}

// Update for TS4.1+: you can now use template literal types to get
// the string representation of the values of the enums:
export type AlarmTypeName = `${AlarmTypeEnum}`;

export interface AlarmType {
  id: number;
  type: AlarmTypeName;
}

export interface AlarmTypeWithLabel extends AlarmType {
  label: string;
}
