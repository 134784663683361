import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export async function initializeSentry() {
  try {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_ENVIRONMENT?.toLowerCase(),
      release: 'SCase.Platform@' + process.env.REACT_APP_VERSION,
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: [process.env.REACT_APP_API_URL || ''],
        }),
      ],
      tracesSampleRate: 0.1,
    });
  } catch {
    /*
     * adblock might block loading of sentry
     * and web can function properly without sentry loaded
     * therefor we ignore this error
     */
  }
}
