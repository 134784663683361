import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { Note } from '../../../../../models';
import { FONT_SIZES, FONT_WEIGHTS } from '../../../../Constants';
import { DATE_TIME_LOCALE_WITHOUT_SECONDS } from '../../../../../constants';

const styles = StyleSheet.create({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  noteHeader: {
    fontSize: FONT_SIZES.h5,
    marginBottom: 3,
  },
  noteAuthor: {
    fontWeight: FONT_WEIGHTS.semibold,
  },
  noteDateTime: {
    marginLeft: 10,
    fontWeight: FONT_WEIGHTS.light,
  },
  noteTextWrapper: {
    paddingLeft: 15,
  },
  noteText: {
    fontSize: FONT_SIZES.h6,
  },
});

interface Props {
  note: Note;
}

export function PatientNote({ note }: Props) {
  return (
    <View style={styles.flexColumn} wrap={false}>
      <View style={[styles.flexRow, styles.noteHeader]}>
        <Text style={styles.noteAuthor}>{note.author}</Text>
        <Text style={styles.noteDateTime}>
          {getDateTimeInLocaleFromCreateAndUpdate(note.createdAt, note.updatedAt)}
        </Text>
      </View>
      <View style={styles.noteTextWrapper}>
        <Text style={styles.noteText}>{note.text}</Text>
      </View>
    </View>
  );
}

function getDateTimeInLocaleFromCreateAndUpdate(
  createdAt: Date | undefined,
  updatedAt: Date | undefined,
) {
  if (updatedAt) {
    return new Date(updatedAt).toLocaleString([], DATE_TIME_LOCALE_WITHOUT_SECONDS);
  } else if (createdAt) {
    return new Date(createdAt).toLocaleString([], DATE_TIME_LOCALE_WITHOUT_SECONDS);
  } else {
    return '--';
  }
}
