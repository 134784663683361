import { PatientMeasurementsFilterParameters, PatientNotesFilterParameters } from '../../models';

type ID = number | string;

export abstract class ApiRoutes {
  private static readonly ApiPrefix = '/web';

  public static Patient(patientId: ID) {
    return `${ApiRoutes.Patients}/${patientId}`;
  }

  public static PatientNotes(
    patientId: ID,
    patientNotesFilterParameters?: PatientNotesFilterParameters,
  ) {
    const params = [];
    let queryParams = '';

    if (patientNotesFilterParameters) {
      const { from, to } = patientNotesFilterParameters;

      if (from) {
        params.push(`from=${from.toISOString().slice(0, 10)}`);
      }

      if (to) {
        params.push(`to=${to.toISOString().slice(0, 10)}`);
      }

      if (params.length > 0) {
        queryParams = `?${params.join('&')}`;
      }
    }

    return `${ApiRoutes.Patients}/${patientId}/notes${queryParams}`;
  }

  public static PatientNote(patientId: ID, noteId: ID) {
    return `${ApiRoutes.Patients}/${patientId}/notes/${noteId}`;
  }

  public static PatientAlarms(patientId: ID) {
    return `${ApiRoutes.Patients}/${patientId}/alarms`;
  }

  public static PatientAlarm(patientId: ID, alarmId: ID) {
    return `${ApiRoutes.PatientAlarms(patientId)}/${alarmId}`;
  }

  public static DailyPlanTemplate(id: ID) {
    return `${ApiRoutes.DailyPlanTemplates}/${id}`;
  }

  public static DailyPlanTemplateAlarms(alarmGroupId: ID) {
    return `${ApiRoutes.DailyPlanTemplate(alarmGroupId)}/alarms`;
  }

  public static DailyPlanTemplateAlarm(alarmGroupId: ID, alarmId: ID) {
    return `${ApiRoutes.DailyPlanTemplateAlarms(alarmGroupId)}/${alarmId}`;
  }

  public static PatientMeasurements(
    patientId: ID,
    patientMeasurementsFilterParameters?: PatientMeasurementsFilterParameters,
  ) {
    const params = [];
    let queryParams = '';

    if (patientMeasurementsFilterParameters) {
      const { from, to, measuringDeviceIds } = patientMeasurementsFilterParameters;

      if (from) {
        params.push(`from=${from.toISOString().slice(0, 10)}`);
      }

      if (to) {
        params.push(`to=${to.toISOString().slice(0, 10)}`);
      }

      if (measuringDeviceIds && measuringDeviceIds.length > 0) {
        for (const measuringDeviceId of measuringDeviceIds) {
          params.push(`measuringDeviceIds=${measuringDeviceId}`);
        }
      }

      if (params.length > 0) {
        queryParams = `?${params.join('&')}`;
      }
    }

    return `${ApiRoutes.Patients}/${patientId}/measurements${queryParams}`;
  }

  public static PatientMeasurementThresholds(patientId: ID) {
    return `${ApiRoutes.Patients}/${patientId}/measurement-thresholds`;
  }

  public static PatientQuestionnaires(patientId: ID) {
    return `${ApiRoutes.Patients}/${patientId}/questionnaires`;
  }

  public static PatientQuestionnaireResponses(patientId: ID) {
    return `${ApiRoutes.Patients}/${patientId}/questionnaires/response`;
  }

  public static PatientQuestionnaire(patientId: ID, questionnaireId: ID) {
    return `${ApiRoutes.PatientQuestionnaires(patientId)}/${questionnaireId}`;
  }

  public static PatientDevicePairings(patientId: ID) {
    return `${ApiRoutes.Patients}/${patientId}/device-pairings`;
  }

  public static PatientDevicePairing(patientId: ID, devicePairingId: ID) {
    return `${ApiRoutes.PatientDevicePairings(patientId)}/${devicePairingId}`;
  }

  public static Medic(medicId: ID) {
    return `${ApiRoutes.Medics}/${medicId}`;
  }

  public static ResetPassword(token: string) {
    return `${ApiRoutes.ApiPrefix}/reset-password?token=${token}`;
  }

  public static ResetMedicPasscode(medicId: number) {
    return `${ApiRoutes.Medic(medicId)}/reset-passcode`;
  }

  public static UpdateActiveMedic(medicId: number) {
    return `${ApiRoutes.Medic(medicId)}/active`;
  }

  public static Questionnaire(questionnaireId: ID) {
    return `${ApiRoutes.Questionnaires}/${questionnaireId}`;
  }

  public static PatientQuestionnaireResponse(patientId: ID, questionnaireId: ID) {
    return `${ApiRoutes.Patient(patientId)}/questionnaires/${questionnaireId}/response`;
  }

  public static CreateAlarmsFromDailyPlanTemplates(patientId: ID, dailyPlanTemplateId: ID) {
    return `${ApiRoutes.ApiPrefix}/patients/${patientId}/alarms/create-from-template/${dailyPlanTemplateId}`;
  }

  public static MedicPatients(medicId: ID) {
    return `${ApiRoutes.Medics}/${medicId}/patients`;
  }

  public static PatientMedics(patientId: ID) {
    return `${ApiRoutes.Patients}/${patientId}/medics`;
  }

  public static PatientSendLoginInstructions(patientId: ID) {
    return `${ApiRoutes.Patients}/${patientId}/send-login-instructions`;
  }

  public static Organization(organizationId: ID) {
    return `${ApiRoutes.Organizations}/${organizationId}`;
  }

  public static Department(departmentId: ID) {
    return `${ApiRoutes.Departments}/${departmentId}`;
  }

  public static Device(deviceId: ID) {
    return `${ApiRoutes.Devices}/${deviceId}`;
  }

  public static OrganizationDevice(organizationId: ID) {
    return `${ApiRoutes.Organizations}/${organizationId}/devices`;
  }

  public static Group(groupId: ID) {
    return `${ApiRoutes.Groups}/${groupId}`;
  }

  public static readonly CurrentUser = `${ApiRoutes.ApiPrefix}/current-user`;
  public static readonly LoginUser = `${ApiRoutes.ApiPrefix}/login-user`;
  public static readonly LoginUserToken = `${ApiRoutes.ApiPrefix}/login-user/token`;
  public static readonly LogoutUser = `${ApiRoutes.ApiPrefix}/logout-user`;
  public static readonly UserRoles = `${ApiRoutes.ApiPrefix}/user-roles`;
  public static readonly Qualifications = `${ApiRoutes.ApiPrefix}/qualifications`;
  public static readonly Questionnaires = `${ApiRoutes.ApiPrefix}/questionnaires`;
  public static readonly Medics = `${ApiRoutes.ApiPrefix}/medics`;
  public static readonly Patients = `${ApiRoutes.ApiPrefix}/patients`;
  public static readonly PatientPairings = `${ApiRoutes.ApiPrefix}/pair/patient`;
  public static readonly IdentifierTypes = `${ApiRoutes.ApiPrefix}/identifier-types`;
  public static readonly MeasuringDevices = `${ApiRoutes.ApiPrefix}/measuring-devices`;
  public static readonly QuestionTypes = `${ApiRoutes.ApiPrefix}/question-types`;
  public static readonly DailyPlanTemplates = `${ApiRoutes.ApiPrefix}/daily-plan-templates`;
  public static readonly ResetOnetimePassword = `${ApiRoutes.ApiPrefix}/reset-onetime-password`;
  public static readonly ResetPasscode = `${ApiRoutes.ApiPrefix}/reset-passcode`;
  public static readonly AlarmTypes = `${ApiRoutes.ApiPrefix}/alarm-types`;
  public static readonly UnpairPatient = `${ApiRoutes.ApiPrefix}/unpair/patient`;
  public static readonly SystemEvents = `${ApiRoutes.ApiPrefix}/system-events`;
  public static readonly SystemEventsSearch = `${ApiRoutes.ApiPrefix}/system-events/search`;
  public static readonly AutocompleteDrugNames = `${ApiRoutes.ApiPrefix}/autocomplete/drug-names`;
  public static readonly SystemThresholds = `${ApiRoutes.ApiPrefix}/measurement-system-thresholds`;
  public static readonly Profile = `${ApiRoutes.ApiPrefix}/profile`;
  public static readonly AdminProfile = `${ApiRoutes.ApiPrefix}/admin-profile`;
  public static readonly ForgottenPassword = `${ApiRoutes.ApiPrefix}/forgotten-password`;
  public static readonly Organizations = `${ApiRoutes.ApiPrefix}/organizations`;
  public static readonly Departments = `${ApiRoutes.ApiPrefix}/departments`;
  public static readonly Devices = `${ApiRoutes.ApiPrefix}/devices`;
  public static readonly CreateBusinessToCustomerOrganization = `${ApiRoutes.ApiPrefix}/create-business-to-customer-organization`;
  public static readonly Groups = `${ApiRoutes.ApiPrefix}/groups`;
}
