import { Box } from '@mui/material';

interface IconProps {
  width?: number;
  height?: number;
}

export function BackIcon({ width = 8, height = 15 }: IconProps) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ position: 'relative', left: -1, top: -0.5 }}
    >
      <svg
        width={`${width}`}
        height={`${height}`}
        viewBox="0 0 8 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.33137 14.7699L7.77811 14.3113C7.91763 14.167 7.99449 13.9749 7.99449 13.7697C7.99449 13.5646 7.91763 13.3724 7.77811 13.228L2.71692 7.99678L7.78373 2.75949C7.92325 2.61539 8 2.42314 8 2.21804C8 2.01293 7.92325 1.82057 7.78373 1.67636L7.33974 1.21765C7.05101 0.918985 6.5807 0.918985 6.29198 1.21765L0.237633 7.45328C0.0982251 7.59738 0 7.7894 0 7.99633V7.99872C0 8.20394 0.0983353 8.39596 0.237633 8.54006L6.27557 14.7699C6.41498 14.9142 6.60636 14.9934 6.80479 14.9937C7.00333 14.9937 7.19207 14.9142 7.33137 14.7699Z"
          fill="currentColor"
        />
      </svg>
    </Box>
  );
}
