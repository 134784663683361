import { makeStyles } from '@mui/styles';
import { theme } from '../../assets/style';

export const useFormStyles = makeStyles(() => ({
  form: {
    width: '100%',
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(3),
    },
  },
  formPadding: {
    padding: theme.spacing(1),
  },
  alert: {
    marginTop: theme.spacing(3),
  },
}));
