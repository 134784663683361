import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { STICKY_TOPBAR_CLOSED_HEIGHT } from '../../constants';

export const useStyles = makeStyles((theme: Theme) => ({
  page: {
    width: '100%',
  },
  content: {
    maxWidth: '700px',
  },
  dividerMargin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  backButton: {
    borderRadius: theme.typography.pxToRem(20),
  },
}));

export const useQuestionnaireFormStyles = makeStyles((theme: Theme) => ({
  selectedQuestionSticky: {
    position: 'sticky',
    top: STICKY_TOPBAR_CLOSED_HEIGHT + 20,
  },
  selectedQuestionStickySafari: {
    position: '-webkit-sticky',
  },
  questionsAndSelectedQuestionContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  questionsInput: {
    width: '40%',
    marginTop: theme.spacing(1),
    height: 'max-content',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  selectedQuestionContainer: {
    width: '60%',
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    height: 'max-content',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: theme.spacing(1, 0),
      padding: theme.spacing(1),
      boxShadow: 'none',
    },
  },
}));

export const useQuestionnairePreviewPageStyles = makeStyles((theme: Theme) => ({
  scoringCategoriesPreviewAndPatients: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    columnGap: theme.spacing(1),
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginBottom: theme.spacing(2),
    },
  },
}));
