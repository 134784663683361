import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  indicator: {
    width: 10,
    height: 10,
    borderRadius: 90,
    display: 'block',
  },
}));
