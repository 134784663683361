import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  questionnaireHeaderTitle: {
    fontWeight: 900,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(30),
    color: theme.palette.text.primary,
  },
  questionnaireScoreTitle: {
    fontWeight: 900,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(26),
    color: theme.palette.text.primary,
  },
  questionnaireHeaderDate: {
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(23),
    background: `-webkit-linear-gradient(230.06deg, ${theme.palette.primary.main} 30.16%, ${theme.palette.primary.dark} 77.51%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  questionnaireHeaderDescription: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(18),
    [theme.breakpoints.down('md')]: {
      textAlign: 'justify',
      padding: theme.spacing(0, 1),
    },
  },
  duplicateButton: {
    padding: theme.spacing(0.25, 1.5),
    fontWeight: 900,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    borderColor: '#EDEDED',
  },
  titleAndDuplicateWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      padding: theme.spacing(0, 1),
    },
  },
  duplicateButtonWrapper: {
    padding: theme.spacing(2, 1, 0),
  },
}));
