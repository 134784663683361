import { MeasurementValueDouble, MeasurementValueInt, MeasurementValueString } from '../models';

// eslint-disable-next-line
export function instanceOfMeasurementValueInt(object: any): object is MeasurementValueInt {
  return 'valueInt' in object;
}

// eslint-disable-next-line
export function instanceOfMeasurementValueDouble(object: any): object is MeasurementValueDouble {
  return 'valueDouble' in object;
}

// eslint-disable-next-line
export function instanceOfMeasurementValueString(object: any): object is MeasurementValueString {
  return 'valueString' in object;
}
