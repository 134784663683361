import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { PageStyleProps } from './Page';

export const useStyles = makeStyles<Theme, PageStyleProps>((theme: Theme) => ({
  pageLayout: {
    width: '100%',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),

    flex: (props) => (props.fullWidth ? 1 : undefined),
    [theme.breakpoints.up(1000 + parseInt(theme.spacing(6), 10))]: {
      width: (props) => (props.fullWidth ? undefined : 1000),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: theme.spacing(0, 1.5),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: theme.spacing(0, 1),
    },
  },
  pageLayoutNotFull: {
    width: '100%',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),

    flex: (props) => (props.fullWidth ? 1 : undefined),
    [theme.breakpoints.up(1000 + parseInt(theme.spacing(6), 10))]: {
      width: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: theme.spacing(0, 1.5),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: theme.spacing(0, 1),
    },
  },
  pageContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)}`,
    [theme.breakpoints.down('md')]: {
      margin: 0,
      padding: 0,
    },
  },
}));
