import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  fullWidth: {
    width: '100%',
  },
  boxShadowAndBorderRadius: {
    marginTop: theme.typography.pxToRem(-3),
    boxShadow: `0px ${theme.typography.pxToRem(1)} ${theme.typography.pxToRem(
      5,
    )} rgba(0, 0, 0, 0.15)`,
    borderRadius: `0px 0px ${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(10)}`,
  },
}));
