import {
  AllMeasurements,
  MeasurandTypes,
  Measurement,
  MeasurementValueWithUnit,
  MeasuringDeviceType,
  MeasuringDeviceWithType,
  Note,
  Patient,
  WebMeasurement,
} from '../../../../models';
import {
  firstLetterToLower,
  getLocaleStringWith2DigitsFormat,
  mapMeasurementsToWebMeasurements,
  sortWebMeasurementsByMeasuringDevice,
  translateAndFixWebMeasurement,
} from '../../../../utils';
import { ValuesForExport } from '../ExportPatient';
import { TFunction } from 'i18next';

const getLocaleDateOrEmptyString = (date?: Date) =>
  date ? new Date(date).toLocaleDateString() : '';

const getPatientNameAndBirthdayString = (patient: Patient) =>
  `${patient.firstName} ${patient.lastName}\t${getLocaleDateOrEmptyString(patient.birthday)}`;

interface ExportSettingsAndData {
  shouldInclude: boolean;
  hasFunctionality?: boolean;
}

interface PatientExportSettingsAndData extends ExportSettingsAndData {
  data: Patient;
}

interface NoteExportSettingsAndData extends ExportSettingsAndData {
  data: Note[];
}

interface MeasurementExportSettingsAndData extends ExportSettingsAndData {
  data: AllMeasurements;
}

interface ExportDataObject {
  patient: PatientExportSettingsAndData;
  notes: NoteExportSettingsAndData;
  measurements: MeasurementExportSettingsAndData;
}

interface RowStringValueWithTimestamp {
  timestamp: Date;
  value: string;
}

const getExportDataObject = (
  patient: Patient,
  valuesForExport: ValuesForExport,
): ExportDataObject => {
  return {
    patient: {
      shouldInclude: valuesForExport.exportFormValues.includePatientDetail,
      data: patient,
    },
    notes: {
      shouldInclude: valuesForExport.exportFormValues.includeNotes,
      hasFunctionality: valuesForExport.hasNotesReadFunctionality,
      data: valuesForExport.patientNotes,
    },
    measurements: {
      shouldInclude: valuesForExport.exportFormValues.deviceIds.length > 0,
      hasFunctionality: true,
      data: valuesForExport.measurements,
    },
  };
};

const getDateOrNow = (date?: Date) => (date ? new Date(date) : new Date());

const getNoteValueRowString = (note: Note, tNote: TFunction) =>
  `${tNote('title.note')}\t${note.text}`;

const filterMeasuringDevicesBasedOnFormDeviceIds = (
  measuringDevices: MeasuringDeviceWithType[],
  deviceIds: number[],
) => measuringDevices.filter((x) => deviceIds.includes(x.id));

const mapSortAndTranslateMeasurementsToWebMeasurements = (
  measuringDeviceName: MeasuringDeviceType,
  tMeasurement: TFunction,
  measurements?: Measurement[],
) => {
  return translateAndFixWebMeasurement(
    sortWebMeasurementsByMeasuringDevice(
      mapMeasurementsToWebMeasurements(measurements),
      measuringDeviceName,
    ),
    tMeasurement,
  );
};

const getMeasurandTranslation = (measurand: keyof MeasurandTypes, tMeasurement: TFunction) =>
  tMeasurement(`${measurand}` as const);

const getMeasurementValueUnit = (measurementValueWithUnit: MeasurementValueWithUnit) =>
  measurementValueWithUnit.unit ? ` ${measurementValueWithUnit.unit}` : '';

const getMeasurementValueWithUnit = (measurementValueWithUnit: MeasurementValueWithUnit) =>
  `${String(measurementValueWithUnit.value)}${getMeasurementValueUnit(measurementValueWithUnit)}`;

const filterMeasurandEntriesFromMeasurement = (measurement: WebMeasurement) => {
  return Object.entries(measurement).filter(
    ([key]) => key !== 'id' && key !== 'measuredOnDatetime' && key !== 'measuredByName',
  );
};

const getMeasuringDeviceTranslation = (
  measuringDeviceName: MeasuringDeviceType,
  tMeasurement: TFunction,
) =>
  tMeasurement(
    `measuringDevices.${firstLetterToLower(
      measuringDeviceName,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    )}` as any,
  );

const rowStringValueWithTimestampSorter = (
  a: RowStringValueWithTimestamp,
  b: RowStringValueWithTimestamp,
) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();

export const convertValuesForExportToText = (
  tNote: TFunction,
  tMeasurement: TFunction,
  patient: Patient,
  valuesForExport: ValuesForExport,
): string => {
  const rows: string[] = [];
  const dynamicRowsStringValueWithTimestamp: RowStringValueWithTimestamp[] = [];
  const exportData = getExportDataObject(patient, valuesForExport);

  if (exportData.patient.shouldInclude && exportData.patient.data) {
    rows.push(getPatientNameAndBirthdayString(exportData.patient.data));
  }

  if (
    exportData.notes.shouldInclude &&
    exportData.notes.hasFunctionality &&
    exportData.notes.data
  ) {
    for (const note of exportData.notes.data) {
      dynamicRowsStringValueWithTimestamp.push({
        timestamp: getDateOrNow(note.createdAt),
        value: getNoteValueRowString(note, tNote),
      });
    }
  }

  if (
    exportData.measurements.shouldInclude &&
    exportData.measurements.hasFunctionality &&
    exportData.measurements.data
  ) {
    const measuringDevicesSelectedInForm = filterMeasuringDevicesBasedOnFormDeviceIds(
      valuesForExport.measuringDevices,
      valuesForExport.exportFormValues.deviceIds,
    );

    for (const measuringDevice of measuringDevicesSelectedInForm) {
      const measurements = mapSortAndTranslateMeasurementsToWebMeasurements(
        measuringDevice.name,
        tMeasurement,
        valuesForExport.measurements[measuringDevice.name as MeasuringDeviceType],
      );

      for (const measurement of measurements) {
        const measurandValues = filterMeasurandEntriesFromMeasurement(measurement).map(
          ([key, val]) =>
            `${getMeasurandTranslation(
              key as keyof MeasurandTypes,
              tMeasurement,
            )}: ${getMeasurementValueWithUnit(val as MeasurementValueWithUnit)}`,
        );

        dynamicRowsStringValueWithTimestamp.push({
          timestamp: new Date(measurement.measuredOnDatetime),
          value: `${getMeasuringDeviceTranslation(
            measuringDevice.name as MeasuringDeviceType,
            tMeasurement,
          )}\t${measurandValues.join('\t')}`,
        });
      }
    }
  }

  const sortedContentArr = dynamicRowsStringValueWithTimestamp.sort(
    rowStringValueWithTimestampSorter,
  );

  for (const a of sortedContentArr) {
    rows.push(`${getLocaleStringWith2DigitsFormat(a.timestamp)}\t${a.value}`);
  }

  return rows.join('\n');
};
