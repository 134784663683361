import { useNavigate, useParams } from 'react-router-dom';
import { ProgressIndicator } from '../../components/ProgressIndicator';
import { ApiRoutes, makeApiRequest, useGetApi } from '../../services/api';
import {
  Department,
  Medic,
  MedicCreateOrEditInput,
  MedicCreateResult,
  Qualification,
  ResetOnetimePasswordRequest,
  ResetOnetimePasswordResponse,
  ResetPasscodeResponse,
  UserRole,
} from '../../models';
import { MedicFormValues } from './MedicForm';
import { Page } from '../../components/Page';
import { useTranslation } from 'react-i18next';
import { FunctionalityProvider } from '../../context/functionality';
import { MedicNavigation } from './MedicNavigation';
import { StickyTopAppBar } from '../../components/StickyTopAppBar';
import React from 'react';
import { useEntityActionSnackbar } from '../../hooks';
import { MedicHeader } from '../../components/MedicHeader';

export function MedicPage() {
  const { t } = useTranslation('medic');
  const { enqueueEntityUpdatedSnackbar } = useEntityActionSnackbar();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [
    { data: medic, isLoading: isMedicLoading },
    { refetch: refetchMedic },
    { lastFetchDatetime },
  ] = useGetApi<Medic>(ApiRoutes.Medic(id as string));
  const [{ data: qualifications, isLoading: areQualificationsLoading }] = useGetApi<
    Qualification[]
  >(ApiRoutes.Qualifications);
  const [{ data: departments, isLoading: areDepartmentsLoading }] = useGetApi<Department[]>(
    ApiRoutes.Departments,
  );
  const [{ data: userRoles, isLoading: areUserRolesLoading }] = useGetApi<UserRole[]>(
    ApiRoutes.UserRoles,
  );

  async function handleSubmit(values: MedicFormValues) {
    const input: MedicCreateOrEditInput = {
      ...values,
      email: values.email || null,
    };
    return makeApiRequest<never, MedicCreateOrEditInput>(
      'PUT',
      ApiRoutes.Medic(id as string),
      input,
    );
  }

  async function handleSubmitCompleted(data?: MedicCreateResult) {
    await refetchMedic();
    navigate(`/medics/${id}`);

    enqueueEntityUpdatedSnackbar(t('medic', { context: data?.sex }), { context: data?.sex });
  }

  async function handleResetOnetimePassword(medicId: number): Promise<string | null> {
    const apiResponse = await makeApiRequest<
      ResetOnetimePasswordResponse,
      ResetOnetimePasswordRequest
    >('POST', ApiRoutes.ResetOnetimePassword, { medicId });

    if (apiResponse.error || !apiResponse.data) {
      navigate('/error');
      return null;
    }

    return apiResponse.data.onetimePassword;
  }

  async function handleResetPasscode(medicId?: number): Promise<string | null> {
    if (medicId == null) {
      navigate('/error');
      return null;
    }

    const apiResponse = await makeApiRequest<ResetPasscodeResponse>(
      'POST',
      ApiRoutes.ResetMedicPasscode(medicId),
    );

    if (apiResponse.error || !apiResponse.data) {
      navigate('/error');
      return null;
    }

    return apiResponse.data.passcode;
  }

  if (isMedicLoading || areQualificationsLoading || areUserRolesLoading || areDepartmentsLoading) {
    return <ProgressIndicator />;
  }

  if (medic == null) {
    return <div>{t('error.medicNotFound')}</div>;
  }

  if (qualifications == null) {
    return <div>{t('error.qualificationsNotFound')}</div>;
  }

  if (departments == null) {
    return <div>{t('error.departmentsNotFound')}</div>;
  }

  if (userRoles == null) {
    return <div>{t('error.userRolesNotFound')}</div>;
  }

  const {
    qualifications: medicQualifications,
    departments: medicDepartments,
    ...medicFields
  } = medic;

  return (
    <FunctionalityProvider
      createFunctionality="MEDICS_CREATE"
      readFunctionality="MEDICS_READ"
      updateFunctionality="MEDICS_UPDATE"
      deleteFunctionality="MEDICS_DELETE"
    >
      <>
        <StickyTopAppBar>
          <MedicHeader
            medic={medic}
            lastUpdated={lastFetchDatetime}
            onResetOnetimePassword={handleResetOnetimePassword}
            onResetPasscode={handleResetPasscode}
          />
        </StickyTopAppBar>
        <Page noPaper={true} requireFunctionality="MEDICS_READ">
          <MedicNavigation
            id={id as string}
            initialValues={{
              ...medicFields,
              qualificationIds: medicQualifications.map((q) => q.id),
              departmentIds: medicDepartments.map((d) => d.id),
            }}
            onSubmit={handleSubmit}
            onSubmitCompleted={handleSubmitCompleted}
            qualifications={qualifications}
            departments={departments}
            userRoles={userRoles}
          />
        </Page>
      </>
    </FunctionalityProvider>
  );
}
