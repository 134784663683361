import { Accordion, AccordionDetails, AccordionSummary, Box, Hidden } from '@mui/material';
import { useStyles } from './styles';
import { LinkButton } from '../LinkButton';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RelativeDateTime } from '../RelativeDateTime';
import { PageTopBarSearch } from '../PageTopBarSearch';
import { useScrollTrigger } from '../../hooks';

interface Props {
  title: string;
  addNewRedirectPath?: string;
  newRecordDescriptionText?: string;
  onSearch?: (searchedText: string) => void;
  lastUpdated?: Date | null;
  hideLastUpdated?: boolean;
}

export function PageTopBar({
  title,
  addNewRedirectPath,
  newRecordDescriptionText,
  onSearch,
  lastUpdated,
  hideLastUpdated = false,
}: Props) {
  const scrollTrigger = useScrollTrigger();
  const classes = useStyles();
  const { t } = useTranslation('common');
  const searchEnabled = !!onSearch;
  const addNewEnabled = typeof addNewRedirectPath !== 'undefined';

  return (
    <Box className={classes.wrapper}>
      <Hidden lgUp={true}>
        <Accordion elevation={0} classes={{ root: classes.accordionRoot }}>
          <AccordionSummary
            expandIcon={searchEnabled || addNewEnabled ? <ExpandMoreIcon /> : <></>}
            aria-controls="topbar-content"
            style={{
              pointerEvents: searchEnabled || addNewEnabled ? 'inherit' : 'none',
            }}
            id="topbar-header"
            classes={{ content: classes.accordionSummaryContent }}
          >
            <Box className={`${classes.flexColumn} ${classes.sectionWrapper}`} style={{ flex: 4 }}>
              {!hideLastUpdated && (
                <Box className={classes.lastUpdatedWrapper}>
                  {t('lastUpdated')}:
                  <Box className={classes.lastUpdatedValue}>
                    <RelativeDateTime date={lastUpdated} />
                  </Box>
                </Box>
              )}
              <Box className={classes.title}>{title}</Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box className={classes.wrapper}>
              {searchEnabled && <PageTopBarSearch onSearch={onSearch} />}
              <Box className={classes.descAndButtonWrapper}>
                <Box>{newRecordDescriptionText}</Box>
                {addNewEnabled && addNewRedirectPath && (
                  <LinkButton
                    to={addNewRedirectPath}
                    variant="contained"
                    disableElevation={true}
                    className={classes.addNewButton}
                    startIcon={<AddIcon />}
                  >
                    {t('add')}
                  </LinkButton>
                )}
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Hidden>
      <Hidden lgDown={true}>
        <Box>
          {!hideLastUpdated && (
            <>
              {!scrollTrigger && (
                <Box className={`${classes.mainSubtitle} ${classes.lastUpdatedWrapper}`}>
                  {t('lastUpdated')}:
                  <Box className={classes.lastUpdatedValue}>
                    <RelativeDateTime date={lastUpdated} />
                  </Box>
                </Box>
              )}
            </>
          )}
          <Box className={classes.mainTitleWrapper}>
            <Box className={classes.mainTitle}>{title}</Box>
            {addNewEnabled && addNewRedirectPath && (
              <LinkButton
                to={addNewRedirectPath}
                variant="contained"
                disableElevation={true}
                className={classes.addNewButton}
              >
                <AddIcon />
              </LinkButton>
            )}
          </Box>
        </Box>
        {searchEnabled && <PageTopBarSearch onSearch={onSearch} />}
      </Hidden>
    </Box>
  );
}
