import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { StyleProps } from './SaveSubmitButton';

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: {
    transition: theme.transitions.create(['background-color', 'color', 'border'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    border: ({ invertColors }) =>
      invertColors
        ? '#0065f0'
        : `${theme.typography.pxToRem(1)} solid ${theme.palette.grey['200']}`,
    borderRadius: theme.typography.pxToRem(4),
    padding: ({ width }) => theme.spacing(1, width ? 2 : 6),
    height: ({ height }) => height,
    minWidth: ({ width }) => width,
    color: ({ invertColors }) => (invertColors ? '#0065f0' : 'auto'),
    backgroundColor: ({ invertColors }) => (invertColors ? '#e5eefd' : '#082caf'),
    boxShadow: 'none',
    '&:hover, &:focus': {
      border: ({ invertColors }) =>
        invertColors ? '#0065f0' : `${theme.typography.pxToRem(1)} solid #041e74`,
      boxShadow: 'none',
      color: ({ invertColors }) => (invertColors ? theme.palette.common.white : 'auto'),
      backgroundColor: ({ invertColors }) =>
        invertColors ? theme.palette.primary.main : '#041e74',
    },
  },
  disabled: {
    backgroundColor: '#f3f4f7 !important',
    color: '#a3a9bd !important',
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: 'Inter',
    fontWeight: 'normal',
  },
}));
