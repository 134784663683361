import { Department } from '../../models';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { DeleteCompletedHandler, DeleteHandler } from '../../components/Grid';
import { DepartmentsGrid } from '../../components/Grids/DepartmentsGrid';

interface Props {
  departments: Department[] | null;
  onDelete?: DeleteHandler;
  onDeleteCompleted?: DeleteCompletedHandler<Department>;
}

export function DepartmentsList({ departments, onDelete, onDeleteCompleted }: Props) {
  const navigate = useNavigate();

  function onRowClick(row: Department) {
    navigate(`/departments/${row.id}`);
  }

  return (
    <Box width="100%">
      {departments && (
        <DepartmentsGrid
          data={departments}
          onDelete={onDelete}
          onDeleteCompleted={onDeleteCompleted}
          onRowClick={onRowClick}
        />
      )}
    </Box>
  );
}
