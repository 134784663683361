import React from 'react';
import { AppBar, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { useStyles } from './styles';
import { useScrollTrigger } from '../../hooks';

interface Props {
  children: React.ReactElement;
}

export function StickyTopAppBar({ children }: Props) {
  const scrollTrigger = useScrollTrigger();
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const classes = useStyles({ scrollTrigger: scrollTrigger });

  return (
    <AppBar
      position="sticky"
      classes={{ root: classes.appbarRoot }}
      className={`${classes.navbar} ${
        scrollTrigger && !isTabletOrMobile ? classes.navbarClosedHeight : classes.navbarFullHeight
      }`}
      elevation={0}
    >
      <Toolbar disableGutters={true}>{children}</Toolbar>
    </AppBar>
  );
}
