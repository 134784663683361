import { Box, ListItemIcon, useTheme } from '@mui/material';
import React, { MouseEventHandler, ReactNode } from 'react';
import { useStyles } from './styles';
import { LinkListItem } from '../../../LinkListItem';

interface Props {
  to: string;
  isExternalUrl?: boolean;
  selected: boolean;
  text: string;
  icon: ReactNode;
  selectedClassName?: string;
  onClick?: MouseEventHandler;
  disabled?: boolean;
  iconColor?: string;
}

export function DashboardMobileNavItem({
  text,
  icon,
  selectedClassName,
  onClick,
  selected,
  iconColor,
  disabled,
  ...props
}: Props) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box className={classes.listItemWrapper} onClick={!disabled ? onClick : () => {}}>
      <LinkListItem
        {...props}
        disabled={disabled}
        selected={selected}
        text={text}
        icon={
          <ListItemIcon
            classes={{ root: classes.listItemIconRoot }}
            style={{
              color: selected
                ? theme.palette.primary.main
                : iconColor ?? theme.palette.primary.A200,
            }}
          >
            {icon}
          </ListItemIcon>
        }
        textClassName={classes.navigationItemText}
        selectedTextClassName={classes.navigationItemTextSelected}
        selectedListItemClassName={selectedClassName}
        iconAndTextContainerClassName={classes.navigationItemIconAndText}
        listItemClassName={`${classes.navigationItemWrapper} ${classes.listItemWrapper}`}
        disableGutters={true}
      />
    </Box>
  );
}
