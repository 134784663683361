import { useFormikContext } from 'formik';
import { FormButton } from '../../../../../components/FormButton';
import React from 'react';
import { ExportPatientFormValues } from '../../ExportPatientForm';
import { ExportTypes } from '../../../../../types';
import { useTranslation } from 'react-i18next';

interface Props {
  text: string;
  forceSubmitting?: boolean;
  exportType: ExportTypes;
}

export function ExportByTypeFormButton({ text, forceSubmitting, exportType }: Props): JSX.Element {
  const { t } = useTranslation(['common', 'report', 'measurement']);
  const { submitForm, setValues, values } = useFormikContext();

  const handleSubmit = async () => {
    setValues({ ...(values as ExportPatientFormValues), exportType: exportType });

    await submitForm();
  };

  return (
    <FormButton
      actionText={text}
      disabledTooltipText={t('common:enterMissingData')}
      forceSubmitting={forceSubmitting}
      onSubmit={handleSubmit}
    />
  );
}
