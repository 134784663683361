import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

type CustomColor = {
  light?: string;
  main: string;
  dark?: string;
  contrastText?: string;
};

type ColorPartial = Partial<CustomColor>;

declare module '@mui/material/styles/createPalette' {
  interface PaletteColor extends ColorPartial {}
}

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2, 1.5, 4),
  },
  wrapperTextOverImage: {
    position: 'relative',
    textAlign: 'center',
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '270px',
    maxHeight: '250px',
    '& > img': {
      width: '100%',
      height: 'auto',
    },
  },
  imageWrapperTextOverImage: {
    '& > img': {
      width: '100%',
      height: 'auto',
    },
  },
  textWrapperTextOverImage: {
    position: 'absolute',
    bottom: '20%',
    left: '50%',
    transform: 'translate(-50%, 20%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 600,
    letterSpacing: theme.typography.pxToRem(-1.12),
    textAlign: 'center',
    color: theme.palette.primary[500],
  },
  subtitle: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    letterSpacing: theme.typography.pxToRem(-0.14),
    textAlign: 'center',
    color: theme.palette.grey.A400,
    maxWidth: 250,
    lineHeight: 1.71,
    marginTop: theme.spacing(0.5),
  },
}));
