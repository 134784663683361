import React, { Fragment } from 'react';
import { useStyles } from './styles';

interface Props {
  isOnline: boolean;
}

export function OnlineStatusCircle({ isOnline }: Props) {
  const classes = useStyles();
  return (
    <Fragment>
      {isOnline ? (
        <span className={`${classes.circle} ${classes.online}`} />
      ) : (
        <span className={`${classes.circle} ${classes.offline}`} />
      )}
    </Fragment>
  );
}
