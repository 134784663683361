import { Box } from '@mui/material';
import { SaveIcon } from './SaveIcon';

interface Props {
  color?: string;
  backgroundColor?: string;
  circleSize?: number;
}

export function SaveIconWithCircleBackground({
  color = '#ffff',
  backgroundColor = '#0E48D2',
  circleSize = 28,
}: Props) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.15)',
        width: circleSize,
        height: circleSize,
        borderRadius: '50%',
        color: color,
        backgroundColor: backgroundColor,
      }}
    >
      <SaveIcon />
    </Box>
  );
}
