import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  buttonRoot: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minWidth: 'unset',
    backgroundColor: theme.palette.primary.A100,
    color: theme.palette.primary.main,
    borderRadius: theme.typography.pxToRem(10),
    transition: theme.transitions.create(['backgroundColor', 'color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    '&:hover, &:focus, &[aria-expanded=true]': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1, 1.5),
    },
  },
  menuPaperRoot: {
    borderRadius: 5,
    boxShadow: '0 12px 44px -20px rgba(3, 17, 64, 0.80)',
    marginTop: theme.spacing(1),
    minWidth: 155,
  },
}));
