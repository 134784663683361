import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { BOTTOM_NAV_HEIGHT, BOTTOM_NAV_TOP_INDENT } from '../../constants';
import { StyleProps } from './Dashboard';

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: theme.palette.common.white,
  },
  navigation: {
    width: ({ drawerWidthCurrent }) => drawerWidthCurrent,
    minWidth: ({ drawerWidthCurrent }) => drawerWidthCurrent,
    transition: ({ isDrawerOpen }) =>
      theme.transitions.create(['width', 'min-width'], {
        easing: theme.transitions.easing.sharp,
        duration: isDrawerOpen
          ? theme.transitions.duration.leavingScreen
          : theme.transitions.duration.enteringScreen,
      }),
    [theme.breakpoints.between('sm', 'lg')]: {
      width: ({ drawerWidthClosed }) => `${drawerWidthClosed}px !important`,
      minWidth: ({ drawerWidthClosed }) => `${drawerWidthClosed}px !important`,
      transition: ({ isDrawerOpen }) =>
        theme.transitions.create(['width', 'min-width'], {
          easing: theme.transitions.easing.sharp,
          duration: isDrawerOpen
            ? theme.transitions.duration.leavingScreen
            : theme.transitions.duration.enteringScreen,
        }),
    },
    [theme.breakpoints.down('sm')]: {
      width: '0 !important',
      minWidth: '0 !important',
    },
  },
  drawerPaper: {
    width: ({ drawerWidthCurrent }) => drawerWidthCurrent,
    borderRight: 'none',
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: ({ drawerWidthCurrent }) => drawerWidthCurrent,
      boxSizing: 'border-box',
      borderRight: 'none',
    },
  },
  drawerPaperMobile: {
    width: '100%',
    borderTopLeftRadius: theme.typography.pxToRem(8),
    borderTopRightRadius: theme.typography.pxToRem(8),
    transition: ({ isDrawerOpen }) =>
      theme.transitions.create(['width', 'min-width'], {
        easing: theme.transitions.easing.sharp,
        duration: isDrawerOpen
          ? theme.transitions.duration.leavingScreen
          : theme.transitions.duration.enteringScreen,
      }),
  },
  navigationBox: {
    background: theme.palette.common.white,
    color: theme.palette.primary.A400,
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'normal',
    height: '100%',
    overflowY: 'auto',
    borderRight: '3px solid #f8f9fa',
    [theme.breakpoints.down('sm')]: {
      margin: '0 !important',
      width: '100%',
      borderRadius: 0,
    },
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    width: '100%',
    minWidth: 0,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: BOTTOM_NAV_HEIGHT + BOTTOM_NAV_TOP_INDENT,
    },
  },
  dividerLight: {
    backgroundColor: 'white',
  },
  bold: {
    fontWeight: 'bold',
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: ({ drawerWidthCurrent }) => drawerWidthCurrent,
      minWidth: ({ drawerWidthCurrent }) => drawerWidthCurrent,
      transition: ({ isDrawerOpen }) =>
        theme.transitions.create(['width', 'min-width'], {
          easing: theme.transitions.easing.sharp,
          duration: isDrawerOpen
            ? theme.transitions.duration.leavingScreen
            : theme.transitions.duration.enteringScreen,
        }),
      flexShrink: 0,
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      width: ({ drawerWidthClosed }) => `${drawerWidthClosed}px !important`,
      minWidth: ({ drawerWidthClosed }) => `${drawerWidthClosed}px !important`,
      transition: ({ isDrawerOpen }) =>
        theme.transitions.create(['width', 'min-width'], {
          easing: theme.transitions.easing.sharp,
          duration: isDrawerOpen
            ? theme.transitions.duration.leavingScreen
            : theme.transitions.duration.enteringScreen,
        }),
      flexShrink: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: 0,
      minWidth: 0,
      transition: ({ isDrawerOpen }) =>
        theme.transitions.create(['width', 'min-width'], {
          easing: theme.transitions.easing.sharp,
          duration: isDrawerOpen
            ? theme.transitions.duration.leavingScreen
            : theme.transitions.duration.enteringScreen,
        }),
      flexShrink: 0,
    },
  },
  appBar: {
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    [theme.breakpoints.up('lg')]: {
      width: ({ drawerWidthCurrent }) => `calc(100% - ${drawerWidthCurrent}px)`,
      minWidth: ({ drawerWidthCurrent }) => `calc(100% - ${drawerWidthCurrent}px)`,
      transition: ({ isDrawerOpen }) =>
        theme.transitions.create(['width', 'min-width'], {
          easing: theme.transitions.easing.sharp,
          duration: isDrawerOpen
            ? theme.transitions.duration.leavingScreen
            : theme.transitions.duration.enteringScreen,
        }),
      marginLeft: ({ drawerWidthCurrent }) => drawerWidthCurrent,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  [theme.breakpoints.down('sm')]: {
    // necessary for content to be below app bar
    toolbar: {
      ...theme.mixins.toolbar,
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      minHeight: BOTTOM_NAV_HEIGHT + BOTTOM_NAV_TOP_INDENT,
    },
  },
  bottomNavRoot: {
    minHeight: BOTTOM_NAV_HEIGHT,
  },
  bottomNavAction: {
    '& .MuiBottomNavigationAction-wrapper>svg': {
      width: 44,
      height: 44,
    },
  },
  bottomNavActionLabel: {
    display: 'none',
  },
  bottomNavActionSelected: {
    paddingTop: `${theme.spacing(2)} !important`,
  },
}));
