import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MeasurementValueWithUnit, WebMeasurement } from '../../../../models';
import { useStyles } from './styles';
import { getTimeString } from '../../../../utils';
import { LatestMeasurementColumn } from './LatestMeasurementColumn';
import { CustomTooltip } from '../../../../components/CustomTooltip';

interface Props {
  latestMeasurement?: WebMeasurement;
}

export function LatestMeasurement({ latestMeasurement }: Props) {
  const { t } = useTranslation('measurement');
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.column}>
        <LatestMeasurementColumn
          headerTitle={t('label.latestMeasurement')}
          primaryText={getTimeString(latestMeasurement?.measuredOnDatetime)}
          secondaryText={
            latestMeasurement != null
              ? new Date(latestMeasurement.measuredOnDatetime).toLocaleDateString()
              : ''
          }
        />
      </Box>
      <Box className={classes.column}>
        <LatestMeasurementColumn
          headerTitle={t('measuredByName')}
          primaryText={
            <CustomTooltip title={latestMeasurement?.measuredByName || ''}>
              <Box className={classes.measuredByName}>
                <span className={classes.measuredByNameText}>
                  {latestMeasurement?.measuredByName}
                </span>
              </Box>
            </CustomTooltip>
          }
        />
      </Box>
      {latestMeasurement &&
        Object.entries(latestMeasurement).map(([key, value], idx) => {
          const webMeasurementKey = key as keyof WebMeasurement;
          if (
            webMeasurementKey != 'id' &&
            webMeasurementKey != 'measuredOnDatetime' &&
            webMeasurementKey != 'measuredByName'
          ) {
            const measurementValueWithUnit = value as MeasurementValueWithUnit;
            return (
              <Box key={idx} className={classes.column}>
                <LatestMeasurementColumn
                  headerTitle={t(`${webMeasurementKey}` as const)}
                  primaryText={measurementValueWithUnit.value}
                  secondaryText={measurementValueWithUnit.unit}
                />
              </Box>
            );
          }
        })}
    </Box>
  );
}
