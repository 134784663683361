import { ApiResponse, ApiRoutes, makeApiRequest, useGetApi } from '../../../services/api';
import {
  AllMeasurements,
  IdentifierType,
  MeasuringDeviceWithType,
  Note,
  Optional,
  Patient,
  PatientDevicePairing,
  PatientMeasurementsFilterParameters,
  QuestionnaireResponse,
} from '../../../models';
import { ExportPatientForm, ExportPatientFormValues } from './ExportPatientForm';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ExportPatientPdf } from '../../../exports/PatientExport/ExportPatientPdf';
import { Font, pdf, PDFDownloadLink } from '@react-pdf/renderer';
import { Box } from '@mui/material';
import { useExportPatientStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { getSanitizedString } from '../../../utils';
import { saveAs } from 'file-saver';
import { useRequireFunctionality } from '../../../hooks';
import { ExportPatientFormLoading } from './ExportPatientFormLoading';
import { sendAmplitudeEvent } from '../../../utils/amplitude';
import { FONT_WEIGHTS } from '../../../exports/Constants';
import {
  PATIENT_NOTES_READ_POLICY,
  PATIENT_QUESTIONNAIRES_READ_POLICY,
} from '../../../constants/Policies';
import { ExportTypes } from '../../../types';
import { ExportAsTextModal } from './components/ExportAsTextModal';
import { convertValuesForExportToText } from './utils/exportToTextUtils';

export interface ValuesForExport {
  measurements: AllMeasurements;
  patientDevicePairings: PatientDevicePairing[];
  questionnaireResponses: QuestionnaireResponse[];
  patientNotes: Note[];
  measuringDevices: MeasuringDeviceWithType[];
  identifierTypes: IdentifierType[];
  exportFormValues: ExportPatientFormValues;
  hasPatientDevicePairingsReadFunctionality: boolean;
  hasNotesReadFunctionality: boolean;
  hasQuestionnaireReadFunctionality: boolean;
}

interface Props {
  patient: Patient;
  patientFetchDatetime: Date | null;
}

Font.register({
  family: 'SegoeUI',
  fonts: [
    {
      src: '/fonts/SegoeUI/SegoeUI-Regular.ttf',
    },
    {
      src: '/fonts/SegoeUI/SegoeUI-Bold.ttf',
      fontWeight: FONT_WEIGHTS.bold,
    },
    {
      src: '/fonts/SegoeUI/SegoeUI-Semibold.ttf',
      fontWeight: FONT_WEIGHTS.semibold,
    },
    {
      src: '/fonts/SegoeUI/SegoeUI-Light.ttf',
      fontWeight: FONT_WEIGHTS.light,
    },
  ],
});

export function ExportPatient({ patient, patientFetchDatetime }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const { patientId } = useParams();
  const { t } = useTranslation('report');
  const { t: tPatient } = useTranslation('patient');
  const { t: tMeasurement } = useTranslation('measurement');
  const { t: tNote } = useTranslation('note');
  const classes = useExportPatientStyles();

  const { hasFunctionality: hasQuestionnaireReadFunctionality } = useRequireFunctionality(
    PATIENT_QUESTIONNAIRES_READ_POLICY,
  );
  const { hasFunctionality: hasNotesReadFunctionality } =
    useRequireFunctionality(PATIENT_NOTES_READ_POLICY);
  const { hasFunctionality: hasPatientDevicePairingsReadFunctionality } = useRequireFunctionality(
    'PATIENT_DEVICE_PAIRINGS_READ',
  );

  const loadFonts = useCallback(async () => {
    await Promise.all([
      Font.load({ fontFamily: 'SegoeUI' }),
      Font.load({ fontFamily: 'SegoeUI', fontWeight: 700 }),
      Font.load({ fontFamily: 'SegoeUI', fontWeight: 600 }),
      Font.load({ fontFamily: 'SegoeUI', fontWeight: 200 }),
    ]);
  }, []);

  useEffect(() => {
    loadFonts();
  }, [loadFonts]);

  const [forceSubmitting, setForceSubmitting] = useState<boolean>(false);
  const [valuesForExport, setValuesForExport] = useState<ValuesForExport | null>(null);
  const [textExportContent, setTextExportContent] = useState<string>('');

  const [{ data: measuringDevices, isLoading: areMeasuringDevicesLoading }] = useGetApi<
    MeasuringDeviceWithType[]
  >(ApiRoutes.MeasuringDevices);

  const [{ data: identifierTypes, isLoading: areIdentifierTypesLoading }] = useGetApi<
    IdentifierType[]
  >(ApiRoutes.IdentifierTypes);

  const generateAndShowAsText = (patient: Patient, valuesForExport: ValuesForExport) => {
    setTextExportContent(
      convertValuesForExportToText(tNote, tMeasurement, patient, valuesForExport),
    );
    setIsOpen(true);
    setForceSubmitting(false);
  };

  async function getQuestionnaireResponses() {
    return (
      (
        await makeApiRequest<QuestionnaireResponse[]>(
          'GET',
          ApiRoutes.PatientQuestionnaireResponses(patientId as string),
        )
      ).data ?? []
    );
  }

  async function getPatientDevicePairings() {
    return (
      (
        await makeApiRequest<PatientDevicePairing[]>(
          'GET',
          ApiRoutes.PatientDevicePairings(patientId as string),
        )
      ).data ?? []
    );
  }

  async function getPatientNotes(from?: Optional<Date>, to?: Optional<Date>) {
    return (
      (
        await makeApiRequest<Note[]>(
          'GET',
          ApiRoutes.PatientNotes(patientId as string, { from: from, to: to }),
        )
      ).data ?? []
    );
  }

  async function getPatientMeasurements(
    from: Optional<Date>,
    to: Optional<Date>,
    deviceIds: number[],
  ) {
    return (
      (
        await makeApiRequest<AllMeasurements>(
          'GET',
          ApiRoutes.PatientMeasurements(
            patientId as string,
            {
              from: from,
              to: to,
              measuringDeviceIds: deviceIds,
            } as PatientMeasurementsFilterParameters,
          ),
        )
      ).data ?? {}
    );
  }

  async function submitExport(formData: ExportPatientFormValues) {
    sendAmplitudeEvent('Patient export - export button clicked');
    // set as submiting and reset data for export
    setForceSubmitting(true);
    setValuesForExport(null);

    let questionnaireResponses: QuestionnaireResponse[] = [];
    if (formData.includeAnsweredQuestionnaires) {
      questionnaireResponses = await getQuestionnaireResponses();
    }

    let patientDevicePairings: PatientDevicePairing[] = [];
    if (formData.includePairings) {
      patientDevicePairings = await getPatientDevicePairings();
    }

    let patientNotes: Note[] = [];
    if (formData.includeNotes) {
      // FIXME: notes are filtered only in text export
      patientNotes =
        formData.exportType === ExportTypes.TXT
          ? await getPatientNotes(formData.from, formData.to)
          : await getPatientNotes();
    }

    let measurements: AllMeasurements = {};
    if (formData.deviceIds.length > 0) {
      measurements = await getPatientMeasurements(formData.from, formData.to, formData.deviceIds);
    }

    setValuesForExport({
      patientDevicePairings: patientDevicePairings,
      questionnaireResponses: questionnaireResponses,
      patientNotes: patientNotes,
      measurements: measurements,
      exportFormValues: formData,
      measuringDevices: measuringDevices ?? [],
      identifierTypes: identifierTypes ?? [],
      hasPatientDevicePairingsReadFunctionality: hasPatientDevicePairingsReadFunctionality,
      hasNotesReadFunctionality: hasNotesReadFunctionality,
      hasQuestionnaireReadFunctionality: hasQuestionnaireReadFunctionality,
    });

    // multiple API requests are called, so handle it before and return empty Promise
    return new Promise<ApiResponse<never>>((resolve) => {
      return resolve({});
    });
  }

  const patientPdfName = `${getSanitizedString(patient.firstName)}_${getSanitizedString(
    patient.lastName,
  )}_${new Date().toISOString().slice(0, 10)}`;

  useEffect(() => {
    const generateAndDownloadPdf = async () => {
      if (valuesForExport != null) {
        await generateAndDownloadPdfFile(
          patient,
          patientFetchDatetime,
          valuesForExport,
          patientPdfName,
        );
        setForceSubmitting(false);
      }
    };

    if (valuesForExport) {
      const formValues = valuesForExport.exportFormValues;
      switch (formValues.exportType) {
        case ExportTypes.PDF:
          generateAndDownloadPdf();
          break;
        case ExportTypes.TXT:
          generateAndShowAsText(patient, valuesForExport);
          break;
      }
    }
  }, [valuesForExport, measuringDevices]);

  if (areIdentifierTypesLoading) {
    return <ExportPatientFormLoading />;
  }

  if (identifierTypes == null) {
    return <div>{tPatient('error.identifierTypesNotFound')}</div>;
  }

  return (
    <Box className={classes.wrapper}>
      <ExportPatientForm
        onSubmit={submitExport}
        measuringDevices={measuringDevices}
        areMeasuringDevicesLoading={areMeasuringDevicesLoading}
        noResetFormOnSubmitCompleted={true}
        forceSubmitting={forceSubmitting}
      />
      <ExportAsTextModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        content={textExportContent}
      />
      {valuesForExport != null &&
      valuesForExport.exportFormValues.exportType === ExportTypes.PDF ? (
        <>
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <PDFDownloadLink
              document={
                <ExportPatientPdf
                  patient={patient}
                  patientFetchDatetime={patientFetchDatetime}
                  valuesForExport={valuesForExport}
                />
              }
              fileName={patientPdfName}
            >
              {({ blob, url: _, loading, error }) => {
                if (!loading && blob && !error) {
                  return t('downloadExport.downloadDoesntStartClickHere');
                }
              }}
            </PDFDownloadLink>
          </Box>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
}

const generateAndDownloadPdfFile = async (
  patient: Patient,
  patientFetchDatetime: Date | null,
  valuesForExport: ValuesForExport,
  patientPdfName: string,
) => {
  const blob = await pdf(
    <ExportPatientPdf
      patient={patient}
      patientFetchDatetime={patientFetchDatetime}
      valuesForExport={valuesForExport}
    />,
  ).toBlob();

  saveAs(blob, patientPdfName);
};
