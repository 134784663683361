import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSystemEventListHeaderStyles } from './styles';

export function SystemEventListHeader() {
  const classes = useSystemEventListHeaderStyles();
  const { t } = useTranslation('systemEvent');

  return (
    <Box className={classes.container}>
      <Typography color="textSecondary" variant="subtitle1" className={classes.actionNameColumn}>
        {t('column.action')}
      </Typography>
      <Divider orientation="vertical" flexItem={true} />
      <Typography color="textSecondary" variant="body1" className={classes.actionTimestampColumn}>
        {t('column.actionTimestamp')}
      </Typography>
      <Divider orientation="vertical" flexItem={true} />
      <Typography color="textSecondary" className={classes.actionDataColumn}>
        {t('column.data')}
      </Typography>
    </Box>
  );
}
