import { MeasuringDeviceType } from '../../models';
import React from 'react';
import { mapMeasuringDeviceToIcon } from '../../utils';

interface Props {
  measuringDeviceType: MeasuringDeviceType;
  isActive?: boolean;
}

export const MeasuringDeviceCheckBoxIcon = React.memo(
  function MeasuringDeviceCheckBoxIconComponent({ measuringDeviceType, isActive = false }: Props) {
    return mapMeasuringDeviceToIcon(measuringDeviceType, isActive);
  },
);
