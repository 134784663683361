import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  date: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.6,
    color: '#1b1e22',
  },
  endDate: {
    color: '#40434a !important',
  },
}));
