import React from 'react';
import { theme } from '../../assets/style';

interface Props {
  width?: number;
  height?: number;
}

export const MedicationIcon = React.memo(function MedicationIconComponent({
  width,
  height,
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height || 24}
      width={width || 24}
      viewBox="0 0 24 24"
      fill={theme.palette.primary.light}
    >
      <g>
        <path d="M0,0h24v24H0V0z" fill="none" />
      </g>
      <g>
        <g>
          <path d="M10.5,15H8v-3h2.5V9.5h3V12H16v3h-2.5v2.5h-3V15z M19,8v11c0,1.1-0.9,2-2,2H7c-1.1,0-2-0.9-2-2V8c0-1.1,0.9-2,2-2h10 C18.1,6,19,6.9,19,8z M17,8H7v11h10V8z M18,3H6v2h12V3z" />
        </g>
      </g>
    </svg>
  );
});
