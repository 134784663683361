import { PatientOnSystemEvent } from '../../../models';
import { useTranslation } from 'react-i18next';
import { SystemEventListItemEntityPropertyValue } from './SystemEventListItemEntityPropertyValue';

interface Props {
  patient: PatientOnSystemEvent;
}

export function SystemEventListItemActionPatient({ patient }: Props) {
  const { t } = useTranslation('patient');

  return (
    <SystemEventListItemEntityPropertyValue
      propertyName={t('patient', { context: patient.sex })}
      propertyValue={patient.name}
    />
  );
}
