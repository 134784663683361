import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useCurrentUser } from '../../context';
import { Page } from '../../components/Page';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InstallationFormValues, InstallationStepperForm } from './form/InstallationStepperForm';
import { InstallationData } from '../../models/InstallationData';
import { useStyles } from './styles';
import { ApiRoutes, makeApiRequest } from '../../services/api';
import { useEntityActionSnackbar, useErrorSnackbar } from '../../hooks';
import { RequestHeaders } from '../../services/api/utils';
import { API_INSTALLATION_TOKEN_HEADER } from '../../services/api/constants';
import { isValidEmail } from '../../utils';
import { useEffect, useState } from 'react';

export function InstallationPage() {
  const navigate = useNavigate();
  const [emailIsValid, setEmailIsValid] = useState(true);
  const classes = useStyles();
  const { enqueueEntityCreatedSnackbar } = useEntityActionSnackbar();
  const { enqueueCommonErrorSnackbar } = useErrorSnackbar();
  const { isAuthenticated } = useCurrentUser();
  const { t } = useTranslation('installation');
  const { token, encodedEmail, chassisId } = useParams<{
    token: string;
    encodedEmail: string;
    chassisId: string;
  }>();
  const email = decodeURIComponent(encodedEmail as string);

  useEffect(() => {
    const checkEmail = async () => {
      const valid = await isValidEmail(email);
      setEmailIsValid(valid);
    };

    checkEmail();
  }, [encodedEmail]);

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (!emailIsValid) {
    return <Navigate to="/" />;
  }

  const handleSubmit = (values: InstallationFormValues) => {
    const header: RequestHeaders = { [API_INSTALLATION_TOKEN_HEADER]: token };
    const data = { ...values };
    return makeApiRequest<never, InstallationData>(
      'POST',
      ApiRoutes.CreateBusinessToCustomerOrganization,
      data,
      undefined,
      header,
    );
  };

  const handleSubmitError = () => {
    enqueueCommonErrorSnackbar();
  };

  async function handleSubmitCompleted() {
    navigate('/', { replace: true });
    enqueueEntityCreatedSnackbar(t('installation'));
  }

  return (
    <Page noPaper={true}>
      <Box
        display="flex"
        width="100%"
        height="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box className={classes.intallationWrapper}>
          <InstallationStepperForm
            chassisId={chassisId as string}
            email={email}
            onSubmit={handleSubmit}
            onSubmitCompleted={handleSubmitCompleted}
            onSubmitError={handleSubmitError}
          />
        </Box>
        <Typography display="inline" color="textPrimary" variant="body2">
          {t('support.label')}
          <Typography display="inline" color="primary" variant="body2">
            {' support@m.scase.io'}
          </Typography>
        </Typography>
      </Box>
    </Page>
  );
}
