import { CurrentUser } from '../../models';

export interface CurrentUserState {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: CurrentUser | undefined;
}

export type CurrentUserReducerActions =
  | CurrentUserFetchInitAction
  | CurrentUserFetchSuccessAction
  | CurrentUserFetchFailureAction;

interface CurrentUserFetchInitAction {
  type: 'CURRENT_USER_FETCH_INIT';
}

interface CurrentUserFetchSuccessAction {
  type: 'CURRENT_USER_FETCH_SUCCESS';
  user: CurrentUser;
}

interface CurrentUserFetchFailureAction {
  type: 'CURRENT_USER_FETCH_FAILURE';
}

export type CurrentUserReducerType = (
  state: CurrentUserState,
  action: CurrentUserReducerActions,
) => CurrentUserState;

export function currentUserReducer(
  state: CurrentUserState,
  action: CurrentUserReducerActions,
): CurrentUserState {
  switch (action.type) {
    case 'CURRENT_USER_FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        user: undefined,
        isAuthenticated: false,
      };
    case 'CURRENT_USER_FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
      };
    case 'CURRENT_USER_FETCH_SUCCESS':
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.user,
      };
    default:
      throw new Error();
  }
}
